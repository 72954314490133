<template>
  <div>
    <!-- Modals -->
    <input-modal
      v-if="shareModal.message"
      :close-on-select="true"
      :multiple="false"
      :placeholder="$t('internal_chat.share_modal_placeholder')"
      :thread-list="true"
      :options="shareThreads"
      :title="$t('internal_chat.share_modal_title')"
      :button-text="$t('internal_chat.share_modal_button_share')"
      @update:modelValue="shareMessage"
      @close="closeModal"
    ></input-modal>
    <input-modal
      v-else-if="editMessage"
      :model-value="editMessage.getBodyHtmlSanitized('edit')"
      :rich="true"
      title="Edit message"
      :button-text="$t('internal_chat.edit_message_button_send')"
      @update:modelValue="saveEditedMessage"
      @close="closeModal"
    ></input-modal>

    <landing-page-index
      v-if="!thread && !isMobile"
      :description="$t('internal_chat.landing_page')"
    ></landing-page-index>

    <div v-else-if="!thread && isMobile" class="thread-messages flex h-full min-w-0 flex-col">
      <div
        class="sidebar-overlay scroll-on-hover w-full bg-white pt-4"
        :class="{ 'lg:hidden': sidebarOverlay, hidden: !sidebarOverlay }"
      >
        <div class="-mt-4 p-4 pr-4 lg:pr-2">
          <div
            class="tt-search-box tt-search-box-threads border-radius mt-2 flex items-center rounded-lg bg-grey-200 px-3"
            @click="$refs.searchThread.focus()"
          >
            <div class="flex items-center">
              <i class="material-icons mr-3 rounded-lg text-grey-600" style="font-size: 23px">search</i>
            </div>
            <form class="w-100 h-full">
              <input
                ref="searchThread"
                type="text"
                :value="queryValue"
                :placeholder="$t('general.searching')"
                class="w-100 h-full bg-grey-200 text-grey-600"
                style="line-height: normal"
                @input="(e) => (queryValue = e.target.value)"
              />
            </form>
          </div>
          <thread-list
            type="groups"
            class="thread-groups mt-2 pb-6"
            :query-value="queryValue.toLowerCase()"
          ></thread-list>
          <thread-list type="users" class="thread-users pb-6" :query-value="queryValue.toLowerCase()"></thread-list>
        </div>
      </div>
    </div>

    <div v-else class="thread-messages relative flex h-full min-w-0 flex-col">
      <div class="thread-header-wrapper flex select-none border-b-2 border-grey-200 px-4 py-4 pl-12 lg:pl-0">
        <span class="_700 thread-header-inner flex items-center truncate text-base lg:ml-4 lg:mr-6">
          <online-status
            v-if="thread.isUser()"
            :status="chatStatus(thread.getUser())"
            class="thread-header-online-status"
          ></online-status>
          <i v-else-if="thread.isGroup()" class="far fa-hashtag text-center" style="width: 35px"></i>
          <span class="title align-middle">&nbsp;{{ this.threadTitle || thread.name }}</span>
        </span>
        <div class="valign-center ml-auto mr-4 flex">
          <dropdown :static="true" :search="false" width="auto" :auto-height="true" :scroll-on-hover="false">
            <template #heading>
              <div class="_500 block py-4 text-center text-base leading-none text-black lg:hidden">
                {{ $t('internal_chat.settings_dropdown_title_video') }}
              </div>
            </template>
            <template #toggle>
              <li class="items-center" style="cursor: pointer; display: flex">
                <button aria-haspopup="true" aria-expanded="false">
                  <i class="far fa-video valign-center thread-options text-grey-600"></i>
                </button>
              </li>
            </template>
            <template #body>
              <ul class="dropdown-noborder thread-dropdown m-0 select-none p-0" style="z-index: 99999">
                <li v-if="thread.isGroup()" role="button" class="dropdown-item" @click="startZoomMeeting">
                  <svg
                    style="width: 22px"
                    height="22"
                    viewBox="0 0 32 32"
                    width="22"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <clipPath id="a"><path d="m-200-175h1000v562h-1000z" /></clipPath>
                    <clipPath id="b"><circle cx="107" cy="106" r="102" /></clipPath>
                    <clipPath id="c"><circle cx="107" cy="106" r="100" /></clipPath>
                    <clipPath id="d"><circle cx="107" cy="106" r="92" /></clipPath>
                    <clipPath id="e">
                      <path
                        clip-rule="evenodd"
                        d="m135 94.06 26-19c2.27-1.85 4-1.42 4 2v57.94c0 3.84-2.16 3.4-4 2l-26-19zm-88-16.86v43.2a17.69 17.69 0 0 0 17.77 17.6h63a3.22 3.22 0 0 0 3.23-3.2v-43.2a17.69 17.69 0 0 0 -17.77-17.6h-63a3.22 3.22 0 0 0 -3.23 3.2z"
                      />
                    </clipPath>
                    <g clip-path="url(#a)" transform="translate(0 -178)">
                      <path d="m232 61h366v90h-366z" fill="#4a8cff" />
                    </g>
                    <g clip-path="url(#a)" transform="matrix(.156863 0 0 .156863 -.784314 -.627496)">
                      <g clip-path="url(#b)"><path d="m0-1h214v214h-214z" fill="#e5e5e4" /></g>
                      <g clip-path="url(#c)"><path d="m2 1h210v210h-210z" fill="#fff" /></g>
                      <g clip-path="url(#d)"><path d="m10 9h194v194h-194z" fill="#4a8cff" /></g>
                      <g clip-path="url(#e)"><path d="m42 69h128v74h-128z" fill="#fff" /></g>
                    </g>
                    <g clip-path="url(#a)" transform="translate(0 -178)">
                      <path d="m232 19.25h180v38.17h-180z" fill="#90908f" />
                    </g>
                  </svg>
                  <span class="ml-2">Start Zoom video meeting</span>
                </li>
                <li v-if="thread.isUser()" role="button" class="dropdown-item" @click="createTrengoVideoRoom">
                  <i class="far fa-video fa-fw text-grey-600"></i>
                  <span class="ml-2">{{ $t('internal_chat.user_video_call', { name: thread.name }) }}</span>
                </li>
              </ul>
            </template>
          </dropdown>
        </div>
        <div class="valign-center ml-2 flex">
          <dropdown :static="true" :search="false" width="auto" :auto-height="true" :scroll-on-hover="false">
            <template #heading>
              <div class="_500 block py-4 text-center text-base leading-none text-black lg:hidden">
                {{ $t('internal_chat.settings_dropdown_title') }}
              </div>
            </template>
            <template #toggle>
              <li class="items-center" style="cursor: pointer; display: flex">
                <button aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons valign-center thread-options text-grey-600">more_horiz</i>
                </button>
              </li>
            </template>
            <template #body>
              <ul class="dropdown-noborder thread-dropdown m-0 select-none p-0" style="z-index: 99999">
                <li role="button" class="dropdown-item" @click="toggleSidebar()">
                  <i class="fa fa-info fa-fw text-grey-600" style="opacity: 1; margin-bottom: -2px"></i>
                  <span class="ml-2">
                    <span v-if="!sidebarState">{{ $t('internal_chat.settings_dropdown_show_sidebar') }}</span>
                    <span v-else-if="sidebarState">
                      {{ $t('internal_chat.settings_dropdown_hide_sidebar') }}
                    </span>
                  </span>
                </li>
                <li role="button" class="dropdown-item" @click="openMessagesModal()">
                  <i class="fa fa-search fa-fw text-grey-600" style="opacity: 1"></i>
                  <span class="ml-2">{{ $t('internal_chat.settings_dropdown_search') }}</span>
                </li>
                <li v-if="thread.id" role="button" class="dropdown-item" @click="thread.toggleMute()">
                  <i
                    class="fa fa-fw text-grey-600"
                    style="opacity: 1; margin-bottom: -1px"
                    :class="{ 'fa-bell-slash': thread.muted, 'fa-bell': !thread.muted }"
                  ></i>
                  <span class="ml-2">
                    {{
                      thread.muted
                        ? $t('internal_chat.settings_dropdown_unmute')
                        : $t('internal_chat.settings_dropdown_mute')
                    }}
                  </span>
                </li>
                <li v-if="thread.id && thread.isGroup()" role="button" class="dropdown-item" @click="leaveThread()">
                  <i class="fa fa-minus-circle fa-fw text-grey-600" style="opacity: 1; margin-bottom: -2px"></i>
                  <span class="ml-2">{{ $t('internal_chat.settings_dropdown_leave') }}</span>
                </li>
                <li
                  v-if="thread.id && thread.isGroup() && thread.userIsOwner()"
                  role="button"
                  class="dropdown-item"
                  @click="openGroupModal()"
                >
                  <i class="fa fa-cog fa-fw text-grey-600" style="opacity: 1; margin-bottom: -1px"></i>
                  <span class="ml-2">{{ $t('internal_chat.settings_dropdown_manage_group') }}</span>
                </li>
                <!--<div class="dropdown-divider" style="padding:0; margin:0;"></div>
                                <li @click="startZoomMeeting" role="button" class="dropdown-item"><svg style="width: 22px;" height="22" viewBox="0 0 32 32" width="22" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><clipPath id="a"><path d="m-200-175h1000v562h-1000z"/></clipPath><clipPath id="b"><circle cx="107" cy="106" r="102"/></clipPath><clipPath id="c"><circle cx="107" cy="106" r="100"/></clipPath><clipPath id="d"><circle cx="107" cy="106" r="92"/></clipPath><clipPath id="e"><path clip-rule="evenodd" d="m135 94.06 26-19c2.27-1.85 4-1.42 4 2v57.94c0 3.84-2.16 3.4-4 2l-26-19zm-88-16.86v43.2a17.69 17.69 0 0 0 17.77 17.6h63a3.22 3.22 0 0 0 3.23-3.2v-43.2a17.69 17.69 0 0 0 -17.77-17.6h-63a3.22 3.22 0 0 0 -3.23 3.2z"/></clipPath><g clip-path="url(#a)" transform="translate(0 -178)"><path d="m232 61h366v90h-366z" fill="#4a8cff"/></g><g clip-path="url(#a)" transform="matrix(.156863 0 0 .156863 -.784314 -.627496)"><g clip-path="url(#b)"><path d="m0-1h214v214h-214z" fill="#e5e5e4"/></g><g clip-path="url(#c)"><path d="m2 1h210v210h-210z" fill="#fff"/></g><g clip-path="url(#d)"><path d="m10 9h194v194h-194z" fill="#4a8cff"/></g><g clip-path="url(#e)"><path d="m42 69h128v74h-128z" fill="#fff"/></g></g><g clip-path="url(#a)" transform="translate(0 -178)"><path d="m232 19.25h180v38.17h-180z" fill="#90908f"/></g></svg> <span class="ml-2">Zoom Meeting</span></li>
                                <li @click="createTrengoVideoRoom" role="button" class="dropdown-item"><svg style="width: 22px;" height="22" viewBox="0 0 32 32" width="22" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><clipPath id="a"><path d="m-200-175h1000v562h-1000z"/></clipPath><clipPath id="b"><circle cx="107" cy="106" r="102"/></clipPath><clipPath id="c"><circle cx="107" cy="106" r="100"/></clipPath><clipPath id="d"><circle cx="107" cy="106" r="92"/></clipPath><clipPath id="e"><path clip-rule="evenodd" d="m135 94.06 26-19c2.27-1.85 4-1.42 4 2v57.94c0 3.84-2.16 3.4-4 2l-26-19zm-88-16.86v43.2a17.69 17.69 0 0 0 17.77 17.6h63a3.22 3.22 0 0 0 3.23-3.2v-43.2a17.69 17.69 0 0 0 -17.77-17.6h-63a3.22 3.22 0 0 0 -3.23 3.2z"/></clipPath><g clip-path="url(#a)" transform="translate(0 -178)"><path d="m232 61h366v90h-366z" fill="#4a8cff"/></g><g clip-path="url(#a)" transform="matrix(.156863 0 0 .156863 -.784314 -.627496)"><g clip-path="url(#b)"><path d="m0-1h214v214h-214z" fill="#e5e5e4"/></g><g clip-path="url(#c)"><path d="m2 1h210v210h-210z" fill="#fff"/></g><g clip-path="url(#d)"><path d="m10 9h194v194h-194z" fill="#4a8cff"/></g><g clip-path="url(#e)"><path d="m42 69h128v74h-128z" fill="#fff"/></g></g><g clip-path="url(#a)" transform="translate(0 -178)"><path d="m232 19.25h180v38.17h-180z" fill="#90908f"/></g></svg> <span class="ml-2">Start video call</span></li>-->
              </ul>
            </template>
          </dropdown>
        </div>
      </div>

      <div
        v-show="showInitOverlay"
        :class="{ 'bg-white': showInitOverlay }"
        class="chat-messages-overlay my-4 flex items-center justify-center"
      >
        <div
          class="shadow"
          style="
            width: 45px;
            height: 45px;
            background: white;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto"
            width="34px"
            height="34px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#14B29F"
              stroke-width="10"
              r="35"
              stroke-dasharray="164.93361431346415 56.97787143782138"
              transform="rotate(284.393 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      </div>

      <div
        v-show="!showInitOverlay"
        ref="chatMessages"
        class="scroll-on-hover chat-messages flex-grow"
        infinite-wrapper
      >
        <div style="min-height: 53px">
          <infinite-loading
            :key="'scrollUp#' + thread.id"
            ref="scrollUp"
            direction="top"
            :thread="thread"
            :identifier="'scrollUp' + resetCounter"
            force-use-infinite-wrapper="true"
            style="min-height: 39px"
            @infinite="loadMoreMessagesUp"
          >
            <template #no-more>
              <div>
                <div
                  v-if="thread && thread.isGroup()"
                  class="select-none px-3 py-2 pb-3 text-sm text-grey-500 md:mx-10 md:px-10"
                >
                  {{ $t('internal_chat.group_conversation') }}
                </div>
                <div
                  v-else-if="thread && thread.isUser()"
                  class="select-none px-3 py-2 pb-6 text-sm text-grey-500 md:mx-10 md:px-10"
                >
                  {{ $t('internal_chat.user_conversation', { name: thread.getName() }) }}
                </div>
              </div>
            </template>
            <template #no-results>
              <div>
                <div
                  v-if="thread && thread.isGroup()"
                  class="select-none px-3 py-2 pb-3 text-sm text-grey-500 md:mx-10 md:px-10"
                >
                  {{ $t('internal_chat.group_conversation') }}
                </div>
                <div
                  v-else-if="thread && thread.isUser()"
                  class="select-none px-3 py-2 pb-6 text-sm text-grey-500 md:mx-10 md:px-10"
                >
                  {{ $t('internal_chat.user_conversation', { name: thread.getName() }) }}
                </div>
              </div>
            </template>
            <template #spinner>
              <div></div>
            </template>
          </infinite-loading>
        </div>
        <div class="chat-messages-container">
          <template v-for="(message, index) in thread.messages">
            <chat-divider
              v-if="!isSameDay(message, thread.messages[index - 1] || null) || showUnread(message, index)"
              :key="'divider#' + message.id"
              :show-day="!isSameDay(message, thread.messages[index - 1] || null)"
              :day-text="dayDividerText(message.createdAt * 1000)"
              :show-unread="showUnread(message, index)"
              :unread-text="
                thread.messages.length - index === 1
                  ? $t('tickets.unread_message')
                  : $t('tickets.unread_messages', { count: thread.messages.length - index })
              "
              :is-mobile="isMobile"
            />
            <message
              v-if="message.id"
              :id="'message-' + message.id"
              :key="'message#' + message.id"
              :is-mobile="isMobile"
              :is-first-in-message-group="
                index === 0 ||
                thread.messages[index - 1].userId !== message.userId ||
                message.createdAt - thread.messages[index - 1].createdAt > 60
              "
              :message="message"
              :index="index"
              @onImageLoaded="onImageLoaded"
            ></message>
          </template>
        </div>
        <infinite-loading
          v-if="!showInitOverlay"
          :key="'scrollDown#' + thread.id"
          ref="scrollDown"
          direction="bottom"
          spinner="spiral"
          :identifier="'scrollDown' + resetCounter"
          force-use-infinite-wrapper="true"
          @infinite="loadMoreMessagesDown"
        >
          <template #spinner>
            <div></div>
          </template>
          <template #no-more><div></div></template>
          <template #no-results><div></div></template>
        </infinite-loading>
      </div>
      <div v-if="showInitOverlay" class="flex-grow"></div>

      <div>
        <chat-composer
          :key="'chat-message-composer-thread-' + thread.identifier"
          class="chat-composer-main mt-auto lg:mx-4"
          :class="{ 'lg:mr-6': !sidebarState }"
          :main-editor="true"
          :thread="thread"
          :placeholder="
            this.toName
              ? $t('internal_chat.editor_placeholder', { to: this.toName })
              : $t('internal_chat.editor_message_placeholder')
          "
          :emoji-trigger-icon="'emojiContainer'"
          :unread-count="thread.unread"
          :show-scroll-down="
            thread.messages.length &&
            (scrolledUp || (thread.lastMessageId && !thread.getLoadedMessageById(thread.lastMessageId)))
          "
          @scroll-down-click="goToBottom(false)"
        ></chat-composer>

        <div class="thread-typing-container">
          <div
            v-if="Object.keys(thread.typing).length"
            class="mr-auto select-none bg-white pl-4 pr-2 text-xs text-grey-600"
          >
            <span class="font-bold">{{ isTypingString }}</span>
            {{
              Object.keys(thread.typing).length > 1
                ? $t('internal_chat.users_are_typing')
                : $t('internal_chat.user_is_typing')
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters } from 'vuex';

import eventBus from '@/eventBus';

import { waitForEvent } from '../../../start/util';
import LandingPageIndex from '../../LandingPageIndex';
import Dropdown from '../../ReplyForm/Dropdown';
import ChatComposer from '../Components/ChatComposer';
import ChatDivider from '../Components/ChatDivider';
import Message from '../Components/Message';
import InputModal from '../Components/Modals/InputModal';
import OnlineStatus from '../Components/OnlineStatus';
import ThreadList from '../Components/ThreadList';
import { clean } from '../Util/Chat';
const initialMessagesCount = 1;

export default {
  name: 'Thread',
  components: {
    LandingPageIndex,
    ThreadList,
    InputModal,
    OnlineStatus,
    Message,
    ChatComposer,
    InfiniteLoading,
    Dropdown,
    ChatDivider,
  },
  data() {
    return {
      isMobile: window.document.body.clientWidth <= 991 || window.is_mobile_device(),
      threadTitle: '',
      queryValue: '',
      resetCounter: 0,
      editMessage: null,
      shareModal: {
        message: null,
      },
      scrolledUp: false,
      sidebarOverlay: false,
      showInitOverlay: true,
      sidebarState: window.innerWidth > 991,
      observer: null,
      lastReadProxy: null,
      lastReadIndicator: null,
    };
  },
  async created() {
    this.sidebarState =
      window.innerWidth > 991 ? (await this.$tStorage.getItem('sidebar_thread_state')) || true : false;
  },
  computed: {
    ...mapGetters({
      thread: 'chat/currentThread',
      userThreads: 'chat/userThreads',
      groupThreads: 'chat/groupThreads',
      currentUser: 'usersInternalChat/currentUser',
      chatStatus: 'chat/chatStatus',
      threadByIdentifier: 'chat/threadByIdentifier',
      userById: 'usersInternalChat/userById',
    }),
    shareThreads() {
      return [...this.userThreads, ...this.groupThreads]
        .filter((t) => this.thread !== t && (!t.isUser() || t.getUser().exists))
        .map((t) => {
          let user = {};
          if (t.isUser()) {
            user = t.getUser() || {};
          }

          return {
            value: t.identifier,
            label: t.isUser() ? user.getDisplayName() : t.subject,
            color: t.isUser() ? user.color : null,
            abbr: t.isUser() ? user.abbr : null,
            profileImage: t.isUser() ? user.profileImage : null,
            isUser: t.isUser(),
            isGroup: t.isGroup(),
          };
        });
    },
    toName() {
      if (this.thread.isGroup()) {
        return this.thread.identifier;
      }

      if (this.thread.isUser()) {
        return this.thread.getUser().getDisplayName();
      }
    },
    isTypingString() {
      if (this.thread.typing && this.thread.typing.length >= 3) {
        return 'Several people';
      }
      return Object.keys(this.thread.typing)
        .map((userId) => {
          return this.userById(parseInt(userId)).getDisplayName();
        })
        .join(', ');
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.initRoute();
        this.closeModal();
      },
    },
    thread: {
      deep: false,
      handler(t) {
        if (t) {
          this.$nextTick(() => this.afterThreadSet());
        }
      },
    },
    lastReadProxy(val) {
      this.$nextTick(() => {
        if ((window.hasWindowFocus && !window.lastWindowFocus) || new Date().getTime() - window.lastWindowFocus > 250) {
          this.lastReadIndicator = val;
        }
      });
    },
  },
  beforeMount() {
    this.initRoute();
  },
  provide() {
    return {
      attachmentImageLoaded: this.onImageLoaded,
    };
  },
  mounted() {
    window.addEventListener('focus', this.onWindowFocus);
    window.addEventListener('resize', this.onResizeDebounced);
    window.addEventListener('resize', this.toggleSideBarState);

    if (this.$route.path.endsWith('/chat') || this.$route.path.endsWith('/chat/')) {
      this.sidebarOverlay = true;
    }

    eventBus.$on(['interval-resume'], this.onResume);

    eventBus.$on(['chat@REACTION_SENT', 'chat@REACTION_RECEIVED'], ({ message, emoji }) => {
      this.$nextTick(() => {
        if (this.thread && message.threadId === this.thread.id) {
          this.scrollDown();
        }
      });
    });

    eventBus.$on('chat@AFTER_RECEIVE_MESSAGE', (message) => {
      this.$nextTick(() => {
        if (this.thread && message.threadId === this.thread.id && this.shouldScrollDown()) {
          this.goToMessage(message.id, false);
          if (window.hasWindowFocus) {
            this.lastReadIndicator = message.createdAt;
            this.thread.setLastRead();
          }
        }
        this.resetCounter++;
      });
    });

    eventBus.$on('chat@BEFORE_MESSAGE_SENT', (message) => {
      if (message.userId === this.$root.user.id) {
        this.lastReadProxy = message.createdAt;
      }
      if (message.id && isInt(message.id)) {
        this.$nextTick(() => {
          this.goToMessage(message.id, false);
          this.thread.setLastRead();
        });
      }
    });

    eventBus.$on('chat@AFTER_MESSAGE_SENT', (message) => {
      if (message.userId === this.$root.user.id) {
        this.lastReadProxy = message.createdAt;
      }
      this.scrollDown(true);
    });

    eventBus.$on('chat@SHOW_MESSAGE_EDITOR', (message) => {
      this.editMessage = message;
    });

    eventBus.$on('chat@SHOW_SHARE_MESSAGE', (message) => {
      this.shareModal.message = message;
    });

    eventBus.$on('chat@MARK_AS_UNREAD', (message) => {
      this.thread.setUnread(message);
    });

    eventBus.$on('chat@GO_TO_MESSAGE', (messageId) => {
      if (messageId && this.thread) {
        this.goToMessage(messageId);
      }
    });

    eventBus.$on('chat@ON_MAIN_COMPOSER_INIT', (editor) => {
      this.editor = editor;
    });

    eventBus.$on('chat@REPLY_MESSAGE', (message) => {
      const thread = message.getThread();
      if (thread && thread !== this.thread) {
        this.setCurrentThread(thread.identifier);
      }
    });

    eventBus.$on('chat@EDIT_MESSAGE', (messageBody) => {
      this.saveEditedMessage(messageBody);
    });

    eventBus.$on('chat-nav-integrated', () => {
      this.sidebarOverlay = !this.sidebarOverlay;
    });

    eventBus.$on('chat@TOGGLE_SIDEBAR_STATE', (showSidebar) => {
      this.sidebarState = showSidebar;
    });

    eventBus.$on('showThreadOverlay', () => {
      this.showInitOverlay = true;
    });

    if (this.thread) this.$nextTick(() => this.afterThreadSet());
  },
  beforeUnmount() {
    // TODO RL: only this instance, not all $on()'s
    eventBus.$off([
      'chat@REACTION_SENT',
      'chat@REACTION_RECEIVED',
      'chat@GO_TO_MESSAGE',
      'chat@SHOW_MESSAGE_EDITOR',
      'chat@SHOW_SHARE_MESSAGE',
      'chat@MARK_AS_UNREAD',
      'chat@AFTER_RECEIVE_MESSAGE',
      'chat@BEFORE_MESSAGE_SENT',
      'chat@AFTER_MESSAGE_SENT',
      'chat@REPLY_MESSAGE',
      'chat@ON_MAIN_COMPOSER_INIT',
      'chat@TOGGLE_SIDEBAR_STATE',
      'chat-nav-integrated',
    ]);

    eventBus.$off(['interval-resume'], this.onResume);
    window.removeEventListener('focus', this.onWindowFocus);
    window.removeEventListener('resize', this.onResizeDebounced);
    window.removeEventListener('resize', this.toggleSideBarState);
    this.$refs.chatMessages?.removeEventListener('scroll', this.onScrollDebounced);

    // reload last x messages (speeds up navigating to this thread next time)
    if (this.thread?.messages.length > initialMessagesCount || this.messageId) {
      this.thread?.reset();
    }
  },
  methods: {
    showUnread(message, index) {
      return (
        this.lastReadIndicator &&
        index !== 0 &&
        message.createdAt > this.lastReadIndicator &&
        this.thread.messages[index - 1].createdAt <= this.lastReadIndicator
      );
    },
    moment: () => moment,
    // todo optimize: we could create a computed moment instances in Message.vue. Another way would be a cached moment instance MessageModel.js (createdAt never updates, so that's easy)
    isSameDay(messageA, messageB) {
      return this.moment()
        .utc(messageA?.createdAt * 1000)
        .isSame(this.moment().utc(messageB?.createdAt * 1000), 'day');
    },
    onResizeDebounced: _.debounce(function () {
      this.scrollDown();
    }, 50),
    onScrollDebounced: _.debounce(function () {
      const offset = 100;
      if (this.$refs.chatMessages) {
        this.scrolledUp =
          this.$refs.chatMessages.scrollTop <
          this.$refs.chatMessages.scrollHeight - this.$refs.chatMessages.clientHeight - offset;
      }
    }, 150),

    shouldScrollDown() {
      // not when scrolled up
      if (this.scrolledUp) {
        return false;
      }

      // not when last message not loaded
      if (this.thread.lastMessageId && !this.thread.getLoadedMessageById(this.thread.lastMessageId)) {
        return false;
      }

      return true;
    },

    onImageLoaded(message) {
      this.scrollDown();
    },

    onWindowFocus() {
      if (this.thread) {
        this.lastReadProxy = this.thread.lastRead;
        this.$nextTick(() => {
          this.thread.setLastRead();
        });
      }
    },

    async onResume() {
      if (this.thread) {
        this.initRoute();
        this.resetCounter++;
      }
    },

    async initRoute() {
      if (!this.$store.state.chat.threads.length) {
        await waitForEvent('chat@INITIALIZED');
      }

      // get route params
      let hash = this.$route.hash.split('/');
      let newIdentifier = hash[0] || this.$route.params.identifier || null;
      this.messageId = parseInt(hash[1]) || parseInt(this.$route.params.messageId) || null;

      // nothing to init
      if (!newIdentifier) {
        return;
      }

      // reset previous thread if navigating to another thread
      if (
        this.thread &&
        this.thread.identifier !== newIdentifier &&
        (this.thread.messages.length > initialMessagesCount || this.messageId)
      ) {
        this.thread.reset();
      }

      // same thread but messageID got set
      if (this.messageId && this.thread && this.thread.identifier === newIdentifier) {
        //console.log('gotomessage in initRoute', this.messageId);
        // todo this breaks scroll down? search, and send a new message to test if scrolldown still works
        this.goToMessage(this.messageId);
        return;
      }

      // preload thread
      // todo figure out if the thread was reset. and only reset if more than x messages were loaded. this way we can quickly switch to /chat without (pre)loading
      this.showInitOverlay = true;
      let newThread = this.threadByIdentifier(newIdentifier);

      if (!newThread) {
        console.error("Thread doesn't exist");
        this.$router.push('/chat');
        this.showInitOverlay = false;
        return;
      }

      // set the preload thread title
      this.threadTitle = newThread.name;
      // todo set thread status/icon

      // preload the messages
      await this.$store.dispatch('chat/preloadThread', newThread);
      this.showInitOverlay = false;

      // set thread in store
      this.setCurrentThread(newIdentifier);

      this.$nextTick(() => {
        // unset preload title
        this.threadTitle = '';
        // todo unset thread status/icon

        // messageID is set
        if (this.messageId) {
          this.goToMessage(this.messageId);
        } else {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$nextTick(() => this.scrollDown(true));
              });
            });
          });
        }
      });
    },

    openGroupModal() {
      eventBus.$emit('chat@SHOW_MANAGE_GROUP_MODAL', this.thread);
    },

    openMessagesModal() {
      eventBus.$emit('chat@SHOW_MESSAGES_MODAL');
    },

    closeModal() {
      if (this.editMessage) this.editMessage = null;
      else if (this.shareModal.message) this.shareModal.message = null;

      if (!is_mobile_device() && this.editor) {
        window.placeCaretAtEnd(this.editor.el);
      }
    },

    /* message actions */
    async shareMessage(identifier) {
      let attachmentIds = this.shareModal.message.attachments.map((a) => a.id);
      let thread = this.threadByIdentifier(identifier);

      // send message
      await thread.sendMessage({ messageBody: this.shareModal.message.body }, attachmentIds);

      // go to shared message.
      eventBus.$emit('showThreadOverlay');
      setTimeout(() => {
        this.$router.push('/chat/' + thread.identifier);
      }, 1);

      // reset share modal;
      this.shareModal = {
        message: null,
      };
    },
    async saveEditedMessage(e) {
      // if message is set
      if (clean(e) || this.editMessage.attachments.length) {
        this.editMessage.body = clean(e);
        await this.thread.updateMessage(this.editMessage);
        this.editMessage = null;
        window.placeCaretAtEnd(this.editor.el);
      } else if (!clean(e) && !this.editMessage.attachments.length) {
        this.flashError(this.$t('internal_chat.error_message_is_required'));
      }
    },
    /* end message actions */

    /* thread methods */
    async afterThreadSet() {
      if (!this.thread) {
        this.showInitOverlay = false;
        return;
      }

      this.lastReadProxy = this.thread.lastRead;

      /*if(this.messageId) {
                    console.log('gotomessage in afterthreadset');
                    this.goToMessage(this.messageId);
                } else {
                    //this.goToFirstUnreadOrBottom(); // v2
                    this.scrollDown();
                }*/

      // can only do this after thread set otherwise the ref is not defined
      this.$refs.chatMessages.removeEventListener('scroll', this.onScrollDebounced);
      this.$refs.chatMessages.addEventListener('scroll', this.onScrollDebounced);

      this.handleZoomAuthentication();

      // load attachments
      this.thread.searchAttachments('');
    },
    // Notice: SHOULD ONLY BE CALLED IF MESSAGE-ID BELONGS TO CURRENT THREAD. Otherwise mixes up messages from two threads
    async goToMessage(messageId, glow = true) {
      if (!messageId) {
        // hide overlay if visible
        this.showInitOverlay = false;
        throw new Error('Message ID not set');
      }
      // temporary message id's are not allowed
      if (!isInt(messageId)) {
        // hide overlay if visible
        this.showInitOverlay = false;
        return;
      }

      if (!this.thread.getLoadedMessageById(messageId)) {
        // v2 : set last read to last loaded message (on serverside check if lastread > oldLastRead, otherwise ignore
        await this.loadMessagesAround({ messageId: messageId, thread: this.thread });
        // reset infinite-scroll
        this.resetCounter++;
      }

      // hide overlay if visible
      this.showInitOverlay = false;

      this.$nextTick(() => {
        const msgEl = document.querySelector('#message-' + messageId);
        if (!msgEl) {
          return;
        }

        msgEl.scrollIntoView({ block: 'start', behavior: 'auto' });

        if (glow) {
          msgEl.classList.remove('bg-grey-once');
          setTimeout(() => {
            // TODO: use Observer to see when message is visible
            msgEl.classList.add('bg-grey-once');
            setTimeout(() => {
              msgEl.classList.remove('bg-grey-once');
            }, 1000); // animation duration is 1 sec
          }, 1);
        }
      });
    },
    goToFirstUnreadOrBottom() {
      // TODO rl V2: update firstUnreadMessageId realtime. V1 only sets from server on init
      if (this.thread.firstUnreadMessageId) {
        this.goToMessage(this.thread.firstUnreadMessageId, false);
      } else {
        this.goToBottom(false);
      }
    },
    goToBottom(glow = false) {
      if (this.thread.lastMessageId) {
        this.goToMessage(this.thread.lastMessageId, glow);
      } /* else {
                    this.scrollDown();
                }*/
    },
    scrollDown(force = false) {
      if (this.$refs.chatMessages && (force || this.shouldScrollDown()) && !this.messageId) {
        this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight - this.$refs.chatMessages.clientHeight;
      }
    },
    loadMoreMessagesUp(scroll) {
      if (this.showInitOverlay) {
        return;
      }

      let firstId = (this.thread.messages[0] || {}).id || this.thread.lastMessageId;
      if (!firstId) {
        return scroll.complete();
      }

      this.loadMessagesBefore({ thread: this.thread, messageId: firstId })
        .then((m) => {
          if (m.length) {
            scroll.loaded();
          } else {
            scroll.complete();
          }
        })
        .catch((e) => {
          console.error(e);
          scroll.error();
        })
        .finally(() => {
          this.showInitOverlay = false;
        });
    },
    loadMoreMessagesDown(scroll) {
      if (this.showInitOverlay) {
        return;
      }

      let lastId = (this.thread.messages[this.thread.messages.length - 1] || {}).id || this.thread.lastMessageId;
      if (!lastId) {
        this.thread.setLastRead(); // v2: last loaded message timestamp
        return scroll.complete();
      }

      // race condition, or user is offline after sending a message. ignore scrolldown until we have a real message ID
      if (!window.isInt(lastId)) {
        return;
      }

      this.loadMessagesAfter({ thread: this.thread, messageId: lastId })
        .then((m) => {
          if (m.length) {
            scroll.loaded();
          } else {
            scroll.complete();
          }
        })
        .catch((e) => {
          console.error(e);
          scroll.error();
        })
        .finally(() => {
          this.showInitOverlay = false;
          this.thread.setLastRead(); // v2: last loaded message timestamp
        });
    },
    leaveThread() {
      if (!this.thread.isGroup()) {
        return;
      }

      let threadOwner = this.thread.participants.find((p) => p.id !== this.currentUser.id && p.owner === true);

      if (!threadOwner) {
        this.flashError(this.$t('internal_chat.error_group_one_owner'));
      } else {
        this.thread.leave();
        this.$router.push('/chat');
      }
    },
    async toggleSideBarState() {
      if (window.innerWidth > 991) {
        if ((await this.$tStorage.getItem('sidebar_thread_state')) === true) {
          this.sidebarState = true;
        }
      } else if (window.innerWidth <= 991) {
        this.sidebarState = false;
      }
    },
    toggleSidebar() {
      if (window.innerWidth > 991) {
        this.sidebarState = !this.sidebarState;
      }
      eventBus.$emit('chat@TOGGLE_SIDEBAR');
    },
    createTrengoVideoRoom() {
      let popup;
      if (!window.isLoadedFromApp && !window.isElectron) {
        popup = window.open();
      }

      axios
        .post('/api/v2/video_calling/rooms', {
          callable_type: 'chat_thread',
          callable_id: this.thread.id,
          to_user_id: this.thread.isUser() && !this.thread.id ? this.thread.getUserId() : undefined,
        })
        .then((r) => {
          if (r.data?.status === 'ended') {
            this.flashWarning('This meeting has ended, please start a new meeting.');
            popup.close();
            return;
          }

          if (window.isLoadedFromApp) {
            parent.postMessage(
              'app-browse-url:' + encodeURI(`${window.VIDEO_CALLING_URL}/${r.data?.participant_token}`),
              '*'
            );
          } else if (window.isElectron) {
            shell.openExternal(`${window.VIDEO_CALLING_URL}/${r.data?.participant_token}`);
          } else {
            popup.location = `${window.VIDEO_CALLING_URL}/${r.data?.participant_token}`;
          }
        });
    },
    startZoomMeeting(first_attempt = true, redirect_to_authenticate = true) {
      this.flashSuccess('Generating Zoom Meeting..');
      let to_user_id = this.thread.isUser() ? this.thread.getUserId() : null;
      axios
        .post('/api/v2/integrations/zoom/meetings', {
          thread: this.thread.identifier,
          thread_id: this.thread.id,
          to_user_id: to_user_id,
        })
        .then((res) => {
          if (!res.data.authenticated && res.data.redirect_url && redirect_to_authenticate) {
            window.location.href = res.data.redirect_url;
          }
          if (res.data.error) {
            if (first_attempt) {
              this.startZoomMeeting(false);
            } else {
              alert(this.$t('internal_chat.error_setting_up_zoom'));
            }
          }
        });
    },
    handleZoomAuthentication() {
      if (!this.$route.query.zoom) {
        return;
      }
      this.$router.replace({});
      this.startZoomMeeting(false, false);
    },
    isYesterday(date) {
      return moment(date).format('LL') === moment().subtract(1, 'day').format('LL');
    },
    isToday(date) {
      return moment(date).format('LL') === moment().format('LL');
    },
    dayDividerText(date) {
      if (this.isToday(date)) {
        return this.$t('general.today');
      }
      if (this.isYesterday(date)) {
        return this.$t('general.yesterday');
      }
      return moment(date).format('dddd, LL');
    },
    ...mapActions({
      setCurrentThread: 'chat/setCurrentThread',
      loadMessagesBefore: 'chat/loadMessagesBefore',
      loadMessagesAfter: 'chat/loadMessagesAfter',
      loadMessagesAround: 'chat/loadMessagesAround',
    }),
  },
};
</script>

<style lang="scss">
.thread-groups i.collapse_icon,
.thread-users i.collapse_icon {
  opacity: 1;
}

.thread-header-wrapper {
  height: 72px;
  min-height: 72px;
  z-index: 98;
  .title {
    max-width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.thread-header-inner {
  padding-bottom: 3px;
  margin-top: 11px;
}
.thread-header-icon {
  font-size: 22px;
  margin-left: 9px;
  margin-right: 6px;
}
.thread-header-online-status {
  margin-left: 11px;
  margin-right: 6px;
  margin-bottom: -2px;
}
.thread-options {
  margin-top: 6px;
}
.thread-dropdown {
  li {
    display: flex;
    align-items: center;
  }
}
.thread-typing-container {
  min-height: 22px;
  margin-top: -15px;
}

// https://blog.eqrion.net/pin-to-bottom/
.chat-messages * {
  /* don't allow the children of the scrollable element to be selected as an anchor node */
  overflow-anchor: none;
}
.chat-messages::after {
  content: '';
  display: block;
  /* allow the final child to be selected as an anchor node */
  overflow-anchor: auto;
  /* anchor nodes are required to have non-zero area */
  height: auto;
}
.chat-messages-overlay {
  position: absolute;
  z-index: 999999;
  top: 72px;
  right: 0;
  left: 0;
  bottom: 121px;
  margin: 0;
}
.md-hidden-down {
  display: block;
}
.dropdown-noborder {
  li,
  a {
    border: 0;
  }
}

@media (max-width: 991px) {
  .chat-messages-overlay {
    top: 63px;
    bottom: 94px;
  }
  .thread-header-wrapper {
    height: 63px;
    min-height: 63px;
    max-height: 63px;
    padding-left: 61px;
  }
  .thread-header-inner {
    padding-bottom: 0;
    margin-top: -3px;
  }
  .thread-header-icon {
    margin-top: 1px;
    margin-left: -1px;
  }
  .thread-header-online-status {
    margin-left: 1px;
  }
  .thread-options {
    margin-top: -1px;
  }
  .thread-typing-container {
    position: absolute;
    bottom: 94px;
    margin: 0;
    min-height: auto;
  }
  .md-hidden-down {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.sidebar-overlay {
  z-index: 999;
  margin-top: 61px;
  border-top: solid theme('colors.grey-200') 2px;
}
.tt-search-box {
  margin-top: 1rem;
  height: 46px;
  input::placeholder {
    color: theme('colors.grey-600');
    opacity: 0.8;
  }
}
.tt-search-box-threads {
  cursor: text;
  height: 35px;
  margin: 0.5rem 0 1.25rem 0;
}
@media (max-width: 800px) {
  .thread-messages {
    .scroll-on-hover {
      overflow-y: overlay !important;
      padding-right: 16px;
      &:hover {
        padding-right: 16px;
      }
    }
  }
}
</style>
