import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f24f0cee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t-text-h3" }
const _hoisted_2 = { class: "mt-2 flex w-full flex-row items-center justify-between pb-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "t-text-sm m-0 text-grey-600" }
const _hoisted_5 = {
  class: "mt-[3px] w-full",
  "data-test": "overview"
}
const _hoisted_6 = { class: "reporting-wrapper px-6 pb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_dropdown = _resolveComponent("filter-dropdown")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_settings_base_layout = _resolveComponent("settings-base-layout")!
  const _component_mobile_status = _resolveComponent("mobile-status")!

  return (!_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_settings_base_layout, {
        key: 0,
        class: "reporting-container h-full overflow-auto bg-grey-100",
        "data-test": "reporting-layout"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_main_header, { "max-width": "1440px" }, {
            left: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.headingTitle), 1 /* TEXT */)
            ]),
            bottom: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_filter_dropdown),
                _renderSlot(_ctx.$slots, "right", {}, () => [
                  (_ctx.updatedAt)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.updatedAt), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true)
                ], true)
              ])
            ]),
            _: 3 /* FORWARDED */
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ])
        ]),
        _: 3 /* FORWARDED */
      }))
    : (_openBlock(), _createBlock(_component_mobile_status, {
        key: 1,
        "data-test": "reporting-mobile"
      }))
}