// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'moment-timezone';
import './util';
import 'froala-editor';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'jquery.growl';
// this is causing error, importing from node_modules seems to work, only the css is not loaded yet
//import '../assets/vendor/jquery.growl/jquery.growl.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/affix';
import 'bootstrap-sass/assets/javascripts/bootstrap/button';
import 'bootstrap-sass/assets/javascripts/bootstrap/alert';
import 'bootstrap-sass/assets/javascripts/bootstrap/carousel';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal';
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';
import 'bootstrap-sass/assets/javascripts/bootstrap/popover';
import 'bootstrap-sass/assets/javascripts/bootstrap/scrollspy';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition';
import '../assets/vendor/bootstrap-colorselector/bootstrap-colorselector.js';
import '../assets/vendor/bootstrap-datetimepicker/bootstrap-datetimepicker.js';
import '../assets/vendor/bootstrap-timepicker/bootstrap-timepicker.js';
import '../assets/vendor/offline-js/offline.min.js';
import '../assets/vendor/jquery-editable/js/bootstrap-editable.js';
import '../assets/vendor/multiside-select/multiselect.js';
import '../assets/vendor/desktop-notifications.js';
import '../assets/vendor/telinput/intlTelInput.js';
import 'daterangepicker';
import 'chosen-js';
import 'bootstrap-colorpicker';
import 'select2';
import '../util/recorder';
import '../assets/sass/app.scss';

import Axios from 'axios';
import jquery from 'jquery';
import Lodash from 'lodash';
import moment from 'moment';
import PusherJs from 'pusher-js';
import swal from 'sweetalert2';
import Tether from 'tether';
import Vue from 'vue';

global.$ = global.jQuery = jquery;

const modal = require('bootstrap-sass/assets/javascripts/bootstrap/modal');
const tooltip = require('bootstrap-sass/assets/javascripts/bootstrap/tooltip');

window.moment = moment;
window.modal = modal;
window.tooltip = tooltip;
window.Vue = Vue;
//window.io = require('socket.io-client'); // todo remove.
window.pusher = PusherJs;
window._ = Lodash;
window.Tether = Tether;
window.axios = Axios;
window.swal = swal;

if (
  navigator.appName == 'Microsoft Internet Explorer' ||
  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) ||
  (typeof $.browser !== 'undefined' && $.browser.msie == 1)
) {
  $('body').addClass('ie');
}

$.ajaxSetup({
  beforeSend: function (jqXHR, settings) {
    jqXHR.url = settings.url;
  },
  complete: function (jqXHR) {
    if (jqXHR.status == 401) {
      window.location.href = '/auth/login';
    }
  },
  headers: {
    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content'),
  },
});

$(document).ajaxComplete(function (e, xhr) {
  try {
    if (
      xhr.responseJSON &&
      xhr.responseJSON.trengo_reload_after_update &&
      window.location.href.indexOf('?reload') === -1
    ) {
      window.location.href = window.location.href.split('?')[0] + '?reload=true&t=' + new Date().getTime();
    }
  } catch (e) {}
});
