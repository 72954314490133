<template>
  <div
    ref="backdrop"
    class="trengo-modal-chat trengo-modal-input flex items-center justify-center md:p-8"
    @click.self="close"
  >
    <div
      class="
        box
        custom-shadow-outline
        chat-modal
        relative
        flex
        h-full
        w-full
        flex-col
        rounded-lg
        pb-6
        lg:h-auto lg:w-auto lg:pb-8
      "
      tabindex="0"
      @keydown.esc="close"
    >
      <div class="modal-header">
        <h3
          v-if="title"
          class="mb-0 select-none text-ellipsis px-8 text-center"
          style="font-size: 18px; line-height: 1.5"
        >
          {{ title }}
        </h3>
        <button type="button" class="modal-close-btn leading-none" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px" @click="$emit('close')">close</i>
        </button>
      </div>

      <div class="flex h-full flex-col px-4 md:h-auto lg:px-8">
        <select v-if="select" v-model="output" class="form-control">
          <option v-for="obj in select" :value="obj.value">{{ obj.text }}</option>
        </select>

        <multiselect
          v-if="threadList"
          v-model="output"
          class="select-none"
          :show-pointer="false"
          :placeholder="placeholder"
          :options="options"
          :multiple="multiple"
          :close-on-select="closeOnSelect"
          :allow-empty="false"
          label="value"
          track-by="value"
        >
          <template #noResult>
            <span>{{ $t('internal_chat.no_results_found') }}</span>
          </template>
          <template #option="{ option }">
            <template v-if="option.isUser">
              <avatar
                class="mr-2"
                font-size="11px"
                width="24"
                :abbr="option.abbr"
                :image="option.profileImage"
                :color="option.color"
              ></avatar>
              <span>{{ option.label }}</span>
            </template>
            <template v-else-if="option.isGroup">
              <i class="material-icons md-24 valign-center mr-2">people</i>
              <span>{{ option.label }}</span>
            </template>
          </template>
        </multiselect>

        <chat-composer
          v-else-if="rich"
          :placeholder="$t('internal_chat.edit_message_message_placeholder')"
          class="chat-composer-edit"
          :edit-message="this.output"
          @composerInit="onComposerInit"
        ></chat-composer>
        <textarea v-else-if="multiLine" v-model="output" class="form-control"></textarea>
        <input v-else v-model="output" type="text" class="form-control" />
        <button class="btn btn-succes success mt-3 block sm:inline-block" @click="emit">{{ buttonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import Avatar from '../../../Avatar';
import ChatComposer from '../ChatComposer';

export default {
  name: 'InputModal',
  emits: ['update:modelValue', 'close'],
  components: { Avatar, Multiselect, ChatComposer },
  data() {
    return {
      output: this.modelValue,
      editor: null,
    };
  },

  props: {
    placeholder: { type: String, default: '' },
    modelValue: { default: '', type: [String, Object] },
    title: { default: '', type: String },
    buttonText: { default: '', type: String },
    multiLine: { default: false, type: Boolean },
    rich: { default: false, type: Boolean },
    select: { default: null, type: Array },
    threadList: { default: null, type: Boolean },
    options: { default: null, type: Array },
    multiple: { default: true, type: Boolean },
    closeOnSelect: { default: false, type: Boolean },
  },

  methods: {
    onComposerInit(editor) {
      this.editor = editor;
    },
    close() {
      this.$emit('close');
    },
    emit() {
      if (this.editor) {
        this.output = this.editor.html.get();
      }
      this.$emit('update:modelValue', typeof this.output === 'object' ? this.output.value : this.output);
    },
  },
};
</script>

<style lang="scss">
.trengo-modal-chat {
  /*.multiselect__content-wrapper {
            z-index: 99999;
        }*/

  .multiselect__content {
    max-width: 360px;
  }
  .multiselect__option {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background: #f1f1f1;
      color: #35495e;
    }
  }
  .multiselect__tags {
    border: solid #ebedf5 2px;
    border-radius: 10px;
    cursor: text;
  }
  .multiselect__input {
    color: theme('colors.grey-600');
    font-size: 16px;
  }
}
@media (max-width: 543px) {
  .trengo-modal-chat {
    .multiselect__content {
      max-width: 280px;
    }
  }
}
</style>

<style scoped lang="scss">
body {
  overflow-y: auto;
}
.trengo-modal-chat {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;

  .modal-header {
    border-bottom: 0 !important;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    min-width: 525px;
    max-width: 991px;
    border-radius: 10px;
    border: none;
    max-height: 100%;
    /*overflow: hidden;*/

    h2 {
      font-size: 300%;
      font-weight: 600;
    }

    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

@media (max-width: 767px) {
  .trengo-modal-chat {
    .box {
      width: 100%;
      min-width: 100%;
      border-radius: 0;
    }
  }
}
</style>
