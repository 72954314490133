<script>
import { isEmpty, has } from 'lodash';

import NoteRedesign from '@/components/MessageFeed/Note';
import PrechatMessage from '@/components/MessageFeed/PrechatMessage.vue';
import WhatsappDeliveryStatus from '@/components/MessageFeed/WhatsappDeliveryStatus.vue';
import { PERMISSION, FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { TEMPLATE_HEADER_TYPE, TEMPLATE_FORM_CONTENTS } from '@/Configs/Constants/whatsapp';
import { useFeatureFlagStore } from '@/store/pinia';
import downloadFile from '@/util/downloadHelper';
import { openLink } from '@/util/helpers';
import { isOnlyEmoji, escapeHtml } from '@/util/stringHelpers';

import Avatar from './Avatar';
import ChatMessageActions from './ChatMessageActions';
import ChatMessageReactions from './ChatMessageReactions';
import ImageMessage from './ImageMessage.vue';
import InstagramStory from './InstagramStory.vue';
import MessageAttachments from './MessageAttachments';
import Note from './Note';
export default {
  emits: ['deleteTicketMessage', 'loaded', 'mark-previous-tasks', 'deleteMessage'],
  props: {
    message: {
      type: Object,
      required: true,
    },
    iteration: {
      type: Number,
      default: 0,
    },
    ticket: {
      type: Object,
      required: true,
    },
    publicProvider: {
      type: String,
      default: null,
    },
    replyTo: {
      type: Object,
      default: null,
    },
    isLatestInbound: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    WhatsappDeliveryStatus,
    PrechatMessage,
    MessageAttachments,
    ChatMessageActions,
    ChatMessageReactions,
    Avatar,
    InstagramStory,
    ImageMessage,
    Note,
    NoteRedesign,
  },

  data() {
    return {
      noteDeleteVisible: false,
      lastPlaybackSpeed: null,
      audioError: false,
      PERMISSION,
    };
  },

  methods: {
    isOnlyEmoji,
    async download(url) {
      // twilio will cause cors error, so open directly for twilio links
      if (url.includes('twilio.com')) {
        openLink(url);
        return;
      }
      try {
        await downloadFile(url, 'call_log.wav');
      } catch (e) {
        openLink(url);
      }
    },
    getInstagramMessageTitle(message) {
      if (message.body_type === 'IG_STORY') {
        return message.meta.mentioned
          ? this.$t('notification.notifications_chatmessage_mentioned_you_in_a_story')
          : this.$t('notification.notifications_chatmessage_reacted_to_your_story');
      } else if (message.body_type === 'IG_SHARE') {
        return this.$t('notification.notifications_chatmessage_shared_you_a_post');
      }
    },
    imageLoaded(e) {
      this.$emit('loaded');
    },

    getFileUrl: function (message) {
      // Support for absolute URL's (instead of relative path names to S3)
      if (message.meta && message.meta.file_url) {
        return message.meta.file_url;
      }

      return CDN + 'media/' + message.file_name;
    },

    googleUrl: function (data) {
      return 'https://www.google.com/maps?q=' + data.lat + ',' + data.lng;
    },

    getChatMessage: function (msg) {
      if (msg == null) {
        return null;
      }

      msg = escapeHtml(msg);

      msg = window.linkifyHtml(msg, {
        defaultProtocol: 'http',
        target: msg.indexOf('/tickets/') == -1 ? '_blank' : '_self',
        className: window.isLoadedFromApp ? 'open-external' : '',
      });

      msg = msg.replace(/\n/g, '<br />');

      return msg;
    },

    formatVoipDuration(secs) {
      return window.toHHMMSS(secs);
    },

    openInPopup(url, e = null) {
      if (!window.isElectron) {
        if (e) {
          e.preventDefault();
        }
        window.PopupCenter(this.safeUrl(url), 'Pop-up', 1020, 700);
      } else {
        window.open(this.safeUrl(url), '_blank', 'noreferrer,noopener');
      }
    },
    openParentInWindow() {
      let url = this.message.parent?.meta.meta.link ? this.message.parent?.meta.meta.link : this.message.meta.link;
      this.openInPopup(this.safeUrl(url));
    },
    setPlaybackSpeed(iteration, speed) {
      if (!speed) {
        speed = this.$refs['call_log_' + iteration].playbackRate;
      }
      this.$refs['call_log_' + iteration].playbackRate = speed;
      this.$refs['call_log_' + iteration].play();
      if (this.lastPlaybackSpeed) {
        this.$refs[this.lastPlaybackSpeed][0].classList.remove('active_audio_speed');
      }
      this.lastPlaybackSpeed = 'call_log_' + iteration + '_speed_' + speed;
      this.$refs[this.lastPlaybackSpeed][0].classList.add('active_audio_speed');
    },

    deleteTicketMessage(ticket_id) {
      this.$emit('deleteTicketMessage', ticket_id);
    },
  },

  computed: {
    messageStatusesEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.MESSAGE_DELIVERY_STATUS);
    },
    messageContainerClasses() {
      return {
        'text-grey-600 !bg-grey-200 !border-grey-300':
          this.message.type === 'OUTBOUND' && this.message.delivery_status === 'FAILED',
        'bg-green-lightest': this.message.type === 'OUTBOUND' && this.message.delivery_status !== 'FAILED',
        'bg-white': this.message.type !== 'OUTBOUND',
        'text-grey-800': this.message.type === 'OUTBOUND' && this.message.delivery_status !== 'FAILED',
        'pr-12': this.message.type === 'OUTBOUND',
        'pl-12': this.message.type === 'INBOUND',
        'border-grey-200':
          this.message.type === 'INBOUND' &&
          (this.message.body_type === 'TEXT' ||
            this.message.body_type === 'IG_STORY' ||
            this.message.body_type === 'IG_SHARE' ||
            this.message.body_type === 'LOCATION' ||
            this.message.body_type === 'FILE' ||
            this.message.body_type === 'AUDIO'),
        'b-2x':
          this.message.body_type === 'TEXT' ||
          this.message.body_type === 'BUTTONS' ||
          this.message.body_type === 'COMPONENTS' ||
          this.message.body_type === 'IG_STORY' ||
          this.message.body_type === 'IG_SHARE' ||
          this.message.body_type === 'EMAIL_CAPTURE' ||
          this.message.body_type === 'LOCATION' ||
          this.message.body_type === 'FILE' ||
          this.message.body_type === 'AUDIO',
        'py-4 px-4':
          this.message.body_type === 'TEXT' ||
          this.message.body_type === 'IG_STORY' ||
          this.message.body_type === 'IG_SHARE' ||
          this.message.body_type === 'FILE' ||
          this.message.body_type === 'EMAIL_CAPTURE' ||
          this.message.body_type === 'AUDIO' ||
          this.message.body_type === 'BUTTONS' ||
          this.message.body_type === 'COMPONENTS' ||
          this.message.body_type === 'LOCATION',
        'leading-normal': true,
        inline: true,
        'border-green-lighter':
          (this.message.type === 'OUTBOUND' && this.message.body_type === 'TEXT') ||
          this.message.body_type === 'BUTTONS' ||
          this.message.body_type === 'COMPONENTS' ||
          this.message.body_type === 'EMAIL_CAPTURE' ||
          this.message.body_type === 'FILE',
        'opacity-75': this.message.composing,

        'b-2x border-grey-200 pr-2 pb-2 pt-8 pl-2': this.message.body_type === 'CALL_LOG',
      };
    },

    avatarClasses() {
      return {
        'avatar-left': this.message.type === 'INBOUND',
        'avatar-right': this.message.type === 'OUTBOUND',
      };
    },
    avatarContainerClasses() {
      return {
        'avatar-container-left': this.message.type === 'INBOUND',
        'avatar-container-right': this.message.type === 'OUTBOUND',
      };
    },

    showMessageActions() {
      return (
        (this.ticket.channelMeta && this.ticket.channelMeta.type === 'FACEBOOK_FEED_MESSAGES') ||
        (this.ticket.channelMeta && this.ticket.channelMeta.type === 'FACEBOOK_PAGE_MENTIONS') ||
        (this.ticket.channelMeta && this.ticket.channelMeta.type === 'INSTAGRAM_COMMENTS')
      );
    },

    showMessageReactions() {
      return this.message.reactionSums && this.message.reactionSums.filter((r) => r.total_count > 0).length > 0;
    },

    from() {
      if (!this.ticket.channel) {
        return;
      }

      // Echo message
      if (
        this.publicProvider &&
        this.message.type === 'OUTBOUND' &&
        this.message.contact &&
        this.message.user_id === null
      ) {
        if (this.message.contact.profile_image !== 'undefined') {
          this.message.contact.image_path = this.message.contact.profile_image;
        }
        return this.message.contact;
      }

      // Chatbot
      if (this.message.type === 'OUTBOUND' && this.message.user_id == null) {
        return {
          abbr: null,
          color: null,
          image_path: this.$root.assetsURL + 'img/chatbot.png',
        };
      }

      // Echo message
      if (
        (this.ticket.channel.type === 'FACEBOOK' || this.publicProvider === 'INSTAGRAM') &&
        this.message.type === 'OUTBOUND' &&
        this.message.contact &&
        this.message.user_id === null
      ) {
        if (this.message.contact.profile_image !== 'undefined') {
          this.message.contact.image_path = this.message.contact.profile_image;
        }
        return this.message.contact;
      }

      // Deleted agent
      if (this.message.type === 'OUTBOUND' && this.message.user_id !== null && this.message.agent == null) {
        return {
          abbr: 'T',
          color: '#5bb130',
          image_path: null,
        };
      }

      // Origins from contact
      if (this.message.type === 'INBOUND') {
        this.message.contact.image_path = this.message.contact.profile_image;
        return this.message.contact;
      }

      // Origins from user (agent)
      if (this.message.agent && (this.message.type === 'OUTBOUND' || this.message.type === 'NOTE')) {
        this.message.agent.image_path = this.message.agent.profile_image;
        return this.message.agent;
      }

      return {
        full_name: null,
        abbr: null,
        color: null,
        image_path: null,
      };
    },

    fromParent() {
      if (this.message.parent?.contact) {
        if (this.message.parent?.contact.profile_image !== null) {
          this.message.parent.contact.image_path = this.message.parent.contact.profile_image;
        }
        return this.message.parent?.contact;
      }

      if (this.message.parent?.agent) {
        return this.message.parent?.agent;
      }

      return {
        full_name: null,
        abbr: null,
        color: null,
        image_path: null,
      };
    },

    messageHasTemplateFooterText() {
      return has(this.message.meta, TEMPLATE_FORM_CONTENTS.FOOTER) && !isEmpty(this.message.meta.footer);
    },

    messageHasTemplateHeaderText() {
      return has(this.message.meta.header, TEMPLATE_HEADER_TYPE.TEXT) && !isEmpty(this.message.meta.header);
    },
    messageHasTemplateHeaderImage() {
      return has(this.message.meta.header, TEMPLATE_HEADER_TYPE.IMAGE);
    },

    messageHasComponentBodyText() {
      return !isEmpty(this.message.message);
    },

    waComponentButton() {
      return this.message.meta?.buttons;
    },

    messaageHasGuidingText() {
      return !isEmpty(this.message.meta.button_guiding_text);
    },

    isFeedUpdateEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.COMMENT_FEED_REDESIGN);
    },

    isPrechatFormMessage() {
      return this.message.type === 'NOTE' && !this.message.user_id && !this.message.is_auto_reply;
    },
  },
};
</script>

<template>
  <div class="p-b-md ml-3 lg:ml-0" style="word-break: break-word" data-test="chatMessageWrapper">
    <note
      v-if="!isFeedUpdateEnabled && message.type === 'NOTE'"
      :message="message"
      @mark-previous-tasks="$emit('mark-previous-tasks')"
      @deleteMessage="$emit('deleteMessage')"
    />
    <prechat-message v-else-if="isPrechatFormMessage" :message="message" />
    <note-redesign v-else-if="message.type === 'NOTE'" :message="message" @deleteTicketMessage="deleteTicketMessage" />

    <div v-else class="flex" :class="{ 'justify-end text-right': message.type === 'OUTBOUND' }">
      <div
        class="flex-col overflow-hidden rounded-lg"
        :class="{ 'chat-message-wrapper': isFeedUpdateEnabled }"
        :style="{ 'max-width: 80%': !isFeedUpdateEnabled }"
      >
        <div class="relative rounded-lg text-left" :class="messageContainerClasses">
          <div class="overflow-hidden" :class="avatarContainerClasses">
            <avatar
              v-if="from"
              :border="message.type === 'OUTBOUND'"
              data-hj-suppress
              width="45"
              :class="avatarClasses"
              :color="from.color"
              :abbr="from.abbr"
              :image="from.image_path"
            ></avatar>
          </div>

          <div class="inline-flex max-w-[474px] flex-col" data-hj-suppress>
            <i v-show="message.sending" class="fa fa-spinner fa-spin text-success" style="margin-right: 5px"></i>
            <div v-if="message.body_type === 'COMPONENTS' && message.meta" class="message-container flex">
              <div class="buttons outbound">
                <div style="display: block; width: 100%">
                  <p v-if="messageHasTemplateHeaderText" class="t-text-md mb-1 text-grey-900">
                    {{ message.meta.header.text }}
                  </p>
                  <image-message
                    v-if="messageHasTemplateHeaderImage"
                    :path="getFileUrl(message)"
                    custom-max-width="100%"
                    :class="{ 'image-with-parent-message': message.parent }"
                    class="mb-2 block"
                  ></image-message>
                  <div v-if="messageHasComponentBodyText">
                    <p class="mb-0 text-sm">{{ message.message }}</p>
                  </div>
                  <p v-if="messageHasTemplateFooterText" class="t-text-xs mb-0 mt-2 text-grey-600">
                    {{ message.meta.footer.text }}
                  </p>
                  <ul v-if="waComponentButton.length" class="template-button mb-0 mt-6 list-none p-0">
                    <li
                      v-for="button in waComponentButton"
                      class="t-text-sm-emphasize mx-0 flex h-9 items-center justify-center border-t border-solid p-3"
                    >
                      {{ button.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-if="message.body_type === 'BUTTONS' && message.meta" class="message-container flex">
              <div class="buttons outbound">
                <div style="text-align: center; display: block; width: 100%">
                  <div v-if="message.meta && messaageHasGuidingText" class="mb-2 ml-2 text-sm text-grey-600">
                    {{ message.meta.button_guiding_text }}
                  </div>
                  <template v-for="button in message.meta.buttons">
                    <span class="message-button mb-2 mr-2 inline-block border bg-white text-grey-800">
                      {{ button.text }}
                    </span>
                  </template>
                </div>
              </div>
            </div>

            <div
              v-else
              :class="{
                'opacity-75': message.composing,
                '-mr-12':
                  message.body_type !== 'TEXT' &&
                  message.body_type !== 'FILE' &&
                  message.body_type !== 'AUDIO' &&
                  message.body_type !== 'CALL_LOG' &&
                  message.body_type !== 'EMAIL_CAPTURE' &&
                  message.type === 'OUTBOUND',
                '-ml-12':
                  message.body_type !== 'TEXT' &&
                  message.body_type !== 'FILE' &&
                  message.body_type !== 'AUDIO' &&
                  message.body_type !== 'CALL_LOG' &&
                  message.body_type !== 'LOCATION' &&
                  message.body_type !== 'IG_STORY' &&
                  message.body_type !== 'IG_SHARE' &&
                  message.type === 'INBOUND',
                '': message.body_type === 'IMAGE' && message.type === 'OUTBOUND',
              }"
            >
              <div
                v-if="message.parent"
                class="pointer mb-2 flex overflow-hidden rounded-lg p-1 p-4 pr-0 pt-0"
                :class="{
                  '-mr-0  bg-white': message.type === 'OUTBOUND',
                  '-ml-8 bg-grey-200 pl-8': message.type === 'INBOUND',
                  'green-lightest':
                    (message.type === 'OUTBOUND' && message.body_type !== 'COMPONENTS') ||
                    (message.type === 'OUTBOUND' && message.body_type !== 'BUTTONS'),
                  'bg-grey-200': message.type === 'INBOUND',
                  'py-1': !message.message,
                }"
                style="align-items: center"
                @click="openParentInWindow()"
              >
                <div class="flex">
                  <div class="pt-4">
                    <div class="text-ellipsis text-sm text-grey-500">
                      {{ fromParent.full_name }} -
                      <datetime :time="message.parent.created_at" :pretty="$root.prettyDates" />
                    </div>
                    <div class="text-grey-600">{{ message.parent.message }}</div>
                  </div>
                  <avatar
                    class="-mr-1 -mt-1 ml-4 rounded"
                    :border="message.type === 'OUTBOUND'"
                    width="40"
                    :color="fromParent.color"
                    :abbr="fromParent.abbr"
                    :image="fromParent.image_path"
                  ></avatar>
                </div>
              </div>

              <i
                v-if="message.parent"
                v-show="message.type === 'INBOUND'"
                class="material-icons text-grey absolute mt-1 text-sm"
                style="margin-left: -20px; transform: rotateX(180deg); margin-top: 8px; font-size: 14px"
              >
                redo
              </i>

              <div v-if="message.body_type === 'IG_STORY' || message.body_type === 'IG_SHARE'">
                <instagram-story
                  :media-url="message.meta.file_url"
                  :video-thumbnail-url="message.meta.video_thumbnail_url"
                  :file-content-type="message.meta.file_content_type"
                >
                  <template #header>
                    <div
                      class="mb-2 text-ellipsis text-sm text-grey-600 md:pr-10"
                      v-text="getInstagramMessageTitle(message)"
                    ></div>
                  </template>
                  <template #errorMessage>
                    <div>
                      <template v-if="message.body_type === 'IG_STORY'">Story unavailable</template>
                      <template v-else-if="message.body_type === 'IG_SHARE'">Shared post unavailable</template>
                    </div>
                  </template>
                </instagram-story>
                <div
                  v-if="!message.meta.mentioned && message.body_type !== 'IG_SHARE'"
                  class="mt-4"
                  v-html="getChatMessage(message.message)"
                ></div>
              </div>

              <div v-if="isOnlyEmoji(message.message) && !message.composing" style="font-size: 36px">
                {{ message.message }}
              </div>

              <div v-else-if="message.body_type === 'TEXT' && !message.composing">
                <p class="mb-0" v-html="getChatMessage(message.message)"></p>
              </div>

              <div v-if="message.composing" class="flex items-center">
                <span class="loading flex-shrink-0">
                  <span class="dot one"></span>
                  <span class="dot two"></span>
                  <span class="dot three"></span>
                </span>
                <div class="font-italic">{{ message.message }}</div>
              </div>
              <template v-if="message.body_type === 'IMAGE'">
                <image-message
                  :path="getFileUrl(message)"
                  :caption="message.file_caption"
                  :class="{ 'image-with-parent-message': message.parent }"
                ></image-message>
              </template>

              <div v-if="message.body_type === 'FILE'" class="flex flex-col leading-none">
                <div class="flex items-center leading-none">
                  <i class="material-icons">attach_file</i>
                  <a target="_blank" :href="getFileUrl(message)" class="open-external">{{ message.file_name }}</a>
                </div>
                <div v-if="message.file_caption" class="flex-shrink-0 pt-4">{{ message.file_caption }}</div>
              </div>
              <div v-if="message.body_type === 'AUDIO'" class="flex flex-col leading-none">
                <audio v-if="!audioError" controls @error.capture="audioError = true">
                  <source :src="getFileUrl(message)" />
                </audio>
                <div v-else>
                  <div class="mb-4 text-center text-red">Unable to play file</div>
                  <a
                    target="_blank"
                    download
                    class="open-external btn btn-default"
                    :href="getFileUrl(message)"
                    rel="noopener"
                  >
                    <i class="far fa-download mr-2"></i>
                    Download file
                  </a>
                </div>
                <div v-if="message.file_caption" class="flex-shrink-0 pt-4">{{ message.file_caption }}</div>
              </div>
              <div v-if="message.body_type === 'VIDEO'">
                <video :src="getFileUrl(message)" controls style="max-width: 100%"></video>
                <div v-if="message.file_caption" class="p-4 pt-2">{{ message.file_caption }}</div>
              </div>
              <div v-if="message.body_type === 'VIDEO_GIF'">
                <span class="pull-left msg-type">
                  <video :src="getFileUrl(message)" autoplay loop style="max-width: 100%; border-radius: 10px"></video>
                  <div v-if="message.file_caption">{{ message.file_caption }}</div>
                </span>
              </div>
              <div v-if="message.body_type === 'LOCATION'" class="flex items-center">
                <i class="fa fa-map-marker fa-2x pull-left text-grey-600"></i>
                <a target="_blank" :href="googleUrl(message.location_data)" class="open-external">Location</a>
                {{ message.file_caption }}
              </div>
              <div v-if="message.body_type === 'EMAIL_CAPTURE'">
                {{ message.message }}
              </div>
              <div v-if="message.body_type === 'CALL_LOG'" class="my-2">
                <span class="">
                  <div v-show="message.file_name != null">
                    <audio
                      :ref="'call_log_' + iteration"
                      controls
                      controlsList="nodownload noplaybackrate"
                      @playing="setPlaybackSpeed(iteration)"
                    >
                      <source :src="message.file_name" type="audio/mpeg" />
                    </audio>
                    <br />
                    <div class="mb-2 mt-1">
                      <a
                        v-for="playbackSpeed in [1, 1.25, 1.5, 2]"
                        :ref="'call_log_' + iteration + '_speed_' + playbackSpeed"
                        href="#"
                        class="audio-speed-control btn btn-xs mr-3 rounded"
                        @click.prevent="setPlaybackSpeed(iteration, playbackSpeed)"
                      >
                        <i class="material-icons md-18">play_arrow</i>
                        <span class="audio-speed-text">{{ playbackSpeed }}x</span>
                      </a>
                      <div class="audio-speed-control btn btn-xs mr-3 rounded" @click="download(message.file_name)">
                        <i class="far fa-download mx-2 my-1"></i>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    {{ message.message }}
                    <span v-if="message.location_data">
                      - Duration: {{ formatVoipDuration(message.location_data.duration) }}
                    </span>
                  </div>
                </span>
              </div>
              <div v-if="message.body_type === 'GIF'">
                <img :src="message.file_name" class="gif_image" alt="" @load="imageLoaded" />
              </div>
            </div>
          </div>
        </div>
        <message-attachments
          v-if="message.attachments && message.attachments.length"
          :attachments="message.attachments"
        ></message-attachments>
        <div
          v-if="!message.composing"
          class="
            mb-1
            mt-2
            flex flex-shrink-0 flex-col flex-wrap
            items-start
            text-sm
            leading-none
            text-grey-600
            lg:flex-row lg:items-center
          "
          :class="{ 'text-right': message.type === 'OUTBOUND' }"
        >
          <div class="flex flex-shrink-0 flex-nowrap items-center text-sm leading-none text-grey-600">
            <span v-if="from && from.full_name" data-hj-suppress class="flex-shrink-0">
              {{ from.full_name }}
              <span class="mx-1">-</span>
            </span>

            <datetime :time="message.created_at" :pretty="$root.prettyDates" class="flex-shrink-0" />

            <span v-if="message.meta && message.meta.link" class="flex-shrink-0">
              <span class="mx-1">-</span>
              <a
                :href="safeUrl(message.meta.link)"
                rel="noopener noreferrer"
                class="open-external underline"
                @click="openInPopup(message.meta.link, $event)"
              >
                <i class="material-icons" style="font-size: 14px; vertical-align: -3px">open_in_new</i>
              </a>
            </span>

            <span v-if="message.meta && message.meta.path" class="flex-shrink-0">
              <span class="mx-1">-</span>
              <a
                :href="safeUrl(message.meta.path.full)"
                rel="noopener noreferrer"
                class="open-external underline"
                @click="openInPopup(message.meta.path.full, $event)"
              >
                {{ message.meta.path.short }}
              </a>
            </span>

            <span
              v-if="message.meta && message.meta.automation && message.meta.automation.length"
              class="flex-shrink-0"
            >
              <span class="mx-1">-</span>
              <i class="fa fa-cogs" aria-hidden="true" title="Sent by widget automation"></i>
              <a
                v-if="$authorization().hasPermission(PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE)"
                href="#"
                class="underline"
                @click.prevent="$router.push('/admin/widget_automation/' + message.meta.automation[0].id)"
              >
                <span class="text-truncate" style="max-width: 600px; display: inline-block; vertical-align: middle">
                  {{ message.meta.automation[0].title }}
                </span>
                ({{ message.meta.automation[0].type }})
              </a>
              <span v-else>
                <span class="text-truncate" style="max-width: 600px; display: inline-block; vertical-align: middle">
                  {{ message.meta.automation[0].title }}
                </span>
                ({{ message.meta.automation[0].type }})
              </span>
            </span>
          </div>

          <span
            v-if="showMessageActions || showMessageReactions"
            class="-ml-2 mt-2 flex flex-shrink-0 flex-row flex-nowrap items-center lg:m-0"
          >
            <chat-message-actions
              v-if="showMessageActions"
              :is-latest-inbound="isLatestInbound"
              :ticket="ticket"
              :message="message"
              :public-provider="publicProvider"
              :reply-to="replyTo"
              :from-parent="fromParent"
              :from="from"
            />

            <chat-message-reactions
              v-if="showMessageReactions"
              :ticket="ticket"
              :message="message"
              :public-provider="publicProvider"
            />
          </span>

          <whatsapp-delivery-status
            v-if="messageStatusesEnabled && message.delivery_status !== null"
            :status="message.delivery_status"
          />
        </div>
        <div v-if="message.composing">
          <div class="4 mt-2 text-sm text-grey-600">
            {{ $t('tickets.is_typing') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chat-message {
  z-index: 1;
  max-width: none !important;
  min-height: 37px;
}

.chat-message-wrapper {
  max-width: 80%;
}

.parent-message {
  margin-left: -8px;
  margin-right: -8px;
}

.image-message {
  margin-bottom: -4px;
}

.image-with-parent-message .image-message {
  margin: 8px;
}

.image-with-parent-message .image-message-caption {
  margin-top: -4px;
  padding-top: 0px;
}

.audio-speed-control {
  color: #919191;
  text-decoration: none !important;
  padding: 2px 8px !important;
  border-color: #f1f3f4;
}

.audio-speed-control:hover,
.audio-speed-control:focus {
  box-shadow: none !important;
  background-color: #f1f3f4;
}

.audio-speed-text {
  vertical-align: bottom;
  line-height: 18px;
}

.active_audio_speed {
  color: black;
  background-color: #f1f3f4 !important;
}

/*.message-container:last-child {*/
/*    margin-bottom: 0;*/
/*}*/
.message-container {
  animation: trengo-slide-from-left 0.3s ease 0s 1 normal backwards !important;
  overflow: hidden;
}

.message-container:last-child {
  margin-bottom: 0;
}

.message-button {
  padding: 10px 15px;
  border-radius: 10px;
  border-color: #cdeee9;
}

.template-button li {
  border-color: rgba(0, 0, 0, 0.08);
}

.template-button li:last-child {
  padding-bottom: 0;
}

.avatar-left {
  left: -5px;
  top: -5px;
  position: absolute !important;
}

.avatar-right {
  right: -5px;
  top: -5px;
  position: absolute !important;
}

.avatar-container-left {
  left: -2px;
  top: -2px;
  width: 40px;
  height: 40px;
  position: absolute !important;
}

.avatar-container-right {
  right: -2px;
  top: -2px;
  width: 40px;
  height: 40px;
  position: absolute !important;
}

@media screen and (min-width: 1920px) {
  .chat-message-wrapper {
    max-width: 768px;
  }
}
</style>
