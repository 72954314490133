<script lang="ts" setup>
const emit = defineEmits(['close']);
const handleClose = () => {
  emit('close');
};
</script>

<template>
  <t-modal
    :model-value="true"
    :title="$t('usage_overview.add_conversation_blocks_success_modal_title')"
    data-test="addConversationBlocksSuccessModal"
    variant="narrow"
    @close="handleClose"
  >
    <p
      class="t-text-md m-0 text-grey-700"
      v-html="$t('usage_overview.add_conversation_blocks_success_modal_description')"
    />
    <template #footer>
      <div class="flex w-full justify-end">
        <t-button btn-style="primary" @click="handleClose">
          {{ $t('general.continue') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>
