<template>
  <div
    v-if="numberOfChannelsToMigrate > 0"
    class="mx-4 mb-2 rounded-lg bg-error-500 p-4 text-white"
    data-test="whatsapp-migration-banner"
  >
    <p class="mb-1" data-test="wa-banner-message">{{ message }}</p>
    <div v-for="(channel, index) in channelsToMigrate" :key="channel.id" class="inline-block">
      <router-link
        v-if="hasPermission"
        :to="`/admin/channels2/wa_business/${channel.id}`"
        tag="span"
        class="pointer font-bold hover:text-grey-300"
        data-test="wa-channel-to-migrate"
      >
        {{ channel.display_name }}
        <span v-if="showSeparator(index)" class="mx-1">•</span>
      </router-link>
      <p v-else class="m-0 p-0" data-test="wa-channel-no-permission">
        <t-tooltip :text="$t('whatsapp.migration_banner_tooltip')" position="right" data-test="permission-tooltip">
          {{ channel.display_name }}
        </t-tooltip>
        <span v-if="showSeparator(index)" class="mx-1">•</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { FEATURE_FLAG_CHANNELS } from '@/Configs/Constants';
import { WhatsappProviders } from '@/Configs/Constants/whatsapp';
import { useFeatureFlagStore } from '@/store/pinia';

type WaProviders = 'trengo_bsp' | 'messagebird' | 'twilio' | 'cm' | 'sandbox' | 'trengo' | '360dialog';
type WaChannel = {
  whatsappChannel: {
    id: number;
    provider: WaProviders;
    display_name: string;
  };
};

const amountOfChannelsToShow = 2;

export default {
  name: 'WaMigrationBanner',
  computed: {
    isKeyReplyFirstCutOffEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_KEYREPLY_FIRST_CUT_OFF_WARNING);
    },
    isKeyReplySecondCutOffEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_KEYREPLY_SECOND_CUT_OFF_WARNING);
    },
    isOtherWaProvidersFirstCutOffEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_OTHER_WA_PROVIDERS_FIRST_CUT_OFF_WARNING);
    },
    isOtherWaProvidersSecondCutOffEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_OTHER_WA_PROVIDERS_SECOND_CUT_OFF_WARNING);
    },
    hasEnabledKeyReplyCutOff() {
      return this.isKeyReplyFirstCutOffEnabled || this.isKeyReplySecondCutOffEnabled;
    },
    hasEnabledOtherProvidersCutOff() {
      return this.isOtherWaProvidersFirstCutOffEnabled || this.isOtherWaProvidersSecondCutOffEnabled;
    },
    waChannels() {
      return this.$store.getters['initialData/getWaChannels'];
    },
    keyReplyChannelsToMigrate() {
      const keyReplyChannels = this.waChannels.filter(
        (waChannel: WaChannel) => waChannel.whatsappChannel.provider === WhatsappProviders.TRENGO.id
      );
      return keyReplyChannels?.slice(0, amountOfChannelsToShow);
    },
    otherChannelsToMigrate(): WaChannel[] {
      const excludedProviders = [WhatsappProviders.TRENGO_BSP.id, WhatsappProviders.SANDBOX.id];
      const keyReply = WhatsappProviders.TRENGO.id;

      let channels = this.waChannels.filter(
        (waChannel: WaChannel) => !excludedProviders.includes(waChannel.whatsappChannel.provider)
      );

      if (this.hasEnabledOtherProvidersCutOff) {
        channels = channels.filter((channel: WaChannel) => channel.whatsappChannel.provider !== keyReply);
      }

      return channels?.slice(0, amountOfChannelsToShow);
    },
    hasPermission() {
      return this.$authorization().hasPermission(this.SETTINGS__CHANNELS__MANAGE);
    },
    numberOfChannelsToMigrate(): number {
      if (!this.hasEnabledKeyReplyCutOff && !this.hasEnabledOtherProvidersCutOff) {
        return 0;
      }
      return this.hasEnabledKeyReplyCutOff ? this.keyReplyChannelsToMigrate.length : this.otherChannelsToMigrate.length;
    },
    channelsToMigrate() {
      return this.hasEnabledKeyReplyCutOff ? this.keyReplyChannelsToMigrate : this.otherChannelsToMigrate;
    },
    message() {
      if (this.isKeyReplyFirstCutOffEnabled) {
        return this.$t('whatsapp.key_reply_first_cutoff_message');
      }
      if (this.isKeyReplySecondCutOffEnabled) {
        return this.$t('whatsapp.key_reply_second_cutoff_message');
      }
      if (this.isOtherWaProvidersFirstCutOffEnabled) {
        return this.$t('whatsapp.other_providers_first_cutoff');
      }
      return this.$t('whatsapp.other_providers_second_cutoff');
    },
  },
  methods: {
    showSeparator(index: number) {
      return index !== this.numberOfChannelsToMigrate - 1 && index !== amountOfChannelsToShow - 1;
    },
  },
};
</script>
