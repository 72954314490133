<script type="text/babel">
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/nl.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/ru.js';
import 'froala-editor/js/languages/id.js';
import 'froala-editor/js/languages/pt_br.js';

import { sanitize } from 'dompurify';
import FroalaEditor from 'froala-editor';

import eventBus from '@/eventBus';
import { froalaLocaleCodes } from '@/util/froalaLocale';

import { waitForEvent } from '../../start/util';

export default {
  name: 'Froala',
  emits: ['update:modelValue', 'change', 'blur', 'focus', 'filePaste'],
  data() {
    return {
      isFocused: false,
      instance: null,
      oldValue: '',
      changed: false,
      changeTimer: null,
    };
  },
  props: {
    hasPaddingX: {
      type: Boolean,
      default: false,
    },
    hasPaddingY: {
      type: Boolean,
      default: false,
    },
    toolbarPosition: {
      type: String,
      default: 'relative',
      validate: (value) => ['relative', 'absolute'].includes(value),
    },
    focus: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    image: {
      type: Boolean,
      default: false,
    },
    imagePrefix: {
      type: String,
      default: '',
    },
    customFroalaOptions: {
      type: Object,
      default: () => ({}),
    },
    border: {
      type: Boolean,
      default: true,
    },
    saveSelectionOnBlur: {
      // default false, since this can cause jumpy cursor behavior,
      // this seems to be an issue related to Froala
      // https://github.com/froala/wysiwyg-editor/issues/3411
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedIsFocused() {
      return this.isFocused ? 'border-leaf-500' : 'border-grey-300';
    },
    computedContainer() {
      return { 'fr3-editor-no-border': !this.border, 'px-3': this.hasPaddingX, 'py-3': this.hasPaddingY };
    },
    toolbarComputedPosition() {
      return this.toolbarPosition === 'relative' ? '' : 'toolbar-absolute';
    },
  },
  watch: {
    async modelValue(v) {
      if (this.oldValue !== v) {
        v = this.secure(v);
        this.oldValue = v;
        await waitForEvent('froala-initialized', this.instance.html);
        this.instance.html.set(v);
      }
    },
  },
  unmounted() {
    this.instance.destroy();
    eventBus.$off('reply_form.focus');
    eventBus.$off('reply_form.insert');
  },
  mounted() {
    this.init();
  },
  methods: {
    handleFocus(value) {
      this.isFocused = value;
    },
    secure(html) {
      try {
        var parser = new DOMParser();
        var doc = parser.parseFromString(html, 'text/html');
        html = doc.querySelector('body').innerHTML;
        return sanitize(html, {
          FORBID_TAGS: ['style', 'svg', 'audio', 'video', 'math', 'meta', 'script'],
          FORBID_ATTR: ['class'],
        });
      } catch (e) {
        return html;
      }
    },
    init() {
      let options = [
        'bold',
        'italic',
        'underline',
        'textColor',
        'formatOL',
        'formatUL',
        'insertLink',
        '|',
        'clearFormatting',
      ];
      let optionsXS = ['bold', 'italic', 'underline', '|'];
      if (this.image) {
        options.push('insertImage');
        optionsXS.push('insertImage');
      }
      if (this.imagePrefix === 'signature') {
        options.push('insertTable');
        optionsXS.push('insertTable');
      }
      options.push('html');
      optionsXS.push('html');
      this.$refs.area.innerHTML = this.secure(this.modelValue);
      let froalaOptions = this.customFroalaOptions || {};
      // Merge custom options with default options
      froalaOptions = {
        ...froalaOptions,
        key: 'pe1G2wF1C1B1C2C7E7C5oCe1ZSc2XHe1Cd1f1KIWCWMJHXCLSwD1D1D1D1F1H4A11B1D6E4==',
        attribution: false,
        toolbarButtons: options,
        htmlUntouched: true,
        pastePlain: false,
        htmlExecuteScripts: false,
        language: froalaLocaleCodes[this.$root.user.locale_code],
        // toolbarButtonsSM:options,
        // toolbarButtonsMD:options,
        // toolbarButtonsXS:optionsXS,
        imageUploadRemoteUrls: false,
        imagePaste: true,
        toolbarBottom: true,
        heightMin: 100,
        toolbarSticky: false,
        heightMax: this.maxHeight != null ? parseInt(this.maxHeight) : null,
        imageUploadURL: '/client-api/upload',
        imageUploadParams: {
          prefix: this.imagePrefix,
        },
        imageUploadMethod: 'POST',
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        undo: true,
        tableStyles: {
          'no-border': 'Borderless',
        },
        events: {
          contentChanged: () => {
            var v = this.instance.html.get();
            this.oldValue = v;
            this.$emit('update:modelValue', v);
            this.changed = true;
            clearTimeout(this.changeTimer);
            this.changeTimer = setTimeout(() => {
              this.$emit('change');
              this.changed = false;
            }, 1000);
          },
          blur: () => {
            this.$emit('blur');
            clearTimeout(this.changeTimer);
            if (this.changed) {
              this.$emit('change');
              this.changed = false;
            }
            if (this.saveSelectionOnBlur) {
              this.instance.selection.save();
            }
          },
          focus: () => {
            this.$emit('focus');
          },
          initialized: () => {
            this.instance.el.addEventListener('keydown', (e) => eventBus.$emit('froalaKeyDown', e));
            this.instance.el.addEventListener('keyup', (e) => eventBus.$emit('froalaKeyUp', e));
            this.instance.el.addEventListener('paste', (e) => {
              var items = (e.clipboardData || e.originalEvent.clipboardData).items;
              for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.kind === 'file') {
                  var blob = item.getAsFile();
                  if (blob != null) {
                    this.$emit('filePaste', blob);
                    return false;
                  }
                }
              }
            });
            eventBus.$emit('froala-initialized', this.instance);
          },
        },
        fontFamilyDefaultSelection: 'Font Family',
        fontSizeDefaultSelection: 'Font Size',
      };
      this.instance = new FroalaEditor(this.$refs.area, froalaOptions);
      eventBus.$on('reply_form.focus', () => {
        if (this.instance && this.instance.events) {
          this.instance.events.focus();
        }
      });
    },
    insertIntoEditor(html) {
      this.instance.selection.restore();
      this.$nextTick(() => {
        this.instance.html.insert(html);
      });
    },
  },
};
</script>

<template>
  <div
    v-click-outside="() => handleFocus(false)"
    class="fr3-editor"
    :class="[computedContainer, computedIsFocused, toolbarComputedPosition]"
    @click="handleFocus(true)"
  >
    <textarea ref="area" cols="30" rows="20" data-test="composer-message-textarea-create" />
  </div>
</template>

<style lang="scss">
.toolbar-absolute {
  .fr-toolbar {
    position: absolute;
    bottom: 0;
    top: 100%;
    width: 100%;
  }
}

.fr3-editor {
  .fr-box {
    border: 1px solid #e4e6e8 !important;
    border-radius: 0.25rem !important;
  }

  .fr-box.fr-basic .fr-element {
    padding: 0;
    font-family: 'Inter', 'Arial', sans-serif;
    color: unset;
    font-size: 16px !important;
    margin-bottom: 1rem;
    line-height: 24px;
  }

  .fr-toolbar {
    border: none !important;
  }

  .fr-box.fr-basic .fr-wrapper {
    border: none !important;
  }

  .fr-toolbar svg {
    width: 20px !important;
    height: 20px !important;
  }

  .fr-btn-grp {
    margin: 0 4px 0 4px !important;
  }

  .fr-command.fr-btn {
    margin: 0 2px;
    opacity: 0.8;
  }

  .fr-toolbar .fr-newline {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.fr-popup {
  z-index: 9999 !important;
}

.fr3-editor.fr3-editor-no-border {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;

  .fr-box {
    border: none !important;
    border-radius: 0.25rem !important;
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  .fr-wrapper {
    flex: 1;
    overflow-y: auto;
  }

  .fr-view {
  }
}

// tmp fix to hide buttons on mobile
@media (max-width: 991px) {
  .fr3-editor {
    #formatOL-2,
    #formatOLOptions-2,
    #dropdown-menu-formatOLOptions-2,
    #formatUL-2,
    #formatULOptions-2,
    #dropdown-menu-formatULOptions-2,
    #clearFormatting-2,
    #textColor-2,
    #insertLink-1,
    #formatOL-1,
    #formatOLOptions-1,
    #dropdown-menu-formatOLOptions-1,
    #formatUL-1,
    #formatULOptions-1,
    #dropdown-menu-formatULOptions-1,
    #clearFormatting-1,
    #textColor-1,
    #insertLink-2,
    #formatOL-3,
    #formatOLOptions-3,
    #dropdown-menu-formatOLOptions-3,
    #formatUL-3,
    #formatULOptions-3,
    #dropdown-menu-formatULOptions-31,
    #clearFormatting-3,
    #textColor-3,
    #insertLink-3 {
      display: none;
    }
  }
}
</style>
