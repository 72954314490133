<script lang="ts" setup>
import { ChartColumnLinear } from '@trengo/trengo-icons';
import { computed, onMounted, ref } from 'vue';

import { fetchTransactions } from '@/api';
import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import MobilePreviewCard from '@/components/common/MobilePreviewCard/MobilePreviewCard.vue';
import EmptyChart from '@/components/EmptyChart';
import { FEATURE_FLAG_SUBSCRIPTION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';
import { flashError } from '@/util/errorHelper';

import TransactionsGraph from './TransactionsGraph.vue';
import TransactionsTable from './TransactionsTable.vue';
import MigrationFreeze from '../MigrationFreeze/Index.vue';

import type { TransactionMonth } from '@/types';

const transactions = ref([] as TransactionMonth[]);
const isLoading = ref(true);
const isFreeze = useFeatureFlagStore().isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_FREEZE_REGISTRATION);
const isMobile = window.is_mobile_device();

const hasTransactions = computed(
  () =>
    !isLoading.value &&
    transactions.value.length > 0 &&
    transactions.value.some((transaction) => transaction.categories.some((item) => item.amount > 0))
);

const loadTransactions = () => {
  fetchTransactions()
    .then(({ data }) => {
      transactions.value = data;
    })
    .catch((err) => {
      flashError('Something went wrong.');
      console.error(err);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(() => {
  if (!isFreeze && !isMobile) {
    loadTransactions();
  }
});
</script>

<template>
  <migration-freeze v-if="isFreeze" />
  <mobile-preview-card v-else-if="isMobile" class="max-h-screen w-full">
    {{ $t('general.feature_only_available_on_desktop') }}
  </mobile-preview-card>
  <account-page-panel v-else :title="$t('transactions.heading')">
    <div v-if="isLoading" class="mt-6 flex items-center justify-center text-4xl font-extralight">
      <i class="fal fa-spinner m-auto animate-spin" />
    </div>
    <template v-else-if="hasTransactions">
      <transactions-graph :transactions="transactions" />
      <transactions-table :transactions="transactions" />
    </template>
    <empty-chart
      v-else
      :icon="ChartColumnLinear"
      panel-title="transactions.chart_heading"
      title="transactions.no_transactions"
      description="transactions.no_transactions_subtext"
    />
  </account-page-panel>
</template>
