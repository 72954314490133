<template>
  <div>
    <setting-item
      :hide-form-text-on-mobile="true"
      :border-on-mobile="false"
      :show-border="false"
      :custom-class="{ container: 'mb-3 mt-0 pt-0 sm:pt-3 sm:pb-4 pb-0' }"
    >
      <template #title>{{ $t('email_settings.styling') }}</template>
      <template #formText>{{ $t('email_settings.customize_how_your_emails_look_to_your_customers') }}</template>
      <template #moreSettings>
        <t-dropdown
          v-model="computedEmailTheme"
          drop-down-class="text-base font-medium p-4"
          :items="computedEmailItems"
          value-index="id"
          text-index="title"
        />
        <div class="mt-2">
          <router-link to="/admin/email_themes/create" class="font-semibold text-grey-600">
            {{ $t('email_settings.channels_email_create_new_style') }}
          </router-link>
        </div>
      </template>
    </setting-item>

    <setting-item
      v-if="senderNameCanBePersonalised"
      :hide-form-text-on-mobile="true"
      :show-border="false"
      :custom-class="{ container: 'mb-4 mt-0 pt-5 sm:pt-0 sm:pb-3 pb-0' }"
    >
      <template #title>
        {{ senderNameTitle }}
      </template>
      <template #formText>
        {{ $t('email_settings.sender_name_subtitle') }}
      </template>
      <template #moreSettings>
        <atomic-input v-model="computedRecord.emailChannel.sender_name_personal" size="lg" />
      </template>
    </setting-item>

    <setting-item
      v-if="hasReplyTo"
      :hide-form-text-on-mobile="true"
      :show-border="true"
      :custom-class="{ container: 'mb-4 mt-0 pt-5 sm:pt-0 sm:pb-3 pb-0' }"
    >
      <template #title>{{ $t('email_settings.reply_to_mail') }}</template>
      <template #formText>{{ $t('email_settings.optional_add_a_custom_reply_to_email_address') }}</template>
      <template #moreSettings>
        <atomic-input v-model="computedRecord.emailChannel.sender_email" size="lg" />
      </template>
    </setting-item>

    <setting-item
      :border-on-mobile="false"
      :show-border="true"
      :custom-class="{ container: 'mb-6 mt-0 pt-0 sm:pt-0 sm:pb-1 pb-0' }"
    >
      <template #title>{{ $t('email_settings.signature') }}</template>
      <template #moreSettings>
        <froala
          ref="emailSignatureFroalaEditor"
          v-model="computedRecord.emailChannel.signature"
          class="rounded-lg border-1 border-grey-300 bg-white p-4 py-3 pb-12 text-base font-medium text-grey-800"
          image-prefix="signature"
          toolbar-position="absolute"
          :image="true"
          :border="false"
          :custom-froala-options="{
            initOnClick: true,
            maxHeight: 150,
            placeholderText: $t('email_settings.type_something'),
          }"
          :save-selection-on-blur="true"
        />
        <div class="my-4 text-sm font-semibold text-black">
          <span class="mr-2 inline-block">{{ $t('email_settings.available_tags') }}</span>
          <t-multi-select-tag
            v-for="tag in emailSignatureTags"
            :key="tag.id"
            :has-avatar="false"
            :is-closable="false"
            :item="tag"
            class="cursor-pointer"
            size="sm"
            @click="insertIntoEmailSignature(tag.value)"
          />
          <router-link
            v-if="$authorization().hasPermission(PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE)"
            to="/admin/custom_fields/create?type=user"
            class="text-sm font-semibold text-grey-800"
          >
            {{ $t('email_settings.add_custom_field') }}
          </router-link>
        </div>
      </template>
    </setting-item>

    <setting-item
      :show-border="!computedRecord.emailChannel.auto_reply_enabled"
      :custom-class="{ container: autoReplyEnabledContainerStyle }"
      @click="() => handleToggleSwitch('emailChannel.auto_reply_enabled')"
    >
      <template #title>{{ $t('email_settings.auto_replies') }}</template>
      <template #formText>
        {{ $t('email_settings.enable_auto_replies_subtitle') }}
      </template>
      <template #toggle>
        <t-switch v-model="computedRecord.emailChannel.auto_reply_enabled" @click.stop />
      </template>
    </setting-item>

    <div v-if="computedRecord.emailChannel.auto_reply_enabled" class="mt-8 rounded-xl bg-grey-100 p-4 pb-1">
      <setting-item
        v-if="computedRecord.emailChannel.auto_reply_enabled"
        :hide-form-text-on-mobile="true"
        :show-border="false"
        :custom-class="{ container: 'mb-4 mt-0 pt-5 sm:pt-0 sm:pb-3 pb-0' }"
      >
        <template #title>{{ $t('email_settings.auto_reply_sender') }}</template>
        <template #formText>
          {{ $t('email_settings.the_sender_company_name_visible_for_the_receivers_of_this_auto_reply') }}
        </template>
        <template #moreSettings>
          <atomic-input v-model="computedRecord.emailChannel.sender_name" size="lg" />
        </template>
      </setting-item>

      <setting-item
        v-if="computedRecord.emailChannel.auto_reply_enabled"
        :show-border="false"
        :custom-class="{ container: 'mb-0 mt-0 pt-0 sm:pt-0 pb-0' }"
      >
        <template #title>
          <div class="mb-1">{{ $t('email_settings.auto_reply_mail') }}</div>
        </template>
        <template #moreSettings>
          <froala
            ref="emailAutoReplyFroalaEditor"
            v-model="computedRecord.emailChannel.auto_reply_body"
            class="rounded-lg border-1 border-grey-300 bg-white p-4 py-3 pb-12 text-base font-medium text-grey-800"
            :border="false"
            toolbar-position="absolute"
            :custom-froala-options="{
              initOnClick: true,
              maxHeight: 150,
              placeholderText: $t('email_settings.type_something'),
            }"
            :save-selection-on-blur="true"
          />
          <div class="my-4 mb-0 font-semibold text-black">
            <span class="mr-2 inline-block text-sm">{{ $t('email_settings.available_tags') }}</span>
            <t-multi-select-tag
              v-for="tag in autoReplyTags"
              :key="tag.id"
              :has-avatar="false"
              :is-closable="false"
              :item="tag"
              class="cursor-pointer"
              size="sm"
              @click="insertIntoAutoReply(tag.value)"
            />
          </div>
        </template>
      </setting-item>
    </div>

    <div v-if="computedRecord.emailChannel.auto_reply_enabled" class="my-6 mb-1 mt-8 border-b border-grey-300 pt-2" />

    <setting-item
      :show-border="false"
      :custom-class="{
        container: `mb-4 mt-6 pt-5 sm:pt-0 sm:pb-3 pb-0 cursor-pointer `,
      }"
      @click="isEntitledToRemoveTrengoBranding && handleToggleSwitch('emailChannel.branding')"
    >
      <template #title>
        <div class="text-grey-900">{{ $t('email_settings.trengo_branding') }}</div>
      </template>
      <template #formText>
        <div class="text-grey-700">{{ $t('email_settings.include_provided_by_trengo_below_every_email') }}</div>
      </template>
      <template #toggle>
        <t-switch
          v-model="computedRecord.emailChannel.branding"
          :disabled="!isEntitledToRemoveTrengoBranding"
          @click.stop
        />
      </template>
      <template #moreSettings>
        <div
          v-if="!isEntitledToRemoveTrengoBranding"
          class="flex flex-col items-center justify-between rounded-xl bg-grey-100 px-3 py-2 sm:items-start md:flex-row"
        >
          <div class="flex w-3/5 items-center">
            <arrow-top-circle-linear size="1.25rem" class="text-leaf-400" />
            <span class="ml-2 text-sm font-medium" v-html="brandingText"></span>
          </div>
          <div v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
            <t-button btn-style="secondary" size="sm" @click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)">
              {{ $t('email_settings.upgrade_plan') }}
              <arrow-right-linear size="1.125rem" class="ml-1 inline" />
            </t-button>
          </div>
        </div>
      </template>
    </setting-item>
  </div>
</template>

<script>
import { ArrowTopCircleLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { union } from 'lodash';
import { mapStores } from 'pinia';

import Froala from '@/components/ReplyForm/Froala';
import { FEATURE, ENTITLEMENT_STATUS, PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useEntitlementsStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import SettingItem from '../../Components/SettingItem';
import { GOOGLE, NYLAS_V3, TRENGO } from '@/Configs/Constants/ChannelLandingPageTypes';

export default {
  name: 'EmailSettings',
  emits: ['input', 'switch'],
  components: {
    Froala,
    SettingItem,
    ArrowTopCircleLinear,
    ArrowRightLinear,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    isExternalProvider: {
      type: Boolean,
    },
  },
  data() {
    return {
      senderDomains: [],
      emailThemes: [],
      breakpoints,
      SETTINGS_URL,
      PERMISSION,
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    senderNameTitle() {
      const { comparisons } = this.breakpoints;
      const isLgOrXl = comparisons.eq_lg || comparisons.eq_xl;
      return isLgOrXl ? this.$t('email_settings.sender_name') : this.$t('email_settings.from');
    },
    autoReplyEnabledContainerStyle() {
      const padding = this.computedRecord.emailChannel.auto_reply_enabled ? 'pb-0' : 'sm:pb-6 pb-6';
      return `mb-1 mt-0 pt-5 sm:pt-0 ${padding} cursor-pointer`;
    },
    computedRecord: {
      get() {
        return this.record;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasReplyTo() {
      return this.computedRecord.emailChannel.provider === 'TRENGO';
    },
    computedEmailItems() {
      return union([{ id: null, title: 'Default' }], this.emailThemes);
    },
    computedEmailTheme: {
      get() {
        return this.computedRecord.emailChannel.email_theme_id;
      },
      set(value) {
        this.computedRecord.emailChannel.email_theme_id = value ? value : null;
        this.$emit('input', this.computedRecord);
      },
    },
    emailSignatureTags() {
      return [
        { name: 'Agent first name', value: '[agent.first_name]' },
        { name: 'Agent last name', value: '[agent.last_name]' },
        { name: 'Agent image', value: '[agent.image]' },
        { name: 'Agent image size', value: '[agent.image width=200]' },
        { name: 'Agent square image', value: '[agent.image rounded=false]' },
        ...this.$root.customUserFields.map((customField) => {
          return {
            name: customField.title,
            value: '[agent.custom_field.' + customField.id + ']',
          };
        }),
      ];
    },
    autoReplyTags() {
      return [
        { name: 'Contact name', value: '[name]' },
        { name: 'Ticket number', value: '[ticket_number]' },
      ];
    },
    showSenderEmailAddress() {
      return this.computedRecord.emailChannel.type === 'TRENGO';
    },
    senderNameCanBePersonalised() {
      return [TRENGO, GOOGLE, NYLAS_V3].includes(this.computedRecord.emailChannel.type);
    },
    isEntitledToRemoveTrengoBranding() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__EMAIL__WHITELABEL) ===
        ENTITLEMENT_STATUS.ENTITLED
      );
    },
    requiredPlan() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__EMAIL__WHITELABEL);
    },
    brandingText() {
      return this.isEntitledToRemoveTrengoBranding
        ? this.$t('email_settings.include_provided_by_trengo_below_every_email')
        : this.$t('entitlement.customer_service__channels__email__whitelabel', { plan: this.requiredPlan });
    },
  },
  mounted() {
    this.loadEmailThemes();
  },
  methods: {
    handleToggleSwitch(key) {
      this.$emit('switch', key);
    },
    loadEmailThemes() {
      axios.get('/api/v2/email/themes').then((r) => (this.emailThemes = r.data.data));
    },

    insertIntoEmailSignature(text) {
      this.$refs.emailSignatureFroalaEditor.insertIntoEditor(text);
    },

    insertIntoAutoReply(text) {
      this.$refs.emailAutoReplyFroalaEditor.insertIntoEditor(text);
    },
  },
};
</script>
