<template>
  <div data-test="sidebar-infobox-dropdown">
    <t-list-item
      v-for="item in userDataList"
      :key="item.emit"
      :label="item.label"
      size="sm"
      data-test="sidebar-infobox-user-datalist"
      @click="$emit(item.emit)"
    >
      <template #prefix>
        <component :is="item.icon" size="1.25rem" />
      </template>
    </t-list-item>

    <div v-if="hasEmail" aria-hidden="true" class="my-1 w-full border-b border-grey-300 border-opacity-30" />

    <t-list-item
      v-for="item in emailDataList"
      :key="item.emit"
      :label="item.label"
      size="sm"
      data-test="sidebar-infobox-email-datalist"
      @click="$emit(item.emit)"
    >
      <template #prefix>
        <component :is="item.icon" size="1.25rem" />
      </template>
    </t-list-item>

    <div v-if="hasPhone" aria-hidden="true" class="my-1 w-full border-b border-grey-300 border-opacity-30" />

    <t-list-item
      v-for="item in phoneDataList"
      :key="item.emit"
      :label="item.label"
      size="sm"
      data-test="sidebar-infobox-phone-datalist"
      @click="$emit(item.emit)"
    >
      <template #prefix>
        <component :is="item.icon" size="1.25rem" />
      </template>
    </t-list-item>
  </div>
</template>

<script lang="ts">
import {
  Comment2PlusLinear,
  CopyLinear,
  Edit3Linear,
  RotateCwLinear,
  SearchLinear,
  UserSquareLinear,
  PhoneLinear,
} from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';

export default defineComponent({
  name: 'InfoboxDropdown',
  emits: [
    'edit-name',
    'show-contact',
    'edit-email',
    'search-email',
    'copy-email',
    'start-conversation',
    'call-contact',
    'search-phone',
    'copy-phone',
  ],
  props: {
    hasEmail: {
      type: Boolean,
      default: false,
    },
    hasPhone: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Comment2PlusLinear,
    CopyLinear,
    Edit3Linear,
    RotateCwLinear,
    SearchLinear,
    UserSquareLinear,
    PhoneLinear,
  },

  computed: {
    hasPermissionsForConversation() {
      return this.$authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE);
    },

    userDataList() {
      return [
        {
          label: this.$tc('ticket_sidebar.edit_name'),
          emit: 'edit-name',
          icon: 'edit-3-linear',
        },
        {
          label: this.$tc('general.show_contact'),
          emit: 'show-contact',
          icon: 'user-square-linear',
        },
      ];
    },

    emailDataList() {
      if (!this.hasEmail) {
        return [];
      }

      let allowedItems = [
        {
          label: this.$tc('user_profile.email'),
          emit: 'edit-email',
          icon: 'edit-3-linear',
        },
        {
          label: this.$tc('ticket_sidebar.search_by_email'),
          emit: 'search-email',
          icon: 'search-linear',
        },
        {
          label: this.$tc('ticket_sidebar.copy_email'),
          emit: 'copy-email',
          icon: 'copy-linear',
        },
      ];

      if (this.hasPermissionsForConversation) {
        allowedItems = [
          ...allowedItems,
          {
            label: this.$tc('tickets.start_conversation'),
            emit: 'start-conversation',
            icon: 'comment2-plus-linear',
          },
        ];
      }

      return allowedItems;
    },

    phoneDataList() {
      if (!this.hasPhone) {
        return [];
      }

      return [
        {
          label: this.$tc('general.call_contact'),
          emit: 'call-contact',
          icon: 'phone-linear',
        },
        {
          label: this.$tc('ticket_sidebar.search_by_phone_number'),
          emit: 'search-phone',
          icon: 'search-linear',
        },
        {
          label: this.$tc('ticket_sidebar.copy_phone_number'),
          emit: 'copy-phone',
          icon: 'copy-linear',
        },
      ];
    },
  },
});
</script>
