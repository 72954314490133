<template>
  <div class="sidebar-collapse" :class="{ isOpen }">
    <t-slide-toggle v-if="loaded" v-model="isOpen" @toggle="onToggle">
      <template #button>
        <div class="header-container">
          <div class="header-container">
            <slot name="header" />
          </div>
          <i class="fal fa-chevron-up icon"></i>
        </div>
      </template>

      <div v-if="isOpen" class="body">
        <slot />
      </div>
    </t-slide-toggle>
  </div>
</template>

<script>
export default {
  name: 'SidebarCollapse',
  props: {
    collapseKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isOpen: false,
      loaded: false,
    };
  },
  methods: {
    onToggle(toggle) {
      this.isOpen = toggle;
    },
  },

  watch: {
    isOpen: function (val) {
      if (typeof val == 'boolean') {
        this.$tStorage.setItem(this.collapseKey, '' + val);
      }
    },
  },

  async mounted() {
    if (!this.collapseKey.length) {
      return;
    }
    if ((await this.$tStorage.getItem(this.collapseKey)) == 'true') {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
    this.loaded = true;
  },
};
</script>

<style scoped lang="scss" src="./SidebarCollapse.scss" />
