<template>
  <div
    class="
      mb-12
      mt-4
      grid grid-cols-1
      justify-items-stretch
      rounded-xl
      border-1 border-galaxy-300
      bg-galaxy-100
      px-4
      py-6
      sm:px-8 sm:py-6
    "
  >
    <div class="flex flex-row items-start sm:items-center">
      <div
        class="
          flex
          h-44px
          w-44px
          items-center
          justify-center
          overflow-hidden
          rounded-xl
          bg-galaxy-600
          sm:h-48px sm:w-48px
        "
      >
        <img class="h-full w-full" :src="`${$root.assetsURL}img/onno.jpg`" />
      </div>
      <div class="ml-4 flex flex-col">
        <div class="t-text-mobile-body-md sm:t-text-desktop-label-lg sm:mb-1">
          {{ $t('getting_started.need_some_help_setting_things_up') }}
        </div>
        <div class="t-text-desktop-body-xs sm:t-text-desktop-paragraph-sm text-grey-600">
          {{ $t('getting_started.subtitle_need_help_let_us_know') }}
        </div>
      </div>
    </div>
    <div class="mt-8 flex flex-row gap-2 lg:flex-row">
      <item-button v-if="hasNotSubscription" class="w-full" @click="requestACall">
        <div>
          <call-calling-fill size="1.5rem" fill="#249888" class="mb-2" />
        </div>
        <span class="t-text-button-sm">{{ $t('getting_started.request_a_call') }}</span>
      </item-button>
      <item-button class="w-full" @click="handleOpenWebChat('chat')">
        <div>
          <comments-fill size="1.5rem" fill="#249888" class="mb-2" />
        </div>
        <span class="t-text-button-sm">{{ $t('getting_started.chat_with_us') }}</span>
      </item-button>
      <item-button class="w-full" @click="visitOurHelpCentre">
        <div>
          <question-fill size="1.5rem" fill="#249888" class="mb-2" />
        </div>
        <span class="t-text-button-sm">{{ $t('getting_started.visit_our_help_centre') }}</span>
      </item-button>
    </div>
  </div>
</template>

<script>
import { CallCallingFill, CommentsFill, QuestionFill } from '@trengo/trengo-icons';

import eventBus from '@/eventBus';
import { openLink } from '@/util/helpers';

import ItemButton from '../ItemButton';

export default {
  name: 'NeedHelpBox',
  components: {
    CallCallingFill,
    CommentsFill,
    ItemButton,
    QuestionFill,
  },
  computed: {
    hasNotSubscription() {
      return this.$root?.companyProfile?.subscription?.plan_id === null;
    },
  },
  methods: {
    handleOpenWebChat(ChannelType) {
      window.Trengo?.Api?.Widget?.open(ChannelType);
    },
    visitOurHelpCentre() {
      openLink('https://help.trengo.com/');
    },
    requestACall() {
      if (this.$root.user.locale_code === 'nl-NL') {
        openLink('https://meetings.hubspot.com/trengo-meets/demo-sdr-dutch');
      } else {
        openLink('https://meetings.hubspot.com/trengo-meets/demo-sdr-english');
      }
    },
  },
};
</script>
