<template>
  <div>
    <t-divider class="my-6" />
    <t-inline-banner v-if="!isEntitledToManagePermissions" type="upgrade" class="my-6">
      <template #icon>
        <arrow-top-circle-linear />
      </template>
      <span v-html="bannerLabel" />
      <template #action>
        <slot v-if="hasUpgradePermissions">
          <t-button btn-style="secondary" class="flex items-center gap-1" @click="handleUpgrade">
            {{ labelCTA }}
            <arrow-right-linear size="1rem" />
          </t-button>
        </slot>
      </template>
    </t-inline-banner>
    <div v-for="(permissions, domain) in permissionsGroupedByDomain" :key="domain" class="pb-8">
      <h3 class="t-text-lg-emphasize">{{ $t(`user_management.${domain}`) }}</h3>
      <div
        v-for="permission in permissions"
        :key="`permission-${permission.id}-${role.id}`"
        class="mb-3 flex justify-between py-2"
      >
        <span class="t-text-md text-grey-800">
          {{ $t(`user_management.${getTranslatedPermissionName(permission.name)}`) }}
        </span>

        <t-switch
          :model-value="permission.granted"
          :disabled="!isEntitledToManagePermissions"
          @update:modelValue="handleToggleClick(permission.id, role.id)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';

import { FEATURE, PERMISSION } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';

export default {
  name: 'ConfigPermissions',
  emits: ['change'],
  components: {
    ArrowTopCircleLinear,
    ArrowRightLinear,
  },
  props: {
    role: {
      type: Object,
      default: () => ({}),
    },
    configurablePermissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FEATURE,
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    bannerLabel() {
      return this.$t('entitlement.banner_account__authorization__manage_role_permissions', {
        plan: this.minimumRequiredPlanToManagePermissions,
      });
    },
    labelCTA() {
      return this.$t('entitlement.upgrade_plan_cta');
    },
    permissionsGroupedByDomain() {
      return this.configurablePermissions.reduce((domains, permission) => {
        const domain = this.getDomainFromPermission(permission);
        let domainPermissions = [permission];

        if (domains[domain]) {
          domainPermissions = [...domains[domain], permission];
        }

        return { ...domains, ...{ [domain]: domainPermissions } };
      }, {});
    },
    isEntitledToManagePermissions() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.ACCOUNT__AUTHORIZATION__MANAGE_ROLE_PERMISSIONS);
    },
    minimumRequiredPlanToManagePermissions() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.ACCOUNT__AUTHORIZATION__MANAGE_ROLE_PERMISSIONS);
    },
    hasUpgradePermissions() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
  },
  methods: {
    getDomainFromPermission(permission) {
      return permission.name.split('.')[0];
    },
    handleToggleClick(permissionId, roleId) {
      this.$emit('change', permissionId, roleId);
    },
    getTranslatedPermissionName(permission) {
      return permission.split('.').join('_');
    },
    handleUpgrade() {
      this.$router.push({ name: 'subscription' });
    },
  },
};
</script>
