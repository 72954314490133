<template>
  <div ref="backdrop" class="trengo-modal-chat flex items-center justify-center md:p-8" @click.self="$emit('close')">
    <div
      class="box custom-shadow-outline relative flex h-full w-full select-none flex-col overflow-hidden rounded-lg"
      style="margin-bottom: 0"
      tabindex="0"
      @keydown.esc="$emit('close')"
    >
      <div class="modal-header">
        <h3 class="mb-0 text-ellipsis px-8 text-center" style="font-size: 18px; line-height: 1.5">
          {{
            total !== 1
              ? $t('internal_chat.search_attachment_modal_title_plural', { total: total, name: thread.name })
              : $t('internal_chat.search_attachment_modal_title_singular', { total: total, name: thread.name })
          }}
        </h3>
        <button type="button" class="modal-close-btn leading-none" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px" @click="$emit('close')">close</i>
        </button>
      </div>

      <div class="mx-4 flex lg:mx-8">
        <i
          class="material-icons icon-search b-2x flex items-center border-grey-200 pl-3 text-grey-600"
          @click="$refs.inputSearch.focus()"
        >
          search
        </i>
        <input
          ref="inputSearch"
          type="text"
          :value="queryValue"
          class="form-control input-search b-2x sm:mt-0"
          :placeholder="$t('internal_chat.search_attachment_modal_placeholder')"
          @input="(e) => (queryValue = e.target.value)"
        />
      </div>

      <!--            <div class="inline-flex w-100 bg-grey-200 rounded-lg text-grey-600 font-medium mt-3" style="height:40px;">-->
      <!--                <template v-for="(filter, key) in filters">-->
      <!--                    <span class="flex-1 flex justify-center items-center pointer rounded-lg b-2x" style="border-color:#ebedf5;" :key="key" :class="{ 'bg-white': key === activeItem}" @click="selectItem(key)">{{filter.title}}<span class="font-normal">&nbsp;({{filter.amount}})</span></span>-->
      <!--                </template>-->
      <!--            </div>-->

      <div v-if="attachments && attachments.length" class="scroll-on-hover" style="overflow-x: hidden" infinite-wrapper>
        <div class="pl-4 lg:pl-8 lg:pr-4">
          <div v-for="file in attachments" class="modal-item" role="button" @click="attachmentClicked(file)">
            <!--                <div v-for="file in attachments" role="button">-->
            <div class="overflow-hidden">
              <div class="flex py-4 text-center md:mx-4">
                <img
                  v-if="file.isImage() && file.getPreviewUrl()"
                  class="b-a open-external rounded-lg"
                  :src="file.getPreviewUrl()"
                  :title="file.getName()"
                  style="object-fit: cover; height: 45px; width: 45px"
                />
                <div
                  v-else
                  class="flex items-center justify-center rounded-lg bg-grey-200"
                  style="min-width: 45px; height: 45px"
                >
                  <i class="fa text-grey-800" style="font-size: 20px" :class="file.getIconClass()"></i>
                </div>

                <div class="ml-2 flex flex-col justify-center overflow-hidden pb-1" style="width: 100%">
                  <div class="inline-flex" style="height: 20px">
                    <strong
                      class="text-truncate mr-auto block text-right"
                      style="height: 20px; line-height: 1.2; max-width: 70%"
                    >
                      {{ file.getName() }}
                    </strong>
                    <span class="modal-item-hover mr-2 text-grey-600">
                      {{ $t('internal_chat.search_attachment_modal_hover_attachment') }}
                    </span>
                    <button
                      class="mb-1 ml-1 inline-flex items-center rounded-lg bg-grey-200"
                      style="height: 18px; padding: 0 5px"
                      @click.prevent.stop="downloadClicked(file)"
                    >
                      <span class="mr-1 h-full font-bold text-grey-600" style="line-height: 1.18">
                        .{{ file.getExtension() }}
                      </span>
                      <i
                        class="fa fa-download text-grey-600"
                        :aria-label="'Download ' + file.getName()"
                        style="font-size: 14px"
                      ></i>
                    </button>
                  </div>
                  <!-- File options -->
                  <div class="text flex items-center text-grey-600" style="margin-top: 2px">
                    <div class="attachment-by text-ellipsis text-left leading-none">
                      {{
                        $t('internal_chat.search_attachment_modal_attachment_shared_by', {
                          name: getUserById(file.userId).firstName,
                        })
                      }}
                    </div>
                    <span class="text-grey ml-2 mr-auto leading-none" style="font-size: 13px">
                      ({{ (file.size / (1024 * 1024)).toFixed(1) }} MB)
                    </span>
                    <!--                                    <div class="text-grey-500" style="font-size:13px;line-height:1;">{{moment.utc(file.createdAt*1000).format('LL')}}</div>-->
                    <datetime
                      :time="file.createdAt * 1000"
                      :pretty="$root.prettyDates"
                      :allow-change="false"
                      class="text-grey-500"
                      style="font-size: 13px; line-height: 1"
                    ></datetime>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading
          ref="scrollDown"
          direction="bottom"
          spinner="spiral"
          force-use-infinite-wrapper="true"
          class="fw-medium my-6 pr-2 md:pr-0 lg:pr-4"
          style="font-size: 16px"
          @infinite="loadMoreAttachmentsDown"
        >
          <template #no-more>
            <div>{{ $t('internal_chat.end_of_results') }}</div>
          </template>
          <template #no-results>
            <div>{{ $t('internal_chat.end_of_results') }}</div>
          </template>
          <template #spinner><div></div></template>
        </infinite-loading>
      </div>
      <div
        v-else-if="attachments && !attachments.length"
        class="no-results fw-medium mt-8 p-2 text-center"
        style="font-size: 20px"
      >
        {{ $t('internal_chat.no_results_found') }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';
import { mapGetters } from 'vuex';

import api from '../../Api';
import MessageAttachmentModel from '../../Models/MessageAttachmentModel';

export default {
  name: 'SearchAttachmentModal',
  emits: ['close'],
  components: { InfiniteLoading },
  props: {
    query: {
      type: String,
      default: '',
    },
    thread: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      queryValue: this.query,
      page: 1,
      attachments: null,
      total: '',
      // TODO: V2
      // filters: [
      //     {title:'All', amount:'25'},
      //     {title:'Images', amount:'10'},
      //     {title:'Documents', amount:'15'}
      // ],
      // activeItem: null,
    };
  },
  mounted() {
    if (!is_mobile_device()) {
      this.$refs.inputSearch.focus();
    }

    this.searchClicked();
  },
  methods: {
    attachmentClicked(file) {
      window.open(file.getUrl());
    },
    downloadClicked(file) {
      window.open(file.getUrl());
    },

    searchClicked: _.debounce(async function () {
      if (this.scroll) {
        this.scroll.reset();
      }

      this.page = 1;

      await api.threads.getAttachments(this.thread.id, this.page, this.queryValue).then((m) => {
        this.attachments = m.data.data.map((a) => new MessageAttachmentModel(a));
        this.total = m.data.meta.total;
      });
    }, 300),

    loadMoreAttachmentsDown(scroll) {
      this.scroll = scroll;
      this.page++;

      api.threads
        .getAttachments(this.thread.id, this.page, this.queryValue)
        .then((m) => {
          if (m.data.data.length) {
            m = m.data;

            this.attachments = [...this.attachments, ...m.data.map((m) => new MessageAttachmentModel(m))];
            this.scroll.loaded();
          } else {
            this.scroll.complete();
          }
        })
        .catch((e) => {
          console.error(e);
          this.scroll.error();
        });
    },
  },
  computed: {
    moment: () => moment,
    ...mapGetters({
      getUserById: 'usersInternalChat/userById',
    }),
  },
  watch: {
    queryValue() {
      this.searchClicked();
    },
  },
};
</script>

<style scoped lang="scss">
body {
  overflow-y: auto;
}

.message-body {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.trengo-modal-chat {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  overflow: hidden;

  .modal-header {
    border-bottom: 0 !important;
  }

  .modal-item:not(:first-child) {
    border-top: solid theme('colors.grey-200') 2px;
  }

  .modal-item {
    &:hover .modal-item-hover {
      display: block;
    }
  }

  .modal-item-hover {
    display: none;
    font-size: 12px;
    right: 120px;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    width: 700px;
    max-width: 100%;
    border-radius: 10px;
    border: none;
    max-height: 687px;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }

    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }

  .attachment-by {
    max-width: 65%;
    font-size: 14px;
  }

  .icon-search {
    font-size: 23px;
    border-right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: text;
  }

  .input-search {
    border-left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: theme('colors.grey-200');
  }

  .form-control::placeholder {
    color: theme('colors.grey-600') !important;
    opacity: 0.5 !important;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

@media (max-width: 800px) {
  .trengo-modal-chat {
    .scroll-on-hover {
      overflow-y: overlay !important;
      padding-right: 16px;
      &:hover {
        padding-right: 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  .trengo-modal-chat {
    .box {
      width: 100%;
      border-radius: 0;
    }
    .attachment-by {
      max-width: 45%;
    }
    .modal-item {
      &:hover .modal-item-hover {
        display: none;
      }
    }
  }
}

@media (max-width: 370px) {
  .trengo-modal-chat {
    .attachment-by {
      max-width: 40%;
    }
  }
}
</style>
