<template>
  <div v-if="loaded" class="row-col">
    <div class="t-shadow-xs block sm:hidden">
      <div class="navbar no-radius">
        <ul class="nav navbar-nav">
          <li class="nav-item" />
        </ul>
      </div>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="get-started-container px-4 pb-4 pt-8 sm:p-4 sm:px-20 sm:pb-0 sm:pt-12">
            <div class="t-text-desktop-paragraph-sm text-grey-600 sm:pb-1">
              {{ $t('getting_started.lets_get_started') }}
            </div>
            <div class="sm:t-text-desktop-h2 t-text-mobile-h3 text-grey-900">
              {{ $t('getting_started.youre_on_the_right_track') }}
            </div>
          </div>

          <div class="get-started-container mb-2 px-2 pt-0 sm:p-4 sm:px-20 sm:pb-2 sm:pt-0">
            <div class="justify-items-stretch pb-6 sm:py-8">
              <first-steps />
            </div>

            <div class="justify-items-stretch pb-6 sm:pb-8">
              <div class="t-text-desktop-label-lg pb-2 sm:pb-4">
                <span>{{ $t('getting_started.start_with_the_basics') }}</span>
              </div>
              <div class="pb-2 sm:pb-4">
                <shared-inbox />
              </div>
              <div v-if="$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)" class="pb-2 sm:pb-4">
                <connect-first-channel />
              </div>
              <div>
                <add-team-members />
              </div>
            </div>

            <div class="justify-items-stretch sm:pb-8">
              <div class="t-text-desktop-label-lg pb-2 sm:pb-4">
                <span>{{ $t('getting_started.increase_engagement_and_conversion') }}</span>
              </div>
              <div class="pb-2 sm:pb-4">
                <setup-web-widget />
              </div>
            </div>

            <div class="mt-12 flex flex-col sm:mt-0">
              <learn-more />
            </div>
            <div class="flex flex-col pt-4">
              <need-help-box />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import LearnMore from '@/components/OverviewPage/components/LearnMore';
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';

import {
  AddTeamMembers,
  ConnectFirstChannel,
  FirstSteps,
  NeedHelpBox,
  SetupWebWidget,
  SharedInbox,
} from '../components';

export default {
  name: 'GetStarted',
  data() {
    return {
      loaded: false,
      PERMISSION,
    };
  },
  components: {
    NeedHelpBox,
    SetupWebWidget,
    ConnectFirstChannel,
    LearnMore,
    FirstSteps,
    SharedInbox,
    AddTeamMembers,
  },
  mounted() {
    this.loaded = this.getSteps.length;
    eventBus.$emit('user-created-account');
    const mergeSeenAndUnSeen = [...new Set([...this.getUnSeenStepsTypes, ...this.getSeenStepsType])];
    this.updateUserConfig({ key: 'onboarding_steps.steps_seen', value: mergeSeenAndUnSeen });
    this.$nextTick(() => {
      this.updateBadgeStatus(false);
    });
  },
  computed: {
    ...mapGetters({
      getSteps: 'gamification/getSteps',
      getSeenStepsType: 'gamification/getSeenStepsType',
      getUnSeenStepsTypes: 'gamification/getUnSeenStepsTypes',
    }),
  },
  methods: {
    ...mapActions({
      updateBadgeStatus: 'gamification/updateBadgeStatus',
      updateUserConfig: 'userConfig/put',
    }),
  },
  watch: {
    getSteps(value) {
      this.loaded = !!value.length;
    },
  },
};
</script>

<style scoped src="./GetStarted.scss" lang="scss" />
