<template>
  <div class="flex flex-col justify-between xl:flex-row">
    <div class="flex flex-col">
      <div class="t-text-mobile-h2 sm:t-text-desktop-h3 mb-1 xl:mb-4">{{ $t('getting_started.settings') }}</div>
    </div>
    <div class="mb-8 sm:mb-0" v-if="hasNotSubscription">
      <div class="flex flex-col rounded-xl bg-grey-100 px-4 py-3 xl:flex-row xl:justify-between xl:py-2">
        <div class="mb-2 flex items-center justify-center xl:mb-0">
          <svg class="inline" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.41699 10C2.41699 5.81186 5.81217 2.41669 10.0003 2.41669C14.1885 2.41669 17.5837 5.81186 17.5837 10C17.5837 14.1882 14.1885 17.5834 10.0003 17.5834C5.81217 17.5834 2.41699 14.1882 2.41699 10ZM10.0003 0.916687C4.98374 0.916687 0.916992 4.98343 0.916992 10C0.916992 15.0166 4.98374 19.0834 10.0003 19.0834C15.0169 19.0834 19.0837 15.0166 19.0837 10C19.0837 4.98343 15.0169 0.916687 10.0003 0.916687ZM10.5307 6.13633C10.39 5.99568 10.1992 5.91666 10.0003 5.91666C9.80142 5.91666 9.61066 5.99568 9.47 6.13633L6.97 8.63633C6.67711 8.92922 6.67711 9.4041 6.97 9.69699C7.2629 9.98989 7.73777 9.98989 8.03066 9.69699L9.25033 8.47732L9.25033 13.3333C9.25033 13.7475 9.58612 14.0833 10.0003 14.0833C10.4145 14.0833 10.7503 13.7475 10.7503 13.3333L10.7503 8.47732L11.97 9.69699C12.2629 9.98989 12.7378 9.98989 13.0307 9.69699C13.3236 9.4041 13.3236 8.92922 13.0307 8.63633L10.5307 6.13633Z"
              fill="#49B2A1"
            />
          </svg>
          <span class="t-text-desktop-body-sm mx-2" v-html="daysLeftText" />
        </div>
        <div
          v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
          class="flex justify-center"
        >
          <atomic-button @click="handleClickUpgrade" btn-style="secondary" size="sm">
            <span class="t-text-button-sm">{{ $t('getting_started.upgrade_now') }}</span>
            <img class="ml-1 inline h-16px" :src="`${$root.assetsURL}img/arrow-right.svg`" alt="upgrade" />
          </atomic-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

export default {
  name: 'TopHeader',
  data() {
    return {
      PERMISSION,
    };
  },
  methods: {
    handleClickUpgrade() {
      router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS);
    },
  },
  computed: {
    hasNotSubscription() {
      return this.$root?.companyProfile?.subscription?.plan_id === null;
    },
    daysLeftText() {
      const days = this.daysLeft;
      return `${days} day${days !== 1 ? 's' : ''} left in <strong>free trial</strong>`;
    },
    daysLeft() {
      return this.$root.companyProfile.subscription.trial_ends_at_days || 0;
    },
  },
};
</script>
