<template>
  <div
    v-if="Object.keys($root.user).length"
    class="flex h-full min-w-0 flex-col"
    :class="{ electron: isElectron, 'electron-mac': isElectronMac, 'electron-windows': isElectronWindows }"
    style="overflow: hidden"
  >
    <portal to="body-portal">
      <div
        v-show="whatsNewOpen"
        style="position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: black; opacity: 0.2; z-index: 9999"
        class="nav-bg"
      ></div>
      <div
        v-show="showMenu"
        style="position: fixed; left: 0; top: 0; right: 0; bottom: 0; opacity: 0; z-index: 999"
        class="nav-bg"
        @click="showMenu = false"
      ></div>
      <div v-show="nav" class="sn-overlay nav-bg" @click="nav = false"></div>
      <toast></toast>
    </portal>
    <service-notifications></service-notifications>
    <div
      v-show="showMenu"
      class="animated slideInUp main-app-drawer flex select-none flex-col rounded-3xl bg-black p-4 pt-0 text-white"
      style="animation-duration: 0.2s"
    >
      <div class="flex w-full">
        <div
          class="flex w-full flex-col items-center justify-center"
          style="margin-top: -25px; margin-right: -10px"
          :class="{ 'brightness-60': profileHover }"
        >
          <avatar
            class="border-2 border-black bg-white"
            width="55"
            :color="$root.user.color"
            :abbr="$root.user.abbr.substr(0, 1)"
            :image="$root.user.profile_image"
          />
          <span class="mb-2 pt-2 text-lg font-bold leading-none text-white" @click="showMenu = false">
            {{ $root.user.first_name }} {{ $root.user.last_name }}
          </span>
        </div>
        <div class="absolute right-3">
          <cross-linear
            size="1.5em"
            class="mt-3 cursor-pointer text-grey-500 hover:brightness-90"
            @click="showMenu = false"
          />
        </div>
      </div>

      <div class="align-center mb-1 flex justify-center pb-4 pt-2">
        <router-link to="/profile" @click="showMenu = false">
          <div
            class="
              hover:t-shadows-button-sm
              h-32px
              cursor-pointer
              rounded-full
              border-1 border-grey-700
              px-6
              py-1
              hover:border-grey-300
            "
          >
            <span class="t-text-desktop-label-sm">{{ $t('user_profile.general_edit_your_profile') }}</span>
          </div>
        </router-link>
      </div>
      <div class="hidden-lg-up -mx-1 mt-2 flex flex-wrap">
        <router-link
          to="/tickets"
          class="
            selector-navigate-inbox
            mx-1
            mt-2
            flex flex-1 flex-col
            items-center
            justify-center
            rounded-lg
            bg-grey-800
            py-3
            hover:brightness-90
          "
          active-class="uber-nav-state-active"
          style="min-width: 40%"
          @click="showMenu = false"
        >
          <span class="mb-2">
            <inbox-fill size="1.5rem" />
          </span>
          <span class="leading-none">Inbox</span>
        </router-link>
        <router-link
          v-if="chatEnabled"
          to="/chat"
          class="
            selector-navigate-team-chat
            mx-1
            mt-2
            flex flex-1 flex-col
            items-center
            justify-center
            rounded-lg
            bg-grey-800
            py-3
            hover:brightness-90
          "
          active-class="uber-nav-state-active"
          style="min-width: 40%"
          @click="showMenu = false"
        >
          <div class="relative flex flex-col text-center">
            <span class="mb-2 flex justify-center">
              <users-fill size="1.5rem" />
            </span>
            <span class="leading-none">{{ $t('navigation.chat') }}</span>
            <team-chat-unread-badge
              v-if="totalUnreadChatMessagesCount() > 0 || totalUnreadChatMentionsCount()"
              :total-unread-chat-messages-count="totalUnreadChatMessagesCount()"
              :total-unread-chat-mentions-count="totalUnreadChatMentionsCount()"
              message-indicator-classes="bg-white"
              style="right: -5px; top: -3px"
            />
          </div>
        </router-link>
        <router-link
          v-if="$authorization().hasPermission(PERMISSION.BROADCASTING__GENERAL__MANAGE)"
          to="/broadcasting"
          class="
            mx-1
            mt-2
            flex flex-1 flex-col
            items-center
            justify-center
            rounded-lg
            bg-grey-800
            py-3
            hover:brightness-90
          "
          active-class="uber-nav-state-active"
          style="min-width: 40%"
          @click="showMenu = false"
        >
          <megaphone-linear class="mb-1" size="24" />
          <span class="leading-none">Broadcast</span>
        </router-link>
        <router-link
          v-if="$root.hasHelpcenter && $authorization().hasPermission(PERMISSION.HELP_CENTER__GENERAL__MANAGE)"
          to="/help_center"
          class="
            open-external
            selector-navigate-help-center
            mx-1
            mt-2
            flex flex-1 flex-col
            items-center
            justify-center
            rounded-lg
            bg-grey-800
            py-3
            hover:brightness-90
          "
          active-class="uber-nav-state-active"
          style="min-width: 40%"
          @click="showMenu = false"
        >
          <span><i class="material-icons">help</i></span>
          <span class="leading-none">Help Center</span>
        </router-link>
      </div>
      <div class="hidden-lg-up mt-6 flex flex-col pl-2 pr-4">
        <router-link
          class="selector-navigate-contacts mb-2 flex items-center text-white hover:brightness-90"
          to="/contacts"
          active-class="uber-nav-state-active2"
          @click="showMenu = false"
        >
          <user-square-fill size="1.5rem" />
          <span class="t-text-desktop-paragraph-sm ml-2 flex-1 leading-none text-white">
            {{ $t('navigation.contacts') }}
          </span>
        </router-link>
        <router-link
          class="selector-navigate-profiles mb-2 flex items-center text-white hover:brightness-90"
          to="/profiles"
          active-class="uber-nav-state-active2"
          @click="showMenu = false"
        >
          <users-fill size="1.5rem" />
          <span class="t-text-desktop-paragraph-sm ml-2 flex-1 leading-none text-white">
            {{ $t('navigation.profiles') }}
          </span>
        </router-link>
        <router-link
          v-if="$authorization().hasPermission(PERMISSION.REPORTING__GENERAL__MANAGE)"
          class="hidden-md-down selector-navigate-reports mb-2 flex items-center text-white hover:brightness-90"
          to="/statistics"
          active-class="uber-nav-state-active2"
          @click="showMenu = false"
        >
          <gear-fill size="1.5rem" />
          <span class="t-text-desktop-paragraph-sm ml-2 flex-1 leading-none text-white">
            {{ $t('navigation.reports') }}
          </span>
        </router-link>
        <div class="flex items-center">
          <router-link
            v-if="hasAnySettingsPermission"
            class="selector-navigate-settings flex items-center text-white hover:brightness-90"
            to="/admin"
            active-class="uber-nav-state-active2"
            @click="showMenu = false"
          >
            <gear-fill size="1.5rem" />
            <span class="t-text-desktop-paragraph-sm ml-2 flex-1 leading-none text-white">
              {{ $t('navigation.settings') }}
            </span>
          </router-link>
        </div>
      </div>
      <div data-test="profile-settings-items-modal" class="mt-6 flex flex-col">
        <user-status />
        <voip-status v-if="$root.hasAcccessToVoipChannel"></voip-status>
        <chat-status v-if="chatEnabled"></chat-status>
      </div>
      <div class="mt-6 flex text-xs text-grey-600">
        <div class="flex flex-row">
          <sign-in-fill size="1.1rem" class="mr-1" />
          <button class="t-text-desktop-label-xs selector-logout hover:brightness-90" @click="handleLogout">
            {{ $t('general.logout') }}
          </button>
        </div>
        <a
          href="https://help.trengo.com"
          target="_blank"
          class="selector-get-help ml-auto flex items-center text-sm text-grey-500 hover:brightness-90"
          @click="showMenu = false"
        >
          <span class="t-text-desktop-label-xs text-xs leading-none">{{ $t('general.get_help') }}</span>
        </a>
      </div>
    </div>
    <div class="flex min-w-0 flex-1 flex-row" style="overflow: hidden">
      <div class="main-nav-desktop-placeholder hidden-md-down flex h-full flex-shrink-0 flex-col items-center pt-4">
        <div class="flex flex-col items-center justify-center">
          <router-link
            v-tooltip="{ placement: 'right', content: 'Inbox', popperClass: 'tooltip-vuetify' }"
            to="/tickets"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            class="icon-navigation selector-navigate-inbox"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
            data-test="main-navigation-inbox"
            @click="handleMainNavigationItem('mainNavInbox')"
          >
            <icon-inbox />
          </router-link>
          <router-link
            v-if="showAISettings"
            v-tooltip="{ placement: 'right', content: $t('navigation.ai_settings'), popperClass: 'tooltip-vuetify' }"
            class="icon-navigation selector-navigate-inbox mt-2"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink, active: routeIsAIorAITerms }"
            :to="{ name: 'aiDashboardTerms' }"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            data-test="main-navigation-ai"
          >
            <icon-ai />
          </router-link>
          <router-link
            v-if="showWorkflows"
            v-tooltip="{ placement: 'right', content: 'Workflows', popperClass: 'tooltip-vuetify' }"
            class="icon-navigation selector-navigate-inbox mt-2"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink, active: routeIsWorkflows }"
            :to="{ name: 'workflows.overview' }"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            data-test="main-navigation-workflows"
          >
            <icon-workflows />
          </router-link>
          <router-link
            v-if="chatEnabled"
            v-tooltip="{ placement: 'right', content: $t('navigation.team_chat'), popperClass: 'tooltip-vuetify' }"
            to="/chat"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            class="selector-navigate-team-chat icon-navigation relative mt-2"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
            data-test="main-navigation-team-chat"
            @click="handleMainNavigationItem('mainNavTeamChat', 'Team chat')"
          >
            <icon-team-chat />
            <team-chat-unread-badge
              :total-unread-chat-messages-count="totalUnreadChatMessagesCount()"
              :total-unread-chat-mentions-count="totalUnreadChatMentionsCount()"
            />
          </router-link>
          <div
            v-if="$authorization().hasPermission(PERMISSION.BROADCASTING__GENERAL__MANAGE)"
            class="mt-2 flex w-full flex-col items-center justify-center"
          >
            <router-link
              v-tooltip="{ placement: 'right', content: 'Broadcast', popperClass: 'tooltip-vuetify' }"
              class="open-external icon-navigation selector-navigate-contacts"
              :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
              to="/broadcasting"
              :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
              data-test="main-navigation-broadcasting"
              @click="handleMainNavigationItem('mainNavBroadcasting')"
            >
              <icon-broadcast />
            </router-link>
          </div>
          <router-link
            v-if="$authorization().hasPermission(PERMISSION.HELP_CENTER__GENERAL__MANAGE)"
            v-tooltip="{ placement: 'right', content: $t('navigation.help_center'), popperClass: 'tooltip-vuetify' }"
            to="/help_center"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            class="open-external icon-navigation selector-navigate-help-center mt-2"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
            data-test="main-navigation-help-center"
            @click="handleMainNavigationItem('mainNavHelpCenter')"
          >
            <icon-help-center />
          </router-link>
        </div>
        <div class="mx-3 hidden flex-col items-center justify-center pt-2 lg:flex">
          <router-link
            v-tooltip="{ placement: 'right', content: $t('navigation.contacts'), popperClass: 'tooltip-vuetify' }"
            class="open-external icon-navigation selector-navigate-contacts"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
            to="/contacts"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            data-test="main-navigation-contacts"
            @click="handleMainNavigationItem('mainNavContacts')"
          >
            <icon-contacts />
          </router-link>
          <router-link
            v-if="$authorization().hasPermission(PERMISSION.REPORTING__GENERAL__MANAGE) && !isMobile"
            v-tooltip="{ placement: 'right', content: $t('navigation.reports'), popperClass: 'tooltip-vuetify' }"
            class="icon-navigation selector-navigate-reports mt-2"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
            to="/reports"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            data-test="main-navigation-reports"
            @click="handleMainNavigationItem('mainNavReports')"
          >
            <icon-reports />
          </router-link>
          <router-link
            v-if="hasAnySettingsPermission"
            v-tooltip="{ placement: 'right', content: $t('navigation.settings'), popperClass: 'tooltip-vuetify' }"
            class="icon-navigation open-external selector-navigate-settings relative mt-2"
            :class="{ inactive: mainNavActiveItemIsNonRouterLink }"
            :to="adminLink"
            :tabindex="!userOnboardingHasBeenFinished ? '-1' : '0'"
            data-test="main-navigation-settings"
            @click="handleMainNavigationItem('mainNavSettings')"
          >
            <icon-settings />
            <span
              v-if="showNewBadge"
              class="
                absolute
                right-2
                top-2
                inline-block
                h-2
                w-2
                -translate-y-1/2
                translate-x-1/2
                transform
                rounded-full
                bg-white
              "
            />
          </router-link>
        </div>
        <div class="mt-auto flex flex-col items-center">
          <div
            data-test="profile-avatar-navbar"
            role="button"
            class="selector-toggle-menu mb-5 flex cursor-pointer items-center justify-center rounded-full text-grey-700"
            :class="{ 'main-menu-avatar-active': showMenu }"
            @click="handleAvatarClicked"
          >
            <avatar
              width="40"
              :color="$root.user.color"
              :abbr="$root.user.abbr.substr(0, 1)"
              :image="$root.user.profile_image"
            />
          </div>
        </div>
      </div>
      <div
        v-if="showMainSideBarContainer"
        class="sn w-275 text-15 flex flex-none flex-col border-grey-300 bg-white pr-0 lg:border-r"
        :class="{
          'hidden-md-down': !nav,
          'main-app-sidebar': shouldShowMainAppSideBar,
          'main-app-sidebar-contacts': isContactRouteActive,
          'main-app-sidebar-reporting': isNewReportingRouteActive,
        }"
      >
        <div class="scroll-on-hover flex-1 overflow-x-hidden pt-4 lg:pt-0">
          <portal-target name="sidebar"></portal-target>
          <portal-target v-if="isComponent('tickets')" name="sidebar/tickets"></portal-target>
          <portal-target v-if="isComponent('chat')" name="sidebar/threads"></portal-target>
        </div>
        <portal-target name="sidebar/bottom"></portal-target>
      </div>
      <div
        v-show="hamburger"
        class="hidden-lg-up flex flex-grow flex-row items-center"
        style="position: absolute; top: 18px; z-index: 99"
        @click="toggleMenu"
      >
        <i class="material-icons pl-4">menu</i>

        <team-chat-unread-badge
          v-if="isComponent('chat') && (totalUnreadChatMessagesCount() > 0 || totalUnreadChatMentionsCount())"
          :total-unread-chat-messages-count="totalUnreadChatMessagesCount()"
          :total-unread-chat-mentions-count="totalUnreadChatMentionsCount()"
          style="right: -15px; top: -13px"
        />
      </div>
      <router-view></router-view>
      <div v-if="isComponent('tickets') && !isMobile" style="width: 73px" class="hidden-md-down flex bg-white">
        <chat-sidebar></chat-sidebar>
      </div>
    </div>
    <trial-expired-modal></trial-expired-modal>
    <session-limit-handler></session-limit-handler>
    <create-ticket-modal></create-ticket-modal>
    <forward-ticket-modal></forward-ticket-modal>
    <reminder-modal></reminder-modal>
    <send-as-modal></send-as-modal>
    <onboarding-modal
      v-if="
        $root.onboarding &&
        !isLegacyAgency() &&
        onboardingStepsEnabled &&
        $authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
      "
    />
    <confetti></confetti>
    <widget-preview></widget-preview>
    <mandatory-two-factor-auth-grace-period-reminder-modal></mandatory-two-factor-auth-grace-period-reminder-modal>

    <pricing-migration-quote-available-modal
      v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
    ></pricing-migration-quote-available-modal>
    <add-related-ticket-modal></add-related-ticket-modal>
    <edit-user-modal
      v-if="
        openEditUserModal && editUserModalUser && $authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
      "
      v-model="openEditUserModal"
      :initial-record-prop="editUserModalUser"
    />
    <key-shortcuts-modal></key-shortcuts-modal>
    <jump-to-modal @close="handleMainNavigationItem" @open-edit-user-modal="handleOpenEditUserModal"></jump-to-modal>
    <portal-target name="body-portal" multiple></portal-target>
    <portal-target name="voip-portal"></portal-target>
    <modal-container></modal-container>
    <onboarding-notifications></onboarding-notifications>
    <sip-panel></sip-panel>
    <video-call-popup></video-call-popup>
    <get-started-popup
      v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
      v-model="showOnboardingProgressModal"
    />
    <t-confirm />
    <permissions-changed-modal v-if="permissionsChanged"></permissions-changed-modal>
    <div class="main-nav" :class="{ 'hidden-md-down': $route.name === 'tickets-show' || hideMobileNav }">
      <div
        class="
          hidden-lg-up
          main-nav-trengo-icon
          relative
          flex
          cursor-pointer
          select-none
          items-center
          rounded
          bg-grey-200
          px-3
          py-2
          hover:brightness-90
        "
        style="width: 78px; padding: 7px !important"
        @click="showMenu = !showMenu"
      >
        <div style="width: 36px; height: 28px; overflow: hidden">
          <team-chat-unread-badge
            v-if="chatEnabled && (totalUnreadChatMessagesCount() > 0 || totalUnreadChatMentionsCount())"
            :total-unread-chat-messages-count="totalUnreadChatMessagesCount()"
            :total-unread-chat-mentions-count="totalUnreadChatMentionsCount()"
          />
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 112 87"
            style="enable-background: new 0 0 112 87"
            xml:space="preserve"
          >
            <rect class="cls-2" x="23.52" y="14.53" width="80.73" height="51.87" rx="12.88" fill="#fff" />
            <path
              d="M56.88,1.32c-85.61,7.5-57.02,51.13-24.15,70.31c33.43,19.51,48.99,23.09,68.33-7.15C101.05,64.48,142.49-6.18,56.88,1.32z
                                     M35.47,42.9c0-2.77,2.27-5.04,5.04-5.04h25.33c2.77,0,5.04,2.27,5.04,5.04s-2.27,5.04-5.04,5.04H40.51
                                    C37.74,47.94,35.47,45.68,35.47,42.9z M81.69,62.35h-34.1c-2.77,0-5.04-2.27-5.04-5.04s2.27-5.04,5.04-5.04h34.1
                                    c2.77,0,5.04,2.27,5.04,5.04S84.46,62.35,81.69,62.35z M81.69,33.54H32.23c-2.77,0-5.04-2.27-5.04-5.04s2.27-5.04,5.04-5.04h49.46
                                    c2.77,0,5.04,2.27,5.04,5.04S84.46,33.54,81.69,33.54z"
            />
          </svg>
        </div>
        <i class="material-icons ml-1" style="font-size: 14px">expand_more</i>
      </div>
      <div
        v-if="$authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
        class="main-nav-voip"
        @click="handleMainNavigationItem('mainNavVoice')"
      >
        <voip-panel @close="handleMainNavigationItem" @update-showMenu="updateShowMenu">
          <template #icon-voice>
            <div>
              <a
                class="container-icon icon-navigation select-none"
                :class="{ 'main-menu-item-active': sidebarNavigationActiveItem === 'mainNavVoice' }"
                data-test="main-navigation-voice"
              >
                <icon-voice class="icon-voice" />
              </a>
            </div>
          </template>
        </voip-panel>
      </div>

      <div
        v-tooltip="{ placement: 'right', content: $t('general.jump_to'), popperClass: 'tooltip-vuetify' }"
        class="main-nav-updates hidden-md-down icon-navigation selector-jump-to cursor-pointer"
        :class="{ 'main-menu-item-active': sidebarNavigationActiveItem === 'mainNavJumpToModal' }"
        data-test="main-navigation-jump-to"
        @click="handleMainNavigationItem('mainNavJumpToModal')"
      >
        <icon-jump-to />
      </div>
      <div
        v-tooltip="{ placement: 'right', content: $t('general.notifications'), popperClass: 'tooltip-vuetify' }"
        class="main-nav-notifications icon-navigation"
        :class="{ 'main-menu-item-active': sidebarNavigationActiveItem === 'mainNavNotifications' }"
      >
        <notification-panel
          @open="handleMainNavigationItem('mainNavNotifications')"
          @close="handleMainNavigationItem"
        />
      </div>
      <div
        v-if="isComponent('tickets') && $authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
        id="keyboardShortCutCreateTicket"
        class="hidden-lg-up main-nav-create-ticket"
        :class="{ 'hidden-md-down': $route.name !== 'tickets-index' }"
        @click="createConv"
      >
        <div v-tooltip="$t('general.create_message')" class="flex">
          <i class="material-icons md-16">edit</i>
        </div>
      </div>
    </div>

    <transition name="toast-slide-fade">
      <t-toast
        v-if="toastMessageStore.showToast"
        :has-icon="toastMessageStore.hasIcon"
        :icon-type="toastMessageStore.iconType"
        :has-close-icon="toastMessageStore.hasCloseIcon"
        :is-open="toastMessageStore.showToast"
        @close="toastMessageStore.closeToastMessage()"
      >
        <div>{{ toastMessageStore.text }}</div>
      </t-toast>
    </transition>
  </div>
</template>

<script type="text/babel">
import {
  CrossLinear,
  GearFill,
  InboxFill,
  MegaphoneLinear,
  SignInFill,
  UserSquareFill,
  UsersFill,
} from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { mapActions, mapGetters } from 'vuex';

import TConfirm from '@/components/common/TConfirm';
import TeamChatUnreadBadge from '@/components/common/TeamChatUnreadBadge';
import TToast from '@/components/common/TToast/Index';
import GetStartedPopup from '@/components/GetStartedPopup/GetStartedPopup';
import iconAi from '@/components/sidebarNavigationIcons/iconAI';
import iconBroadcast from '@/components/sidebarNavigationIcons/iconBroadcast';
import iconContacts from '@/components/sidebarNavigationIcons/iconContacts';
import iconHelpCenter from '@/components/sidebarNavigationIcons/iconHelpCenter';
import iconInbox from '@/components/sidebarNavigationIcons/iconInbox';
import iconJumpTo from '@/components/sidebarNavigationIcons/iconJumpTo';
import iconReports from '@/components/sidebarNavigationIcons/iconReports';
import iconSettings from '@/components/sidebarNavigationIcons/iconSettings';
import iconTeamChat from '@/components/sidebarNavigationIcons/iconTeamChat';
import iconVoice from '@/components/sidebarNavigationIcons/iconVoice';
import iconWorkflows from '@/components/sidebarNavigationIcons/iconWorkflows';
import EditUserModal from '@/components/Users/components/EditUsers/EditUserModal';
import PermissionsChangedModal from '@/components/Users/components/PermissionsChangedModal';
import {
  FEATURE_FLAG_INBOX,
  PERMISSION,
  SETTINGS_PERMISSIONS,
  FEATURE_FLAG_ACTIVATION,
  FEATURE_FLAG_WORKFLOWS,
} from '@/Configs/Constants';
import { FEATURE } from '@/Configs/Constants/Feature';
import { AUTH_URL } from '@/Configs/Constants/navigation/authUrl';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import eventBus from '@/eventBus';
import { waitForEvent } from '@/start/util';
import { useAuthStore, useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';
import { useToastMessageStore } from '@/store/pinia/toastMessage';

import AddRelatedTicketModal from './AddRelatedTicketModal';
import avatar from './Avatar';
import ChatSidebar from './Chat/Chat.vue';
import Confetti from './Confetti.vue';
import CreateTicketModal from './CreateTicketModal.vue';
import ForwardTicketModal from './ForwardTicketModal.vue';
import ChatStatus from './InternalChat/Components/ChatStatus';
import VideoCallPopup from './InternalChat/Components/VideoCallPopup';
import jumpToModal from './JumpToModal';
import keyShortcutsModal from './KeyShortcutsModal';
import MandatoryTwoFactorAuthGracePeriodReminderModal from './MandatoryTwoFactorAuthGracePeriodReminderModal';
import ModalContainer from './ModalContainer';
import notificationPanel from './Notifications/NotificationPanel.vue';
import OnboardingModal from './Onboarding/OnboardingModal.vue';
import OnboardingNotifications from './Onboarding/OnboardingNotifications';
import OnboardingStepsIndicator from './Onboarding/OnboardingStepsIndicator';
import PricingMigrationQuoteAvailableModal from './PricingMigrationQuoteAvailableModal';
import ReminderModal from './ReminderModal.vue';
import SendAsModal from './SendAsModal.vue';
import ServiceNotifications from './ServiceNotifications';
import sessionLimitHandler from './sessionLimitHandler';
import Toast from './Toast.vue';
import trialExpiredModal from './TrialExpiredModal.vue';
import userStatus from './UserStatus.vue';
import voipPanel from './Voip/Panel/Panel.vue';
import voipStatus from './Voip/Panel/VoipStatus.vue';
import SipPanel from './Voip/Sip/Sip';
import WidgetPreview from './WidgetPreview';

const { TRENGO, ADMINISTRATOR, SUPERVISOR } = USER_ROLE;
export default {
  name: 'Dashboard',
  components: {
    TToast,
    AddRelatedTicketModal,
    ChatSidebar,
    ChatStatus,
    Confetti,
    CreateTicketModal,
    CrossLinear,
    EditUserModal,
    ForwardTicketModal,
    GearFill,
    GetStartedPopup,
    InboxFill,
    MandatoryTwoFactorAuthGracePeriodReminderModal,
    PricingMigrationQuoteAvailableModal,
    MegaphoneLinear,
    ModalContainer,
    OnboardingModal,
    OnboardingNotifications,
    PermissionsChangedModal,
    ReminderModal,
    SendAsModal,
    ServiceNotifications,
    SignInFill,
    SipPanel,
    TConfirm,
    TeamChatUnreadBadge,
    Toast,
    UserSquareFill,
    UsersFill,
    VideoCallPopup,
    WidgetPreview,
    avatar,
    iconAi,
    iconBroadcast,
    iconContacts,
    iconHelpCenter,
    iconInbox,
    iconJumpTo,
    iconReports,
    iconSettings,
    iconTeamChat,
    iconVoice,
    iconWorkflows,
    jumpToModal,
    keyShortcutsModal,
    notificationPanel,
    sessionLimitHandler,
    trialExpiredModal,
    userStatus,
    voipPanel,
    voipStatus,
  },

  watch: {
    showMenu(value) {
      if (!value) {
        return;
      }
    },
    $route(route, prev) {
      this.showMenu = false;
      this.nav = false;
      this.showStatusPanel = route.path.includes('/tickets') || route.path.startsWith('/chat');
      this.setHamburgerState();

      // Remember route when switching from teamchat<>tickets
      if (route.name === 'chat-index' && prev.name === 'tickets-show') {
        this.prevTicketPath = prev.path;
      }

      if (route.name === 'tickets-index' && prev.name === 'chat-thread' && this.prevTicketPath) {
        router.push(this.prevTicketPath);
        this.prevTicketPath = null;
      }
    },

    userNeedsNewPassword() {
      this.checkIfNeedPassword();
    },
  },

  data() {
    return {
      showOnboardingProgressModal: false,
      nav: false,
      hamburger: true,
      showStatusPanel: false,
      showMenu: false,
      whatsNewOpen: false,
      profileHover: false,
      prevTicketPath: null,
      hideMobileNav: false,
      isMobile: window.document.body.clientWidth <= 767 || window.is_mobile_device(),
      openEditUserModal: false,
      editUserModalUser: false,
      sidebarNavigationActiveItem: '',
      permissionsChanged: false,
      toastMessageStore: useToastMessageStore(),
      PERMISSION,
      buildInfo: __BUILD_INFORMATION__,
    };
  },

  computed: {
    ...mapStores(useAuthStore, useEntitlementsStore),
    showAISettings() {
      return (
        useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.AI_SETTINGS) &&
        [TRENGO, ADMINISTRATOR, SUPERVISOR].includes(this.$root.user.role.name) &&
        !this.isMobile
      );
    },
    showWorkflows() {
      // return true;
      return (
        useFeatureFlagStore().isEnabled(FEATURE_FLAG_WORKFLOWS.WF_SHOW_NAVIGATION) &&
        [TRENGO, ADMINISTRATOR, SUPERVISOR].includes(this.$root.user.role.name) &&
        !this.isMobile
      );
    },
    isFirstLogin() {
      const userFirstLogin = this.getStepByType('user.create_account')?.completed;
      this.updateBadgeStatus(!userFirstLogin);
      return !userFirstLogin;
    },
    adminLink() {
      return !this.isFirstLogin ? '/admin' : '/admin/get-started';
    },
    userOnboardingHasBeenFinished() {
      return !this.$root.onboarding;
    },
    chatEnabled() {
      return !useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.TEAM_CHAT_DEPRECATION);
    },
    userNeedsNewPassword() {
      return this.$root.user.needs_new_pw;
    },
    onboardingStepsEnabled() {
      return this.$store.state.onboardingSteps.enabled;
    },
    ...mapGetters({
      thread: 'chat/currentThread',
      totalUnreadChatMentionsCount: 'chat/totalUnreadMentionsCount',
      totalUnreadChatMessagesCount: 'chat/totalUnreadMessageCount',
      getStepByType: 'gamification/getStepByType',
      getSteps: 'gamification/getSteps',
      showNewBadge: 'gamification/getShowNewBadge',
      shouldRedirectToMFASetup: 'initialData/shouldRedirectToMFASetup',
    }),
    isElectron() {
      return window.isElectron;
    },
    isElectronMac() {
      return window.isElectron && window.isElectron.platform === 'darwin';
    },
    isElectronWindows() {
      return window.isElectron && window.isElectron.platform === 'win32';
    },
    isNotComponentWabBroadcastingOrUnauthorized() {
      return !this.isComponent('wabBroadcasting') && !this.isComponent('unauthorized');
    },
    isNotComponentAIOrUnauthorized() {
      return (
        !this.isComponent('AISettings') && !this.isComponent('AISettingsTerms') && !this.isComponent('unauthorized')
      );
    },
    mainNavActiveItemIsNonRouterLink() {
      return (
        this.sidebarNavigationActiveItem === 'mainNavJumpToModal' ||
        this.sidebarNavigationActiveItem === 'mainNavNotifications' ||
        this.sidebarNavigationActiveItem === 'mainNavVoice'
      );
    },

    routeIsAIorAITerms() {
      return this.$route.name === 'aiDashboard' || this.$route.name === 'aiDashboardTerms';
    },

    routeIsWorkflows() {
      return this.$route.name === 'workflowsOverview';
    },

    shouldShowMainAppSideBar() {
      return !this.isNewReportingRouteActive && !this.isContactRouteActive;
    },
    isContactRouteActive() {
      const routes = ['/contacts/all', '/sms/import', '/profiles', '/contact_groups'];
      return routes.some((route) => this.$route.path.includes(route));
    },
    isNewReportingRouteActive() {
      const isOldReportingPageEnabled = this.$route.path.includes('/reports/old');
      return this.$route.matched.some(({ regex }) => regex?.test(`/reports`)) && !isOldReportingPageEnabled;
    },
    isReportingRouteActive() {
      return this.$route.matched.some(({ regex }) => regex?.test(`/reports`));
    },
    hasVoipChannelAccess() {
      return this.$root.hasAcccessToVoipChannel && !this.isReportingRouteActive;
    },
    hasAnySettingsPermission() {
      return this.$authorization().hasAnyPermission(SETTINGS_PERMISSIONS);
    },
    showMainSideBarContainer() {
      if (this.isComponent('workflows')) {
        return false;
      }
      if (!this.isComponent('reports')) {
        return this.isNotComponentWabBroadcastingOrUnauthorized && this.isNotComponentAIOrUnauthorized;
      } else {
        return (
          this.isNotComponentWabBroadcastingOrUnauthorized &&
          this.isNotComponentAIOrUnauthorized &&
          this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__REPORTING)
        );
      }
    },
  },
  async beforeMount() {
    await waitForEvent('initial-data.loaded', this.$store.state.initialData.agency.id);
    if (this.shouldRedirectToMFASetup) {
      this.$router.push({ name: 'setup-2fa' });
    }
  },
  async mounted() {
    if (window.APP_ENV !== 'development') {
      console.info(`%cBuild: ${this.buildInfo.hash}`, 'color: #EF2F54; background: #2F2F2F;');
    }

    if (!Object.keys(this.$root.user).length) {
      $('#TrengoLoader').show();
    }
    eventBus.$on('role-permissions-updated', (data) => this.handlePermissionsChanged(data));
    eventBus.$on('user-role-updated', (data) => this.handleRoleChanged(data));

    eventBus.$on('showOnboardingProgressPopup', (value) => {
      this.showOnboardingProgressModal = value;
    });

    eventBus.$on('hideNav', () => {
      this.nav = false;
    });

    eventBus.$on('showThreadOverlay', () => {
      this.nav = false;
    });

    this.showStatusPanel = this.$route.path.includes('/tickets') || this.$route.path.includes('/chat');

    if (!(await this.$tStorage.getItem('team_chat_sidebar'))) {
      this.$tStorage.setItem('team_chat_sidebar', '0');
    }

    this.setHamburgerState();

    eventBus.$on('scroll.top', () => {
      if (this.$route.name === 'tickets-index') {
        document.querySelector('[data-list="tickets"]').scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (this.$route.name === 'tickets-show') {
        document.querySelector('[data-list="ticket"]').scrollTo({ top: 0, behavior: 'smooth' });
      }
    });

    if (is_mobile_device()) {
      eventBus.$on('chat@FOCUS_COMPOSER', () => {
        this.hideMobileNav = true;
      });
      eventBus.$on('chat@BLUR_COMPOSER', () => {
        setTimeout(() => {
          this.hideMobileNav = false;
        }, 1);
      });
    }
  },

  methods: {
    handleLogout() {
      this.authStore.logout();
      location.assign(AUTH_URL.LOGOUT); // can't use router here, the logout happens on the server
    },
    handlePermissionsChanged(data) {
      this.permissionsChanged = this.$root.user.role.name === data.role.name;
    },
    handleRoleChanged() {
      this.permissionsChanged = true;
    },
    handleMainNavigationItem(activeItem = '') {
      if (
        activeItem &&
        activeItem !== 'mainNavJumpToModal' &&
        activeItem !== 'mainNavNotifications' &&
        activeItem !== 'mainNavVoice'
      ) {
        this.sidebarNavigationOldActiveItem = activeItem;
      } else if (!activeItem) {
        this.sidebarNavigationActiveItem = this.sidebarNavigationOldActiveItem;
      } else {
        this.sidebarNavigationActiveItem = activeItem;
      }

      if (activeItem === 'mainNavJumpToModal') eventBus.$emit('jumpToModal');
    },
    handleOpenEditUserModal(userId) {
      this.editUserModalUser = this.$root.users.find((user) => user.id === userId);
      this.openEditUserModal = true;
    },
    ...mapActions({
      updateBadgeStatus: 'gamification/updateBadgeStatus',
    }),
    handleAvatarClicked() {
      this.showMenu = !this.showMenu;
    },
    checkIfNeedPassword() {
      if (this.userNeedsNewPassword && this.$route.path !== AUTH_URL.PASSWORD) {
        router.push(AUTH_URL.PASSWORD);
        return;
      }
    },
    setHamburgerState() {
      this.hamburger = !(
        ['tickets-show', 'profiles-show', 'contacts-show'].includes(this.$route.name) ||
        this.$route.path.startsWith('/admin/channels2/email/') ||
        this.$route.path.startsWith('/broadcasting')
      );
    },
    async toggleTeamChatSidebar() {
      this.$tStorage.setItem(
        'team_chat_sidebar',
        (await this.$tStorage.getItem('team_chat_sidebar')) === '0' ? '1' : '0'
      );
      this.showChatSidebar = await this.$tStorage.getItem('team_chat_sidebar');
    },
    async openChatSidebar() {
      this.$tStorage.setItem('team_chat_sidebar', '1');
      this.showChatSidebar = await this.$tStorage.getItem('team_chat_sidebar');
    },
    toggleMenu() {
      if ((!this.$route.fullPath.endsWith('/chat') && !this.$route.fullPath.endsWith('/chat/')) || this.thread) {
        this.nav = true;
      } else if ((this.$route.fullPath.endsWith('/chat') || this.$route.fullPath.endsWith('/chat/')) && !this.thread) {
        eventBus.$emit('chat-nav-integrated');
      }
    },
    createConv() {
      eventBus.$emit('modals.create-ticket.open');
    },
    createGroup() {
      eventBus.$emit('chat@SHOW_MANAGE_GROUP_MODAL', null);
    },
    isLegacyAgency() {
      if (moment(this.$root.companyProfile.profile.created_at).isBefore('2020-04-09')) {
        return true;
      }
    },

    isComponent(component) {
      switch (component) {
        case 'chat':
          return this.$route.path.startsWith('/chat');
        case 'tickets':
          return this.$route.path.startsWith('/tickets');
        case 'wabBroadcasting':
          return this.$route.path.startsWith('/broadcasting');
        case 'AISettings':
          return this.$route.path.startsWith('/ai');
        case 'AISettingsTerms':
          return this.$route.path.startsWith('/ai/terms');
        case 'unauthorized':
          return this.$route.path.startsWith('/unauthorized');
        case 'reports':
          return this.$route.path.startsWith('/reports');
        case 'workflows':
          return this.$route.path.startsWith('/workflows');
      }
    },

    addGroup() {
      eventBus.$emit('chat@SHOW_MANAGE_GROUP_MODAL', null);
    },
    updateShowMenu(value) {
      this.showMenu = value;
    },
  },
};
</script>

<style lang="scss">
.sidebar i.collapse_icon {
  color: theme('colors.grey-700');
  opacity: 1;
  vertical-align: middle;
  padding-top: 2px;
}

.main-app-drawer {
  width: 350px;
  position: fixed;
  bottom: 2.5rem;
  left: 88px;
  z-index: 9999;
}

.main-nav-trengo-icon {
  padding: 0.25rem 0.75rem;
  height: 46px;
}

.main-nav {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  height: 55px;
  border-top: 2px solid #f1f1f1;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0 30px;
  color: theme('colors.grey-600');
}

@media (min-width: 991px) {
  .main-nav-desktop-placeholder {
    width: 72px;
    @apply bg-grey-900;
  }

  .main-app-sidebar {
    padding-top: 1rem;
  }

  .main-nav {
    background: none;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    bottom: 121px;
    width: 72px;
    flex-direction: column;

    .main-nav-inbox {
      display: none;
    }

    .main-nav-voip {
      position: fixed;
      z-index: 99;
      color: theme('colors.grey-500');
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      left: 12px;
      bottom: 180px;
      top: auto;
    }

    .main-nav-notifications {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .main-nav-updates {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-bottom: 0.5rem;
      position: relative;
    }
  }

  .sidebar-bottom {
    @apply w-full bg-white;
    box-shadow: 0px -1px 4px rgba(255, 255, 255, 0.8), 0px -4px 24px var(--color-white);
    clip-path: inset(-24px 0px 0px 0px);
  }

  .divider {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }

  .icon-navigation {
    @apply flex h-12 w-12 items-center justify-center rounded-full;

    .icon-active {
      @apply hidden;
    }

    .svg-fill {
      fill: var(--color-grey-400);
    }

    &:hover {
      .svg-fill {
        fill: var(--color-white);
      }

      .svg-stroke {
        stroke: var(--color-white);
      }
    }

    .svg-stroke {
      stroke: var(--color-grey-400);
    }

    &.main-menu-item-active,
    &.active {
      @apply bg-grey-800;

      .svg-fill,
      .svg-stroke,
      .svg-inactive {
        @apply hidden;
      }

      .icon-active {
        @apply block;
      }
    }

    &.inactive {
      @apply bg-transparent;

      .svg-fill,
      .svg-stroke {
        @apply block;
      }

      .icon-active {
        @apply hidden;
      }
    }

    .icon-label {
      margin-top: -1.5rem;
    }
  }
}

@media (max-width: 991px) {
  .nav-bg {
    background: black;
    opacity: 0.3 !important;
  }

  /*.main-nav {*/
  .main-nav-trengo-icon {
    background: white;
    padding: 0;
    width: auto !important;
    height: auto;
  }

  .main-app-drawer {
    /* top: 10%; */
    top: auto;
    right: 0px;
    /* width: 100%; */
    /* height: 70%; */
    bottom: 0px;
    left: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    width: auto;
  }

  .main-nav-create-ticket {
    position: absolute;
    bottom: 80px;
    right: 20px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: white;
    display: flex;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
    color: theme('colors.grey-600');
    z-index: 999;
  }

  .main-nav-notifications {
    margin-left: 2.625rem;
  }

  .icon-navigation .icon-active {
    @apply hidden;
  }

  .t-badge-notification {
    top: -0.625rem;
    right: -0.75rem;
  }

  .icon-navigation .icon-voice svg {
    stroke: var(--color-grey-900);
  }
}

.navigation-dropdown-more {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .sn {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    width: 80% !important;
    background: white;
    z-index: 999;
  }

  .sn-overlay {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    width: 20%;
    background: #0000006b;
    z-index: 999999;
  }

  .navigation-dropdown-more {
    transform-origin: bottom right !important;
    left: auto !important;
    right: 0 !important;
    margin-right: -10px;
    bottom: 50px !important;
  }
}

@media only screen and (min-width: 991px) {
  .slideInUp {
    transform: none !important;
    animation: none !important;
  }
}

@media only screen and (max-width: 991px) {
  body.hw-open {
    #HW_frame_cont {
      max-width: 80% !important;
      left: 10% !important;
      right: 10% !important;
      top: 10% !important;
      margin-left: 0 !important;
    }
  }

  .btn-ticket-action {
    height: 40px !important;
    width: 40px !important;
    padding: 14px;
  }

  .btn-ticket-assign {
    height: 40px !important;

    span {
      max-height: 40px !important;
      max-width: 40px !important;
    }
  }
}

@media only screen and (min-width: 991px) and (max-width: 1599px) {
  .main-app-sidebar {
    width: 225px !important;
    padding-right: 0;
  }

  .main-app-sidebar-reporting {
    @apply w-66 #{!important};
  }

  .tickets_list {
    width: 300px !important;
  }

  .sidebar-container {
    width: 300px !important;
  }
}

.icon-toggle {
  width: 45px;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.material-icons {
  user-select: none;
}

.uber-nav-state-active {
  background: #4c4c4c;
}

.uber-nav-state-active2 {
  font-weight: bold;

  i {
    color: white;
  }
}

.has-swipe-events {
  // fix for: https://github.com/bsdfzzzy/vue2-hammer/issues/23
  touch-action: pan-y !important;
}

@media only screen and (max-width: 991px) {
  /*//https://borderleft.com/toolbox/rem/*/
  /*.text-xs {*/
  /*    font-size: 0.75rem !important;*/
  /*}*/
  /*.text-sm {*/
  /*    font-size: 0.875rem !important;*/
  /*}*/
  /*.text-md {*/
  /*    font-size: 1rem !important;*/
  /*}*/
  /*.text-lg {*/
  /*    font-size:  1emrem !important;*/
  /*}*/
}

.HW_badge_cont {
  position: absolute !important;
  top: -2px;
  right: -5px;
}

.HW_badge {
  background: #e8374c !important;
}

.HW_badge.HW_softHidden {
  opacity: 0 !important;
}

.chat_sidebar[x-placement='right'] {
  left: -50px !important; //  tmp fix
}

.box {
  border: none !important;
}

.main-menu-avatar-active {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.2);
}

.toast-slide-fade-enter-active,
.toast-slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.toast-slide-fade-enter-from {
  opacity: 0;
  bottom: 28px;
}

.toast-slide-fade-leave-to {
  opacity: 0;
  bottom: 0px;
}

.toast-slide-fade-enter-to {
  opacity: 1;
  bottom: 16px;
}
</style>
