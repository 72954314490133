<script type="text/babel">
import dompurify from 'dompurify';

import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';

import contactCustomFieldEditor from './ContactCustomFieldEditor.vue';
import contactGroupEditor from './ContactGroupEditor.vue';
import inlineEditable from './ContactNameEditable.vue';
import voipCallable from '../../mixins/VoipCallable';
import avatar from '../Avatar.vue';
import ContentEditable from '../ContentEditable';
import Dropdown from '../ReplyForm/Dropdown';
import timeline from '../Timeline.vue';

export default {
  name: 'Contact',
  emits: ['contactNameUpdated'],
  mixins: [voipCallable],
  data: function () {
    return {
      handleInfiniteScroll: true,
      page: 0,
      list: [],
      contact: {
        notes: [],
      },
      loading: false,
      noteForm: {
        note: '',
      },
      sending: false,
      PERMISSION,
    };
  },

  computed: {
    notes() {
      return (
        this.contact?.notes.map((note) => {
          return { ...note, ...{ body: dompurify.sanitize(note.body, { ALLOWED_TAGS: ['br'], ALLOWED_ATTR: [] }) } };
        }) || []
      );
    },
  },

  components: {
    Dropdown,
    ContentEditable,
    avatar,
    contactCustomFieldEditor,
    contactGroupEditor,
    inlineEditable,
    timeline,
  },

  mounted: function () {
    this.loading = true;
    this.fetchData();
  },

  methods: {
    addNote: function () {
      this.noteForm.note = this.noteForm.note.replace(/&nbsp;/gi, ' ');
      this.noteForm.note = this.noteForm.note.replace(/\s+/gi, ' ');

      if (!this.noteForm.note) {
        return;
      }
      this.sending = true;
      axios
        .post('/api/v2/contacts/' + this.$route.params.id + '/notes', { note: this.noteForm.note })
        .then((result) => {
          this.noteForm.note = '';
          this.fetchData();
          this.sending = false;
          this.$refs.noteBody.$el.innerText = ''; // Content editable should update model values..
        });
    },

    fetchData: function () {
      axios
        .get('/api/v2/contacts/' + this.$route.params.id)
        .then((result) => {
          this.contact = result.data;
          this.loading = false;
        })
        .catch((e) => {
          router.push('/contacts');
        });
    },

    async deleteNote(note_id) {
      const shouldDeleteNote = await this.$tConfirm('Are you sure you want to delete this note?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteNote) {
        axios.delete('/api/v2/contacts/' + this.$route.params.id + '/notes/' + note_id).then((result) => {
          this.fetchData();
        });
      }
    },

    async deleteContact() {
      const shouldDeleteContact = await this.$tConfirm(
        'This action will remove all conversations linked to this contact. Are you really sure? This action cannot be undone.',
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteContact) {
        axios.delete('/api/v2/contacts/' + this.contact.id).then((result) => {
          router.push('/contacts');
        });
      }
    },

    createTicket() {
      eventBus.$emit('modals.create-ticket.open', {
        contact: this.contact,
        channel: this.contact.channels[0],
      });
    },

    downloadPdf() {
      axios.get('/api/v2/contacts/' + this.contact.id + '/pdf').then((res) => {
        var a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + res.data.pdf;
        a.download = 'Contact_' + this.contact.id + '.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },

    canBeCalledWithVoip() {
      return this.$root.voipReady === true && this.contact.is_phone === true;
    },

    callContact() {
      eventBus.$emit('voip.call', {
        phone: '+' + this.contact.phone,
      });
    },

    hasProfile() {
      return typeof this.contact.profile != 'undefined' && this.contact.profile.length > 0;
    },

    showProfile() {
      this.$router.push('/profiles/' + this.contact.profile[0]['id']);
    },

    onContactUpdated(name) {
      this.contact.full_name = name.value;
      this.$emit('contactNameUpdated', this.contact);
    },

    getReturnUrl() {
      return this.$router.back();
    },

    onContentEditableBlur(value) {
      this.noteForm.note = value;
    },
  },
};
</script>

<template>
  <div class="scroll-on-hover w-full flex-1">
    <div v-if="!loading" class="w-full">
      <div class="mb-4 mt-4 flex flex-1 flex-wrap items-center lg:mb-8">
        <div class="flex min-w-0 flex-1 items-center pr-4">
          <div class="pl-4 lg:p-0">
            <a @click="getReturnUrl"><i class="material-icons md-32">arrow_back</i></a>
          </div>
          <div class="mx-4">
            <avatar
              :color="contact.color"
              :abbr="contact.abbr"
              :image="contact.profile_image"
              width="45"
              data-hj-suppress
            ></avatar>
          </div>
          <div class="text-ellipsis">
            <h6 id="ContactName" class="mb-1 text-ellipsis pb-0 leading-none" data-hj-suppress>
              <inline-editable
                mode="popup"
                placement="bottom"
                :contact_id="this.contact.id"
                :model-value="contact.full_name"
                @saved="onContactUpdated"
              ></inline-editable>
            </h6>
            <div class="text-ellipsis text-grey-500" data-hj-suppress>
              <i v-if="contact.is_private" class="fa fa-lock mr-1 text-grey-500"></i>
              {{ contact.identifier }}
            </div>
          </div>
        </div>
        <div class="hidden-md-down ml-auto flex flex-wrap">
          <a
            v-if="$authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
            class="mr-2 rounded bg-green px-4 py-2 text-white"
            @click="createTicket"
          >
            <i class="fa fa-pencil mr-1"></i>
            {{ $t('contacts.add_ticket') }}
          </a>
          <a class="mr-2 rounded bg-grey-200 px-4 py-2 text-grey-500" @click="downloadPdf()">
            <i class="fa fa-file-pdf-o mr-1"></i>
            {{ $t('contacts.download_pdf') }}
          </a>
          <a v-if="canBeCalledWithVoip()" class="text-grey mr-2 rounded bg-grey-200 px-4 py-2" @click="callContact()">
            <i class="fa fa-phone mr-1"></i>
            {{ $t('contacts.call') }}
          </a>
          <a v-if="hasProfile()" class="mr-2 rounded bg-grey-200 px-4 py-2 text-grey-500" @click="showProfile()">
            <i class="fa fa-user mr-1"></i>
            {{ $t('tickets.show_attached_profile') }}
          </a>
          <a
            v-if="$authorization().hasPermission(PERMISSION.CONTACTS__CONTACTS__DELETE)"
            class="rounded bg-grey-200 px-4 py-2 text-grey-500"
            @click="deleteContact"
          >
            <i class="fa fa-trash mr-1"></i>
            {{ $t('contacts.delete') }}
          </a>
        </div>
        <div class="hidden-sm-up">
          <div class="nav-item dropdown">
            <dropdown :static="true" :search="false" :auto-height="true">
              <template #toggle>
                <a class="mr-4">
                  <i class="material-icons">more_horiz</i>
                </a>
              </template>
              <template #heading>
                <div
                  class="
                    _500
                    hidden-lg-up
                    select-none
                    border-grey-200
                    py-4
                    text-center text-base
                    leading-none
                    text-black
                  "
                >
                  {{ $t('general.nav_options') }}
                </div>
              </template>
              <template #body>
                <a
                  v-if="
                    contact.channels &&
                    contact.channels.length > 0 &&
                    contact.channels[0].type != 'CHAT' &&
                    $authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)
                  "
                  class="align-items m-0 flex flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                  @click="createTicket"
                >
                  <i class="fa fa-pencil mr-1"></i>
                  {{ $t('contacts.add_ticket') }}
                </a>
                <a
                  class="align-items m-0 flex flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                  @click="downloadPdf()"
                >
                  <i class="fa fa-file-pdf-o mr-1"></i>
                  {{ $t('contacts.download_pdf') }}
                </a>
                <a
                  v-if="hasProfile()"
                  class="align-items m-0 flex flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                  @click="showProfile()"
                >
                  <i class="fa fa-user mr-1"></i>
                  {{ $t('tickets.show_attached_profile') }}
                </a>
                <a
                  v-if="$authorization().hasPermission(PERMISSION.CONTACTS__CONTACTS__DELETE)"
                  class="align-items m-0 flex flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                  @click="deleteContact"
                >
                  <i class="fa fa-trash mr-1"></i>
                  {{ $t('contacts.delete') }}
                </a>
              </template>
            </dropdown>
          </div>
        </div>
      </div>

      <div class="flex flex-1 flex-wrap p-4 lg:p-0" data-hj-suppress>
        <div class="w-full pb-4 lg:w-1/3 lg:pr-6">
          <h6 class="mb-4">{{ $t('contacts.custom_fields') }}</h6>
          <div class="border-b border-grey-300 pb-4">
            <contact-custom-field-editor :contact="contact" :title="false"></contact-custom-field-editor>
          </div>

          <h6 class="mb-4 mt-6">{{ $t('contacts.groups') }}</h6>
          <div v-if="$root.contactGroups.length > 0" class="rounded-lg border-1 border-grey-200 p-4">
            <contact-group-editor :contact="contact" :title="false"></contact-group-editor>
          </div>
        </div>
        <div class="mt-2 w-full pb-4 lg:mt-0 lg:w-1/3 lg:pr-6">
          <h6 class="mb-4">{{ $t('contacts.notes') }}</h6>
          <form @submit.prevent="addNote">
            <div class="flex rounded-lg border-2 border-grey-200">
              <div class="overflow-hidden rounded-lg" style="margin-left: -2px; margin-top: -2px">
                <avatar
                  width="45"
                  class="-ml-1 -mt-1"
                  :color="this.$root.user.color"
                  :abbr="this.$root.user.abbr"
                  :image="this.$root.user.profile_image"
                ></avatar>
              </div>
              <div class="flex-1 p-4">
                <content-editable
                  id="NoteTextArea"
                  ref="noteBody"
                  v-model="noteForm.note"
                  :return-plain-text="false"
                  rows="2"
                  :placeholder="$t('notes.create_note_placeholder')"
                  @blur="onContentEditableBlur"
                  @enter="addNote"
                ></content-editable>
              </div>
            </div>
          </form>
          <div v-if="notes && notes.length > 0">
            <div v-for="note in notes" class="w-full">
              <div class="mt-2 flex w-full min-w-0 rounded-lg border-2 border-grey-200">
                <div class="overflow-hidden rounded-lg" style="flex-shrink: 0; margin-left: -2px; margin-top: -2px">
                  <avatar
                    width="45"
                    class="-ml-1 -mt-1"
                    :color="contact.color"
                    :abbr="contact.abbr"
                    :image="contact.profile_image"
                  ></avatar>
                </div>
                <div class="w-full p-4" style="word-break: break-all" v-html="note.body"></div>
              </div>
              <div class="flex items-center text-grey-600">
                <span class="text-xs">
                  {{ note.user ? note.user.first_name : $t('general.deleted_user') }}
                </span>
                <span class="mx-2 text-xs">
                  <datetime :time="note.created_at" :pretty="$root.prettyDates"></datetime>
                </span>
                <a style="padding-top: 1px" @click="deleteNote(note.id)"><i class="material-icons text-xs">clear</i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 h-full w-full lg:mt-0 lg:w-1/3">
          <h6 class="mb-4">{{ $t('ticket_sidebar.contact_moments') }}</h6>
          <timeline v-if="!loading" :contact="contact"></timeline>
        </div>
      </div>
    </div>
  </div>
</template>
