<template>
  <modal-base
    v-model="computedValue"
    :submit-button-text="getSubmitButtonText"
    :cancel-button-text="$t('user_management.cancel')"
    class="add-user-modal"
    :title="$t('user_management.invite_new_user')"
    @submit="save"
  >
    <template #content>
      <div class="mb-4">
        <div>
          <div class="t-text-desktop-label-md mb-2 text-grey-800 sm:mt-4 md:mt-1">
            {{ $t('user_management.email') }}
          </div>
          <atomic-input
            v-model="email"
            :placeholder="$t('user_management.agent_at_mailcom')"
            size="md"
            autofocus
            :error="!validation.email.email"
            data-test="email-input"
            @keydown="handleKeyboardEvent($event)"
          >
            <template v-if="saving && validateFields && !validation.email.unique" #end>
              <loader2-linear size="1rem" class="button-loading-spinner mx-3 text-grey-600" />
            </template>
            <template
              v-else-if="validateFields && validation.email.email && validation.email.unique && submittedEmail"
              #end
            >
              <check-linear size="1rem" class="mx-3 text-leaf-500" />
            </template>
          </atomic-input>
          <t-error-item
            v-if="!validation.email.email"
            :text="$t('user_management.validation_invalid_email_address')"
            data-test="invalid-email-error"
          />
          <t-error-item
            v-else-if="!validation.email.unique && validation.email.email && submittedEmail"
            :text="$t('user_management.validation_email_already_in_use')"
            data-test="email-in-use-error"
          />
          <transition name="fade">
            <t-inline-banner
              v-if="!saving && !validation.email.unique && validation.email.email && submittedEmail"
              class="mt-3"
              data-test="resend-info"
            >
              <template #icon>
                <info-linear />
              </template>
              <div>
                {{ $t('user_management.want_to_resend_invite', { email: submittedEmail }) }}
              </div>
            </t-inline-banner>
          </transition>
        </div>
        <div>
          <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.name') }}</div>
          <div class="md:flex">
            <div class="md:mr-1 md:w-1/2">
              <atomic-input
                v-model="firstname"
                :placeholder="$t('user_management.first_name')"
                size="md"
                required
                :error="!validation.firstname"
                data-test="firstname-input"
                @keydown="handleKeyboardEvent($event)"
              />
              <t-error-item
                v-if="!validation.firstname"
                :text="$t('user_management.validation_first_name_required')"
                data-test="firstname-error"
              />
            </div>
            <div class="mt-2 md:ml-1 md:mt-0 md:w-1/2">
              <atomic-input
                v-model="lastname"
                :placeholder="$t('user_management.last_name')"
                size="md"
                :error="!validation.lastname"
                data-test="lastname-input"
                @keydown="handleKeyboardEvent($event)"
              />
              <t-error-item
                v-if="!validation.lastname"
                :text="$t('user_management.validation_last_name_required')"
                data-test="lastname-error"
              />
            </div>
          </div>
          <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.user_role') }}</div>
          <t-dropdown
            :key="role"
            v-model="role"
            :items="roles(true)"
            :placeholder="$t('user_management.pick_an_option')"
            size="md"
            value-index="value"
            text-index="title"
            :error="!validation.role"
            container-text-ellipsis
            data-test="role-input"
          />
          <t-error-item
            v-if="!validation.role"
            :text="$t('user_management.please_select_a_user_role')"
            data-test="role-error"
          />
          <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.teams') }}</div>
          <t-multi-select
            v-model="team"
            :placeholder="$t('user_management.select_one_or_more_teams')"
            :use-icon="false"
            :has-avatar="false"
            is-closable
            :data="teams"
            size="md"
            variant="leaf"
            :error="!validation.teams"
            limited-height
            data-test="teams-input"
          />
          <t-error-item
            v-if="!validation.teams"
            :text="$t('user_management.please_select_a_team')"
            data-test="teams-error"
          />
          <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.language') }}</div>
          <t-dropdown
            v-model="locale_code"
            size="md"
            :placeholder="$t('user_management.select_a_language')"
            :items="languages"
            value-index="id"
            text-index="title"
            data-test="language-input"
          />
        </div>
      </div>
      <transition name="fade">
        <t-inline-banner
          v-if="!hasPermissionToManageSubscription && !$userPlan().isTrial"
          type="warning"
          class="sticky bottom-0 z-10"
          data-test="user-limit-info"
        >
          <template #icon>
            <alert-triangle-linear />
          </template>
          <div>
            {{ $t('user_management.invitation_user_limit_error') }}
          </div>
        </t-inline-banner>
      </transition>
    </template>
    <template #footer-left>
      <div class="mr-0 flex justify-center md:mr-auto">
        <transition-group name="fade" class="flex items-center">
          <template v-if="showUsersAmount">
            <span key="usersLength" class="t-text-sm text-grey-900" data-test="users-label">
              {{ usersAmount }}
            </span>
            <span
              v-if="subscription.quantity"
              key="subscriptionUsersQuantity"
              class="t-text-sm text-grey-700"
              data-test="subscription-users-label"
            >
              /{{ $t('user_management.subscription_quantity_users', { subscription_quantity: subscription.quantity }) }}
            </span>
            <span
              v-if="!hasPermissionToManageSubscription"
              key="tooltipPlan"
              v-tooltip="{
                placement: 'bottom',
                content: $t('user_management.administrator_can_upgrade_subscription_to_add_more_user_seats'),
                popperClass: 'tooltip-vuetify',
                trigger: getTooltipEvent,
              }"
              class="
                tooltip-user-modal
                ml-1.5
                flex
                h-16px
                w-16px
                cursor-default
                items-center
                justify-center
                rounded-full
                bg-grey-600
                font-bold
                text-white
                hover:bg-grey-800
              "
            >
              i
            </span>
          </template>
        </transition-group>
      </div>
    </template>
    <template v-if="saving" #button-spinner>
      <loader2-linear size="1rem" class="button-loading-spinner ml-1 text-grey-500" />
    </template>
  </modal-base>
</template>

<script>
import { AlertTriangleLinear, CheckLinear, InfoLinear, Loader2Linear } from '@trengo/trengo-icons';
import { map } from 'lodash';

import { fetchTeams, createUser, fetchAllUsers } from '@/api';
import ModalBase from '@/components/OverviewPage/components/ModalBase';
import { PERMISSION } from '@/Configs/Constants';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import eventBus from '@/eventBus';
import RolesDropdown from '@/mixins/RolesDropdown';
import breakpoints from '@/util/breakpoints';
import { validateEmail } from '@/util/helpers';
export default {
  name: 'AddUserModal',
  emits: ['update:modelValue', 'update', 'close'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AlertTriangleLinear,
    CheckLinear,
    InfoLinear,
    Loader2Linear,
    ModalBase,
  },
  mixins: [RolesDropdown],
  data() {
    return {
      user: {
        role: {
          name: USER_ROLE.BASIC_AGENT,
        },
      },
      breakpoints,
      firstname: '',
      lastname: '',
      email: '',
      submittedEmail: '',
      teams: [],
      team: [],
      locale_code: 'nl-NL',
      languages: [
        {
          id: 'nl-NL',
          title: this.$t('user_management.nl'),
        },
        {
          id: 'en-GB',
          title: this.$t('user_management.en'),
        },
      ],
      saving: false,
      validation: {
        email: {
          email: true,
          unique: true,
        },
        firstname: true,
        lastname: true,
        locale_code: true,
        role: true,
        teams: true,
      },
      validationTriggered: false,
      subscription: {},
      usersAmount: -1,
      duplicatedUserRecord: null,
    };
  },
  watch: {
    email(value) {
      if (this.validationTriggered) {
        this.validation.email.email = validateEmail(value);
        this.validation.email.unique = true;
      }
    },
    firstname(value) {
      this.validation.firstname = !!value;
    },
    lastname(value) {
      this.validation.lastname = !!value;
    },
    locale_code(value) {
      this.validation.locale_code = !!value;
    },
    role(value) {
      this.validation.role = !!value;
    },
    team(value) {
      this.validation.teams = !!value.length;
    },
  },
  async mounted() {
    const userList = await fetchAllUsers({ cacheEnabled: false });
    this.$root.users = userList.data.users;
    this.usersAmount = this.$root.getBillableUsers().length;

    fetchTeams().then((response) => {
      this.teams = response.data.data;
    });
  },
  computed: {
    showUsersAmount() {
      return this.usersAmount && !this.$userPlan().isTrial;
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    role: {
      get() {
        return this.currentRole;
      },
      set(value) {
        this.user.role.name = value;
      },
    },
    hasPermissionToManageSubscription() {
      return this.$authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE);
    },
    isMdOrMore() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_md || comparisons.eq_lg || comparisons.eq_xl;
    },
    getSubmitButtonText() {
      return this.isMdOrMore ? this.$t('user_management.invite_user') : this.$t('user_management.invite');
    },
    getTooltipEvent() {
      return this.isMdOrMore ? 'hover' : 'hover focus click';
    },
    validateFields() {
      return (
        validateEmail(this.email) &&
        this.validation.firstname &&
        this.validation.lastname &&
        this.validation.locale_code &&
        this.validation.role &&
        this.validation.teams
      );
    },
  },
  methods: {
    save() {
      if (this.saving) {
        return;
      }
      this.validationTriggered = true;
      this.submittedEmail = '';
      this.setValidationFields();

      if (!this.validateFields) {
        return;
      }

      this.saving = true;
      this.storeUser();
    },
    isAssignable() {
      return true;
    },
    storeUser() {
      const data = this.getUserObject();

      createUser(data)
        .then(async () => {
          this.submittedEmail = this.email;
          this.validation.email.unique = true;
          this.$emit('update');
          const userList = await fetchAllUsers({ cacheEnabled: false });
          this.$root.users = userList.data.users;
          setTimeout(() => {
            this.closeModal();
            this.saving = false;
            this.flashSuccess(this.$t('user_management.user_invite_success', { email: data.email }));
            eventBus.$emit('user-invite-first-team-member');
          }, 600);
        })
        .catch((error) => {
          if (error.response?.status !== 403) {
            this.submittedEmail = this.email;
            this.validation.email.unique = !(error.response.data.errors.email && this.validation.email.email);
          }

          this.saving = false;
        });
    },

    mapTeamIds() {
      return map(this.team, 'id');
    },
    getUserObject() {
      return {
        ...{ team_ids: this.mapTeamIds() },
        ...{
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          role: this.user.role.name,
          team: this.team,
          locale_code: this.locale_code,
        },
      };
    },

    setValidationFields() {
      this.validation.email.email = validateEmail(this.email);
      this.validation.firstname = !!this.firstname;
      this.validation.lastname = !!this.lastname;
      this.validation.locale_code = !!this.locale_code;
      this.validation.role = !!this.role;
      this.validation.teams = !!this.team.length;
    },

    closeModal() {
      this.computedValue = false;
    },
    handleKeyboardEvent(event) {
      if (event.key === 'Enter') {
        this.save();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/UserModalTemplate.scss';
</style>
