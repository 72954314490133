<script type="text/babel">
import _ from 'lodash';

import SidebarCollapse from '@/components/common/SidebarCollapse';

export default {
  props: ['contact', 'selectedPlugins'],

  data: function () {
    return {
      selected: [],
    };
  },

  mounted: function () {
    this.init();
  },

  components: {
    SidebarCollapse,
  },

  watch: {
    contact() {
      this.init();
    },
  },

  methods: {
    init() {
      this.setSelected();
    },
    setSelected() {
      this.selected = _.map(this.contact.groups, (group) => {
        return group.id;
      });
    },

    save() {
      axios.put('/api/v2/contacts/' + this.contact.id, { contact_group_ids: this.selected });
    },
  },
};
</script>

<template>
  <div v-if="$root.contactGroups.length > 0" class="text-left">
    <sidebar-collapse collapse-key="collapseTicketSidebarContactGroupEditor">
      <template #header>
        <h6 class="sidebar_block_title mb-0">
          {{ $t('ticket_sidebar.contact_groups') }}
        </h6>
      </template>
      <div class="sidebar_block_body" style="position: relative; margin: 0 20px">
        <div v-for="contactGroup in $root.contactGroups" class="p-b-xs mt-3">
          <span class="contact-group-checkbox items-center justify-center">
            <label
              class="md-check items-center justify-center"
              :class="{ checked: selected.indexOf(contactGroup.id) > -1 }"
            >
              <i class="custom-contact-group-checkbox bg-green text-white" style="margin-top: 0"></i>
              <span class="p-l-xs">{{ contactGroup.name }}</span>
            </label>
          </span>
          <label class="hiddenCheckbox" style="margin-bottom: 3px">
            <input v-model="selected" type="checkbox" :value="contactGroup.id" @change="save" />
            <span>{{ contactGroup.name }}</span>
          </label>
        </div>
      </div>
    </sidebar-collapse>
  </div>
</template>
<style>
.contact-group-checkbox i {
  margin-right: 25px !important;
}

.contact-group-checkbox i:before {
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
}

.contact-group-checkbox i:after {
  left: 7px !important;
  top: 3px !important;
}

.hiddenCheckbox {
  cursor: pointer;
  position: absolute;
  z-index: 20;
  left: 10px;
  height: 25px;
  width: 200px;
  border: 1px solid red;
  opacity: 0;
}
</style>
