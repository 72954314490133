<template>
  <div>
    <usage-progress-card
      :spent-amount="standardConversations?.finished ?? 0"
      :quota="standardConversations?.quota ?? 0"
      :in-progress-amount="standardConversations?.inProgress ?? 0"
      format="number"
      :type="'standard_conversations'"
      :show-button="!isNearingTheEndOfBillingCycle && !isInTrial"
      :period-type="usageOverview.periodType"
      :is-unlimited="isInTrial"
    >
      <template #icon>
        <t-thumbnail size="default">
          <comments-linear size="m" />
        </t-thumbnail>
      </template>
      <template #title>{{ $t('usage_overview.overview_section_standard_conversations_heading') }}</template>
      <template #info>
        <info-fill v-tooltip="{ content: $t('usage_overview.overview_section_conversations_tooltip') }" size="1rem" />
      </template>
      <template #button>
        <t-button-text @click="() => $router.push({ name: 'subscription' })">
          {{ $t('usage_overview.overview_section_conversations_cta') }}
          <arrow-right-linear size="1.5rem" />
        </t-button-text>
      </template>
    </usage-progress-card>
    <separator />

    <template v-if="isAutomedConversationsEnabled">
      <usage-progress-card
        :spent-amount="intelligentConversations?.finished ?? 0"
        :quota="intelligentConversations?.quota ?? 0"
        :in-progress-amount="intelligentConversations?.inProgress ?? 0"
        format="number"
        :type="'intelligent_conversations'"
        :period-type="usageOverview.periodType"
        :show-button="!isNearingTheEndOfBillingCycle && !isInTrial"
        :is-unlimited="isInTrial"
      >
        <template #icon>
          <t-thumbnail size="default">
            <comments-linear size="m" />
          </t-thumbnail>
        </template>
        <template #title>{{ $t('usage_overview.overview_section_intelligent_conversations_heading') }}</template>
        <template #info>
          <info-fill v-tooltip="{ content: $t('usage_overview.overview_section_conversations_tooltip') }" size="1rem" />
        </template>
        <template #button>
          <t-button-text @click="() => $router.push({ name: 'subscription' })">
            {{ $t('usage_overview.overview_section_conversations_cta') }}
            <arrow-right-linear size="1.5rem" />
          </t-button-text>
        </template>
      </usage-progress-card>
      <separator />
    </template>

    <template v-if="showBalanceProgress">
      <usage-progress-card
        v-if="usageOverview.carrierCosts"
        :spent-amount="usageOverview.carrierCosts.used"
        :quota="usageOverview.carrierCosts.quota ?? 0"
        format="currency"
        :type="'balance'"
        :period-type="usageOverview.periodType"
      >
        <template #icon>
          <t-thumbnail size="default">
            <wallet-minus-linear size="m" />
          </t-thumbnail>
        </template>
        <template #title>{{ $t('usage_overview.overview_section_balance_spent_heading') }}</template>
      </usage-progress-card>
      <separator />
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  ArrowRightLinear,
  CommentsLinear,
  InfoFill,
  WalletMinusLinear,
} from '@trengo/trengo-icons';
import { type PropType, computed } from 'vue';


import { PERIOD_TYPE, CONVERSATION_TYPE } from '@/Configs/Constants';

import Separator from './Separator.vue';
import UsageProgressCard from './UsageProgressCard.vue';

import type { ConversationType, PeriodBreakdown } from '../types';


const props = defineProps({
  usageOverview: {
    type: Object as PropType<PeriodBreakdown>,
    required: true,
  },
});
const isAutomedConversationsEnabled = false; // intelligent conversations will be enabled when it's calculated
const isInTrial = computed(() => props.usageOverview.periodType === PERIOD_TYPE.TRIAL);

const showBalanceProgress = computed(() => props.usageOverview.periodType !== PERIOD_TYPE.VOID && props.usageOverview.periodType !== PERIOD_TYPE.UPCOMING);

const computeConversations = (type: ConversationType) => computed(() => {
  const conversation = (props.usageOverview?.conversations || []).find(
    conversation => conversation.type === type
  );

  if (!isInTrial.value) {
    return conversation;
  }

  return { ...conversation, quota: conversation?.finished || 0 };
});

const standardConversations = computeConversations(CONVERSATION_TYPE.STANDARD);
const intelligentConversations = computeConversations(CONVERSATION_TYPE.INTELLIGENT);

const isNearingTheEndOfBillingCycle = computed(() => {
  const currentTermTotal = props.usageOverview.endDate - props.usageOverview.startDate;
  const currentTermUsed = Date.now() - props.usageOverview.startDate;
  const percentageUsed = currentTermUsed / currentTermTotal * 100;
  return percentageUsed > 80;
});
</script>
