import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "t-text-sm-emphasize m-0 p-0" }
const _hoisted_2 = { class: "t-text-sm m-0 p-0" }
const _hoisted_3 = {
  key: 2,
  class: "mt-4"
}
const _hoisted_4 = { class: "t-text-sm-emphasize m-0 p-0" }
const _hoisted_5 = {
  key: 3,
  class: "mt-4"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mb-4 ml-2 text-xs text-grey-600" }
const _hoisted_8 = { class: "mb-2 ml-2 text-sm font-semibold" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "mb-4 ml-2 text-xs text-grey-600" }
const _hoisted_11 = { class: "mb-2 ml-2 text-sm font-semibold" }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "mb-4 ml-2 text-xs text-grey-600" }
const _hoisted_14 = { class: "mb-2 ml-2 text-sm font-semibold" }
const _hoisted_15 = {
  key: 3,
  class: "pb-3"
}
const _hoisted_16 = { class: "mb-4 ml-2 text-xs text-grey-600" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["href"]
const _hoisted_19 = {
  key: 1,
  class: "mb-4 ml-2 text-base font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_loading = _resolveComponent("integration-loading")!
  const _component_cross_octagon_linear = _resolveComponent("cross-octagon-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_divider = _resolveComponent("t-divider")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_integration_loading, {
        key: 0,
        class: "mt-4"
      }))
    : (this.hasLoaded && this.hasError)
      ? (_openBlock(), _createBlock(_component_t_inline_banner, {
          key: 1,
          type: "error",
          class: "mt-4"
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_cross_octagon_linear)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.sidebar_error_title')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('integration_hub.sidebar_error_description')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : (this.hasLoaded && !_ctx.leads?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_t_inline_banner, { type: "default" }, {
              icon: _withCtx(() => [
                _createVNode(_component_info_linear)
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('integration_hub.sidebar_no_data_found', { name: this.installation.name })), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: lead.lead_id,
                class: "mb-3 rounded-lg border border-grey-300 bg-white px-2 pt-3"
              }, [
                (lead.owner_name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_lead_owner_name')), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(lead.owner_name), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (lead.status)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_lead_status')), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_11, _toDisplayString(lead.status), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (lead.created_at)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_lead_created_at')), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.formatDate(lead.created_at)), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (lead.lead_id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_lead_id')), 1 /* TEXT */),
                      (lead.lead_url)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                            _createElementVNode("a", {
                              class: "mb-4 ml-2 cursor-pointer text-base font-semibold text-leaf-500",
                              href: lead.lead_url,
                              target: "_blank"
                            }, _toDisplayString(lead.lead_id), 9 /* TEXT, PROPS */, _hoisted_18)
                          ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(lead.lead_id), 1 /* TEXT */))
                    ]))
                  : _createCommentVNode("v-if", true),
                (i !== Object.keys(_ctx.leads).length - 1)
                  ? (_openBlock(), _createBlock(_component_t_divider, { key: 4 }))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
}