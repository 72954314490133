import { defineStore } from 'pinia';

import type { Translations, TranslationsStore } from './types';

export const useTranslationsStore = defineStore('translations', {
  state: (): TranslationsStore => ({
    translations: {} as Translations,
  }),
  actions: {
    setTranslations(translations: Translations) {
      this.translations = translations;
    },
  },
  getters: {
    getTranslations: (state) => state.translations,
  },
});
