<script lang="ts" setup>
import { ArrowLeftLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { computed, onMounted, ref } from 'vue';

import { fetchUsageOverview } from '@/api';
import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import MobilePreviewCard from '@/components/common/MobilePreviewCard/MobilePreviewCard.vue';
import EmptyChart from '@/components/EmptyChart';
import { FEATURE_FLAG_SUBSCRIPTION, PERIOD_TYPE, CONVERSATION_TYPE } from '@/Configs/Constants';
import { useBillingStore, useFeatureFlagStore } from '@/store/pinia';
import { formatEpochMillisecondDate } from '@/util/date';

import AddConversationBlocksModal from './components/AddConversationBlocksModal.vue';
import AddConversationBlocksSuccessModal from './components/AddConversationBlocksSuccessModal.vue';
import UsageBreakdown from './components/UsageBreakdown.vue';
import { DEFAULT_EMPTY_CHART_PANEL_ITEM } from './constants';

import type { PeriodBreakdown } from './types';
import type { AgencyUsageResponse, ConversationBreakdownItem, PeriodBreakdownItem } from '@/api';

const usageResponse = ref<AgencyUsageResponse | null>(null);
const breakdowns = ref<PeriodBreakdown[]>([]);
const isLoading = ref(true);
const hasError = ref(false);
const isMobile = window.is_mobile_device();
const isSuccessModalVisible = ref(false);
const billingStore = useBillingStore();
const isQuotaUpdatePending = computed(() => billingStore.invoices.length > 0);

const hasUsage = computed(() => !isLoading.value && !hasError.value && breakdowns.value.length > 0);

const currentCycleIndex = computed(() =>
  breakdowns.value.findIndex((item) => item.startDate === usageResponse.value?.current_period_breakdown?.start_date)
);

const selectedCycleOverviewIndex = ref(-1);
const selectedCycleOverview = computed(() => breakdowns.value[selectedCycleOverviewIndex.value]);

const loadUsage = () => {
  fetchUsageOverview()
    .then(({ data }) => {
      usageResponse.value = data;
      prepareBreakdownList();
      selectedCycleOverviewIndex.value = currentCycleIndex.value;
    })
    .catch((error) => {
      console.error(error);
      hasError.value = true;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const mapBreakdown = (breakdown: PeriodBreakdownItem): PeriodBreakdown => ({
  periodType: breakdown.period_type,
  startDate: breakdown.start_date,
  endDate: breakdown.end_date,
  conversations: breakdown.conversations.map((conversation: ConversationBreakdownItem) => ({
    type: conversation.conversation_type,
    quota: conversation.commitment_in_bundles * 100,
    finished: conversation.finished_conversations_count,
    inProgress: conversation.in_progress_conversations_count,
  })),
  carrierCosts: { used: breakdown.carrierCosts.used * 100, quota: breakdown.carrierCosts.commitment_in_bundles * 2500 },
});

const prepareBreakdownList = () => {
  if (!usageResponse?.value) return;

  usageResponse.value?.previous_periods_break_down?.reverse()?.forEach((breakdown: PeriodBreakdownItem) => {
    breakdowns.value.push(mapBreakdown(breakdown));
  });
  if (usageResponse.value?.current_period_breakdown) {
    breakdowns.value.push(mapBreakdown(usageResponse.value?.current_period_breakdown));
  }

  const hasUpcomingBreakdown =
    (usageResponse.value?.upcoming_period_breakdown?.upcoming_standard_conversations_count ?? 0) > 0 ||
    (usageResponse.value?.upcoming_period_breakdown?.upcoming_ai_conversations_count ?? 0) > 0;

  if (hasUpcomingBreakdown) {
    breakdowns.value.push({
      periodType: PERIOD_TYPE.UPCOMING,
      startDate: 0,
      endDate: 0,
      conversations: [
        {
          type: CONVERSATION_TYPE.STANDARD,
          inProgress: usageResponse?.value.upcoming_period_breakdown?.upcoming_standard_conversations_count ?? 0,
          finished: 0,
        },
        {
          type: CONVERSATION_TYPE.INTELLIGENT,
          inProgress: usageResponse.value?.upcoming_period_breakdown?.upcoming_ai_conversations_count ?? 0,
          finished: 0,
        },
      ],
    });
  }
};

onMounted(() => {
  if (!isMobile) {
    loadUsage();
    billingStore.getInvoices();
  }
});

const switchCycle = (result: 'backward' | 'forward' | 'current') => {
  switch (result) {
    case 'backward':
      selectedCycleOverviewIndex.value = selectedCycleOverviewIndex.value - 1;
      break;
    case 'forward':
      selectedCycleOverviewIndex.value = selectedCycleOverviewIndex.value + 1;
      break;
    case 'current':
    default:
      selectedCycleOverviewIndex.value = currentCycleIndex.value;
      break;
  }
};

const emptyChartDescription = computed(() => {
  switch (true) {
    case hasError.value:
      return 'Please try again later, or contact support';
    case !hasUsage.value && !isLoading.value:
      return 'Your usage of conversations and balance will be displayed here';
    default:
      return '';
  }
});

const dateRange = computed(() => {
  const { startDate, endDate } = selectedCycleOverview.value || {};

  if (!startDate || !endDate) {
    return '';
  }

  const firstDay = formatEpochMillisecondDate(startDate, 'dd MMM yyyy');
  const lastDay = formatEpochMillisecondDate(endDate, 'dd MMM yyyy');

  return `${firstDay} - ${lastDay}`;
});
const isUpcomingCycleSelected = computed(() => selectedCycleOverview.value?.periodType === PERIOD_TYPE.UPCOMING);

const handleAddConversationBlocksModalSubmit = () => {
  isSuccessModalVisible.value = true;
};
const handleSuccessModalClose = () => {
  isSuccessModalVisible.value = false;
};
</script>

<template>
  <mobile-preview-card v-if="isMobile" class="max-h-screen w-full">
    {{ $t('general.feature_only_available_on_desktop') }}
  </mobile-preview-card>
  <account-page-panel v-else :title="$t('usage_overview.your_usage_heading')">
    <template #headingRight>
      <div v-if="hasUsage" class="flex items-center gap-2">
        <t-icon-button :disabled="selectedCycleOverviewIndex === 0" @click="switchCycle('backward')">
          <arrow-left-linear />
        </t-icon-button>
        <span class="t-text-sm w-48 text-center text-grey-600">
          {{ isUpcomingCycleSelected ? $t('usage_overview.upcoming_cycle') : dateRange }}
        </span>
        <t-icon-button :disabled="selectedCycleOverviewIndex === breakdowns.length - 1" @click="switchCycle('forward')">
          <arrow-right-linear />
        </t-icon-button>
        <t-button
          :disabled="selectedCycleOverviewIndex === currentCycleIndex"
          btn-style="secondary"
          class="!h-8"
          @click="switchCycle('current')"
        >
          {{ $t('usage_overview.current') }}
        </t-button>
      </div>
    </template>

    <div class="flex w-full flex-col">
      <div v-show="isLoading" class="mt-6 flex items-center justify-center text-4xl font-extralight">
        <t-spinner />
      </div>
      <usage-breakdown
        v-if="hasUsage"
        :is-current-cycle="selectedCycleOverviewIndex === currentCycleIndex"
        :period-breakdown="selectedCycleOverview"
      />
      <template v-if="!hasUsage && !isLoading">
        <empty-chart v-bind="DEFAULT_EMPTY_CHART_PANEL_ITEM" :description="emptyChartDescription" />
      </template>
    </div>

    <add-conversation-blocks-modal
      v-if="
        !isQuotaUpdatePending &&
        useFeatureFlagStore().isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_ADD_CONVERSATION_BLOCKS_CURRENT)
      "
      @success="handleAddConversationBlocksModalSubmit"
    />
    <add-conversation-blocks-success-modal v-if="isSuccessModalVisible" @close="handleSuccessModalClose" />
  </account-page-panel>
</template>
