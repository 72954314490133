<script type="text/babel">
import SidebarCollapse from '@/components/common/SidebarCollapse';

import ticketSidebarContactCustomFieldEditor from './ContactCustomFieldEditor';
import ticketSidebarContactGroupEditor from './ContactGroupEditor';
import ticketSidebarMoments from './Moments';
import ticketSidebarNotes from './Notes';

export default {
  props: ['contact', 'ticket', 'selectedPlugins'],

  data() {
    return {
      hide: false,
      notes: [],
    };
  },

  components: {
    ticketSidebarMoments,
    ticketSidebarNotes,
    ticketSidebarContactCustomFieldEditor,
    ticketSidebarContactGroupEditor,
    SidebarCollapse,
  },

  mounted() {
    this.loadContactNotes();
  },

  methods: {
    addNote(note) {
      if (note != null && this.ticket.contact_id != null) {
        axios
          .post('/api/v2/contacts/' + this.ticket.contact_id + '/notes', {
            note: note.replace(/\r?\n/, '<br>'),
          })
          .then(() => {
            this.loadContactNotes();
            this.$refs.noteEditor.$refs.noteTextArea.$el.innerText = '';
          })
          .catch((e) => {
            console.error('Error on note post: ', e);
          });
      }
    },
    loadContactNotes() {
      axios.get('/api/v2/contacts/' + this.ticket.contact_id).then((res) => {
        this.notes = res.data.notes;
      });
    },
  },
};
</script>

<template>
  <div>
    <template
      v-if="
        contact &&
        contact.formatted_custom_field_data != null &&
        Object.keys(contact.formatted_custom_field_data).length > 0 &&
        selectedPlugins.includes('contactCustomFields')
      "
    >
      <!-- Contact fields -->
      <ticket-sidebar-contact-custom-field-editor
        :contact="contact"
        :ticket="ticket"
      ></ticket-sidebar-contact-custom-field-editor>
    </template>
    <div
      v-if="$root.contactGroups.length > 0 && selectedPlugins.includes('contactGroups')"
      class="sidebar_block border-b border-grey-300"
    >
      <!-- Contact groups -->
      <ticket-sidebar-contact-group-editor :contact="contact"></ticket-sidebar-contact-group-editor>
    </div>

    <div v-if="selectedPlugins.includes('contactMoments') && !hide" class="sidebar_block border-b border-grey-300">
      <!-- Contact moments -->
      <sidebar-collapse collapse-key="collapseTicketSidebarContactMoments">
        <template #header>
          <h6 class="mb-0">
            {{ $t('ticket_sidebar.contact_moments') }}
          </h6>
        </template>
        <div>
          <ticket-sidebar-moments
            :ticket="ticket"
            :contact="contact"
            @no-moments="this.hide = true"
          ></ticket-sidebar-moments>
        </div>
      </sidebar-collapse>
    </div>

    <!-- Contact notes -->
    <div v-if="selectedPlugins.includes('contactNotes')" class="sidebar_block border-b border-grey-300">
      <sidebar-collapse collapse-key="collapseTicketSidebarContactNotes">
        <template #header>
          <h6 class="mb-0">
            {{ $t('ticket_sidebar.contact_notes') }}
          </h6>
        </template>
        <div class="sidebar_block_body mt-2">
          <ticket-sidebar-notes
            ref="noteEditor"
            :placeholder="$t('ticket_sidebar.contact_note_placeholder')"
            :initial-notes="notes"
            @input="addNote"
          ></ticket-sidebar-notes>
        </div>
      </sidebar-collapse>
    </div>
  </div>
</template>
