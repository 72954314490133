<template>
  <div v-if="loaded" class="row-col">
    <div v-if="!isFeatureFlagEnabled" class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.title"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <back-header
            v-if="isFeatureFlagEnabled"
            class="mb-4 mt-6 px-6 xs:pl-6 xs:pr-0"
            :title="record.title"
            @click="handleBackButtonClick"
          />
          <div class="p-b-0 container pb-4" :class="{ 'p-a': !isFeatureFlagEnabled }">
            <div class="pb-4">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('quick_reply.quick_reply') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('quick_reply.name') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.title" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('quick_reply.channels') }}</label>
                      <div class="col-sm-8">
                        <multiselect
                          v-model="record.channels"
                          label="text"
                          track-by="id"
                          :allow-empty="true"
                          :options="channels"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :multiple="true"
                          :placeholder="$t('quick_reply.select_one_or_more_channels')"
                        ></multiselect>
                        <span class="form-text">
                          {{ $t('quick_reply.select_channels_description') }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('quick_reply.type') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <select v-model="record.type" class="form-control">
                          <option value="MESSAGING">{{ $t('quick_reply.messaging') }}</option>
                          <option value="EMAIL">{{ $t('quick_reply.email') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('quick_reply.message') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <div>
                          <textarea
                            v-if="record.type !== 'EMAIL'"
                            v-model="record.message"
                            class="form-control"
                            rows="5"
                          ></textarea>
                          <froala
                            v-if="record.type === 'EMAIL'"
                            v-model="record.message"
                            :custom-froala-options="{
                              placeholderText: $t('quick_reply.email_textarea_placeholder'),
                            }"
                            rows="20"
                          ></froala>
                        </div>
                      </div>
                      <span class="form-text col-sm-10 offset-sm-2">
                        {{ $t('quick_reply.qr_instructions') }}
                      </span>
                      <div class="list-group col-sm-10 offset-sm-2 mt-2">
                        <div class="list-group-item text-muted font-bold">{{ $t('quick_reply.available_tags') }}</div>
                        <div class="list-group-item">
                          [ticket_id]
                          <span class="text-muted font-italic pl-2">{{ $t('quick_reply.ticket_id') }}</span>
                        </div>
                        <div class="list-group-item">
                          [contact_name]
                          <span class="text-muted font-italic pl-2">{{ $t('quick_reply.contact_name') }}</span>
                        </div>
                        <div class="list-group-item">
                          [agent_name]
                          <span class="text-muted font-italic pl-2">{{ $t('quick_reply.agent_name') }}</span>
                        </div>
                        <div class="list-group-item">
                          [profile_name]
                          <span class="text-muted font-italic pl-2">{{ $t('quick_reply.profile_name') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready"
                      v-text="saving ? $t('quick_reply.saving') : $t('quick_reply.save_changes')"
                    ></button>
                    <a v-if="action === 'edit'" class="btn btn-default ml-2" @click="openAttachmentsModal()">
                      <i class="fa fa-paperclip"></i>
                      {{ $t('quick_reply.manage_attachments') }} ({{ record.attachments.length }})
                    </a>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteQuickReply()"
                    >
                      {{ $t('quick_reply.delete') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="attachmentsModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('quick_reply.attachments') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th width="30%">{{ $t('quick_reply.file_name') }}</th>
                <th width="30%">{{ $t('quick_reply.file_size') }}</th>
                <th width="30%">{{ $t('quick_reply.file_type') }}</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attachment in record.attachments">
                <td>
                  <a target="_blank" :href="attachment.file_url" class="w-128px truncate text-ellipsis sm:w-200px">
                    {{ attachment.client_name }}
                  </a>
                </td>
                <td>{{ attachment.file_size }}</td>
                <td>{{ attachment.file_type }}</td>
                <td>
                  <a class="btn btn-xs btn-danger text-white" @click="deleteAttachment(attachment)">
                    <i class="fa fa-remove"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  <label for="a" class="btn btn-default" style="cursor: pointer; display: inherit; margin-bottom: 0">
                    <i class="fa fa-paperclip"></i>
                    {{ $t('quick_reply.add_files') }}
                    <input id="a" ref="input" type="file" hidden @change="addFile($event)" />
                  </label>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { map } from 'lodash';
import Multiselect from 'vue-multiselect';

import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import BackHeader from '../common/PageHeaders/BackHeader';
import Froala from '../ReplyForm/Froala.vue';

export default {
  name: 'QuickReplyEdit',
  emits: ['call-parent-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  components: {
    BackHeader,
    Froala,
    Multiselect,
  },

  computed: {
    ready() {
      if (this.record.title == '' || this.record.message == '') {
        return;
      }

      return true;
    },
    isFeatureFlagEnabled() {
      return false; // @todo remove this when we want to enable the changes for quick replies and uncomment the code below

      // return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_PAGE);
    },
  },

  data() {
    return {
      record: {
        title: this.$t('quick_reply.quick_reply'),
        channels: [],
        type: 'MESSAGING',
        message: '',
        attachments: [],
      },
      saving: false,
      channels: [],
    };
  },

  mounted() {
    if (this.action === 'edit') {
      axios.get('/api/v2/quick_replies/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        this.initWarning();
      });
    } else {
      this.initWarning();
    }

    this.channels = this.$root.channels;
  },

  methods: {
    handleBackButtonClick() {
      this.$router.push('/admin/quick_replies');
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeQuickReply();
      } else {
        this.updateQuickReply();
      }
    },

    storeQuickReply() {
      axios
        .post('/api/v2/quick_replies', { ...{ channel_ids: this.mapChannelIds() }, ...this.record })
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-parent-loadlist-method');
          this.$nextTick(() => router.push('/admin/quick_replies/' + res.data.id));
          this.flashSuccess(this.$t('quick_reply.the_quick_reply_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateQuickReply() {
      axios
        .put('/api/v2/quick_replies/' + this.record.id, { ...{ channel_ids: this.mapChannelIds() }, ...this.record })
        .then((res) => {
          this.saving = false;
          this.$emit('call-parent-loadlist-method');
          this.flashSuccess(this.$t('quick_reply.the_quick_reply_has_been_updated_successfully'));
          this.initWarning();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteQuickReply() {
      const shouldDeleteQuickReply = await this.$tConfirm(
        this.$t('quick_reply.are_you_sure_you_want_to_delete_this_quick_reply'),
        {
          delete: true,
          title: this.$t('quick_reply.are_you_sure'),
        }
      );
      if (shouldDeleteQuickReply) {
        this.initWarning(false);

        axios.delete('/api/v2/quick_replies/' + this.record.id).then(() => {
          this.$emit('call-parent-loadlist-method');
          router.push('/admin/quick_replies');
          this.flashSuccess(this.$t('quick_reply.the_quick_reply_has_been_deleted_successfully'));
        });
      }
    },

    mapChannelIds() {
      return map(this.record.channels, 'id');
    },

    openAttachmentsModal() {
      $(this.$refs.attachmentsModal).modal('show');
    },

    async deleteAttachment(attachment) {
      const shouldDeleteAttachment = await this.$tConfirm(
        this.$t('quick_reply.are_you_sure_you_want_to_delete_this_attachment'),
        {
          delete: true,
          title: this.$t('quick_reply.are_you_sure'),
        }
      );
      if (shouldDeleteAttachment) {
        axios.delete('/api/v2/quick_replies/' + this.record.id + '/attachments/' + attachment.id);
        this.record.attachments.splice(this.record.attachments.indexOf(attachment), 1);
      }
    },

    addFile(event) {
      let formData = new FormData();
      formData.append('file', event.target.files[0]);

      axios.post('/api/v2/quick_replies/' + this.record.id + '/attachments', formData).then((res) => {
        this.record.attachments.push(res.data);
      });
    },
  },
};
</script>
