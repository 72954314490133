import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "t-text-sm-emphasize m-0 p-0" }
const _hoisted_2 = { class: "t-text-sm m-0 p-0" }
const _hoisted_3 = {
  key: 2,
  class: "mt-4"
}
const _hoisted_4 = { class: "t-text-sm-emphasize m-0 p-0" }
const _hoisted_5 = {
  key: 3,
  class: "mt-4"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "mb-3 pt-3 text-base font-semibold"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "text-xs text-grey-600" }
const _hoisted_11 = { class: "mb-3 text-sm font-semibold" }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "text-xs text-grey-600" }
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { class: "text-xs text-grey-600" }
const _hoisted_17 = { class: "mb-3" }
const _hoisted_18 = { key: 4 }
const _hoisted_19 = { class: "text-xs text-grey-600" }
const _hoisted_20 = { class: "mb-3 text-sm font-semibold" }
const _hoisted_21 = { key: 5 }
const _hoisted_22 = { class: "text-xs text-grey-600" }
const _hoisted_23 = { class: "mb-3 text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integration_loading = _resolveComponent("integration-loading")!
  const _component_cross_octagon_linear = _resolveComponent("cross-octagon-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_divider = _resolveComponent("t-divider")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_integration_loading, {
        key: 0,
        class: "mt-4"
      }))
    : (this.hasLoaded && this.hasError)
      ? (_openBlock(), _createBlock(_component_t_inline_banner, {
          key: 1,
          type: "error",
          class: "mt-4"
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_cross_octagon_linear)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('integration_hub.sidebar_error_title')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.error ?? _ctx.$t('integration_hub.sidebar_error_description')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : (this.hasLoaded && _ctx.contacts.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_t_inline_banner, { type: "default" }, {
              icon: _withCtx(() => [
                _createVNode(_component_info_linear)
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('integration_hub.sidebar_no_data_found', { name: this.installation.name })), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: "mb-4 rounded-lg border border-grey-300 bg-white px-2"
              }, [
                (contact.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (contact.contact_url)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "mb-3 cursor-pointer pt-3 text-base font-semibold text-leaf-500",
                            href: contact.contact_url,
                            target: "_blank"
                          }, _toDisplayString(contact.name), 9 /* TEXT, PROPS */, _hoisted_7))
                        : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(contact.name), 1 /* TEXT */))
                    ]))
                  : _createCommentVNode("v-if", true),
                (contact.company?.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_company')), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_11, _toDisplayString(contact.company.name), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (contact.phone_numbers.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_phone')), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contact.phone_numbers, (phone, phoneIdx) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: phoneIdx,
                            class: "my-0 text-sm font-semibold"
                          }, _toDisplayString(phone.country_code) + _toDisplayString(phone.number), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                (contact.email_addresses.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_email')), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contact.email_addresses, (email, emailIdx) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: emailIdx,
                            class: "my-0 text-sm font-semibold"
                          }, _toDisplayString(email.email), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                (contact.contact_owner)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_contact_owner')), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_20, _toDisplayString(contact.contact_owner), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (contact.company?.number_of_employees)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('integration_hub.sidebar_crm_company_size')), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_23, _toDisplayString(contact.company.number_of_employees), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (i !== Object.keys(_ctx.contacts).length - 1)
                  ? (_openBlock(), _createBlock(_component_t_divider, { key: 6 }))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
}