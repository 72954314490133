<template>
  <div ref="backdrop" class="trengo-modal-chat flex items-center justify-center md:p-8" @click.self="$emit('close')">
    <div
      class="box custom-shadow-outline relative flex h-full w-full select-none flex-col overflow-hidden rounded-lg"
      style="margin-bottom: 0"
      tabindex="0"
      @keydown.esc="$emit('close')"
    >
      <div class="modal-header">
        <h3 class="mb-0 text-ellipsis px-8 text-center" style="font-size: 18px; line-height: 1.5">
          {{ $t('internal_chat.search_messages_modal_title') }}
        </h3>
        <button type="button" class="modal-close-btn leading-none" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px" @click="$emit('close')">close</i>
        </button>
      </div>

      <div class="mx-4 flex lg:mx-8">
        <i
          class="material-icons icon-search b-2x flex items-center border-grey-200 pl-3 text-grey-600"
          @click="$refs.inputSearch.focus()"
        >
          search
        </i>
        <input
          ref="inputSearch"
          type="text"
          :value="queryValue"
          class="form-control input-search b-2x sm:mt-0"
          :placeholder="$t('internal_chat.search_messages_modal_placeholder')"
          @input="(e) => (queryValue = e.target.value)"
        />
      </div>

      <!--            <div class="inline-flex w-100 bg-grey-200 rounded-lg text-grey-600 font-medium mt-3" style="height:40px;">-->
      <!--                <template v-for="(filter, key) in filters">-->
      <!--                    <span class="flex-1 flex justify-center items-center pointer rounded-lg b-2x" style="border-color:#ebedf5;" :key="key" :class="{ 'bg-white': key === activeItem}" @click="selectItem(key)">{{filter.title}}<span class="font-normal">&nbsp;({{filter.amount}})</span></span>-->
      <!--                </template>-->
      <!--            </div>-->

      <div
        v-if="messages && messages.length"
        class="scroll-on-hover h-full"
        style="overflow-x: hidden"
        infinite-wrapper
      >
        <div class="w-full pl-4 lg:pl-8 lg:pr-4">
          <div
            v-for="message in messages"
            :key="'searchMessage#' + message.id"
            role="button"
            class="b-b modal-item py-4"
            @click="goToMessage(message)"
          >
            <div class="relative inline-flex w-full rounded-lg">
              <!-- Avatar -->
              <div>
                <avatar
                  :color="message.getUser().color"
                  font-size="12px"
                  class="white mt-2"
                  :abbr="message.getUser().abbr"
                  :image="message.getUser().profileImage"
                  width="38"
                ></avatar>
              </div>

              <div class="pl-3" style="width: calc(100% - 38px)">
                <div class="mb-2 inline-flex w-full">
                  <span class="inline text-ellipsis font-bold" style="max-width: 50%">
                    {{ message.getUser().getDisplayName() }}
                  </span>
                  <div>&nbsp;{{ $t('internal_chat.search_messages_modal_message_title_in_between') }}&nbsp;</div>
                  <span class="text-ellipsis" style="max-width: 50%">
                    {{ (message.getThread() || {}).identifier || 'Deleted thread' }}
                  </span>
                </div>

                <!-- Parent Message -->
                <div
                  v-if="message.parent"
                  class="b-a grey-50 my-4 mb-0 mb-4 ml-4 block h-auto overflow-hidden rounded-lg p-4 lg:m-4"
                  style="color: black; word-break: break-word"
                  @click.prevent="goToMessage(message.parent.id)"
                >
                  <div v-html="message.parent.getBodyHtmlSanitized()"></div>
                  <div v-if="message.parent.attachments.length">
                    <div
                      v-for="a in message.parent.attachments"
                      class="b-a white inline-flex max-w-full items-center truncate rounded-lg px-2"
                    >
                      <i class="fa mr-1" style="height: 14px" :class="a.getIconClass()"></i>
                      <span class="text-ellipsis">{{ a.getName() }}</span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="message.getBodyHtmlSanitized('search')"
                  class="message-body mb-3 leading-none text-grey-600"
                  style="word-break: break-word"
                  v-html="message.getBodyHtmlSanitized('search')"
                ></div>

                <div v-if="message.attachments.length" class="mb-3 ml-1 mt-4">
                  <div
                    v-for="a in message.attachments"
                    class="b-a white inline-flex max-w-full items-center truncate rounded-lg px-2"
                  >
                    <i class="fa mr-1" style="height: 14px" :class="a.getIconClass()"></i>
                    <span class="text-ellipsis">{{ a.getName() }}</span>
                  </div>
                </div>

                <div style="font-size: 12px; line-height: 1" class="flex justify-between text-grey-600">
                  <!--                                    <span>{{moment.utc(message.createdAt*1000).fromNow()}}</span>-->
                  <datetime
                    :time="message.createdAt * 1000"
                    :pretty="$root.prettyDates"
                    :allow-change="false"
                  ></datetime>
                  <span class="modal-item-hover absolute mr-4">
                    {{ $t('internal_chat.search_messages_modal_hover_message') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <infinite-loading
          ref="scrollDown"
          direction="bottom"
          spinner="spiral"
          force-use-infinite-wrapper="true"
          class="fw-medium my-6 pl-2 md:pl-4"
          style="font-size: 16px"
          @infinite="loadMoreMessagesDown"
        >
          <template #no-more>
            <div>{{ $t('internal_chat.end_of_results') }}</div>
          </template>
          <template #no-results>
            <div>{{ $t('internal_chat.end_of_results') }}</div>
          </template>
          <template #spinner><div></div></template>
        </infinite-loading>
      </div>
      <div
        v-else-if="messages && !messages.length"
        class="no-results fw-medium mt-8 p-2 text-center"
        style="font-size: 20px"
      >
        <span v-if="queryValue && queryResult">{{ $t('internal_chat.no_results_found') }}</span>
        <span v-else-if="!queryValue">{{ $t('internal_chat.search_messages_modal_empty_query') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';

import eventBus from '@/eventBus';

import Avatar from '../../../Avatar';
import { messages } from '../../Api';
import MessageModel from '../../Models/MessageModel';
export default {
  name: 'SearchModal',
  emits: ['close'],
  components: { Avatar, InfiniteLoading },
  props: {
    query: {
      type: String,
      default: '',
    },
    thread: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      messages: [],
      queryValue: this.query,
      queryResult: false,
      // TODO: V2
      // filters: [
      //     {title:'All', amount:'100'},
      //     {title:'Groups', amount:'10'},
      //     {title:'Colleagues', amount:'90'}
      // ],
      // activeItem: null,
      page: 1,
    };
  },
  mounted() {
    if (!is_mobile_device()) {
      this.$refs.inputSearch.focus();
    }
    this.searchClicked();
  },
  methods: {
    goToMessage(message) {
      eventBus.$emit('showThreadOverlay');
      setTimeout(() => {
        this.$router.push('/chat/' + message.getThread().identifier + '/' + message.id);
      }, 1);
      this.$emit('goToMessage');
    },
    searchClicked: _.debounce(async function () {
      if (this.scroll) {
        this.scroll.reset();
      }

      if (this.queryValue === '') {
        this.queryResult = false;
        this.messages = [];
      } else if (this.queryValue !== '') {
        this.page = 1;
        this.messages = (await messages.search(this.queryValue, this.page)).data.map((m) => new MessageModel(m));
        this.queryResult = true;
      }
    }, 300),
    loadMoreMessagesDown(scroll) {
      this.scroll = scroll;
      this.page++;

      messages
        .search(this.queryValue, this.page)
        .then((m) => {
          if (m.data.length) {
            this.messages = [...this.messages, ...m.data.map((m) => new MessageModel(m))];
            this.scroll.loaded();
          } else {
            this.scroll.complete();
          }
        })
        .catch((e) => {
          console.error(e);
          this.scroll.error();
        });
    },
  },
  computed: {
    moment: () => moment,
  },
  watch: {
    queryValue() {
      this.searchClicked();
    },
  },
};
</script>

<style scoped lang="scss">
body {
  overflow-y: auto;
}

.message-body {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.trengo-modal-chat {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  overflow: hidden;

  .modal-header {
    border-bottom: 0 !important;
  }

  .modal-item {
    border-color: theme('colors.grey-200');
    &:hover .modal-item-hover {
      display: block;
    }
  }

  .modal-item-hover {
    display: none;
    right: 0;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    width: 700px;
    max-width: 100%;
    border-radius: 10px;
    border: none;
    max-height: 687px;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }

    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }

  .icon-search {
    font-size: 23px;
    border-right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: text;
  }

  .input-search {
    border-left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: theme('colors.grey-200');
  }

  .form-control::placeholder {
    color: theme('colors.grey-600') !important;
    opacity: 0.5 !important;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

@media (max-width: 800px) {
  .trengo-modal-chat {
    .scroll-on-hover {
      overflow-y: overlay !important;
      padding-right: 16px;
      &:hover {
        padding-right: 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  .trengo-modal-chat {
    .box {
      width: 100%;
      border-radius: 0;
    }
  }
}
</style>
