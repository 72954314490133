import { useAuthStore } from '@/store/pinia';

export async function checkAuthentication(to: any, _from: any, next: any) {
  if (to.meta.allowUnauthenticated) {
    next();
    return;
  }
  const auth = useAuthStore();
  await auth.init();
  if (auth.isAuthenticated || to.name === 'login') {
    next();
  } else {
    next({ name: 'login' });
  }
}
