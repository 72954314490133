<template>
  <form
    class="chat-composer relative flex flex-shrink-0 flex-col lg:mb-4"
    :class="{ 'chat-composer-fade': fadeEditor }"
    @submit.prevent="sendClicked"
  >
    <!-- Scroll down button -->
    <transition name="fade">
      <div v-if="showScrollDown" style="bottom: 125px; right: 18px" class="hidden-md-down absolute">
        <div class="relative" style="z-index: 999" @click="$emit('scroll-down-click')">
          <div>
            <span v-if="unreadCount" class="success label relative rounded" style="left: 60px; bottom: 20px">
              {{ unreadCount }}
            </span>
            <div class="inline-block-56px pointer flex h-56 items-center rounded bg-white leading-none shadow">
              <i style="font-size: 20px; line-height: 55px" class="material-icons">keyboard_arrow_down</i>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Reply message-->
    <div v-if="replyTo" class="message-reply-wrapper inline-flex select-none">
      <div class="message-reply-inner-wrapper relative my-4 mb-0 h-auto w-full rounded-lg py-3 pr-4 lg:ml-4">
        <!-- Avatar -->
        <div class="overflow-hidden rounded-lg" style="z-index: 999">
          <avatar
            :color="replyToUser.color"
            font-size="14px"
            :abbr="replyToUser.abbr"
            :image="replyToUser.profileImage"
            width="30"
            class="white"
            style="position: absolute; left: 8px; top: 8px"
          ></avatar>
        </div>
        <span class="mb-1 mr-2 inline-flex font-bold text-black text-grey-500">{{ replyToUser.getDisplayName() }}</span>
        <datetime
          :time="moment.utc(replyTo.createdAt * 1000).format()"
          :allow-change="false"
          class="message-reply-datetime mb-1 inline-flex"
          style="cursor: default"
        ></datetime>
        <div
          class="message-reply"
          :class="{
            'mb-4': replyTo.attachments.length && !isMobile,
            'mb-2': isMobile && replyTo.getBodyHtmlSanitized() && replyTo.attachments.length,
            'mb-0': isMobile && !replyTo.getBodyHtmlSanitized(),
          }"
          v-html="replyTo.getBodyHtmlSanitized()"
        ></div>
        <div v-if="replyTo.attachments.length && !isMobile" class="inline-flex flex-wrap">
          <div v-for="a in replyTo.attachments" style="width: 210px; max-width: 210px">
            <div class="mr-2 overflow-hidden p-0">
              <img
                v-if="replyTo.attachments.length === 1 && a.isImage() && a.getPreviewUrl()"
                :src="a.getPreviewUrl()"
                :title="a.getName()"
                class="w-auto rounded-lg"
                style="object-fit: contain; max-height: 225px; max-width: 100%"
              />
              <div
                v-else
                class="mb-2 flex flex w-full items-center rounded-lg bg-white p-2 text-center"
                style="min-height: 51px"
              >
                <img
                  v-if="a.isImage() && a.getPreviewUrl()"
                  class="b-a rounded-lg"
                  :src="a.getPreviewUrl()"
                  :title="a.getName()"
                  style="object-fit: cover; height: 35px; width: 35px"
                />
                <i v-else-if="!a.isImage()" class="fa fa-2x text-grey-800" :class="{ [a.getIconClass()]: true }"></i>

                <div class="ml-2 inline-flex w-full items-center overflow-hidden">
                  <strong
                    class="text-truncate mr-auto block text-right text-grey-600"
                    style="height: 20px; line-height: 1.2"
                  >
                    {{ a.getName() }}
                  </strong>
                  <!-- File options -->
                  <div class="text flex items-center">
                    <button
                      class="ml-1 inline-flex items-center whitespace-nowrap rounded-lg bg-grey-200"
                      style="padding: 0 5px; font-size: 14px; height: 20px"
                      @click.prevent.stop="downloadClicked(a)"
                    >
                      <span class="mr-2 h-full font-bold text-grey-600" style="line-height: 1.2">
                        .{{ a.getExtension() }}
                      </span>
                      <i
                        class="fa fa-download text-grey-600"
                        style="font-size: 14px"
                        :aria-label="'Download ' + a.getName()"
                      ></i>
                    </button>
                    <!--<button class="m-1" @click.prevent.stop="shareClicked(file)"><i class="fa fa-share" :aria-label="'Share '+file.getName()"></i></button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="replyTo.attachments.length && isMobile" class="inline-block rounded-lg bg-white p-2">
          {{ $t('internal_chat.editor_attachments') }} ({{ replyTo.attachments.length }})
        </div>
      </div>
      <!-- Close button -->
      <button type="button" class="mt-4 px-4" @click="removeReply">
        <i class="material-icons material-close" style="padding: 3px; font-size: 14px">close</i>
      </button>
    </div>

    <div>
      <div>
        <div v-show="!disableEditor" ref="editor"></div>
        <div v-if="disableEditor" style="padding: 20px 15px; height: 82px; display: flex; align-items: center">
          <span v-if="disabledMessage" class="chat-composer-disabled">
            {{ $t('internal_chat.editor_disabled') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="!disableEditor" class="composer-message-additions my-0 flex items-center justify-end md:absolute">
      <label v-if="mainEditor" role="button" class="mb-0 px-2">
        <i class="far fa-paperclip text-grey-800"></i>
        <input ref="fileInput" type="file" multiple class="none" @change="onFileInputChanged" />
      </label>
      <span v-if="mainEditor" class="pointer icon-gif flex items-center px-2 lg:relative" @click="toggleGifPicker()">
        <svg
          v-tooltip="{ placement: 'top', content: $t('tickets.insert_gif'), delay: { show: 500, hide: 0 } }"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0H16V2H0V0ZM7.06309 9.49864C6.45235 10.1757 5.35688 10.5 4.24203 10.5C2.09957 10.5 0.5 8.98365 0.5 7C0.5 5.07357 1.99293 3.5 4.1257 3.5C5.34719 3.5 6.28754 3.93869 6.94676 4.65395L5.86099 5.69346C5.47321 5.28338 4.91094 4.96867 4.1257 4.96867C2.87512 4.96867 2.0511 5.90327 2.0511 7C2.0511 8.15395 2.87512 9.03134 4.22264 9.03134C4.76552 9.03134 5.26963 8.93597 5.56046 8.70708V7.70572H4.21295V6.28474H7.06309V9.49864ZM9.81334 10.3856H8.27193V3.61444H9.81334V10.3856ZM11.2636 10.3856H12.805V7.73433H14.9086V6.28474H12.805V5.06403H15.5V3.61444H11.2636V10.3856ZM16 12H0V14H16V12Z"
          />
        </svg>
      </span>
      <span v-if="showGifPicker & mainEditor" ref="gifContainer" v-click-away="hideGifPicker">
        <span class="gif-container">
          <gif-picker @insertGif="sendGif" @close="showGifPicker = false"></gif-picker>
        </span>
      </span>
      <span ref="showEmojiPicker" class="pointer flex items-center px-2 md:relative" @click="toggleEmojiPicker($event)">
        <i class="far fa-smile text-grey-800"></i>
      </span>
      <span class="pointer flex items-center px-2 md:relative" @click="toggleTag">
        <i class="far fa-at text-grey-800"></i>
      </span>
      <button
        v-if="mainEditor"
        type="submit"
        class="btn composer-send-button ml-2 flex items-center bg-black text-white"
        :disabled="composerBtnDisabled && !draftMessage"
        style="border-radius: 8px; padding: 7px 7px"
        @click="editor.events.focus()"
      >
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
    <emoji-picker
      v-if="!!showEmojiPicker"
      :show-emoji-picker="!!showEmojiPicker"
      :button-ref="showEmojiPicker"
      class="emoji-picker md-20 fixed select-none whitespace-normal text-grey-600"
      :current-user="user"
      @insertEmoji="insertEmoji"
      @hideEmojiPicker="showEmojiPicker = false"
    ></emoji-picker>
  </form>
</template>

<script>
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/nl.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/ru.js';
import 'froala-editor/js/languages/id.js';
import 'froala-editor/js/languages/pt_br.js';

import FroalaEditor from 'froala-editor';
import _ from 'lodash';
import moment from 'moment';
import { mixin as VueClickAway } from 'vue3-click-away';
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';
import { froalaLocaleCodes } from '@/util/froalaLocale';
import { escapeHtml } from '@/util/stringHelpers';

import EmojiPicker from './EmojiPicker';
import emojiList from '../../../util/emoji';
import Avatar from '../../Avatar';
import GifPicker from '../../ReplyForm/GifPicker';
import { clean } from '../Util/Chat';
import mentionEmojiTribute from '../Util/TributeEmojiCollection.js';
import mentionGroupTribute from '../Util/TributeGroupsCollection.js';
import mentionUserTribute from '../Util/TributeUsersCollection.js';

export default {
  name: 'ChatComposer',
  emits: ['composerInit'],
  components: {
    Avatar,
    EmojiPicker,
    GifPicker,
  },
  props: {
    mainEditor: {
      type: Boolean,
      default: false,
    },
    thread: {
      type: Object,
      default: null,
    },
    showScrollDown: {
      type: Boolean,
      default: false,
    },
    unreadCount: {
      type: String || Number,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    editMessage: {
      type: String,
      default: '',
    },
    initialMaxHeight: {
      type: Number,
      default: 500,
    },
  },
  mixins: [VueClickAway],
  data() {
    return {
      isTyping: false,
      groupTribute: mentionGroupTribute,
      userTribute: mentionUserTribute,
      emojiTribute: mentionEmojiTribute,
      editor: null,
      msgBody: '',
      replyTo: null,
      showGifPicker: false,
      disableEditor: true,
      disabledMessage: false,
      maxHeight: this.initialMaxHeight,
      isMobile: false,
      draftMessage: '',
      fadeEditor: false,
      showEmojiPicker: false,
      emojiReplacementAliases: [
        { emoji: '🙂', alias: [':)', ':-)'] },
        { emoji: '😃', alias: [':D', ':-D'] },
        { emoji: '😛', alias: [':P', ':p', ':-P', ':-p'] },
        { emoji: '😜', alias: [';p', ';-p', ';P', ';-P'] },
        { emoji: '😘', alias: [':*', ':-*'] },
        { emoji: '😞', alias: [':(', ':-('] },
        { emoji: '😉', alias: [';)', ';-)'] },
        { emoji: '😮', alias: [':O', ':o', ':-O', ':-o'] },
        { emoji: '😎', alias: ['8-)', '8)'] },
        { emoji: '😕', alias: [':/', ':-/', ':\\', ':-\\'] },
        { emoji: '😐', alias: [':-|', ':|'] },
        { emoji: '❤️', alias: ['&lt;3'] },
        { emoji: '💔', alias: ['&lt;/3'] },
        { emoji: '😂', alias: [":')"] },
        { emoji: '😢', alias: [":'("] },
        { emoji: '😅', alias: ["':)", "':-)"] },
      ],
    };
  },
  methods: {
    toggleTag() {
      if (this.userTribute.isActive) {
        this.userTribute.isActive = false;
        document.getElementsByClassName('tribute-container')[0].style.display = 'none';
        return;
      }

      this.editor.selection.restore();
      this.userTribute.showMenuForCollection(this.editor.el);

      if (this.editor.html.get() === '@') {
        document.querySelector('.fr-wrapper')?.classList?.remove('show-placeholder');
      }

      if (this.editor.html.get() === '<br>@') {
        this.editor.html.set('@');
        window.placeCaretAtEnd(this.editor.el);
      }
    },

    async sendClicked() {
      this.msgBody = this.editor.html.get();
      if (!stripHtml(this.msgBody || '').length) {
        return;
      }

      this.replaceEmojiSymbols();
      this.replaceEmojiShortcodes();
      const messageBody = this.msgBody;
      this.editor.html.set('');
      await this.thread
        .sendMessage({ messageBody: messageBody }, [], (this.replyTo || {}).id || null)
        .then(() => {
          // reset message
          this.removeReply();
          this.removeDraft();
          this.thread.endTyping();
          this.msgBody = '';
        })
        .catch((e) => {
          console.error(e);
          this.editor.html.set(messageBody);
        });
    },
    setDraft(value) {
      return this.$tStorage.setItem('draft_' + this.thread.identifier, value);
    },
    async getDraft() {
      return (await this.$tStorage.getItem('draft_' + this.thread.identifier)) || '';
    },
    removeDraft() {
      return this.$tStorage.removeItem('draft_' + this.thread.identifier) || '';
    },
    onFileInputChanged(e) {
      if (this.$refs.fileInput.files) {
        // Add files to attachments draft
        eventBus.$emit('chat@SHOW_ATTACHMENT_MODAL', [...this.$refs.fileInput.files]);
        this.$refs.fileInput.value = null;
      }
    },
    removeReply() {
      this.replyTo = null;
      this.updateDraft();
    },

    toggleGifPicker() {
      this.showGifPicker = !this.showGifPicker;
    },
    async sendGif(e, gif) {
      const gifUrl = gif.images.fixed_height.url;
      if (!gifUrl) {
        return;
      }
      const msg = {
        messageBody: gifUrl,
        bodyType: 'GIF',
      };
      this.hideGifPicker();
      await this.thread
        .sendMessage(msg, [], (this.replyTo || {}).id || null)
        .then(() => {
          // reset message
          this.removeReply();
          this.removeDraft();
          this.thread.endTyping();
          this.msgBody = '';
        })
        .catch((e) => {
          console.error(e);
        });

      window.placeCaretAtEnd(this.editor.el);

      // complete onboarding step
      eventBus.$emit('ticket-gif-sent');
    },
    hideGifPicker() {
      this.showGifPicker = false;
    },

    toggleEmojiPicker(e) {
      this.showEmojiPicker = this.$refs.showEmojiPicker;
    },

    insertEmoji(emoji) {
      this.editor.selection.restore();
      if (this.editor.html.get()) {
        this.editor.html.insert(emoji.emoji);
      } else if (!this.editor.html.get()) {
        this.editor.html.set(emoji.emoji);
        window.placeCaretAtEnd(this.editor.el);
      }

      this.showEmojiPicker = false;
      this.msgBody = this.editor.html.get();
    },

    downloadClicked(file) {
      window.open(file.getUrl());
    },

    updateDraft() {
      this.draft = {
        messageBody: this.editor.html.get(),
        // only needs ID?
        //     attachments: [{
        //         name:
        //         id:
        //     }],
        //     ...this.attachments && {attachments: this.attachments},
        ...(this.replyTo && { replyToMessageId: this.replyTo.id }),
      };
      // this.attachments = m.data.data.map(a => new MessageAttachmentModel(a))
      this.setDraft(this.draft);
    },

    initEditor() {
      if (document.body.clientWidth >= 991) {
        this.toolbarButtons = [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'quote',
          'formatUL',
          'formatOL',
          'codeBlock',
        ];
      } else if (document.body.clientWidth < 991) {
        this.toolbarButtons = {
          moreText: {
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'quote', 'formatUL', 'formatOL', 'codeBlock'],
            buttonsVisible: 0,
          },
        };

        if (this.mainEditor) {
          this.maxHeight = 110;
        }
      }

      this.editor = new FroalaEditor(this.$refs.editor, {
        key: 'pe1G2wF1C1B1C2C7E7C5oCe1ZSc2XHe1Cd1f1KIWCWMJHXCLSwD1D1D1D1F1H4A11B1D6E4==',
        attribution: false,
        toolbarButtons: this.toolbarButtons,
        htmlUntouched: true,
        pastePlain: true,
        toolbarBottom: true,
        heightMax: this.maxHeight,
        undo: true,
        imagePaste: false,
        imageUpload: false,
        listAdvancedTypes: false,
        toolbarSticky: false,
        placeholderText: escapeHtml(this.placeholder),
        iconsTemplate: 'font_awesome_5r',
        language: froalaLocaleCodes[this.$root.user.locale_code],

        events: {
          initialized: async () => {
            this.editor.el.addEventListener('keydown', (e) => eventBus.$emit('froalaKeyDown', e));
            this.editor.el.addEventListener('keyup', (e) => eventBus.$emit('froalaKeyUp', e));

            this.msgBody = this.editor.html.get();

            // Emit current editor
            this.$emit('composerInit', this.editor);

            if (this.mainEditor) {
              // Emit main editor
              eventBus.$emit('chat@ON_MAIN_COMPOSER_INIT', this.editor);
            }

            if (this.editMessage) {
              this.editor.html.set(this.editMessage);
            }

            if ((this.thread && !this.thread.isUser()) || (this.thread && this.thread.getUser().exists)) {
              this.disableEditor = false;
            } else {
              this.disabledMessage = true;
            }

            // Firefox/safari fix for focus adding text after <br> in froala composer
            if (!this.mobileClientWidth()) {
              if (!this.mainEditor) {
                window.placeCaretAtEnd(this.editor.el);
              } else if (!this.disabledMessage && this.mainEditor) {
                this.$nextTick(() => this.editor.events.focus(true));
              }
            }

            if (this.mainEditor && (await this.getDraft())) {
              this.draftMessage = (await this.getDraft())?.messageBody;
              let draftReplyTo = (await this.getDraft())?.replyToMessageId;

              if (this.draftMessage) {
                this.editor.html.set(this.draftMessage);
                window.placeCaretAtEnd(this.editor.el);
              }

              if (draftReplyTo) {
                this.replyTo = this.thread.getLoadedMessageById(parseInt(draftReplyTo));
              }
            }

            // mentions
            this.groupTribute.attach(this.editor.el);
            this.userTribute.attach(this.editor.el);
            this.emojiTribute.attach(this.editor.el);
            // this.userTribute.detach(this.editor.el);

            // Send on enter and avoid adding break tag before sending
            this.editor.events.on(
              'keydown',
              (e) => {
                let formatUL = document.querySelector('[data-cmd=formatUL]').classList.contains('fr-active');
                let formatOL = document.querySelector('[data-cmd=formatOL]').classList.contains('fr-active');

                if (
                  e.which === FroalaEditor.KEYCODE.ENTER &&
                  (this.groupTribute.isActive || this.userTribute.isActive || this.emojiTribute.isActive)
                ) {
                  return false;
                }

                if (e.key === 'Enter' && document.body.clientWidth > 991 && !e.shiftKey && !formatUL && !formatOL) {
                  e.stopImmediatePropagation();
                  e.preventDefault();
                  if (this.mainEditor && !!stripHtml(this.editor.html.get()).trim()) {
                    this.editor.undo.saveStep();
                    this.sendClicked();
                  } else if (this.editMessage) {
                    eventBus.$emit('chat@EDIT_MESSAGE', this.editor.html.get());
                  } else {
                    eventBus.$emit('chat@ATTACHMENT_MODAL_SEND');
                  }
                  return false;
                }
              },
              true
            );
          },
          contentChanged: this.froalaContentChanged,
          focus: () => {
            eventBus.$emit('chat@FOCUS_COMPOSER');
            this.fadeEditor = false;
          },
          blur: () => {
            if (this.msgBody) {
              this.editor.selection.save();
            }
            if (this.userTribute.isActive) {
              this.userTribute.isActive = false;
              document.getElementsByClassName('tribute-container')[0].style.display = 'none';
            }
            eventBus.$emit('chat@BLUR_COMPOSER');
            this.fadeEditor = true;
            this.froalaContentChanged();
          },
          keydown: (e, editor) => {
            if (e.key === 'Enter' && !stripHtml(this.editor.html.get()).trim()) {
              this.editor.html.set('');
            }

            if (e.key === 'Backspace' || e.key === 'Delete') {
              if (
                (this.editor.html.get() === '<br><br>' && this.msgBody !== '<br><br><br>') ||
                (this.editor.html.get() === '&nbsp;<br><br>' && this.msgBody !== '<br><br><br>')
              ) {
                this.editor.html.set('');
              }
            }

            // Jump out of codeblock and remove last <br> after 3x shift+enter
            if (
              document.getSelection().focusNode.parentNode?.classList?.contains('code-block-pre') &&
              e.shiftKey &&
              e.key === 'Enter'
            ) {
              let focusedElement = document.getSelection().focusNode.innerHTML;
              if (focusedElement.endsWith('<br><br><br><br>')) {
                if (!clean(focusedElement)) {
                  document.getSelection().focusNode.parentNode?.closest('.code-block-pre').remove();
                  return;
                }

                // 16 chars = 4x BR-tag
                document.getSelection().focusNode.innerHTML = focusedElement.substr(0, focusedElement.length - 16);

                setTimeout(() => {
                  if (!this.editor.html.get().endsWith('<br>')) {
                    this.editor.html.insert('<br>');
                  }
                }, 1);
                window.placeCaretAtEnd(this.editor.el);
              }
            }

            if (this.mainEditor) {
              this.setTyping();
            }
          },
        },
        enter: FroalaEditor.ENTER_BR,
      });

      // FroalaEditor.RegisterShortcut(192, 'codeBlock', '', '`', false);
      FroalaEditor.DefineIcon('codeBlock', { NAME: 'code', SVG_KEY: 'codeView' });
      FroalaEditor.RegisterCommand('codeBlock', {
        title: 'Code block',
        undo: true,
        callback: function () {
          let selectedElement = this.selection.element();
          let parentElement = document.getSelection().focusNode.parentNode.closest('.code-block-pre');
          let childElement = document.getSelection().focusNode.parentNode.closest('.code-block-div');
          // If empty editor set code-block
          if (!this.html.get()) {
            this.html.set(
              '<pre class="block code-block-pre whitespace-pre mb-0"><div class="bg-grey-100 code-block-div rounded-lg overflow-x-scroll px-2 py-2"></div></pre>'
            );
            window.placeCaretAtEnd(this.el);
            // Remove code-block
          } else if (parentElement || childElement || selectedElement?.classList?.contains('code-block-pre')) {
            // if not empty remove codeblack and revert text if possible
            if (childElement) {
              this.html.set(
                this.html
                  .get()
                  .replace(
                    selectedElement.parentNode.closest('.code-block-pre').outerHTML,
                    document.getSelection().focusNode.parentNode.innerHTML
                  )
              );
              setTimeout(() => {
                if (!this.html.get().endsWith('<br>')) {
                  this.html.insert('<br>');
                }
              }, 1);
              // if empty remove code-block
            } else if (!childElement) {
              if (!stripHtml(clean(this.html.get()))) {
                selectedElement.remove();
              } else if (!document.getSelection().focusNode.firstChild) {
                parentElement.remove();
              } else if (document.getSelection().focusNode.firstChild) {
                setTimeout(() => {
                  if (!this.html.get().endsWith('<br>')) {
                    this.html.insert('<br>');
                  }
                }, 1);
              }
            }
            window.placeCaretAtEnd(this.el);
            // Insert a code-block with optional getSelection
          } else if (!childElement && !parentElement && this.html.get()) {
            let selection = document.getSelection().toString();
            this.html.insert(
              '<pre class="block code-block-pre whitespace-pre mb-0 md:pr-10"><div class="bg-grey-100 code-block-div rounded-lg overflow-x-scroll px-2 py-2">' +
                escapeHtml(selection) +
                '</div></pre>'
            );
          }
        },
      });
    },

    async froalaContentChanged() {
      this.msgBody = this.editor.html.get();

      this.replaceEmojiSymbols();
      this.replaceEmojiShortcodes();

      if (this.mainEditor) {
        this.updateDraft();

        if (!this.msgBody && !this.replyTo && (await this.getDraft())) {
          this.removeDraft();
        }
      }
    },

    setTyping: _.debounce(
      function () {
        if (window.stripHtml(this.editor.html.get() || '')) {
          this.isTyping = true;
          this.thread.startTyping();
        } else if (this.isTyping) {
          this.isTyping = false;
          this.thread.endTyping();
        }
      },
      1000,
      {
        leading: true,
        maxWait: 1000,
      }
    ),

    replaceEmojiSymbols() {
      // todo fix bug that trims message on update
      /*let replaced = this.editor.html.get();
                let escapedAliases;
                this.emojiReplacementAliases.forEach(emoji => {
                    escapedAliases = emoji.alias.map(window.escapeRegex).join('|');
                    replaced = replaced.replace(new RegExp('(>|^|\\s|&nbsp;)('+escapedAliases+')(<|$|\\s|&nbsp;)', 'ig'), '$1'+emoji.emoji+'$3');
                });

                this.msgBody = replaced;
                this.editor.html.set(replaced);
                window.placeCaretAtEnd(this.editor.el);*/
    },

    replaceEmojiShortcodes() {
      let replaced = this.editor.html.get();
      let emojiRegex = this.editor.html.get().match(/:([\w]+):/giu);

      if (!emojiRegex?.length) {
        return;
      }

      emojiRegex.forEach((shortcode) => {
        this.allEmojis.forEach((emoji) => {
          if (emoji.name === shortcode.slice(1, -1)) {
            replaced = replaced.replace(shortcode, emoji.char);
          }
        });
      });

      this.msgBody = replaced;
      this.editor.html.set(replaced);
      window.placeCaretAtEnd(this.editor.el);
    },

    onInsertComposer(html) {
      if (!stripHtml(this.editor.html.get()).trim()) {
        this.editor.html.set(html);
        window.placeCaretAtEnd(this.editor.el);
      } else {
        this.editor.selection.restore();
        this.editor.html.insert(html);
      }
    },
    onReplyMessage(message) {
      this.replyTo = message;
      this.editor.events.focus(true);
      this.froalaContentChanged();
    },
    afterAttachmentSent() {
      this.replyTo = null;
    },
    mobileClientWidth() {
      return (this.isMobile = document.body.clientWidth <= 991);
    },
  },
  mounted() {
    window.addEventListener('resize', this.mobileClientWidth);
    this.mobileClientWidth();

    if (!this.mainEditor) {
      this.disableEditor = false;
      this.disabledMessage = false;
    }
    eventBus.$on('user-deleted', (user) => {
      if (this.thread.isUser() && user?.id === this.thread.getUserId()) {
        this.disableEditor = true;
        this.disabledMessage = true;
      }
    });

    eventBus.$on('chat@INSERT_COMPOSER_MESSAGE', this.onInsertComposer);
    eventBus.$on('chat@REPLY_MESSAGE', this.onReplyMessage);
    eventBus.$on('CHAT@AFTER_ATTACHMENT_SENT', this.afterAttachmentSent);

    this.initEditor();
  },
  beforeUnmount() {
    if (this.userTribute.isActive) {
      this.userTribute.isActive = false;
      document.getElementsByClassName('tribute-container')[0].style.display = 'none';
    }

    this.editor.destroy();
    // eventBus.$off(['chat@INSERT_COMPOSER_MESSAGE', 'chat@REPLY_MESSAGE', 'attachment_file_upload']);
    eventBus.$off('chat@INSERT_COMPOSER_MESSAGE', this.onInsertComposer);
    eventBus.$off('chat@REPLY_MESSAGE', this.onReplyMessage);
    eventBus.$off('chat@AFTER_ATTACHMENT_SENT', this.afterAttachmentSent);
  },
  computed: {
    allEmojis() {
      let emojis = [];
      Object.entries(emojiList).forEach(([groupName, group]) => {
        Object.entries(group).forEach(([name, char]) => {
          emojis.push({ name: name, char: char });
        });
      });
      return emojis;
    },
    moment: () => moment,
    ...mapGetters({
      user: 'usersInternalChat/currentUser',
      getUserById: 'usersInternalChat/userById',
    }),
    composerBtnDisabled() {
      return !stripHtml(this.msgBody).trim();
    },
    replyToUser() {
      return this.getUserById(this.replyTo.userId);
    },
  },
};
</script>

<style lang="scss">
.code-block-pre {
  color: #bd4147;
  background: inherit;
}

.code-block-div {
  line-height: 1.8;
  min-height: 41px;
}

.chat-composer-main {
  .fr-box.fr-basic .fr-element {
    min-height: 0;
  }
}

.chat-composer-edit {
  .fr-box.fr-basic {
    .fr-element {
      min-height: 128px;
    }
  }
}

.chat-composer-edit,
.chat-composer-upload {
  .fr-box.fr-basic {
    height: 100%;

    .fr-element {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 16px;
      font-size: 16px;
      height: 100%;
      color: var(--color-grey-900);
    }
  }

  .fr-command.fr-btn {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 5px;
  }

  .fr-toolbar .fr-btn-grp i {
    margin: 0 0 3px 0;
  }

  .fr-btn-grp .fr-toolbar {
    margin: 0 17px 0 4px;
    height: 35px;
  }

  .fr-toolbar .fr-btn-grp {
    margin: 0 17px 0 4px;
    height: 35px;
  }
}

.chat-composer {
  .fr-toolbar .fr-newline {
    display: none;
  }

  .composer-message-additions {
    bottom: 0;
    right: 0;
    z-index: 99;
    margin: 0 3px 3px 0;

    i:not(.fa-paper-plane, .fa-paperclip) {
      font-size: 16px;
    }

    .icon-gif svg {
      fill: var(--color-grey-800);
    }

    > span,
    > label {
      height: 32px;
      display: flex;
      align-items: center;
    }
  }

  .fr-wrapper {
    border-radius: 12px;
  }
}

.chat-composer-upload {
  .composer-message-additions {
    margin: 0 4px 4px 0;
  }

  .fr-placeholder {
    margin-top: 2px !important;
    color: var(--color-grey-700);
  }
}

.chat-composer-fade {
  border-color: var(--color-grey-300) !important;

  .fr-btn-grp i,
  .composer-message-additions i,
  .fr-placeholder {
    color: var(--color-grey-400) !important;
  }

  .icon-gif svg {
    fill: var(--color-grey-400) !important;
  }

  .fr-placeholder {
    color: var(--color-grey-600) !important;
  }

  .composer-send-button {
    background: transparent !important;
  }
}

.chat-composer.chat-composer-main {
  min-height: 84px;
  border: 1px solid;
  border-color: var(--color-grey-400);
  border-radius: 12px;

  .chat-composer-disabled {
    color: var(--color-grey-700);
  }

  .material-close {
    color: var(--color-grey-800);
  }

  .composer-send-button {
    display: flex;

    .material-icons {
      font-size: 16px;
    }

    span {
      line-height: 1;
    }
  }

  .fr-more-toolbar {
    bottom: 35px;
    background: theme('colors.grey-200');
  }

  .fr-box.fr-basic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .fr-element {
      font-size: 16px;
      height: 100%;
      color: var(--color-grey-900);
    }

    .fr-wrapper {
      border: 0;
      overflow-y: hidden !important;
      padding-right: 16px;
      border-radius: 13px;
      height: 100%;

      &:hover {
        overflow-y: scroll !important;
        padding-right: 9px;
      }

      // Firefox only
      @-moz-document url-prefix() {
        &:hover {
          padding-right: 1px;
        }
      }

      @media only screen and (max-width: 800px) {
        overflow-y: auto !important;
        padding-right: 0;

        &:hover {
          overflow-y: auto !important;
          padding-right: 0;
        }
      }

      .fr-placeholder {
        padding-left: 16px !important;
        padding-right: 16px !important;
        color: var(--color-grey-700);
      }
    }
  }

  .fr-box {
    .fr-element {
      padding: 12px 0 10px 16px;
      word-break: break-word;
      overflow-x: hidden;
    }
  }

  .fr-toolbar.fr-toolbar-open {
    padding-bottom: 43px;
  }

  .fr-toolbar {
    border: none;
    order: 2;
    display: flex;
    align-items: center;

    .fr-btn-grp {
      margin: 0 17px 0 4px;
      height: 35px;

      i {
        color: var(--color-grey-800);
        margin: 0 0 3px 0;
      }

      .fr-command.fr-btn[data-cmd='moreText' i] {
        position: absolute;
        bottom: 0;
      }
    }

    .fr-command {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      &:hover {
        background: transparent;
      }
    }

    .fr-command.fr-btn {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0 5px;
    }

    .fr-more-toolbar.fr-expanded {
      height: 40px;
    }
  }

  .fr-active {
    i {
      color: var(--color-leaf-400) !important;
    }
  }

  .message-reply-inner-wrapper {
    background: var(--color-grey-100);
    padding-left: 46px;
  }

  .message-reply {
    word-break: break-word;
    max-height: 63px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--color-grey-800);
  }

  .message-reply-datetime {
    color: var(--color-grey-600);
  }

  .composer-send-button:hover,
  .composer-send-button:active,
  .composer-send-button:focus {
    color: rgba(255, 255, 255, 0.87) !important;
  }

  .gif-container {
    position: absolute;
    bottom: 40px;
    left: -180px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.34);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 9999;
    color: #9b9b9b;
    font-size: 14px;
  }
}

.fr-tooltip {
  margin-top: -1px !important;
}

@media (max-width: 991px) {
  .chat-composer {
    border: 1px solid;
    border-color: var(--color-grey-400);
    min-height: 84px;
    border-radius: 12px;

    .fr-toolbar {
      display: none;
    }

    .fr-box.fr-basic .fr-wrapper {
      border: 0;
    }

    .composer-message-additions {
      margin: 0 10px 3px 0;
    }
  }

  .chat-composer-edit .fr-wrapper {
    max-height: 300px !important;
  }

  .chat-composer.chat-composer-main {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    .fr-box .fr-element {
      max-height: 110px;
    }

    .fr-box.fr-basic .fr-element {
      padding: 10px 0 10px 16px;
    }

    .fr-toolbar {
      display: none;
      // Mobile font styling expand/collapse menu
      //    border-radius: 10px;
      //    margin-bottom: 3px;
      //    .fr-more-toolbar > {
      //        order: 1;
      //        bottom: 35px;
      //        .fr-command.fr-btn {
      //            height: 40px;
      //            .fr-expanded {
      //                height: 40px;
      //            }
      //        }
      //        .fr-expanded {
      //            height: 48px;
      //            .fr-command .fr-btn {
      //                margin: 0 4px;
      //                svg {
      //                    margin: 6px 7px;
      //                }
      //            }
      //        }
      //    }
      //    .fr-btn-grp {
      //        order: 2;
      //        margin: 0 16px 0 4px;
      //        .fr-command.fr-btn[data-cmd="moreText" i] {
      //            height: 32px;
      //        }
      //    }
    }

    .message-reply-wrapper {
      padding-left: 1rem;
    }

    .message-reply {
      -webkit-line-clamp: 1;
    }

    .composer-message-additions {
      margin: 0 10px 3px 0;
    }

    .gif-container {
      left: auto;
    }
  }

  .chat-composer-upload {
    .fr-placeholder {
      margin-top: 0 !important;
    }
  }
}

@media (max-width: 800px) {
  .chat-composer {
    .fr-box.fr-basic .fr-element {
      padding-right: 16px;
    }
  }
}

@media (max-width: 767px) {
  .chat-composer {
    .gif-container {
      right: 0;
      left: auto;
    }

    .emoji-picker {
      bottom: 0;
      box-shadow: 0 1px 4px 0 rgb(0 0 0 / 34%);
    }
  }
}

@media (max-width: 543px) {
  .chat-composer .gif-container {
    right: 0;
  }
}

@media (max-width: 370px) {
  .chat-composer .fr-toolbar .fr-more-toolbar > .fr-command.fr-btn {
    padding: 0 3px;
  }
}
</style>
