<template>
  <div class="custom-fields-table" :class="{ drag }" @mousemove="onMousemove">
    <div>
      <div class="justify-end pb-4 md:flex">
        <router-link :to="{ name: 'CustomFieldsCreate', params: { type: typeParam } }">
          <atomic-button>
            <i class="fal fa-plus mr-3" />
            {{ $t('custom_fields.new_custom_field') }}
          </atomic-button>
        </router-link>
      </div>

      <div v-if="record && record.length" class="table-wrap" @mouseleave="hoverIndex = -1">
        <div class="table-scroll">
          <t-table-list alternate>
            <t-table-list-row head @mouseover="hoverIndex = -1">
              <t-table-list-head width="100px">{{ $t('custom_fields.sort') }}</t-table-list-head>
              <t-table-list-head width="160px">{{ $t('custom_fields.title') }}</t-table-list-head>
              <t-table-list-head width="190px">{{ $t('custom_fields.tag') }}</t-table-list-head>
              <t-table-list-head v-if="!isUSerTab" width="170px">{{ $t('custom_fields.channels') }}</t-table-list-head>
              <t-table-list-head width="80px">{{ $t('custom_fields.type') }}</t-table-list-head>
              <t-table-list-head v-if="!isUSerTab">{{ $t('custom_fields.required') }}</t-table-list-head>
              <t-table-list-head width="151px"></t-table-list-head>
            </t-table-list-row>
            <t-draggable
              v-model="record"
              tag="tbody"
              :scroll-sensitivity="500"
              easing="linear"
              animation="50"
              class="draggable-container"
              :class="{ drag }"
              @start="onStart"
              @end="onEnd"
            >
              <t-table-list-row
                v-for="(item, index) in record"
                :key="item.id"
                class="custom-fields-row"
                @mouseover="hoverIndex = index"
              >
                <t-table-list-cell>
                  <div v-if="hoverIndex !== index" class="sort fade-in font-medium text-grey-900">
                    {{ index + 1 }}
                  </div>
                  <div v-else class="handle cursor-grab">
                    <img :src="`${$root.assetsURL}img/action_icons/icon-drag.svg`" alt="drag" />
                  </div>
                </t-table-list-cell>
                <t-table-list-cell>
                  <div class="pr-1 text-sm font-medium">
                    {{ item.title }}
                  </div>
                </t-table-list-cell>
                <t-table-list-cell>
                  <div class="tag-name break-all pr-2 text-sm font-medium text-grey-600">
                    {{ item.identifier && `[${item.identifier}]` }}
                  </div>
                </t-table-list-cell>
                <t-table-list-cell v-if="!isUSerTab">
                  <t-tag
                    v-tooltip="{
                      placement: 'bottom',
                      content: getChannelTooltip(item),
                      container: '.custom-fields-table',
                      html: item.channels.length > 1,
                    }"
                    variant="channel"
                    :avatar="getChannelIcon(item)"
                    size="md"
                    class="custom-fields-channel-tag truncate"
                  >
                    <span class="tag-content" :title="getChannelName(item)">{{ getChannelName(item) }}</span>
                  </t-tag>
                </t-table-list-cell>
                <t-table-list-cell>
                  <span class="cf-type font-medium">{{ getCustomFieldName(item.field_type) }}</span>
                </t-table-list-cell>
                <t-table-list-cell v-if="!isUSerTab">
                  <span class="cf-type font-medium">
                    {{ item.is_required ? 'Yes' : 'No' }}
                  </span>
                </t-table-list-cell>
                <t-table-list-cell>
                  <div class="flex items-center">
                    <router-link :to="getCustomFieldUrl(item.id)">
                      <atomic-button size="sm" btn-style="secondary">{{ $t('custom_fields.edit') }}</atomic-button>
                    </router-link>
                    <atomic-button size="sm" btn-style="secondary ml-2" @click="deleteItem(item.id)">
                      <img :src="`${$root.assetsURL}img/custom-fields/trash-red.svg`" alt="" />
                    </atomic-button>
                  </div>
                </t-table-list-cell>
              </t-table-list-row>
            </t-draggable>
          </t-table-list>
        </div>
      </div>
      <custom-fields-empty v-else-if="loaded" :type="typeParam" />
    </div>

    <transition name="slide-fade-down">
      <div v-show="recordIsDirty" class="action-bar-container hidden lg:block">
        <t-action-popup
          :saving="saving"
          :text="$t('general.action_popup_you_have_unsaved_changes')"
          :discard-text="$t('general.action_popup_discard')"
          :save-text="$t('general.action_popup_save_changes')"
          @save="save"
          @reset="revertDirtyChanges"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { capitalize, upperCase } from 'lodash';
import { mapActions, mapState } from 'vuex';

import { sortCustomField } from '@/components/CustomFields/Api';
import { CUSTOM_FIELD } from '@/Configs/Constants';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import TDraggable from '@/UIKit/Utils/TDraggable';

import { getChannelIcon, getChannelTooltip, getCustomFieldName, getCustomFieldUrl } from './utils';
import CustomFieldsEmpty from '../CustomFieldsEmpty';

export default {
  mixins: [RouterWarningOnEdit],
  name: 'CustomFieldsTable',
  components: {
    CustomFieldsEmpty,
    TDraggable,
  },
  computed: {
    ...mapState({
      customFieldsTicket: (state) => state.customFields.customTicketFields,
      customFieldsProfile: (state) => state.customFields.customProfileFields,
      customFieldsUser: (state) => state.customFields.customUserFields,
      customFieldsContact: (state) => state.customFields.customContactFields,
    }),
    storeItems() {
      if (this.typeParam === CUSTOM_FIELD.TICKET) {
        return this.customFieldsTicket;
      } else if (this.typeParam === CUSTOM_FIELD.PROFILE) {
        return this.customFieldsProfile;
      } else if (this.typeParam === CUSTOM_FIELD.USER) {
        return this.customFieldsUser;
      } else if (this.typeParam === CUSTOM_FIELD.CONTACT) {
        return this.customFieldsContact;
      }
    },
    typeParam() {
      return upperCase(this.$route.meta.activePage) || CUSTOM_FIELD.TICKET;
    },
    isUSerTab() {
      return CUSTOM_FIELD.USER === this.typeParam;
    },
  },
  watch: {
    storeItems: {
      handler(val) {
        this.recordIsDirty = false;
        this.record = val;
        this.$nextTick(() => {
          this.initWarning();
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      drag: false,
      dragging: false,
      hoverIndex: -1,
      saving: false,
      record: [],
    };
  },
  created() {
    this.record = this.storeItems;
    this.initWarning();
  },
  methods: {
    ...mapActions({
      deleteCustomField: 'customFields/deleteCustomField',
      loadCustomFields: 'customFields/loadCustomFields',
    }),
    getCustomFieldName,
    getCustomFieldUrl,
    getChannelIcon,
    getChannelTooltip,
    getChannelName(item) {
      if (item.channels?.length)
        if (item.channels.length === 1) {
          return item.channels[0].display_name;
        } else {
          return `${item.channels.length} ${this.$t('custom_fields.channels')}`;
        }
      else return this.$t('custom_fields.all_channels');
    },
    onStart() {
      this.drag = true;
      this.dragging = true;
    },
    onEnd() {
      this.hoverIndex = -1;
      this.dragging = false;
    },
    save() {
      this.saving = true;
      sortCustomField({ ids: this.record.map((item) => item.id), type: this.typeParam })
        .then((res) => {
          this.$store.commit(`customFields/setCustom${capitalize(this.typeParam)}Fields`, this.record);
          this.initialRecord = this.record;
          this.revertDirtyChanges();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    onMousemove() {
      setTimeout((s) => {
        if (!this.dragging) this.drag = false;
      }, 200);
    },
    deleteItem(id) {
      const i18n = {
        message: this.$t('custom_fields.delete_custom_fields_message'),
        confirm: this.$t('custom_fields.delete_custom_fields_confirm'),
        success: this.$t('custom_fields.delete_custom_fields_successfull'),
      };
      this.deleteCustomField({ id, i18n }).then(() => {
        this.loadCustomFields();
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./CustomFieldsTable.scss" />
<style lang="scss" src="./CustomFieldsTableGlobal.scss" />
