<template>
  <div class="report__tableWrapper" :class="{ 'is-csat': this.isCsat }">
    <div v-if="this.isCsat" class="csat-title">{{ $t('csat.customer_reports') }}</div>
    <ag-grid-vue
      v-if="!isCsat"
      :row-data="rowData"
      :column-defs="colDefs"
      style="height: 500px"
      class="ag-theme-quartz"
    />
    <ag-grid-vue
      v-else
      :column-defs="colDefs"
      style="height: 500px"
      class="ag-theme-quartz"
      row-model-type="infinite"
      :pagination="true"
      :pagination-page-size="20"
      :cache-block-size="20"
      :tooltip-show-delay="tooltipShowDelay"
      :tooltip-mouse-strack="true"
      :pagination-page-size-selector="false"
      :default-col-def="defaultColDef"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import Util from '../Util/Util';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import { AgGridVue } from 'ag-grid-vue3'; // Vue Data Grid Component
import AgAvatar from '../../../components/AgAvatar';
import AgRatingCell from '../../../components/AgRatingCell.vue';
import AgTicketCell from '@/components/AgTicketCell.vue';

export default {
  props: {
    currentData: {},
    timeColumns: {},
    isCsat: false,
    ticketIds: {},
    actualData: {},
  },
  components: { AgGridVue, AgAvatar },
  data() {
    return {
      rowData: [],
      colDefs: [],
      tooltipShowDelay: 200,
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {},
      paginationNumberFormatter: null,
    };
  },
  watch: {
    currentData() {
      if (this.isCsat) {
        this.gridApi.refreshInfiniteCache();
      } else {
        this.render();
      }
    },
    ticketIds() {
      if (this.isCsat) {
        this.gridApi.refreshInfiniteCache();
      } else {
        this.render();
      }
    },
  },
  mounted() {
    if (this.isCsat) {
      this.renderCsatCols();
    } else {
      this.render();
    }
  },
  methods: {
    renderCsatCols() {
      this.defaultColDef = {
        unSortIcon: true,
        resizable: false,
        suppressMovable: true,
        sortable: false,
      };
      this.colDefs = [
        {
          field: 'rating',
          headerName: this.$t('reports.col_rating'),
          width: 85,
          cellClass: 'rating-cell',
          cellRendererSelector: (p) => {
            if (p.value === undefined) return;
            return {
              component: AgRatingCell,
              params: {
                rating: p.value,
                assetsUrl: this.$root.assetsURL,
              },
            };
          },
        },
        {
          field: 'name',
          headerName: this.$t('reports.col_name'),
          tooltipValueGetter: (p) => {
            return p.value;
          },
        },
        {
          field: 'user',
          headerName: this.$t('reports.col_user'),
          cellRendererSelector: (p) => {
            if (p.value === undefined) return;
            return {
              component: AgAvatar,
              params: {
                width: '24',
                color: p.value.color,
                abbr: p.value.abbr?.substr(0, 1),
                image: p.value.profile_image,
                fullName: p.value.full_name,
              },
            };
          },
        },
        {
          field: 'remark',
          headerName: this.$t('reports.col_remark'),
          tooltipValueGetter: (p) => {
            return p.value;
          },
        },
        {
          field: 'profile',
          headerName: this.$t('reports.col_profile'),
        },
        {
          field: 'ticket_id',
          headerName: this.$t('reports.col_ticket_id'),
          cellClass: 'ticket-cell',
          cellRendererSelector: (p) => {
            if (p.value === undefined) return;
            return {
              component: AgTicketCell,
              params: {
                router: this.$router,
                ticket: p.value,
              },
            };
          },
        },
        {
          field: 'date',
          headerName: this.$t('reports.col_date'),
        },
      ];
    },
    render(tableData = null) {
      let source = this.currentData || tableData;

      if (source[0]) {
        Object.keys(source[0]).forEach((col) => {
          this.colDefs.push({
            headerName: this.$t('reports.col_' + col),
            unSortIcon: true,
            field: col,
            valueFormatter: (params) => {
              if (this.timeColumns && this.timeColumns.includes(params.colDef.field)) {
                return Util.secondsToString(params.value, false);
              }
              return params.value;
            },
          });
        });
      }

      this.rowData = source;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.updateData(params);
    },
    updateData(params) {
      const dataSource = {
        rowCount: undefined,
        getRows: async (params) => {
          const pageNumber = params.endRow / 20;
          this.showGridLoadingOverlay();
          const request = await axios.post(`/api/v2/csat/reports?per_page=${20}&page=${pageNumber}`, {
            tickets: this.ticketIds,
          });

          if (request.status !== 200) {
            params.failCallback();
            return;
          }

          const lastRow = request.data.meta.total;
          const data = request.data.data;

          const formattedData = data.map((csat) => {
            return {
              rating: csat.rating || '-',
              name: csat.ticket?.contact?.full_name || '-',
              user: csat.ticket?.agent || '-',
              remark: csat.remark || '-',
              profile: csat.ticket?.contact?.profile?.[0]?.name ? csat.ticket.contact.profile[0].name : '-',
              ticket_id: csat.ticket?.id || '-',
              date: moment
                .tz(csat.submitted_at, 'YYYY-MM-DD HH:mm:ss', this.$root.user.timezone)
                .format('ddd MMM DD YYYY'),
            };
          });
          this.hideGridLoadingOverlay();
          params.successCallback(formattedData, lastRow);
        },
      };
      params.api.setGridOption('datasource', dataSource);
    },
    showGridLoadingOverlay() {
      this.gridApi.showLoadingOverlay();
    },
    hideGridLoadingOverlay() {
      this.gridApi.hideOverlay();
    },
  },
};
</script>

<style lang="scss" scoped>
.report__tableWrapper {
  width: 100%;
  position: relative;
  overflow-x: auto;
}
</style>

<style lang="scss">
.report__tableWrapper {
  .csat-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey-800);
    padding: 2rem 0 0.75rem 0;
  }
}

.report__tableWrapper.is-csat {
  .rating-cell {
    display: flex !important;
    padding-left: 0 !important;
  }

  .rating-cell-container {
    width: 100%;
  }

  .rating-cell-container > div {
    height: 100%;
    width: 100%;
  }

  .ticket-cell > span > div,
  .ticket-cell > span {
    display: inline;
  }

  .remark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ag-paging-row-summary-panel {
    display: none !important;
  }
  .ag-paging-panel.ag-unselectable {
    justify-content: center !important;
  }
}
</style>
