<template>
  <div
    class="flex min-w-0 flex-1 bg-white"
    @dragenter.stop.prevent="onDragEnter"
    @dragover.prevent.stop="onDragOver"
    @dragleave.prevent.stop="onDragLeave"
    @drop.stop.prevent="onDrop"
    @paste="onPaste"
  >
    <drag-over-overlay
      v-if="showDragOver"
      @dragenter.stop.prevent="onDragEnter"
      @dragover.prevent.stop="onDragOver"
    ></drag-over-overlay>
    <!-- Modals -->
    <insert-attachment-modal
      v-else-if="attachmentModal.show"
      :attachment-files="attachmentModal.files"
      :dragging-over="draggingOver"
      :init-files="attachmentModal.files"
      :init-thread-identifier="attachmentModal.threadIdentifier"
      :initial-message="attachmentModal.message"
      :reply-to="replyTo"
      :spinner="spinner"
      @upload="uploadAttachment"
      @close="closeModal"
    ></insert-attachment-modal>
    <manage-group-modal
      v-if="manageGroup.show"
      tabindex="0"
      :thread="manageGroup.thread"
      @close="closeModal"
      @submit="manageGroup.show = false"
    ></manage-group-modal>
    <search-modal
      v-else-if="searchMessages.show"
      :thread="thread"
      :query="searchMessages.term"
      @close="closeModal"
      @goToMessage="hideSidebar"
    ></search-modal>
    <search-attachment-modal
      v-else-if="searchAttachments.show"
      :thread="thread"
      :query="searchAttachments.term"
      @close="closeModal"
    ></search-attachment-modal>
    <search-colleague-modal
      v-else-if="searchColleagues.show"
      :thread="thread"
      :query="searchColleagues.term"
      @close="closeModal"
      @goToParticipant="hideSidebar"
    ></search-colleague-modal>
    <add-user-modal
      v-if="showAddUserModal && $authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)"
      v-model="showAddUserModal"
    />

    <portal to="sidebar/threads">
      <div class="-mt-4 p-4 pr-4 lg:pr-0">
        <div
          class="tt-search-box border-radius flex items-center rounded-lg bg-grey-200 px-3"
          style="height: 46px; margin-top: 0; margin-bottom: 21px"
          @click="$refs.indexSearchThread.focus()"
        >
          <div class="flex items-center">
            <i class="material-icons mr-3 rounded-lg text-grey-600" style="font-size: 23px">search</i>
          </div>
          <div class="w-100 h-full">
            <input
              ref="indexSearchThread"
              type="text"
              :value="queryValue"
              :placeholder="$t('general.searching')"
              class="w-100 h-full bg-grey-200 text-grey-600"
              style="line-height: normal"
              @input="(e) => (queryValue = e.target.value)"
            />
          </div>
        </div>
        <thread-list type="groups" class="thread-groups pb-4" :query-value="queryValue.toLowerCase()"></thread-list>
        <thread-list type="users" class="thread-users pb-4" :query-value="queryValue.toLowerCase()"></thread-list>
        <div v-if="$authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)" style="padding: 0px 20px">
          <div class="flex cursor-pointer items-center" @click="openAddUserModal()">
            <div
              class="btn btn-circle pointer rounded bg-grey-200 text-center"
              style="width: 25px; height: 25px; padding: 3px 3px"
            >
              <i style="font-size: 17px" class="material-icons font-semibold">add</i>
            </div>
            <span class="ml-2 text-ellipsis font-semibold">
              {{ $t('internal_chat.sidebar_buttons_add_new_user') }}
            </span>
          </div>
        </div>
      </div>
    </portal>

    <router-view ref="view" class="min-w-0 flex-grow"></router-view>

    <div
      v-if="thread"
      v-show="showSidebar"
      class="sidebar-container w-350 min-w-350 scroll-on-hover flex flex-col border-l border-grey-200"
    >
      <i class="sidebar-container-close material-icons ml-auto cursor-pointer text-grey-500" @click="toggleSidebarIcon">
        close
      </i>
      <div class="pl-4" style="margin-bottom: 16px">
        <div
          class="tt-search-box border-radius flex items-center rounded-lg bg-grey-200 px-3"
          @click="$refs.search.focus()"
        >
          <div class="flex items-center">
            <i class="material-icons mr-3 rounded-lg text-grey-600" style="font-size: 23px">search</i>
          </div>
          <form class="w-100 h-full" @submit.prevent="openMessagesModal">
            <input
              ref="search"
              v-model="searchMessages.term"
              type="text"
              :placeholder="$t('general.searching')"
              class="w-100 h-full bg-grey-200 text-grey-600"
              style="line-height: normal"
            />
          </form>
        </div>
      </div>

      <div v-if="thread.isGroup()" class="sidebar_block select-none">
        <collapse
          :key="'chat-thread-info-' + thread.identifier"
          class="flex flex-col justify-center leading-none"
          collapse-key="chat-thread-info"
          :default-open="true"
        >
          <template #collapse-title>
            <div class="mb-1">
              <h6 class="mb-0" style="max-width: 294px">{{ $t('internal_chat.sidebar_info_title') }}</h6>
            </div>
          </template>
          <template #collapse-content>
            <div class="sidebar_block_body">
              <thread-info :thread="thread" style="line-height: 1.9"></thread-info>
            </div>
          </template>
        </collapse>
      </div>
      <div v-if="thread.isGroup()" class="sidebar_block select-none">
        <collapse
          :key="'chat-thread-participants-' + thread.identifier"
          class="flex cursor-pointer flex-col justify-center leading-none"
          collapse-key="chat-thread-participants"
          :default-open="true"
        >
          <template #collapse-title>
            <div class="mb-1 inline-flex items-center">
              <h6 class="mb-0">{{ $t('internal_chat.sidebar_participants_title') }}</h6>
              <span class="pl-1 font-bold text-grey-600" style="line-height: 1.1">
                ({{ thread.participants.length }})
              </span>
            </div>
          </template>
          <template #collapse-content>
            <div class="sidebar_block_body" style="padding-left: 21px; width: 90%">
              <thread-participants
                class="sidebar_participants mb-0"
                :thread="thread"
                @button="searchColleagues.show = true"
              ></thread-participants>
            </div>
          </template>
        </collapse>
      </div>
      <div v-if="thread.isUser()" class="sidebar_block select-none">
        <collapse
          :key="'chat-user-profile-' + thread.identifier"
          class="flex flex-col justify-center leading-none"
          collapse-key="chat-user-profile"
          :default-open="true"
        >
          <template #collapse-title>
            <div class="mb-1">
              <h6 class="mb-0">{{ $t('internal_chat.sidebar_info_title') }}</h6>
            </div>
          </template>

          <template #collapse-content>
            <div class="sidebar_block_body rounded-lg bg-grey-200 p-6 text-center" style="line-height: 1.5">
              <div>
                <avatar
                  :color="thread.getUser().color"
                  :abbr="thread.getUser().abbr"
                  :image="thread.getUser().profileImage"
                  :width="100"
                  :height="100"
                  :font-size="'38px'"
                  class="white mb-4"
                ></avatar>
              </div>
              <div class="mb-1 inline-flex w-full justify-center font-bold">
                <span class="text-ellipsis">{{ thread.getUser().getDisplayName() }}</span>
                <span class="flex items-center">
                  <online-status
                    :status="chatStatus(thread.getUser())"
                    class="ml-1"
                    style="font-size: 16px; margin-bottom: -1px"
                  ></online-status>
                </span>
              </div>
              <div v-if="thread.isUser() && thread.getUser().exists" class="text-grey-600">
                {{
                  $t('internal_chat.sidebar_info_date_prefix', {
                    date: moment.utc(thread.getUser().createdAt * 1000).format('LL'),
                  })
                }}
              </div>
            </div>
          </template>
        </collapse>
      </div>
      <div v-if="thread.attachments.length || thread.searchQuery" class="sidebar_block select-none">
        <collapse
          :key="'chat-thread-attachments-' + thread.identifier"
          class="flex flex-col justify-center leading-none"
          collapse-key="chat-thread-attachments"
          :default-open="true"
        >
          <template #collapse-title>
            <div class="mb-1 inline-flex">
              <h6 class="mb-0">{{ $t('internal_chat.sidebar_shared_files_title') }}</h6>
              <!--                        <span class="font-bold text-grey-600 pl-1" style="line-height:1.1;">({{thread.attachments.length}})</span>-->
            </div>
          </template>
          <template #collapse-content>
            <div class="sidebar_block_body" style="padding-left: 21px">
              <thread-attachments
                :key="'chat-thread-attachments-component-' + thread.identifier"
                style="width: 90%; overflow: hidden"
                :thread="thread"
                @input="openAttachmentModal"
              ></thread-attachments>
            </div>
          </template>
        </collapse>
      </div>
      <!--<div class="sidebar_block" v-if="thread.attachments.length">
                <collapse collapse-key="chat-thread-pinned flex flex-col" :key="'chat-thread-pinned-'+thread.identifier" :defaultOpen="true">
                    <div slot="collapse-title" class="mb-1">
                        <h6 class="mb-0">Pinned items</h6>
                    </div>
                    <div slot="collapse-content" class="sidebar_block_body">
                        TODO
                        <pinned-items :key="'chat-thread-pinned-component-'+thread.identifier"></pinned-items>
                    </div>
                </collapse>
            </div>-->
    </div>
  </div>
</template>

<script>
import { sanitize } from 'dompurify';
import { map } from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

import { fetchAllUsers } from '@/api';
import AddUserModal from '@/components/Users/components/AddUsers/AddUserModal';
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { uploadFiles } from '@/util/fileHelper';
import PusherHelper from '@/util/PusherHelper';
import UsersUtil from '@/util/Users';

import Avatar from '../../Avatar';
import Collapse from '../../Collapse';
import DragOverOverlay from '../Components/DragOverOverlay';
import InsertAttachmentModal from '../Components/Modals/InsertAttachmentModal';
import ManageGroupModal from '../Components/Modals/ManageGroupModal';
import SearchAttachmentModal from '../Components/Modals/SearchAttachmentModal';
import SearchColleagueModal from '../Components/Modals/SearchColleagueModal';
import SearchModal from '../Components/Modals/SearchModal';
import OnlineStatus from '../Components/OnlineStatus';
import ThreadAttachments from '../Components/ThreadAttachments';
import ThreadInfo from '../Components/ThreadInfo';
import ThreadList from '../Components/ThreadList';
import ThreadParticipants from '../Components/ThreadParticipants';
import { clean } from '../Util/Chat';
export default {
  name: 'InternalChatIndex',
  components: {
    DragOverOverlay,
    InsertAttachmentModal,
    ManageGroupModal,
    ThreadAttachments,
    OnlineStatus,
    Collapse,
    ThreadParticipants,
    ThreadInfo,
    ThreadList,
    Avatar,
    SearchModal,
    SearchAttachmentModal,
    SearchColleagueModal,
    AddUserModal,
  },
  data() {
    return {
      user: {},
      users: [],
      agencyChannel: [],
      userChannel: [],
      current: true,
      draggingOver: false,
      showSidebar: true,
      spinner: false,
      queryValue: '',
      disableOptions: null,
      replyTo: null,
      showAddUserModal: false,
      attachmentModal: {
        show: false,
        threadIdentifier: null,
        files: [],
        message: '',
      },
      manageGroup: {
        show: false,
        thread: null,
      },
      searchMessages: {
        show: false,
        term: '',
      },
      searchAttachments: {
        show: false,
        term: '',
      },
      searchColleagues: {
        show: false,
        term: '',
      },
      PERMISSION,
    };
  },
  async beforeCreate() {
    let hash = this.$route.hash.split('/');
    let newIdentifier = hash[0] || this.$route.params.identifier || null;

    let identifier = await this.$tStorage.getItem('internalChat.currentThread');
    if (identifier && !newIdentifier) {
      this.$router.push('/chat/' + identifier);
    }
  },
  beforeMount() {
    eventBus.$on('chat@SHOW_MANAGE_GROUP_MODAL', (thread) => {
      this.manageGroup.thread = thread || null;
      this.manageGroup.show = true;
    });
    eventBus.$on('chat@SHOW_ATTACHMENT_MODAL', this.showAttachmentModal);
    eventBus.$on('chat@TOGGLE_SIDEBAR', this.toggleSidebar);

    eventBus.$on('user-deleted', () => {
      if (this.thread.isUser()) {
        this.disableOptions = true;
      }
    });
  },
  created() {
    fetchAllUsers({ cacheEnabled: this.$root.isCacheRequestEnabled })
      .then((res) => {
        this.$root.users = res.data?.users;
        this.user = this.$root.user;
        this.users = this.$root.users;
        this.agencyChannel = this.$root.agencyChannel;
        this.userChannel = this.$root.userChannel;
        this.initUserStore();
        this.initSidebarState();
      })
      .catch((e) => {
        console.error('Error while fetching users: ', e);
        reject(e);
      });
  },
  mounted() {
    window.addEventListener('resize', this.toggleSidebarOnResize);

    eventBus.$on('chat@ON_MAIN_COMPOSER_INIT', (editor) => {
      this.editor = editor;
      if (this.queryValue) this.queryValue = '';
    });
    eventBus.$on('chat@HIDE_SIDEBAR', () => {
      this.showSidebar = false;
    });
    eventBus.$on('chat@SHOW_MESSAGES_MODAL', this.openMessagesModal);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.toggleSidebarOnResize);

    // eventBus.$off(['chat@SHOW_MANAGE_GROUP_MODAL', 'chat@SHOW_ATTACHMENT_MODAL', 'chat@TOGGLE_SIDEBAR', 'attachment_file_upload']);
    eventBus.$off([
      'chat@SHOW_MANAGE_GROUP_MODAL',
      'chat@SHOW_ATTACHMENT_MODAL',
      'chat@TOGGLE_SIDEBAR',
      'chat@SHOW_MESSAGES_MODAL',
    ]);
  },
  methods: {
    ...PusherHelper,
    ...UsersUtil,
    clearSearch() {
      this.$refs.search.blur();
      this.searchMessages.term = '';
      this.searchMessages.show = false;
    },
    onPaste(e) {
      if (!e.clipboardData.files.length) {
        return;
      }

      const files = [];
      e.clipboardData.files?.forEach((file) => {
        files.push(file);
      });

      this.draggingOver = false;

      if (this.attachmentModal.show) {
        // append files
        eventBus.$emit('chat@ATTACHMENT_MODAL_APPEND', files);
      } else {
        // replace files
        this.showAttachmentModal(files);
      }
    },
    onDragEnter(e) {
      let dataTransfer =
        e.originalEvent && e.originalEvent.dataTransfer ? e.originalEvent.dataTransfer : e.dataTransfer;
      if (window.isDragSourceExternalFile(dataTransfer)) {
        this.draggingOver = true;
      }
    },
    onDragLeave(e) {
      this.draggingOver = false;
    },
    onDragOver(e) {
      // todo rl: e.dataTransfer.files is always empty on drag, only filled on drop. can we know what type of data is dragged?
      let dataTransfer =
        e.originalEvent && e.originalEvent.dataTransfer ? e.originalEvent.dataTransfer : e.dataTransfer;
      if (window.isDragSourceExternalFile(dataTransfer)) {
        this.draggingOver = true;
      }
    },
    onDrop(e) {
      this.draggingOver = false;

      // no files dropped
      if (!e.dataTransfer.files.length) {
        return;
      }

      if (this.attachmentModal.show) {
        // append files
        eventBus.$emit('chat@ATTACHMENT_MODAL_APPEND', e.dataTransfer.files);
      } else {
        // replace files
        // eventBus.$emit('attachment_file_upload', e.dataTransfer.files);
        eventBus.$emit('chat@ATTACHMENT_MODAL_APPEND', e.dataTransfer.files);
        this.showAttachmentModal(e.dataTransfer.files);
      }
    },
    openMessagesModal() {
      this.searchMessages.show = true;
      if (this.$refs.search) this.$refs.search.blur();
    },
    openAttachmentModal(searchQuery) {
      this.searchAttachments.show = true;
      this.searchAttachments.term = searchQuery;
    },
    showAttachmentModal(droppedFiles = []) {
      //this.draggingOver = false;
      if (this.thread.isUser() && !this.thread.getUser().exists) {
        return;
      }

      this.attachmentModal.show = true;
      this.attachmentModal.threadIdentifier = this.thread.identifier;
      this.attachmentModal.files = droppedFiles;
      this.attachmentModal.message = clean(this.editor.html.get());

      if (this.getDraft()) {
        let draftReplyTo = sanitize(JSON.parse(this.getDraft()).replyToMessageId);
        this.replyTo = this.thread.getLoadedMessageById(parseInt(draftReplyTo));
      }
    },
    closeModal() {
      if (this.attachmentModal.show) this.onCloseAttachmentModal();
      else if (this.searchAttachments.show) {
        this.searchAttachments.show = false;
        eventBus.$emit('chat@ATTACHMENT_MODAL_CLOSE');
      } else if (this.searchColleagues.show) this.searchColleagues.show = false;
      else if (this.manageGroup.show) this.manageGroup.show = false;
      else if (this.searchMessages.show) this.clearSearch();

      if (!is_mobile_device() && this.editor) {
        window.placeCaretAtEnd(this.editor.el);
      }
    },
    onCloseAttachmentModal() {
      //this.draggingOver = false;
      this.attachmentModal.show = false;
      this.attachmentModal.threadIdentifier = null;
      this.attachmentModal.files = [];
      this.attachmentModal.message = '';
    },
    openAddUserModal() {
      this.showAddUserModal = true;
    },
    hideSidebar() {
      this.closeModal();
      if (window.innerWidth <= 991) {
        this.showSidebar = false;
      }
    },
    async uploadAttachment({ files, message, replyTo, threadIdentifier }) {
      this.spinner = true;

      let fileSize = 0;
      files.forEach((f) => {
        fileSize += f.size;
      });

      if (fileSize > 20971520) {
        this.flashError('The total size of your attachments may not exceed 20MB');
        this.spinner = false;
        return;
      }

      let res = await uploadFiles(files, 'messenger_message')
        .then((r) => {
          return r;
        })
        .catch((e) => {
          this.spinner = false;
        });

      let thread = this.threadByIdentifier(threadIdentifier);

      if (!thread) {
        throw Error('Thread not found: '.threadIdentifier);
      }

      if (res) {
        return await thread
          .sendMessage({ messageBody: message }, map(res.data, 'attachment_id'), (replyTo || {}).id || null)
          .then((m) => {
            this.attachmentModal.show = false;
            if (this.editor) {
              this.editor.html.set('');
              // this.$router.push('/chat/' + threadIdentifier);
              eventBus.$emit('CHAT@AFTER_ATTACHMENT_SENT');
            }
            return m;
          })
          .catch((e) => {
            // todo growl
            throw e;
          })
          .finally(() => {
            this.spinner = false;
            window.placeCaretAtEnd(this.editor.el);
          });
      }
    },
    initSidebarState() {
      if (window.innerWidth <= 991) {
        this.showSidebar = false;
        this.$tStorage.setItem('sidebar_thread_state', this.showSidebar);
      }
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
      this.$tStorage.setItem('sidebar_thread_state', this.showSidebar);
    },
    toggleSidebarIcon() {
      this.toggleSidebar();
      eventBus.$emit('chat@TOGGLE_SIDEBAR_STATE', this.showSidebar);
    },
    async toggleSidebarOnResize() {
      if (!is_mobile_device()) {
        if (window.innerWidth > 991) {
          if ((await this.$tStorage.getItem('sidebar_thread_state')) === true) {
            this.showSidebar = true;
          }
        } else if (window.innerWidth <= 991) {
          this.showSidebar = false;
        }
      }
    },
    async getDraft() {
      return (await this.$tStorage.getItem('draft_' + this.thread.identifier)) || '';
    },
  },
  computed: {
    showDragOver() {
      return (
        this.draggingOver && !this.attachmentModal.show && !(this.thread.isUser() && !this.thread.getUser().exists)
      );
    },
    ...mapGetters({
      thread: 'chat/currentThread',
      currentUser: 'usersInternalChat/currentUser',
      chatStatus: 'chat/chatStatus',
      threadByIdentifier: 'chat/threadByIdentifier',
    }),
    moment: () => moment,
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.closeModal();
      },
    },
  },
};
</script>
<style lang="scss">
.tt-search-box-threads {
  cursor: text;
  height: 35px;
  margin: 0.5rem 0 1.25rem 0;

  input::placeholder {
    color: theme('colors.grey-600');
    opacity: 0.8;
  }
}
</style>

<style lang="scss" scoped>
.tt-search-box {
  cursor: text;
  margin-top: 1rem;
  height: 46px;

  input::placeholder {
    color: theme('colors.grey-600');
    opacity: 0.8;
  }
}

.sidebar-container-close {
  position: fixed;
  top: 10px;
  right: 12px;
  z-index: 9999999999;
}

@media (max-width: 1299px) {
  .tt-search-box:not(.tt-search-box-threads) {
    margin-top: 2.5rem;
  }
}

@media (max-width: 800px) {
  .scroll-on-hover {
    overflow-y: overlay !important;
    padding-right: 16px;

    &:hover {
      padding-right: 16px;
    }
  }
}
</style>
