<script>
import { formatDate } from '@/util/date';

export default {
  props: ['ticket'],

  computed: {
    assignedLabel: function () {
      return this.$t('tickets.status_assigned', {
        to: this.ticket.agent.first_name,
        by: this.ticket.assignee.first_name,
        at: formatDate(this.ticket.assigned_at),
      });
    },

    openLabel: function () {
      return this.$t('tickets.status_open', {
        at: formatDate(this.ticket.created_at),
      });
    },

    closedLabel: function () {
      if (this.ticket.closed_by == null) {
        this.ticket.closed_by = {
          first_name: 'bot',
        };
      }

      return this.$t('tickets.status_closed', {
        by: this.ticket.closed_by.first_name,
        at: formatDate(this.ticket.closed_at),
      });
    },

    spamLabel: function () {
      return this.$t('tickets.status_spam');
    },
  },
};
</script>

<template>
  <span class="m-l-1 hidden-xs-down">
    <span v-if="ticket.status == 'CLOSED'" class="label _300">
      {{ closedLabel }}
    </span>

    <span v-if="ticket.status == 'ASSIGNED'" class="label success _300 text-white">
      {{ assignedLabel }}
    </span>

    <span v-if="ticket.status == 'OPEN'" class="label warning _300 text-white">
      {{ openLabel }}
    </span>

    <span v-if="ticket.status == 'INVALID'" class="label danger _300 text-white">
      {{ spamLabel }}
    </span>
  </span>
</template>
