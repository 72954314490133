import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/rules',
    component: require('../../components/Rules/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__RULES__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: 'create',
        name: 'ruleCreate',
        component: require('../../components/Rules/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__RULES__MANAGE },
      },
      {
        path: 'create/:sentFromCsat/:csatId/:csatName',
        name: 'ruleCreateFromCsat',
        component: require('../../components/Rules/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__RULES__MANAGE },
      },
      {
        path: '/admin/rules/:id',
        name: 'ruleEdit',
        component: require('../../components/Rules/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__RULES__MANAGE },
      },
      {
        path: '/admin/rules/:id/:sentFromCsat/:csatId',
        name: 'ruleEditFromCsat',
        component: require('../../components/Rules/Edit').default,
        props: (route) => ({ action: 'edit', ...route.query }),
        meta: { permissionRequired: PERMISSION.SETTINGS__RULES__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'rules' },
        meta: { permissionRequired: PERMISSION.SETTINGS__RULES__MANAGE },
      },
    ],
  },
];
