<script type="text/babel">
import { includes, map } from 'lodash';

import avatar from '../Avatar.vue';

export default {
  props: ['ticket'],

  data: function () {
    return {
      test: {},
      loading: false,
    };
  },

  components: {
    avatar,
  },

  computed: {
    isAssignee() {
      return this.ticket.agent && this.ticket.agent.id === this.$root.user.id;
    },
    isWatching() {
      return includes(map(this.ticket.watchers, 'id'), this.$root.user.id);
    },
    hasWatchers() {
      return this.ticket.watchers && this.ticket.watchers.length;
    },
  },

  methods: {
    triggerWatch() {
      if (!this.ticket.agent.id) {
        alert("Can't watch unassigned ticket");
      } else if (this.ticket.agent.id === this.$root.user.id) {
        return;
      } else if (!this.loading) {
        if (this.isWatching) {
          this.loading = true;
          axios.delete('/api/v2/tickets/' + this.ticket.id + '/watchers/0').then((res) => {
            for (let i = 0; i < this.ticket.watchers.length; i++) {
              if (this.ticket.watchers[i].id === this.$root.user.id) {
                this.ticket.watchers.splice(i, 1);
              }
            }
            this.loading = false;
          });
        } else {
          this.loading = true;
          axios.post('/api/v2/tickets/' + this.ticket.id + '/watchers').then((res) => {
            this.ticket.watchers.push(this.$root.user);
            this.loading = false;
          });
        }
      }
    },
  },
};
</script>

<template>
  <div
    class="b-a-lightblue hover-item-hover-background inline-flex rounded-full bg-white"
    :class="{ 'has-watchers ': hasWatchers }"
  >
    <div
      class="hover-ticket-nav-item flex cursor-pointer items-center pl-1 text-grey-600"
      style="height: 36px"
      :class="{ 'text-grey-600-dark': hasWatchers, 'cursor-not-allowed': isAssignee, 'no-watchers': !hasWatchers }"
    >
      <div
        role="button"
        class="selector-conversation-watch flex flex-1 items-center py-2 pl-3 leading-none"
        style="margin-right: 20px"
        :class="{ watchers_active: isWatching, 'text-grey-600': isAssignee || !ticket.agent }"
        @click="triggerWatch()"
      >
        <i class="material-icons md-18 pr-2" :class="{ 'pr-4': hasWatchers }">notifications_none</i>
        <span v-if="!isAssignee && !hasWatchers" class="px-1">
          {{ $t('ticket_sidebar.receive_notifications') }}
        </span>
      </div>
    </div>
    <div
      v-if="hasWatchers"
      class="watchers hover-ticket-nav-item flex cursor-pointer items-center rounded-r-full px-1"
      style="height: 36px"
    >
      <span class="nav-item dropdown flex-1">
        <span data-toggle="dropdown" aria-expanded="false">
          <transition-group name="slide-fade">
            <span v-for="(watcher, index) in ticket.watchers" :key="watcher.id" class="whitespace-nowrap">
              <span v-if="index < 6">
                <avatar
                  style="margin-left: -15px"
                  width="30"
                  :color="watcher.color"
                  :abbr="watcher.abbr"
                  :image="watcher.profile_image"
                ></avatar>
              </span>
              <span v-if="index === 6">
                <avatar
                  style="margin-left: -15px"
                  width="30"
                  color="#bac2cb"
                  :abbr="'+' + (ticket.watchers.length - 6)"
                ></avatar>
              </span>
            </span>
          </transition-group>
        </span>
        <div role="menu" class="dropdown-menu text-color cursor-pointer">
          <div
            v-for="watcher in ticket.watchers"
            :key="watcher.id"
            class="dropdown-item text-ellipsis"
            style="max-width: 14rem"
          >
            <avatar width="30" :color="watcher.color" :abbr="watcher.abbr" :image="watcher.profile_image"></avatar>
            <span class="ml-2">{{ watcher.full_name }}</span>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<style>
.watchers {
  color: #b8c2cc;
}
.no-watchers {
  margin-right: -10px;
}
.watchers_active {
  color: #919191;
}
.watchers_active .material-icons {
  color: #14b29f;
}
.watchers .dropdown-item {
  background-color: inherit;
}
.has-watchers {
}
.watchers .dropdown-menu {
  margin-top: 8px;
  margin-left: -20px;
}
</style>
