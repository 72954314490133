<template>
  <div class="custom-fields-edit h-full overflow-auto bg-white">
    <div class="container">
      <div class="flex max-w-lg flex-col px-4 py-12">
        <div class="pointer" @click="goBack">
          <i class="far fa-arrow-left mr-2"></i>
          <span class="text-base font-semibold leading-none">{{ $t('custom_fields.back_to_overview') }}</span>
        </div>
        <span class="mt-4 text-4xl font-bold leading-10">{{ $t('custom_fields.create_custom_field') }}</span>
        <span class="mt-12 text-lg font-semibold leading-5">{{ $t('custom_fields.title') }}</span>
        <span class="mb-2 text-sm font-medium leading-6 text-grey-700">
          {{ $t('custom_fields.title_description') }}
        </span>
        <atomic-input
          v-model="record.title"
          size="lg"
          :placeholder="$t('custom_fields.title_placeholder')"
          :required="true"
        />
        <span class="mb-3 mt-12 text-lg font-semibold leading-5" :class="{ 'opacity-50': editingCustomField }">
          {{ $t('custom_fields.category') }}
        </span>
        <span class="mb-2 text-sm font-medium leading-6 text-grey-700">
          {{ $t('custom_fields.category_description') }}
        </span>
        <t-multi-choice v-model="record.type" class="flex" child-class="w-1/4" :disabled="editingCustomField">
          <template #TICKET>
            <t-multi-choice-button
              class="mr-4"
              :title="$t('custom_fields.ticket')"
              :selected="record.type === 'TICKET'"
              :disabled="editingCustomField"
            />
          </template>
          <template #CONTACT>
            <t-multi-choice-button
              class="mr-4"
              :title="$t('custom_fields.contact')"
              :selected="record.type === 'CONTACT'"
              :disabled="editingCustomField"
            />
          </template>
          <template #PROFILE>
            <t-multi-choice-button
              class="mr-4"
              :title="$t('custom_fields.profile')"
              :selected="record.type === 'PROFILE'"
              :disabled="editingCustomField"
            />
          </template>
          <template #USER>
            <t-multi-choice-button
              :title="$t('custom_fields.user')"
              :selected="record.type === 'USER'"
              :disabled="editingCustomField"
            />
          </template>
        </t-multi-choice>
        <span v-if="shouldShowChannels" class="mb-3 mt-12 text-lg font-semibold leading-6">
          {{ $t('custom_fields.channels') }}
        </span>
        <span v-if="shouldShowChannels" class="mb-2 text-sm font-medium leading-6 text-grey-700">
          {{ $t('custom_fields.channels_description') }}
        </span>
        <t-multi-select
          v-if="shouldShowChannels"
          v-model="record.channels"
          :use-icon="true"
          placeholder=""
          size="md"
          container-size="md"
          variant="sky"
          :height="300"
          :has-avatar="false"
          :data="channels"
          class="z-20"
        >
          <template v-if="!record.channels.length" #all-channels>
            <t-multi-select-tag
              :item="{ color: '', abbr: '', name: 'All channels' }"
              variant="sky"
              :has-avatar="false"
              :is-closable="false"
              size="md"
              :is-empty-tag="true"
            />
          </template>
        </t-multi-select>
        <span class="mb-3 mt-12 text-lg font-semibold leading-5">{{ $t('custom_fields.field_type') }}</span>
        <span class="mb-2 text-sm font-medium leading-6 text-grey-700">
          {{ $t('custom_fields.field_type_description') }}
        </span>
        <t-dropdown
          v-model="record.field_type"
          :items="record.type !== USER ? fields : fieldsUser"
          value-index="name"
          text-index="name"
          :placeholder="$t('custom_fields.select_option')"
          :light-version="true"
          :disabled="editingCustomField"
        ></t-dropdown>
        <div v-if="record.field_type" class="mt-4 border-l border-leaf-400 pb-4 pl-8 pt-4">
          <div v-if="shouldShowPlaceholderText">
            <span class="text-lg font-semibold leading-6">{{ $t('custom_fields.placeholder') }}</span>
            <br />
            <span class="text-sm font-medium leading-6 text-grey-700">
              {{ $t('custom_fields.changing_the_input') }}
            </span>
            <atomic-input
              v-if="record.field_type === 'Text'"
              v-model="record.placeholder"
              size="lg"
              class="mt-6"
              :placeholder="$t('custom_fields.input')"
              :required="true"
              :disabled="editingCustomField"
            />
            <div v-if="record.field_type === 'Checkbox'" class="mt-6 flex items-center">
              <input type="checkbox" :value="false" disabled class="mr-4" />
              <atomic-input
                v-model="record.placeholder"
                size="lg"
                :placeholder="$t('custom_fields.input')"
                :required="true"
                :disabled="editingCustomField"
              />
            </div>
            <atomic-input
              v-if="record.field_type === 'Hyperlink'"
              v-model="record.placeholder"
              size="lg"
              class="mt-6"
              placeholder="www."
              :required="true"
              prepend-icon="custom-fields/link-icon"
              :disabled="editingCustomField"
            />
            <atomic-input
              v-if="record.field_type === 'Number'"
              v-model="record.placeholder"
              size="lg"
              class="mt-6"
              :placeholder="0"
              :required="true"
              :disabled="editingCustomField"
            />
          </div>
          <div v-if="record.field_type === 'Dropdown'">
            <atomic-input
              v-model="record.placeholder"
              size="lg"
              class="mb-6"
              :placeholder="$t('custom_fields.select_option')"
              :required="true"
              :disabled="editingCustomField"
            />
            <span class="ml-8 text-lg font-semibold leading-6">{{ $t('custom_fields.options') }}</span>
            <br />
            <span class="ml-8 text-sm font-medium leading-6 text-grey-700">
              {{ $t('custom_fields.these_fields') }}
            </span>
            <t-draggable
              v-model="record.items"
              handle=".handle"
              class="draggable-container"
              @start="drag = true"
              @end="drag = false"
            >
              <div v-for="(item, index) in record.items">
                <div class="my-6 flex items-center">
                  <span class="mr-4 text-lg leading-5">{{ index + 1 }}</span>
                  <atomic-input
                    v-model="item.title"
                    size="lg"
                    :placeholder="$t('custom_fields.option')"
                    :required="true"
                    :prepend-icon="'action_icons/icon-drag'"
                    :draggable="true"
                  />
                  <i
                    class="fal fa-times ml-3 p-1"
                    title="Erase item"
                    :class="{ pointer: record.items.length !== 1 }"
                    @click="deleteItem(index)"
                  ></i>
                </div>
              </div>
            </t-draggable>
            <atomic-button size="md" btn-style="secondary ml-12" @click="addItem()">
              <i class="fal fa-plus"></i>
              {{ $t('custom_fields.add_option') }}
            </atomic-button>
          </div>
          <atomic-input
            v-if="record.field_type === 'Date'"
            v-model="record.placeholder"
            size="lg"
            class="mt-6"
            :placeholder="$t('custom_fields.input')"
            :required="true"
            prepend-icon="custom-fields/date-icon"
            :disabled="editingCustomField"
          />
        </div>
        <div
          v-if="record.type !== USER"
          class="mt-12 flex flex-row justify-between border-b border-grey-300 pb-2"
          :class="{ 'opacity-50': editingCustomField }"
        >
          <div>
            <span class="text-base font-semibold leading-none">{{ $t('custom_fields.required') }}</span>
            <br />
            <span class="mb-2 inline-block text-sm font-medium leading-6 text-grey-700">
              {{ $t('custom_fields.required_description') }}
            </span>
          </div>
          <atomic-toggle
            v-model="record.is_required"
            :small-version="true"
            :gray-version="true"
            :disabled="editingCustomField"
          ></atomic-toggle>
        </div>
        <div class="flex flex-col">
          <template v-if="editingCustomField">
            <span class="flex flex-col" v-html="$t('custom_fields.identifier_cannot_be_edited')" />
          </template>
          <template v-else>
            <span class="flex flex-col" v-html="$t('custom_fields.identifier_auto_generated')" />
          </template>
          <atomic-input
            v-model="record.identifier"
            size="lg"
            class="mt-3"
            :required="true"
            color="grey-100"
            :disabled="editingCustomField"
            @input="identifierIsDirty = true"
          />
        </div>
        <div v-if="!editingCustomField" class="flex justify-end pt-16">
          <atomic-button size="lg" btn-style="secondary" class="mr-2" @click="goBack">
            {{ $t('custom_fields.cancel') }}
          </atomic-button>
          <atomic-button size="lg" :disabled="formIncomplete" @click="save">
            {{ $t('custom_fields.create_custom_field') }}
          </atomic-button>
        </div>
      </div>
      <transition name="slide-fade-down">
        <div v-if="editingCustomField" class="max-w-lg lg:block">
          <div>
            <t-action-popup
              :dirty="recordIsDirty ? true : undefined"
              :saving="saving"
              :delete-version="true"
              :text="$t('general.action_popup_you_have_unsaved_changes')"
              :discard-text="$t('general.action_popup_discard')"
              :save-text="$t('general.action_popup_save_changes')"
              @save="save"
              @reset="revertDirtyChanges"
              @delete="deleteCustomField"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script type="text/babel">
import { capitalize, lowerCase } from 'lodash';
import { mapActions } from 'vuex';

import { CHANNEL_TYPE, CUSTOM_FIELD, CUSTOM_FIELD_TYPE } from '@/Configs/Constants';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import TDraggable from '@/UIKit/Utils/TDraggable';
import { ChannelsIconsMapper } from '@/util/ChannelsUtils';
import { slugify } from '@/util/slugify.js';

import {
  getChannels as apiGetChannels,
  getCustomField as apiGetCustomField,
  storeCustomField as apiStoreCustomField,
  updateCustomField as apiUpdateCustomField,
} from '../Api';

export default {
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  components: {
    TDraggable,
  },

  computed: {
    formIncomplete() {
      return !this.isCompleteField(this.record);
    },
    shouldShowPlaceholderText() {
      const { TEXT, CHECKBOX, HYPERLINK, NUMBER, DROPDOWN } = CUSTOM_FIELD_TYPE;
      return (
        this.record.field_type === TEXT ||
        this.record.field_type === CHECKBOX ||
        this.record.field_type === HYPERLINK ||
        this.record.field_type === NUMBER ||
        this.record.field_type === DROPDOWN
      );
    },
    editingCustomField() {
      return this.action === 'edit';
    },
    shouldShowChannels() {
      const { TICKET, CONTACT } = CUSTOM_FIELD;
      return this.record.type === TICKET || this.record.type === CONTACT;
    },
  },

  data() {
    return {
      channels: [],
      fields: [
        { name: 'Text', id: 1, icon: `${this.$root.assetsURL}img/custom-fields/text-icon.svg` },
        { name: 'Dropdown', id: 2, icon: `${this.$root.assetsURL}img/custom-fields/dropdown-icon.svg` },
        { name: 'Checkbox', id: 3, icon: `${this.$root.assetsURL}img/custom-fields/checkbox-icon.svg` },
        { name: 'Number', id: 4, icon: `${this.$root.assetsURL}img/custom-fields/number-icon.svg` },
        { name: 'Hyperlink', id: 5, icon: `${this.$root.assetsURL}img/custom-fields/link-icon.svg` },
        { name: 'Date', id: 6, icon: `${this.$root.assetsURL}img/custom-fields/date-icon.svg` },
      ],
      fieldsUser: [{ name: 'Text', id: 1, icon: `${this.$root.assetsURL}img/custom-fields/text-icon.svg` }],
      record: {
        title: '',
        type: '',
        channels: [],
        field_type: '',
        is_required: false,
        placeholder: '',
        identifier: '',
        items: [{ title: '' }],
      },
      slugify: slugify,
      identifierIsDirty: false,
      saving: false,
    };
  },

  mounted() {
    if (this.action === 'edit') {
      apiGetCustomField(this.$route.params.id).then((result) => {
        this.record = result.data;
        this.record.field_type = capitalize(result.data.field_type);
        this.record.channels = this.computedChannels(this.record.channels);
        this.initWarning();
      });
    }
    apiGetChannels().then((result) => {
      this.channels = this.computedChannels(
        result.data.data.filter((channel) => channel.type !== CHANNEL_TYPE.HELP_CENTER)
      );
      this.loaded = true;
    });
  },

  methods: {
    ...mapActions({
      deleteCustomFieldStore: 'customFields/deleteCustomField',
      loadCustomFields: 'customFields/loadCustomFields',
    }),
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeCustomField();
      } else {
        this.updateCustomField();
      }
    },

    transformDataForBackend(custom_field) {
      let apiItems = [];
      if (custom_field.field_type === CUSTOM_FIELD.DROPDOWN) {
        apiItems = this.record.items;
      }
      const channelIds = [];
      custom_field.channels.forEach((channel) => {
        channelIds.push(channel.id);
      });
      return {
        ...this.record,
        field_type: this.record.field_type.toUpperCase(),
        items: apiItems,
        channels: channelIds,
      };
    },

    storeCustomField() {
      apiStoreCustomField(this.transformDataForBackend(this.record))
        .then((res) => {
          this.saving = false;
          this.$nextTick(() => router.push('/admin/custom_fields/' + res.data.id));
          this.record.sort_order = res.data.sort_order;
          this.record.id = res.data.id;
          this.initWarning();
          this.flashSuccess(this.$t('custom_fields.flash_create_custom_field_successfully'));
          this.loadCustomFields();
        })
        .catch((e) => {
          console.error('Error on save Custom Field: ', e);
        })
        .finally(() => {
          this.saving = false;
        });
    },

    updateCustomField() {
      apiUpdateCustomField(this.transformDataForBackend(this.record))
        .then(() => {
          this.saving = false;
          this.recordIsDirty = false;
          this.initWarning();
          this.flashSuccess(this.$t('custom_fields.flash_update_custom_field_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    deleteCustomField() {
      this.deleteCustomFieldStore({ id: this.record.id }).then(() => {
        this.goBack();
      });
    },
    isCompleteField(field) {
      return !(!field.title || !field.field_type || !field.type || !field.identifier);
    },
    goBack() {
      const typeRoute =
        this.record?.type !== CUSTOM_FIELD.TICKET && this.action !== 'create' ? lowerCase(this.record.type) + 's' : '';

      this.loadCustomFields();
      this.$router.push('/admin/custom_fields/' + typeRoute);
    },
    mapChannels(list) {
      const channels_svg = ChannelsIconsMapper();
      return list && list.map((item) => ({ ...item, name: item.text, icon: channels_svg[item.type] }));
    },
    deleteItem(i) {
      if (this.record.items?.length === 1) {
        return;
      } else {
        this.record.items?.splice(i, 1);
      }
    },
    addItem() {
      this.record?.items?.push({ title: '' });
    },
    computedChannels(channels) {
      return Object.keys(channels) ? this.mapChannels(channels) : false;
    },
  },

  watch: {
    'record.title': function (val) {
      if (!this.identifierIsDirty && this.action !== 'edit') {
        this.record.identifier = slugify(val);
      }
    },
    'record.type': function () {
      const { TEXT, USER } = CUSTOM_FIELD_TYPE;
      if (this.record.field_type && this.record.type === USER) {
        this.record.field_type = TEXT;
      }
    },
  },

  created() {
    this.USER = CUSTOM_FIELD.USER;
    this.record.type = this.$route.params.type || '';
  },
};
</script>

<style lang="scss" src="./CustomFieldsEdit.scss" />
