<template>
  <div class="wab-landing max-h-screen w-full overflow-y-auto overflow-x-hidden pb-12">
    <div class="ml-6 mr-6 flex sm:justify-center xl:ml-0 xl:mr-0">
      <section class="wab-landing-content mt-18 flex-shrink-0 xs3:w-full md:w-1/2">
        <header class="wab-landing-header">
          <t-feature-icon :icon="FEATURE_ICON.BROADCASTING" class="mb-8" />
          <h1 class="t-text-desktop-h1 font-bold">{{ $t('broadcast.landing_page_broadcast_title') }}</h1>
          <t-badge
            v-if="requiresAddon"
            variant="upgrade"
            text="Requires add-on"
            class="cursor-defailt mb-8 mt-3 w-fit"
            data-test="wab-broadcast-add-on-badge"
          >
            <template #icon><arrow-top-circle-linear size="1rem" /></template>
          </t-badge>
          <t-inline-banner v-if="requiresSmsAndWhatsappUpgrade" type="warning" class="my-8 w-max">
            <div>
              <ul class="m-0">
                <li class="t-text-desktop-body-sm pr-4 text-sun-900">
                  {{ $t('broadcast.landing_page_sms_available_on_scale') }}
                </li>
              </ul>
            </div>
          </t-inline-banner>
          <t-inline-banner v-else-if="requiresWhatsappUpgrade" type="warning" class="my-8 w-max">
            <div>
              <ul class="m-0">
                <li class="t-text-desktop-body-sm pr-4 text-sun-900">
                  {{ $t('broadcast.landing_page_whatsapp_available_on_enterprise') }}
                </li>
              </ul>
            </div>
          </t-inline-banner>
        </header>
        <main>
          <t-unordered-list :items="featuresList" />
          <div class="actions mt-8 flex">
            <t-button
              v-if="hasCompanyProfilePermission && requiresAddon"
              class="mr-4 flex items-center justify-center"
              data-test="unlock-broadcast-btn"
              @click="redirectToPage('/admin/company_profile/subscription')"
            >
              <arrow-top-circle-linear size="1.25rem" class="mr-1" />
              {{ $t('broadcast.unlock_broadcast') }}
            </t-button>
            <t-button
              v-if="(requiresWhatsappUpgrade && hasSmsEnabled) || isEntitledToSmsAndWhatsapp"
              class="mr-4"
              data-test="create-broadcast-btn"
              @click="redirectToPage('/broadcasting/new')"
            >
              {{ $t('broadcast.landing_page_new_broadcast') }}
            </t-button>
            <t-button
              v-if="hasCompanyProfilePermission && requiresSmsAndWhatsappUpgrade"
              class="mr-4 flex items-center justify-center"
              data-test="upgrade-broadcast-btn"
              @click="redirectToPage(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)"
            >
              <arrow-top-circle-linear size="1.25rem" class="mr-2" />
              {{ $t('broadcast.landing_page_upgrade_your_plan') }}
            </t-button>
            <t-button
              v-if="requiresWhatsappUpgrade && !hasSmsEnabled"
              class="mr-4 flex items-center justify-center"
              href="mailto:support@trengo.com"
              data-test="contact-support-btn"
            >
              <lifebuoy-linear size="1.25rem" class="mr-2" />
              {{ $t('broadcast.landing_page_contact_support') }}
            </t-button>
            <t-button
              href="https://help.trengo.com/en/articles/63677-sending-sms-to-a-group-of-people-with-trengo"
              target="_blank"
              btn-style="secondary"
              data-test="learn-more-btn"
            >
              {{ $t('broadcast.landing_page_learn_more') }}
            </t-button>
          </div>
          <div
            v-if="(requiresWhatsappUpgrade || isEntitledToSmsAndWhatsapp) && hasSmsEnabled"
            class="t-text-desktop-paragraph-sm mt-12 text-grey-600"
          >
            {{ $t('broadcast.landing_page_you_still_have_access_to') }}
            <router-link to="/sms" class="underline">
              {{ $t('broadcast.landing_page_previous_sms_broadcasts') }}
            </router-link>
          </div>
          <div v-if="!hasCompanyProfilePermission && (requiresAddon || requiresSmsAndWhatsappUpgrade)" class="mt-3">
            <p class="t-text-sm text-grey-700">{{ $t('broadcast.ask_your_administrator_to_unlock_broadcasting') }}</p>
          </div>
          <div v-if="requiresWhatsappUpgrade && !hasSmsEnabled" class="t-text-desktop-paragraph-sm mt-3">
            <span class="text-grey-600" data-test="contact-support-text">
              {{ $t('broadcast.landing_page_please_contact_support') }}
            </span>
          </div>
          <div v-if="isEntitledToSmsAndWhatsapp && !hasSmsEnabled" class="t-text-desktop-paragraph-sm mt-3">
            <span
              class="text-grey-600"
              data-test="contact-support-text-with-link"
              v-html="$t('broadcast.landing_page_please_contact_support_with_link')"
            />
          </div>
        </main>
      </section>
      <div class="wab-landing-preview mt-24 flex w-full items-center xs3:w-0 md:w-1/2">
        <img :src="`${$root.assetsURL}img/wab-broadcasting/broadcast-landing.svg`" class="w-536px" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear, LifebuoyLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import TFeatureIcon from '@/components/common/TFeatureIcon/TFeatureIcon.vue';
import { FEATURE, FEATURE_ICON, ENTITLEMENT_STATUS, PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useEntitlementsStore } from '@/store/pinia';

export default defineComponent({
  name: 'WabBroadcastingLanding',
  components: {
    ArrowTopCircleLinear,
    LifebuoyLinear,
    TFeatureIcon,
  },
  data() {
    return {
      FEATURE_ICON,
      SETTINGS_URL,
      featuresList: [
        this.$t('broadcast.reach_thousands_of_customers_in_a_few_clicks'),
        this.$t('broadcast.landing_page_send_whatsapp_template_messages'),
        this.$t('broadcast.landing_page_proactively_engage_with_customers'),
      ],
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    hasSmsEnabled() {
      return typeof window.AGENCY.enable_bulk_sms == 'undefined' || window.AGENCY.enable_bulk_sms == 1;
    },
    requiresSmsAndWhatsappUpgrade() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE
      );
    },
    requiresWhatsappUpgrade() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.ENTITLED &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE
      );
    },
    isEntitledToSmsAndWhatsapp() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.ENTITLED &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.ENTITLED
      );
    },
    requiresAddon() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.REQUIRES_ADDON &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.REQUIRES_ADDON
      );
    },
    hasCompanyProfilePermission() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
  },
  methods: {
    redirectToPage(link) {
      return this.$router.push(link);
    },
  },
});
</script>

<style lang="scss" scoped src="./WabBroadcastingLanding.scss" />
