<template>
  <div class="hidden h-full flex-1 select-none flex-col items-center justify-center text-center lg:flex">
    <img :src="`${$root.assetsURL}img/no-tickets-empty-state.svg`" alt="Add your first channel to start exploring" />

    <div v-if="channelsCreatedByUser" class="m-6">
      <p class="t-text-h4 m-0 mt-4 text-grey-800">{{ $t('tickets.engage_with_customers') }}</p>
      <p class="t-text-md m-0 mt-2 text-grey-600">{{ $t('tickets.no_ticket_selected') }}</p>
      <t-button
        v-if="$authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
        type="button"
        class="hidden-md-down selector-create-conversation m-auto mt-6 flex items-center"
        data-test="start-conversation-button-placeholder"
        @click="createConversation()"
      >
        <edit-fill class="mr-1" size="16" />
        {{ $t('tickets.start_conversation') }}
      </t-button>
      <add-channel-modal v-model="openAddChannel" />
    </div>
    <div v-else class="m-6">
      <p class="t-text-h4 m-0 mt-4 text-grey-800">{{ $t('tickets.welcome_to_trengo') }}</p>
      <p class="t-text-md m-0 mt-2 text-grey-600">{{ $t('tickets.welcome_to_trengo_description') }}</p>
      <t-button
        v-if="$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)"
        class="m-auto mt-6 inline-flex items-center"
        @click="toggleAddChannelModal"
      >
        <plus-linear size="1rem" class="mr-1" />
        {{ $t('channels.create') }}
      </t-button>
      <add-channel-modal v-model="openAddChannel" />
    </div>
  </div>
</template>

<script type="text/babel">
import { EditFill, PlusLinear } from '@trengo/trengo-icons';

import AddChannelModal from '@/components/OverviewPage/components/AddChannel/AddChannelModal';
import { PERMISSION } from '@/Configs/Constants';
export default {
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  components: {
    AddChannelModal,
    PlusLinear,
    EditFill,
  },
  computed: {
    channelsCreatedByUser() {
      return this.$root.allChannels?.filter((channel) => channel.created_by_user)?.length;
    },
  },
  data() {
    return {
      openAddChannel: false,
      PERMISSION,
    };
  },
  methods: {
    toggleAddChannelModal() {
      this.openAddChannel = !this.openAddChannel;
    },
    createConversation() {
      eventBus.$emit('modals.create-ticket.open');
    },
  },
};
</script>
