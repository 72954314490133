<template>
  <label class="ui-switch success m-t-xs m-r" :class="{ disabled: disabled, 'cursor-not-allowed': disabled }">
    <input v-model="model" type="checkbox" :disabled="disabled" />
    <i></i>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<style scoped></style>
