<template>
  <t-modal
    :close-on-backdrop-click="true"
    :close-icon="true"
    variant="narrow"
    :is-open="isOpen"
    data-test="integration-hub-upgrade-modal"
    @close="$emit('close')"
  >
    <template #header>
      <div class="flex items-center gap-2">
        <span class="t-text-h3">{{ $t('integration_hub.upgrade_modal_title') }}</span>
        <t-badge variant="upgrade" :text="'Boost'">
          <template #icon>
            <arrow-top-circle-linear size="1rem" />
          </template>
        </t-badge>
      </div>
    </template>

    <div class="flex flex-col">
      <img class="" :src="`${$root.assetsURL}images/integration-hub/basic-plan-banner.png`" alt="" />
      <p class="text-md my-6 text-grey-700">
        {{ $t('integration_hub.upgrade_modal_description') }}
      </p>
      <p class="text-sm text-grey-600">
        {{ upgradeModalInstructions }}
      </p>
    </div>

    <template #footer>
      <t-button btn-style="secondary" @click="$emit('close')">
        {{ isFirstTime ? $t('integration_hub.explore_integrations') : $t('general.close') }}
      </t-button>
      <t-button v-if="hasUpgradePermissions" @click="handleUpgrade">
        {{ $t('entitlement.upgrade_plan_cta') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import { FEATURE } from '@/Configs/Constants/Feature';

import type { Integration } from '@/store/types/integrations';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationHubUpgradeModal',

  components: { ArrowTopCircleLinear },

  props: {
    integrations: {
      type: Array as PropType<Integration[]>,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isFirstTime: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      FEATURE,
    };
  },

  methods: {
    handleUpgrade() {
      this.$emit('close');
      this.$nextTick(() => {
        this.$router.push({ name: 'subscription' });
      });
    },
  },

  computed: {
    hasUpgradePermissions() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },

    upgradeModalInstructions() {
      return this.hasUpgradePermissions
        ? this.$t('integration_hub.upgrade_modal_instructions_admin')
        : this.$t('integration_hub.upgrade_modal_instructions_supervisor');
    },
  },
});
</script>
