<template>
  <span
    v-if="countProxy"
    :class="{ 'bg-red-light-important': threadIsUser || mentionsCountProxy > 0 }"
    class="label mb-0 ml-1"
    style="line-height: 12px; padding: 2px 3px; min-width: 16px"
  >
    <template v-if="countProxy >= 100 || mentionsCountProxy >= 100">99+</template>
    <template v-else>
      {{ mentionsCountProxy !== 0 ? mentionsCountProxy : countProxy }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'UnreadIndicator',
  props: ['count', 'mentionsCount', 'threadIsUser'],
  data() {
    return {
      countProxy: this.count,
      mentionsCountProxy: this.mentionsCount,
    };
  },
  watch: {
    // delay unread indicator increment. otherwise it will be visible for a few ms on the active thread
    count(val) {
      if (val > this.countProxy) {
        setTimeout(() => {
          this.countProxy = this.count;
        }, 1000);
      } else {
        this.countProxy = val;
      }
    },
    mentionsCount(val) {
      if (val > this.mentionsCountProxy) {
        setTimeout(() => {
          this.mentionsCountProxy = this.mentionsCount;
        }, 1000);
      } else {
        this.mentionsCountProxy = val;
      }
    },
  },
};
</script>
