<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 23.5C18.503 23.5 18.1 23.0971 18.1 22.6V22.3799C18.1 21.9345 17.5615 21.7114 17.2465 22.0264L17.0909 22.182C16.7394 22.5334 16.1695 22.5334 15.8181 22.182C15.4666 21.8305 15.4666 21.2607 15.8181 20.9092L15.9737 20.7536C16.2887 20.4386 16.0656 19.9 15.6201 19.9H15.4C14.9029 19.9 14.5 19.4971 14.5 19C14.5 18.503 14.9029 18.1 15.4 18.1H15.6201C16.0656 18.1 16.2887 17.5614 15.9737 17.2465L15.818 17.0908C15.4666 16.7393 15.4666 16.1695 15.818 15.818C16.1695 15.4665 16.7394 15.4665 17.0908 15.818L17.2465 15.9737C17.5615 16.2887 18.1 16.0656 18.1 15.6201V15.4C18.1 14.9029 18.503 14.5 19 14.5C19.4971 14.5 19.9 14.9029 19.9 15.4V15.6201C19.9 16.0656 20.4386 16.2887 20.7536 15.9737L20.9092 15.818C21.2607 15.4665 21.8306 15.4665 22.182 15.818C22.5335 16.1695 22.5335 16.7393 22.182 17.0908L22.0264 17.2465C21.7114 17.5614 21.9345 18.1 22.3799 18.1H22.6C23.0971 18.1 23.5 18.503 23.5 19C23.5 19.4971 23.0971 19.9 22.6 19.9H22.3799C21.9345 19.9 21.7114 20.4386 22.0264 20.7536L22.182 20.9092C22.5335 21.2607 22.5335 21.8305 22.182 22.182C21.8305 22.5335 21.2607 22.5335 20.9092 22.182L20.7536 22.0264C20.4386 21.7114 19.9 21.9345 19.9 22.3799V22.6C19.9 23.0971 19.4971 23.5 19 23.5ZM19 20.8947C20.0464 20.8947 20.8947 20.0464 20.8947 19C20.8947 17.9536 20.0464 17.1053 19 17.1053C17.9535 17.1053 17.1052 17.9536 17.1052 19C17.1052 20.0464 17.9535 20.8947 19 20.8947Z"
        class="svg-fill"
      />
      <circle cx="12" cy="15.25" r="1" class="svg-fill" />
      <path
        d="M9.25 9.75C9.25 10.1642 9.58579 10.5 10 10.5C10.4142 10.5 10.75 10.1642 10.75 9.75H9.25ZM13.0928 10.817L13.4903 11.453L13.4903 11.453L13.0928 10.817ZM12.0769 11.4519L11.6794 10.8159L11.6794 10.8159L12.0769 11.4519ZM11.25 12.5C11.25 12.9142 11.5858 13.25 12 13.25C12.4142 13.25 12.75 12.9142 12.75 12.5H11.25ZM10.75 9.75V9.25H9.25V9.75H10.75ZM12 8H12.0698V6.5H12V8ZM12.6953 10.181L11.6794 10.8159L12.4744 12.0879L13.4903 11.453L12.6953 10.181ZM11.25 11.5907V12.5H12.75V11.5907H11.25ZM12.0698 8C12.7216 8 13.25 8.52839 13.25 9.18019H14.75C14.75 7.69996 13.55 6.5 12.0698 6.5V8ZM13.25 9.18019C13.25 9.58711 13.0404 9.96533 12.6953 10.181L13.4903 11.453C14.2739 10.9632 14.75 10.1043 14.75 9.18019H13.25ZM11.6794 10.8159C11.4123 10.9829 11.25 11.2757 11.25 11.5907H12.75C12.75 11.7929 12.6459 11.9808 12.4744 12.0879L11.6794 10.8159ZM10.75 9.25C10.75 8.55964 11.3096 8 12 8V6.5C10.4812 6.5 9.25 7.73122 9.25 9.25H10.75Z"
        class="svg-fill"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4921 21.3835C13.006 21.4602 12.5076 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 12.5076 21.4602 13.006 21.3835 13.4921C21.8789 13.7067 22.3396 13.9862 22.7549 14.3199C22.9155 13.572 23 12.7959 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C12.7959 23 13.572 22.9155 14.3199 22.7549C13.9862 22.3396 13.7067 21.8789 13.4921 21.3835Z"
        class="svg-fill"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.7549 14.3199C22.9155 13.572 23 12.7959 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C12.7959 23 13.572 22.9155 14.3199 22.7549C13.4941 21.727 13 20.4212 13 19C13 15.6863 15.6863 13 19 13C20.4212 13 21.727 13.4941 22.7549 14.3199ZM12 16.25C12.5523 16.25 13 15.8023 13 15.25C13 14.6977 12.5523 14.25 12 14.25C11.4477 14.25 11 14.6977 11 15.25C11 15.8023 11.4477 16.25 12 16.25ZM12 8C11.3096 8 10.75 8.55964 10.75 9.25V9.75C10.75 10.1642 10.4142 10.5 10 10.5C9.58579 10.5 9.25 10.1642 9.25 9.75V9.25C9.25 7.73122 10.4812 6.5 12 6.5H12.0698C13.55 6.5 14.75 7.69996 14.75 9.18019C14.75 10.1043 14.2739 10.9632 13.4903 11.453L12.75 11.9157V12.5C12.75 12.9142 12.4142 13.25 12 13.25C11.5858 13.25 11.25 12.9142 11.25 12.5V11.5907C11.25 11.2757 11.4123 10.9829 11.6794 10.8159L12.6953 10.181C13.0404 9.96533 13.25 9.58711 13.25 9.18019C13.25 8.52839 12.7216 8 12.0698 8H12Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 23.5C18.503 23.5 18.1 23.0971 18.1 22.6V22.3799C18.1 21.9345 17.5615 21.7114 17.2465 22.0264L17.0909 22.182C16.7394 22.5334 16.1695 22.5334 15.8181 22.182C15.4666 21.8305 15.4666 21.2607 15.8181 20.9092L15.9737 20.7536C16.2887 20.4386 16.0656 19.9 15.6201 19.9H15.4C14.9029 19.9 14.5 19.4971 14.5 19C14.5 18.503 14.9029 18.1 15.4 18.1H15.6201C16.0656 18.1 16.2887 17.5614 15.9737 17.2465L15.818 17.0908C15.4666 16.7393 15.4666 16.1695 15.818 15.818C16.1695 15.4665 16.7394 15.4665 17.0908 15.818L17.2465 15.9737C17.5615 16.2887 18.1 16.0656 18.1 15.6201V15.4C18.1 14.9029 18.503 14.5 19 14.5C19.4971 14.5 19.9 14.9029 19.9 15.4V15.6201C19.9 16.0656 20.4386 16.2887 20.7536 15.9737L20.9092 15.818C21.2607 15.4665 21.8306 15.4665 22.182 15.818C22.5335 16.1695 22.5335 16.7393 22.182 17.0908L22.0264 17.2465C21.7114 17.5614 21.9345 18.1 22.3799 18.1H22.6C23.0971 18.1 23.5 18.503 23.5 19C23.5 19.4971 23.0971 19.9 22.6 19.9H22.3799C21.9345 19.9 21.7114 20.4386 22.0264 20.7536L22.182 20.9092C22.5335 21.2607 22.5335 21.8305 22.182 22.182C21.8305 22.5335 21.2607 22.5335 20.9092 22.182L20.7536 22.0264C20.4386 21.7114 19.9 21.9345 19.9 22.3799V22.6C19.9 23.0971 19.4971 23.5 19 23.5ZM19 20.8947C20.0464 20.8947 20.8947 20.0464 20.8947 19C20.8947 17.9536 20.0464 17.1053 19 17.1053C17.9535 17.1053 17.1052 17.9536 17.1052 19C17.1052 20.0464 17.9535 20.8947 19 20.8947Z"
          fill="white"
        />
      </svg>
    </span>
  </span>
</template>
