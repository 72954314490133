<template>
  <div class="m-0 p-0">
    <div
      class="tt-search-box border-radius mb-3 flex items-center rounded-lg bg-grey-200 px-3 py-2"
      @click="$refs.search.focus()"
    >
      <div class="flex items-center">
        <i class="material-icons mr-3 rounded-lg text-grey-600" style="font-size: 23px">search</i>
      </div>
      <form class="w-100 h-full" @submit.prevent="openModal">
        <input
          ref="search"
          type="text"
          :placeholder="$t('general.searching')"
          :value="searchQuery"
          class="w-100 h-full bg-grey-200 text-grey-600"
          style="line-height: normal"
          @input="(e) => (searchQuery = e.target.value)"
        />
      </form>
    </div>

    <template v-if="thread.attachments.length">
      <div
        v-for="(file, index) in thread.attachments"
        class="search-item"
        :class="{ 'b-t b-t-2x': index > 0 }"
        role="button"
        @click="attachmentClicked(file)"
      >
        <div class="overflow-hidden p-0">
          <div class="flex py-2 text-center" style="border-radius: 0.25rem">
            <img
              v-if="file.isImage() && file.getPreviewUrl()"
              class="b-a open-external rounded-lg"
              :src="file.getPreviewUrl()"
              :title="file.getName()"
              style="object-fit: cover; height: 45px; width: 45px"
            />
            <div
              v-else
              class="flex items-center justify-center rounded-lg bg-grey-200"
              style="min-width: 45px; height: 45px"
            >
              <i class="fa text-grey-800" style="font-size: 20px" :class="file.getIconClass()"></i>
            </div>

            <div class="ml-2 flex flex-col overflow-hidden" style="width: 100%">
              <div class="inline-flex" style="height: 20px; margin-bottom: 3px">
                <strong class="text-truncate mr-auto block text-right" style="height: 20px; line-height: 1.2">
                  {{ file.getName() }}
                </strong>
                <button
                  class="mb-1 ml-1 inline-flex items-center rounded-lg bg-grey-200"
                  style="height: 20px; padding: 0 5px"
                  @click.prevent.stop="downloadClicked(file)"
                >
                  <span class="mr-1 h-full font-bold text-grey-600" style="line-height: 1.2">
                    .{{ file.getExtension() }}
                  </span>
                  <i
                    class="fa fa-download text-grey-600"
                    :aria-label="'Download ' + file.getName()"
                    style="font-size: 14px"
                  ></i>
                </button>
              </div>
              <!-- File options -->
              <div class="text flex items-center justify-between text-grey-600" style="margin-top: 2px">
                <div class="sidebar-name text-ellipsis" style="font-size: 14px; line-height: 1">
                  {{ file.userId ? getUserById(file.userId).getDisplayName() : '' }}
                </div>
                <!--                            <div class="text-grey-500 text-ellipsis sidebar-date text-right" style="font-size:13px;line-height:1;">{{moment.utc(file.createdAt*1000).format('LL')}}</div>-->
                <datetime
                  :time="(file.createdAt * 1000).toString()"
                  :pretty="$root.prettyDates"
                  :allow-change="false"
                  class="sidebar-date text-ellipsis text-right text-grey-500"
                  style="font-size: 13px; line-height: 1"
                ></datetime>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="!thread.attachments.length">
      <div class="mt-4 pt-1 text-center">{{ $t('internal_chat.no_results_found') }}</div>
    </template>

    <div class="w-100 mt-2 inline-flex text-center">
      <button
        v-if="thread.attachmentsPage > 1"
        class="btn btn-sm b-a b-2x pointer items-center rounded text-grey-600"
        style="display: flex; line-height: 1; padding-top: 2px; padding-bottom: 2px; padding-left: calc(1.2em - 7px)"
        @click="loadNewerClicked"
      >
        <i class="material-icons md-20 valign-center">chevron_left</i>
        {{ $t('internal_chat.sidebar_shared_files_button_previous') }}
      </button>
      <button
        v-if="
          thread.attachments.length &&
          Math.ceil(thread.attachmentsTotal / perPage) !== thread.attachmentsPage &&
          thread.attachments.length >= perPage
        "
        class="btn btn-sm b-a b-2x pointer ml-auto items-center rounded text-grey-600"
        style="display: flex; line-height: 1; padding-top: 2px; padding-bottom: 2px; padding-right: calc(1.2em - 7px)"
        @click="loadOlderClicked"
      >
        {{ $t('internal_chat.sidebar_shared_files_button_next') }}
        <i class="material-icons md-20 valign-center">chevron_right</i>
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';

export default {
  name: 'ThreadAttachments',
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
  emits: ['input'],
  data() {
    return {
      perPage: 10,
      searchQuery: '',
      search: false,
    };
  },
  mounted() {
    eventBus.$on('CHAT@AFTER_ATTACHMENT_SENT', () => {
      this.thread.searchAttachments(this.searchQuery);
    });
  },
  beforeUnmount() {
    eventBus.$off('CHAT@AFTER_ATTACHMENT_SENT');
  },
  methods: {
    loadOlderClicked() {
      this.thread.loadOlderAttachments(this.searchQuery);
    },
    loadNewerClicked() {
      this.thread.loadNewerAttachments(this.searchQuery);
    },
    attachmentSearch: _.debounce(function () {
      this.thread.searchAttachments(this.searchQuery);
    }, 300),
    attachmentClicked(file) {
      window.open(file.getUrl());
    },
    downloadClicked(file) {
      window.open(file.getUrl());
    },
    openModal() {
      this.$emit('input', this.searchQuery);
      this.$refs.search.blur();
    },
  },
  computed: {
    moment: () => moment,
    ...mapGetters({
      getUserById: 'usersInternalChat/userById',
    }),
  },
  watch: {
    searchQuery() {
      if (this.search) {
        this.attachmentSearch();
      }
      this.search = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.tt-search-box {
  cursor: text;
  height: 40px;
  input::placeholder {
    color: theme('colors.grey-600');
    opacity: 0.8;
  }
}
.search-item {
  border-color: theme('colors.grey-200');
}
.sidebar-name {
  max-width: 90px;
}
.sidebar-date {
  max-width: 122px;
}
@media (max-width: 991px) {
  .sidebar-name {
    max-width: 100%;
  }
  .sidebar-date {
    max-width: none;
    min-width: 135px;
  }
}
</style>
