<template>
  <div
    class="absolute flex h-full w-full select-none items-center justify-center text-center"
    style="left: 0; top: 0; background: rgba(255, 255, 255, 0.8); z-index: 99999999999; pointer-events: none"
  >
    <i class="fa fa-folder-open text-4x text-muted mr-2 text-center"></i>
    <h1 class="text-center">{{ $t('internal_chat.overlay_drop_files') }}</h1>
  </div>
</template>

<script>
export default {
  name: 'DragOverOverlay',
};
</script>

<style scoped></style>
