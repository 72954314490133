<template>
  <i v-if="!avatarAsParent" class="material-icons material-icons-xs align-middle" :class="computedClasses">
    fiber_manual_record
  </i>
  <strong
    v-else-if="avatarAsParent"
    class="absolute rounded border border-white"
    style="width: 13px; height: 13px; bottom: -1px; right: -3px"
    :class="computedClasses"
  ></strong>
</template>

<script>
export default {
  name: 'OnlineStatus',
  props: {
    status: {
      default: '',
    },
    avatarAsParent: {
      default: false,
    },
  },
  computed: {
    computedClasses() {
      if (!this.avatarAsParent) {
        return {
          'text-green': this.status === 'online',
          'text-white online-status-border': this.status === 'away',
          'text-grey-600': this.status === 'offline',
        };
      } else if (this.avatarAsParent) {
        return {
          'bg-green': this.status === 'online',
          'bg-orange-light online-status-border-color': this.status === 'away',
          'bg-grey-600': this.status === 'offline',
        };
      }
    },
  },
};
</script>

<style scoped>
.online-status-border {
  text-shadow: -1px 0 theme('colors.red'), 0 1px theme('colors.red'), 1px 0 theme('colors.red'),
    0 -1px theme('colors.red');
}
.online-status-border-color {
  border-color: theme('colors.red');
}
</style>
