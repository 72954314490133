<template>
  <t-modal v-model="shouldOpenModal" :title="$t('email_settings.add_new_business_hours')" @close="closeModal">
    <div class="overflow-x-hidden">
      <first-step
        v-if="!isSecondStep && recordExists"
        :timezones="timezones"
        :record="record"
        :channels="businessHoursChannelsList"
        @change="handleChangeRecord"
      />
      <second-step v-if="isSecondStep && isLgOrXl && recordExists" :days="record.days" @change="handleChangeDays" />
      <second-step-mobile
        v-if="isSecondStep && !isLgOrXl && recordExists"
        :days="record.days"
        @change="handleChangeDays"
      />
    </div>
    <template #footer>
      <t-button btn-style="secondary" class="mr-2" @click="!isSecondStep ? closeModal() : goToStep(0)">
        <template v-if="isSecondStep">{{ $t('email_settings.back') }}</template>
        <template v-else>{{ $t('email_settings.cancel') }}</template>
      </t-button>
      <t-button @click="isSecondStep ? save() : goToStep(2)">
        <template v-if="isSecondStep">{{ $t('email_settings.add_business_hours') }}</template>
        <template v-else>
          {{ $t('email_settings.next') }}
          <i class="fal fa-arrow-right ml-3" />
        </template>
      </t-button>
    </template>
  </t-modal>
</template>

<script>
import { map } from 'lodash';
import { mapGetters } from 'vuex';

import Steps from '@/components/BusinessHours/components';
import { DEFAULT_DAYS } from '@/components/BusinessHours/Utils';
import Timezones from '@/components/Pages/Sections/Timezones';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import { request } from '@/util';
import breakpoints from '@/util/breakpoints';

export default {
  mixins: [RouterWarningOnEdit],
  data() {
    return {
      breakpoints,
      isSecondStep: false,
      timezones: Timezones,
      loaded: false,
      record: {},
      saving: false,
      defaultDays: DEFAULT_DAYS,
      createdBusinessHour: {},
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  components: {
    'first-step': Steps.FirstStep,
    'second-step': Steps.SecondStep,
    'second-step-mobile': Steps.SecondStepMobile,
  },
  mounted() {
    if (this.id) {
      request(`/api/v2/business_hours/${this.id}`, 'GET').then((result) => {
        this.record = result.data;
        if (!this.record.days || !this.record.days.length) {
          this.record.days = this.defaultDays;
        }
      });
    } else {
      this.record = {
        name: 'General',
        timezone: this.$root.user.timezone,
        description: '',
        days: DEFAULT_DAYS,
      };
    }
    this.fetchInitialData();
  },
  methods: {
    handleChangeRecord(record) {
      this.record = record;
    },
    handleChangeDays(days) {
      this.record.days = days;
    },
    closeModal() {
      const { id, name } = this.createdBusinessHour;
      const createdBusinessHour = id && name ? { id, name } : null;
      this.close({ createdBusinessHour });
    },
    fetchInitialData() {
      this.initWarning();
      this.$store.dispatch('channels/fetchChannels');
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (!this.id) {
        this.storeBusinessHours();
      } else {
        this.updateBusinessHours();
      }
    },
    storeBusinessHours() {
      const data = { ...{ channel_ids: this.mapChannelIds() }, ...this.record };
      request('/api/v2/business_hours', 'POST', data)
        .then((res) => {
          this.createdBusinessHour = res.data;
          this.saving = false;
          this.initWarning();
          this.flashSuccess(this.$t('company_profile.business_hours_created_successfully'));
          this.$store.dispatch({ type: 'business_hours/fetchBusinessHours', force_fetch: true });
          this.closeModal();
        })
        .catch(() => {
          this.saving = false;
        });
    },
    updateBusinessHours() {
      const data = { ...{ channel_ids: this.mapChannelIds() }, ...this.record };
      request(`/api/v2/business_hours/${this.record.id}`, 'PUT', data)
        .then((_) => {
          this.saving = false;
          this.initWarning();
          this.flashSuccess(this.$t('company_profile.business_hours_updated_successfully'));
          this.$store.dispatch({ type: 'business_hours/fetchBusinessHours', force_fetch: true });
          this.closeModal();
        })
        .catch(() => {
          this.saving = false;
        });
    },
    mapChannelIds() {
      return map(this.record.channels, 'id');
    },
    goToStep(step) {
      this.isSecondStep = !!step;
    },
  },
  computed: {
    ...mapGetters('channels', ['businessHoursChannelsList']),
    recordExists() {
      return Object.keys(this.record).length > 0;
    },
    isLgOrXl() {
      const { comparisons } = this.breakpoints;
      return comparisons.eq_lg || comparisons.eq_xl;
    },
    shouldOpenModal() {
      return this.open;
    },
  },
};
</script>
