<script type="text/babel">
import SidebarCollapse from '@/components/common/SidebarCollapse';

import ticketSidebarMoments from './Moments';
import ticketSidebarNotes from './Notes';
import ticketSidebarProfileCustomFieldEditor from './ProfileCustomFieldEditor.vue';

export default {
  props: ['profile', 'ticket', 'selectedPlugins'],

  data() {
    return {
      hide: false,
      notes: [],
    };
  },

  components: {
    ticketSidebarProfileCustomFieldEditor,
    ticketSidebarMoments,
    ticketSidebarNotes,
    SidebarCollapse,
  },

  mounted() {
    if (this.ticket?.contact?.profile[0]?.id) {
      this.loadProfileNotes();
    }
  },

  methods: {
    openProfile() {
      this.$router.push('/profiles/' + this.profile.id);
    },
    // Duplicate function from Moments.vue, necessary evil
    filterByContactOrProfile(t) {
      if (t.length && this.contact) {
        // Filter by contacts
        return t.filter((ticket) => {
          return ticket.contact_id === this.contact.id;
        });
      }
      return t;
    },
    addNote(note) {
      if (note !== null && this.ticket?.contact?.profile[0]?.id !== null) {
        axios
          .post('/api/v2/profiles/' + this.ticket?.contact?.profile[0]?.id + '/notes', {
            note: note.replace(/\r?\n/, '<br>'),
          })
          .then(() => {
            this.loadProfileNotes();
            this.$refs.noteEditor.$refs.noteTextArea.$el.innerText = '';
          })
          .catch((e) => {
            console.error('Error on note post: ', e);
          });
      }
    },
    loadProfileNotes() {
      axios.get('/api/v2/profiles/' + this.ticket.contact.profile[0].id).then((res) => {
        this.notes = res.data.notes.map((note) => {
          return {
            ...note,
            body: note.note,
          };
        });
      });
    },
  },
};
</script>

<template>
  <div v-if="profile">
    <!-- Profile moments -->
    <ticket-sidebar-profile-custom-field-editor
      v-show="Object.keys($root.customProfileFields).length > 0 && selectedPlugins.includes('profileCustomFields')"
      :profile="profile"
    />
    <div
      v-if="selectedPlugins.includes('profileContactMoments') && !hide"
      class="sidebar_block border-b border-grey-300"
    >
      <sidebar-collapse collapse-key="collapseTicketSidebarProfile">
        <template #header>
          <h6 class="mb-0">
            {{ $t('ticket_sidebar.profile_moments') }}
          </h6>
        </template>
        <div>
          <ticket-sidebar-moments
            :ticket="ticket"
            :profile="profile"
            @no-moments="this.hide = true"
          ></ticket-sidebar-moments>
        </div>
      </sidebar-collapse>
    </div>

    <!-- Profile notes -->
    <div v-if="selectedPlugins.includes('profileNotes')" class="sidebar_block border-b border-grey-300">
      <sidebar-collapse collapse-key="collapseTicketSidebarProfileNotes">
        <template #header>
          <h6 class="mb-0">
            {{ $t('ticket_sidebar.profile_notes') }}
          </h6>
        </template>
        <div class="sidebar_block_body mt-2">
          <ticket-sidebar-notes
            ref="noteEditor"
            :placeholder="$t('ticket_sidebar.profile_note_placeholder')"
            :initial-notes="notes"
            @input="addNote"
          ></ticket-sidebar-notes>
        </div>
      </sidebar-collapse>
    </div>
  </div>
</template>
