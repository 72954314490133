<template>
  <modal-base
    v-model="computedValue"
    :submit-button-text="getSubmitButtonText"
    :cancel-button-text="$t('user_management.cancel')"
    class="edit-user-modal"
    :title="$t('user_management.edit_user_details')"
    @submit="save"
    @close="closeModal"
  >
    <template #content>
      <div class="mb-4">
        <div>
          <div class="t-text-desktop-label-md mb-2 text-grey-800 sm:mt-4 md:mt-1">
            {{ $t('user_management.email') }}
          </div>
          <atomic-input v-model="user.email" size="md" class="cursor-not-allowed" data-test="email-input" disabled />
        </div>
        <div>
          <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.name') }}</div>
          <div class="md:flex">
            <div class="md:mr-1 md:w-1/2">
              <atomic-input
                v-model="user.first_name"
                :placeholder="$t('user_management.first_name')"
                size="md"
                required
                autofocus
                :error="!validation.firstname"
                data-test="firstname-input"
                @keydown="handleKeyboardEvent($event)"
              />
              <t-error-item
                v-if="!validation.firstname"
                :text="$t('user_management.validation_first_name_required')"
                data-test="firstname-error"
              />
            </div>
            <div class="mt-2 md:ml-1 md:mt-0 md:w-1/2">
              <atomic-input
                v-model="user.last_name"
                :placeholder="$t('user_management.last_name')"
                size="md"
                :error="!validation.lastname"
                data-test="lastname-input"
                @keydown="handleKeyboardEvent($event)"
              />
              <t-error-item
                v-if="!validation.lastname"
                :text="$t('user_management.validation_last_name_required')"
                data-test="lastname-error"
              />
            </div>
          </div>
        </div>
        <div class="t-text-desktop-label-md mt-6 text-grey-800">{{ $t('user_management.user_role') }}</div>
        <div
          v-if="!isEditRoleDropdownEnabled"
          class="t-text-sm mt-1 text-grey-600"
          data-test="subscription-user-limit-info"
        >
          {{ $t('user_management.you_have_reached_limit_message') }}
        </div>
        <t-dropdown
          :key="currentRole"
          v-model="role"
          :items="roles()"
          :placeholder="$t('user_management.pick_an_option')"
          size="md"
          value-index="value"
          text-index="title"
          :error="!validation.role"
          :disabled="!isEditRoleDropdownEnabled || isOnlyAdministrator"
          container-text-ellipsis
          class="mt-2"
          data-test="authorization-input"
        />
        <t-error-item
          v-if="!validation.role"
          :text="$t('user_management.please_select_a_user_role')"
          data-test="authorization-error"
        />
        <t-inline-banner v-if="!saving && isOnlyAdministrator" class="mt-3" data-test="user-role-validation-info">
          <template #icon>
            <info-linear />
          </template>
          <template v-if="isOnlyAdministrator">
            <div>{{ $t('user_management.organisation_must_have_at_least_one_administrator') }}</div>
          </template>
        </t-inline-banner>
        <t-inline-banner
          v-if="!saving && isObserver(duplicatedUserRecord.role.name) && !isObserver(role)"
          class="mt-3"
          data-test="user-role-upgrade-info"
        >
          <template #icon>
            <info-linear />
          </template>
          <div>{{ $t('user_management.upgrading_this_user_to_another_role_can_not_be_reversed') }}</div>
        </t-inline-banner>
        <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.teams') }}</div>
        <t-multi-select
          v-model="user.teams"
          :placeholder="$t('user_management.select_one_or_more_teams')"
          :use-icon="false"
          :has-avatar="false"
          is-closable
          :data="teams"
          size="md"
          variant="leaf"
          :error="!validation.teams"
          limited-height
          data-test="teams-input"
        />
        <t-error-item
          v-if="!validation.teams"
          :text="$t('user_management.please_select_a_team')"
          data-test="teams-error"
        />
        <div class="t-text-desktop-label-md mb-2 mt-6 text-grey-800">{{ $t('user_management.language') }}</div>
        <t-dropdown
          v-model="user.locale_code"
          size="md"
          :placeholder="$t('user_management.select_a_language')"
          :items="languages"
          value-index="id"
          text-index="title"
          data-test="language-input"
        />
        <div class="t-text-desktop-label-md mb-2 mt-6 inline-flex items-center text-grey-800">
          <span>{{ $t('user_management.account_owner') }}</span>
          <span
            key="tooltipPlan"
            v-tooltip="{
              placement: 'bottom',
              content: $t(
                'user_management.the_account_owner_receives_important_emails_about_invoices_account_and_subscription_updates'
              ),
              popperClass: 'tooltip-vuetify tooltip-medium',
              trigger: getTooltipEvent,
            }"
            class="
              tooltip-user-modal
              ml-1.5
              flex
              h-16px
              w-16px
              cursor-default
              items-center
              justify-center
              rounded-full
              bg-grey-600
              font-bold
              text-white
              hover:bg-grey-800
            "
          >
            i
          </span>
        </div>
        <t-dropdown
          v-model="isPrimary"
          size="md"
          :items="is_primary"
          value-index="id"
          text-index="title"
          :disabled="isOnlyPrimaryUser"
          data-test="is-primary-input"
        />
        <t-inline-banner v-if="!saving && isOnlyPrimaryUser" class="mt-3" data-test="account-owner-validation-info">
          <template #icon>
            <info-linear />
          </template>
          <div>{{ $t('user_management.you_need_to_make_another_user_the_primary_user_to') }}</div>
        </t-inline-banner>
        <template v-if="!customFieldsMapped">
          <div v-for="customField in customFieldsMapped" :key="customField.id" class="mt-6">
            <span class="t-text-desktop-label-md text-grey-800">{{ customField.title }}</span>
            <span class="t-text-desktop-label-md text-grey-600">{{ $t('user_management.optional') }}</span>
            <atomic-input
              v-model="customField.value"
              :placeholder="customField.title"
              size="md"
              class="mt-2"
              data-test="custom-field-input"
              @keydown="handleKeyboardEvent($event)"
            />
          </div>
        </template>
        <t-button
          v-if="canDeleteUser && isMobile"
          btn-style="secondary"
          type="button"
          class="t-text-button-md mt-8 w-full"
          data-test="delete-user-button"
          @click="deleteUser"
        >
          <span class="text-error-500">{{ $t('user_management.delete_user') }}</span>
        </t-button>
      </div>
    </template>
    <template v-if="userIsUnverified" #footer-left>
      <t-button
        class="t-text-desktop-button-md"
        btn-style="secondary"
        data-test="resend-invite-button"
        @click="resendInvite()"
      >
        {{ $t('user_management.resend_invite') }}
      </t-button>
    </template>
  </modal-base>
</template>

<script>
import { InfoLinear } from '@trengo/trengo-icons';
import { map, isEqual } from 'lodash';

import ModalBase from '@/components/OverviewPage/components/ModalBase';
import { getFullUserList } from '@/components/Users/api';
import Constants from '@/Configs/Constants';
import RolesDropdown from '@/mixins/RolesDropdown';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import breakpoints from '@/util/breakpoints';
import { duplicateObject } from '@/util/helpers';

import { editUser, getAllTeams, getUser, sendVerification } from '../Api';

const { UserStatus } = Constants;

export default {
  name: 'EditUserModal',
  emits: ['update:modelValue', 'update', 'delete'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    initialRecordProp: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InfoLinear,
    ModalBase,
  },
  mixins: [RouterWarningOnEdit, RolesDropdown],

  data() {
    return {
      user: this.initialRecordProp,
      breakpoints,
      saving: false,
      teams: [],
      customFieldsMapped: [],
      duplicatedUserRecord: duplicateObject(this.initialRecordProp),
      languages: [
        {
          id: 'nl-NL',
          title: this.$t('user_management.nl'),
        },
        {
          id: 'en-GB',
          title: this.$t('user_management.en'),
        },
      ],
      is_primary: [
        {
          id: 1,
          title: this.$t('user_management.yes'),
        },
        {
          id: 0,
          title: this.$t('user_management.no'),
        },
      ],
      validation: {
        email: {
          email: true,
          unique: true,
        },
        firstname: true,
        lastname: true,
        locale_code: true,
        role: true,
        teams: true,
      },
    };
  },
  watch: {
    firstname(value) {
      this.validation.firstname = !!value;
    },
    lastname(value) {
      this.validation.lastname = !!value;
    },
    locale_code(value) {
      this.validation.locale_code = !!value;
    },
    role(value) {
      this.validation.role = !!value;
    },
    team(value) {
      this.validation.teams = !!value.length;
    },
    initialRecordProp(value) {
      this.user = value;
      this.duplicatedUserRecord = duplicateObject(value);
    },
  },

  created() {
    this.UNVERIFIED = UserStatus.UNVERIFIED;
  },
  async mounted() {
    if (!this.user?.custom_fields) {
      await this.fetchGetUser();
    }
    this.customFieldsMapped = this.user.custom_fields_mapped = this.mapCustomFields();
    this.initWarning();

    getAllTeams().then((response) => {
      this.teams = response.data.data;
    });
  },
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isPrimary: {
      get() {
        return this.user.is_primary;
      },
      set(value) {
        this.user.is_primary = Number(value);
      },
    },
    role: {
      get() {
        return this.currentRole;
      },
      set(value) {
        this.user.role.name = value;
      },
    },
    getSubmitButtonText() {
      return this.isMobile ? this.$t('user_management.save_changes') : this.$t('user_management.save');
    },
    validateFields() {
      return (
        this.validation.firstname &&
        this.validation.lastname &&
        this.validation.locale_code &&
        this.validation.role &&
        this.validation.teams
      );
    },
    isEditRoleDropdownEnabled() {
      return true; // TODO remove if no longer needed (along with related UI)
    },
    getTooltipEvent() {
      return this.isMobile ? 'hover' : 'hover focus click';
    },
    userIsUnverified() {
      return this.user.status === this.UNVERIFIED;
    },
  },
  methods: {
    isDirty() {
      return this.duplicatedUserRecord !== null && !isEqual(this.duplicatedUserRecord, this.user);
    },
    isAssignable() {
      return true;
    },
    deleteUser() {
      this.$emit('delete', this.user);
    },
    async save() {
      if (!this.isDirty()) {
        this.closeModal();
        return;
      }

      if (this.saving) {
        return;
      }

      this.saving = true;
      this.setValidationFields();

      if (!this.validateFields) {
        this.saving = false;
        return;
      }

      await this.editUser();
      this.saving = false;
      await this.fetchGetFullUserList();
      const user = this.$root.users.find((user) => user.id === this.user.id);
      this.$emit('update', user);
      this.closeModal();
    },
    setValidationFields() {
      this.validation.firstname = !!this.user.first_name;
      this.validation.lastname = !!this.user.last_name;
      this.validation.locale_code = !!this.user.locale_code;
      this.validation.role = !!this.user.role;
      this.validation.teams = !!this.user.teams.length;
    },
    async editUser() {
      const data = this.getUserObject();

      await editUser(this.user.id, data)
        .then(() => {
          this.flashSuccess(this.$t('user_management.user_details_were_updated_successfully'));
        })
        .catch((e) => {
          this.saving = false;
          console.error('Error while saving user: ', e);
        });
    },
    resendInvite() {
      sendVerification(this.user.id)
        .then(() => {
          this.flashSuccess(this.$t('user_management.invite_was_sent_successfully'));
          this.closeModal();
        })
        .catch((e) => {
          console.error('Error while sending invite: ', e);
        });
    },

    async fetchGetFullUserList() {
      await getFullUserList(false)
        .then(({ data }) => {
          this.$root.users = data.users;
        })
        .catch((e) => {
          console.error('Error while fetching users: ', e);
        });
    },
    fetchGetUser() {
      return getUser(this.user.id)
        .then((res) => {
          this.user = res.data;
        })
        .catch((e) => {
          console.error('Error while fetching user: ', e);
        });
    },
    getUserObject() {
      return {
        ...{ team_ids: this.mapTeamIds() },
        ...{ custom_fields_mapped: this.customFieldsMapped },
        ...{
          email: this.user.email,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          is_primary: this.user.is_primary,
          role: this.user.role.name,
          locale_code: this.user.locale_code,
        },
      };
    },
    mapTeamIds() {
      return map(this.user.teams, 'id');
    },
    mapCustomFields() {
      return (this.$root.customUserFields || []).map((field) => {
        const userField = this.user.custom_fields.find((userField) => {
          return userField.id === field.id;
        });

        let value = '';

        if (userField) {
          value = userField.pivot.value;
        }

        return { title: field.title, id: field.id, value: value };
      });
    },
    closeModal() {
      this.computedValue = false;
    },
    handleKeyboardEvent(event) {
      if (event.key === 'Enter') {
        this.save();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/UserModalTemplate.scss';
</style>
