<template>
  <div
    v-if="isOpen"
    data-test="toast-message"
    class="
      absolute
      bottom-4
      left-[88px]
      z-50
      flex
      w-auto
      flex-row
      items-center
      justify-between
      rounded-lg
      bg-grey-800
      px-4
      py-2
    "
  >
    <div class="t-text-sm flex w-auto flex-row items-center justify-between text-white">
      <alert-triangle-linear
        v-if="hasIcon && iconType === 'alert'"
        data-test="alert-icon"
        size="1.25rem"
        class="mr-2"
      />
      <checkbox-circle-linear
        v-if="hasIcon && iconType === 'success'"
        data-test="success-icon"
        size="1.25rem"
        class="mr-2"
      />
      <slot />
      <t-icon-button
        v-if="hasCloseIcon"
        variant="dark"
        data-test="close-icon"
        class="ml-3 cursor-pointer text-white"
        @click="handleCloseBar"
      >
        <cross-linear />
      </t-icon-button>
    </div>
  </div>
</template>

<script lang="ts">
import { CrossLinear, AlertTriangleLinear, CheckboxCircleLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TToast',
  emits: ['close'],
  components: { CrossLinear, AlertTriangleLinear, CheckboxCircleLinear },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    iconType: {
      default: 'alert',
      type: String,
    },
    hasIcon: {
      default: false,
      type: Boolean,
    },
    hasCloseIcon: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    handleCloseBar() {
      this.$emit('close');
    },
  },
});
</script>
