import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/wa_templates',
    component: () => import('../../components/WaTemplates/Index'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: '/admin/wa_templates/create',
        component: () => import('../../components/WaTemplates/Edit'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/wa_templates/:id',
        component: () => import('../../components/WaTemplates/Edit'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '',
        component: () => import('../../components/AdminIndexBox'),
        props: { boxName: 'wa_templates' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
  {
    path: '/admin_popout/wa_templates',
    component: () => import('../../components/WaTemplates/Index'),
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: '/admin/admin_popout/create',
        component: () => import('../../components/WaTemplates/Edit'),
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/admin_popout/:id',
        component: () => import('../../components/WaTemplates/Edit'),
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];
