// @ts-nocheck
// @ts-ignore

import plugins from './plugins';
import rest from './rest';
import webhooks from './webhooks';
import zapier from './zapier';

import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [...webhooks, ...rest, ...zapier, ...plugins];

export default routes;
