<template>
  <ul class="grey-block mb-0 rounded-lg bg-grey-200 p-6 text-grey-600">
    <li class="inline-flex w-full">
      <span class="title">{{ $t('internal_chat.sidebar_info_created') }}:</span>
      <span class="description text-ellipsis">
        <!--                <div>{{moment.utc(thread.createdAt*1000).format('LL')}}</div>-->
        <datetime :time="(thread.createdAt * 1000).toString()" :pretty="$root.prettyDates" class="pointer"></datetime>
      </span>
    </li>
    <!--        <li class="inline-flex"><span class="title">messages loaded:</span> <span class="text-ellipsis description">{{thread.messages.length}}</span></li>-->
    <li class="inline-flex w-full">
      <span class="title">{{ $t('internal_chat.sidebar_info_total') }}:</span>
      <span class="description text-ellipsis">
        <template v-if="thread.participants.length !== 1">
          {{ $t('internal_chat.sidebar_info_participants_plural', { total: thread.participants.length }) }}
        </template>
        <template v-else-if="thread.participants.length === 1">
          {{ $t('internal_chat.sidebar_info_participants_singular', { total: thread.participants.length }) }}
        </template>
      </span>
    </li>

    <li class="inline-flex w-full">
      <span class="title">ID:</span>
      <span class="description text-ellipsis">
        {{ thread.id }}
      </span>
    </li>
  </ul>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ThreadInfo',
  props: ['thread'],
  methods: {},
  computed: {
    moment: () => moment,
  },
};
</script>

<style lang="scss">
.grey-block {
  .title {
    font-weight: bold;
    color: theme('colors.grey-600');
    min-width: 130px;
    display: inline-block;
  }
  .description {
    max-width: 150px;
  }
}
</style>
