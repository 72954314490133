<template>
  <div>
    <h3 class="t-text-desktop-h4 mb-4">{{ $t('getting_started.learn_more') }}</h3>
    <learn-more-category
      v-for="(category, index) in categories"
      :items="category.items"
      :title="category.title"
      :key="index"
    />
  </div>
</template>

<script>
import { PERMISSION } from '@/Configs/Constants';

import LearnMoreCategory from './LearnMoreCategory';

export default {
  name: 'LearnMore',
  components: { LearnMoreCategory },
  computed: {
    categories() {
      return [
        {
          title: this.$t('getting_started.set_up_the_basics'),
          items: [
            {
              title: this.$t('getting_started.your_personal_profile'),
              subtitle: this.$t('getting_started.subtitle_your_personal_profile'),
              link: 'https://help.trengo.com/en/articles/63487-your-personal-trengo-profile',
              settingLink: '/profile',
              icon: 'user_square_duotone',
              color: 'sun-500',
            },
            {
              title: this.$t('getting_started.start_a_conversation'),
              subtitle: this.$t('getting_started.subtitle_start_a_conversation'),
              link: 'https://help.trengo.com/en/15672-working-in-trengo/19950-start-and-answer-conversations',
              settingLink: '/tickets',
              icon: 'comment_duotone',
              color: 'sun-500',
            },
          ],
        },
        {
          title: this.$t('getting_started.provide_better_customer_service'),
          items: [
            ...(this.$authorization().hasPermission(PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE)
              ? [
                  {
                    title: this.$t('getting_started.create_a_quick_reply'),
                    type: 'Quick Replies',
                    subtitle: this.$t('getting_started.subtitle_create_a_quick_reply'),
                    link: 'https://help.trengo.com/en/articles/63727-adding-quick-replies-to-your-trengo-inbox',
                    settingLink: '/admin/quick_replies',
                    icon: 'quick_reply_duotone',
                    color: 'sky-500',
                  },
                ]
              : []),
            ...(this.$authorization().hasPermission(PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE)
              ? [
                  {
                    title: this.$t('getting_started.create_an_auto_reply'),
                    type: 'Auto replies',
                    subtitle: this.$t('getting_started.subtitle_create_an_auto_reply'),
                    link: 'https://help.trengo.com/en/articles/63697-how-to-set-up-auto-replies',
                    settingLink: '/admin/auto_replies',
                    icon: 'reply_all_outline',
                    color: 'sky-500',
                  },
                ]
              : []),
            ...(this.$authorization().hasPermission(PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE)
              ? [
                  {
                    title: this.$t('getting_started.create_a_live_chat_widget'),
                    type: 'Website Widgets',
                    subtitle: this.$t('getting_started.subtitle_create_a_live_chat_widget'),
                    link: 'https://help.trengo.com/en/articles/68462-add-a-website-widget-to-your-trengo-inbox',
                    settingLink: '/admin/web_widgets',
                    icon: 'comments_duotone',
                    color: 'sky-500',
                  },
                ]
              : []),
          ],
        },
        {
          title: this.$t('getting_started.scale_quickly_and_efficiently'),
          items: [
            ...(this.$authorization().hasPermission(PERMISSION.SETTINGS__LABELS__MANAGE)
              ? [
                  {
                    title: this.$t('getting_started.create_a_label'),
                    type: 'Label',
                    subtitle: this.$t('getting_started.subtitle_create_a_label'),
                    link: 'https://help.trengo.com/en/articles/63822-how-to-create-labels',
                    settingLink: '/admin/labels',
                    icon: 'tag_duotone',
                    color: 'purple-500',
                  },
                ]
              : []),
            ...(this.$authorization().hasPermission(PERMISSION.SETTINGS__RULES__MANAGE)
              ? [
                  {
                    title: this.$t('getting_started.create_a_rule'),
                    type: 'Rules',
                    subtitle: this.$t('getting_started.subtitle_create_a_rule'),
                    link: 'https://help.trengo.com/en/articles/63757-the-basic-configuration-of-rules',
                    settingLink: '/admin/rules',
                    icon: 'lightning_duotone',
                    color: 'purple-500',
                  },
                ]
              : []),
            ...(this.$authorization().hasPermission(PERMISSION.SETTINGS__FLOWBOTS__MANAGE)
              ? [
                  {
                    title: this.$t('getting_started.create_a_flowbot'),
                    type: 'Flowbots',
                    subtitle: this.$t('getting_started.subtitle_create_a_flowbot'),
                    link: 'https://help.trengo.com/en/articles/64117-the-anatomy-of-a-flowbot',
                    settingLink: '/admin/flows',
                    icon: 'flow_duotone',
                    color: 'purple-500',
                  },
                ]
              : []),
            {
              title: this.$t('getting_started.create_a_help_center'),
              type: 'Help center',
              subtitle: this.$t('getting_started.subtitle_create_a_help_center'),
              link: 'https://help.trengo.com/en/articles/63862-adding-a-help-center-to-your-trengo-account',
              settingLink: '/help_center',
              icon: 'helpcenter_create_legacy',
              color: 'purple-500',
            },
          ],
        },
      ];
    },
  },
};
</script>
