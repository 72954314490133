import { intersection } from 'lodash';

import { PLAN } from '@/Configs/Constants';

import type { QuantityEntitlement, Entitlement } from '@/types';

export const isQuantityWithinEntitlementLimit = (entitlement: QuantityEntitlement, quantity: number) => {
  return entitlement.max_quantity === null || (entitlement.max_quantity !== 0 && entitlement.max_quantity >= quantity);
};

export const filterEntitlementsByParams = (params: Partial<Entitlement>, entitlements: Entitlement[]) =>
  entitlements.filter((entitlement) => {
    return Object.entries(params).every(([paramKey, paramValue]) =>
      Array.isArray(paramValue)
        ? intersection(paramValue, entitlement[paramKey]).length > 0
        : entitlement[paramKey] === paramValue
    );
  });

export const isTrialEntitlement = (entitlement: Entitlement) => {
  return entitlement.item_id === PLAN.TIER_0;
};
