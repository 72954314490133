<template>
  <dropdown
    :search="false"
    :toggle-element-inline="true"
    :options="dropdownOptionObjects"
    width="auto"
    max-width="300px"
    @update:modelValue="onInput"
  >
    <template #heading>
      <div class="flex items-center p-2">
        <div class="pr-2">
          <span @click="openContact(contact.id)">
            <avatar
              :color="contact.color"
              :abbr="contact.abbr"
              width="30"
              font-size="12px"
              :image="contact.profile_image"
            ></avatar>
          </span>
        </div>
        <div>
          <strong @click="openContact(contact.id)">{{ contact.full_name }}</strong>
          <div class="text-xs">{{ contact.email }}</div>
        </div>
      </div>
    </template>
    <template #toggle>
      <span
        v-if="contact.email"
        class="font-weight-normal flex min-w-0 cursor-pointer flex-nowrap items-center hover:text-grey-800"
        data-no-toggle
        data-hj-suppress
      >
        <i v-if="icon" class="material-icons material-icon-mail pr-1">email</i>
        <div data-hj-suppress class="text-ellipsis" data-no-toggle>
          {{ contact.email }}
        </div>
        <i v-if="icon" class="material-icons text-md">expand_more</i>
      </span>
    </template>
  </dropdown>
</template>

<script type="text/babel">
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';

import Avatar from './Avatar';
import Dropdown from './ReplyForm/Dropdown';

export default {
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dropdownOptionObjects: [],
    };
  },

  components: {
    Dropdown,
    Avatar,
  },
  mounted() {
    this.getDropdownOptionObjects();
  },

  methods: {
    onInput(input) {
      switch (input.id) {
        case 'open_contact':
          router.push('/contacts/' + this.contact.id);
          break;

        case 'compose':
          eventBus.$emit('modals.create-ticket.open', {
            contact: this.contact,
            channelType: 'EMAIL',
          });
          break;

        case 'search':
          eventBus.$emit('tickets.search', this.contact.email);
          break;

        case 'copy':
          window.copyToClipboard(this.contact.email);
          break;

        case 'update':
          eventBus.$emit('contact.update', true);
          break;
      }
    },
    getDropdownOptionObjects() {
      this.dropdownOptionObjects = [
        { id: 'open_contact', icon: 'person', text: this.$t('general.show_contact') },
        { id: 'search', icon: 'search', text: this.$t('general.search') + " '" + this.contact.email + "'" },
        { id: 'copy', icon: 'filter_none', text: this.$t('general.copy') + " '" + this.contact.email + "'" },
        { id: 'update', icon: 'update', text: this.$t('general.update') + " '" + this.contact.email + "'" },
      ];
      if (this.$authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)) {
        const dropdownOptionCreateMessageObject = {
          id: 'compose',
          icon: 'create',
          text: this.$t('general.create_message'),
        };
        this.dropdownOptionObjects = [dropdownOptionCreateMessageObject, ...this.dropdownOptionObjects];
      }
    },
  },
};
</script>
