import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { ADDON_WITH_QUANTITY } from '@/Configs/Constants';
import type { ScheduledChanges } from '@/types';
import type { ConversationBlocksInvoice } from '@/types/billing';
import type { AxiosPromise } from 'axios';

type AddonWithQuantity = valueof<typeof ADDON_WITH_QUANTITY>;

type AddonWithQuantityPayload = { [key in AddonWithQuantity]?: number };

export function postOneTimePurchaseAddConversationBlocks(payload: {
  standard_conversation_blocks?: number;
  intelligent_conversation_blocks?: number;
}): AxiosPromise<void> {
  return request(ENDPOINT.BILLING_ONE_TIME_PURCHASE_ADD_CONVERSATION_BLOCKS, 'POST', payload);
}

export function updateCarrierCost(payload: { quantity: number }): AxiosPromise<void> {
  return request(ENDPOINT.BILLING_SUBSCRIPTION_UPDATE_CARRIER_COST, 'POST', payload);
}

export function addAddonsWithQuantity(payload: AddonWithQuantityPayload): AxiosPromise<void> {
  return request(ENDPOINT.BILLING_SUBSCRIPTION_ADD_ADDONS_WITH_QUANTITY, 'POST', payload);
}

export function updateAddonsWithQuantity(payload: AddonWithQuantityPayload): AxiosPromise<void> {
  return request(ENDPOINT.BILLING_SUBSCRIPTION_UPDATE_ADDONS_WITH_QUANTITY, 'POST', payload);
}

export function fetchOneTimePurchaseInvoices(): AxiosPromise<{
  items: ConversationBlocksInvoice[];
}> {
  return request(ENDPOINT.BILLING_ONE_TIME_PURCHASE_INVOICES, 'GET');
}

export function fetchScheduledChanges(): AxiosPromise<ScheduledChanges> {
  return request(ENDPOINT.BILLING_SUBSCRIPTION_SCHEDULED_CHANGES, 'GET');
}
