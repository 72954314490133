import { debounce } from 'lodash';
import { getActivePinia } from 'pinia';

import { useFeatureFlagStore } from '@/store/pinia';

import type { FeatureFlag } from '@/Configs/Constants';

async function checkFeatureFlag(featureFlag: FeatureFlag) {
  await new Promise(ensureFlagsAreFetched);

  return useFeatureFlagStore().isEnabled(featureFlag);
}

function ensureFlagsAreFetched(resolve: (value?: unknown) => void, reject: unknown): void {
  if (getActivePinia() && useFeatureFlagStore().isStarted) {
    resolve();
  } else {
    const debounced = debounce(function () {
      ensureFlagsAreFetched(resolve, reject);
    }, 30);

    debounced();
  }
}

export default checkFeatureFlag;
