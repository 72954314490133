import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import type { NavigationGroupItem } from '@/types/navigation';

export const SETTINGS_NAVIGATION: readonly NavigationGroupItem[] = [
  {
    title: 'settings.navigation_organization',
    items: [
      {
        title: 'settings.navigation_teams',
        url: SETTINGS_URL.ADMIN__TEAMS,
        icon: 'far fa-users',
        permission: [PERMISSION.PERMISSIONS__TEAMS__MANAGE],
      },
      {
        title: 'settings.navigation_users',
        url: SETTINGS_URL.ADMIN__USERS,
        icon: 'far fa-user',
        permission: [PERMISSION.PERMISSIONS__USERS__MANAGE],
      },
    ],
  },
  {
    title: 'settings.navigation_channels',
    items: [
      {
        title: 'settings.navigation_whatsapp_business',
        url: SETTINGS_URL.ADMIN__CHANNELS2_WA_BUSINESS,
        icon: 'fab fa-whatsapp',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_facebook',
        url: SETTINGS_URL.ADMIN__CHANNELS2_FACEBOOK,
        icon: 'fab fa-facebook',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_instagram',
        url: SETTINGS_URL.ADMIN__CHANNELS2_INSTAGRAM,
        icon: 'fab fa-instagram',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_email',
        url: SETTINGS_URL.ADMIN__CHANNELS2_EMAIL,
        icon: 'far fa-envelope',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_website_chat',
        url: SETTINGS_URL.ADMIN__CHANNELS2_CHAT,
        icon: 'far fa-comments',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_telegram',
        url: SETTINGS_URL.ADMIN__CHANNELS2_TELEGRAM,

        icon: 'fab fa-telegram',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        id: 'gbm',
        title: 'settings.navigation_google_business',
        url: SETTINGS_URL.ADMIN__CHANNELS2_GBM,
        icon: 'fab fa-google',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_sms',

        url: SETTINGS_URL.ADMIN__CHANNELS2_SMS,

        icon: 'far fa-sms',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_custom',
        url: SETTINGS_URL.ADMIN__CHANNELS2_CUSTOM,

        icon: 'far fa-eye',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
      {
        title: 'settings.navigation_voice',
        url: SETTINGS_URL.ADMIN__CHANNELS2_VOIP,

        icon: 'far fa-phone',
        permission: [PERMISSION.SETTINGS__CHANNELS__MANAGE],
      },
    ],
  },
  {
    title: 'settings.navigation_automation',
    items: [
      {
        title: 'settings.navigation_rules',
        url: SETTINGS_URL.ADMIN__RULES,

        icon: 'far fa-bolt',
        permission: [PERMISSION.SETTINGS__RULES__MANAGE],
      },
      {
        title: 'settings.navigation_chatbots',
        url: SETTINGS_URL.ADMIN__CHATBOTS,
        hidden: true,
        icon: 'far fa-user-robot',
        permission: [PERMISSION.SETTINGS__CHATBOTS__MANAGE],
      },
      {
        title: 'settings.navigation_flowbots',
        url: SETTINGS_URL.ADMIN__FLOWBOTS,

        icon: 'far fa-sitemap',
        permission: [PERMISSION.SETTINGS__FLOWBOTS__MANAGE],
      },
      {
        title: 'settings.navigation_auto_replies',
        url: SETTINGS_URL.ADMIN__AUTO_REPLIES,

        icon: 'far fa-reply-all',
        permission: [PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE],
      },
      {
        title: 'settings.navigation_widget_automation',
        url: SETTINGS_URL.ADMIN__WIDGET_AUTOMATION,

        icon: 'far fa-magic',
        permission: [PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE],
      },
      {
        title: 'settings.navigation_customer_satisfaction',
        url: SETTINGS_URL.ADMIN__CSAT,

        icon: 'far fa-laugh-beam',
        permission: [PERMISSION.SETTINGS__CSAT__MANAGE],
      },
    ],
  },
  {
    title: 'settings.navigation_settings',
    items: [
      {
        id: 'integrationHub',
        title: 'integration_hub.integrations',
        url: SETTINGS_URL.ADMIN__APPS_INTEGRATION_HUB,
        hasBetaBadge: true,
        iconComponent: 'integration-linear',
        permission: [PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE],
      },
      {
        title: 'settings.navigation_website_widgets',
        url: SETTINGS_URL.ADMIN__WEB_WIDGETS,

        icon: 'far fa-globe',
        permission: [PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE],
      },
      {
        title: 'settings.navigation_quick_replies',
        url: SETTINGS_URL.ADMIN__QUICK_REPLIES,
        icon: 'far fa-comments',
        permission: [PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE],
      },
      {
        title: 'settings.navigation_labels',
        url: SETTINGS_URL.ADMIN__LABELS,

        icon: 'far fa-tag',
        permission: [PERMISSION.SETTINGS__LABELS__MANAGE],
      },
      {
        title: 'settings.navigation_views',
        url: SETTINGS_URL.ADMIN__CUSTOM_VIEWS,
        icon: 'far fa-eye',
        permission: [PERMISSION.SETTINGS__VIEWS__MANAGE],
      },
      {
        title: 'settings.navigation_custom_fields',
        url: SETTINGS_URL.ADMIN__CUSTOM_FIELDS,

        icon: 'far fa-list',
        permission: [PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE],
      },
      {
        title: 'settings.navigation_contact_groups',
        url: SETTINGS_URL.ADMIN__CONTACT_GROUPS,

        icon: 'far fa-users',
        permission: [PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE],
      },
      {
        title: 'settings.navigation_ticket_results',
        url: SETTINGS_URL.ADMIN__TICKET_RESULTS,

        icon: 'far fa-trophy',
        permission: [PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE],
      },
      {
        title: 'company_profile.sidenav_locations',
        url: SETTINGS_URL.ADMIN__LOCATIONS,

        icon: 'far fa-map-marker-alt',
        permission: [PERMISSION.SETTINGS__LOCATIONS__MANAGE],
      },
      {
        title: 'company_profile.sidenav_business_hours',
        url: SETTINGS_URL.ADMIN__BUSINESS_HOURS,

        icon: 'far fa-alarm-clock',
        permission: [PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE],
      },
      {
        id: 'legacyIntegrations',
        title: 'settings.navigation_apps_integrations',
        url: SETTINGS_URL.ADMIN__APPS_INTEGRATIONS,
        icon: 'far fa-bolt',
        permission: [PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE],
      },
    ],
  },
  {
    title: 'settings.account',
    items: [
      {
        title: 'settings.account_settings',
        url: SETTINGS_URL.ADMIN__ACCOUNT_SETTINGS,
        icon: 'far fa-user-circle',
        permission: [PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE],
      },
      {
        title: 'settings.subscription_settings',
        url: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
        icon: 'far fa-file-invoice',
        permission: [PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE],
      },
      {
        title: 'settings.balance_settings',
        url: SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
        icon: 'far fa-wallet',
        permission: [PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE],
        key: 'balance_settings',
      },
      {
        title: 'transactions.heading',
        url: SETTINGS_URL.ADMIN__TRANSACTIONS,
        icon: 'far fa-money-check-alt',
        permission: [PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE],
      },
      {
        title: 'usage_overview.your_usage_heading',
        url: SETTINGS_URL.ADMIN__USAGE_OVERVIEW,
        icon: 'far fa-chart-bar',
        permission: [PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE],
        key: 'usage_overview',
      },
    ],
  },
];
