<template>
  <gray-card>
    <template #default>
      <div class="mb-8 flex flex-row items-center">
        <div class="flex h-44px w-44px items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-48px sm:w-48px">
          <img class="w-24" :src="`${$root.assetsURL}img/comment_filled_white.svg`" />
        </div>
        <div class="ml-4 flex flex-col">
          <div class="t-text-mobile-body-md sm:t-text-button-lg sm:mb-1">
            {{ $t('getting_started.title_add_a_channel') }}
          </div>
          <div class="t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800">
            {{ $t('getting_started.connect_with_your_customers') }}
          </div>
        </div>
      </div>
      <div class="mb-4 grid grid-cols-1 gap-4 sm:mb-3 lg:grid-cols-3">
        <add-channel-modal v-model="openAddChannel" />
        <item-button @click="handleChooseChannel({ type: 'whatsapp', id: 'wa_business' })">
          <img :src="`${$root.assetsURL}img/whatsapp.svg`" class="w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_whatsapp') }}</span>
        </item-button>
        <item-button @click="handleChooseChannel({ type: 'email', id: 'email' })">
          <img :src="`${$root.assetsURL}img/channels_icons/no-bg/email.svg`" class="w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_email') }}</span>
        </item-button>
        <item-button @click="handleChooseChannel({ type: 'chat', id: 'chat' })">
          <img :src="`${$root.assetsURL}img/channels_icons/no-bg/chat.svg`" class="w-24px" />
          <span class="t-text-button-sm">{{ $t('getting_started.title_live_chat') }}</span>
        </item-button>
      </div>
      <div>
        <span class="t-text-button-md cursor-pointer text-leaf-500 hover:underline" @click="openAddChannel = true">
          {{ $t('getting_started.button_see_all') }}
        </span>
      </div>
    </template>
  </gray-card>
</template>

<script>
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';

import AddChannelModal from './AddChannelModal';
import GrayCard from '../GrayCard';
import ItemButton from '../ModalItem/ItemButton/';
export default {
  name: 'AddChannel',
  components: { AddChannelModal, ItemButton, GrayCard },
  data() {
    return {
      openAddChannel: false,
    };
  },
  mounted() {
    eventBus.$on('choose-channel', this.handleChooseChannel);
  },
  unmounted() {
    eventBus.$off('choose-channel', this.handleChooseChannel);
  },
  methods: {
    handleChooseChannel({ type, id }) {
      // Notice: this will unset previously set interests, if you don't want that, just append 'name' to this.$store.getters['onboardingSteps/selectedInterests'].map(item => item.name)
      this.$store.dispatch('onboardingSteps/putInterestsSelected', [type]);
      let route;
      if (type === 'help_center') {
        route = this.$authorization().hasPermission(PERMISSION.HELP_CENTER__GENERAL__MANAGE)
          ? '/help_center'
          : '/unauthorized';
      } else {
        route = `/admin/channels2/${id}`;
      }
      eventBus.$emit('close-overview-modal');
      //remove listener
      eventBus.$off('choose-channel', this.handleChooseChannel);
      setTimeout(() => {
        router.push(route);
        eventBus.$off('close-overview-modal');
      }, 200);
    },
    closeModal() {
      this.openAddChannel = false;
    },
  },
};
</script>
