// @ts-nocheck
// @ts-ignore

import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/admin/integrations/rest',
    component: () => import(/* webpackChunkName; "Rest" */ '@/components/IntegrationHub/Pages/RestApi/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    beforeEnter: hasPermission,
    name: 'rest',
  },
];

export default routes;
