import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/chatbots',
    redirect: '/admin/chatbots',
  },
  {
    path: '/chatbots/:catchAll(.*)',
    redirect: (to) => {
      return '/admin/chatbots/' + to.params.catchAll;
    },
  },
  {
    path: '/admin/chatbots',
    component: require('../../components/Chatbots/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: '/admin/chatbots/:id',
        component: require('../../components/Chatbots/Edit').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
        beforeEnter: hasPermission,
        children: [
          {
            path: '/admin/chatbots/:id/questions',
            component: require('../../components/Chatbots/Questions').default,
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/categories',
            component: require('../../components/Chatbots/Categories').default,
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/categories/create',
            component: require('../../components/Chatbots/Category').default,
            props: { action: 'create' },
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/categories/:category_id',
            component: require('../../components/Chatbots/Category').default,
            props: { action: 'update' },
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/playground',
            component: require('../../components/Chatbots/Testing').default,
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/mismatches',
            component: require('../../components/Chatbots/Mismatches').default,
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
          {
            path: '/admin/chatbots/:id/settings',
            component: require('../../components/Chatbots/Settings').default,
            meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
          },
        ],
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'chatbots' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHATBOTS__MANAGE },
        beforeEnter: hasPermission,
      },
    ],
  },
];
