<script setup lang="ts">
import { AlertTriangleFill, TaskAutomationFill } from '@trengo/trengo-icons';

import { useSuggestedReplyStore } from './useSuggestedReplyStore';

const suggestedReplyStore = useSuggestedReplyStore();

const props = defineProps<{
  ticketId: number;
}>();

suggestedReplyStore.activeTicketId = props.ticketId;

const handleClick = () => {
  suggestedReplyStore.updateReply(props.ticketId);
};
</script>

<template>
  <t-button
    :disabled="suggestedReplyStore.isLoading"
    type="button"
    btn-style="secondary"
    class="flex items-center"
    data-test="suggest-reply-cta"
    @click="handleClick"
  >
    <t-spinner v-if="suggestedReplyStore.isLoading" class="me-1" size="1rem" />
    <TaskAutomationFill v-else class="me-1 size-5" />
    {{ suggestedReplyStore.isLoading ? $t('tickets.suggest_reply_loading') : $t('tickets.suggest_reply_cta') }}
  </t-button>
  <!-- TODO Update template root values  -->
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <div v-if="suggestedReplyStore.isError" class="flex items-center gap-1 text-red-dark">
    <AlertTriangleFill class="me-1 size-5" />
    <span>{{ $t('tickets.suggest_reply_error') }}</span>
  </div>
</template>
