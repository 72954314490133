import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { fetchAllUsers } from '@/api';
import { USER_ROLE, adminRoles } from '@/Configs/Constants/UserRoles';

import type { User } from '@/types';

const filterOutObservers = (usersList: User[]): User[] =>
  usersList.filter((user) => user.role.name !== USER_ROLE.OBSERVER);

export const useUsersStore = defineStore('users', () => {
  const isLoading = ref(true);
  const users = ref<User[]>([]);
  const billableUsers = computed(() => filterOutObservers(users.value));
  const assignableUsers = computed(() => filterOutObservers(users.value));
  const primaryUsers = computed(() => (users.value ?? []).filter(({ is_primary }) => is_primary));

  const hasOnlyOneAdministrator = computed(() => {
    const adminUsers = users.value.filter((user) => Boolean(user?.role?.name) && adminRoles.includes(user.role.name));
    return adminUsers.length <= 1;
  });

  const init = async (payload?: { data: { users: User[] } }) => {
    if (users.value.length > 0) {
      return;
    }

    try {
      if (payload?.data?.users?.length) {
        users.value = payload?.data.users;
      } else {
        reload();
      }
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  const reload = async () => {
    try {
      isLoading.value = true;
      const { data } = await fetchAllUsers({ cacheEnabled: false });
      users.value = data?.users || [];
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    assignableUsers,
    billableUsers,
    primaryUsers,
    init,
    reload,
    isLoading,
    hasOnlyOneAdministrator,
    users,
  };
});
