import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/custom_views',
    component: require('../../components/CustomViews/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: '/admin/custom_views/create',
        component: require('../../components/CustomViews/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
      },
      {
        path: '/admin/custom_views/:id',
        component: require('../../components/CustomViews/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'views' },
        meta: { permissionRequired: PERMISSION.SETTINGS__VIEWS__MANAGE },
      },
    ],
  },
];
