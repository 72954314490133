import { h } from 'vue';
import { RouterView } from 'vue-router';

import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/widget_automation',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        component: require('../../components/WidgetAutomation/Index').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: 'clone/:clone',
        component: require('../../components/WidgetAutomation/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: 'create/:template([a-zA-Z-_.0-9]+)',
        component: require('../../components/WidgetAutomation/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: 'create',
        component: require('../../components/WidgetAutomation/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: ':id(\\d+)',
        component: require('../../components/WidgetAutomation/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE },
        beforeEnter: hasPermission,
      },
    ],
  },
];
