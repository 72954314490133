// @ts-nocheck
// @ts-ignore

import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/admin/integrations/webhooks',
    component: () =>
      import(/* webpackChunkName: "WebhooksIndex" */ '@/components/IntegrationHub/Pages/Webhooks/Index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    beforeEnter: hasPermission,
    name: 'webhooks',
  },
  {
    path: '/admin/integrations/webhooks/create',
    component: () =>
      import(/* webpackChunkName: "WebhookCreate" */ '@/components/IntegrationHub/Pages/Webhooks/Edit.vue'),
    props: { action: 'create' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    beforeEnter: hasPermission,
    name: 'webhookCreate',
  },
  {
    path: '/admin/integrations/webhooks/:id',
    component: () =>
      import(/* webpackChunkName: "WebhookItem" */ '@/components/IntegrationHub/Pages/Webhooks/Edit.vue'),
    props: { action: 'edit' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    beforeEnter: hasPermission,
    name: 'webhookItem',
  },
];

export default routes;
