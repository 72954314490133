<template>
  <div
    ref="backdrop"
    class="trengo-modal-chat trengo-modal-group flex max-h-full select-none items-center justify-center px-0 md:px-8"
    @click.self="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <div v-if="creating" class="box custom-shadow-outline overflow-hidden rounded-lg p-8">
      <h3 class="mb-0">
        <i class="fa fa-spin fa-cog mr-2"></i>
        {{ $t('internal_chat.manage_group_modal_creating_group') }}
      </h3>
    </div>

    <div v-else class="box custom-shadow-outline flex max-h-full flex-col rounded-lg" style="width: 700px">
      <div class="modal-header">
        <h3 v-if="thread" class="mb-0 text-ellipsis px-8 text-center" style="font-size: 18px; line-height: 1.5">
          {{ $t('internal_chat.manage_group_modal_title_update', { subject: thread.identifier }) }}
        </h3>
        <h3 v-else class="mb-0 text-ellipsis px-8 text-center" style="font-size: 18px; line-height: 1.5">
          {{ $t('internal_chat.manage_group_modal_title') }}
        </h3>
        <button type="button" class="modal-close-btn leading-none" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px" @click="$emit('close')">close</i>
        </button>
      </div>

      <div class="modal-container bg-white pl-4 lg:pl-8">
        <div class="flex flex-col pb-6 lg:pb-8">
          <div class="form-group pr-4 lg:pr-8">
            <label>{{ $t('internal_chat.manage_group_modal_subject') }}</label>
            <input
              ref="subject"
              v-model="record.subject"
              type="text"
              :placeholder="$t('internal_chat.manage_group_modal_subject')"
              class="form-control b-b b-2x form-input"
            />
          </div>

          <form class="form-group pr-4 lg:pr-8">
            <label for="userSelect">{{ $t('internal_chat.manage_group_modal_participants') }}</label>
            <multiselect
              id="userSelect"
              v-model="selectedUser"
              :reset-after="true"
              :placeholder="$t('internal_chat.manage_group_modal_participants_placeholder')"
              label="name"
              :options="usersAvailable"
              :select-label="$t('internal_chat.manage_group_modal_select_label')"
              deselect-label=""
              :allow-empty="false"
              track-by="id"
              @select="addParticipant($event)"
            >
              <template #noResult>
                <span>{{ $t('internal_chat.manage_group_modal_no_users_found') }}</span>
              </template>
              <template #option="{ option }">
                <div class="flex items-center">
                  <avatar
                    class="mr-2"
                    font-size="11px"
                    width="24"
                    :abbr="option.abbr"
                    :image="option.profileImage"
                    :color="option.color"
                  ></avatar>
                  <span class="ml-2 text-ellipsis">{{ option.name }}</span>
                </div>
              </template>
            </multiselect>
          </form>

          <div class="scroll-on-hover">
            <table class="table">
              <template v-if="record.participants.length">
                <thead>
                  <tr>
                    <th colspan="2" class="w-3/4" style="padding-left: 0">
                      {{ $t('internal_chat.manage_group_modal_participants') }}
                    </th>
                    <th class="w-full text-center">{{ $t('internal_chat.manage_group_modal_owner') }}</th>
                    <th class="text-center" style="padding-right: 0">
                      {{ $t('internal_chat.manage_group_modal_remove') }}
                    </th>
                  </tr>
                </thead>
                <tr v-for="participant in record.participants">
                  <td>
                    <avatar
                      width="33"
                      :abbr="getUserById(participant.id).abbr"
                      :image="getUserById(participant.id).profileImage"
                      :color="getUserById(participant.id).color"
                    ></avatar>
                  </td>
                  <td style="vertical-align: middle; width: 70%; padding-left: 1rem">
                    <span class="block leading-none">
                      {{ getUserById(participant.id).name }}
                      <small v-if="participant.id === currentUser.id" class="text-muted mt-1 w-3/4 leading-none">
                        ({{ $t('internal_chat.you_indicator') }})
                      </small>
                    </span>
                  </td>
                  <td :class="{ 'text-muted': participant.id === currentUser.id }" class="flex w-full justify-center">
                    <checkbox v-model="participant.owner" :disabled="participant.id === currentUser.id"></checkbox>
                  </td>
                  <td
                    :class="{ 'text-muted': participant.id === currentUser.id }"
                    class="text-center"
                    style="padding-right: 0"
                  >
                    <button :disabled="participant.id === currentUser.id" @click="removeParticipant(participant)">
                      <i class="material-icons">close</i>
                    </button>
                  </td>
                </tr>
                <!--<infinite-loading></infinite-loading>-->
              </template>
              <template v-else>
                {{ $t('internal_chat.manage_group_modal_no_participants') }}
              </template>
            </table>
          </div>

          <div class="mt-4 flex">
            <button v-if="!thread" :disabled="submitBtnDisabled" class="btn success rounded" @click="createGroup()">
              {{ $t('internal_chat.manage_group_modal_button_create') }}
            </button>
            <button v-if="thread" :disabled="submitBtnDisabled" class="btn success rounded" @click="updateGroup()">
              {{ $t('internal_chat.manage_group_modal_button_update') }}
            </button>
            <button
              v-if="thread && !thread.primary"
              class="btn btn-danger ml-auto mr-4 rounded lg:mr-8"
              @click="deleteGroup()"
            >
              {{ $t('internal_chat.manage_group_modal_button_delete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import eventBus from '@/eventBus';
import { duplicateObject } from '@/util/helpers';

import Avatar from '../../../Avatar';
import Checkbox from '../../../Elements/Checkbox';
import { threads } from '../../Api';
import ThreadModel from '../../Models/ThreadModel';
export default {
  name: 'ManageGroupModal',
  emits: ['close'],
  props: {
    thread: {
      type: Object,
      default: null,
    },
  },
  components: { Checkbox, Multiselect, Avatar },
  data() {
    return {
      record: {
        id: null,
        subject: '',
        participants: [],
      },
      selectedUser: null,
      creating: false,
    };
  },
  beforeMount() {
    if (this.thread) {
      this.record = {
        id: this.thread.id,
        subject: this.thread.subject,
        participants: duplicateObject(this.thread.participants),
      };
    } else {
      this.record.participants = [{ id: this.currentUser.id, owner: true }];
    }
  },
  mounted() {
    if (!is_mobile_device()) {
      this.$refs.subject.focus();
    }
    this.setMultiselectId();
  },
  methods: {
    async createGroup() {
      this.creating = true;

      try {
        const threadModel = await this.createGroupAction(new ThreadModel(this.record));
        this.$emit('close');
        eventBus.$emit('showThreadOverlay');
        setTimeout(() => {
          this.$router.push('/chat/' + threadModel.identifier);
        }, 1);
        this.creating = false;
      } catch (e) {
        this.creating = false;
        if (e === 'maxwait') {
          this.$emit('close');
          console.warn('Timeout creating thread');
          this.flashWarning('Delay in group creation. If the group is not created, try refreshing the page.');
        } else {
          this.$nextTick(() => this.$refs.subject.focus());
        }
      }
    },
    updateGroup() {
      threads
        .update(this.record)
        .then((r) => {
          this.flashSuccess(this.$t('internal_chat.manage_group_modal_flash_updated'));
          this.$emit('close');

          const threadModel = new ThreadModel(this.record);

          if (this.thread.subject !== threadModel.subject) {
            eventBus.$emit('showThreadOverlay');
            setTimeout(() => {
              this.$router.push('/chat/' + threadModel.identifier);
            }, 1);
          }
        })
        .catch((e) => {});
    },
    deleteGroup() {
      if (confirm('Are you sure you want to delete this group? This cannot be undone.')) {
        threads.delete(this.record.id).then((r) => {
          this.setCurrentThread(null);
          this.$router.push('/chat');
          this.flashSuccess(this.$t('internal_chat.manage_group_modal_flash_deleted'));
          this.$emit('close');
        });
      }
    },
    removeParticipant(participant) {
      let index = this.record.participants.indexOf(participant);
      if (index < 0) {
        return;
      }

      this.record.participants.splice(index, 1);
    },
    addParticipant(selectedUser) {
      if (!selectedUser) {
        return;
      }
      this.record.participants.push({
        id: parseInt(selectedUser.id),
        owner: false,
      });
    },
    ...mapActions({
      createGroupAction: 'chat/createGroup',
    }),
    ...mapMutations({
      setCurrentThread: 'chat/setCurrentThread',
      removeThread: 'chat/removeThread',
    }),
    setMultiselectId() {
      const multiselectDiv = document.querySelector('.multiselect');
      multiselectDiv.id = 'user-multi-select';
    },
  },
  computed: {
    usersAvailable() {
      return this.$store.state.usersInternalChat.users.filter(
        (u) => !this.record.participants.find((p) => p.id === u.id)
      );
    },
    submitBtnDisabled() {
      return !stripHtml(this.record.subject).trim();
    },
    ...mapGetters({
      chatStatus: 'chat/chatStatus',
      getUserById: 'usersInternalChat/userById',
      currentUser: 'usersInternalChat/currentUser',
    }),
  },
};
</script>

<style lang="scss">
#user-multi-select .multiselect__option--selected,
#user-multi-select .multiselect__option--highlight {
  &:after {
    @apply flex h-full items-center justify-center p-3;
  }
}
.trengo-modal-group .multiselect__tags {
  border: 2px solid theme('colors.grey-200');
  border-radius: 10px;
}
.trengo-modal-chat {
  .multiselect__content {
    max-width: 360px;
  }
  .multiselect__input {
    color: theme('colors.grey-600');
    font-size: 16px;
  }
}
@media (max-width: 543px) {
  .trengo-modal-chat {
    .multiselect__content {
      max-width: 280px;
    }
  }
}
</style>

<style scoped lang="scss">
body {
  overflow-y: auto;
}

.message-body p:last-of-type {
  margin-bottom: 0;
}

.trengo-modal-chat {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;

  .modal-header {
    border-bottom: 0 !important;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    width: auto;
    display: inline-flex;
    max-width: 100%;
    border-radius: 10px;
    border: none;
    margin: 0;
    max-height: 100%;
    overflow: hidden;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }

    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }

  .form-input {
    border-color: theme('colors.grey-200');
    border-radius: 10px;
  }

  .table {
    display: block;
    height: 360px;
    max-height: 360px;
    padding-right: calc(2rem - 16px);
    th {
      border-color: theme('colors.grey-200');
    }
    td {
      border-top-width: 0;
    }
  }

  .modal-container {
    overflow-y: auto;
  }

  .form-control::placeholder {
    color: theme('colors.grey-600') !important;
    opacity: 0.5 !important;
    font-size: 16px;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.ui-switch {
  margin-right: 0;
}

@media (max-width: 991px) {
  .trengo-modal-chat {
    .table {
      padding-right: calc(1rem - 16px);
    }
  }
}

@media (max-width: 800px) {
  .trengo-modal-chat {
    .scroll-on-hover {
      overflow-y: overlay !important;
      padding-right: 16px;
      &:hover {
        padding-right: 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  .trengo-modal-chat {
    .box {
      width: 100%;
      border-radius: 0;
      max-height: 100%;
    }
  }
}
</style>
