<template>
  <ul class="list">
    <dropdown
      v-for="(participant, index) in filteredThreadParticipants"
      :key="participant.id"
      :static="true"
      :search="false"
      width="auto"
      :max-width="'98%'"
      :auto-height="true"
      :scroll-on-hover="false"
    >
      <template #heading>
        <div
          class="
            _500
            block
            select-none
            py-4
            pr-4
            text-center text-base
            leading-none
            text-black
            lg:pb-2 lg:pt-3 lg:text-left
          "
          style="padding-left: 15px"
        >
          {{ getUserById(participant.id).getDisplayName() }}
          <span v-if="currentUser.id === participant.id">({{ $t('internal_chat.you_indicator') }})</span>
        </div>
      </template>
      <template #toggle>
        <div class="flex items-center" :class="{ 'mt-2': index !== 0 }">
          <div class="relative">
            <div class="overflow-hidden rounded-lg" style="z-index: 9999; width: 32px; height: 32px">
              <avatar
                :color="getUserById(participant.id).color"
                font-size="14px"
                :abbr="getUserById(participant.id).abbr"
                :image="getUserById(participant.id).profileImage"
                width="32"
                class="avatar-left white"
                style="top: 0; left: 0"
              ></avatar>
            </div>
            <online-status
              :status="chatStatus(getUserById(participant.id))"
              :avatar-as-parent="true"
              class="mr-1"
              style="width: 11px; height: 11px; bottom: -2px; right: -4px"
            ></online-status>
          </div>
          <div class="ml-4 leading-none" :class="{ 'font-bold': currentUser.id === participant.id }">
            {{ getUserById(participant.id).getDisplayName() }}
            <span v-if="currentUser.id === participant.id">({{ $t('internal_chat.you_indicator') }})</span>
          </div>
        </div>
      </template>
      <template #body>
        <user-dropdown :participant="participant" class="dropdown-noborder"></user-dropdown>
      </template>
    </dropdown>
    <button
      v-if="thread.participants.length > 3"
      class="btn btn-sm b-a b-2x pointer mt-4 rounded"
      style="color: #74798e"
      @click="$emit('button')"
    >
      {{ $t('internal_chat.sidebar_participants_button_show', { total: thread.participants.length }) }}
    </button>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

import OnlineStatus from './OnlineStatus';
import UserDropdown from './UserDropdown';
import Avatar from '../../Avatar';
import Dropdown from '../../ReplyForm/Dropdown';

export default {
  name: 'ThreadParticipants',
  emits: ['button'],
  components: { OnlineStatus, UserDropdown, Dropdown, Avatar },
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'usersInternalChat/currentUser',
      getUserById: 'usersInternalChat/userById',
      chatStatus: 'chat/chatStatus',
    }),
    filteredThreadParticipants() {
      return this.thread.participants.filter((participant, index) => index < 3 && this.getUserById(participant.id));
    },
  },
};
</script>
