import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/users',
    component: require('../../components/Users/pages/UsersPage').default,
    meta: { permissionRequired: PERMISSION.PERMISSIONS__USERS__MANAGE },
    beforeEnter: hasPermission,
  },
];
