import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { Channel } from '@/types/common';
import type { AxiosPromise } from 'axios';

type ChannelsResponse = {
  data: Channel[];
  links: unknown;
  meta: unknown;
};

export function fetchChannels(): AxiosPromise<ChannelsResponse> {
  return request(ENDPOINT.ME_CHANNELS, 'GET');
}

export function fetchAllChannels(): AxiosPromise<ChannelsResponse> {
  return request(ENDPOINT.CHANNELS, 'GET');
}
