<template>
  <div class="message-container-chat m-0" :class="{ showMenu: showMenu }">
    <emoji-picker
      v-if="!!showEmojiPickerReaction"
      :show-emoji-picker="!!showEmojiPickerReaction"
      :button-ref="showEmojiPickerReaction"
      class="md-20 fixed select-none whitespace-normal text-grey-600"
      :current-user="currentUser"
      @insertEmoji="toggleReaction"
      @hideEmojiPicker="showEmojiPickerReaction = false"
    ></emoji-picker>

    <dropdown
      ref="dropdown_mobile"
      :use-material-icons="false"
      :render-close-button="false"
      :render-toggle-element="false"
      :search="false"
      :current="false"
      width="auto"
      :auto-height="true"
      :scroll-on-hover="false"
      overflow-y="visible"
      :options="dropdownOptionsMobile"
      @selected="dropdownSelect"
      @open="showMenu = true"
      @close="removeFocusFromMessage"
    >
      <template #heading>
        <div class="reaction-emojis-container flex justify-center py-4">
          <div
            v-for="(reaction, index) in favEmojisMobile"
            :key="index"
            class="reaction-emojis flex items-center justify-center"
          >
            <span class="emoji" @click="toggleReaction(reaction)">
              {{ findEmoji(reaction) }}
            </span>
          </div>
          <button
            ref="emojiPickerMobile"
            class="reaction-emojis flex items-center justify-center"
            @click="showReactionPickerMobile"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.1698 0C16.6998 0 16.3188 0.381034 16.3188 0.851064V2.97872H14.1911C13.7211 2.97872 13.34 3.35976 13.34 3.82979C13.34 4.29982 13.7211 4.68085 14.1911 4.68085H16.3188V6.80851C16.3188 7.27854 16.6998 7.65957 17.1698 7.65957C17.6398 7.65957 18.0209 7.27854 18.0209 6.80851V4.68085H20.1485C20.6186 4.68085 20.9996 4.29982 20.9996 3.82979C20.9996 3.35976 20.6186 2.97872 20.1485 2.97872H18.0209V0.851064C18.0209 0.381034 17.6398 0 17.1698 0ZM9.51064 4.68085C5.7504 4.68085 2.70213 7.72913 2.70213 11.4894C2.70213 15.2496 5.7504 18.2979 9.51064 18.2979C13.2709 18.2979 16.3191 15.2496 16.3191 11.4894C16.3191 11.0193 16.7002 10.6383 17.1702 10.6383C17.6402 10.6383 18.0213 11.0193 18.0213 11.4894C18.0213 16.1897 14.2109 20 9.51064 20C4.81034 20 1 16.1897 1 11.4894C1 6.78907 4.81034 2.97872 9.51064 2.97872C9.98067 2.97872 10.3617 3.35976 10.3617 3.82979C10.3617 4.29982 9.98067 4.68085 9.51064 4.68085ZM12.064 9.78715C12.7691 9.78715 13.3406 9.21559 13.3406 8.51055C13.3406 7.80551 12.7691 7.23395 12.064 7.23395C11.359 7.23395 10.7874 7.80551 10.7874 8.51055C10.7874 9.21559 11.359 9.78715 12.064 9.78715ZM6.95807 9.78726C7.66311 9.78726 8.23467 9.21571 8.23467 8.51067C8.23467 7.80563 7.66311 7.23407 6.95807 7.23407C6.25303 7.23407 5.68147 7.80563 5.68147 8.51067C5.68147 9.21571 6.25303 9.78726 6.95807 9.78726ZM5.68104 12.2516C5.68104 12.0656 5.83182 11.9148 6.01783 11.9148H13.0038C13.1898 11.9148 13.3406 12.0656 13.3406 12.2516V12.3403C13.3406 14.4555 11.626 16.1701 9.51083 16.1701C7.39569 16.1701 5.68104 14.4555 5.68104 12.3403V12.2516Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </template>
    </dropdown>

    <div ref="reactionPickerMobile" v-longpress="onLongPress" class="relative mx-2 flex py-2 md:mx-8">
      <!-- Message actions bar -->
      <div
        v-if="!disableOptions && !isMobile"
        v-click-away="removeFocusFromMessage"
        class="message-actions inline-flex"
      >
        <button
          v-for="(reaction, index) in favEmojisDesktop"
          :key="index"
          v-tooltip="{ content: $t('internal_chat.message_dropdown_reaction') }"
          class="message-action-button"
          @click="toggleReaction(reaction)"
        >
          <span class="emoji-reaction-button" style="line-height: 32px; vertical-align: top">
            {{ findEmoji(reaction) }}
          </span>
        </button>
        <button
          ref="reactionPickerDropdown"
          v-tooltip="{ content: $t('internal_chat.message_dropdown_reaction') }"
          class="message-action-button"
          @click="showEmojiPickerReaction = $refs.reactionPickerDropdown"
        >
          <svg
            class="inline-block"
            style="vertical-align: top"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.1698 0C16.6998 0 16.3188 0.381034 16.3188 0.851064V2.97872H14.1911C13.7211 2.97872 13.34 3.35976 13.34 3.82979C13.34 4.29982 13.7211 4.68085 14.1911 4.68085H16.3188V6.80851C16.3188 7.27854 16.6998 7.65957 17.1698 7.65957C17.6398 7.65957 18.0209 7.27854 18.0209 6.80851V4.68085H20.1485C20.6186 4.68085 20.9996 4.29982 20.9996 3.82979C20.9996 3.35976 20.6186 2.97872 20.1485 2.97872H18.0209V0.851064C18.0209 0.381034 17.6398 0 17.1698 0ZM9.51064 4.68085C5.7504 4.68085 2.70213 7.72913 2.70213 11.4894C2.70213 15.2496 5.7504 18.2979 9.51064 18.2979C13.2709 18.2979 16.3191 15.2496 16.3191 11.4894C16.3191 11.0193 16.7002 10.6383 17.1702 10.6383C17.6402 10.6383 18.0213 11.0193 18.0213 11.4894C18.0213 16.1897 14.2109 20 9.51064 20C4.81034 20 1 16.1897 1 11.4894C1 6.78907 4.81034 2.97872 9.51064 2.97872C9.98067 2.97872 10.3617 3.35976 10.3617 3.82979C10.3617 4.29982 9.98067 4.68085 9.51064 4.68085ZM12.064 9.78715C12.7691 9.78715 13.3406 9.21559 13.3406 8.51055C13.3406 7.80551 12.7691 7.23395 12.064 7.23395C11.359 7.23395 10.7874 7.80551 10.7874 8.51055C10.7874 9.21559 11.359 9.78715 12.064 9.78715ZM6.95807 9.78726C7.66311 9.78726 8.23467 9.21571 8.23467 8.51067C8.23467 7.80563 7.66311 7.23407 6.95807 7.23407C6.25303 7.23407 5.68147 7.80563 5.68147 8.51067C5.68147 9.21571 6.25303 9.78726 6.95807 9.78726ZM5.68104 12.2516C5.68104 12.0656 5.83182 11.9148 6.01783 11.9148H13.0038C13.1898 11.9148 13.3406 12.0656 13.3406 12.2516V12.3403C13.3406 14.4555 11.626 16.1701 9.51083 16.1701C7.39569 16.1701 5.68104 14.4555 5.68104 12.3403V12.2516Z"
              fill="black"
            />
          </svg>
        </button>
        <button
          v-tooltip="{ content: $t('internal_chat.message_dropdown_reply') }"
          class="message-action-button"
          @click="replyMessage()"
        >
          <i class="far fa-fw fa-quote-right"></i>
        </button>
        <!--<button class="message-action-button" @click="" v-tooltip="{content: $t('internal_chat.message_dropdown_thread')}">
                    <i class="far fa-fw fa-comment-alt-lines"></i>
                </button>-->
        <button
          v-tooltip="{ content: $t('internal_chat.message_dropdown_share') }"
          class="message-action-button"
          @click="shareMessage()"
        >
          <i class="far fa-fw fa-share"></i>
        </button>
        <button
          ref="emojiPickerDesktopDropdown"
          v-tooltip="{ content: $t('tickets.tooltip_more_options') }"
          class="message-action-button m-0 p-0"
          @click="openDropdown($refs.dropdown_desktop)"
        >
          <dropdown
            ref="dropdown_desktop"
            :use-material-icons="false"
            :render-toggle-element="false"
            :search="false"
            :current="false"
            width="auto"
            :auto-height="true"
            :scroll-on-hover="false"
            overflow-y="visible"
            :options="dropdownOptionsDesktop"
            @selected="dropdownSelect"
            @open="showMenu = true"
            @close="removeFocusFromMessage"
          ></dropdown>
          <i class="far fa-fw fa-ellipsis-v"></i>
        </button>
      </div>

      <!-- Avatar -->
      <avatar
        v-if="isFirstInMessageGroup"
        :color="user.color"
        :abbr="user.abbr"
        :image="user.profileImage"
        :width="40"
        :height="40"
        class="white"
      ></avatar>
      <!-- message time -->
      <div v-else class="message-time select-none" style="min-width: 40px">
        <span class="text-grey-600" style="font-size: 14px">
          {{
            moment
              .utc(message.createdAt * 1000)
              .tz(this.$root.user.timezone)
              .format('LT')
          }}
        </span>
      </div>

      <!-- the message -->
      <div
        class="internal-chat-message relative flex w-full flex-col bg-transparent pl-2"
        style="border-radius: 12px"
        :class="{ 'select-none': isMobile }"
      >
        <!-- show name and time on top -->
        <div v-if="isFirstInMessageGroup" class="inline-flex">
          <strong>{{ message.getUser().getDisplayName() }}</strong>
          <span class="pl-1 text-grey-600" style="font-size: 14px">
            {{
              moment
                .utc(message.createdAt * 1000)
                .tz(this.$root.user.timezone)
                .format('LT')
            }}
          </span>
        </div>

        <!-- Parent Message -->
        <div
          v-if="parentMessage"
          role="button"
          style="border-radius: 12px"
          class="
            chat-parent-message
            relative
            mb-2
            flex
            h-auto
            w-full
            select-none
            flex-row
            overflow-hidden
            bg-grey-100
            p-2
          "
          @click.prevent="goToMessage(parentMessage)"
        >
          <avatar
            :color="getUserById(parentMessage.userId).color"
            :abbr="getUserById(parentMessage.userId).abbr"
            :image="getUserById(parentMessage.userId).profileImage"
            :width="28"
            :height="28"
            class="white"
          ></avatar>
          <div class="mx-2 flex flex-col">
            <!-- name and time -->
            <span class="inline-flex text-grey-500">
              <strong class="reply-title text-ellipsis text-black">
                {{ getUserById(parentMessage.userId).firstName }}
              </strong>
              <span class="reply-date text-ellipsis pl-1 text-grey-600" style="font-size: 14px">
                {{
                  moment
                    .utc(parentMessage.createdAt * 1000)
                    .tz(this.$root.user.timezone)
                    .format('llll')
                }}
              </span>
            </span>

            <!-- parent type gif -->
            <div v-if="parentMessage.bodyType === 'GIF'" class="mt-1">
              <img :src="parentMessage.body" class="gif_image" alt="GIF" @load="$emit('onImageLoaded')" />
            </div>
            <!-- parent message type text -->
            <div v-else>
              <div
                v-if="parentHtml"
                class="chat-parent-message-body text-black text-grey-800"
                style="word-break: break-word"
                v-html="parentHtml"
              ></div>
            </div>

            <!-- parent message attachments -->
            <message-attachments
              v-if="parentMessage.attachments.length"
              :small="true"
              :message="parentMessage"
              :is-parent-message="true"
              class="mt-1"
            ></message-attachments>
          </div>
        </div>

        <!-- Body -->
        <!-- message type GIF -->
        <div v-if="message.bodyType === 'GIF'" class="mt-1">
          <img :src="message.body" class="gif_image" alt="GIF" @load="$emit('onImageLoaded')" />
        </div>
        <!-- message type video call -->
        <div v-else-if="message.bodyType === 'VIDEO_CALL' || message.bodyType === 'VIDEO_CALL_STATUS'">
          <div class="break-words rounded-lg bg-grey-100 p-4">
            <p v-if="message.bodyType === 'VIDEO_CALL'" class="flex flex-col items-start">
              <span class="text-center text-sm font-bold">
                <i class="fas fa-arrow-up rotate-45 transform"></i>
                <span class="pl-2">Call started by {{ initiatorName }}</span>
              </span>
              <span class="text-sm font-medium text-grey-700">
                Started at
                {{
                  moment
                    .utc(message.createdAt * 1000)
                    .tz(this.$root.user.timezone)
                    .format('LT')
                }}
              </span>
              <button
                v-if="isNotTooOld"
                class="
                  reaction
                  mt-2
                  flex
                  select-none
                  whitespace-normal
                  rounded
                  border border-grey-200
                  bg-white
                  px-4
                  font-semibold
                  text-black
                  hover:border-grey-400
                "
                @click="joinButton"
              >
                Join call
              </button>
            </p>
            <p v-if="message.bodyType === 'VIDEO_CALL_STATUS'" class="flex flex-col items-start">
              <span class="text-center text-sm font-bold">
                <i
                  class="fas rotate-45 transform text-error-500"
                  :class="{
                    'fa-arrow-down': this.currentUser.id === message.userId,
                    'fa-arrow-up': this.currentUser.id !== message.userId,
                  }"
                ></i>
                <span class="pl-2 text-error-500">
                  {{ videoRoomParticipantName }} {{ videoRoomParticipantStatus }} this call
                </span>
              </span>
              <span class="text-sm font-medium text-grey-700">
                {{
                  moment
                    .utc(message.createdAt * 1000)
                    .tz(this.$root.user.timezone)
                    .fromNow()
                }}
              </span>
            </p>
          </div>
        </div>
        <!-- message type text -->
        <div v-else>
          <div v-if="html" class="overflow-hidden break-words text-grey-800" v-html="html"></div>
        </div>

        <!-- attachments -->
        <message-attachments
          v-if="message.attachments.length"
          :small="isMobile"
          :message="message"
          :class="{ 'mt-1': !isFirstInMessageGroup }"
        ></message-attachments>

        <!-- reactions on bottom of each message -->
        <div
          v-if="message.reactions.length && !disableOptions"
          class="relative mt-2 flex select-none flex-wrap items-center"
        >
          <button
            v-for="reaction in filteredMessageReactions"
            :key="reaction.emoji"
            v-tooltip="{ content: reactionUsers(reaction), popperClass: 'show-mobile', delay: 100 }"
            class="
              reaction
              mb-1
              mr-1
              flex
              select-none
              whitespace-normal
              rounded
              border border-grey-200
              bg-grey-100
              px-2
              text-black
              hover:border-grey-400
            "
            :class="{ 'my-reaction': message.isMyReaction(reaction.emoji) }"
            @click.stop="toggleReaction(reaction.emoji)"
          >
            <span class="reaction-emoji mr-1 text-sm" style="line-height: 26px">{{ findEmoji(reaction.emoji) }}</span>
            <span style="font-size: 14px; line-height: 26px; font-weight: 500">{{ reaction.users.length || 0 }}</span>
          </button>
          <button
            ref="reactionPickerBottom"
            v-tooltip="{ content: $t('internal_chat.message_dropdown_reaction') }"
            class="
              mb-1
              flex
              items-center
              justify-center
              rounded
              border border-grey-200
              bg-grey-100
              px-1
              text-black
              hover:border-grey-400
            "
            style="width: 36px; height: 28px"
            @click.stop="showEmojiPickerReaction = $refs.reactionPickerBottom"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.1698 0C16.6998 0 16.3188 0.381034 16.3188 0.851064V2.97872H14.1911C13.7211 2.97872 13.34 3.35976 13.34 3.82979C13.34 4.29982 13.7211 4.68085 14.1911 4.68085H16.3188V6.80851C16.3188 7.27854 16.6998 7.65957 17.1698 7.65957C17.6398 7.65957 18.0209 7.27854 18.0209 6.80851V4.68085H20.1485C20.6186 4.68085 20.9996 4.29982 20.9996 3.82979C20.9996 3.35976 20.6186 2.97872 20.1485 2.97872H18.0209V0.851064C18.0209 0.381034 17.6398 0 17.1698 0ZM9.51064 4.68085C5.7504 4.68085 2.70213 7.72913 2.70213 11.4894C2.70213 15.2496 5.7504 18.2979 9.51064 18.2979C13.2709 18.2979 16.3191 15.2496 16.3191 11.4894C16.3191 11.0193 16.7002 10.6383 17.1702 10.6383C17.6402 10.6383 18.0213 11.0193 18.0213 11.4894C18.0213 16.1897 14.2109 20 9.51064 20C4.81034 20 1 16.1897 1 11.4894C1 6.78907 4.81034 2.97872 9.51064 2.97872C9.98067 2.97872 10.3617 3.35976 10.3617 3.82979C10.3617 4.29982 9.98067 4.68085 9.51064 4.68085ZM12.064 9.78715C12.7691 9.78715 13.3406 9.21559 13.3406 8.51055C13.3406 7.80551 12.7691 7.23395 12.064 7.23395C11.359 7.23395 10.7874 7.80551 10.7874 8.51055C10.7874 9.21559 11.359 9.78715 12.064 9.78715ZM6.95807 9.78726C7.66311 9.78726 8.23467 9.21571 8.23467 8.51067C8.23467 7.80563 7.66311 7.23407 6.95807 7.23407C6.25303 7.23407 5.68147 7.80563 5.68147 8.51067C5.68147 9.21571 6.25303 9.78726 6.95807 9.78726ZM5.68104 12.2516C5.68104 12.0656 5.83182 11.9148 6.01783 11.9148H13.0038C13.1898 11.9148 13.3406 12.0656 13.3406 12.2516V12.3403C13.3406 14.4555 11.626 16.1701 9.51083 16.1701C7.39569 16.1701 5.68104 14.4555 5.68104 12.3403V12.2516Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mixin as VueClickAway } from 'vue3-click-away';
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';

import EmojiPicker from './EmojiPicker';
import MessageAttachments from './MessageAttachments';
import VideoCallingMixin from '../../../mixins/VideoCalling';
import Avatar from '../../Avatar';
import Dropdown from '../../ReplyForm/Dropdown';

export default {
  name: 'Message',
  emits: ['onImageLoaded'],
  components: { Dropdown, MessageAttachments, Avatar, EmojiPicker },
  props: {
    message: { default: null, type: Object },
    isFirstInMessageGroup: { default: true, type: Boolean },
    disableOptions: { default: false, type: Boolean },
    isMobile: { default: false, type: Boolean },
  },
  mixins: [VueClickAway, VideoCallingMixin],
  data() {
    return {
      showEmojiPickerReaction: false,
      showMenu: false,
    };
  },
  methods: {
    joinButton() {
      if (this.message.meta?.video_room_id) {
        this.joinTrengoVideoRoom(this.message.meta?.video_room_id);
      }
    },
    onLongPress() {
      if (this.isMobile && !this.disableOptions) {
        this.openDropdown(this.$refs.dropdown_mobile);
      }
    },
    showReactionPickerMobile() {
      this.closeDropdown(this.$refs.dropdown_mobile);
      this.showEmojiPickerReaction = this.$refs.emojiPickerMobile;
    },
    removeFocusFromMessage() {
      this.showMenu = false;
    },
    openDropdown(ref) {
      eventBus.$emit('OPEN_DROPDOWN', ref);
    },
    closeDropdown(ref) {
      eventBus.$emit('CLOSE_DROPDOWN', ref);
    },
    dropdownSelect(item) {
      this.removeFocusFromMessage();
      this.closeDropdown(this.$refs.dropdown_mobile);
      switch (item.id) {
        case 'edit':
          return this.editMessage();
        case 'reply':
          return this.replyMessage();
        case 'delete':
          return this.deleteMessage();
        case 'share':
          return this.shareMessage();
        case 'reaction':
          return (this.showEmojiPickerReaction = this.$refs.emojiPickerDesktopDropdown);
        case 'copy-link':
          return this.copyPermaLink();
        case 'copy-text':
          return this.copyText();
        case 'unread':
          return this.markAsUnread();
      }
    },
    copyText() {
      window.copyToClipboard(window.stripHtml(this.message.getBodyHtmlSanitized()));
    },
    copyPermaLink() {
      console.log('ToDo', this.getPermaLink());
    },
    getPermaLink(message) {
      return '/chat/' + message.getThread().identifier + '/' + message.id;
    },
    markAsUnread() {
      eventBus.$emit('chat@MARK_AS_UNREAD', this.message);
    },
    goToMessage(message) {
      if (message.threadId === this.message.threadId) {
        eventBus.$emit('chat@GO_TO_MESSAGE', message.id);
      }
    },
    editMessage() {
      eventBus.$emit('chat@SHOW_MESSAGE_EDITOR', this.message);
    },
    shareMessage() {
      eventBus.$emit('chat@SHOW_SHARE_MESSAGE', this.message);
    },
    replyMessage() {
      eventBus.$emit('chat@REPLY_MESSAGE', this.message);
    },
    async deleteMessage() {
      const shouldDeleteMessage = await this.$tConfirm(
        'Are you sure you want to delete this message? This cannot be undone.',
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteMessage) {
        this.message.deleteMessage();
      }
    },
    /*callStarMessage() {
                alert('todo');
            },
            callPinMessage() {
                alert('todo');
            },*/
    toggleReaction(emojiCode) {
      // get emojiCode from emoji object returned by emoji-picker
      if (typeof emojiCode === 'object' && emojiCode.emoji) {
        let chars = [];
        for (let i = 0; i < emojiCode.emoji.length; i++) {
          chars.push(emojiCode.emoji.charCodeAt(i));
        }
        emojiCode = chars.join('.');
      }

      // call the toggleReaction on the store
      this.message.toggleReaction(emojiCode);

      eventBus.$emit('chat@REACTION_SENT', { message: this.message, emojiCode });

      // close menus
      this.showEmojiPickerReaction = false;
      this.closeDropdown(this.$refs.dropdown_mobile);
    },
    findEmoji(emoji) {
      return String.fromCharCode(...String(emoji).split('.'));
    },
    reactionUsers(reaction) {
      return reaction.users.map((uId) => this.getUserById(uId).firstName).join(', ');
    },
  },
  computed: {
    filteredMessageReactions() {
      return this.message.reactions.filter((reaction) => reaction.users.length);
    },
    moment: () => moment,
    isNotTooOld() {
      return this.message.createdAt > new Date().getTime() / 1000 - 60 * 60 * 2; // 2 hours
    },
    videoRoomParticipantStatus() {
      if (this.message.bodyType === 'VIDEO_CALL_STATUS' && this.thread.isUser()) {
        return this.message?.meta?.participant_response;
      }
    },
    videoRoomParticipantName() {
      if (this.message?.meta?.participating_id && this.message?.meta?.participating_type === 'user') {
        return this.getUserById(this.message.meta.participating_id)?.name;
      }
    },
    initiatorName() {
      if (this.message?.meta?.initiator_user_id) {
        return this.getUserById(this.message.meta.initiator_user_id)?.name;
      }
    },
    favEmojisMobile() {
      return this.currentUser.favouriteReactions.slice(0, 5);
    },
    favEmojisDesktop() {
      return this.currentUser.favouriteReactions.slice(0, 3);
    },
    dropdownOptionsMobile() {
      let options = [];

      // edit own messages only
      if (this.message.isOutgoing()) {
        options.push({
          id: 'edit',
          icon: 'far fa-pencil-alt',
          text: this.$t('internal_chat.message_dropdown_edit'),
        });
      }

      // can reply everyone
      options.push({
        id: 'reply',
        icon: 'far fa-quote-right',
        text: this.$t('internal_chat.message_dropdown_reply'),
      });

      // copy message
      options.push({
        id: 'copy-text',
        icon: 'far fa-copy',
        text: this.$t('internal_chat.message_dropdown_copy'),
      });

      // mark as unread
      options.push({
        isMaterial: true,
        id: 'unread',
        icon: 'mark_chat_unread',
        text: this.$t('internal_chat.message_dropdown_unread'),
      });

      // delete messages
      if (
        this.message.isOutgoing() ||
        (this.thread.isGroup() &&
          this.thread.participants.find((p) => p.id === this.currentUser.id && p.owner === true))
      ) {
        options.push({
          id: 'delete',
          icon: 'far fa-trash',
          itemClass: 'dropdown-delete-option',
          text: this.$t('internal_chat.message_dropdown_delete'),
        });
      }

      return options;
    },
    dropdownOptionsDesktop() {
      let options = [];

      // mark as unread
      options.push({
        isMaterial: true,
        id: 'unread',
        icon: 'mark_chat_unread',
        text: this.$t('internal_chat.message_dropdown_unread'),
      });

      // edit own messages only
      if (this.message.isOutgoing()) {
        options.push({
          id: 'edit',
          icon: 'far fa-pencil-alt',
          text: this.$t('internal_chat.message_dropdown_edit'),
        });
      }

      // delete messages
      if (
        this.message.isOutgoing() ||
        (this.thread.isGroup() &&
          this.thread.participants.find((p) => p.id === this.currentUser.id && p.owner === true))
      ) {
        options.push({
          id: 'delete',
          icon: 'far fa-trash',
          itemClass: 'dropdown-delete-option',
          text: this.$t('internal_chat.message_dropdown_delete'),
        });
      }

      return options;
    },
    /*isMobile() {
                return document.body.clientWidth <= 991 || window.is_mobile_device();
            },*/
    parentMessage() {
      return this.message.getParentMessage();
    },
    parentHtml() {
      return this.parentMessage?.getBodyHtmlSanitized() || '';
    },
    html() {
      return this.message?.getBodyHtmlSanitized() || '';
    },
    user() {
      return this.message.getUser();
    },
    ...mapGetters({
      currentUser: 'usersInternalChat/currentUser',
      getUserById: 'usersInternalChat/userById',
      thread: 'chat/currentThread',
    }),
  },
};
</script>
<style lang="scss">
.dropdown-delete-option {
  color: var(--color-error-500) !important;
}

.message-container-chat p:last-of-type {
  margin-bottom: 0;
}
.heart-emoji {
  animation: pulse 1.5s infinite;
  // height = font-size (32px) * highest scale (1.2)
  height: 38px;
  width: 32px;
  margin-left: 0.5rem;
  transform: scale(1);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.95);
  }
}
.internal-chat-message {
  ul {
    margin: 0 0 0 0.5rem;
  }
  ol {
    margin: 0 0 0 0.75em;
  }
  li {
    margin-left: 0;
  }
}
.chat-parent-message-body {
  ol,
  ul {
    display: inline-block;
  }
}
</style>
<style scoped lang="scss">
.reaction-emojis-container {
  border-bottom: solid 1px;
  border-color: #eaeaea;
}
.reaction-emojis {
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 12px;
  margin-left: 7px;
  margin-right: 7px;
}

.reaction-emoji {
  font-size: 12px;
  width: 20px;
  margin-left: -4px;
  margin-top: 1px;
}
.emoji-reaction-button {
  font-size: 18px;
  margin-top: 2px;
  margin-left: -4px;
}

// Only for Retina screens
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  // Retina emojis are smaller
  .reaction-emoji {
    font-size: 18px;
    margin-left: 0;
  }
  .emoji-reaction-button {
    font-size: 20px;
    margin-left: -1px;
  }
}

.chat-parent-message-body {
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chat-messages-container .message-container-chat {
  line-height: 24px;

  &:first-of-type {
    margin-top: 0.75rem;
  }

  .message-time,
  .message-actions {
    visibility: hidden;
  }

  .message-actions {
    right: 100px;

    z-index: 10;
    color: var(--color-leaf-900);
    position: absolute;
    background: var(--color-white);
    border: 1px solid var(--color-grey-200);
    padding: 8px 6px;
    height: 48px;
    top: -24px;
    border-radius: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.1);

    .message-action-button {
      color: var(--color-leaf-900);
      margin: 0 2px;
      background: var(--color-white);
      width: 32px;
      height: 32px;
      border-radius: 4px;

      &:hover,
      &:active,
      &:focus {
        background: var(--color-grey-100);
      }
    }
  }

  &:hover,
  &.showMenu {
    background-color: var(--color-grey-100);

    .message-time,
    .message-actions {
      visibility: visible;
    }
  }
}

.reaction {
  height: 28px;
  line-height: 26px;
  &.my-reaction {
    border-color: var(--color-leaf-500);
    background-color: var(--color-leaf-100);
    &:hover {
      border-color: var(--color-grey-400);
      background-color: var(--color-white);
    }
  }
}

.message-container-chat {
  .hover-message-container-chat {
    transition: opacity 100ms ease;
    visibility: hidden;
    opacity: 0;
  }
  &:hover .hover-message-container-chat {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .message-container-chat {
    .hover-message-container-chat {
      opacity: 1;
      visibility: visible;
    }
    .dropdown-menu-right {
      left: 0;
    }
    .dropdown-menu-left {
      right: 0;
      left: auto;
    }
  }
  .internal-chat-message {
    width: calc(100% - 40px);
  }
  .reply-title,
  .reply-date {
    max-width: 100px;
  }
}

/*@media (max-width:543px) {
        .message-container-chat {
            .chat-parent-message {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }*/
</style>
