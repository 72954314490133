<template>
  <div class="row-col">
    <div class="row-row">
      <div class="row-body scrollable">
        <div ref="scrollableContainer" class="row-inner">
          <div class="p-a p-b-0 container min-h-full bg-white">
            <div class="p-a">
              <div>
                <router-link :to="'/admin/channels2/wa_business'" class="mb-6 ml-2 flex items-center">
                  <img
                    class="mr-2 inline-block h-3"
                    :src="`${$root.assetsURL}img/action_icons/icon-arrow-left.svg`"
                    alt="back"
                  />
                  <span class="mx-2 font-semibold">Back to overview</span>
                </router-link>
                <h1 class="mb-8 font-bold">Connect your account</h1>
                <template v-if="!clickedConnectAccount">
                  <div class="mb-6 rounded-xl bg-galaxy-100 p-8">
                    <div class="flex flex-col md:flex-row">
                      <div class="w-10 flex-shrink-0"><i class="far fa-info-circle" /></div>
                      <div>
                        <div class="font-bold">If you’re currently using this number</div>
                        <div class="mt-2">Please temporarily disconnect your number from the WhatsApp App</div>
                        <div>
                          <atomic-button btn-style="secondary" size="sm" class="mt-4" @click="openLearnHowDisconnectWa">
                            Learn how
                          </atomic-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-6 rounded-xl bg-galaxy-100 p-8">
                    <div class="flex flex-col md:flex-row">
                      <div class="w-10 flex-shrink-0"><i class="far fa-info-circle"></i></div>
                      <div>
                        <div class="font-bold">Our partner won’t bill you for extra costs</div>
                        <div class="mt-2 text-grey-800">
                          During the registration process, you will come across a 'Billing Management' permission field.
                          <span class="font-semibold">Don’t worry!</span>
                          This is included in our pricing.
                        </div>
                      </div>
                      <div class="mr-8 hidden md:flex">
                        <div>
                          <div
                            class="relative flex h-8 w-8 items-center rounded-full bg-error-100"
                            style="
                              left: 50%;
                              top: 60%;
                              box-shadow: 0px 22px 80px rgba(114, 11, 24, 0.07),
                                0px 10.1713px 36.9864px rgba(114, 11, 24, 0.0519173),
                                0px 5.81977px 21.1628px rgba(114, 11, 24, 0.0438747),
                                0px 3.53256px 12.8457px rgba(114, 11, 24, 0.0377964),
                                0px 2.12852px 7.74008px rgba(114, 11, 24, 0.0322036),
                                0px 1.1853px 4.31018px rgba(114, 11, 24, 0.0261253),
                                0px 0.509789px 1.85378px rgba(114, 11, 24, 0.0180827);
                            "
                          >
                            <i class="fas fa-times mx-auto text-error-700" />
                          </div>
                        </div>
                        <div
                          style="
                            box-shadow: 0px 22px 80px rgba(114, 11, 24, 0.07),
                              0px 10.1713px 36.9864px rgba(114, 11, 24, 0.0519173),
                              0px 5.81977px 21.1628px rgba(114, 11, 24, 0.0438747),
                              0px 3.53256px 12.8457px rgba(114, 11, 24, 0.0377964),
                              0px 2.12852px 7.74008px rgba(114, 11, 24, 0.0322036),
                              0px 1.1853px 4.31018px rgba(114, 11, 24, 0.0261253),
                              0px 0.509789px 1.85378px rgba(114, 11, 24, 0.0180827);
                          "
                        >
                          <img :src="`${$root.assetsURL}img/wabusiness/360_billing_warning.png`" />
                        </div>
                      </div>
                    </div>
                    <div class="rounded-xl bg-galaxy-100 p-8">
                      <div class="flex flex-col md:flex-row">
                        <div class="w-10 flex-shrink-0"><i class="far fa-info-circle"></i></div>
                        <div>
                          <div class="font-bold">Copy the generated API key into Trengo</div>
                          <div class="mt-2 text-grey-800">
                            After the registration process, generate an API key in 360 dialog's dashboard, and copy the
                            API key and address into Trengo below.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="mb-4 mt-8">
                <atomic-button @click="connectYourAccount">Connect your account</atomic-button>
              </div>
              <hr class="my-12 border-grey-300" />
              <form @submit.prevent="createChannel()">
                <div :class="{ 'opacity-25': !clickedConnectAccount }">
                  <div class="my-8">
                    <h4>Link your API key</h4>
                    <p>Paste your new API key that was generated with 360dialog.io</p>
                    <!--                                        TODO update URL once HC article created -->
                    <!--                                        <p>-->
                    <!--                                            <a href="https://help.trengo.com" target="_blank" rel="noopener"-->
                    <!--                                               class="font-bold hover:underline inline-block">Where can I find my API key?<i-->
                    <!--                                                class="fas fa-chevron-right ml-2"/></a>-->
                    <!--                                        </p>-->
                  </div>
                  <div class="my-2">
                    <p class="font-semibold">API key</p>
                    <div>
                      <atomic-input v-model="record.whatsappChannel.meta.api_key" place-holder="e.g. 434iuwnsdfksdkx" />
                    </div>
                  </div>
                  <div class="my-2">
                    <p class="font-semibold">API address</p>
                    <div>
                      <atomic-input
                        v-model="record.whatsappChannel.meta.api_address"
                        placeholder="e.g. https://waba.360dialog.io"
                      />
                    </div>
                  </div>
                  <div class="mb-10">
                    <atomic-button
                      :disabled="
                        !record.whatsappChannel.meta.api_key || !record.whatsappChannel.meta.api_address || saving
                      "
                      :class="{ loader: saving }"
                      size="lg"
                      class="my-4"
                      type="submit"
                      @click="createChannel"
                    >
                      Submit
                    </atomic-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <channel-created-modal :route="redirect_url" :badge="badge" />
  </div>
</template>

<script>
import ChannelCreatedModal from '@/components/Channels/ChannelCreatedModal';
import { CHANNEL_TYPE } from '@/Configs/Constants';

import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';

export default {
  name: 'Create360Dialog',
  components: { ChannelCreatedModal },
  mixins: [RouterWarningOnEdit],
  data() {
    return {
      channel: null,
      saving: false,
      clickedConnectAccount: false,
      redirect_url: '',
      record: {
        type: this.$route.params.channel.toUpperCase(),
        notification_email: '',
        notification_sound: 'chat.mp3',
        username: '',
        password: '',
        password_is_null: false,
        title: this.$route.params.channel,
        color: null,
        teams: [],
        users: [],
        reopen_closed_ticket: 1,
        business_hour_id: null,
        is_private: false,
        reassign_reopened_ticket: false,
        reopen_closed_ticket_time_window_days: 30,
        whatsappChannel: {
          meta: {
            api_key: null,
            api_address: 'https://waba.360dialog.io',
          },
          sms_channel_id: null,
          provider: '360dialog',
        },
      },
    };
  },
  computed: {
    badge() {
      return CHANNEL_TYPE.WHATSAPP;
    },
  },

  mounted() {
    this.channel = this.$route.params.type;
  },

  methods: {
    connectYourAccount() {
      this.clickedConnectAccount = true;

      const email = encodeURIComponent(this.$root.user.email);
      const fullName = encodeURIComponent(this.$root.user.full_name);
      const companyName = encodeURIComponent(this.$root.companyProfile.profile.company_name);
      window.open(
        `https://hub.360dialog.com/lp/whatsapp/J23j2RPA?email=${email}&fullName=${fullName}&companyName=${companyName}`,
        '_blank',
        'noopener'
      );
    },
    async save() {
      if (this.saving || this.uploading) {
        return;
      }

      this.saving = true;

      if (this.$refs.channelAction && this.$refs.channelAction.beforeSave) {
        await this.$refs.channelAction.beforeSave();
      }

      if (this.action === 'create') {
        return this.createChannel();
      } else {
        this.updateChannel();
      }
    },

    createChannel() {
      this.saving = true;
      axios
        .post('/api/v2/channels', {
          ...this.record,
          ...{
            team_ids: this.mapTeamIds(),
            user_ids: [],
          },
        })
        .then((res) => {
          this.saving = false;
          this.initWarning(false);
          //set redirect url & open show created modal
          this.redirect_url = '/admin/channels2/' + this.record.type.toLowerCase() + '/' + res.data.id;
          if (this.$root.isInOnboardingFlow) {
            $('#channel-created-modal').modal('show');
          } else {
            router.push('/admin/channels2/' + this.record.type.toLowerCase() + '/' + res.data.id);
            this.flashSuccess('The channel has been created successfully.');
          }
          // this.onSaved();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    mapTeamIds() {
      return this.$store.getters['initialData/user'].teams.map((i) => i.id);
    },

    openLearnHowDisconnectWa() {
      window.open('https://faq.whatsapp.com/en/android/21119703/', '_blank', 'noopener');
    },
  },
};
</script>
