<script type="text/babel">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';

import { fetchCompanyProfile, updateAgency, setCompanyProfileSettings, setCompanyProfileSupport } from '@/api';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { FEATURE } from '@/Configs/Constants/Feature';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import { useEntitlementsStore, useFeatureFlagStore } from '@/store/pinia';

export default {
  components: { ArrowTopCircleLinear },
  mixins: [RouterWarningOnEdit],
  data() {
    return {
      FEATURE,
      isSaving: false,
      record: {
        agency: {
          locale_code: '',
          mfa_is_mandatory: false,
          beta_enrollment: false,
        },
        settings: {
          email_notify_ticket_assigned: 0,
          set_offline_status_at_idle: 0,
          require_ticket_label: 0,
        },
        profile: {
          admin_access_granted: false,
        },
      },
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitledToM2FA() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.ACCOUNT__AUTHENTICATION__MANDATORY_MFA);
    },
    requiredPlanForM2FA() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.ACCOUNT__AUTHENTICATION__MANDATORY_MFA);
    },
    isBetaProgramEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.BETA_PROGRAM);
    },
    adminAccessGranted: {
      // return true;
      get() {
        return this.record.profile.admin_access_granted;
      },
      set(value) {
        this.record.profile.admin_access_granted = value;
      },
    },
  },
  mounted() {
    this.loadAccountSettings();
  },
  methods: {
    loadAccountSettings() {
      fetchCompanyProfile()
        .then(({ data: { agency, settings, profile } }) => {
          const record = {
            agency: {
              locale_code: agency.locale_code,
              mfa_is_mandatory: agency.mfa_is_mandatory,
              beta_enrollment: agency.beta_enrollment,
            },
            settings: {
              email_notify_ticket_assigned: settings.email_notify_ticket_assigned,
              set_offline_status_at_idle: settings.set_offline_status_at_idle,
              require_ticket_label: settings.require_ticket_label,
            },
            profile: {
              admin_access_granted: !!profile.admin_access_granted,
            },
          };
          this.record = record;
          this.isLoaded = true;
          this.initWarning();
        })
        .catch((err) => {
          console.error(err);
          this.isLoaded = true;
        });
    },
    refreshState() {
      this.initWarning();
      this.loadAccountSettings();
      this.flashSuccess(this.$t('company_profile.notification_settings_update_success'));
    },

    async save() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      let data = {
        locale_code: this.record.agency.locale_code,
        email_notify_ticket_assigned: this.record.settings.email_notify_ticket_assigned,
        set_offline_status_at_idle: this.record.settings.set_offline_status_at_idle,
        require_ticket_label: this.record.settings.require_ticket_label,
      };
      await setCompanyProfileSettings(data);
      this.isSaving = false;
      this.refreshState();
    },

    async saveSecurity() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      await updateAgency({
        mfa_is_mandatory: this.record.agency.mfa_is_mandatory,
      });
      this.refreshState();
      this.isSaving = false;
    },

    async saveBeta() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      await updateAgency({
        beta_enrollment: this.record.agency.beta_enrollment,
      })
        .then(() => {
          this.refreshState();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    async saveSupport() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      await setCompanyProfileSupport({
        access_granted: this.record.profile.admin_access_granted ? 1 : 0,
      });
      this.refreshState();
      this.isSaving = false;
    },
    handleUpgradePlan() {
      this.$router.push({ name: 'subscription' });
    },
  },
};
</script>

<template>
  <div class="row-col" style="display: block">
    <div class="row-body">
      <div class="row-inner">
        <div class="p-a container">
          <form class="form-horizontal form-bordered" @submit.prevent="save">
            <div class="box">
              <div class="box-header b-b">
                <h2>{{ $t('company_profile.subscription_page_account_settings') }}</h2>
              </div>
              <div class="box-body">
                <div class="form-group row">
                  <label for="languagePreferenceSelect" class="col-md-3 form-control-label">
                    {{ $t('company_profile.subscription_page_language_preference') }}
                  </label>
                  <div class="col-md-9">
                    <select
                      id="languagePreferenceSelect"
                      v-model="record.agency.locale_code"
                      class="form-control"
                      name="locale_code"
                    >
                      <option value="nl-NL">{{ $t('company_profile.subscription_page_dutch') }}</option>
                      <option value="en-GB">{{ $t('company_profile.subscription_page_english') }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="emailNotificationSelect" class="col-md-3 form-control-label">
                    {{ $t('company_profile.subscription_page_assignment_email_notifications') }}
                  </label>
                  <div class="col-md-9">
                    <select
                      id="emailNotificationSelect"
                      v-model="record.settings.email_notify_ticket_assigned"
                      class="form-control"
                    >
                      <option :value="0">{{ $t('company_profile.subscription_page_no') }}</option>
                      <option :value="1">{{ $t('company_profile.subscription_page_yes') }}</option>
                    </select>
                    <span class="form-text text-muted">
                      {{
                        $t(
                          'company_profile.subscription_page_an_e_mail_will_be_sent_when_a_ticket_is_assigned_to_a_user'
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="autoOfflineStatusSelect" class="col-md-3 form-control-label">
                    {{ $t('company_profile.subscription_page_auto_offline_status') }}
                  </label>
                  <div class="col-md-9">
                    <select
                      id="autoOfflineStatusSelect"
                      v-model="record.settings.set_offline_status_at_idle"
                      class="form-control"
                    >
                      <option :value="0">{{ $t('company_profile.subscription_page_no') }}</option>
                      <option :value="1">{{ $t('company_profile.subscription_page_yes') }}</option>
                    </select>
                    <span class="form-text text-muted">
                      {{ $t('company_profile.subscription_page_users_will_be_automatically_set_to_offline') }}
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="ticketLabelRequiementSelect" class="col-md-3 form-control-label">
                    {{ $t('company_profile.subscription_page_tickets_require_a_label') }}
                  </label>
                  <div class="col-md-9">
                    <select
                      id="ticketLabelRequiementSelect"
                      v-model="record.settings.require_ticket_label"
                      class="form-control"
                    >
                      <option :value="0">{{ $t('company_profile.subscription_page_no') }}</option>
                      <option :value="1">{{ $t('company_profile.subscription_page_yes') }}</option>
                    </select>
                    <span class="form-text text-muted">
                      {{
                        $t(
                          'company_profile.subscription_page_require_tickets_to_have_at_least_1_label_in_order_to_close_them'
                        )
                      }}
                    </span>
                  </div>
                </div>

                <t-button type="submit" class="btn success text-white" :class="{ loader: isSaving }">
                  {{ $t('company_profile.subscription_page_save') }}
                </t-button>
              </div>
            </div>
          </form>

          <form action="" @submit.prevent="saveSecurity">
            <div class="box">
              <div class="box-header b-b flex items-center gap-2">
                <h2>{{ $t('company_profile.account_settings_security_title') }}</h2>
                <t-badge
                  v-if="!isEntitledToM2FA"
                  v-tooltip="{
                    placement: 'top',
                    content: this.$t('entitlement.tooltip_account__authentication__mandatory_mfa', {
                      plan: requiredPlanForM2FA,
                    }),
                  }"
                  :text="requiredPlanForM2FA"
                  variant="upgrade"
                  class="cursor-default"
                  data-test="entitlement-badge"
                >
                  <template #icon><arrow-top-circle-linear size="1rem" /></template>
                </t-badge>
              </div>
              <div class="box-body">
                <p class="mb-6">
                  {{ $t('company_profile.account_settings_security_description') }}
                </p>
                <t-input-checkbox
                  v-model="record.agency.mfa_is_mandatory"
                  :disabled="!isEntitledToM2FA"
                  class="my-6"
                  name="mfa_is_mandatory"
                >
                  {{ $t('company_profile.account_settings_security_mandatory_2fa_input_label') }}
                </t-input-checkbox>
                <t-button
                  v-if="isEntitledToM2FA"
                  type="submit"
                  class="btn success text-white"
                  :class="{ loader: isSaving }"
                >
                  {{ $t('general.save') }}
                </t-button>
                <t-button
                  v-else
                  btn-style="secondary"
                  type="button"
                  class="flex items-center gap-1"
                  @click="handleUpgradePlan"
                >
                  <arrow-top-circle-linear size="1rem" />
                  {{ $t('entitlement.upgrade_to_tier3_legacy') }}
                </t-button>
              </div>
            </div>
          </form>

          <form v-if="isBetaProgramEnabled" @submit.prevent="saveBeta">
            <div class="box">
              <div class="box-header b-b flex items-center gap-2">
                <h2>{{ $t('company_profile.account_settings_beta_title') }}</h2>
              </div>
              <div class="box-body">
                <p class="mb-6">
                  {{ $t('company_profile.account_settings_beta_description') }}
                </p>
                <t-input-checkbox v-model="record.agency.beta_enrollment" name="beta_enrollment" class="my-6">
                  {{ $t('company_profile.account_settings_beta_signup_input_label') }}
                </t-input-checkbox>
                <t-button type="submit" class="btn success text-white" :class="{ loader: isSaving }">
                  {{ $t('general.save') }}
                </t-button>
              </div>
            </div>
          </form>

          <form action="" @submit.prevent="saveSupport">
            <div class="box">
              <div class="box-header b-b">
                <h2>{{ $t('company_profile.subscription_page_support') }}</h2>
              </div>
              <div class="box-body">
                <p>
                  {{
                    $t(
                      'company_profile.subscription_page_by_default_the_trengo_team_does_not_have_access_to_your_account_in_order_to_assist_you'
                    )
                  }}
                </p>
                <t-input-checkbox v-model="adminAccessGranted" name="access_granted" class="my-6">
                  {{ $t('company_profile.subscription_page_yes_i_grant_the_trengo_team_permission_to_my_account') }}
                </t-input-checkbox>

                <t-button type="submit" class="btn success block text-white" :class="{ loader: isSaving }">
                  {{ $t('general.save') }}
                </t-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
