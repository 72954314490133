export const SETTINGS_URL = {
  ADMIN: '/admin',
  ADMIN__ACCOUNT_SETTINGS: '/admin/account_settings',
  ADMIN__APPS_INTEGRATIONS: '/admin/developers',
  ADMIN__APPS_INTEGRATION_HUB: '/admin/integrations',
  ADMIN__APPS_INTEGRATIONS__PLUGINS: '/admin/developers/plugins',
  ADMIN__APPS_INTEGRATIONS__REST: '/admin/developers/rest',
  ADMIN__APPS_INTEGRATIONS__WEBHOOKS: '/admin/developers/webhooks',
  ADMIN__APPS_INTEGRATIONS__ZAPIER: '/admin/developers/zapier',
  ADMIN__AUTO_REPLIES: '/admin/auto_replies',
  ADMIN__BALANCE_SETTINGS: '/admin/balance_settings',
  ADMIN__BUSINESS_HOURS: '/admin/business_hours',
  ADMIN__BUSINESS_HOURS__CREATE: '/admin/business_hours/create',
  ADMIN__CHANNELS2: '/admin/channels2',
  ADMIN__CHANNELS2_CHAT: '/admin/channels2/chat',
  ADMIN__CHANNELS2_CUSTOM: '/admin/channels2/custom',
  ADMIN__CHANNELS2_EMAIL: '/admin/channels2/email',
  ADMIN__CHANNELS2_FACEBOOK: '/admin/channels2/facebook',
  ADMIN__CHANNELS2_GBM: '/admin/channels2/gbm',
  ADMIN__CHANNELS2_INSTAGRAM: '/admin/channels2/instagram',
  ADMIN__CHANNELS2_SMS: '/admin/channels2/sms',
  ADMIN__CHANNELS2_TELEGRAM: '/admin/channels2/telegram',
  ADMIN__CHANNELS2_VOIP: '/admin/channels2/voip',
  ADMIN__CHANNELS2_WA_BUSINESS: '/admin/channels2/wa_business',
  ADMIN__CHATBOTS: '/admin/chatbots',
  ADMIN__CONTACT_GROUPS: '/admin/contact_groups',
  ADMIN__CSAT: '/admin/csat',
  ADMIN__CUSTOM_FIELDS: '/admin/custom_fields',
  ADMIN__CUSTOM_VIEWS: '/admin/custom_views',
  ADMIN__FLOWBOTS: '/admin/flows',
  ADMIN__LABELS: '/admin/labels',
  ADMIN__LOCATIONS: '/admin/locations',
  ADMIN__LOCATIONS__CREATE: '/admin/locations/create',
  ADMIN__QUICK_REPLIES: '/admin/quick_replies',
  ADMIN__RULES: '/admin/rules',
  ADMIN__SUBSCRIPTION_SETTINGS: '/admin/subscription_settings',
  ADMIN__TEAMS: '/admin/teams',
  ADMIN__TICKET_RESULTS: '/admin/ticket_results',
  ADMIN__TRANSACTIONS: '/admin/transactions',
  ADMIN__USAGE_OVERVIEW: '/admin/usage_overview',
  ADMIN__USERS: '/admin/users',
  ADMIN__WEB_WIDGETS: '/admin/web_widgets',
  ADMIN__WIDGET_AUTOMATION: '/admin/widget_automation',
} as const;
