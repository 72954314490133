import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8df63a48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setup-password" }
const _hoisted_2 = { class: "setup-password__content" }
const _hoisted_3 = { class: "setup-password__header" }
const _hoisted_4 = { class: "setup-password__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_header = _resolveComponent("logo-header")!
  const _component_key_linear = _resolveComponent("key-linear")!
  const _component_t_thumbnail = _resolveComponent("t-thumbnail")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_help_center_button = _resolveComponent("help-center-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_logo_header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_t_thumbnail, {
          class: "mx-auto mb-6",
          size: "large"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_key_linear)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('login.setup_password_headline')) + ", " + _toDisplayString(_ctx.getUserFirstName) + "!", 1 /* TEXT */),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('login.setup_password_subheadline')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          method: "POST",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
        }, [
          _createVNode(_component_t_input_text, {
            id: "setupPassword",
            ref: "password",
            modelValue: _ctx.form.password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
            "has-error": !_ctx.isPasswordValid,
            type: _ctx.isPasswordVisible ? 'text' : 'password',
            label: _ctx.$t('general.password'),
            placeholder: _ctx.$t('general.choose_password'),
            "sub-label": _ctx.$t('login.setup_password_input_sublabel')
          }, {
            end: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.passwordIcon), {
                class: "setup-password__form-icon",
                size: "1.25rem",
                onClick: _ctx.togglePassword
              }, null, 8 /* PROPS */, ["onClick"])), [
                [
                  _directive_tooltip,
                  { placement: 'top', content: _ctx.passwordTooltip, delay: { show: 200, hide: 0 } },
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "has-error", "type", "label", "placeholder", "sub-label"]),
          (!_ctx.isPasswordValid)
            ? (_openBlock(), _createBlock(_component_t_error_item, {
                key: 0,
                text: _ctx.$t('login.validation_message_length')
              }, null, 8 /* PROPS */, ["text"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_t_input_text, {
            id: "setupPasswordConfirm",
            ref: "confirm",
            modelValue: _ctx.form.password_confirmation,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password_confirmation) = $event)),
            "has-error": !_ctx.isPasswordConfirmValid,
            type: _ctx.isPasswordConfirmVisible ? 'text' : 'password',
            class: "mt-4",
            label: _ctx.$t('general.confirm_password'),
            placeholder: _ctx.$t('general.repeat_password')
          }, {
            end: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.confirmIcon), {
                class: "setup-password__form-icon",
                size: "1.25rem",
                onClick: _ctx.togglePasswordConfirm
              }, null, 8 /* PROPS */, ["onClick"])), [
                [
                  _directive_tooltip,
                  { placement: 'top', content: _ctx.confirmTooltip, delay: { show: 200, hide: 0 } },
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "has-error", "type", "label", "placeholder"]),
          (!_ctx.isPasswordConfirmValid)
            ? (_openBlock(), _createBlock(_component_t_error_item, {
                key: 1,
                text: _ctx.$t('login.validation_message_password_match')
              }, null, 8 /* PROPS */, ["text"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_t_button, {
            "btn-style": "primary",
            class: "setup-password__form-action"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.create_account')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ], 32 /* NEED_HYDRATION */)
      ])
    ]),
    _createVNode(_component_help_center_button)
  ]))
}