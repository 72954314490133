<template v-if="getUserById(participant.id)">
  <div class="select-none">
    <a class="dropdown-item text-ellipsis" @click="goToParticipant()">
      {{ $t('internal_chat.user_dropdown_private_message') }}
    </a>
    <a class="dropdown-item text-ellipsis" @click="mention(getUserById(participant.id))">
      {{ $t('internal_chat.user_dropdown_mention') }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';

export default {
  name: 'UserDropdown',
  props: {
    participant: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    mention(user) {
      eventBus.$emit(
        'chat@INSERT_COMPOSER_MESSAGE',
        '<span class="fr-deletable fr-tribute text-green" contenteditable="false">' +
          '@' +
          user.username +
          '</span>&nbsp;'
      );
      if (is_mobile_device()) {
        this.hideSidebar();
      }
    },
    goToParticipant() {
      let identifier = this.getUserById(this.participant.id).identifier;
      eventBus.$emit('showThreadOverlay');
      setTimeout(() => {
        this.$router.push('/chat/' + identifier);
      }, 1);
      if (is_mobile_device()) {
        this.hideSidebar();
      }
    },
    hideSidebar() {
      eventBus.$emit('chat@HIDE_SIDEBAR');
    },
  },
  computed: {
    ...mapGetters({
      getUserById: 'usersInternalChat/userById',
    }),
  },
};
</script>
