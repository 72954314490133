<template>
  <div>
    <div class="mb-6">
      <div class="flex flex-col content-end">
        <div class="mb-4 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_name') }}
          </span>
          <span class="text-sm font-medium text-grey-700">
            {{ $t('company_profile.subscription_page_name_this_set_of_business_hours') }}
          </span>
        </div>
        <div class="w-full">
          <input
            v-model="localRecord.name"
            class="h-12 w-full rounded-xl border border-grey-200 px-4 py-2"
            placeholder="e.g. Default opening hours"
            type="text"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
    <div class="mb-6">
      <div class="flex flex-col content-end">
        <div class="mb-1 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_timezone') }}
          </span>
        </div>
        <div class="w-full">
          <t-dropdown
            v-model="localRecord.timezone"
            :items="timezones.map((item) => ({ value: item, text: item }))"
          ></t-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-6">
      <div class="flex flex-col content-end">
        <div class="mb-4 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_channels') }}
          </span>
          <span class="text-sm font-medium text-grey-700">
            {{ $t('company_profile.subscription_page_select_the_applicable_channels_for_these_business_hours') }}
          </span>
        </div>
        <div class="w-full">
          <t-multi-select
            v-model="computedChannelModel"
            :use-icon="true"
            :placeholder="$t('company_profile.subscription_page_placeholder_search_for_channels')"
            size="md"
            container-size="md"
            variant="sky"
            :height="300"
            :has-avatar="false"
            :data="computedChannels"
          ></t-multi-select>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <div class="flex flex-col content-end">
        <div class="mb-4 flex flex-col">
          <span class="mb-1 text-base font-semibold text-grey-900">
            {{ $t('company_profile.subscription_page_description') }}
          </span>
          <span class="text-sm font-medium text-grey-700">
            {{ $t('company_profile.subscription_page_a_description_for_the_business_hours') }}
          </span>
        </div>
        <div class="w-full">
          <input
            v-model="localRecord.description"
            :placeholder="$t('company_profile.subscription_page_placeholder_eg_summer_schedule')"
            class="h-12 w-full rounded-xl border border-grey-200 px-4 py-2"
            type="text"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { ChannelsIconsMapper } from '@/util/ChannelsUtils';

export default {
  name: 'FirstStep',
  emits: ['change'],
  methods: {
    handleChangeChannels(value) {
      this.localRecord.channels = value;
      this.emitInput(this.localRecord);
    },
    getTimezone(v) {
      this.localRecord.timezone = v;
    },
    emitInput(record) {
      this.$emit('change', record);
    },
    mapChannels(list) {
      const channels_svg = ChannelsIconsMapper();
      return list && list.map((item) => ({ ...item, name: item.text, icon: channels_svg[item.type] }));
    },
  },
  watch: {
    localRecord: {
      handler(value) {
        this.emitInput(value);
      },
      deep: true,
    },
  },
  data() {
    return {
      localRecord: {},
    };
  },
  mounted() {
    this.localRecord = cloneDeep(this.record);
  },
  props: {
    timezones: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: () => ({}),
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    computedChannelModel: {
      get() {
        return this.mapChannels(this.localRecord.channels);
      },
      set(value) {
        this.localRecord.channels = value;
        this.emitInput(this.localRecord);
      },
    },
    computedChannels() {
      return Object.keys(this.channels) ? this.mapChannels(this.channels) : false;
    },
  },
};
</script>
