import { jwtDecode } from 'jwt-decode';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchLogInToken } from '@/api/modules/auth';

import type { JwtPayload } from 'jwt-decode';

const validateToken = (token: string | null): token is string => {
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode<JwtPayload>(token);
  switch (true) {
    case !decodedToken || !decodedToken.exp:
    case decodedToken.exp && Date.now() >= decodedToken.exp * 1000:
      return false;
    default: {
      return true;
    }
  }
};

const getBearerToken = () =>
  axios.defaults?.headers?.common?.authorization?.startsWith('Bearer ')
    ? axios.defaults.headers.common.authorization.split('Bearer ')[1]
    : '';
const setBearerToken = (token: string) => {
  axios.defaults.headers.common.authorization = `Bearer ${token}`;
};
const removeBearerToken = () => {
  axios.defaults.headers.common.authorization = '';
};

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false);

  async function init() {
    const token = getBearerToken();
    if (validateToken(token)) {
      setBearerToken(token);
      isAuthenticated.value = true;
      return Promise.resolve();
    } else {
      return requestAuthentication();
    }
  }

  async function requestAuthentication() {
    try {
      const { data } = await fetchLogInToken();
      setBearerToken(data.access_token);
      isAuthenticated.value = true;
    } catch (err) {
      logout();
    }
  }

  function logout() {
    removeBearerToken();
    isAuthenticated.value = false;
  }

  return {
    requestAuthentication,
    init,
    isAuthenticated,
    logout,
  };
});
