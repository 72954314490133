import axios from 'axios';
const apiUrl = '/api/v2/';

const getContacts = (params) => axios.get('/client-api/sms/contacts/list', { params });
const getContactGroups = (params) => axios.get(`/client-api/sms/contacts/groups?placeholder=true`, { params });
const getContactsInGroups = (id) => axios.get('/client-api/sms/contacts/groups/' + id);
const getWaTemplates = (status, channel_id) =>
  axios.get(`${apiUrl}wa_templates?status=${status}&channel_id=${channel_id}`);
const getBalance = () => axios.get(`${apiUrl}wallet/balance`);
const getBroadcastList = (params) => axios.get(`${apiUrl}broadcasts`, { params });
const deleteBroadcast = (id) => axios.delete(`${apiUrl}broadcasts/` + id);
const showBroadcast = (id) => axios.get(`${apiUrl}broadcasts/` + id);
const getPriceEstimation = (params) => axios.post(`${apiUrl}broadcasts/price_estimation`, { ...params });
const createBroadcast = (params) => axios.post(`${apiUrl}broadcasts`, { ...params });
const getBroadcast = (id) => axios.get(`${apiUrl}broadcasts/` + id);
const updateBroadcast = (id, params) => axios.put(`${apiUrl}broadcasts/` + id, { ...params });

export {
  getContacts,
  getContactGroups,
  getContactsInGroups,
  getWaTemplates,
  getBalance,
  getBroadcastList,
  deleteBroadcast,
  showBroadcast,
  getPriceEstimation,
  createBroadcast,
  getBroadcast,
  updateBroadcast,
};
