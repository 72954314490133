import { CHANNEL_TYPE, PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/channels2/:channel',
    component: require('../../components/Channels/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: '/admin/channels2/:channel/create',
        component: require('../../components/Channels/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/channels2/:channel/create360dialog',
        component: require('../../components/Channels/Create360Dialog').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      // When deprecating Channels?edit.vue
      // {
      //     path: "/admin/channels/email/:id",
      //     component: require("../../components/Channels/EmailSettings/index").default,
      //     props: { action: "edit", channelType: 'email' },
      // },
      {
        path: '/admin/channels2/:channel/:id',
        component: require('../../components/Channels/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'channel' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];
