import { h } from 'vue';
import { RouterView } from 'vue-router';

import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: 'broadcasting',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        component: require('../../components/WabBroadcasting/pages/BroadcastingPage').default,
        meta: { permissionRequired: PERMISSION.BROADCASTING__GENERAL__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: 'new',
        component: require('../../components/WabBroadcasting/pages/CreateBroadcast/CreateBroadcast.vue').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.BROADCASTING__GENERAL__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: ':id',
        component: require('../../components/WabBroadcasting/pages/CreateBroadcast/CreateBroadcast.vue').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.BROADCASTING__GENERAL__MANAGE },
        beforeEnter: hasPermission,
      },
    ],
  },
];
