import { cachedRequest } from '@/util/request';

export default class UserRepository {
  static fetchAllUsers(cacheEnabled = true) {
    return new Promise(function (resolve, reject) {
      cachedRequest({ method: 'get', url: '/client-api/users/list' }, cacheEnabled).then((res) => {
        resolve(res.data);
      });
    });
  }
}
