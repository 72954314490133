import { useEntitlementsStore } from '@/store/pinia';

export async function checkEntitlements(to: any, _from: any, next: any) {
  if (to.meta.allowUnauthenticated) {
    next();
    return;
  }
  const entitlements = useEntitlementsStore();
  await entitlements.init();
}
