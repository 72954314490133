<template>
  <div class="template-landing-container sm:justify-center lg:justify-start">
    <div class="template-landing mx-4 px-0 lg:ml-0 xl:ml-28">
      <div>
        <router-link to="/admin/channels2/wa_business">
          <t-button-text btn-style="secondary" class="template-landing--link-back">
            <arrow-left-linear size="1.2rem" class="xs2:mr-1.5" />
            {{ $t('whatsapp.back_to_whatsapp_business_channels') }}
          </t-button-text>
        </router-link>
        <div class="md:pl-4">
          <div class="flex items-center lg:block">
            <h2 class="template-landing-title lg:t-text-desktop-h1 text-3xl">{{ $t('whatsapp.message_templates') }}</h2>
          </div>

          <ul class="template-landing-list">
            <li class="flex-start flex">
              <i class="list-bullet far fa-fw fa-check" />
              <span class="list-item-text">
                {{
                  $t('whatsapp.check_in_with_customers_who_have_not_interacted_on_whatsapp_within_the_last_24_hours')
                }}
              </span>
            </li>
            <li class="flex-start flex">
              <i class="list-bullet far fa-fw fa-check" />
              <span class="list-item-text">
                {{ $t('whatsapp.proactively_send_personalized_and_relevant_notifications_to_your_customers') }}
              </span>
            </li>
            <li class="flex-start flex">
              <i class="list-bullet far fa-fw fa-check" />
              <span class="list-item-text">
                {{ $t('whatsapp.streamline_your_communication_with_customers_and_save_time_and_effort') }}
              </span>
            </li>
          </ul>
        </div>
        <div class="flex w-full flex-col md:w-auto md:flex-row md:pl-4">
          <t-button class="md:mr-4" @click="navigateToWATemplateCreation">{{ $t('whatsapp.new_template') }}</t-button>
          <t-button
            is-link
            href="https://trengo.com/blog/communication/whatsapp-business-message-templates-2"
            btn-style="secondary"
            class="my-2 inline-flex items-center justify-center md:m-0"
            target="_blank"
          >
            <template #default>
              {{ $t('whatsapp.learn_more') }}
              <external-link-linear size="1rem" class="ml-1.5" />
            </template>
          </t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftLinear, ExternalLinkLinear } from '@trengo/trengo-icons';

export default {
  name: 'WaTemplateGuide',
  components: { ExternalLinkLinear, ArrowLeftLinear },
  methods: {
    navigateToWATemplateCreation() {
      this.$router.push('/admin/wa_templates/create');
    },
  },
};
</script>
<style scoped lang="scss" src="./WaTemplateGuide.scss" />
