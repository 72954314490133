<template>
  <div class="users-list-header">
    <h3 class="title t-text-desktop-h3">{{ $t('user_management.users') }}</h3>

    <div class="actions">
      <t-button
        v-if="$authorization().hasPermission(PERMISSION.PERMISSIONS__ROLES__MANAGE)"
        btn-style="secondary"
        data-test="manage-roles-btn"
        class="manage-roles-btn"
        :icon-only="isMobile"
        @click="handleOpenRolesModal"
      >
        <gear-linear v-if="!isMobile" class="icon" size="1rem" />
        <gear-linear v-else #icon class="icon" size="1rem" />
        <span v-if="!isMobile" class="button-text">{{ $t('user_management.manage_roles') }}</span>
      </t-button>

      <t-button v-if="!isMobile" data-test="invite-user-btn" class="invite-user-btn" @click="handleOpenModal">
        <user-plus-linear size="1rem" class="icon" />
        <span class="button-text">{{ $t('user_management.invite_user') }}</span>
      </t-button>
      <t-button v-else data-test="invite-user-btn" class="invite-user-btn-mobile" icon-only @click="handleOpenModal">
        <user-plus-linear #icon size="1rem" class="icon" />
      </t-button>
    </div>
  </div>
</template>

<script>
import { GearLinear, UserPlusLinear } from '@trengo/trengo-icons';

import { PERMISSION } from '@/Configs/Constants';

export default {
  name: 'UsersListHeader',
  emits: ['openModal', 'openRolesModal'],
  data() {
    return { PERMISSION };
  },
  components: {
    GearLinear,
    UserPlusLinear,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleOpenModal() {
      this.$emit('openModal');
    },
    handleOpenRolesModal() {
      this.$emit('openRolesModal');
    },
  },
};
</script>

<style scoped lang="scss" src="./UsersListHeader.scss" />
