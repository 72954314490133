<template>
  <mobile-status v-if="isMobile" />

  <div v-else class="relative flex flex-1">
    <div v-if="showLandingPage" class="relative flex flex-1 bg-grey-200">
      <Landing />
    </div>

    <div v-else v-show="isLoaded" class="relative flex flex-1 bg-grey-200">
      <iframe v-show="showIframe" ref="ai_frame" class="absolute h-full w-full border-0" :src="AIURL" />
      <TermsAndCondition v-if="!showIframe" @register="handleRegisterUser" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isEmpty } from 'lodash';
import { type Ref, ref, onMounted, onUnmounted, nextTick, computed, watch } from 'vue';

import { vm } from '@/ApplicationHandler';
import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants/featureFlag';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import { useFeatureFlagStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import { registerTenant, getTenant, type Tenant } from './api';
import Landing from './Landing.vue';
import MobileStatus from './MobileStatus.vue';
import TermsAndCondition from './TermsAndCondition.vue';
import { provideToken } from './utils';

const { TRENGO, ADMINISTRATOR, SUPERVISOR } = USER_ROLE;
const isLoaded = ref(false);
const showIframe = ref(false);
const AIURL = `${window.AI_MFE_URL || process.env.AI_LOCAL_URL}?v=${Math.random()}`
const ai_frame: Ref<HTMLIFrameElement | undefined> = ref(undefined);
const tenant: Ref<Tenant> = ref({provider: '', provider_id: ''});
const eventInterval: Ref<number | undefined> = ref();

const showLandingPage = computed(() => {
  return useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.AI_LANDING_PAGE)
});

const isMobile  = computed(() => {
  return breakpoints.w <= 991;
});

onMounted(() => {
  mountAiDashboard()
})

onUnmounted(() => {
  showIframe.value = false
  isLoaded.value = false
  window.removeEventListener('message', authorizeIframe);
  window.removeEventListener('message', provideAgencyIdToIframe);

  if (eventInterval.value !== undefined) {
    clearInterval(eventInterval.value);
  }
})


function handleRegisterUser() {
  registerTenant().then(res => {
    tenant.value = res.data;
    provideTokenToIframe()
  })
}

function getTenantUser() {
  getTenant().then(res => {
    if (!res.data.provider_id) {
      isLoaded.value = true;
      showIframe.value = false;
      vm.$router.push({ name: 'aiDashboardTerms' })
    }
    else {
      vm.$router.push({ name: 'aiDashboard' })
    }
    tenant.value = res.data;
    provideTokenToIframe()
  })
}

async function provideTokenToIframe() {
  await nextTick();
  if (eventInterval.value !== undefined) {
    clearInterval(eventInterval.value);
  }
  const userSubscriptionSlug = vm?.$userPlan()?.getSubscription as {plan_slug: string};
  const isTrial = isEmpty(userSubscriptionSlug.plan_slug);
  const payload = provideToken(isTrial, tenant.value.provider_id, tenant.value.provider);
  eventInterval.value = window.setInterval(() => {
    ai_frame.value?.contentWindow?.postMessage(payload, '*');
  }, 100)
}

function changeRoute(path: string) {
  vm.$router.push(path)
}

async function handleIframeMessage(e: MessageEvent) {
  await authorizeIframe(e);
  await provideAgencyIdToIframe(e);
  await provideClientLanguageToIframe(e);
}

async function authorizeIframe(e: MessageEvent) {
  if (e.data.type === "CHANGE_ROUTE") {
    changeRoute(e.data.path)
  }
  if (!tenant.value.provider_id || e.data.type !== 'AUTH' || e.data.payload !== 'authentication') {
    return;
  }
  window.clearInterval(eventInterval.value);
  showIframe.value = true;
  await nextTick()
  isLoaded.value = true;
  vm.$router.push({ name: 'aiDashboard' })
}

async function provideAgencyIdToIframe(e: MessageEvent) {
  if (e.data.type === 'DATA_REQUEST' && e.data.payload === 'agencyId') {
    ai_frame.value?.contentWindow?.postMessage({ type: "DATA_REQUEST", payload: { agencyId: vm?.$store.getters["initialData/agency"].id } }, '*');
  }
}

async function provideClientLanguageToIframe(e: MessageEvent) {
  if (e.data.type === 'DATA_REQUEST' && e.data.payload === 'clientLocale') {
    ai_frame.value?.contentWindow?.postMessage({ type: "DATA_REQUEST", payload: { clientLocale: vm?.$root?.user.locale_code } }, '*');
  }
}

function mountAiDashboard() {
  if (![TRENGO, ADMINISTRATOR, SUPERVISOR].includes(vm?.$root['user'].role.name)) {
    vm?.$router.push('/tickets');
    return false;
  }
  if(showLandingPage.value) {
    return false;
  }

  window.addEventListener('message', handleIframeMessage);

  getTenantUser()
}

watch(isMobile, (isMobile) => {
  if (!isMobile) {
    mountAiDashboard()
  }
})
</script>
