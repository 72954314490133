import 'jquery.growl';

export const flashError = (message: string) => {
  if (typeof $ === 'undefined') {
    console.error('jQuery is not loaded');
    return;
  }
  $('.growl').hide();

  // @ts-ignore
  $.growl.error({
    size: 'large',
    duration: 2500,
    namespace: 'growl',
    title: '',
    location: 'tc',
    message: message,
  });
};
