import type { RouteRecordRaw } from 'vue-router';

const workflows: RouteRecordRaw[] = [
  {
    path: '/workflows/:pathMatch(.*)*', // all paths starting with /workflows
    component: () => {
      return import('@/components/Workflows/Pages/Overview.vue');
    },
    name: 'workflows.overview',
  },
];

export default workflows;
