<script type="text/babel">
import SidebarCollapse from '@/components/common/SidebarCollapse';

export default {
  props: ['ticket'],

  data: function () {
    return {};
  },

  components: {
    SidebarCollapse,
  },

  mounted: function () {},

  methods: {
    detach(related_ticket) {
      this.ticket.related_tickets.splice(this.ticket.related_tickets.indexOf(related_ticket), 1);
      axios.delete('/api/v2/tickets/' + this.ticket.id + '/relations/' + related_ticket.id);
    },
  },
};
</script>

<template>
  <div v-show="ticket.related_tickets.length > 0" class="sidebar_block select-none border-b border-grey-300 p-0">
    <sidebar-collapse collapse-key="collapseTicketSidebarRelations">
      <template #header>
        <h6 class="mb-0">
          {{ $t('ticket_sidebar.linked_conversations') }}
        </h6>
      </template>
      <div class="mt-3">
        <div v-for="ticket in ticket.related_tickets" class="mb-4 flex">
          <div>
            <span class="text-muted mr-3 text-xs" @click="detach(ticket)"><i class="fa fa-link"></i></span>
          </div>
          <div class="flex-1">
            <div>
              <div class="flex items-center">
                <router-link :to="'/tickets/' + ticket.id" class="font-bold">
                  {{ ticket.contact.full_name }}
                </router-link>
                <span class="text-muted ml-2 text-xs" @click="detach(ticket)">
                  <i class="text-muted fa fa-remove"></i>
                </span>
              </div>
              <router-link v-if="ticket.subject" tag="div" :to="'/tickets/' + ticket.id" class="text-muted">
                <em>{{ ticket.subject }}</em>
              </router-link>
              <router-link tag="div" :to="'/tickets/' + ticket.id">#{{ ticket.id }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </sidebar-collapse>
  </div>
</template>
