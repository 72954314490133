import Tribute from 'tributejs';

import { escapeHtml } from '@/util/stringHelpers';

import store from '../../../store';

export default new Tribute({
  trigger: '#',
  noMatchTemplate: '',
  selectTemplate: (item) => {
    if (!item) {
      return;
    }
    return (
      '<span class="fr-deletable fr-tribute text-green" contenteditable="false">' +
      escapeHtml(item.original.identifier) +
      '</span>'
    ); // todo rl test firefox
  },
  menuItemTemplate: (item) => {
    return (
      '<span class="flex w-full items-center"><span class="user-image justify-center items-center" style="display:flex;line-height:1;min-width:23px;width:23px;margin-right:7px;"><i class="far fa-hashtag flex items-center text-center text-grey-600"></i></span><span class="text-ellipsis" style="font-weight:normal;line-height:1;padding:0 7px 1px 0;">' +
      escapeHtml(item.original.name) +
      '</span></span>'
    );
  },
  lookup: (item) => item.name,
  values: (text, cb) => {
    let threads = store.state.chat.threads.filter((t) => t.identifier.startsWith('#'));
    if (!store) {
      setTimeout(() => {
        // todo event
        cb(threads);
      }, 300);
    } else {
      cb(threads);
    }
  },
});
