import { defineStore } from 'pinia';
import { UnleashClient } from 'unleash-proxy-client';

import type { IConfig, IMutableContext, IVariant } from 'unleash-proxy-client';

type UnleashEvents = 'update' | 'ready' | 'error' | 'initialized';

type FeatureFlagState = {
  isStarted: boolean;
  features: { [key: string]: boolean };
};

let unleashClient: UnleashClient | null = null;

export const useFeatureFlagStore = defineStore('featureFlag', {
  state: (): FeatureFlagState => ({
    isStarted: false,
    features: {},
  }),

  actions: {
    initialize() {
      if (unleashClient) return; // Prevent re-initialization
      const config: IConfig = {
        url: `${window.UNLEASH_URL + window.UNLEASH_ENVIRONMENT}/proxy`,
        clientKey: window.UNLEASH_FRONTEND_CLIENT_KEY,
        appName: window.UNLEASH_INSTANCE_ID,
        refreshInterval: 5 * 60,
        environment: window.UNLEASH_ENVIRONMENT,
        metricsInterval: 5 * 60,
      };
      unleashClient = new UnleashClient(config);
    },

    async updateContext(
      userId: string,
      agencyId: string,
      betaEnrollment: boolean,
      unleashContext?: IMutableContext
    ): Promise<void> {
      this.initialize();
      if (!unleashClient) return;

      const defaultContext = {
        userId,
        agencyId,
        'beta-group-all': betaEnrollment ? '1' : '0',
      };
      const context = unleashContext || defaultContext;

      return unleashClient.updateContext(context);
    },

    async start(): Promise<void> {
      if (!unleashClient || this.isStarted) return;
      await unleashClient.start();
      this.isStarted = true;
      this.fetchFeatureToggles();
    },

    async fetchFeatureToggles() {
      // Ensure the client is started
      unleashClient?.on('update', () => {
        this.features = unleashClient?.getAllToggles();
      });

      // Fetch initial features
      this.features = unleashClient?.getAllToggles();
    },

    async stop(): Promise<void> {
      if (!unleashClient || !this.isStarted) return;

      await unleashClient.stop();
      this.isStarted = false;
    },

    isEnabled(name: string): boolean {
      if (!unleashClient || !this.isStarted) {
        return false;
      }
      return unleashClient.isEnabled(name);
    },

    getVariant(name: string): IVariant | null {
      if (!unleashClient || !this.isStarted) {
        return null;
      }
      return unleashClient.getVariant(name);
    },

    on(event: UnleashEvents, func: () => void) {
      if (!unleashClient) {
        console.warn('Unleash client is not initialized');
        return;
      }
      unleashClient.on(event, func);
    },

    off(event: UnleashEvents, func: () => void) {
      if (!unleashClient) {
        console.warn('Unleash client is not initialized');
        return;
      }
      unleashClient.off(event, func);
    },
  },
});
