import { ENDPOINT } from '@/api/constants';
import { request } from '@/util/request';

import type { ConsolidatedComposerSendPayload } from '@/components/ReplyForm/types/composer';
import type Ticket from '@/types/tickets';
import type { AxiosPromise } from 'axios';

export type ConsolidatedMessageReturnPayload = {
  ticket: Ticket;

  // TODO: https://linear.app/trengo/issue/TFA-1/add-consolidatedmessagereturnpayload-message-types We need to create a proper message type here
  message: {
    id: number | string;
    [key: string]: unknown;
  };
};

export function sendConsolidatedMessage(
  payload: ConsolidatedComposerSendPayload
): AxiosPromise<ConsolidatedMessageReturnPayload> {
  return request(ENDPOINT.CONSOLIDATED_MESSAGE_ENDPOINT, 'POST', payload);
}
