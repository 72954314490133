<template>
  <div>
    <div class="sidebar_block border-b border-grey-300">
      <sidebar-collapse collapse-key="collapseTicketSidebarTimeTracking">
        <template #header>
          <h6 class="mb-0">
            {{ $t('ticket_sidebar.time_tracking') }}
          </h6>
        </template>
        <div class="sidebar_block_body">
          <div class="m-b-xs">
            <edit action="create" :record="this.record" class="tt bb mt-2 pb-4" @save="addLog"></edit>

            <div class="tt bb total_time mt-3 pb-3">
              <i class="material-icons tt text-gray-darker align-middle">timelapse</i>
              <span class="tt text-gray-darker align-middle">{{ $t('ticket_sidebar.time_tracking_total_time') }}:</span>
              <span class="align-middle text-black">
                {{ formatSeconds(totalTime) }}
              </span>
            </div>

            <div id="collapseTimeTrackingLogs">
              <transition-group name="slide-fade">
                <div v-for="(dayString, dayIndex) in daysOrder" :key="dayString" class="tt bb pb-3">
                  <div class="mt-3">
                    <div role="tab" class="sidebar-label-title text-grey-500">
                      {{ formatCalendar(dayString, 'DD-MM-YYYY') }}
                      <span>
                        {{
                          $t('ticket_sidebar.time_tracking_total', {
                            time: formatSeconds(days[dayString].totalTime),
                          })
                        }}
                      </span>
                    </div>

                    <div class="sidebar-label-content text-grey-600">
                      <transition-group name="slide-fade">
                        <div
                          v-for="(log, logIndex) in days[dayString].logs"
                          :key="log.tmp_id || log.id"
                          class="card"
                          style="border: none; margin-bottom: 0"
                        >
                          <div :id="'headingDay' + dayIndex + 'Log' + logIndex" class="" role="tab">
                            <div class="collapsed flex items-center py-1">
                              <avatar
                                v-tooltip.left="log.user.name"
                                data-toggle="tooltip"
                                :abbr="log.user.abbr.substr(0, 1)"
                                :color="log.user.color"
                                :image="log.user.profile_image"
                                class="mr-2 align-middle"
                                :width="18"
                                font-size="11px"
                              ></avatar>
                              <div class="align-middle">
                                {{ formatSeconds(diffInSeconds(log.start_time, log.end_time)) }}
                              </div>
                              <div class="ml-auto">
                                <i
                                  class="material-icons md-18"
                                  role="button"
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  :aria-controls="'collapseDay' + dayIndex + 'Log' + logIndex"
                                  :data-target="'#collapseDay' + dayIndex + 'Log' + logIndex"
                                >
                                  {{ log.user_id === $root.user.id ? 'edit' : 'remove_red_eye' }}
                                </i>
                                <i
                                  v-if="log.user_id === $root.user.id"
                                  class="material-icons md-18 ml-1"
                                  role="button"
                                  @click="removeLog(log)"
                                >
                                  delete
                                </i>
                              </div>
                            </div>
                          </div>

                          <div
                            :id="'collapseDay' + dayIndex + 'Log' + logIndex"
                            class="panel-collapse collapse"
                            data-parent="#collapseTimeTrackingLogs"
                          >
                            <div class="card-body mb-3 py-3">
                              <edit
                                v-if="log.user_id === $root.user.id"
                                :record="log"
                                :target="'#collapseDay' + dayIndex + 'Log' + logIndex"
                                action="edit"
                                @save="editLog"
                              ></edit>
                              <div v-else>{{ log.description }}</div>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </sidebar-collapse>
    </div>
  </div>
</template>

<script>
import SidebarCollapse from '@/components/common/SidebarCollapse';
import { randomString } from '@/util/stringHelpers';

import Edit from './TimeTracking/Edit';
import TimeFormatting from '../../mixins/TimeFormatting';
import Avatar from '../Avatar';

export default {
  name: 'TimeTracking',
  components: {
    Avatar,
    Edit,
    SidebarCollapse,
  },

  mixins: [TimeFormatting],

  methods: {
    diffInSeconds(start, end) {
      return end - start;
    },

    /*toggleDescription(key) {
                this.$set(this.showDescription, key, !this.showDescription[key]);
            },*/
    addLog(log) {
      axios
        .post('/api/v2/time_tracking', log)
        .then((r) => {
          const logIndex = this.logs.push(log) - 1;
          this.record = { ...this.getEmptyRecord() };
          this.logs.splice(logIndex, 1, { id: r.data.id });
          this.flashSuccess(this.$t('ticket_sidebar.time_tracking_saved'));
        })
        .catch(() => {
          this.logs.splice(logIndex, 1);
          this.record = log;
        });
    },
    editLog(log) {
      const logIndex = this.logs.findIndex((logB) => logB.id === log.id);
      const logBackup = { ...this.logs[logIndex] };

      this.logs.splice(logIndex, 1, log);

      axios
        .put('/api/v2/time_tracking/' + log.id, log)
        .then(() => {
          this.flashSuccess(this.$t('ticket_sidebar.time_tracking_saved'));
        })
        .catch(() => {
          // rollback on error
          this.logs.splice(logIndex, 1, logBackup);
        });
    },
    removeLog(log) {
      const index = this.logs.indexOf(log);
      if (index > -1) {
        this.logs.splice(index, 1);
      } else {
        return;
      }

      axios.delete('/api/v2/time_tracking/' + log.id, log).catch(() => {
        // rollback on error
        if (log.id && !this.logs.find((log2) => log2.id === log.id)) this.logs.push(log);
      });
    },
    getEmptyRecord() {
      return {
        id: null,
        tmp_id: randomString(8),
        start_time: parseInt((new Date().getTime() / 1000).toFixed()),
        end_time: null,
        description: '',
        ticket_id: parseInt(this.$route.params.ticketId || ''),
        user_id: this.$root.user.id,
        user: this.$root.user,
      };
    },
    computeDays() {
      this.totalTime = 0;
      let days = {};
      this.daysOrder = [];
      this.logs.forEach((log) => {
        if (!log) {
          return;
        }

        const startTime = moment(log.start_time * 1000);
        const dayString = startTime.format('DD-MM-YYYY');

        if (!days.hasOwnProperty(dayString)) {
          days[dayString] = {
            logs: [],
            totalTime: 0,
          };
        }

        days[dayString].logs.push(log);
        days[dayString].totalTime += log.end_time - log.start_time; // totalTime in seconds

        // add to totalTime in ticket
        this.totalTime += log.end_time - log.start_time;
      });

      // sort (days.logs)
      for (const dayString in days) {
        if (!days.hasOwnProperty(dayString)) {
          continue;
        }

        // add to daysOrder
        if (this.daysOrder.indexOf(dayString) === -1) {
          this.daysOrder.push(dayString);
        }

        const day = days[dayString];

        days[dayString].logs = day.logs.sort((a, b) => {
          return a.start_time < b.start_time ? -1 : 1;
        });
      }

      // sort (days) (objects are unsorted, set sort order in a new array)
      this.daysOrder.sort((a, b) => {
        return moment(a, 'DD-MM-YYYY').isAfter(moment(b, 'DD-MM-YYYY')) ? -1 : 1;
      });

      return days;
    },
  },

  mounted() {
    axios.get('/api/v2/time_tracking?ticket_id=' + this.record.ticket_id).then((r) => {
      this.logs = r.data.data || [];
    });
  },

  watch: {
    logs: {
      handler(v) {
        this.days = this.computeDays();
      },
      deep: true,
    },
  },

  data() {
    return {
      record: this.getEmptyRecord(),
      logs: [],
      days: {},
      daysOrder: [],
      totalTime: 0, // total time in ticket in seconds
      showDescription: {},
    };
  },
};
</script>

<style lang="scss" scoped>
// Trengo TwentyTwenty Theme Variables
@import '../../assets/sass/_variables.scss';

.total_time,
.total_time_value {
  font-weight: 700;
}
</style>
