<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.contact_groups')"
    collapse-key="contactGroupDropdown"
    data-test="contact-group-dropdown"
  >
    <div class="flex flex-col">
      <t-input-checkbox
        v-for="group in groups"
        :key="group.id"
        v-model="group.selected"
        class="mb-2 last:mb-0"
        data-test="contact-group-checkbox"
        @change="save"
      >
        {{ group.name }}
      </t-input-checkbox>
    </div>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { setContactGroupStatus } from '@/components/TicketSidebar/Api/requests';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';

import type Contact from '@/types/contact';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ContactGroupDropdown',

  components: { SidebarDropdown },

  props: {
    contact: {
      type: Object as PropType<Contact>,
      default: () => ({}),
    },
  },

  data() {
    return {
      groups: [],
    };
  },

  created() {
    this.setGroups();
  },

  methods: {
    async save() {
      const groupIds = this.groups
        .map((group) => {
          if (group.selected) {
            return group.id;
          }
        })
        .filter((group) => group !== undefined);

      try {
        await setContactGroupStatus(this.contact.id, groupIds);
      } catch (err) {
        console.error(err);
        this.flashError(err?.message);
      }
    },

    setGroups() {
      this.groups = this.$root.contactGroups.map((group) => {
        return {
          ...group,
          selected: this.contact.groups.some((item) => item.id === group.id),
        };
      });
    },
  },
});
</script>
