<template>
  <div
    v-if="channelsWithConnectionIssues.length > 0"
    class="mx-4 h-[200px] overflow-y-scroll rounded-lg bg-orange p-4 text-white"
  >
    {{ $t('general.channels_with_connection_issues') }}
    <div v-for="(channel, index) in channelsWithConnectionIssues" :key="index" class="inline-block">
      <router-link :to="manageChannelsRoute(channel)" tag="span" class="pointer font-bold hover:text-grey-300">
        {{ channel.display_name }}
      </router-link>

      <template v-if="index !== channelsWithConnectionIssues.length - 1" class="font-bold">,</template>
    </div>
  </div>
</template>

<script type="text/babel">
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';

import pusherMixin from '../mixins/PusherHelper';

export default {
  computed: {
    channelsWithConnectionIssues() {
      const selectedChannels = this.$root.channels.filter((channel) => {
        return ['EMAIL', 'FACEBOOK', 'INSTAGRAM'].includes(channel.type) && channel.password_is_null;
      });
      return selectedChannels;
    },
  },

  mixins: [pusherMixin],

  mounted() {
    this.bind();
  },

  unmounted() {
    this.$root.agencyChannel.unbind('channel-status-update');
  },

  methods: {
    bind() {
      if (this.$root.agencyChannel.subscribed) {
        this.$root.agencyChannel.bind('channel-status-update', (data) => {
          if (data.channel && this.$root.channels.find((c) => c.id === data.channel.id)) {
            this.$root.channels.find((c) => c.id === data.channel.id).password_is_null = data.channel.password_is_null;
          }
        });
      } else {
        eventBus.$once(
          'pusher.private-agency-' + this.$root.companyProfile.profile.agency_id + '.subscribed',
          this.bind
        );
      }
    },

    manageChannelsRoute(channel) {
      if (this.$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)) {
        return '/admin/channels2/' + channel.type.toLowerCase() + '/' + channel.id;
      }

      return '';
    },
  },
};
</script>
