<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('view_settings.view') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('view_settings.title') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.title" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('view_settings.conditions') }}</label>
                      <div class="col-sm-10">
                        <div v-for="condition in record.conditions" class="mb-2 flex items-center">
                          <div class="flex-1">
                            <select v-model="condition.type" class="form-control">
                              <option value="USER">{{ $t('view_settings.user') }}</option>
                              <option value="STATUS">{{ $t('view_settings.status') }}</option>
                              <option value="LABEL">{{ $t('view_settings.label') }}</option>
                              <option value="CHANNEL">{{ $t('view_settings.channel') }}</option>
                            </select>
                          </div>
                          <div class="mx-4">
                            <span class="text-muted">{{ $t('view_settings.is') }}</span>
                          </div>
                          <div class="flex-1">
                            <div v-if="condition.type === 'USER'">
                              <select v-model="condition.value" class="form-control">
                                <option v-for="user in $root.users" :value="user.id">{{ user.name }}</option>
                              </select>
                            </div>
                            <div v-if="condition.type === 'TEAM'">
                              <select v-model="condition.value" class="form-control">
                                <option v-for="team in $root.teams" :value="team.id">{{ team.name }}</option>
                              </select>
                            </div>
                            <div v-if="condition.type === 'LABEL'">
                              <select v-model="condition.value" class="form-control">
                                <option v-for="label in $root.labels" :value="label.id">{{ label.name }}</option>
                              </select>
                            </div>
                            <div v-if="condition.type === 'CHANNEL'">
                              <select v-model="condition.value" class="form-control">
                                <option v-for="channel in $root.channels" :value="channel.id">
                                  {{ channel.title }}
                                </option>
                              </select>
                            </div>
                            <div v-if="condition.type === 'STATUS'">
                              <select v-model="condition.value" class="form-control">
                                <option value="OPEN">New</option>
                                <option value="ASSIGNED">Assigned</option>
                                <option value="CLOSED">Closed</option>
                                <option value="INVALID">Invalid</option>
                              </select>
                            </div>
                          </div>
                          <div class="text-muted pl-2">
                            <i class="fa fa-remove text-muted cursor-pointer" @click="removeCondition(condition)"></i>
                          </div>
                        </div>
                        <div class="mt-4 pt-4 text-center">
                          <a class="text-muted" @click="addCondition">{{ $t('view_settings.add_condition') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn btn-success"
                      :disabled="saving || !ready_to_save"
                      v-text="saving ? this.$t('view_settings.saving') : this.$t('view_settings.save_changes')"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteLabel()"
                    >
                      {{ $t('view_settings.delete') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';

export default {
  name: 'CustomViewsEdit',
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  computed: {
    ready_to_save() {
      if (this.record.title === '') {
        return;
      }

      return true;
    },
  },

  data() {
    return {
      loaded: false,
      record: {
        title: '',
        conditions: [],
      },
      saving: false,
    };
  },

  mounted() {
    if (this.action === 'edit') {
      axios.get('/api/v2/custom_views/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        this.initWarning();
      });
    } else {
      this.addCondition();
      this.initWarning();
    }
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action === 'create') {
        return this.storeCustomView();
      } else {
        this.updateCustomView();
      }
    },

    addCondition() {
      this.record.conditions.push({
        type: 'USER',
      });
    },

    removeCondition(condition) {
      if (this.record.conditions.length <= 1) {
        return;
      }

      this.record.conditions.splice(this.record.conditions.indexOf(condition), 1);
    },

    storeCustomView() {
      axios
        .post('/api/v2/custom_views', this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          this.$nextTick(() => router.push('/admin/custom_views/' + res.data.id));
          this.flashSuccess(this.$t('view_settings.the_custom_view_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateCustomView() {
      axios
        .put('/api/v2/custom_views/' + this.record.id, this.record)
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('view_settings.the_custom_view_has_been_updated_successfully'));
          this.initWarning();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteLabel() {
      const shouldDeleteView = await this.$tConfirm(
        this.$t('view_settings.are_you_sure_you_want_to_delete_this_view'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteView) {
        this.initWarning();

        axios.delete('/api/v2/custom_views/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push('/admin/custom_views/create');
          this.flashSuccess(this.$t('view_settings.the_custom_view_has_been_deleted_successfully'));
        });
      }
    },
  },
};
</script>
