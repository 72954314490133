import { PERMISSION, PRICING_MODEL } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import checkPricingModel from '@/middleware/checkPricingModel';
import hasPermission from '@/middleware/hasPermission';

const hasSeatBasedPricing = async (_to, _from, next) => {
  const pricingModel = await checkPricingModel();

  if (pricingModel === PRICING_MODEL.USAGE_BASED) {
    next('/tickets');
  }
  next();
};

export default [
  {
    path: '/admin/company_profile/balance',
    redirect: SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
  },
  {
    path: SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
    component: require('../../components/BalanceSettings/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE },
    beforeEnter: hasPermission && hasSeatBasedPricing,
  },
];
