<template>
  <div class="flex justify-center pb-6">
    <div class="xs:w-[510px] md:w-[690px] xl:w-[870px]">
      <div class="flex items-center">
        <back-header
          v-if="hasBackButton"
          class="mb-4 mt-6"
          :title="$t('edit_subscription.title')"
          @click="$emit('back-click')"
        />
        <main-header v-else :title="title" class="flex-grow pb-6" />
        <div class="mr-6">
          <slot name="headingRight"></slot>
        </div>
      </div>
      <div class="flex w-full flex-col px-6"><slot></slot></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BackHeader from '@/components/common/PageHeaders/BackHeader';
import MainHeader from '@/components/common/PageHeaders/MainHeader';

import type { TranslateResult } from 'vue-i18n';

defineProps<{
  title: string | TranslateResult;
  hasBackButton?: boolean;
}>();
</script>
