// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import 'floating-vue/dist/style.css';

import TrengoUI from '@trengo/trengo-ui';
import vClickOutside from 'click-outside-vue3';
import DOMPurify from 'dompurify';
import FloatingVue from 'floating-vue';
import { createPinia } from 'pinia';
import PortalVue from 'portal-vue';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { VueTelInput } from 'vue-tel-input';
import VueClickAway from 'vue3-click-away';

import DateTime from '@/components/DateTime.vue';
import authorization from '@/plugins/authorization';
import confirmAlert from '@/plugins/confirm';
import tStorage from '@/plugins/storage';
import tUserPlan from '@/plugins/userPlan';
import router from '@/routes/routes';
import App from '@/start/App.vue';
import uiKitComponents from '@/start/uikit';
import VuexStore from '@/store';
import { showGrowlNotification } from '@/util/growlNotification';

const pinia = createPinia();
const app = createApp(App);

app.component('datetime', DateTime);

uiKitComponents.forEach((component) => {
  app.component(component.name, component.path);
});

app.directive('click-outside', vClickOutside);
// mobile longPress. usage: <div v-longpress="callbackFunction" /> you can specify an optional delay with v-longpress:1000="callbackFunction" (default is 300ms).
app.directive('longpress', {
  bind(el, binding) {
    let longPressTimeout = null;
    el.addEventListener('touchstart', (e) => {
      longPressTimeout = window.setTimeout(/*callback*/ binding.value, /*delay*/ binding.arg || 300);
    });
    el.addEventListener('touchend', () => {
      window.clearTimeout(longPressTimeout);
    });
    el.addEventListener('touchcancel', () => {
      window.clearTimeout(longPressTimeout);
    });
    el.addEventListener(
      'touchmove',
      _.debounce(
        () => {
          window.clearTimeout(longPressTimeout);
        },
        100,
        { maxWait: 100, leading: true, trailing: true }
      )
    );
  },
});

app.mixin({
  methods: {
    /**
     * Removes javascript URLs.
     */
    safeUrl: window.safeUrl,

    /**
     * Removes all HTML-tags from a string, safe against XSS.
     */
    stripHtml: window.stripHtml,

    /**
     * DOMPurify sanitize method, strips unsafe HTML.
     */
    sanitizeHtml: DOMPurify.sanitize,

    /**
     * Linkify HTML into links with rel=noopener (safe)
     */
    linkifyHtml: window.linkifyHtml,
    linkifyString: window.linkifyString,
  },
});

app.config.globalProperties.flashSuccess = function (message) {
  $('.growl').hide();
  showGrowlNotification('notice', 'large', 2500, '', message, 'tc');
};
app.config.globalProperties.flashWarning = function (message) {
  $('.growl').hide();
  showGrowlNotification('warning', 'large', 2500, '', message, 'tc');
};

app.config.globalProperties.flashError = function (message) {
  $('.growl').hide();
  showGrowlNotification('error', 'large', 2500, '', message, 'tc');
};

const i18n = createI18n({
  locale: 'en-GB', // set locale
  allowComposition: true,
  fallbackLocale: 'fallback',
});

app.use(VuexStore);
app.use(pinia);
app.use(TrengoUI);
app.use(i18n);
app.use(PortalVue);
app.use(VueClickAway);
app.use(VueTelInput);
app.use(FloatingVue);
app.use(confirmAlert);
app.use(tStorage);
app.use(tUserPlan);
app.use(authorization);
app.use(router);

const vueInstance = app.mount('#mount_app');
export { app, vueInstance };
