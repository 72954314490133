import { defineStore } from 'pinia';

import { SETTINGS_NAVIGATION } from '@/Configs/Constants/navigation/settingsNavigation';

import { useTranslationsStore } from '../translations';

import type { RoutesStore } from './types';
import type { NavigationItem } from '@/types/navigation';

export const useRoutesStore = defineStore('routes', {
  state: (): RoutesStore => ({
    lastRoutePath: null,
    currentRoutePath: null,
    activeSettingsSidebarName: '',
  }),

  actions: {
    setLastRoutePath(path: string): void {
      this.lastRoutePath = path;
    },

    setCurrentRoutePath(path: string): void {
      this.currentRoutePath = path;
    },

    setSettingsSidebarName(): void {
      const translationsStore = useTranslationsStore();
      const translations = translationsStore.translations;
      const route: NavigationItem | undefined = SETTINGS_NAVIGATION.map((parentRoute) => parentRoute.items)
        .flat()
        .find((subRoute) => subRoute.url === this.currentRoutePath);

      if (route) {
        const routeTitleKey: string = route?.title.split('.')[0];
        const routeTitleValue: string = route?.title.split('.')[1];

        // Iterate through translations to find the matching key for routeTitleKey and retrieve its corresponding value
        const translationValue = Object.keys(translations).find((key) => key === routeTitleKey)
          ? translations[routeTitleKey][routeTitleValue]
          : '';

        this.activeSettingsSidebarName = translationValue;
      }
    },
  },
  getters: {
    getLastRoutePath: (state): string | null => state.lastRoutePath,

    getCurrentRoutePath: (state): string | null => state.currentRoutePath,

    getSettingsSidebarName: (state): string => state.activeSettingsSidebarName,
  },
});
