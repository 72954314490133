import { vm } from '@/ApplicationHandler';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import store from '@/store';
import { useFeatureFlagStore } from '@/store/pinia';
import pusherHelper from '@/util/PusherHelper';
import tStorage from '@/util/tStorage';

import { waitForEvent } from '../../../start/util';
import api from '../Api';
import MessageAttachmentModel from '../Models/MessageAttachmentModel';
import MessageModel from '../Models/MessageModel';
import ThreadModel from '../Models/ThreadModel';
import { notifyThreadMessage } from '../Util/Chat';

const onOnline = () => {
  store.dispatch('chat/init', true);
};
const onResume = () => {
  window.Offline.check().onreadystatechange = (r) => {
    if (r.target.readyState === 4 && window.Offline.state === 'up') {
      store.dispatch('chat/init', true);
    }
  };
};

export default {
  async init({ dispatch, commit, state, rootState, getters }, resume = false) {
    await new Promise((f) => setTimeout(f, 2000));
    if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.TEAM_CHAT_DEPRECATION)) {
      return;
    }

    // replaceThreads=true automatically subscribes replaced threads
    let threads = (await dispatch('loadThreadList', resume)) || [];

    eventBus.$off('interval-resume', onResume);
    eventBus.$on('interval-resume', onResume);

    // only bind events on init. prevent rebinding on resume
    if (resume) {
      return;
    }

    threads.forEach((thread) => thread.subscribe());

    eventBus.$on('user-created', (user) => {
      // create new placeholder threads
      let threads = state.threads;
      dispatch('createPlaceholderThreads', threads);
    });

    eventBus.$on('user-deleted', (id) => {
      let index;
      state.threads.forEach((thread) => {
        if (thread.isGroup()) {
          index = thread.participants.findIndex((p) => p.id === id);
          if (index > -1) {
            commit('removeThreadParticipant', { thread: thread, index: index });
          }
        }
      });
    });

    // sync last read
    pusherHelper.bindPusherEvent(
      'private-user-' + rootState.usersInternalChat.currentUserId,
      'client-thread-read',
      ({ threadId, when, read = true, unread = null }) => {
        let thread = getters.threadById(threadId);

        if (!thread) {
          return;
        }

        if (read) {
          commit('setUnread', { thread: thread, unread: 0 });
          commit('setUnreadMentions', { thread: thread, unreadMentions: 0 });
          commit('setLastRead', { lastRead: when, thread: thread });
        } else {
          commit('setUnread', { thread: thread, unread: unread });
          commit('setLastRead', { lastRead: when, thread: thread });
        }
      }
    );

    // listen for new threads
    pusherHelper.bindPusherEvent(
      'private-user-' + rootState.usersInternalChat.currentUserId,
      'thread-created',
      async (thread) => {
        // replace user placeholder thread, or append new thread
        let threadModel = new ThreadModel(thread);
        threadModel = await dispatch('updateOrAppendThread', threadModel);

        // notify when unread messages in new thread
        let unreadMessages = threadModel.getLoadedUnreadMessages();
        if (unreadMessages.length) {
          commit('setUnread', { thread: threadModel, unread: unreadMessages.length });
          notifyThreadMessage(threadModel, unreadMessages[unreadMessages.length - 1]);
        }

        eventBus.$emit('thread-created', threadModel);
      }
    );

    pusherHelper.bindPusherEvent(
      'private-user-' + rootState.usersInternalChat.currentUserId,
      'thread-updated',
      (thread) => {
        let threadModel = new ThreadModel(thread);
        dispatch('updateOrAppendThread', threadModel);

        eventBus.$emit('thread-updated', threadModel);
      }
    );

    pusherHelper.bindPusherEvent(
      'private-user-' + rootState.usersInternalChat.currentUserId,
      'thread-deleted',
      (threadId) => {
        let thread = getters['threadById'](threadId);
        thread.unsubscribe();
        commit('removeThread', thread);
      }
    );

    // TODO maybe also when pusher:connection_established for the second time. (on window.Pusher: https://pusher.com/docs/channels/library_auth_reference/pusher-websockets-protocol#connection-events)
    window.Offline.off('up', onOnline);
    window.Offline.on('up', onOnline);

    eventBus.$emit('chat@INITIALIZED');
  },

  async unsubscribeThreads({ state }) {
    state.threads.forEach((thread) => thread.unsubscribe());
  },

  // use replaceThreads true on resume, false on init.
  async loadThreadList({ dispatch, commit, state, getters }, replaceThreads = false) {
    let threads = await api.threads.list();

    // create thread for each user except if it already has an existing thread
    dispatch('createPlaceholderThreads', threads);

    if (replaceThreads) {
      threads.forEach((thread) => {
        if (thread.identifier !== state.currentThread) {
          dispatch('updateOrAppendThread', thread);
        }
      });
    } else {
      commit('setThreads', threads);
    }
    return threads;
  },

  async loadOlderAttachments({ commit }, { thread, searchQuery }) {
    commit('incrementAttachments', { thread: thread });

    let response = (await api.threads.getAttachments(thread.id, thread.attachmentsPage, searchQuery)).data;
    let attachments = response.data;

    commit('setAttachmentsTotal', { thread: thread, total: response.meta.total });

    if (!attachments || !attachments.length) {
      thread.attachmentsPage--; // todo mutation
      return false;
    }

    attachments = attachments.map((a) => new MessageAttachmentModel(a));
    commit('setAttachments', { thread: thread, attachments: attachments });

    return attachments;
  },

  async loadNewerAttachments({ commit }, { thread, searchQuery }) {
    commit('decrementAttachments', { thread: thread });

    let response = (await api.threads.getAttachments(thread.id, thread.attachmentsPage, searchQuery)).data;
    let attachments = response.data;

    if (!attachments || !attachments.length) {
      thread.attachmentsPage++; // todo mutation
      return false;
    }

    attachments = attachments.map((a) => new MessageAttachmentModel(a));
    commit('setAttachments', { thread: thread, attachments: attachments });
    commit('setAttachmentsTotal', { thread: thread, total: response.meta.total });

    return attachments;
  },

  async searchAttachments({ commit }, { thread, searchQuery, page }) {
    if (!thread.id) {
      return;
    }

    let response = (await api.threads.getAttachments(thread.id, page, searchQuery)).data;
    let attachments = response.data;

    thread.attachmentsPage = page;

    attachments = attachments.map((a) => new MessageAttachmentModel(a));
    commit('setAttachments', { thread: thread, attachments: attachments });
    commit('setAttachmentsTotal', { thread: thread, total: response.total });
    commit('setSearchQuery', { thread: thread, searchQuery: searchQuery });

    return attachments;
  },

  setCurrentThread({ commit }, identifier) {
    tStorage.setItem('internalChat.currentThread', identifier);
    commit('setCurrentThread', identifier);
    eventBus.$emit('chat@AFTER_THREAD_SET', identifier);
  },

  markMessageAsReadOrUnread({ commit, rootState }, { thread, markAsRead = true, message = null, max = null }) {
    if (!thread || !thread.id) {
      return false;
    }

    let when = new Date().getTime() / 1000;

    if (markAsRead) {
      return api.threads
        .read(thread.id)
        .then((r) => {
          // sync tabs
          if (vm?.userChannel?.subscribed) {
            vm.userChannel.trigger('client-thread-read', { threadId: thread.id, when: when });
          }

          commit('setUnread', { thread: thread, unread: 0 });
          commit('setUnreadMentions', { thread: thread, unreadMentions: 0 });
          commit('setMarkUnread', { thread: thread, unread: false });
          commit('setLastRead', { lastRead: when, thread: thread });
        })
        .catch(() => {});
    } else {
      return api.threads
        .unread(thread.id, message.id)
        .then((r) => {
          let unreadCount = 0;
          let messageIndex;

          let whenUnread = moment(message.createdAt * 1000).subtract('seconds', 1) / 1000;

          // finds the index of the message inside the thread object
          messageIndex = thread.messages.findIndex((threadMessage) => threadMessage.id === message.id);

          // calculates unread messages starting from the index of message till the last message
          for (let i = messageIndex; i < thread.messages.length; i++) {
            if (thread.messages[i].userId !== rootState.usersInternalChat.currentUserId) {
              unreadCount++;
            }
          }

          // sync tabs
          if (vm?.userChannel?.subscribed) {
            vm.userChannel.trigger('client-thread-read', {
              threadId: thread.id,
              when: whenUnread,
              read: false,
              unread: unreadCount,
            });
          }

          commit('setUnread', { thread: thread, unread: unreadCount });
          commit('setMarkUnread', { thread: thread, unread: true });
          commit('setLastRead', { lastRead: whenUnread, thread: thread });
        })
        .catch(() => {});
    }
  },

  async leaveThread({ commit }, thread) {
    await api.threads.leave(thread.id);
    thread.unsubscribe();
    commit('removeThread', thread);
  },

  toggleMuted({ commit }, thread) {
    const value = !thread.muted;

    commit('setMuted', { thread: thread, muted: value });
    return api.threads.mute(thread.id, value);
  },

  togglePinned({ commit }, message) {
    const value = !message.pinned;

    commit('setPinned', { message: message, pinned: value });
    return api.messages.pin(message.id, value);
  },

  async updateOrAppendThread({ rootState, commit, getters }, threadModel) {
    let oldThread = threadModel.isUser()
      ? getters['threadByUserId'](threadModel.getUserId())
      : getters['threadById'](threadModel.id);

    if (oldThread) {
      // current user deleted from participants, remove thread instead of updating
      if (!threadModel.userIsParticipant()) {
        await oldThread.unsubscribe();
        commit('removeThread', oldThread);
        return;
      }

      // these properties should be updated
      let properties = [];
      if (oldThread === store.getters['chat/currentThread']) {
        // keep messages, attachments, lastRead, etc. if thread is currently open
        properties = [
          'id',
          'participants',
          'subject',
          'firstUnreadMessageId',
          'lastMessageId',
          'lastMessageTime',
          'muted',
        ];
        if (!oldThread.messages.length) {
          properties.push('messages'); // add new messages if none loaded (placeholder thread updated).
        }
      } else {
        properties = [
          'id',
          'participants',
          'subject',
          'firstUnreadMessageId',
          'lastMessageId',
          'lastMessageTime',
          'muted',
          'lastRead',
          'unread',
          'userMessageCount',
          'messages',
          'attachments',
          'identifier',
        ];
      }

      commit('updateThread', { oldThread: oldThread, newThread: threadModel, properties });

      return oldThread;
    } else {
      commit('appendThread', threadModel);
      await threadModel.subscribe();
      return threadModel;
    }
  },

  removeThread({ commit }, thread) {
    thread.unsubscribe();
    commit('removeThread', thread);
  },

  createPlaceholderThreads({ rootState }, threads) {
    rootState.usersInternalChat.users.forEach((u) => {
      if (!u.hasThread(threads)) {
        threads.push(
          new ThreadModel({
            id: null,
            identifier: u.getIdentifier(),
            participants:
              rootState.usersInternalChat.currentUserId === u.id
                ? [{ id: u.id, owner: false }]
                : [
                    { id: rootState.usersInternalChat.currentUserId, owner: false },
                    { id: u.id, owner: false },
                  ],
          })
        );
      }
    });
  },

  /**
   * @param dispatch
   * @param commit
   * @param getters
   * @param thread ThreadModel
   * @returns {Promise<ThreadModel>}
   */
  async createGroup({ dispatch, commit, getters }, thread) {
    if (!thread) {
      throw new Error('No thread to create');
    }

    let r = await api.threads.create(thread);
    let threadExists = !!getters['threadByIdentifier'](thread.identifier);
    await waitForEvent('thread-created', threadExists, false, 10000);
    return new ThreadModel(r.data);
  },

  /**
   * @param commit
   * @param getters
   * @param message MessageModel
   * @param thread ThreadModel
   * @returns {Promise<Object>}
   */
  async sendMessage({ commit, getters }, { message, thread }) {
    if (!thread) {
      throw new Error('Thread not found');
    }

    commit('appendMessage', { message, thread });

    return api.messages
      .send(message)
      .then((r) => {
        let newMessage = new MessageModel(r.data);
        commit('replaceMessage', { thread: thread, oldMessage: message, newMessage: newMessage });
        commit('setLastMessageId', { thread: thread, messageId: newMessage.id });
        commit('setLastRead', { lastRead: newMessage.createdAt, thread: thread });
        return r.data;
      })
      .catch((e) => {
        $.growl.error({ size: 'large', duration: 1000, namespace: 'growl', title: '', message: e, location: 'tc' });

        commit('rollbackMessage', { message, thread });
        throw e;
      });
  },

  async deleteMessage({ commit, getters }, { message, thread }) {
    if (!thread) {
      throw new Error('Thread not found');
    }
    let originalIndex = thread.messages.indexOf(message);
    if (originalIndex === -1) {
      throw new Error('Message not found in thread');
    }

    // save id
    const messageId = message.id;

    // remove message
    commit('rollbackMessage', { message, thread });

    return api.messages
      .delete(message.id, thread.id)
      .then((r) => {
        // if deleted message was last message, reload to get lastMessageId.
        thread.searchAttachments('', 1);

        return messageId;
      })
      .catch((e) => {
        //commit('insertMessage', {index, message, thread}); // todo rl rollback delete (aka insert)
        throw e;
      });
  },

  async updateMessage({ commit, getters }, { message, thread }) {
    if (!thread) {
      throw new Error('Thread not found');
    }

    // todo saveEditedMessage dereference message instead of mutating directly, this way we can rollback on error
    //commit('replaceMessage', {thread: thread, oldMessage: message, newMessage: newMessage});

    return api.messages
      .update(message)
      .then((r) => {
        let newMessage = new MessageModel(r.data);
        commit('replaceMessage', { thread: thread, oldMessage: message, newMessage: newMessage });
        return r.data;
      })
      .catch((e) => {
        // todo, rollback original message
        //commit('replaceMessage', {thread: thread, oldMessage: newMessage, newMessage: message});
        throw e;
      });
  },

  async preloadThread({ dispatch, commit, getters }, thread) {
    if (!thread) {
      return;
    }

    // last message id
    let messageId =
      thread.lastMessageId || thread.messages?.[thread.messages?.length - 1]?.id || thread.messages?.[0]?.id;
    // last message
    let messages = [getters['messageById'](thread, messageId)].filter((m) => !!m);
    if (messageId) {
      // remove previous messages (duplicates)
      commit('replaceMessages', { thread: thread, messages: messages });
      await dispatch('loadMessagesBefore', { thread: thread, messageId: messageId });
    }
  },

  async loadMessagesBefore({ commit, getters }, { messageId, thread }) {
    if (!thread) throw new Error('Thread not found');
    if (!messageId) throw new Error('Message ID not set');

    let messages = (await api.messages.listBefore(messageId, thread.id)).data;
    if (!messages || !Array.isArray(messages)) {
      throw new Error('No messages');
    }
    const currentMessageIdsInThread = thread.messages.map((m) => m.id);
    messages = messages.map((m) => new MessageModel(m)).filter((m) => !currentMessageIdsInThread.includes(m.id));
    commit('prependMessages', { messages, thread });
    return messages;
  },

  async loadMessagesAfter({ commit, getters }, { messageId, thread }) {
    if (!thread) throw new Error('Thread not found');
    if (!messageId) throw new Error('Message ID not set');

    let messages = (await api.messages.listAfter(messageId, thread.id)).data;
    if (!messages || !Array.isArray(messages)) {
      throw new Error('No messages');
    }
    const currentMessageIdsInThread = thread.messages.map((m) => m.id);
    messages = messages.map((m) => new MessageModel(m)).filter((m) => !currentMessageIdsInThread.includes(m.id));

    commit('appendMessages', { messages, thread });
    return messages;
  },

  async loadMessagesAround({ commit, getters }, { messageId, thread }) {
    if (!thread) throw new Error('Thread not found');
    if (!messageId) throw new Error('Message ID not set');

    let messages = (await api.messages.listAround(messageId, thread.id)).data;
    if (!messages || !Array.isArray(messages)) {
      throw new Error('No messages');
    }
    messages = messages.map((m) => new MessageModel(m));

    commit('replaceMessages', { messages, thread });
    return messages;
  },

  async postReaction({ commit, getters }, { messageModel, reactionResource }) {
    commit('incrementReaction', { messageModel, reactionResource });

    return api.messages
      .postReaction(messageModel.id, reactionResource.emoji)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        commit('decrementReaction', { messageModel, reactionResource });
        throw e;
      });
  },

  async deleteReaction({ commit, getters }, { messageModel, reactionResource }) {
    commit('decrementReaction', { messageModel, reactionResource });

    return api.messages
      .deleteReaction(messageModel.id, reactionResource.emoji)
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        commit('incrementReaction', { messageModel, reactionResource });
        throw e;
      });
  },
};
