<template>
  <div v-if="fields.length > 0" class="sidebar_block border-b border-grey-300">
    <div class="dark-white">
      <div>
        <ul class="nav text-left">
          <sidebar-collapse collapse-key="collapseTicketSidebarContactFieldEditor">
            <template #header>
              <li class="p">
                <h6 class="mb-0">
                  {{ $t('ticket_sidebar.ticket_fields') }}
                </h6>
              </li>
            </template>
            <div v-for="field in fields" :key="field.id">
              <div class="mt-3">
                <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
                <custom-field-item :field="field" @save="save(field)" />
              </div>
            </div>
          </sidebar-collapse>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { cloneDeep, lowerCase, remove, some } from 'lodash';
import { mapState } from 'vuex';

import SidebarCollapse from '@/components/common/SidebarCollapse';
import ticketSidebarMixins from '@/components/TicketSidebar/ticketSidebarMixins';
import eventBus from '@/eventBus';

import { storeTicketCustomField as apiStoreTicketCustomField } from './Api';
import CustomFieldItem from './CustomFieldItem';

export default {
  name: 'CustomFieldEditor',
  props: {
    ticket: { type: Object, required: true, default: () => ({}) },
  },
  mixins: [ticketSidebarMixins],

  data: function () {
    return {
      sidebarShowTicketFields: false,
      fields: [],
    };
  },

  mounted() {
    this.loadList();

    eventBus.$on('ticket.current.reload.done', () => {
      this.$nextTick(() => {
        this.loadList();
      });
    });
  },

  components: {
    SidebarCollapse,
    CustomFieldItem,
  },

  computed: {
    ...mapState({
      storedFields: (state) => state.customFields.customTicketFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
  },
  watch: {
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },

  methods: {
    save(field) {
      const fieldValue = field.value || null;
      this.commitFields();
      apiStoreTicketCustomField(this.ticket.id, field.id, fieldValue)
        .then(() => {
          this.resetFieldServerError(lowerCase(field.type), field.id);
        })
        .catch((error) => {
          this.setFieldServerError(lowerCase(field.type), field.id, error.response?.data?.errors);
        })
        .finally(() => {
          this.$nextTick(this.commitFields);
        });
    },
    commitFields() {
      this.$store.commit('customFields/setTicketTicketFields', cloneDeep(this.fields));
    },
    loadList() {
      this.fields = cloneDeep(this.storedFields);
      remove(this.fields, (field) => {
        return field.channels.length && !some(field.channels, ['id', this.ticket.channel.id]);
      });
      this.fields = this.fields.map((field) => {
        return {
          ...field,
          value: this.ticket?.custom_data ? this.ticket.custom_data[field.title] : null,
          ticketId: this.ticket?.id,
        };
      });
      this.commitFields();
    },
  },
};
</script>
