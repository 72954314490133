<script type="text/babel">
import SidebarCollapse from '@/components/common/SidebarCollapse';

import countries from '../../util/countries.json';

export default {
  name: 'ChatTech',
  emits: ['statusChanged', 'onClientComposing'],
  data: function () {
    return {
      online: false,
      url: null,
      ip: '',
      country: null,
      countryName: null,
      channel: null,
      channelName: null,
      offlineTimer: null,
      chatInfo: null,
      countries: countries,
      presenceChannel: null,
    };
  },

  components: {
    SidebarCollapse,
  },

  props: {
    ticket: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  watch: {
    online(v) {
      this.$emit('statusChanged', v);
    },
  },

  mounted: function () {
    this.subscribe();
    this.loadVisitor();
  },

  unmounted: function () {
    if (this.channelName != null) {
      PusherInstance.unsubscribe(this.channelName);
    }
  },

  methods: {
    subscribe: function () {
      var contactKey = this.ticket.contact.phone;

      this.channelName = 'presence-ticket@' + contactKey;
      var channel = PusherInstance.subscribe(this.channelName);

      this.presenceChannel = channel;

      channel.bind('pusher:subscription_succeeded', (members) => {
        if (members.count >= 2) {
          $.each(members.members, (key, member) => {
            if (key == contactKey) {
              this.handleClientAdded(member);
            }
          });
        }

        channel.bind('pusher:member_added', (member) => {
          if (member.id == contactKey) {
            this.handleClientAdded(member.info);
          }
        });

        channel.bind('pusher:member_removed', (member) => {
          if (member.id == contactKey) {
            this.offlineTimer = setTimeout(() => {
              this.handleClientRemoved();
            }, 100);
          }
        });
      });

      channel.bind('client-typing', (data) => {
        this.$emit('onClientComposing', data.message);
      });
    },

    loadVisitor() {
      axios.get('/api/v2/tickets/' + this.ticket.id + '/chat_info').then((res) => {
        this.chatInfo = res.data;
      });
    },

    handleClientAdded: function (client) {
      clearTimeout(this.offlineTimer);

      this.online = true;

      this.url = {
        long: client.url.long,
        short: client.url.short,
      };
    },

    handleClientRemoved: function () {
      this.online = false;
      this.url = null;
      this.ip = {};
    },
  },
};
</script>

<template>
  <div v-if="ticket.channel.type === 'CHAT'" class="sidebar_block border-b border-grey-300">
    <sidebar-collapse collapse-key="collapseTicketSidebarTech">
      <template #header>
        <h6 class="mb-0">
          {{ $t('ticket_sidebar.chat_tech') }}
        </h6>
      </template>
      <div>
        <div class="m-b-xs">
          <div class="mt-3">
            <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.chatter_status') }}</div>
            <div class="sidebar-label-content text-grey-600">
              <div class="capitalize">
                <span class="label label-xs success" :class="{ success: online, danger: !online }"></span>
                <span v-if="online">{{ $t('ticket_sidebar.online') }}</span>
                <span v-if="!online">{{ $t('ticket_sidebar.offline') }}</span>
              </div>
            </div>
          </div>
          <div v-if="url && safeUrl(url.long)" class="mt-3">
            <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.chat_active_url') }}</div>
            <div class="sidebar-label-content text-grey-600">
              <a target="_blank" rel="noopener noreferrer" :href="safeUrl(url.long)" class="text-success">
                {{ url.short }}
              </a>
            </div>
          </div>
          <div v-if="chatInfo !== null && Object.keys(chatInfo).length > 0 && !$root.anonymousModule">
            <div v-if="chatInfo.country" class="mt-3">
              <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.chat_tech_country') }}</div>
              <div class="sidebar-label-content text-ellipsis text-grey-600">
                <img :src="chatInfo.country_flag" width="20" />
                {{ countries[chatInfo.country] }}
              </div>
            </div>
            <div class="mt-3">
              <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.chat_tech_browser') }}</div>
              <div class="sidebar-label-content text-ellipsis text-grey-600">{{ chatInfo.browser }}</div>
            </div>
            <div class="mt-3">
              <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.chat_tech_os') }}</div>
              <div class="sidebar-label-content text-ellipsis text-grey-600">{{ chatInfo.os }}</div>
            </div>
            <div class="mt-3">
              <div class="sidebar-label-title text-grey-500">
                {{ $t('ticket_sidebar.chat_tech_screen_resolution') }}
              </div>
              <div class="sidebar-label-content text-ellipsis text-grey-600">{{ chatInfo.resolution }}</div>
            </div>
            <div class="mt-3">
              <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.ip_address') }}</div>
              <div class="sidebar-label-content text-ellipsis text-grey-600">{{ chatInfo.remote_address }}</div>
            </div>
            <div class="mt-3">
              <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.website') }}</div>
              <div class="sidebar-label-content text-ellipsis text-grey-600">
                <a
                  style="text-decoration: underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="safeUrl(chatInfo.domain)"
                >
                  {{ chatInfo.domain.replace('http://', '').replace('https://', '') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </sidebar-collapse>
  </div>
</template>
