import { request } from '@/util';

export default {
  namespaced: true,
  state: {
    businessHours: [],
    businessHour: {},
  },
  mutations: {
    setBusinessHours(state, payload) {
      state.businessHours = payload;
    },
    setBusinessHour(state, payload) {
      state.businessHour = payload;
    },
  },
  actions: {
    fetchBusinessHours({ commit, state }, payload) {
      //prevent to cll the api if channels state is full.
      //of course it is not persist and after refreshing it will call again
      if (payload && payload.force_fetch) {
        request('/api/v2/business_hours', 'GET').then((res) => {
          commit('setBusinessHours', res.data.data);
        });
      } else if (state.businessHours.length === 0) {
        request('/api/v2/business_hours', 'GET').then((res) => {
          commit('setBusinessHours', res.data.data);
        });
      }
    },
    fetchBusinessHour({ commit, state }, payload) {
      if (payload && payload.id) {
        request(`/api/v2/business_hours/${payload.id}`, 'GET').then((res) => {
          commit('setBusinessHour', res.data);
        });
      }
    },
  },
  getters: {
    businessHours(state) {
      return state.businessHours;
    },
    businessHour(state) {
      return state.businessHour;
    },
  },
};
