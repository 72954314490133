<script type="text/babel">
import { head, map, toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import { uploadFiles } from '@/util/fileHelper';

import Editor from './Editor.vue';
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';

export default {
  name: 'HelpCenterArticle',
  props: {
    helpCenter: {
      type: Object,
      required: true,
    },
    localeCodes: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Multiselect,
    Editor,
  },

  mixins: [RouterWarningOnEdit],

  data() {
    return {
      record: {
        title: '',
        content: '',
      },
      article: {
        translations: [],
      },
      attachments: [],
      tags: [],
      category: {},
      categories: [],
      highlighted: 0,
      localeCode: this.helpCenter.default_language,
      published: false,
      dirty: false,
      saving: false,
      showAttachmentsModal: false,
    };
  },

  async mounted() {
    this.loadArticle();

    this.loadCategories();

    if (await this.$tStorage.getItem('hc.selected_locale')) {
      this.localeCode = await this.$tStorage.getItem('hc.selected_locale');
    }

    this.setToolbarWidth();
    window.addEventListener('resize', this.setToolbarWidth);
  },

  computed: {
    articleUrl() {
      return this.helpCenter.url + '/' + this.localeCode + '/articles/' + this.getTranslation(this.article).slug;
    },
  },

  watch: {
    localeCode() {
      const translatedArticle = this.getTranslation(this.article);
      this.record = Object.assign({}, this.record, {
        content: translatedArticle.content,
        title: translatedArticle.title,
      });
      this.published = translatedArticle.published;

      if (translatedArticle.attachments) {
        this.attachments = translatedArticle.attachments;
      }

      this.$tStorage.setItem('hc.selected_locale', this.localeCode);
    },

    title() {
      this.saveDraft('title', this.record.title);
    },

    content() {
      this.saveDraft('content', this.record.content);
    },
  },

  methods: {
    loadArticle() {
      if (this.$route.params.article_id) {
        this.loading = true;
        axios
          .get('/api/v2/help_center/' + this.$route.params.id + '/articles/' + this.$route.params.article_id)
          .then((res) => {
            this.article = res.data;
            this.loading = false;
            this.setValues();
            this.restoreDraft();
            this.initWarning();
          })
          .catch(() => {
            this.initWarning(false);
            router.push('/help_center/' + this.$route.params.id + '/articles');
          });
      } else {
        this.initWarning();
        this.restoreDraft();
      }
    },

    setValues() {
      this.published = this.getTranslation(this.article).published;
      this.record.content = this.getTranslation(this.article).content;
      this.record.title = this.getTranslation(this.article).title;
      this.tags = this.article.tags;
      this.highlighted = this.article.highlighted;

      if (this.getTranslation(this.article).attachments) {
        this.attachments = this.getTranslation(this.article).attachments;
      }

      if (this.article.category != null) {
        this.category = {
          id: this.article.category.id,
          title: this.getCategoryTranslation(this.article.category).title,
        };
      }
    },

    getTranslation(record, locale) {
      if (locale == null) {
        locale = this.localeCode;
      }

      var trans = head(
        record.translations?.filter((trans) => {
          return trans.locale_code == locale;
        })
      );
      if (trans == null) {
        trans = {
          published: false,
          title: null,
        };
      }
      return trans;
    },

    getCategoryTranslation(record) {
      var trans = head(
        record.translations.filter((trans) => {
          return trans.locale_code == this.helpCenter.default_language;
        })
      );

      if (trans == null) {
        trans = {
          title: this.$t('help_center.untitled'),
        };
      }
      return trans;
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        fresh: true,
      };

      this.tags.push(tag);
    },

    detectTags() {
      axios
        .post('/api/v2/help_center/tags/detect', {
          content: this.record.content,
        })
        .then((tags) => {
          console.log(tags);
        });
    },

    save() {
      if (!this.article.id) {
        this.saving = true;
        axios.post('/api/v2/help_center/' + this.$route.params.id + '/articles').then((article) => {
          this.saveDraft('content', null);
          this.saveDraft('title', null);

          this.article = article.data;
          this.saving = false;

          this.update();
        });
      } else {
        this.update();
      }
    },

    update() {
      let attachment_ids = [];

      this.attachments.forEach((element) => {
        if (!this.attachments.includes(element.id)) {
          attachment_ids.push(element.id);
        }
      });

      if (this.saving) {
        return;
      }

      this.saving = true;

      axios
        .put('/api/v2/help_center/' + this.helpCenter.id + '/articles/' + this.article.id, {
          content: this.record.content,
          title: this.record.title,
          locale_code: this.localeCode,
          tags: this.tags,
          highlighted: this.highlighted,
          category_id: this.category == null ? null : this.category.id,
          published: this.published,
          attachment_ids: attachment_ids,
        })
        .then((res) => {
          this.saving = false;
          this.saveDraft('content', null);
          this.saveDraft('title', null);
          this.article.translations = res.data.translations;
          this.flashSuccess(this.$t('help_center.you_have_successfully_updated_this_article'));
          this.initWarning(false);
          router.push('/help_center/' + this.article.help_center_id + '/articles/' + this.article.id);
        })
        .catch(() => {
          this.saving = false;
        });
    },

    setPublished(published) {
      this.published = published;
    },

    setLocaleCode(locale_code) {
      let warning = this.$t('help_center.are_you_sure_you_want_to_discard_your_unsaved_changes');

      if (this.localeCode == locale_code || (this.dirty && !confirm(warning))) {
        return;
      }

      this.localeCode = locale_code;
    },

    saveDraft(field, value) {
      return; // TODO: why is this here?
      let k = this.article.id;
      if (k == null) {
        k = this.$route.params.id + '.new';
      }

      let name = 'article_draft_' + k + '_' + this.localeCode + '_' + field;

      if (value == null || value == '') {
        this.$tStorage.removeItem(name);
        return;
      }
      this.$tStorage.setItem(name, value);
    },

    async getDraft(field) {
      return; //TODO: should we delete the whole function?
      let k = this.article.id;
      if (k == null) {
        k = this.$route.params.id + '.new';
      }

      return await this.$tStorage.getItem('article_draft_' + k + '_' + this.localeCode + '_' + field);
    },

    restoreDraft() {
      return;
      if (this.getDraft('title') != null) {
        this.title = this.getDraft('title');
      }

      if (this.getDraft('content') != null) {
        this.content = this.getDraft('content');
      }
    },

    loadCategories() {
      axios.get('/api/v2/help_center/' + this.$route.params.id + '/categories').then((res) => {
        this.categories = toArray(
          map(res.data, (category) => {
            return {
              id: category.id,
              title: this.getCategoryTranslation(category).title,
            };
          })
        );
      });
    },

    cancel() {
      /*if (this.dirty && !confirm('Are you sure you want to discard your unsaved changes? ')) {
                    return;
                }*/

      this.$router.go(-1);
    },

    openAttachmentsModal() {
      $(this.$refs.attachmentsModal).modal('show');
    },

    addAttachment(e) {
      uploadFiles(e.target.files, 'hc_article_attachment').then((result) => {
        this.attachments.push({
          id: result.data[0].attachment_id,
          client_name: result.data[0].client_name,
          size: result.data[0].file_size,
          url: result.data[0].file_url,
          mime_type: result.data[0].file_type,
        });
      });
    },

    async deleteAttachment(attachment) {
      const shouldDeleteAttachment = await this.$tConfirm(
        this.$t('help_center.are_you_sure_you_want_to_delete_this_attachment'),
        {
          delete: true,
          title: this.$t('help_center.are_you_sure'),
        }
      );
      if (shouldDeleteAttachment) {
        // todo this.$root.deleteFile()
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
        axios.delete('/api/v2/attachments/' + attachment.id);
      }
    },

    async remove() {
      const shouldDeleteArticle = await this.$tConfirm(
        this.$t('help_center.are_you_sure_you_want_to_delete_this_article'),
        {
          delete: true,
          title: this.$t('help_center.are_you_sure'),
        }
      );
      if (shouldDeleteArticle) {
        await axios.delete(
          '/api/v2/help_center/' + this.helpCenter.id + '/articles/' + this.$route.params.article_id,
          {}
        );
        this.initWarning(false);
        router.push('/help_center/' + this.helpCenter.id + '/articles');
      }
    },
    setToolbarWidth() {
      const editor = document.querySelector('.stickyEditor');
      const toolbar = document.querySelector('.stickyEditor .fr-toolbar');
      if (editor && toolbar) {
        toolbar.style.minWidth = editor.getBoundingClientRect().width + 'px'; // minWidth instead of width so that froala editor doesn't remove our width
        toolbar.style.maxWidth = editor.getBoundingClientRect().width + 'px'; // maxWidth instead of width so that froala editor doesn't remove our width
      }
    },
  },
};
</script>

<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span>
            <button
              class="btn btn-success text-white"
              :disabled="saving || record.title == null || record.title == ''"
              @click="save()"
              v-text="saving ? $t('help_center.saving') : $t('help_center.save')"
            ></button>
            <button class="btn white m-x-xs hidden-sm-down" @click="cancel()">
              {{ $t('general.cancel') }}
            </button>
            <a
              v-if="helpCenter.published && getTranslation(this.article).published"
              class="btn white d-inline"
              target="_blank"
              :href="articleUrl"
            >
              <i class="fa fa-external-link"></i>
              {{ $t('help_center.view_in_browser') }}
            </a>
          </span>
          <div class="m-l-auto">
            <button v-if="this.article.id" class="btn white" @click="remove">{{ $t('general.delete') }}</button>
          </div>
        </li>
      </ul>
    </div>

    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-t" style="margin: 0 auto; max-width: 900px">
            <!--<div class="col-md-12">-->

            <!--<div class="box">-->
            <!--<div class="box-body flow-root">-->
            <!--<div class="pull-left">-->
            <!--<button class="btn btn-success text-white" @click="save" v-text="saving ? 'Saving...' : 'Save'" :disabled="saving || title == null || title == ''"></button>-->
            <!--<button class="btn white m-x-xs" @click="cancel">Cancel</button>-->
            <!--<a class="btn white" target="_blank" v-if="helpCenter.published && getTranslation(this.article).published" :href="articleUrl"><i class="fa fa-external-link"></i> View in browser</a>-->
            <!--</div>-->
            <!--<div class="pull-right">-->
            <!--<button class="btn white" @click="remove">Delete</button>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <div class="col-md-12">
              <div class="box">
                <div class="box-body">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          <strong>{{ $t('help_center.category') }}:</strong>
                        </label>
                        <multiselect
                          v-model="category"
                          :placeholder="$t('help_center.select_an_option')"
                          :select-label="$t('help_center.press_enter_to_select')"
                          :deselect-label="$t('help_center.press_enter_to_remove')"
                          :options="categories"
                          track-by="id"
                          label="title"
                          :searchable="true"
                          :multiple="false"
                          @tag="addTag"
                        >
                          <template #noResult>
                            <span>{{ $t('help_center.query_no_results') }}</span>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <!--<div class="col-md-4">-->
                    <!--<div class="form-group">-->
                    <!--<label class="display-flex"><strong>Tags:</strong></label>-->
                    <!--<multiselect :options="[]" v-model="tags" label="name" @tag="addTag" :taggable="true" :multiple="true" placeholder="Tag your article to match related articles."></multiselect>-->
                    <!--</div>-->
                    <!--</div>-->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          <strong>{{ $t('help_center.in_the_spotlight') }}</strong>
                        </label>
                        <select v-model="highlighted" class="form-control">
                          <option value="1">{{ $t('general.yes') }}</option>
                          <option value="0">{{ $t('general.no') }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box">
                <div class="box-body b-b">
                  <div class="flow-root">
                    <div class="pull-left">
                      <div class="btn-group">
                        <button
                          v-if="localeCodes[localeCode]"
                          type="button"
                          class="btn white dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {{ localeCodes[localeCode].text }}
                        </button>
                        <div class="dropdown-menu">
                          <a v-for="lang in helpCenter.languages" class="dropdown-item" @click="setLocaleCode(lang)">
                            <div class="display-flex v-align">
                              <b
                                v-if="getTranslation(article, lang).published"
                                class="label label-xs success status-label rounded"
                              ></b>
                              <b
                                v-if="!getTranslation(article, lang).published"
                                class="label label-xs warning status-label rounded"
                              ></b>
                              <span class="p-l-md">
                                <small v-if="localeCodes[lang]" class="text-muted">
                                  {{ localeCodes[lang].text }}:
                                  <br />
                                </small>
                                <span v-if="getTranslation(article, lang).title == null" class="text-muted">
                                  <em>{{ $t('help_center.not_translated') }}</em>
                                </span>
                                <span v-else>
                                  {{ getTranslation(article, lang).title }}
                                </span>
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="pull-right">
                      <div class="btn-group">
                        <a class="btn btn-default ml-2" @click="openAttachmentsModal()">
                          <i class="fa fa-paperclip"></i>
                          {{ $t('help_center.manage_attachments') }} ({{ attachments.length }})
                        </a>
                        <button
                          type="button"
                          class="btn white dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span v-show="published">
                            <b class="label label-xs success status-label rounded"></b>
                            {{ $t('help_center.published') }}
                          </span>

                          <span v-show="!published">
                            <b class="label label-xs warning status-label rounded"></b>
                            {{ $t('help_center.draft') }}
                          </span>
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" @click="setPublished(0)">
                            <b class="label label-xs warning status-label rounded"></b>
                            {{ $t('help_center.draft') }}
                          </a>
                          <a class="dropdown-item" @click="setPublished(1)">
                            <b class="label label-xs success status-label rounded"></b>
                            {{ $t('help_center.published') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-body">
                  <h3 style="width: 100%; padding: 16px">
                    <input
                      v-model="record.title"
                      style="width: 100%"
                      type="text"
                      class="input-transparant"
                      :placeholder="$t('help_center.article_title_here')"
                      @change="dirty = true"
                    />
                  </h3>
                  <editor
                    v-model="record.content"
                    :bottom-toolbar="true"
                    :sticky-toolbar="true"
                    :sticky-toolbar-offset="0"
                    @change="dirty = true"
                  ></editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="attachmentsModal" class="modal">
      <div class="modal-centered height-auto">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('help_center.attachments') }}</h5>
            <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th width="30%">{{ $t('help_center.file_name') }}</th>
                <th width="30%">{{ $t('help_center.file_size') }}</th>
                <th width="30%">{{ $t('help_center.file_type') }}</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attachment in attachments">
                <td>
                  <a target="_blank" :href="attachment.url" class="w-128px truncate text-ellipsis sm:w-200px">
                    {{ attachment.client_name }}
                  </a>
                </td>
                <td>{{ attachment.size }}</td>
                <td>{{ attachment.mime_type }}</td>
                <td>
                  <a class="btn btn-xs btn-danger text-white" @click="deleteAttachment(attachment)">
                    <i class="fa fa-remove"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  <label for="a" class="btn btn-default" style="cursor: pointer; display: inherit; margin-bottom: 0">
                    <i class="fa fa-paperclip"></i>
                    {{ $t('help_center.add_files') }}
                    <input id="a" ref="input" type="file" hidden @change="addAttachment($event)" />
                  </label>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
