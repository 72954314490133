export const createToolbarElement = () => {
  window.missingPermissions = [];
  const toolbarId = 'build-toolbar';

  if (!document.getElementById(toolbarId)) {
    const bannerElm = document.createElement('div');
    bannerElm.textContent = `You are using build number: ${window.DYNAMIC_BUILD_VERSION}`;

    const bannerStyles = {
      id: toolbarId,
      textAlign: 'center',
      background: 'rgba(0,0,0,0.5)',
      color: 'white',
      position: 'absolute',
      top: '5px',
      right: '5px',
      borderRadius: '8px',
      zIndex: '999999',
      padding: '10px',
      cursor: 'pointer',
    };

    for (const [style, value] of Object.entries(bannerStyles)) {
      bannerElm.style[style] = value;
    }

    const removeTheToolbar = () => {
      bannerElm.style.display = 'none';
    };
    bannerElm.addEventListener('click', removeTheToolbar);
    document.getElementsByTagName('body')[0].prepend(bannerElm);
  }
};
