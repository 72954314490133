import { debounce, isEqual } from 'lodash';

import { duplicateObject } from '@/util/helpers';

export default {
  /*
        ### Installation ###

        Required:
            data() {
                return {
                    record: {},
                }
            }

        Make sure you call this.initWarning() after loading this.record.
        Some pages edit this.record after initially loading, so sometimes a setTimeout() or await should be used:
            setTimeout(() => {
                this.initWarning();
            }, 1500);


        Do you want to redirect the user and/or stop this mixin? Call:
            this.initWarning(false);


        Optional:
            this.loaded = true will be set on this.initWarning() so you could add a loader until this.loaded = true. this.loaded is NOT internally used for this mixin's logic.
            Notice: this.loaded will not be changed when calling this.initWarning(false)
    */

  data: () => {
    return {
      record: {},
      loaded: false,
      initialRecord: null,
      recordIsDirty: false,
    };
  },

  watch: {
    record: {
      deep: true,
      handler: debounce(function (v) {
        if (!this.loaded) {
          return;
        }
        this.recordIsDirty = this.isDirty();
      }, 150),
    },
  },

  methods: {
    showConfirm() {
      return window.confirm(this.$t('general.warning_text_leaving_page_unsaved_changes'));
    },

    beforeUnload(e) {
      if (this.isDirty()) {
        e.preventDefault();
        e.returnValue = this.$t('general.warning_text_leaving_page_unsaved_changes');
        return this.$t('general.warning_text_leaving_page_unsaved_changes');
      }
      delete e.returnValue;
    },

    isDirty() {
      return this.initialRecord !== null && !isEqual(this.initialRecord, this.record);
    },

    initWarning(val = true) {
      if (val) {
        this.initialRecord = duplicateObject(this.record);
        this.loaded = true;
      } else {
        this.initialRecord = null;
      }
    },

    revertDirtyChanges() {
      this.record = duplicateObject(this.initialRecord);
    },
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },

  beforeRouteUpdate(to, from, next) {
    if (!this.isDirty() || this.showConfirm()) {
      next();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (!this.isDirty() || this.showConfirm()) {
      next();
    }
  },
};
