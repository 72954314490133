import type { Permission } from '@/types';

export const PERMISSION = {
  BROADCASTING__GENERAL__MANAGE: 'broadcasting.general.manage',
  CONTACTS__CONTACTS__DELETE: 'contacts.contacts.delete',
  CONTACTS__PROFILES__DELETE: 'contacts.profiles.delete',
  HELP_CENTER__GENERAL__MANAGE: 'help_center.general.manage',
  INBOX__CONVERSATIONS__ASSIGN: 'inbox.conversations.assign',
  INBOX__CONVERSATIONS__CLOSE: 'inbox.conversations.close',
  INBOX__CONVERSATIONS__CREATE: 'inbox.conversations.create',
  INBOX__CONVERSATIONS__DELETE: 'inbox.conversations.delete',
  INBOX__CONVERSATIONS__DOWNLOAD: 'inbox.conversations.download',
  INBOX__CONVERSATIONS__READ_FOR_TEAM: 'inbox.conversations.read_for_team',
  INBOX__CONVERSATIONS__REPLY: 'inbox.conversations.reply',
  PERMISSIONS__ROLES__MANAGE: 'permissions.roles.manage',
  PERMISSIONS__TEAMS__MANAGE: 'permissions.teams.manage',
  PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE: 'permissions.users.assign_admin_role',
  PERMISSIONS__USERS__MANAGE: 'permissions.users.manage',
  REPORTING__GENERAL__MANAGE: 'reporting.general.manage',
  SETTINGS__APPS_INTEGRATIONS__MANAGE: 'settings.apps_integrations.manage',
  SETTINGS__AUTO_REPLIES__MANAGE: 'settings.auto_replies.manage',
  SETTINGS__BUSINESS_HOURS__MANAGE: 'settings.company_profile.manage_business_hours',
  SETTINGS__CHANNELS__MANAGE: 'settings.channels.manage',
  SETTINGS__CHATBOTS__MANAGE: 'settings.chatbots.manage',
  SETTINGS__COMPANY_PROFILE__MANAGE: 'settings.company_profile.manage',
  SETTINGS__CONTACT_GROUPS__MANAGE: 'settings.contact_groups.manage',
  SETTINGS__CSAT__MANAGE: 'settings.csat.manage',
  SETTINGS__CUSTOM_FIELDS__MANAGE: 'settings.custom_fields.manage',
  SETTINGS__FLOWBOTS__MANAGE: 'settings.flowbots.manage',
  SETTINGS__LABELS__MANAGE: 'settings.labels.manage',
  SETTINGS__LOCATIONS__MANAGE: 'settings.company_profile.manage_locations',
  SETTINGS__QUICK_REPLIES__MANAGE: 'settings.quick_replies.manage',
  SETTINGS__RULES__MANAGE: 'settings.rules.manage',
  SETTINGS__TICKET_RESULTS__MANAGE: 'settings.ticket_results.manage',
  SETTINGS__VIEWS__MANAGE: 'settings.views.manage',
  SETTINGS__WEBSITE_WIDGETS__MANAGE: 'settings.website_widgets.manage',
  SETTINGS__WIDGET_AUTOMATIONS__MANAGE: 'settings.widget_automations.manage',
  AI_SETTINGS: 'settings.ai_settings',
} as const;

/**
 * If you have any of these permissions,
 * you are allowed to access the Settings area.
 */
export const SETTINGS_PERMISSIONS: Permission[] = [
  PERMISSION.PERMISSIONS__ROLES__MANAGE,
  PERMISSION.PERMISSIONS__TEAMS__MANAGE,
  PERMISSION.PERMISSIONS__USERS__ASSIGN_ADMIN_ROLE,
  PERMISSION.PERMISSIONS__USERS__MANAGE,
  PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE,
  PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE,
  PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE,
  PERMISSION.SETTINGS__CHANNELS__MANAGE,
  PERMISSION.SETTINGS__CHATBOTS__MANAGE,
  PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE,
  PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE,
  PERMISSION.SETTINGS__CSAT__MANAGE,
  PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE,
  PERMISSION.SETTINGS__FLOWBOTS__MANAGE,
  PERMISSION.SETTINGS__LABELS__MANAGE,
  PERMISSION.SETTINGS__LOCATIONS__MANAGE,
  PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE,
  PERMISSION.SETTINGS__RULES__MANAGE,
  PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE,
  PERMISSION.SETTINGS__VIEWS__MANAGE,
  PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE,
  PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE,
];
