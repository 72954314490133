import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import hasPermission from '@/middleware/hasPermission';
import { useSubscriptionStore } from '@/store/pinia';

const hasQuote = async (to, _from, next) => {
  const isEditDisabled = useSubscriptionStore().subscription.quote; // FIXME: check if store is loaded

  if (isEditDisabled) {
    next({ name: 'subscription' });
  }

  next();
};

export default [
  {
    path: '/admin/company_profile/subscription',
    redirect: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
  },
  {
    path: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
    name: 'subscription',
    meta: { permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE },
    component: () => import(/* webpackChunkName: "Subscription" */ '../../components/Subscription/Index.vue'),
    beforeEnter: hasPermission,
    children: [
      {
        name: 'subscription_edit',
        path: `${SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS}/:id`,
        component: () => import(/* webpackChunkName: "SubscriptionEdit" */ '../../components/Subscription/Edit.vue'),
        beforeEnter: hasQuote,
      },
    ],
  },
];
