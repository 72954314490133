<template>
  <div>
    <div class="">
      <div class="sidebar-label-title mb-1 text-grey-500">
        <i class="material-icons md-20 align-middle">access_time</i>
        <span class="align-middle">{{ $t('ticket_sidebar.time_tracking_working_time') }}</span>
      </div>
      <div class="sidebar-label-content text-grey-600">
        <div class="flex items-center">
          <div>
            <input
              v-model="hours"
              type="text"
              maxlength="2"
              class="tt form-control w-full"
              style="width: 47px"
              @focus="selectInput"
              @change="updateTime"
            />
          </div>
          <div class="px-2 text-lg">:</div>
          <div>
            <input
              v-model="minutes"
              type="text"
              maxlength="2"
              class="tt form-control w-full"
              style="width: 47px"
              @focus="selectInput"
              @change="updateTime"
            />
          </div>
          <div class="px-2 text-base">
            {{ $t('general.date_at') }}
          </div>
          <div>
            <date-time-picker2
              v-model="recordProxy.start_time"
              class="tt form-control invisible-flat-picker w-full"
              :config="timePickerConfig"
            ></date-time-picker2>
          </div>
        </div>

        <!--                <date-time-picker2 class="w-full tt form-control" v-model="recordProxy.start_time" :config="timePickerConfig"></date-time-picker2>-->
        <!--                &lt;!&ndash; todo optional: add a 'now' button (button-group) &ndash;&gt;-->
      </div>
    </div>
    <!--        <div class="">-->
    <!--            <div class="sidebar-label-title text-grey-500">-->
    <!--                <i class="material-icons align-middle md-20">access_time</i>-->
    <!--                <span class="align-middle">{{$t('ticket_sidebar.time_tracking_start_time')}}</span>-->
    <!--            </div>-->
    <!--            <div class="sidebar-label-content text-grey-600">-->
    <!--                <date-time-picker2 class="w-full tt form-control" v-model="recordProxy.start_time" :config="timePickerConfig"></date-time-picker2>-->
    <!--                &lt;!&ndash; todo optional: add a 'now' button (button-group) &ndash;&gt;-->
    <!--            </div>-->
    <!--        </div>-->

    <!--        <div class="mt-3">-->
    <!--            <div class="sidebar-label-title text-grey-500">-->
    <!--                <i class="material-icons align-middle md-20">av_timer</i>-->
    <!--                <span class="align-middle">{{$t('ticket_sidebar.time_tracking_end_time')}}</span>-->
    <!--            </div>-->
    <!--            <div class="sidebar-label-content text-grey-600">-->
    <!--                <date-time-picker2 class="w-full tt form-control" v-model="recordProxy.end_time" @close="onClose" :config="timePickerConfig"></date-time-picker2>-->
    <!--            </div>-->
    <!--        </div>-->

    <div class="mt-3">
      <div class="sidebar-label-title mb-1 text-grey-500">
        <i class="material-icons md-20 align-middle">access_time</i>
        <span class="align-middle">{{ $t('general.description') }}</span>
      </div>
      <div class="sidebar-label-content text-grey-600">
        <textarea
          v-model="recordProxy.description"
          class="tt form-control w-full"
          :placeholder="$t('general.description')"
        ></textarea>
      </div>
    </div>

    <div class="mt-3">
      <div class="sidebar-label-content text-grey-600">
        <button class="tt button" @click="saveClicked">
          <i class="material-icons md-20">save</i>
          <span>{{ $t('ticket_sidebar.time_tracking_save') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker2 from '../../Elements/DateTimePicker2';

export default {
  name: 'Edit',
  components: { DateTimePicker2 },
  emits: ['save'],
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: 'create',
      validate: (value) => ['create', 'edit'].includes(value),
    },
    target: {
      type: String,
      default: null,
    },
  },

  data() {
    let now = new Date();
    return {
      hours: 0,
      minutes: 0,
      endTimeInterval: null,
      recordProxy: { ...this.record },
      timePickerConfig: {
        enableTime: false,
        defaultHour: now.getHours(),
        defaultMinute: now.getMinutes(),
        mode: 'single',
        defaultDate: now,
        //allowInput: true,
        time_24hr: false,
        altInput: true,
        altFormat: this.$t('general.format_day_month'),
        dateFormat: 'U',
        //inline: true,
      },
    };
  },

  mounted() {
    // this.initEndTimeInterval();

    if (this.action === 'edit') {
      let diff = moment.duration(
        moment(this.recordProxy.end_time * 1000).diff(moment(this.recordProxy.start_time * 1000))
      );
      let hours = diff.hours();
      if (diff.days() > 0) {
        hours += diff.days() * 24;
      }
      this.hours = hours;
      this.minutes = diff.minutes();
    }
  },

  beforeUnmount() {
    //clearInterval(this.endTimeInterval);
  },

  methods: {
    updateTime() {
      let start_time = moment(this.recordProxy.start_time * 1000);
      start_time.add(this.hours, 'hours');
      start_time.add(this.minutes, 'minutes');
      this.recordProxy.end_time = start_time.unix();
    },

    selectInput($e) {
      $e.target.setSelectionRange(0, $e.target.value.length);
    },
    initEndTimeInterval() {
      if (this.action === 'create' && this.record.end_time === null) {
        clearInterval(this.endTimeInterval);
        this.recordProxy = Object.assign({}, this.recordProxy, {
          end_time: parseInt((new Date().getTime() / 1000).toFixed()),
        });
        this.endTimeInterval = setInterval(() => {
          this.recordProxy = Object.assign({}, this.recordProxy, {
            end_time: this.recordProxy.end_time + 60,
          });
        }, 60000);
      }
    },
    onClose() {
      // fixme should actually clear onOpen... But since the interval is a minute, I dont think they keep the date selector open for more than a minute...
      clearInterval(this.endTimeInterval);
    },
    saveClicked() {
      this.updateTime();
      if (this.action === 'create') {
        this.hours = 0;
        this.minutes = 0;
      }

      if (this.target) {
        $(this.target).collapse('hide');
      }

      this.$emit('save', this.recordProxy);
    },
  },

  watch: {
    record: {
      deep: true,
      handler(v) {
        this.recordProxy = { ...v };
        ///this.initEndTimeInterval();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// Trengo Theme Variables
@import '../../../assets/sass/_variables.scss';

.sidebar-label-title text-grey {
  font-weight: 500;
  margin-bottom: 10px;
}
</style>
