import { request } from '@/util';

import type { AxiosPromise } from 'axios';

const baseUrl = '/api/v2/me/';
const twoFactorUrl = 'two_factor_auth';

const endpoints = {
  twoFactorAuthenticationSettings: `${baseUrl}${twoFactorUrl}`,
  twoFactorQRCode: `${baseUrl}${twoFactorUrl}_qr`,
  twoFactorAuthenticationRecord: `${baseUrl}${twoFactorUrl}/0`,
  trustedDevices: `${baseUrl}trusted_devices`,
} as const;

export function getTwoFactorAuthenticationSettings(): AxiosPromise<{
  enabled: boolean;
  trusted_devices: string[];
}> {
  return request(endpoints.twoFactorAuthenticationSettings, 'GET');
}

export function getTwoFactorQRCode(): AxiosPromise<{ image: string; secret: string }> {
  return request(endpoints.twoFactorQRCode, 'GET');
}

export function deleteTwoFactorAuthentication() {
  return request(endpoints.twoFactorAuthenticationRecord, 'DELETE');
}

export function deleteTrustedDevice(id: number) {
  return request(`${endpoints.trustedDevices}/${id}`, 'DELETE');
}

export function sendVerificationCode(otp: string | null) {
  return request(endpoints.twoFactorAuthenticationSettings, 'POST', { otp });
}
