import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { fetchUser } from '@/api';
import { adminRoles } from '@/Configs/Constants/UserRoles';

import type { User } from '@/types';

export const useUserStore = defineStore('user', () => {
  const isPending = ref(false);
  const isInitialized = ref(false);
  const user = ref<User | null>(null);
  const hasAdminRole = computed(() => user.value && adminRoles.includes(user.value.role?.name));
  const isPrimaryUser = computed(() => user.value && Boolean(user.value.is_primary));
  const role = computed(() => user.value?.role?.name);

  const init = async () => {
    if (isInitialized.value) return;
    await loadUser();
    isInitialized.value = true;
  };

  const loadUser = async () => {
    isPending.value = true;
    try {
      const { data } = await fetchUser();
      user.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  return {
    isInitialized,
    isPending,
    user,
    init,
    reload: loadUser,
    hasAdminRole,
    isPrimaryUser,
    role,
  };
});
