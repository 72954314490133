<template>
  <div>
    <div class="mb-4 border-b pb-4">
      <div class="mt-4">
        <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_lf_locations') }}</div>
        {{ $t('flowbot.step_lf_locations_are_managed_under_company_profile') }}
        <router-link
          v-if="$authorization().hasPermission(PERMISSION.SETTINGS__LOCATIONS__MANAGE)"
          :to="SETTINGS_URL.ADMIN__LOCATIONS"
          class="btn btn-xs btn-default"
        >
          {{ $t('flowbot.step_lf_here') }}
        </router-link>
      </div>

      <div v-if="locations" class="mt-4">
        <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_lf_selected_flow_locations') }}</div>
        <multiselect
          v-model="element.meta.locations"
          label="title"
          track-by="id"
          :allow-empty="false"
          :options="allLocations"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :multiple="true"
          :placeholder="$t('flowbot.step_lf_select_locations')"
        ></multiselect>
      </div>

      <div class="mt-4">
        <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_lf_suggest_locations') }}</div>
        <label for="suggest_locations" class="valign-center">
          <label class="ui-switch success m-t-sm m-r">
            <input id="suggest_locations" v-model="element.meta.suggest_locations" type="checkbox" />
            <i></i>
          </label>
          <div class="mt-1 inline-block">{{ $t('flowbot.step_lf_suggest_locations') }}</div>
        </label>
        <div class="form-text">{{ $t('flowbot.step_lf_only_possible_with_up_to_13_locations') }}</div>
      </div>
    </div>

    <div class="mb-4 border-b pb-4">
      <div class="mt-4">
        <TextEditor
          :element="element"
          :title="$t('flowbot.step_lf_message_for_new_contacts')"
          :custom-fields="customFields"
          :placeholder="$t('flowbot.step_lf_which_location_are_you_looking_for')"
        ></TextEditor>
      </div>
      <div class="mt-4">
        <TextEditor
          :element="element"
          :title="$t('flowbot.step_lf_suggest_guiding_text')"
          :form-text="
            $t('flowbot.step_lf_used_when_search_results_in_13_or_fewer_locations_and_for_the_suggest_locations_option')
          "
          attribute="suggest_location_guiding_text"
          :custom-fields="customFields"
          :placeholder="$t('flowbot.step_lf_which_location_would_you_like_to_talk_to')"
        ></TextEditor>
      </div>
    </div>

    <div class="mt-4">
      <TextEditor
        :element="element"
        :title="$t('flowbot.step_lf_fallback_message_for_unknown_locations')"
        attribute="fallback_message_unknown_location"
        :custom-fields="customFields"
        :placeholder="$t('flowbot.step_lf_not_found_broaden_your_search')"
      ></TextEditor>
    </div>

    <div class="mt-4">
      <TextEditor
        :element="element"
        :title="$t('flowbot.step_lf_fallback_message_for_too_many_locations_more_than_13')"
        attribute="fallback_message_too_many_locations"
        :custom-fields="customFields"
        :placeholder="$t('flowbot.step_lf_too_many_locations_narrow_your_search')"
      ></TextEditor>
    </div>

    <div class="mt-8">
      <div class="mb-2 mt-2 font-bold">{{ $t('flowbot.step_lf_ask_for_confirmation_previous_location') }}</div>
      <label for="remember_location" class="valign-center">
        <label class="ui-switch success m-t-sm m-r">
          <input id="remember_location" v-model="element.meta.confirm_last_input" type="checkbox" />
          <i></i>
        </label>
        <div class="mt-1 inline-block">{{ $t('flowbot.step_lf_confirm_previous_location') }}</div>
      </label>
      <template v-if="element.meta.confirm_last_input">
        <TextEditor
          v-bind="$props"
          :title="$t('flowbot.step_lf_confirm_message_for_previous_location')"
          attribute="confirm_question"
          :placeholder="
            $t('flowbot.step_lf_do_you_want_to_contact_your_previous_location') +
            ' { contact ' +
            flowbotTitle +
            ' Location }?'
          "
        ></TextEditor>
        <TextEditor
          v-bind="$props"
          :title="$t('flowbot.step_lf_confirm_previous_accept')"
          attribute="confirm_title"
          :placeholder="$t('flowbot.step_lf_confirm_previous_yes')"
        />
        <TextEditor
          v-bind="$props"
          :title="$t('flowbot.step_lf_confirm_previous_decline')"
          attribute="refute_title"
          :placeholder="$t('flowbot.step_lf_confirm_previous_no')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

import TextEditor from './Text';

export default {
  props: ['element', 'customFields', 'flowbotTitle'],

  components: {
    TextEditor,
    Multiselect,
  },

  data() {
    return {
      PERMISSION,
      SETTINGS_URL,
      locations: [],
      allLocations: [],
    };
  },
  mounted() {
    axios.get('/api/v2/location').then((res) => {
      if (res.data.data && res.data.data.length) {
        this.allLocations = res.data.data.map((location) => {
          return {
            id: location.id,
            title: location.name,
          };
        });
      }
    });
  },
};
</script>
