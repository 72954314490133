<script>
import { NoteTextLinear } from '@trengo/trengo-icons';

import Avatar from '@/components/Avatar.vue';
import MessageAttachments from '@/components/MessageAttachments';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';
import { cachedRequest } from '@/util/request';
import { isOnlyEmoji, escapeHtml } from '@/util/stringHelpers';
import taggable from '@/util/Taggable';

export default {
  name: 'Note',
  emits: ['deleteMessage'],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    Avatar,
    MessageAttachments,
    NoteTextLinear,
  },

  data() {
    return {
      noteDeleteVisible: false,
      users: [],
    };
  },

  computed: {
    isNoteRedesignEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTERNAL_COMMENT_TABS);
    },

    // Emoji can (and most commonly are) comprised of a combination of multiple different unicode characters combined with a zero-width-joiner character.
    // As such, to get the true length of a string containing emoji, we have to extract individual codepoints. This is easily done by spreading the string
    // and only checking the length of that spread array, rather than the string itself.
    messageLength() {
      return [...this.message.message].length;
    },
  },

  created() {
    cachedRequest({ method: 'get', url: '/client-api/users/list' }, true).then((res) => {
      this.users = res.data.users;
    });
  },

  methods: {
    isOnlyEmoji,

    isMention(text) {
      return this.users.map((u) => taggable.userToMention(u)).includes(text);
    },

    parseMention(text) {
      text = text.replace('<br>', '\n');
      text = window.stripHtml(text);
      text = escapeHtml(text);
      text = window.linkifyHtml(text);
      text = text.replace(/\n/g, '<br />');
      return text.replace(/(^|\s)@([a-z0-9_]+)/g, (match) => {
        let user = this.users.find((u) => taggable.userToMention(u) === match.trim());
        return user
          ? '<span class="text-info select-none"><span class="text-grey-600">@</span>' +
              escapeHtml(user.full_name) +
              '</span>'
          : match;
      });
    },

    toggleSeen(mention) {
      mention.seen = !mention.seen;
      axios.put('/api/v2/ticket_mentions/' + mention.id, {
        seen: mention.seen,
      });

      if (mention.seen) {
        this.markPreviousTasks();
      }
    },

    markPreviousTasks() {
      this.$emit('mark-previous-tasks');
    },
  },
};
</script>

<template>
  <div
    v-if="message.type === 'NOTE'"
    class="my-0"
    style="max-width: 80%"
    @mouseover="noteDeleteVisible = true"
    @mouseleave="noteDeleteVisible = false"
  >
    <div class="inline max-w-xl" style="word-break: break-word">
      <div class="b-2x relative rounded-lg border-grey-200 bg-grey-200 p-3 px-6 pl-12" style="min-height: 48px">
        <div class="pointer avatar-container-left overflow-hidden rounded-lg" data-hj-suppress>
          <avatar
            v-if="message.agent != null"
            width="45"
            class="avatar-left"
            :color="message.agent.color"
            :abbr="message.agent.abbr"
            :image="message.agent.profile_image"
          ></avatar>
          <avatar v-if="message.agent == null" width="45" class="avatar-left" :color="'#5bb130'" :abbr="'T'"></avatar>
        </div>

        <div class="select-default" data-hj-suppress>
          <div v-if="isOnlyEmoji(message.message) && messageLength < 4" style="font-size: 36px">
            {{ message.message }}
          </div>
          <div v-else class="flex items-center">
            <div v-html="parseMention(message.message)"></div>
            <span v-for="mention in message.mentions" :key="mention.id" class="flex items-center">
              <span
                v-if="mention && mention.user_id === $root.user.id"
                class="mention_check ml-2"
                @click="toggleSeen(mention)"
              >
                <i class="material-icons" :class="{ 'text-grey-600': !mention.seen, 'text-green': mention.seen }">
                  check_circle
                </i>
              </span>
              <span
                v-if="mention && mention.user_id !== $root.user.id"
                class="mention_check ml-2"
                style="cursor: pointer; opacity: 0.3"
              >
                <i class="material-icons" :class="{ 'text-grey-600': !mention.seen, 'text-green': mention.seen }">
                  check_circle
                </i>
              </span>
            </span>
          </div>
        </div>
      </div>
      <message-attachments v-if="message.attachments.length" :attachments="message.attachments"></message-attachments>
    </div>

    <div v-if="message.agent" class="mt-2 flex flex-nowrap items-center text-sm text-grey-600">
      <span v-if="message.agent.first_name" class="flex-shrink text-ellipsis">
        {{ message.agent.first_name }} {{ message.agent.last_name }}
      </span>
      <span class="mx-1">-</span>
      <span class="flex-shrink-0 text-ellipsis">
        <datetime class="" :time="message.created_at" :pretty="$root.prettyDates"></datetime>
      </span>
      <span v-if="isNoteRedesignEnabled" class="ml-1.5 flex-shrink-0"><note-text-linear size="1.1rem" /></span>
      <a
        v-if="$root.user.id === message.agent.id"
        class="ml-2 flex-shrink-0 text-xs"
        :class="{ 'opacity-0': !noteDeleteVisible }"
        @click="$emit('deleteMessage', message.id)"
      >
        <span class="mx-1">-</span>
        <span class="text-info">{{ $t('general.delete') }}</span>
      </a>
    </div>
  </div>
</template>

<style scoped>
.mention_check {
  margin-right: -5px;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
}

.mention_check .material-icons {
  font-size: 22px;
  line-height: 0.95;
}

.avatar-left {
  left: -5px;
  top: -5px;
  position: absolute !important;
}
.avatar-container-left {
  left: -2px;
  top: -2px;
  width: 40px;
  height: 40px;
  position: absolute !important;
}
</style>
