import { debounce } from 'lodash';
import { getActivePinia } from 'pinia';

import { FEATURE_FLAG_SUBSCRIPTION } from '@/Configs/Constants';
// TODO: implement multiple middleware logic in router level
import { useSubscriptionStore } from '@/store/pinia';

import checkFeatureFlag from './checkFeatureFlag';
import checkPricingModelForQuarterly from './checkPricingModelForQuarterly';
// TODO: set isLoaded flag inside global store instead of polling via debounce

const checkPricingModel = async () => {
  const hasQuarterlyBilling = await checkFeatureFlag(FEATURE_FLAG_SUBSCRIPTION.TW_QUARTERLY_BILLING_CUSTOMERS);

  if (hasQuarterlyBilling) {
    return checkPricingModelForQuarterly();
  }

  await new Promise(ensureSubscriptionIsFetched);

  return useSubscriptionStore().subscription.pricingModel;
};

const checkSubscriptionLoaded = () =>
  useSubscriptionStore().subscription.subscriptionId || useSubscriptionStore().subscription.isServiceDown;

const ensureSubscriptionIsFetched = (resolve: (value?: unknown) => void, reject: unknown) => {
  if (getActivePinia() && checkSubscriptionLoaded()) {
    resolve();
  } else {
    const debounced = debounce(function () {
      ensureSubscriptionIsFetched(resolve, reject);
    }, 30);

    debounced();
  }
};

export default checkPricingModel;
