import Tribute from 'tributejs';

import { escapeHtml } from '@/util/stringHelpers';

import store from '../../../store';

export default new Tribute({
  trigger: '@',
  noMatchTemplate: '',
  selectTemplate: (item) => {
    if (!item) {
      return;
    }
    return (
      '<span class="fr-deletable fr-tribute text-green" contenteditable="false">' +
      escapeHtml(item.original?.getIdentifier()) +
      '</span>'
    ); // todo rl test firefox
  },
  menuItemTemplate: (item) => {
    let user = item.original;
    if (!user?.profileImage) {
      return (
        '<span class="flex w-full items-center"><span class="user-image" style="background:' +
        user.color +
        ';min-width:30px">' +
        user.abbr +
        '</span><span class="text-ellipsis" style="font-weight:normal;padding-right:7px;">' +
        escapeHtml(user?.getDisplayName()) +
        '</span></span>'
      );
    }
    return (
      '<span class="flex w-full items-center"><span class="user-image" style="line-height:1;min-width:30px;"><img style="width:30px;height:30px;" src="' +
      user?.profileImage +
      '"></span><span class="text-ellipsis" style="font-weight:normal;padding-right:7px;">' +
      escapeHtml(user?.getDisplayName()) +
      '</span></span>'
    );
  },
  lookup: (item) => escapeHtml(item?.getDisplayName()),
  values: (_text, cb) => {
    let thread = store.getters['chat/currentThread'];
    let threadUsers = [];
    thread.participants.forEach((participant) => {
      let user = store.state.usersInternalChat.users.find((user) => user.id === participant.id);
      if (user) {
        threadUsers.push(user);
      }
    });

    cb(threadUsers);
  },
});
