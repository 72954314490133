<template>
  <div v-if="fields.length > 0" class="sidebar_block border-b border-grey-300">
    <ul
      v-if="
        contact &&
        contact.formatted_custom_field_data != null &&
        Object.keys(contact.formatted_custom_field_data).length > 0
      "
      class="m-b-0 pl-0 text-left"
    >
      <sidebar-collapse collapse-key="collapseTicketSidebarContactCustomFieldEditor">
        <template #header>
          <h6 class="mb-0">
            {{ $t('ticket_sidebar.contact_fields') }}
          </h6>
        </template>
        <div>
          <div v-for="field in fields">
            <div class="mt-3">
              <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
              <custom-field-item :field="field" @save="save(field)" />
            </div>
          </div>
        </div>
      </sidebar-collapse>
    </ul>
  </div>
</template>

<script>
import { cloneDeep, find, lowerCase, remove, some } from 'lodash';
import { mapState } from 'vuex';

import SidebarCollapse from '@/components/common/SidebarCollapse';
import ticketSidebarMixins from '@/components/TicketSidebar/ticketSidebarMixins';

import { storeContactCustomField as apiStoreContactCustomField } from './Api';
import CustomFieldItem from './CustomFieldItem';

export default {
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [ticketSidebarMixins],

  data: function () {
    return {
      fields: [],
    };
  },

  mounted() {
    this.loadList();
  },

  components: {
    CustomFieldItem,
    SidebarCollapse,
  },
  watch: {
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },
  computed: {
    ...mapState({
      storedFields: (state) => state.customFields.customContactFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
  },

  methods: {
    save(fieldToSave) {
      const field = find(this.fields, function (field) {
        return field.id === fieldToSave.id;
      });
      const fieldValue = field.value || null;
      this.commitFields();

      apiStoreContactCustomField(this.contact.id, field.id, fieldValue)
        .then(() => {
          this.resetFieldServerError(lowerCase(field.type), field.id);
        })
        .catch((error) => {
          this.setFieldServerError(lowerCase(field.type), field.id, error.response?.data?.errors);
        })
        .finally(() => {
          this.commitFields();
        });
    },
    commitFields() {
      this.$store.commit('customFields/setTicketContactFields', cloneDeep(this.fields));
    },
    loadList() {
      this.fields = cloneDeep(this.storedFields);
      remove(this.fields, (field) => {
        return field.channels.length && !some(field.channels, ['id', this.ticket.channel.id]);
      });
      this.fields = this.fields.map((field) => {
        return {
          ...field,
          value: this.contact?.custom_field_data ? this.contact?.custom_field_data[field.title] : null,
          contactId: this.contact?.id,
        };
      });
      this.commitFields();
    },
  },
};
</script>
