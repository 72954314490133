import { PERMISSION } from '@/Configs/Constants';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: '/admin/teams',
    component: require('../../components/Teams/Index').default,
    meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
    beforeEnter: hasPermission,
    children: [
      {
        path: '/admin/teams/create',
        component: require('../../components/PageNotFound').default,
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: '/admin/teams/:id',
        component: require('../../components/Teams/pages/Edit').default,
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
        beforeEnter: hasPermission,
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'teams' },
        meta: { permissionRequired: PERMISSION.PERMISSIONS__TEAMS__MANAGE },
        beforeEnter: hasPermission,
      },
    ],
  },
];
