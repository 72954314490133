<script type="text/babel">
import _ from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';

export default {
  name: 'AttachProfile',
  data() {
    return {
      showForm: false,
      showCreateForm: false,
      profiles: [],
      isLoading: false,
      selected: null,
      attaching: false,
      profile_name: '',
      adding: false,
    };
  },

  props: {
    contact: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    ticket: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  components: {
    Multiselect,
  },

  watch: {
    selected() {
      this.showCreateForm = false;
    },
    showCreateForm(val) {
      if (val === true) {
        var that = this.$refs.input_new_profile;
        setTimeout(function () {
          that.focus();
        }, 100);
      }
    },
  },

  methods: {
    asyncFind: _.debounce(function (query) {
      this.isLoading = true;

      if (!query) {
        this.isLoading = false;
        return;
      }

      this.profile_name = query;
      axios.get('/api/v2/profiles', { params: { term: query } }).then((response) => {
        this.profiles = response.data.data;
        this.isLoading = false;
      });
    }, 250),

    onSelected(selected) {
      axios
        .post('/api/v2/profiles/' + selected.id + '/contacts', {
          contact_id: this.contact.id,
          type: this.ticket.channel.type,
        })
        .then((response) => {
          eventBus.$emit('ticket.current.reload');
        });
    },

    createProfile() {
      this.adding = true;

      axios.post('/api/v2/profiles', { name: this.profile_name }).then((res) => {
        axios
          .post('/api/v2/profiles/' + res.data.id + '/contacts', {
            contact_id: this.contact.id,
            type: this.ticket.channel.type,
          })
          .then((response) => {
            this.adding = false;
            eventBus.$emit('ticket.current.reload');
          });
      });
    },

    cancel() {
      this.selected = null;
      this.profiles = [];
      this.showCreateForm = false;
      this.profile_name = '';
    },

    onAttachProfileClick() {
      this.showForm = !this.showForm;
    },

    onNewProfile() {
      this.showCreateForm = true;
    },
  },
};
</script>

<template>
  <div>
    <div class="">
      <a
        class="
          b
          selector-conversation-attach-profile
          flex
          items-center
          justify-center
          text-ellipsis
          rounded
          bg-white
          px-4
          py-3
          text-center
        "
        role="button"
        @click="onAttachProfileClick"
      >
        <i class="material-icons material-icons-xs text-grey-600">{{ showForm ? 'remove' : 'add' }}</i>
        <span class="text-grey-600">{{ $t('tickets.attach_profile') }}</span>
      </a>
    </div>
    <div v-show="showForm" class="mb-1">
      <div v-show="!showCreateForm" class="mt-4">
        <h6 class="mb-0">{{ $t('tickets.attach_profile') }}</h6>
        <div class="mt-2" style="margin-bottom: 0">
          <multiselect
            v-model="selected"
            label="name"
            select-label=""
            :placeholder="$t('tickets.search_profile')"
            :allow-empty="false"
            deselect-label=""
            track-by="id"
            open-direction="bottom"
            :show-no-results="false"
            :options="profiles"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :options-limit="300"
            :limit="3"
            :clear-on-select="true"
            :close-on-select="true"
            :max-height="600"
            :show-no-options="false"
            @select="onSelected"
            @search-change="asyncFind"
          >
            <template #noResult>
              <span></span>
            </template>
            <template #afterList>
              <span>
                <div class="b-t" style="padding: 10px; cursor: pointer" @click="onNewProfile">
                  <i class="material-icons material-icons-xs text-success">add</i>
                  {{ $t('tickets.create_profile') }}
                </div>
              </span>
            </template>
          </multiselect>
        </div>
      </div>
      <div v-show="showCreateForm" class="b-y mt-4">
        <h6 class="mb-0">{{ $t('tickets.create_profile') }}</h6>
        <div class="mt-4" style="margin-bottom: 0">
          <div class="input-group m-b">
            <input
              ref="input_new_profile"
              v-model="profile_name"
              type="text"
              :placeholder="$t('tickets.profile_name')"
              class="form-control"
            />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn bg-green text-white"
                :disabled="adding || profile_name == ''"
                @click="createProfile"
              >
                <i class="material-icons material-icons-xs">add</i>
              </button>
            </span>
          </div>
          <div class="text-muted text-center" style="margin-top: -6px" @click="cancel">
            <a>{{ $t('tickets.cancel_create_profile') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.collapse-title-attach-profile {
  width: 200px;
  margin: 0 auto;
}
</style>
