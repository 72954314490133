import { request } from '@/util/request';

import { BASE_URL, ENDPOINT } from '../constants';

import type { AxiosPromise, AxiosResponse } from 'axios';

export function getAuthActivity(socketId: string): AxiosPromise<AxiosResponse> {
  return request(`${ENDPOINT.AUTH_ACTIVITY}`, 'POST', { socket_id: socketId });
}

export function getDirectLoginUrl(): AxiosPromise<AxiosResponse> {
  return request(`${ENDPOINT.DIRECT_LOGIN_URL}`, 'GET');
}

export function fetchLogInToken(): AxiosPromise<{ access_token: string }> {
  return request(`${ENDPOINT.LOGIN_TOKEN}`, 'GET');
}

export function fetchAdminEvents(resource: string): AxiosPromise<AxiosResponse> {
  return request(`${BASE_URL}/${resource}`, 'GET');
}

export function checkDangerZoneAccess(token: string): AxiosPromise<{ is_valid_token: boolean }> {
  return request(`${ENDPOINT.USER_VERIFICATION_VERIFY_TOKEN}`, 'POST', { verification_token: token });
}

export function generateDangerZoneToken(secret: string): AxiosPromise<{ token: string }> {
  return request(`${ENDPOINT.USER_VERIFICATION_GENERATE_TOKEN}`, 'POST', { secret });
}

export function sendDangerZoneVerificationEmail(): AxiosPromise<void> {
  return request(`${ENDPOINT.USER_VERIFICATION_SEND_EMAIL}`, 'POST');
}
