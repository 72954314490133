<template>
  <div v-if="shouldShow()" class="z-9999 relative top-0">
    <transition-group name="slide">
      <div
        v-for="sn of notifications"
        :key="sn.id"
        class="service-notification flex items-center justify-between gap-2 px-4"
        :class="sn.status"
      >
        <div class="pointer" @click="alert(sn.message)">
          <alert-circle-linear class="material-icons text-2x align-middle" size="1.3rem" />
        </div>

        <div class="flex-grow text-ellipsis">
          <span class="t-text-sm">{{ sn.message }}</span>
        </div>

        <div class="mr-1">
          <t-button
            v-if="sn.link_url"
            class="align-baseline"
            :href="sn.link_url"
            btn-style="secondary"
            size="sm"
            target="_blank"
            rel="noreferrer noopener"
          >
            {{ sn.link_text }}
          </t-button>
        </div>

        <div class="flex-shrink-0 overflow-hidden" v-if="sn.is_dismissible">
          <t-action-button class="close-button !rounded-md !p-1" @click="dismissNotification(sn)">
            <cross-linear size="1.3rem" color="white" />
          </t-action-button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">
.service-notification {
  height: 3.5rem;
  line-height: 3.5rem;
  color: #fff;
  transition: all 200ms ease-out;

  .content {
    margin: 0 6rem 0 0.5rem;
  }
}

.slide-enter-from,
.slide-leave-to {
  //margin-top: -3.5rem;
  opacity: 0;
}

.info {
  background-color: rgba(0, 111, 202, 1);

  .btn-outline-white:hover {
    color: #20a6b6;
  }
}
.danger {
  background-color: #ef183c;

  .btn-outline-white:hover {
    color: #ef183c;
  }
}
.warning {
  background-color: #f2c012;

  .btn-outline-white:hover {
    color: #f2c012;
  }
}
.success {
  background-color: #23b66d;

  .btn-outline-white:hover {
    color: #23b66d;
  }
}

.btn-outline-white {
  border: 1px solid #fff;
  border-radius: 500px;
  padding: 0.25rem 1rem;

  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  transition: all 200ms ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
</style>

<script>
import { AlertCircleLinear, CrossLinear } from '@trengo/trengo-icons';
import { PERMISSION, FEATURE_FLAG_SUBSCRIPTION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

const POLLING_INTERVAL = 30000; // 5 minutes
const IS_DISMISSED_KEY = 'pricing_migration_service_notification_dismissed';

const MIGRATION_CUSTOM_NOTIFICATION = {
  id: 'pricing_migration_custom_notification',
  status: 'info',
  message:
    'You will soon be migrated to a new plan. An email containing further information will be sent to the admin.',
  link_url: 'https://www.youtube.com/watch?v=sbXvEjPsDqs',
  link_text: 'Watch video',
};

export default {
  components: { AlertCircleLinear, CrossLinear },
  data() {
    return {
      notifications: [],
      channels: [],
      pollingInterval: null,
    };
  },
  async mounted() {
    await this.initChannels();
    this.startPolling();
    this.initNotifications();
  },
  beforeUnmount() {
    this.stopPolling();
  },
  methods: {
    startPolling() {
      this.pollingInterval = setInterval(() => {
        this.initNotifications();
      }, POLLING_INTERVAL);
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    shouldShow() {
      return !window.isLoadedFromApp && !window.isElectron;
    },
    alert(msg) {
      alert(msg);
    },
    arrayToQueryString(inputArray, paramName) {
      return inputArray.map((item) => paramName + '[]=' + encodeURIComponent(item)).join('&');
    },
    async hasAgencyMigrationBanner() {
      const alreadyDismissed = await this.$tStorage.getItem(IS_DISMISSED_KEY);
      if (alreadyDismissed) {
        return false;
      }
      const isAdmin = this.$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
      const hasMigrationBanner = useFeatureFlagStore().isEnabled(
        FEATURE_FLAG_SUBSCRIPTION.TW_USAGE_BASED_MIGRATION_BANNER
      );
      return hasMigrationBanner && isAdmin;
    },
    initNotifications() {
      axios
        .get('/api/v2/service_notifications?' + this.arrayToQueryString(this.channels, 'channels'))
        .then(async (r) => {
          if (typeof r.data !== 'object') return;
          this.notifications = (await this.hasAgencyMigrationBanner())
            ? [...r.data, MIGRATION_CUSTOM_NOTIFICATION]
            : r.data;
        });
    },
    dismissNotification(sn) {
      this.removeNotification(sn);
      if (sn.id === MIGRATION_CUSTOM_NOTIFICATION.id) {
        this.$tStorage.setItem(IS_DISMISSED_KEY, true);
      } else {
        axios
          .put('/api/v2/service_notifications/' + sn.id + '/dismiss')
          .then((r) => {})
          .catch((e) => console.log(e));
      }
    },
    removeNotification(sn) {
      this.notifications.splice(this.notifications.indexOf(sn), 1);
    },
    async initChannels() {
      let res = await axios.get('/api/v2/service_notifications/channels');
      this.channels = res.data;
    },
  },
};
</script>
