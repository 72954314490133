import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import hasPermission from '@/middleware/hasPermission';

export default [
  {
    path: SETTINGS_URL.ADMIN__TRANSACTIONS,
    component: require(/* webpackChunkName: "Transactions" */ '../../components/Transactions/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE },
    beforeEnter: hasPermission,
  },
];
