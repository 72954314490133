<script type="text/babel">
import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

export default {
  data() {
    return {
      PERMISSION,
    };
  },

  methods: {
    clicked() {
      vue_redirect(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS);
      $('#TrialExpiredModal').modal('hide');
      $('#SubscriptionInactiveModal').modal('hide');
    },
  },
};
</script>

<template>
  <div>
    <div class="modal" id="TrialExpiredModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content text-center">
          <div class="modal-header">
            <h5 class="modal-title">Your trial has expired</h5>
          </div>
          <div class="modal-body">
            Your trial has expired. All the tickets, messages and settings are still available, but you cannot work on
            tickets until you subscribe to one of the plans.
            <br />
            <br />
            <a
              class="btn btn-info text-white"
              @click="clicked"
              v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
            >
              Go to settings
            </a>
            <strong v-else>Please contact your administrator to get a subscription.</strong>
            <br />
            <br />
            <span class="text-muted">
              or
              <a href="/auth/logout" style="text-decoration: underline">logout</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="SubscriptionInactiveModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content text-center">
          <div class="modal-header">
            <h5 class="modal-title">Your account is inactive</h5>
          </div>
          <div class="modal-body">
            There is a problem with your billing. You can not work on tickets until you fix this issue. Please go to the
            company profile to resolve this error.
            <br />
            <br />
            <a
              class="btn btn-info text-white"
              @click="clicked"
              v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
            >
              Resolve issue
            </a>
            <strong v-else>Please contact your administrator to resolve this error.</strong>
            <br />
            <br />
            <span class="text-muted">
              or
              <a href="/auth/logout" style="text-decoration: underline">logout</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
