<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('contacts.contact_group') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('contacts.contact_group_name') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.name" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn btn-success"
                      :disabled="saving || !ready_to_save"
                      v-text="saving ? this.$t('contacts.saving') : this.$t('contacts.save_changes')"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteContactGroup()"
                    >
                      {{ $t('contacts.delete') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';

export default {
  name: 'ContactGroupsEdit',
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  computed: {
    ready_to_save() {
      if (this.record.name === '') {
        return;
      }

      return true;
    },
  },

  data() {
    return {
      loaded: false,
      record: {
        name: '',
        sort_order: '',
      },
      saving: false,
    };
  },

  mounted() {
    if (this.action == 'edit') {
      axios.get('/api/v2/contact_groups/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        this.initWarning();
      });
    } else {
      this.initWarning();
    }
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action == 'create') {
        return this.storeContactGroup();
      } else {
        this.updateContactGroup();
      }
    },

    storeContactGroup() {
      axios
        .post('/api/v2/contact_groups', this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          router.push('/admin/contact_groups/' + res.data.id);
          this.flashSuccess(this.$t('contacts.the_contact_group_is_created'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateContactGroup() {
      axios
        .put('/api/v2/contact_groups/' + this.record.id, this.record)
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('contacts.the_contact_group_is_updated'));
          this.initWarning();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteContactGroup() {
      const shouldDeleteGroup = await this.$tConfirm(this.$t('contacts.are_you_sure_you_want_to_delete_this_group'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteGroup) {
        this.initWarning();

        axios.delete('/api/v2/contact_groups/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push('/admin/contact_groups/create');
          this.flashSuccess(this.$t('contacts.the_contact_group_is_deleted'));
        });
      }
    },
  },
};
</script>
