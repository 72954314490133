<template>
  <div class="create-broadcast flex h-full w-full flex-col overflow-y-scroll bg-white">
    <div class="create-broadcast-content w-536px flex-1 self-center pt-12">
      <header class="create-broadcast-header relative flex items-center bg-white">
        <div class="header-content">
          <router-link :to="'/broadcasting'" class="pointer flex items-center pb-6" data-test="back-button">
            <img
              class="mr-2 inline-block h-3"
              :src="`${$root.assetsURL}img/action_icons/icon-arrow-left.svg`"
              alt="back"
            />
            <span class="mx-2 font-semibold">{{ $t('broadcast.back_to_overview_button') }}</span>
          </router-link>
        </div>
      </header>
      <h3 class="t-text-desktop-h3 mb-12">{{ titleLabel }}</h3>
      <span class="t-text-desktop-label-md">{{ $t('broadcast.broadcast_name') }}</span>
      <span class="t-text-desktop-label-md text-grey-600">{{ $t('broadcast.broadcast_name_optional_label') }}</span>
      <div class="t-text-desktop-paragraph-xs mb-2 text-grey-600">
        {{ $t('broadcast.for_internal_use_only_and_not_visible_to_your_customers') }}
      </div>
      <atomic-input
        v-model.trim="name"
        size="md"
        :placeholder="$t('broadcast.broadcast_name_placeholder')"
        :autofocus="action === 'create'"
        data-test="name-input"
      />
      <div class="t-text-desktop-label-md mb-2 mt-8">{{ $t('broadcast.broadcast_sender') }}</div>
      <t-dropdown
        v-model="sender"
        :items="computedFilteredSenders"
        value-index="id"
        text-index="title"
        :placeholder="$t('broadcast.broadcast_sender')"
        light-version
        size="md"
        item-class="t-text-desktop-paragraph-md text-grey-800"
        data-test="sender-dropdown"
        :error="!validation.sender"
        :disabled="isEditingBroadcast"
      >
        <template #start>
          <div
            v-if="
              !senders.length &&
              isSmsEnabled &&
              $authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
            "
            class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
          >
            {{ $t('broadcast.broadcast_no_whatsapp_and_sms_sender_channel_error') }}
          </div>
          <div
            v-if="
              !senders.length &&
              !isSmsEnabled &&
              $authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
            "
            class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
          >
            {{ $t('broadcast.broadcast_no_whatsapp_sender_channel_error') }}
          </div>
          <div
            v-if="!senders.length && !$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
            class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600"
          >
            {{ $t('broadcast.broadcast_no_sender_check_with_admin_error') }}
          </div>
          <div v-if="senders.length" class="mx-4 my-3 flex h-40px flex-row rounded-xl bg-grey-200 px-4">
            <img alt="icon-search" style="width: 16px" :src="`${$root.assetsURL}img/icon-search.svg`" class="mr-2" />
            <input
              v-model="search_value"
              class="t-text-desktop-paragraph-md w-full bg-transparent text-grey-600 placeholder-grey-600"
              :placeholder="$t('broadcast.search_in_broadcast_channels')"
            />
          </div>
        </template>
        <template #end>
          <div v-if="requiresWhatsappUpgrade">
            <div class="t-text-desktop-paragraph-sm mx-4 border-t border-grey-300 px-2 py-4 text-grey-600">
              <img class="mr-2 inline-block h-4" :src="`${$root.assetsURL}img/wab-broadcasting/whatsapp-grey.svg`" />
              {{ $t('broadcast.whatsapp_is_only_available_on_enterprise') }}
            </div>
          </div>
          <div v-if="!isSmsEnabled && $authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
            <div class="t-text-desktop-paragraph-sm mx-4 border-t border-grey-300 px-2 py-4 text-grey-600">
              <img class="mr-2 inline-block h-4" :src="`${$root.assetsURL}img/wab-broadcasting/sms-grey.svg`" />
              {{ $t('broadcast.please_contact_support_to_enable_sms_for_broadcasting') }}
            </div>
          </div>
        </template>
      </t-dropdown>
      <t-error-item
        v-if="!validation.sender"
        data-test="sender-error"
        class="mb-1 mt-1"
        :text="$t('broadcast.select_broadcast_sender')"
      />
      <div v-if="senderObject && senderObject.type === WA_BUSINESS" class="mt-6">
        <span class="t-text-desktop-label-md">{{ $t('broadcast.message_template') }}</span>
        <div class="t-text-desktop-paragraph-xs mb-2 text-grey-600">
          {{ $t('broadcast.broadcast_dont_spam_with_not_allowed_messages') }}
        </div>
        <t-dropdown
          v-model="templateId"
          :items="wa_templates"
          value-index="id"
          text-index="title"
          :placeholder="$t('broadcast.select_template_field_label')"
          light-version
          size="md"
          item-class="t-text-desktop-paragraph-md text-grey-800"
          data-test="template-dropdown"
          :error="!validation.template"
        >
          <template #start>
            <div v-if="!wa_templates.length" class="t-text-desktop-paragraph-sm mx-4 my-3 text-grey-600">
              {{ $t('broadcast.broadcast_no_template_create_or_wait_for_whatsapp_error') }}
            </div>
          </template>
        </t-dropdown>
        <t-error-item
          v-if="!validation.template"
          data-test="template-error"
          class="mb-1 mt-1"
          :text="$t('broadcast.please_select_a_template_for_your_broadcast')"
        />
        <whatsapp-composer v-model="tags" :template="template" :error="!validation.tags" @tags="updateTags" />
      </div>
      <div v-if="senderObject && senderObject.type === SMS">
        <div class="t-text-desktop-label-md mb-2 mt-6">{{ $t('broadcast.broadcast_message') }}</div>
        <sms-composer
          :channel="senderObject"
          :message="message"
          :error="!validation.message"
          @message="updateMessage"
        />
        <t-inline-banner v-if="tagsAdded" class="tags-warning mt-4">
          <template #icon>
            <info-linear />
          </template>
          {{ $t('broadcast.broadcast_tag_content_length_error_hint') }}
        </t-inline-banner>
      </div>

      <schedule-send-date
        :validation="validation"
        :date-radio="dateRadio"
        :date="date"
        :time="time"
        @time="updateTime"
        @date="updateDate"
        @dateRadio="updateDateRadio"
      />

      <t-inline-banner
        v-if="showRecipientsWarning"
        type="warning"
        class="tags-warning mt-2"
        data-test="invalid-numbers-warning"
      >
        <template #icon>
          <user-cross-linear />
        </template>
        <div>{{ $t('broadcast.some_numbers_are_not_valid_and_have_not_been_added') }}</div>
        <template #action>
          <t-button
            size="sm"
            btn-style="secondary"
            data-test="close-recipients-warning-button"
            @click="showRecipientsWarning = false"
          >
            Close
          </t-button>
        </template>
      </t-inline-banner>
      <wab-recipients
        v-model="recipientsLocal"
        :error="!validation.recipients"
        :show-limit="senderObject && senderObject.type === WA_BUSINESS"
        :is-editing="isEditingBroadcast"
      />
      <t-error-item
        v-if="!validation.recipients"
        data-test="recipients-error"
        class="mb-1 mt-1"
        :text="
          $t('broadcast.recipients_error', {
            date: dateRadio === 'now' ? $t('general.send').toLowerCase() : $t('general.schedule').toLowerCase(),
          })
        "
      />

      <t-inline-banner
        v-if="insufficientBalance"
        type="error"
        class="tags-warning mt-12"
        data-test="insufficient-balance-warning"
      >
        <template #icon>
          <cross-octagon-linear />
        </template>
        <div v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
          {{ $t('broadcast.you_dont_have_enough_balance_to_send_this_broadcast') }}
        </div>
        <div v-if="!$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)">
          {{ $t('broadcast.broadcast_not_enough_balance_contact_admin_error') }}
        </div>
        <template #action>
          <t-button class="flex items-center" size="sm" btn-style="secondary" data-test="topup-button" @click="topUp">
            <span class="whitespace-nowrap">{{ $t('broadcast.top_up') }}</span>
            <external-link2-linear size="14px" class="info-icon ml-2 text-grey-900" />
          </t-button>
        </template>
      </t-inline-banner>
    </div>

    <footer class="footer sticky bottom-0 mt-12 border-t-1 border-grey-300">
      <div class="m-auto flex w-536px justify-between py-4">
        <div>
          <div class="t-text-desktop-label-lg text-grey-900" data-test="cost-label">€{{ formattedAmount }}</div>
          <div class="t-text-desktop-paragraph-sm text-grey-600">{{ costLabel }}</div>
        </div>
        <div>
          <atomic-button size="md" btn-style="secondary" data-test="cancel-button" @click="handleGoBack">
            {{ $t('general.cancel') }}
          </atomic-button>
          <atomic-button
            size="md"
            class="ml-3"
            data-test="submit-button"
            :disabled="insufficientBalance"
            @click="handleSubmit"
          >
            {{ submitLabel }}
          </atomic-button>
        </div>
      </div>
    </footer>
    <whatsapp-disclaimer-modal v-model="whatsappDisclaimerModalIsOpen" @submit="disclaimerAccepted" />
    <confirm-broadcast-modal
      v-model="confirmBroadcastModalIsOpen"
      :sender-type="senderObject && senderObject.type"
      :message-length="stripHtml(message).length"
      :scheduled="dateRadio === 'later'"
      :tags-added="tagsAdded"
      :send-date="sendDateModalLabel"
      :total-amount="amount"
      :balance="balance"
      :recipients-quantity="recipients.contacts.length + recipients.numbers.length"
      :is-confirm-btn-disabled="isConfirmBtnDisabled"
      @submit="confirmBroadcast"
    />
  </div>
</template>

<script>
import { CrossOctagonLinear, ExternalLink2Linear, InfoLinear, UserCrossLinear } from '@trengo/trengo-icons';
import { debounce, forOwn, includes, isEmpty, map } from 'lodash';
import { mapStores } from 'pinia';

import {
  createBroadcast,
  getBalance,
  getBroadcast,
  getPriceEstimation,
  getWaTemplates,
  updateBroadcast,
} from '@/components/WabBroadcasting/api';
import ConfirmBroadcastModal from '@/components/WabBroadcasting/components/ConfirmBroadcastModal';
import ScheduleSendDate from '@/components/WabBroadcasting/components/ScheduleSendDate';
import SmsComposer from '@/components/WabBroadcasting/components/SmsComposer';
import WabRecipients from '@/components/WabBroadcasting/components/WabRecipients';
import WhatsappComposer from '@/components/WabBroadcasting/components/WhatsappComposer';
import WhatsappDisclaimerModal from '@/components/WabBroadcasting/components/WhatsappDisclaimerModal';
import { currency } from '@/components/WabBroadcasting/utils/Utils.js';
import { CHANNEL_TYPE, FEATURE, ENTITLEMENT_STATUS, PERMISSION, PRICING_MODEL } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import { TEMPLATE_STATUS, TEMPLATE_HEADER_TYPE } from '@/Configs/Constants/whatsapp';
import checkPricingModel from '@/middleware/checkPricingModel';
import { useEntitlementsStore } from '@/store/pinia';

export default {
  name: 'CreateBroadcast',
  components: {
    WabRecipients,
    SmsComposer,
    CrossOctagonLinear,
    ExternalLink2Linear,
    InfoLinear,
    UserCrossLinear,
    WhatsappComposer,
    ScheduleSendDate,
    WhatsappDisclaimerModal,
    ConfirmBroadcastModal,
  },
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      name: '',
      sender: '',
      search_value: '',
      message: '',
      recipientsLocal: {
        contacts: {},
        manual: [],
        groups: {},
      },
      templateId: '',
      wa_templates: [],
      tags: [],
      validation: {
        sender: true,
        date: true,
        time: true,
        timeFormat: true,
        timeAfterNow: true,
        template: true,
        recipients: true,
        message: true,
        tags: true,
      },
      isSubmitButtonValid: true,
      date: '',
      time: '',
      dateRadio: 'now',
      send_at: null,
      whatsappDisclaimerModalIsOpen: false,
      confirmBroadcastModalIsOpen: false,
      whatsappDisclaimerAccepted: false,
      amount: '0,00',
      balance: '0,00',
      record: {},
      validationTriggered: false,
      showRecipientsWarning: false,
      recipientsNumbers: [],
      debouncedEstimation: debounce((data) => {
        getPriceEstimation(data)
          .then((res) => {
            this.amount = res.data.amount;
          })
          .catch((e) => {
            if (e.response.status === STATUS_CODE.UNPROCESSABLE_ENTITY) {
              this.showRecipientsWarning = false;
              this.recipientsNumbers = [...this.recipients.numbers];
              forOwn(e.response.data.errors, (error, key) => {
                if (key.startsWith('recipients.numbers')) {
                  this.showRecipientsWarning = true;
                  this.recipientsNumbers = this.recipientsNumbers.filter(
                    (number) => number != this.recipients.numbers[key.split('.')[2]]
                  );
                }
              });
              if (this.showRecipientsWarning) {
                this.recipients = Object.assign({}, this.recipients, {
                  numbers: [...this.recipientsNumbers],
                });
              }
            }
          });
      }, 200),
      FEATURE,
      ENTITLEMENT_STATUS,
      isConfirmBtnDisabled: false,
      PERMISSION,
      pricingModel: null,
    };
  },
  mounted() {
    if (this.isEditingBroadcast) {
      getBroadcast(this.$route.params.id).then((res) => {
        this.name = res.data.name;
        this.sender = res.data.channel_id;
        this.templateId = res.data.template?.id;
        this.tags = [];
        res.data.tags.forEach((tag) => {
          this.tags.push({ key: tag.identifier, value: tag.value });
        });
        this.message = res.data.message;
        this.recipientsLocal.contacts = {};
        res.data.recipients.forEach((recipient) => {
          this.recipientsLocal.contacts[recipient.id] = {
            isSelected: true,
            id: recipient.id,
            name: recipient.name,
            phone: recipient.phone,
          };
        });
        if (res.data.send_at) {
          this.dateRadio = 'later';
          this.date = moment(res.data.send_at).tz(this.$root.user.timezone).format('YYYY-MM-DD');
          this.time = moment(res.data.send_at).tz(this.$root.user.timezone).format('HH:mm');
        }
      });
    }
    this.getPricingModel();
  },
  watch: {
    date(value) {
      this.validation.date = value?.length > 0;
      this.validation.timeAfterNow = moment(this.date + ' ' + this.time)
        .tz(this.$root.user.timezone)
        .isAfter(moment().tz(this.$root.user.timezone));
      this.handleSubmitButtonValidation();
    },
    time(value) {
      this.validation.time = value?.length > 0;
      this.validation.timeFormat = value?.length === 0 || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.time);
      this.validation.timeAfterNow = moment(this.date + ' ' + this.time)
        .tz(this.$root.user.timezone)
        .isAfter(moment().tz(this.$root.user.timezone));
      this.handleSubmitButtonValidation();
    },
    async sender(value) {
      try {
        this.validation.sender = !!value;
        this.handleSubmitButtonValidation();
        this.templateId = '';
        const { data } = await getWaTemplates(TEMPLATE_STATUS.ACCEPTED, value);
        const templatesWithoutHeaderImage = data?.data?.filter((t) => {
          return !t.components?.find((item) => item.sub_type === TEMPLATE_HEADER_TYPE.IMAGE.toUpperCase());
        });
        this.wa_templates = templatesWithoutHeaderImage || [];
        this.convertAndGetEstimation();
      } catch (error) {
        console.error(error);
      }
    },
    template(value) {
      this.validation.template = !!value;
      this.handleSubmitButtonValidation();
    },
    message(value) {
      this.validation.message = stripHtml(value).length > 0;
      this.handleSubmitButtonValidation();
      this.convertAndGetEstimation();
    },
    recipients: {
      deep: true,
      handler(value) {
        this.validation.recipients = !!(value?.contacts?.length || value?.numbers?.length);
        this.convertAndGetEstimation();
      },
    },
    tags: {
      deep: true,
      handler(value) {
        if (this.validationTriggered) {
          this.validation.tags = !includes(map(value, 'value'), '');
          this.handleSubmitButtonValidation();
        }
      },
    },
    templateId(value) {
      if (!value) return;
      this.convertAndGetEstimation();
    },
  },
  async created() {
    this.WA_BUSINESS = CHANNEL_TYPE.WA_BUSINESS;
    this.SMS = CHANNEL_TYPE.SMS;
    this.loadRecord();
    this.whatsappDisclaimerAccepted = await this.$tStorage.getItem(
      this.$root.user.id + '_whatsapp_disclaimer_accepted'
    );
    getBalance().then((res) => {
      this.balance = res.data.balance_unformatted;
    });
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    requiresWhatsappUpgrade() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.ENTITLED &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.REQUIRES_PLAN_UPGRADE
      );
    },
    isEntitledToSmsAndWhatsapp() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__SMS) ===
          ENTITLEMENT_STATUS.ENTITLED &&
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__BROADCASTING__WHATSAPP) ===
          ENTITLEMENT_STATUS.ENTITLED
      );
    },
    senders() {
      let channels = [];
      if (this.isSmsEnabled) {
        channels.push(CHANNEL_TYPE.SMS);
      }
      if (this.isEntitledToSmsAndWhatsapp) {
        channels.push(CHANNEL_TYPE.WA_BUSINESS);
      }
      return this.$root.channels
        .filter((channel) => {
          return channels.includes(channel.type);
        })
        .map((channel) => ({
          icon: `${this.$root.assetsURL}img/${this.getIcon(channel.type)}.svg`,
          ...channel,
        }));
    },
    isSmsEnabled() {
      return typeof window.AGENCY.enable_bulk_sms == 'undefined' || window.AGENCY.enable_bulk_sms == 1;
    },
    computedFilteredSenders() {
      return this.mapItemsBySearch(this.search_value);
    },
    senderObject() {
      return this.senders.filter((channel) => channel.id === this.sender)[0];
    },
    tagsAdded() {
      return this.message?.includes('class="sms-tag"');
    },
    template() {
      return this.wa_templates.filter((currentTemplate) => {
        return currentTemplate.id === this.templateId;
      })[0];
    },
    submitLabel() {
      if (this.isEditingBroadcast) {
        if (this.dateRadio === 'now') {
          return this.$t('broadcast.broadcast_save_and_send_button');
        }
        return this.$t('broadcast.broadcast_save_and_schedule_button');
      } else if (this.dateRadio === 'now') {
        return this.$t('general.send');
      }
      return this.$t('general.schedule');
    },
    sendDateModalLabel() {
      if (this.dateRadio === 'now') {
        return moment().tz(this.$root.user.timezone).format('DD/MM/YYYY HH:mm');
      } else
        return moment(this.date + ' ' + this.time)
          .tz(this.$root.user.timezone)
          .format('DD/MM/YYYY HH:mm');
    },
    costLabel() {
      if (this.senderObject && this.senderObject.type === CHANNEL_TYPE.SMS && this.tagsAdded) {
        return this.$t('broadcast.estimated_cost');
      } else return this.$t('broadcast.total_cost');
    },
    insufficientBalance() {
      if (this.pricingModel === PRICING_MODEL.USAGE_BASED) {
        return;
      }
      return this.balance - this.amount < 0;
    },
    recipients() {
      const contacts = Object.keys(this.recipientsLocal.contacts).map((key) => parseInt(key, 10));
      const numbers = Object.values(this.recipientsLocal.manual).map((number) => number.value);

      return {
        contacts,
        numbers,
      };
    },
    isEditingBroadcast() {
      return this.action === 'edit';
    },
    titleLabel() {
      if (this.isEditingBroadcast) {
        return this.$t('broadcast.editing_broadcast_title');
      } else return this.$t('broadcast.new_broadcast');
    },
    formattedAmount() {
      return currency(this.amount);
    },
  },
  methods: {
    getIcon(type) {
      if (type === CHANNEL_TYPE.SMS) {
        return 'wab-broadcasting/sms';
      } else return 'wab-broadcasting/whatsapp';
    },
    mapItemsBySearch(search) {
      return this.senders.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));
    },
    updateMessage(incomingMessage) {
      this.message = incomingMessage;
    },
    updateTags(incomingTags) {
      this.tags = incomingTags;
    },
    updateDate(incomingDate) {
      this.date = incomingDate;
    },
    updateTime(incomingTime) {
      this.time = incomingTime;
    },
    updateDateRadio(incomingDateRadio) {
      const tz = this.$root.user.timezone;
      const date = moment().tz(tz).format();
      const currentDate = date.split('T')[0];
      this.dateRadio = incomingDateRadio;

      if (incomingDateRadio === 'now') {
        this.updateDate(currentDate);
      }
    },
    handleGoBack() {
      this.$router.push('/broadcasting');
    },
    handleSubmitButtonValidation() {
      this.isSubmitButtonValid = !Object.values(this.validation).includes(false);
    },
    validate() {
      this.validation = {
        sender: !!this.sender,
        date: this.dateRadio === 'now' || this.date?.length > 0,
        time: this.dateRadio === 'now' || this.time?.length > 0,
        timeFormat:
          this.dateRadio === 'now' || this.time?.length === 0 || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.time),
        recipients: !!(this.recipients?.contacts.length || this.recipients?.numbers.length),
        template: this.senderObject?.type === CHANNEL_TYPE.SMS || !!this.template,
        message: this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS || stripHtml(this.message).length > 0,
        tags: !this.template || !includes(map(this.tags, 'value'), ''),
        timeAfterNow:
          this.dateRadio === 'now' ||
          moment(this.date + ' ' + this.time)
            .tz(this.$root.user.timezone)
            .isAfter(moment().tz(this.$root.user.timezone)),
      };
      this.handleSubmitButtonValidation();
      return this.isSubmitButtonValid;
    },
    handleSubmit() {
      this.validationTriggered = true;
      if (!this.validate()) {
        return false;
      }
      if (this.whatsappDisclaimerAccepted || this.senderObject?.type === CHANNEL_TYPE.SMS) {
        this.confirmBroadcastModalIsOpen = true;
      } else {
        this.whatsappDisclaimerModalIsOpen = true;
      }
    },
    disclaimerAccepted() {
      this.whatsappDisclaimerModalIsOpen = false;
      this.$tStorage.setItem(this.$root.user.id + '_whatsapp_disclaimer_accepted', true);
      this.whatsappDisclaimerAccepted = true;
      this.confirmBroadcastModalIsOpen = true;
    },
    confirmBroadcast() {
      this.isConfirmBtnDisabled = true;
      if (this.dateRadio === 'now') {
        this.send_at = null;
      } else if (this.dateRadio === 'later') {
        const tz = this.$root.user.timezone;
        this.send_at = moment.tz(this.date + ' ' + this.time, '', tz).format();
      }
      const now = moment().tz(this.$root.user.timezone);
      let apiParams;
      if (this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS) {
        apiParams = {
          name: this.name ? this.name : this.$t('broadcast.whatsapp_broadcast_title') + now.format('DD/MM/YYYY'),
          channel_id: this.sender,
          template_id: this.templateId,
          send_at: this.send_at,
          tags: this.tags,
          recipients: {
            contacts: this.recipients.contacts,
            numbers: this.recipients.numbers,
          },
        };
      } else {
        apiParams = {
          name: this.name ? this.name : this.$t('broadcast.sms_broadcast_title') + now.format('DD/MM/YYYY'),
          channel_id: this.sender,
          send_at: this.send_at,
          recipients: {
            contacts: this.recipients.contacts,
            numbers: this.recipients.numbers,
          },
          message: this.message,
        };
      }
      if (!this.isEditingBroadcast) {
        createBroadcast(apiParams)
          .then(() => {
            this.confirmBroadcastModalIsOpen = false;
            this.$nextTick(() => {
              if (this.dateRadio === 'now') {
                this.flashSuccess(this.$t('broadcast.your_broadcast_will_begin_sending_shortly'));
              } else {
                this.flashSuccess(this.$t('broadcast.broadcast_scheduled_successfully'));
              }
              this.$router.push('/broadcasting');
              this.isConfirmBtnDisabled = false;
            });
          })
          .catch((e) => {
            this.isConfirmBtnDisabled = false;
            console.error(e);
            if (e.response?.data?.errors && Object.values(e.response.data.errors).length) {
              this.flashError(
                Object.values(e.response?.data?.errors)
                  .map((o) => o[0])
                  .join('<br />')
              );
            }
          });
      } else {
        updateBroadcast(this.$route.params.id, apiParams)
          .then(() => {
            this.confirmBroadcastModalIsOpen = false;
            this.$nextTick(() => {
              this.flashSuccess(this.$t('broadcast.broadcast_updated_successfully'));
              this.$router.push('/broadcasting');
            });
          })
          .catch((e) => {
            console.error(e);
            if (e.response?.data?.errors && Object.values(e.response.data.errors).length) {
              this.flashError(
                Object.values(e.response?.data?.errors)
                  .map((o) => o[0])
                  .join('<br />')
              );
            }
          });
      }
    },
    topUp() {
      this.$tStorage.setItem(this.$root.user.id + '_broadcast_record', {
        name: this.name,
        sender: this.sender,
        templateId: this.templateId,
        message: this.message,
        recipientsLocal: this.recipientsLocal,
        tags: this.tags,
        dateRadio: this.dateRadio,
        date: this.date,
        time: this.time,
      });
      this.$router.push(SETTINGS_URL.ADMIN__BALANCE_SETTINGS);
    },
    async loadRecord() {
      this.record = await this.$tStorage.getItem(this.$root.user.id + '_broadcast_record');
      if (!isEmpty(this.record)) {
        this.$tStorage.setItem(this.$root.user.id + '_broadcast_record', {});
        this.name = this.record.name;
        this.sender = this.record.sender;
        this.templateId = this.record.templateId;
        this.message = this.record.message;
        this.recipientsLocal = this.record.recipientsLocal;
        this.tags = this.record.tags;
        this.dateRadio = this.record.dateRadio;
        this.date = this.record.date;
        this.time = this.record.time;
      }
    },
    convertAndGetEstimation() {
      if (this.sender && (this.senderObject.type === CHANNEL_TYPE.WA_BUSINESS || stripHtml(this.message).length)) {
        let estimationParams;
        if (this.senderObject?.type === CHANNEL_TYPE.WA_BUSINESS) {
          estimationParams = {
            channel_id: this.sender,
            recipients: {
              contacts: this.recipients.contacts,
              numbers: this.recipients.numbers,
            },
            ...(this.templateId && { hsm_id: this.templateId }),
          };
        } else {
          estimationParams = {
            channel_id: this.sender,
            message: stripHtml(this.message),
            recipients: {
              contacts: this.recipients.contacts,
              numbers: this.recipients.numbers,
            },
          };
        }
        this.debouncedEstimation(estimationParams);
      }
    },
    async getPricingModel() {
      try {
        this.pricingModel = await checkPricingModel();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" src="./CreateBroadcast.scss" />
