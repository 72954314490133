import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchCompanyProfile } from '@/api';

import type { CompanyProfile } from '@/types/companyProfile';

export const useCompanyProfileStore = defineStore('companyProfile', () => {
  const isPending = ref(false);
  const isInitialized = ref(false);
  const companyProfile = ref<CompanyProfile | null>(null);

  const init = async () => {
    if (isInitialized.value) return;
    await loadCompanyProfile();
    isInitialized.value = true;
  };

  const loadCompanyProfile = async () => {
    isPending.value = true;
    try {
      const { data } = await fetchCompanyProfile();
      companyProfile.value = data;
    } catch (err) {
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  return {
    isInitialized,
    isPending,
    companyProfile,
    init,
    reload: loadCompanyProfile,
  };
});
