<template>
  <router-link
    v-if="show"
    to="/profile/get-started"
    class="selector-navigate-onboarding-gamification mt-2 text-sm leading-none"
  >
    <div style="height: 7px" class="flex w-full overflow-hidden rounded-full bg-grey-600">
      <div :style="{ width: animatedPercentage + '%' }" class="bg-green"></div>
    </div>
    <div class="mt-2 flex px-2">
      <div>
        <strong>{{ animatedPercentage }}%</strong>
        completed
      </div>
      <div class="ml-auto text-grey-500">get to 100%</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'OnboardingStepsIndicator',
  data() {
    return {
      tweenedPercentage: this.$store.getters['onboardingSteps/completedPercentage'],
    };
  },
  mounted() {},
  methods: {},
  computed: {
    show() {
      return (
        this.animatedPercentage < 100 &&
        (this.unseenCompletedCount || this.$root.user.config['onboarding_steps.enabled'])
      );
    },
    unseenCompletedCount() {
      return this.$store.getters['onboardingSteps/unseenCompleted'].length;
    },
    percentage() {
      return this.$store.getters['onboardingSteps/completedPercentage'];
    },
    isDone() {
      const percentage = this.$store.getters['onboardingSteps/completedPercentage'];
      return percentage === 100;
    },
    animatedPercentage() {
      return parseInt(this.tweenedPercentage.toFixed(0));
    },
  },
};
</script>

<style scoped>
.unseen-steps-label {
  margin-left: -1em;

  width: 13px;
  padding: 0;
  height: 13px;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 13px;
}
</style>
