import { useReportStore } from '@/components/Reporting/store';

export function isOldReportingEnabled() {
  return useReportStore().isOldReportingEnabled;
}

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

export function queryParamsGuard(to, from, next) {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    if (from.name === 'reportingCSAT' && to.name === 'reportingTicketInsights') {
      next();
    } else {
      next({ name: to.name, query: from.query });
    }
  } else {
    next();
  }
}
