<template>
  <div ref="backdrop" class="trengo-modal-chat flex items-center justify-center md:p-8 md:py-8" @click.self="close">
    <div
      class="
        box
        custom-shadow-outline
        chat-modal
        relative
        flex
        h-full
        max-h-full
        w-full
        select-none
        flex-col
        rounded-lg
        lg:h-auto lg:w-auto
      "
      tabindex="0"
      @keydown.esc="$emit('close')"
    >
      <div class="modal-header">
        <h3 class="mb-0 text-center" style="font-size: 18px; line-height: 1.5">
          {{ $t('internal_chat.insert_attachment_modal_title') }}
        </h3>
        <button type="button" class="modal-close-btn leading-none" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px" @click="$emit('close')">close</i>
        </button>
      </div>

      <div class="px-4 lg:px-8">
        <chat-composer
          :placeholder="$t('internal_chat.insert_attachment_modal_message_placeholder')"
          class="chat-composer-upload mb-2"
          :initial-max-height="110"
          @composerInit="onComposerInit"
        ></chat-composer>

        <div
          class="mb-1 bg-grey-200 p-1 md:flex md:flex-row md:items-center"
          style="min-height: 84px; border-radius: 0.25rem; overflow-x: auto"
          :class="{ 'custom-shadow-outline': draggingOver }"
        >
          <div v-for="file in files" class="file-box relative m-1 inline p-0">
            <button
              class="black inline-block-20px file-box-button absolute h-20 rounded"
              style="right: -2px; top: -2px"
              @click="removeFile(file)"
            >
              <i
                class="fa fa-close inline-block-20px h-20 text-center text-white"
                style="line-height: 20px; vertical-align: top"
                aria-label="Close"
              ></i>
            </button>
            <div class="overflow-hidden p-0" style="border-radius: 0.25rem; height: 64px; width: 64px">
              <img
                v-if="getUrl(file)"
                :src="getUrl(file)"
                :title="file.name"
                class="h-full w-full"
                style="object-fit: cover"
              />
              <div v-else class="p-2 text-center">
                <i class="fa text-2x text-success" :class="getIcon(file)"></i>
                <span class="text-truncate block pt-1">{{ file.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4" style="height: 29px">
          <label v-if="files.length < maxCount" role="button" class="text-success">
            <span>{{ $t('internal_chat.insert_attachment_modal_add_file') }}</span>
            <input ref="fileInput" type="file" multiple class="none" @change="onFileInputChanged" />
          </label>

          <span v-if="files.length > 1">
            {{ $t('internal_chat.insert_attachment_modal_files_selected', { selected: files.length, max: maxCount }) }}
          </span>
        </div>

        <label for="threadSelector" class="mb-1 block">
          {{ $t('internal_chat.insert_attachment_modal_share_with') }}:
        </label>
        <div class="flex pb-6 lg:pb-8">
          <select
            id="threadSelector"
            v-model="threadIdentifier"
            :placeholder="$t('internal_chat.insert_attachment_modal_placeholder')"
            class="form-control form-input min-w-0"
          >
            <option v-for="thread in [...groupThreads, ...userThreads]" :value="thread.identifier">
              {{ thread.name }} - {{ thread.identifier }}
            </option>
          </select>
          <div class="relative ml-2 flex items-center justify-center">
            <button
              ref="fileUploadBtn"
              class="btn btn-succes success block h-full sm:inline-block"
              :class="{ loader: spinner }"
              :disabled="spinner"
              @click="upload"
            >
              {{ $t('internal_chat.insert_attachment_modal_button_upload') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getClassNameForExtension from 'font-awesome-filetypes';
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';

import ChatComposer from '../ChatComposer';

export default {
  name: 'InsertAttachmentModal',
  emits: ['close', 'upload'],
  components: { ChatComposer },
  data() {
    return {
      threadIdentifier: this.initThreadIdentifier, // string
      files: [...this.initFiles], // array <file>
      message: this.initialMessage,
    };
  },
  props: {
    initThreadIdentifier: { default: '', type: String },
    initFiles: { default: () => [], type: Array },
    draggingOver: { default: false, type: Boolean },
    maxCount: { default: 10, type: Number },
    initialMessage: { default: '', type: String },
    replyTo: { default: '', type: String },
    spinner: { default: false, type: Boolean },
  },

  mounted() {
    this.files.splice(this.maxCount);

    eventBus.$on('chat@ATTACHMENT_MODAL_SEND', () => this.upload());
  },

  // watch: {
  //     files: {
  //         deep: true,
  //         handler(v) {
  //             this.files = v;
  //             eventBus.$emit('attachment_file_upload', this.files);
  //         }
  //     }
  // },
  methods: {
    upload() {
      this.$emit('upload', {
        files: this.files,
        message: this.editor.html.get(),
        replyTo: this.replyTo,
        threadIdentifier: this.threadIdentifier,
      });
    },
    close() {
      this.$emit('close');
    },
    getUrl(file) {
      if (file.type && ['image/jpeg', 'image/gif', 'image/png'].includes(file.type)) {
        return URL.createObjectURL(file);
      }
    },
    removeFile(file) {
      let index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },
    getIcon(file) {
      if (file.name) {
        let ext = file.name.split('.').pop();
        return getClassNameForExtension(ext);
      }
    },
    onFileInputChanged(e) {
      if (this.$refs.fileInput.files) {
        this.addFiles(this.$refs.fileInput.files);
      }
    },
    addFiles(files) {
      this.files.push(...files); // append files
      this.files.splice(this.maxCount); // limit to maxCount
      // todo rl: check max size
      // todo rl: do we want mime type checks on client-side or allow all?
    },
    onComposerInit(editor) {
      this.editor = editor;
      this.editor.html.set(this.message);
      setTimeout(() => {
        window.placeCaretAtEnd(this.editor.el);
      }, 1);
    },
  },
  beforeUnmount() {
    // eventBus.$off('chat@ATTACHMENT_MODAL_APPEND', 'attachment_file_upload');
    eventBus.$off('chat@ATTACHMENT_MODAL_APPEND');
  },
  beforeMount() {
    eventBus.$on('chat@ATTACHMENT_MODAL_APPEND', (files) => {
      this.addFiles(files);
    });
  },
  computed: {
    ...mapGetters({
      userThreads: 'chat/userThreads',
      groupThreads: 'chat/groupThreads',
      thread: 'chat/currentThread',
    }),
  },
};
</script>

<style scoped lang="scss">
body {
  overflow-y: auto;
}

.trengo-modal-chat {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 99999999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;

  .modal-header {
    border-bottom: 0 !important;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    min-width: 811px;
    width: 811px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    border: none;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }

    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }
  .form-input {
    border-color: theme('colors.grey-200');
    border-width: 2px;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.file-box {
  border-radius: 0.25rem;
  height: 66px;
  width: 66px;
  .file-box-button {
    opacity: 0;
    transition: opacity 75ms ease-in-out;
  }
  &:hover {
    .file-box-button {
      opacity: 1;
    }
  }
}

@media (max-width: 991px) {
  .trengo-modal-chat {
    .box {
      min-width: 766px;
      width: 766px;
    }
  }
}

@media (max-width: 767px) {
  .trengo-modal-chat {
    .box {
      width: 100%;
      border-radius: 0;
      min-width: auto;
    }
  }
}
</style>
