<template>
  <div v-if="fields.length > 0" class="sidebar_block border-b border-grey-300">
    <sidebar-collapse collapse-key="collapseTicketSidebarProfileFieldEditor">
      <template #header>
        <h6 class="mb-0">
          {{ $t('ticket_sidebar.profile_fields') }}
        </h6>
      </template>
      <div>
        <div v-for="field in fieldsNotAligned" :key="field.title" class="nav-item m-t-xs">
          <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
          <custom-field-item :field="field" @save="save(field)" />
        </div>
        <div v-if="aligned" class="row p-a">
          <div v-for="field in fields" :key="field.title" class="m-b-xs">
            <div class="mb-1 text-sm font-semibold text-grey-700">{{ field.title }}</div>
            <custom-field-item :field="field" @save="save(field)" />
          </div>
        </div>
      </div>
    </sidebar-collapse>
  </div>
</template>

<script type="text/babel">
import { cloneDeep, find, lowerCase } from 'lodash';
import { mapState } from 'vuex';

import SidebarCollapse from '@/components/common/SidebarCollapse';
import ticketSidebarMixins from '@/components/TicketSidebar/ticketSidebarMixins';

import { storeProfileCustomField as apiStoreProfileCustomField } from './Api';
import CustomFieldItem from './CustomFieldItem';

export default {
  data() {
    return {
      profileFields: {},
      fields: [],
    };
  },
  mixins: [ticketSidebarMixins],
  props: {
    profile: {},
    aligned: {
      type: Boolean,
      default: false,
    },
    selectedPlugins: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    this.loadList();
  },

  watch: {
    profile() {
      this.loadList();
    },
    customFieldsLoaded(loaded) {
      if (loaded) {
        this.loadList();
      }
    },
  },
  components: {
    CustomFieldItem,
    SidebarCollapse,
  },

  computed: {
    ...mapState({
      storedFields: (state) => state.customFields.customProfileFields,
      customFieldsLoading: (state) => state.customFields.loading,
      customFieldsLoaded: (state) => state.customFields.loaded,
    }),
    fieldsNotAligned() {
      return !this.aligned ? this.fields : [];
    },
  },

  methods: {
    loadList() {
      this.fields = this.storedFields;
      this.fields = this.fields.map((field) => {
        let value = this.profile.custom_fields.filter((f) => f.id == field.id);
        return {
          ...field,
          value: value.length ? value[0].pivot.value : null,
          profileId: this.profile.id,
        };
      });
      this.commitFields();
    },
    commitFields() {
      this.$store.commit('customFields/setTicketProfileFields', cloneDeep(this.fields));
    },
    save(fieldToSave) {
      const field = find(this.fields, function (field) {
        return field.id === fieldToSave.id;
      });
      const fieldValue = field?.value || null;
      this.commitFields();
      apiStoreProfileCustomField(this.profile.id, field.id, fieldValue)
        .then(() => {
          this.resetFieldServerError(lowerCase(field.type), field.id);
        })
        .catch((error) => {
          this.setFieldServerError(lowerCase(field.type), field.id, error.response?.data?.errors);
        })
        .finally(() => {
          this.commitFields();
        });
    },
  },
};
</script>
