<template>
  <div>
    <draggable v-model="record.chat.pre_form_custom_fields" handle=".handle" item-key="id">
      <div v-for="field in record.chat.pre_form_custom_fields" :key="field.id" class="box flex flex-col p-4">
        <div class="flex flex-row items-center">
          <strong>{{ getTitle(getType(field)) }}</strong>
          <div class="ml-auto"></div>
          <div class="ml-auto flex items-center">
            <label class="text-muted mb-0">
              <input v-model="field.required" type="checkbox" true-value="1" />
              {{ $t('web_widget.required') }}
            </label>
            <a class="text-muted handle ml-3 cursor-grab"><i class="fa fa-sort"></i></a>
            <a class="text-muted ml-3" @click="record.chat.pre_form_custom_fields.splice(i, 1)">
              <i class="fa fa-remove"></i>
            </a>
          </div>
        </div>
        <div class="mt-2">
          <div v-for="lang in record.languages" class="mb-2">
            <div class="text-muted mb-1">{{ $t('web_widget.title') }} ({{ lang }})</div>
            <input v-model="field.title[lang]" type="text" class="form-control" />
          </div>
          <div v-if="field.type === 'team_selector'" class="pl-4 pt-2">
            <div v-for="(option, i) in field.options" class="pb-3">
              <div class="text-muted mb-2 flex items-center">
                {{ $t('web_widget.option') }} #{{ i + 1 }}
                <a class="text-muted ml-3 ml-auto text-xs" @click="field.options.splice(i, 1)">
                  <i class="fa fa-remove"></i>
                </a>
              </div>
              <div class="flex items-center">
                <input v-model="option.label" type="text" placeholder="Department" class="form-control mb-1" />
                <i class="material-icons text-muted md-18 px-2">arrow_right_alt</i>
                <select v-model="option.value" class="form-control">
                  <option :value="null">{{ $t('web_widget.assign_to_team') }}</option>
                  <option v-for="team in $root.teams" :value="team.id">{{ team.name }}</option>
                </select>
              </div>
            </div>
            <a class="text-muted mt-2" @click="addOption(field)">
              <i class="fa fa-plus"></i>
              {{ $t('web_widget.add_option') }}
            </a>
          </div>
          <div v-if="getType(field) === 'text_field'" class="">
            <span class="text-muted text-xs">
              {{ $t('web_widget.optional_persist_the_value_into_a_custom_field_instead_of_a_ticket_note') }}
            </span>
            <select v-model="field.custom_field_id" class="form-control">
              <option :value="null"></option>
              <option v-for="field in $root.customTicketFields" :value="field.id">{{ field.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </draggable>
    <div style="cursor: pointer">
      <div class="dropdown">
        <a href="#" data-toggle="dropdown" aria-expanded="false" class="nav-link text-muted">
          <i class="fa fa-plus"></i>
          {{ $t('web_widget.add_field') }}
        </a>

        <div role="menu" class="dropdown-menu text-color">
          <a class="dropdown-item" @click="addField('text_field')">{{ $t('web_widget.text_field') }}</a>
          <a class="dropdown-item" @click="addField('team_selector')">{{ $t('web_widget.department_selector') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  components: {
    draggable: VueDraggableNext,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    getTitle(type) {
      switch (type) {
        case 'text_field':
          return this.$t('web_widget.text_field');
        case 'team_selector':
          return this.$t('web_widget.department_selector');
      }
    },

    addOption(field) {
      field.options.push({
        label: '',
        value: null,
      });
    },

    addField(type) {
      if (!this.canAddField(type)) {
        return;
      }

      let title = {};
      _.each(this.record.languages, (k) => {
        if (type === 'team_selector') {
          title[k] = this.$t('web_widget.choose_a_department');
        } else {
          title[k] = '';
        }
      });

      let row = {
        type: type,
        title: title,
        options: [],
      };

      this.record.chat.pre_form_custom_fields.push(row);
    },

    canAddField(type) {
      if (type === 'text_field') {
        return true;
      }

      if (type === 'team_selector') {
        if (
          this.record.chat.pre_form_custom_fields.filter((f) => {
            return f.type === type;
          }).length === 1
        ) {
          return false;
        }
      }

      return true;
    },

    getType(field) {
      return field.type || 'text_field';
    },
  },
};
</script>
