<script type="text/babel">
import SidebarCollapse from '@/components/common/SidebarCollapse';

export default {
  props: ['ticket', 'selectedPlugins'],

  data: function () {
    return {
      base_url: window.base_url,
    };
  },

  computed: {
    attachments() {
      let att = {};
      let attachments = [];

      function processAttachments(attachment) {
        if (!attachment.message_id) {
          // Attachments in message table itself instead of for example email attachments, refactor properties
          att['extension'] = attachment.file_name.substr(attachment.file_name.lastIndexOf('.') + 1);
          att['file_name'] = attachment.file_name;
          att['id'] = attachment.id;
          att['message_of_type_file'] = true;
          att['client_name'] = attachment.client_name ? attachment.client_name : attachment.file_name;
          att['full_url'] = this.cdnPath(attachment.file_name);
          attachments.push(att);
          att = {};
        } else {
          attachments.push(attachment);
        }
      }

      this.ticket.attachments.forEach((attachment) => {
        processAttachments.call(this, attachment);
      });

      this.ticket.messaging_attachments.forEach((attachment) => {
        processAttachments.call(this, attachment);
      });

      return attachments;
    },
    showDownloadAttachmentFile() {
      return !is_mobile_device() && !window.isLoadedFromApp && !window.isElectron;
    },
  },

  components: {
    SidebarCollapse,
  },

  mounted: function () {},

  methods: {
    openLocalAttachment(attachment, e) {
      if (attachment.full_url.match(/.(jpg|jpeg|png|gif)$/i) && !this.$root.loadedFromApp && !this.$root.isDesktopApp) {
        window.PopupCenter(attachment.full_url, 'Attachment', 1020, 700);
        e.preventDefault();
        return;
      }
    },

    cdnPath: function (name) {
      return CDN + 'media/' + name;
    },
  },
};
</script>

<template>
  <div v-if="attachments.length > 0" class="sidebar_block border-b border-grey-300">
    <sidebar-collapse collapse-key="collapseTicketSidebarAttachments">
      <template #header>
        <h6 class="mb-0">
          {{ $t('ticket_sidebar.attachments') }}
        </h6>
      </template>
      <div class="sidebar_block_body">
        <div class="mt-3">
          <div v-for="attachment in attachments" :key="attachment.id" class="text-ellipsis">
            <div
              class="text-success b-success inline border px-1 text-center uppercase"
              style="border-radius: 0.25rem; min-width: 40px; font-size: 11px"
            >
              {{ attachment.extension }}
            </div>
            <a
              v-if="showDownloadAttachmentFile"
              :href="
                attachment.message_of_type_file
                  ? base_url + 'download/message-file/' + attachment.id
                  : base_url + 'download/attachment/' + attachment.id
              "
              target="_blank"
            >
              <i class="material-icons align-middle text-grey-600" style="font-size: 20px">file_download</i>
            </a>
            <a
              style="color: inherit; text-decoration: none"
              data-hj-suppress
              :class="{ 'ml-1': !showDownloadAttachmentFile }"
              class="open-external"
              :href="
                attachment.message_of_type_file
                  ? base_url + 'download/message-file/' + attachment.id
                  : base_url + 'download/attachment/' + attachment.id
              "
              target="_blank"
              @click="attachment.is_image && openLocalAttachment(attachment, $event)"
            >
              {{ attachment.client_name }}
              <small v-if="attachment.size" class="text-muted mx-1">{{ attachment.size }}</small>
            </a>
          </div>
        </div>
      </div>
    </sidebar-collapse>
  </div>
</template>
