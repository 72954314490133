<template>
  <div class="row-col bg-white">
    <div class="t-shadow-xs block sm:hidden">
      <div class="navbar no-radius">
        <ul class="nav navbar-nav">
          <li class="nav-item" />
        </ul>
      </div>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="container p-4 sm:px-20 sm:pb-0 sm:pt-12">
            <top-header />
          </div>

          <div class="container mb-2 p-4 pt-0 sm:px-20 sm:pb-2">
            <need-help-box />

            <div v-if="hasAnyPermission" class="t-text-mobile-h4 sm:t-text-desktop-h4 mb-3 sm:mb-4">
              {{ $t('getting_started.the_essentials') }}
            </div>
            <div class="flex flex-col">
              <div :class="`mb-4 grid grid-cols-1 gap-4 ${showGridForChannelsAndUsers ? 'xl:grid-cols-2' : ''}`">
                <add-channel v-if="$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)" />
                <add-users v-if="$authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)" />
              </div>

              <div v-if="$authorization().hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)">
                <add-integration v-if="!isIntegrationHubEnabled" />
                <integration-hub-cards v-else />
              </div>

              <learn-more
                v-if="
                  !$root.mobileDevice && $authorization().hasPermission(PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE)
                "
                class="hidden sm:block"
                :class="hasAnyPermission ? 'mt-12' : ''"
              ></learn-more>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NeedHelpBox from '@/components/GetStarted/components/NeedHelpBox';
import IntegrationHubCards from '@/components/OverviewPage/components/IntegrationHubCards';
import { FEATURE_FLAG_INBOX, PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';

import { openLink } from '../../../util/helpers';
import AddChannel from '../components/AddChannel';
import AddIntegration from '../components/AddIntegration';
import AddUsers from '../components/AddUsers';
import LearnMore from '../components/LearnMore';
import TopHeader from '../components/TopHeader';
export default {
  name: 'OverviewPage',
  components: {
    NeedHelpBox,
    TopHeader,
    LearnMore,
    AddIntegration,
    AddUsers,
    AddChannel,
    IntegrationHubCards,
  },

  data() {
    return {
      PERMISSION,
    };
  },

  computed: {
    hasAnyPermission() {
      return this.$authorization().hasAnyPermission([
        PERMISSION.SETTINGS__CHANNELS__MANAGE,
        PERMISSION.PERMISSIONS__USERS__MANAGE,
        PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE,
      ]);
    },

    hasNotSubscription() {
      return this.$root?.companyProfile?.subscription?.plan_id === null;
    },

    showGridForChannelsAndUsers() {
      return (
        this.$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE) &&
        this.$authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
      );
    },
    isIntegrationHubEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB);
    },
  },
  methods: {
    handleOpenWebChat(ChannelType) {
      window.Trengo?.Api?.Widget?.open(ChannelType);
    },
    requestACall() {
      if (this.$root.user.locale_code === 'nl-NL') {
        openLink('https://meetings.hubspot.com/trengo-meets/demo-sdr-dutch');
      } else {
        openLink('https://meetings.hubspot.com/trengo-meets/demo-sdr-english');
      }
    },
    watchADemo() {
      openLink('https://www.youtube.com/watch?v=VFCQAWnWOmo');
    },
  },
};
</script>
