<template>
  <div class="" style="height: 100%; margin-top: -2px" :class="{ 'composer-dragenter': dragenter }">
    <div
      v-if="newTicket || $authorization().hasPermission(PERMISSION.INBOX__CONVERSATIONS__REPLY)"
      class="composer-container pos-relative flex h-full min-w-0 flex-col bg-white lg:rounded-xl"
      :class="{ 'composer__grey-border': composerGreyBorderClass }"
    >
      <div v-if="newTicket && !popout" class="modal-header">
        <h5
          class="modal-title text-center leading-none"
          style="font-size: 18px"
          v-text="forwarding ? $t('tickets.composer_title_forward') : $t('tickets.composer_title_new')"
        ></h5>
        <button type="button" class="modal-close-btn leading-none" data-dismiss="modal" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px">close</i>
        </button>
      </div>
      <form
        class="flex min-h-0 w-full min-w-0 flex-1 flex-col"
        style="word-wrap: break-word"
        @submit.prevent="onFormSubmitted"
      >
        <template v-if="publicProvider === 'FACEBOOK' && replyingTo">
          <span class="flex items-center border-b-2 border-grey-200 px-4 py-3">
            <i class="fa fa-facebook mr-2" style="color: rgb(97, 110, 255)"></i>
            <template v-if="ticket.channelMeta.type && ticket.channelMeta.type === 'FACEBOOK_FEED_MESSAGES'">
              <div
                class="pr-1 text-grey-500"
                v-html="$t('tickets.replying_to', { type: replyingTo.type, name: replyingTo.full_name })"
              ></div>
              <div class="text-grey-600">{{ $t('tickets.replying_to_facebook_page') }} {{ ticket.channel.name }}</div>
            </template>
            <template v-else>
              <div
                class="pr-1 text-grey-500"
                v-html="$t('tickets.replying_to', { type: 'mention', name: replyingTo.full_name })"
              ></div>
              <div class="text-grey-600">{{ $t('tickets.replying_to_facebook_post') }} {{ ticket.contact.name }}</div>
            </template>
          </span>
        </template>
        <template v-else-if="publicProvider === 'INSTAGRAM' && replyingTo">
          <span class="flex items-center border-b-2 border-grey-200 px-4 py-3">
            <i class="fa fa-instagram mr-2" style="color: rgb(131, 58, 180)"></i>
            <div
              class="pr-1 text-grey-500"
              v-html="$t('tickets.replying_to_instagram', { type: replyingTo.type, name: replyingTo.full_name })"
            ></div>
            <div class="text-grey-600">{{ $t('tickets.replying_to_instagram_profile') }} {{ ticket.channel.name }}</div>
          </span>
        </template>
        <div
          v-show="ticket == null || isEmail()"
          class="relative flex min-w-0 border-b-2 border-grey-200"
          :class="{ 'pb-1 lg:pb-0': showCc || showBcc }"
        >
          <div class="flex-1 text-ellipsis xl3:flex">
            <div
              style="/*pointer-events: none*/"
              class="flex min-w-0 items-center"
              :class="{ 'cursor-pointer': !newTicket && !popout }"
              v-on="!newTicket && !popout ? { click: openSendAsModal } : {}"
            >
              <label class="m-0 px-3 py-2 pr-2 text-grey-500" :class="{ 'cursor-pointer': !newTicket && !popout }">
                {{ $t('tickets.composer_from') }}:
              </label>
              <dropdown
                v-if="newTicket"
                v-model="channel"
                :options="channels"
                :visible-items="5"
                :caret="true"
                data-test-dropdown-toggle="composer-from-channel-dropdown-toggle"
                data-test-dropdown-list="composer-from-channel-dropdown-list"
                data-test-search-dropdown-input="composer-select-channel-search-input"
                data-test-dropdown-list-item="composer-from-channel-dropdown-list-item"
              >
                <template #heading>
                  <div class="_500 select-none border-grey-200 pt-4 text-center text-base leading-none text-black">
                    {{ $t('tickets.select_sender') }}
                  </div>
                </template>
                <template #toggle="prop">
                  <div
                    v-if="prop.label"
                    class="
                      flex
                      min-w-0
                      select-none
                      flex-nowrap
                      items-center
                      text-ellipsis
                      rounded-lg
                      bg-grey-200
                      leading-none leading-none
                      text-grey-600
                      hover:text-grey-800
                    "
                    style="display: inline-flex; vertical-align: middle; padding: 5px 10px"
                  >
                    <div class="flex items-center">
                      <i v-if="prop.selected?.is_private" class="fa fa-lock mr-2 text-grey-500"></i>
                      <span class="ticket-from-channel text-ellipsis">{{ prop.label }}</span>
                    </div>
                  </div>
                </template>
                <template #option="prop">
                  <div class="flex items-center">
                    <i v-if="prop.option?.is_private" class="fa fa-lock mr-2 text-grey-500"></i>
                    <span class="text-ellipsis">{{ prop.option?.display_name }}</span>
                  </div>
                </template>
              </dropdown>
              <span
                v-if="!newTicket"
                style="margin: 5px; padding: 5px 10px"
                class="mr-1 cursor-pointer text-ellipsis rounded-lg bg-grey-200 leading-none text-grey-600"
              >
                {{ channel.text }}
              </span>
            </div>
            <div
              class="
                align-items
                flex
                min-w-0
                flex-1 flex-shrink-0
                items-center
                border-t-2 border-grey-200
                xl3:border-t-0
              "
              :class="{ 'mb-2 xl3:mb-0': !isEmail() }"
              style="min-height: 35px"
            >
              <label class="m-0 px-3 py-0 pr-2 text-grey-500">{{ $t('tickets.composer_to') }}:</label>
              <div class="w-full text-ellipsis pr-2">
                <dropdown
                  v-model="contact"
                  label="display_name"
                  :multiple="channel.type === CHANNEL_TYPE.EMAIL"
                  :options="contacts"
                  :remote="true"
                  selected-label-field="identifier"
                  data-test-remote-search-input="composer-to-recipient-remote-search-input"
                  data-test-dropdown-toggle="composer-to-recipient-channel-dropdown-toggle"
                  data-test-dropdown-list="composer-to-recipient-dropdown-list"
                  data-test-dropdown-list-item="composer-to-recipient-dropdown-list-item"
                  @update:modelValue="processDraft"
                  @search="onContactSearch"
                ></dropdown>
              </div>
            </div>
          </div>
          <div
            v-show="isEmail()"
            class="align-items ml-auto flex items-start border-b-2 border-grey-200 pl-3 xl3:border-b-0"
            style="height: 39px; padding-top: 7px"
          >
            <span
              class="composer-btn cursor-pointer"
              :class="{ 'bg-grey-200 text-grey-600': !showCc, 'bg-grey-600 text-white ': showCc }"
              style="margin-right: 5px"
              @click="showCc = !showCc"
            >
              {{ $t('tickets.composer_cc_uppercase') }}
            </span>
            <span
              class="composer-btn cursor-pointer"
              :class="{ 'bg-grey-200 text-grey-600': !showBcc, 'bg-grey-600 text-white': showBcc }"
              style="margin-right: 10px"
              @click="showBcc = !showBcc"
            >
              {{ $t('tickets.composer_bcc_uppercase') }}
            </span>
            <a
              v-if="canPopout"
              class="composer-btn bg-grey-200"
              style="margin-left: -5px; margin-right: 10px"
              @click="openInExternalWindow()"
            >
              <i class="material-icons md-12 text-grey-600">open_in_new</i>
            </a>
          </div>
        </div>
        <div
          v-show="showCc"
          class="align-items flex flex-shrink-0 items-center border-b-2 border-grey-200"
          style="min-height: 35px"
        >
          <label class="m-0 px-3 py-2 pr-2 text-grey-500">{{ $t('tickets.composer_cc') }}:</label>
          <span class="flex-1 text-ellipsis">
            <dropdown
              v-model="cc"
              label="display_name"
              :multiple="true"
              :options="contacts"
              :remote="true"
              selected-label-field="identifier"
              @update:modelValue="processDraft"
              @search="onContactSearch"
            ></dropdown>
          </span>
        </div>
        <div
          v-show="showBcc"
          class="align-items flex flex-shrink-0 items-center border-b-2 border-grey-200"
          style="min-height: 35px"
        >
          <label class="m-0 px-3 py-2 pr-2 text-grey-500" style="font-size: 14px">
            {{ $t('tickets.composer_bcc') }}:
          </label>
          <span class="flex-1 text-ellipsis">
            <dropdown
              v-model="bcc"
              label="display_name"
              :multiple="true"
              :options="contacts"
              :remote="true"
              selected-label-field="identifier"
              @update:modelValue="processDraft"
              @search="onContactSearch"
            ></dropdown>
          </span>
        </div>
        <div v-show="isEmail()" class="align-items flex flex-shrink-0 items-center border-b-2 border-grey-200">
          <label for="InputEmailSubject" class="m-0 px-3 py-2 pr-2 text-grey-500" style="font-size: 14px">
            {{ $t('tickets.composer_subject') }}:
          </label>
          <input
            id="InputEmailSubject"
            v-model="subject"
            type="text"
            data-hj-suppress
            autocomplete="off"
            class="w-full"
            style="border: none; background: none"
            @change="processDraft(true)"
          />
        </div>
        <div class="flex flex-1 flex-col">
          <div class="">
            <div
              v-if="channel.type === CHANNEL_TYPE.WA_BUSINESS && supportsWhatsappTemplateMessages"
              class="px-3 pb-2 pt-3"
            >
              <div class="flex">
                <div class="flex-1">
                  <label class="block">
                    <input
                      v-model="wa_business_message_type"
                      type="radio"
                      :value="WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE"
                      data-test="composer-default-message-radio-btn"
                    />
                    {{ sessionMessageTitle }}
                    <br />
                  </label>
                  <label class="block">
                    <input
                      v-model="wa_business_message_type"
                      type="radio"
                      :value="WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE"
                      data-test="composer-template-message-radio-btn"
                    />
                    {{ $t('tickets.wa_message_type_template_title') }}
                    <br />
                  </label>
                </div>
              </div>
            </div>
            <template v-if="emailChatDeliveryMode">
              <span class="flex items-center border-b-2 border-grey-200 px-3 py-3 text-xs text-grey-600">
                <div class="flex items-center">
                  <i class="material-icons text-md mr-2">email</i>
                  {{ $t('tickets.delivery_via_email') }} {{ ticket.contact.email }}
                </div>
              </span>
            </template>
          </div>

          <froala
            v-if="isEmail()"
            ref="emailEditor"
            v-model="message"
            has-padding-x
            has-padding-y
            data-hj-suppress
            :image="true"
            :border="false"
            image-prefix="inline_image"
            cols="30"
            :focus="true"
            rows="10"
            @paste="onPaste"
            @filePaste="onFilePaste"
            @change="processDraft(true)"
            @loaded="onEditorLoaded"
            @drop="onImageDrop"
            @blur="onFroalaBlur"
            @focus="onFroalaFocus"
          ></froala>
          <contenteditable
            v-if="shouldSendWaMessage"
            id="publicComposer"
            ref="messageEditor"
            v-model="message"
            :file-paste="true"
            :shift-enter="!newTicket ? true : undefined"
            :multiline="newTicket || chatMailMode"
            :style="contentEditableStyle"
            max-height="50vh"
            class="message-editor flex-1 overflow-auto p-3"
            :placeholder="$t('tickets.msg_placeholder')"
            @change="processDraft(true)"
            @filePaste="onFilePaste"
            @enter="onEnter"
            @input="contentEditableContentChanged"
            @blur="onContentEditableBlur"
          ></contenteditable>

          <empty-msg
            v-if="shouldShowEmptyFallbackMsg"
            :show-button="isAdmin"
            @handle-click-action="redirectToWaChannelSetting"
          >
            <template #title>
              {{ $t('tickets.no_fallback_sms_set') }}
            </template>
            <template v-if="isAdmin" #message>
              {{ $t('tickets.select_fallback_sms') }}
            </template>
            <template v-else #message>
              {{ $t('tickets.fallback_get_in_contact_with_admin') }}
            </template>
            <template v-if="isAdmin" #action>
              {{ $t('tickets.set_falllback_sms') }}
            </template>
          </empty-msg>
          <div v-if="isComposingWhatsappTemplateMessage" class="p-3">
            <empty-msg
              v-if="showEmptyTemplateMsg"
              :show-button="isAdmin"
              @handle-click-action="redirectToCreateTemplates"
            >
              <template #title>
                {{ $t('tickets.no_template_yet') }}
              </template>
              <template v-if="isAdmin" #message>
                {{ $t('tickets.create_message_template') }}
              </template>
              <template v-else #message>
                {{ $t('tickets.template_get_in_contact_with_admin') }}
              </template>
              <template v-if="isAdmin" #action>
                {{ $t('tickets.create_new_template') }}
              </template>
            </empty-msg>
            <div v-else>
              <div>
                <div style="color: rgb(166, 166, 166); font-size: 14px">{{ $t('tickets.composer_template') }}</div>
                <select v-model="wa_template" class="form-control mt-1">
                  <option v-for="message in wa_template_messages" :value="message">
                    {{ message.title }} - {{ message.slug }}
                  </option>
                </select>
              </div>
              <div class="mt-4">
                <wa-template-message
                  :body="wa_parsed_message"
                  :header="waTemplateMessageComponents.header"
                  :footer="waTemplateMessageComponents.footer"
                  :selected-image="selectedWaTemplateHeaderImage"
                  :force-file-validation="shouldValidateWaTemplateHeaderImageFile"
                  @handle-file-change="handleFileChange"
                  @reset="handleDeleteSelectedWaTemplateImage"
                />
                <div class="row">
                  <div v-for="(param, i) in waTemplateBodyParams" :key="i" class="col-md-3">
                    <message-input
                      :placeholder="param.key"
                      :input-val="param.value"
                      :is-error="isEmptyTemplateVariable(param.value)"
                      :show-error-msg="isEmptyTemplateVariable(param.value)"
                      input-size="sm"
                      input-class="mt-2"
                      error-item-class="mt-2 mb-1"
                      error-msg="Please fill this field"
                      @useInputValue="updateWaTemplateBodyParams($event, i)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="composer-footer mt-auto flex flex-row flex-wrap p-4">
          <div v-if="!isComposingWhatsappTemplateMessage" class="composer-footer-attachments-container w-full flex-1">
            <div
              v-if="suggestedReplyStore.isFeatureEnabled && suggestedReplyStore.isActive && !newTicket"
              class="flex items-center gap-x-2"
            >
              <SuggestReplyCTA :ticket-id="this.ticket?.id" />
            </div>
            <div
              :class="{ 'mt-1': suggestedReplyStore.isFeatureEnabled && suggestedReplyStore.isActive }"
              class="selected-attachments composer-footer-attachments"
            >
              <div
                v-for="(attachment, index) in attachments"
                :key="attachment.full_url"
                class="email-form-attachment m-b-xs"
              >
                <span class="label success">{{ attachment.extension }}</span>
                <span class="attachment-name whitespace-nowrap">{{ attachment.client_name }}</span>
                <small class="text-muted mx-2 whitespace-nowrap">{{ attachment.size }}</small>
                <span class="text-muted ml-auto whitespace-nowrap">
                  <i v-show="attachment.uploading" class="fa fa-spin fa-spinner mr-1"></i>
                  <a v-show="!attachment.uploading" :href="safeUrl(attachment.full_url)" target="_blank" rel="noopener">
                    <i class="fa fa-download"></i>
                  </a>
                  <i
                    v-show="!attachment.uploading"
                    class="fa fa-remove ml-1"
                    style="cursor: pointer"
                    @click="removeAttachment(attachment, index)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div class="ml-auto flex items-center">
            <span v-if="showDeleteAndQuickReplies" class="mr-2">
              <dropdown
                ref="dropdown_quick_replies"
                :options="quickReplies"
                label="title"
                placement="top"
                :active-state="false"
                width="350px"
                @selected="insertQuickReply"
                @open="onQuickRepliesOpen"
                @close="onQuickRepliesClosed"
              >
                <template #heading>
                  <div class="_500 pt-4 text-center text-base leading-none text-black">
                    {{ $t('tickets.insert_quick_reply') }}
                  </div>
                </template>
                <template #toggle>
                  <i
                    ref="quick_replies_icon"
                    v-tooltip="{
                      placement: 'top',
                      content: $t('tickets.insert_quick_reply'),
                      delay: { show: 500, hide: 0 },
                    }"
                    role="button"
                    class="material-icons selector-conversation-quick-reply cursor-pointer text-grey-600"
                    @click="triggerDropdown"
                  >
                    format_quote
                  </i>
                </template>
                <template #option="prop">
                  <span class="font-bold">{{ prop.option?.title }}</span>
                  <br />
                  <span>{{ stripHtml(prop.option?.message) }}</span>
                </template>
              </dropdown>
            </span>
            <span v-show="isWebChat()" class="mr-2" style="margin-top: 6px">
              <i
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.insert_gif'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons cursor-pointer text-grey-600"
                @click="toggleGifPicker($event)"
              >
                gif
              </i>
              <div v-if="showGifPicker" ref="gifContainer" v-click-away="hideGifpicker" class="gif-container">
                <gif-picker @insertGif="sendGif" @close="showGifPicker = false"></gif-picker>
              </div>
            </span>

            <span v-show="showEmojiSelector" ref="emojiSelector" class="hidden-md-down mr-2" style="margin-top: 6px">
              <i
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.insert_emoji'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons selector-conversation-gif cursor-pointer text-grey-600 hover:text-grey-600"
                role="button"
                @click="toggleEmojiPicker($event)"
              >
                insert_emoticon
              </i>
              <div
                v-if="showEmojiPicker"
                ref="emojiContainer"
                v-click-away="hideEmojipicker"
                class="absolute bottom-12"
              >
                <emoji-picker @insertEmoji="insertEmoji" @hideEmojiPicker="hideEmojipicker"></emoji-picker>
              </div>
            </span>
            <attachment-selector
              v-show="attachmentsEnabled"
              class="mr-2"
              @attachment="addAttachment"
            ></attachment-selector>
            <span v-if="showDeleteAndQuickReplies" class="flex">
              <i
                v-show="draft.exists"
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.delete_draft'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons mr-2 cursor-pointer text-grey-600"
                @click="deleteDraft(true)"
              >
                delete_forever
              </i>
              <i v-show="!draft.exists" disabled="disabled" class="material-icons mr-2 text-grey-500">delete_forever</i>
            </span>

            <TicketResultModal
              :is-open="isResultModalOpen"
              @select-result="onResultSelect"
              @close="resetSendingState"
            />

            <div
              class="composer-send-button btn-group cursor-pointer shadow"
              style="margin-left: 5px; border-radius: 9999px"
            >
              <button
                v-if="!defaultClose"
                :disabled="!isValid()"
                type="button"
                class="btn success btn-md composer-send-button-left cursor-pointer text-white"
                :class="{ loader: sending }"
                data-test="composer-close-button"
                @click="send(false)"
              >
                <i class="material-icons">send</i>
                {{ computedSendText }}
              </button>
              <button
                v-if="defaultClose"
                :disabled="!isValid()"
                type="button"
                class="btn success btn-md composer-send-button-left cursor-pointer text-white"
                :class="{ loader: sending }"
                data-test="composer-default-close-button"
                @click="send(true)"
              >
                <i class="material-icons">send</i>
                {{ $t('tickets.action_send_and_close') }}
              </button>
              <button
                type="button"
                :disabled="!isValid()"
                class="btn success btn-md composer-send-button-right dropdown-toggle-split cursor-pointer text-white"
                data-toggle="dropdown"
                data-test="composer-toggle-send-options-dropdown-button"
              >
                <i class="material-icons" style="font-size: 1.2rem; margin-left: -5px">keyboard_arrow_down</i>
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right cursor-pointer"
                style="transform-origin: bottom left; top: auto; bottom: 100%; margin-bottom: 2px; z-index: 9999"
              >
                <a class="dropdown-item" data-test="composer-send-dropdown-option" @click="send(false)">
                  {{ $t('tickets.action_send') }}
                </a>
                <a class="dropdown-item" data-test="composer-send-and-close-dropdown-option" @click="send(true)">
                  {{ $t('tickets.action_send_and_close') }}
                </a>
                <a
                  v-show="canSendLater()"
                  class="dropdown-item"
                  data-test="composer-send-later-dropdown-option"
                  @click="send(false, true)"
                >
                  {{ $t('tickets.action_send_later') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.composer__grey-border {
  @apply border-b-2 border-t-2 border-grey-200 lg:border-2 lg:border-b-2;
}

.composer-send-button-left {
  /*border-right: 1px solid #21a163;*/
  border-radius: 50px 0 0 50px !important;
  height: 34px;
  background: #14b29f;
  display: inline-flex;
  vertical-align: middle;
}

.composer-send-button-left:hover {
  background: #14b29f;
}

.composer-send-button-left i.material-icons {
  font-size: 16px;
  margin-right: 5px;
  margin-top: 1px;
}

.composer-send-button-right {
  border-left: 1px solid #21a163;
  border-radius: 0 50px 50px 0 !important;
  height: 34px;
  background: #14b29f;
}

.composer-send-button-right:hover,
.composer-send-button-right:active,
.composer-send-button-right:focus {
  background: #14b29f;
}

@media (max-width: 991px) {
  .modal .composer-container {
    border-radius: 0 !important;
  }

  .modal .composer-footer {
    background-color: white;
  }

  .modal-header {
    border-radius: 0 !important;
  }

  .modal-centered {
    border-radius: 0 !important;
    background-color: white;
  }
}

@media (min-width: 1227px) {
  .composer-footer-attachments-container {
    max-height: 100% !important;
  }
}

.composer-footer-attachments-container {
  max-height: 80%;
  margin-bottom: 4px;
  margin-top: 4px;
}

.composer-footer-attachments {
  max-height: 100%;
}

.gif-container {
  position: absolute;
  bottom: 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.34);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 9999;
  color: #9b9b9b;
  font-size: 14px;
}

@media (min-width: 1900px) {
  .ticket-from-channel {
    max-width: 360px;
  }
}

:deep(.emoji-browser) {
  width: 300px;
  height: 300px;
}
</style>

<script lang="ts">
import { debounce, isEmpty, map, throttle, toArray } from 'lodash';
import moment from 'moment';
import { mapState, mapStores } from 'pinia';
import { mixin as VueClickAway } from 'vue3-click-away';

import { ENDPOINT } from '@/api/constants';
import { sendConsolidatedMessage } from '@/api/modules/messages';
import TicketResultModal from '@/components/ReplyForm/TicketResultModal.vue';
import { CHANNEL_TYPE, PERMISSION, FEATURE_FLAG_INBOX, MESSAGE_TYPE } from '@/Configs/Constants';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import {
  TEMPLATE_HEADER_TYPE,
  TEMPLATE_PARAM_TYPE,
  WA_TEMPLATE_COMPONENT,
  WHATSAPP_BUSINESS_MESSAGE_TYPE,
} from '@/Configs/Constants/whatsapp';
import eventBus from '@/eventBus';
import { waitForEvent } from '@/start/util';
import { useFeatureFlagStore } from '@/store/pinia';
import { animateMessageUpdate } from '@/util/animateMessageUpdate';
import { retryRequestIfResponseMatchesStatusCode } from '@/util/request';

import { fetchWaTemplatesByChannel, sendWaTemplateMessage } from './api';
import Dropdown from './Dropdown.vue';
import EmptyMsg from './EmptyMsg';
import Froala from './Froala.vue';
import GifPicker from './GifPicker.vue';
import MessageInput from './MessageInput.vue';
import attachmentSelector from './MultipleAttachmentSelector.vue';
import SuggestReplyCTA from './SuggestReply/SuggestReplyCTA.vue';
import { useSuggestedReplyStore } from './SuggestReply/useSuggestedReplyStore';
import { convertHTMLToText, saveFile } from './utils';
import WaTemplateMessage from './WaTemplateMessage';
import contactRepository from '../../repositories/Contact';
import ticketRepository from '../../repositories/Ticket';
import Contenteditable from '../ContentEditable.vue';
import EmojiPicker from '../Elements/EmojiPicker.vue';

import type { ConsolidatedMessageReturnPayload } from '@/api/modules/messages';
import type { BasePayload, ConsolidatedComposerSendPayload, Recipient } from '@/components/ReplyForm/types/composer';

const DEFAULT_WHATSAPP_BUSINESS_MESSAGE_TYPE = WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;

export default {
  name: 'Composer',
  emits: ['poput', 'draftLoaded', 'focusEditor', 'sent', 'appendMessage'],
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    newTicket: {
      type: Boolean,
      default: false,
    },
    popout: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    defaultMessage: {
      type: String,
      default: '',
    },
    defaultSubject: {
      type: String,
      default: '',
    },
    defaultContact: {
      default: null,
      type: Object,
    },
    emailOnly: {
      type: Boolean,
      default: false,
    },
    forwarding: {
      type: Boolean,
      default: false,
    },
    defaultChannel: {
      type: Object,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    defaultClose: {
      type: Boolean,
      default: false,
    },
    forwardTicketId: {
      type: Number || String,
      default: null,
    },
    forwardMessageId: {
      type: Number || String,
      default: null,
    },
    draftKey: {
      type: String,
      default: null,
    },
    publicProvider: {
      type: String,
      default: null,
    },
    replyTo: {
      type: Object,
      default: null,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    contactIsOnline: {
      type: Boolean,
      default: false,
    },
    hasAccessToChannel: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    TicketResultModal,
    attachmentSelector,
    Dropdown,
    Froala,
    EmojiPicker,
    GifPicker,
    Contenteditable,
    MessageInput,
    EmptyMsg,
    WaTemplateMessage,
    SuggestReplyCTA,
  },

  mixins: [VueClickAway],

  data() {
    return {
      showBcc: false,
      showCc: false,
      channel: {},
      channelInfo: {},
      subject: '',
      attachments: [],
      showEmojiPicker: false,
      showGifPicker: false,
      contacts: [],
      prevChannel: [],
      quickReplies: [],

      contact: {},
      cc: {},
      bcc: {},

      dragenter: false,
      sending: false,
      signature: '',
      message: '',
      typingTimer: null,
      typing: false,
      draftTimer: null,

      ready: false,
      draft: {},
      dirty: false,

      pendingUploads: 0,
      externalWindowInstance: null,

      selection: null,

      wa_business_message_type: DEFAULT_WHATSAPP_BUSINESS_MESSAGE_TYPE,
      wa_template_messages: [],
      wa_template: null,
      waTemplateBodyParams: [],
      waTemplateHeaderParam: null,
      selectedWaTemplateHeaderImage: [],
      shouldValidateWaTemplateHeaderImageFile: false,
      isTemplateWithHeaderImage: false,

      contentEditableSelRange: null,
      quickReplyOpenDropdown: false,
      shouldInsertQuickReplyAtStart: true,
      isValidTemplateVariable: true,
      ticketResultId: -1,
      shouldCloseAfterSend: false,
      isResultModalOpen: false,
      // Track whether the message should be sent later or not in the new message flow
      shouldSendLater: false,

      CHANNEL_TYPE,
      PERMISSION,
      WHATSAPP_BUSINESS_MESSAGE_TYPE,
    };
  },

  computed: {
    ...mapStores(useSuggestedReplyStore),
    ...mapState(useSuggestedReplyStore, ['suggestedReply']),

    composerGreyBorderClass() {
      return !this.newTicket && !this.isInternalCommentRedesignEnabled ? 'composer__grey-border' : '';
    },

    emailChatDeliveryMode() {
      return this.channel.type === CHANNEL_TYPE.CHAT && this.chatMailMode;
    },

    isInternalCommentRedesignEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTERNAL_COMMENT_TABS);
    },

    isConsolidatedMessageEndpointEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.CONSOLIDATED_MESSAGE_ENDPOINT_ENABLED);
    },

    waMessageOutsideWindow() {
      const isNotWaBussiness = this.channelInfo.type !== CHANNEL_TYPE.WA_BUSINESS;

      if (this.newTicket || !this.channelInfo || isNotWaBussiness) {
        return false;
      }

      const now = moment().tz(window.APP_TIMEZONE);
      return (
        this.messages.filter((m) => {
          const createdAt = moment.tz(m.created_at, window.APP_TIMEZONE);
          const diffInHours = now.diff(createdAt, 'hours');
          return m.type === MESSAGE_TYPE.INBOUND && diffInHours < 24;
        }).length === 0
      );
    },

    isComposingWhatsappTemplateMessage() {
      return (
        this.channelInfo?.type === CHANNEL_TYPE.WA_BUSINESS &&
        this.wa_business_message_type === WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE
      );
    },

    supportsWhatsappTemplateMessages() {
      const isTwilio = this.channelInfo?.whatsappChannel?.provider === 'twilio';

      return this.channelInfo || this.channelInfo.type === CHANNEL_TYPE.WA_BUSINESS || isTwilio;
    },

    canPopout() {
      return (
        this.isEmail() && !this.popout && !window.is_mobile_device() && !window.isLoadedFromApp && !window.isElectron
      );
    },

    localStorageChannelKey() {
      if (this.forwarding) {
        return 'ticket_channel.forwarding';
      }

      return 'ticket_channel';
    },

    channels() {
      let supported = [];

      if (this.newTicket) {
        supported = [
          CHANNEL_TYPE.VOIP,
          CHANNEL_TYPE.SMS,
          CHANNEL_TYPE.EMAIL,
          CHANNEL_TYPE.WHATSAPP,
          CHANNEL_TYPE.TELEGRAM,
          CHANNEL_TYPE.WA_BUSINESS,
        ];
      }

      if (this.forwarding) {
        supported = [CHANNEL_TYPE.EMAIL];
      }

      return this.$root.channels.filter((channel) => {
        return supported.indexOf(channel.type) !== -1;
      });
    },

    // Determines whether a chat message should be delivered via email
    shouldDeliverViaEmailInChat() {
      if (this.newTicket || this.ticket.channel.type !== CHANNEL_TYPE.CHAT) {
        return false;
      }

      return !!this.ticket.contact.email && !this.contactIsOnline;
    },

    chatMailMode() {
      if (this.newTicket) {
        return false;
      }

      return (
        this.ticket.channel.type === CHANNEL_TYPE.CHAT &&
        this.ticket.contact.email != null &&
        this.contactIsOnline === false
      );
    },

    attachmentsEnabled() {
      if (
        this.channel.type === CHANNEL_TYPE.SMS ||
        this.channel.type === CHANNEL_TYPE.ZIPWHIP ||
        this.publicProvider === CHANNEL_TYPE.FACEBOOK ||
        this.publicProvider === CHANNEL_TYPE.INSTAGRAM
      ) {
        return false;
      }

      if (this.isComposingWhatsappTemplateMessage) {
        return false;
      }

      if (this.shouldShowEmptyFallbackMsg) {
        return false;
      }

      return true;
    },

    replyingTo() {
      if (isEmpty(this.replyTo)) {
        return {
          type: 'post',
          full_name: this.ticket.contact.full_name,
        };
      }
      return this.replyTo;
    },

    wa_parsed_message() {
      if (!this.wa_template) {
        return;
      }
      let msg = this.wa_template.message;
      for (let p of this.waTemplateBodyParams) {
        if (p.value && p.value.length) {
          msg = msg.replace(p.key, p.value);
        }
      }
      return msg;
    },

    isTypingChannelType() {
      return [CHANNEL_TYPE.CHAT, CHANNEL_TYPE.GBM].includes(this.channel.type);
    },
    hasEmptyTemplateVariable() {
      return this.isComposingWhatsappTemplateMessage && this.waTemplateBodyParams.some((item) => !item.value.trim());
    },
    showEmptyTemplateMsg() {
      return this.wa_template_messages.length === 0;
    },
    fallbackSmsChannel() {
      return this.channelInfo?.whatsappChannel?.sms_channel_id ? true : false;
    },
    isAdmin() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE);
    },
    shouldSendWaMessage() {
      const within24hrWindowConditions =
        !this.waMessageOutsideWindow && !this.isEmail() && !this.isComposingWhatsappTemplateMessage;
      const outside24hrWindowConditions =
        this.waMessageOutsideWindow &&
        this.fallbackSmsChannel &&
        !this.isEmail() &&
        !this.isComposingWhatsappTemplateMessage;
      return within24hrWindowConditions || outside24hrWindowConditions;
    },
    shouldShowEmptyFallbackMsg() {
      const isDefaultMessage = this.wa_business_message_type === DEFAULT_WHATSAPP_BUSINESS_MESSAGE_TYPE;
      return this.waMessageOutsideWindow && isDefaultMessage && !this.fallbackSmsChannel;
    },
    sessionMessageTitle() {
      return this.waMessageOutsideWindow
        ? this.$t('tickets.sms_fall_back')
        : this.$t('tickets.wa_message_type_session_title');
    },
    computedSendText() {
      const isWhatsApp = this.channel.type === CHANNEL_TYPE.WA_BUSINESS;
      const isSessionMessage = this.wa_business_message_type === WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;
      const isFallbackSms = isWhatsApp && this.waMessageOutsideWindow && isSessionMessage;

      if (isFallbackSms) {
        return this.$t('tickets.send_sms');
      } else if (this.isComposingWhatsappTemplateMessage) {
        return this.$t('tickets.send_template');
      } else {
        return this.$t('tickets.composer_send');
      }
    },
    showDeleteAndQuickReplies() {
      return !this.isComposingWhatsappTemplateMessage && !this.shouldShowEmptyFallbackMsg;
    },
    showEmojiSelector() {
      return !this.isEmail() && !this.isComposingWhatsappTemplateMessage && !this.shouldShowEmptyFallbackMsg;
    },
    waTemplateMessageComponents() {
      let component = {
        header: null,
        footer: null,
      };

      this.wa_template.components.forEach((item) => {
        if (item.type === WA_TEMPLATE_COMPONENT.header) {
          component.header = item;
        }
        if (item.type === WA_TEMPLATE_COMPONENT.footer) {
          component.footer = item.value;
        }
      });

      return component;
    },
    contentEditableStyle() {
      if (this.newTicket) {
        return { 'min-height': '250px' };
      }
      const { WA_BUSINESS, WHATSAPP } = CHANNEL_TYPE;
      if ([WA_BUSINESS, WHATSAPP].includes(this.channel.type)) {
        return { 'min-height': '106px' };
      }
      return { 'min-height': '82px' };
    },
    hasNotSelectedHeaderImage() {
      return (
        this.isComposingWhatsappTemplateMessage &&
        this.isTemplateWithHeaderImage &&
        !this.selectedWaTemplateHeaderImage.length
      );
    },
  },

  watch: {
    'channel.id': async function (newId: string) {
      await this.suggestedReplyStore?.getIsSuggestedReplyAvailable(newId);
    },

    suggestedReply(newMessage: string) {
      this.updateMessage(newMessage);
      this.suggestedReplyStore.suggestedReply = '';
    },

    showCc(v) {
      if (!v) {
        this.cc = [];
        this.processDraft(true);
      }
    },

    showBcc(v) {
      if (!v) {
        this.bcc = [];
        this.processDraft(true);
      }
    },

    wa_business_message_type(v) {
      if (v === WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE) {
        this.getAcceptedWaTemplates();
      }
    },

    async wa_template(template) {
      this.waTemplateBodyParams = [];
      const templateBodyParams = template?.message.match(/(\{\{[0-9]+)\}\}/g) || [];
      this.isTemplateWithHeaderImage = template?.components?.some(
        (item) => item.sub_type === TEMPLATE_HEADER_TYPE.IMAGE.toUpperCase()
      );
      await this.$nextTick();

      this.waTemplateBodyParams = templateBodyParams.map((key) => ({ key, value: '' }));

      if (this.isTemplateWithHeaderImage) {
        this.waTemplateHeaderParam = {
          key: 'image',
          value: null,
          type: 'header',
          hasError: false,
        };
      } else {
        this.waTemplateHeaderParam = null;
        this.selectedWaTemplateHeaderImage = [];
        this.shouldValidateWaTemplateHeaderImageFile = false;
      }
    },
    message() {
      if (this.isTypingChannelType) {
        if (this.message == '') {
          this.endTypingIndicator();
        } else {
          this.startTypingIndicator();
        }
      }
    },

    channel(newValue, oldValue) {
      if (oldValue.id != null) {
        this.loadQuickReplies(newValue.id);
        this.$tStorage.setItem(this.localStorageChannelKey, newValue.id);
      }

      let otherType = oldValue.id != null && oldValue.type != newValue.type;

      if (otherType) {
        this.attachments = [];
        this.subject = '';
        this.cc = this.bcc = [];
        this.$tStorage.removeItem(this.getDraftKey());
        this.contact = {};
      }

      this.restoreDraft();

      this.setWaTemplateDefaults();

      this.setChannelInfo();
    },

    defaultMessage() {
      this.message = this.defaultMessage;
      this.onMessageUpdate();
    },

    defaultChannel() {
      this.message = this.defaultMessage;
      this.onMessageUpdate();
    },
    waMessageOutsideWindow(v) {
      this.wa_business_message_type = v
        ? WHATSAPP_BUSINESS_MESSAGE_TYPE.TEMPLATE_MESSAGE
        : WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;
    },
  },

  unmounted() {
    eventBus.$off('reply_form.focus');
    eventBus.$off('reply_form.blur');
    eventBus.$off('updateTicketChannelInComposer');
    eventBus.$off('appendToComposer');
    document.removeEventListener('selectionchange', this.onSelectionChange);
    clearTimeout(this.typingTimer);
  },

  async mounted() {
    this.handleIe11();

    eventBus.$on('reply_form.focus', this.focusOnEditor);
    eventBus.$on('reply_form.blur', this.blurEditor);
    document.addEventListener('selectionchange', this.onSelectionChange);

    // wait for the application to login and load all initial data (channels)
    await waitForEvent('initial-data.loaded', this.$store.state.initialData.user.id);

    if (this.defaultSubject != '') {
      this.subject = this.defaultSubject;
    }

    if (this.defaultChannel != null) {
      this.channel = this.defaultChannel;
    }

    if (this.ticket) {
      let c = this.$root.channels.filter((c) => {
        return c.id == this.ticket.channel.id;
      })[0];

      this.channel = {
        id: this.ticket.channel.id,
        type: this.ticket.channel.type,
        text: this.ticket.channel.text,
      };

      //this.contact = this.getDefaultContact();

      if (this.ticket.subject != null) {
        this.subject = this.ticket.subject;
      }

      if (this.ticket.contact_cc != null && this.ticket.contact_cc != '') {
        this.cc.identifier = this.ticket.contact_cc;
        this.showCc = true;
      }
    } else {
      if (this.defaultChannel == null) {
        const localStorageChannelKey = await this.$tStorage.getItem(this.localStorageChannelKey);
        if (localStorageChannelKey) {
          let c = this.channels.filter((c) => {
            return c.id === localStorageChannelKey;
          })[0];
          this.channel = c != null ? c : this.channels[0];
        } else {
          this.channel = this.channels[0];
        }
      } else {
        // let def = (this.channels).filter(c => { return c.id == this.defaultChannel; })[0];
        // this.channel = def || this.channels[0];
      }
    }

    eventBus.$on('updateTicketChannelInComposer', this.updateTicketChannelInComposer);
    eventBus.$on('appendToComposer', this.appendToComposer);
    eventBus.$on('contact.updated', (data) => {
      for (var i = 0; i < this.contact.length; i++) {
        if (data.old.identifier === this.contact[i].identifier) {
          this.contact[i].identifier = data.new.identifier;
          this.contact[i].email = data.new.identifier;
          this.processDraft();
        }
      }
    });
    eventBus.$on('froalaKeyDown', (e) => {
      this.froalaContentChanged();
    });

    this.initAttachmentDrop();
    this.getAcceptedWaTemplates();
  },

  methods: {
    async setChannelInfo() {
      let channel = this.$root.channels?.find((c) => c.id === this.channel.id);
      if (!channel && this.channel.id) {
        await axios.get('/api/v2/channels/' + this.channel.id).then((res) => {
          channel = res.data;
        });
      }
      this.channelInfo = channel;
    },

    setWaTemplateDefaults() {
      this.wa_business_message_type = WHATSAPP_BUSINESS_MESSAGE_TYPE.SESSION_MESSAGE;
      this.wa_template_messages = [];
      this.wa_template = null;
      this.waTemplateBodyParams = [];
    },

    sumPreviousSiblingsLength(node, prev = null) {
      let currentNodeLength = 1;
      if (node.innerText) {
        currentNodeLength = node.innerText.length; // nodeType 1
      } else if (node.length) {
        currentNodeLength = node.length; // nodeType 3
      }

      if (node.previousSibling) {
        return this.sumPreviousSiblingsLength(node.previousSibling, prev === null ? 0 : currentNodeLength + prev);
      } else {
        return prev === null ? 0 : currentNodeLength + prev;
      }
    },
    sumEditorNodesUntil(index) {
      let total = 0;
      for (let i = 0; i < index && i < this.$refs.messageEditor.$refs.el.childNodes.length; i++) {
        let node = this.$refs.messageEditor.$refs.el.childNodes[i];

        let currentNodeLength = 1;
        if (node.innerText) {
          currentNodeLength = node.innerText.length; // nodeType 1
        } else if (node.length) {
          currentNodeLength = node.length; // nodeType 3
        }

        total += currentNodeLength;
      }
      return total;
    },
    selectionIsInEditor(sel) {
      return sel && sel.anchorNode && this.$refs.messageEditor.$refs.el.contains(sel.anchorNode);
    },
    selectionIsInPicker(sel) {
      return (
        sel &&
        sel.anchorNode &&
        sel.anchorNode &&
        sel.anchorNode.parentElement &&
        this.$refs.emojiSelector.contains(sel.anchorNode.parentElement)
      );
    },
    onSelectionChange(e) {
      if (!this.$refs.messageEditor) {
        return;
      }

      let sel = document.getSelection();
      switch (true) {
        case !this.selectionIsInEditor(sel) && !this.selectionIsInPicker(sel):
          this.selection = null;
          break;
        case this.selectionIsInEditor(sel):
          let selRange = sel.getRangeAt(0);
          if (sel.anchorNode.nodeType === 3) {
            // text
            let addStart = this.sumPreviousSiblingsLength(sel.anchorNode);
            let addEnd = this.sumPreviousSiblingsLength(sel.focusNode);
            this.selection = {
              start: selRange.startOffset + (addStart <= addEnd ? addStart : addEnd),
              end: selRange.endOffset + (addStart > addEnd ? addStart : addEnd),
            };
          } else if (sel.anchorNode.nodeType === 1) {
            // element
            this.selection = {
              start: this.sumEditorNodesUntil(selRange.startOffset),
              end: this.sumEditorNodesUntil(selRange.endOffset),
            };
          }
          break;
      }
    },
    openSendAsModal() {
      if (!this.hasAccessToChannel) {
        return;
      }

      eventBus.$emit('modals.send-as.open', {
        ticket: this.ticket,
      });
    },

    handleIe11() {
      if (this.ticket == null || this.ticket.channel == null || !window.isIe11()) {
        return;
      }

      this.$el.querySelector('.composer-container').style.height =
        this.ticket.channel.type == CHANNEL_TYPE.EMAIL ? '400px' : '150px';
    },

    getDefaultContact() {
      if (this.channel == null) {
        return [];
      }

      if (this.defaultContact) {
        if (this.defaultContact.identifier == null || this.defaultChannel == null) {
          return [];
        }
        return [
          {
            id: this.defaultContact.id,
            identifier: this.defaultContact.identifier,
            text: this.defaultContact.full_name,
          },
        ];
      }

      if (!this.ticket) {
        return [];
      }

      return [
        {
          id: this.ticket.contact.id,
          identifier: this.ticket.contact.identifier,
          text: this.ticket.contact.full_name,
        },
      ];
    },

    processDraft: throttle(function (instant = false, cb = null) {
      axios
        .post(
          '/api/v2/ticket_drafts?ticket_id=' +
            (this.newTicket ? '' : this.ticket.id) +
            '&channel_id=' +
            this.channel.id +
            '&key=' +
            this.draftKey,
          {
            channel_id: this.channel.id,
            message: this.message,
            subject: this.subject,
            to: toArray(
              map(this.contact, (field) => {
                return { id: field.id, identifier: field.identifier };
              })
            ),
            cc: toArray(
              map(this.cc, (field) => {
                return { id: field.id, identifier: field.identifier };
              })
            ),
            bcc: toArray(
              map(this.bcc, (field) => {
                return { id: field.id, identifier: field.identifier };
              })
            ),
          }
        )
        .then((res) => {
          this.draft = res.data;
        });
    }, 2000),

    startTypingIndicator() {
      clearTimeout(this.typingTimer);

      if (!this.typing) {
        axios.post('/api/v2/tickets/' + this.ticket.id + '/presence', {
          action: 'typing-started',
        });
        this.typing = true;
      }

      this.typingTimer = setTimeout(() => {
        this.endTypingIndicator();
      }, 5000);
    },

    endTypingIndicator() {
      axios
        .post('/api/v2/tickets/' + this.ticket.id + '/presence', {
          action: 'typing-ended',
        })
        .then(() => {
          this.typing = false;
        });
    },

    restoreDraft() {
      retryRequestIfResponseMatchesStatusCode(
        {
          method: 'GET',
          url: ENDPOINT.TICKET_DRAFTS,
          params: this.getDraftParams(),
        },
        {
          statusCodes: [STATUS_CODE.NOT_FOUND],
        }
      )
        .then((res) => {
          this.decorateDraft(res.data);
          this.$nextTick(() => {
            this.$emit('draftLoaded');
          });
        })
        .catch((e) => {});
    },

    decorateDraft(data) {
      if (this.newTicket || data.message != '') {
        this.message = data.message || '';
        this.onMessageUpdate();
      }

      this.draft = data;
      this.attachments = data.attachments;
      this.subject = data.subject || '';
      this.cc = data.cc;
      this.bcc = data.bcc;
      this.showCc = data.cc.length > 0;
      this.showBcc = data.bcc.length > 0;

      if (this.defaultContact != null) {
        data.to = [];
      }

      if (data.to.length > 0) {
        this.contact = data.to;
        if (data.to[0]?.id) {
          retryRequestIfResponseMatchesStatusCode(
            {
              method: 'GET',
              url: `${ENDPOINT.CONTACTS}/${data.to[0].id}`,
            },
            {
              statusCodes: [STATUS_CODE.NOT_FOUND],
            }
          ).then((res) => {
            this.contact[0].full_name = res.data.full_name != null ? res.data.full_name : '';
          });
        }
      } else {
        this.contact = this.getDefaultContact();
      }
    },

    getDraftKey() {},

    getDraftParams() {
      return {
        ticket_id: this.newTicket ? '' : this.ticket.id,
        channel_id: this.channel.id,
        forwarding: this.forwarding ? 1 : 0,
        forward_ticket_id: this.forwardTicketId != null ? this.forwardTicketId : '',
        forward_message_id: this.forwardMessageId != null ? this.forwardMessageId : '',
        key: this.draftKey,
      };
    },

    deleteDraft(should_confirm = false, force = true) {
      if (!force && this.message.length) {
        return;
      }

      if (should_confirm) {
        if (!confirm('Are you sure?')) {
          return;
        }
      }
      if (!this.isEmail()) {
        this.message = '';
        this.onMessageUpdate();
      }

      retryRequestIfResponseMatchesStatusCode(
        {
          method: 'DELETE',
          url: `${ENDPOINT.TICKET_DRAFTS}/0`,
          params: this.getDraftParams(),
        },
        {
          statusCodes: [STATUS_CODE.NOT_FOUND],
        }
      ).then((res) => {
        //this.decorateDraft(res.data);
        this.restoreDraft();
      });
    },

    onEnter(e) {
      if (e.shiftKey || this.newTicket == true) {
        return;
      }

      if (!this.chatMailMode) {
        this.send();
      }
    },

    onContactSearch: debounce(function (term) {
      let requiresPhone = [CHANNEL_TYPE.VOIP, CHANNEL_TYPE.SMS, CHANNEL_TYPE.WA_BUSINESS];
      contactRepository.fetchByChannel(this.channel.id, term, this.channel.type).then((contacts) => {
        this.contacts = contacts == '' ? [] : contacts;
        if (requiresPhone.indexOf(this.channel.type) !== -1) {
          this.contacts = this.contacts.filter((contact) => contact.is_phone);
        }
      });
    }, 100),

    onEditorLoaded() {},

    onPaste() {},

    focusOnEditor() {
      if (this.$root.usedShortCut) {
        this.$emit('focusEditor');
        return;
      }

      if (this.ticket && this.ticket.status !== 'ASSIGNED') {
        return;
      }

      if (!this.isEmail()) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (!this.$refs.messageEditor) {
              return;
            }

            window.placeCaretAtEnd(this.$refs.messageEditor.$el);
          }, 100);
        });
      }
    },
    blurEditor() {
      if (this.$refs.messageEditor) {
        this.$refs.messageEditor.blur();
      }
    },

    isEmail() {
      return this.channel.type === CHANNEL_TYPE.EMAIL;
    },

    isCustom() {
      return this.channel.type === CHANNEL_TYPE.CUSTOM;
    },

    isWebChat() {
      return this.channel.type === CHANNEL_TYPE.CHAT;
    },

    toggleEmojiPicker(e) {
      this.showEmojiPicker = !this.showEmojiPicker;
      if (this.showEmojiPicker) {
        this.$nextTick(() => {
          let el = this.$refs.emojiContainer;
          el.style.left = e.target.offsetLeft - 120 + 'px';
        });
      }
    },

    toggleGifPicker(e) {
      this.showGifPicker = !this.showGifPicker;
      if (this.showGifPicker) {
        this.$nextTick(() => {
          const el = this.$refs.gifContainer;
          if (window.matchMedia('(max-width:543px)').matches) {
            // Mobile
            el.style.left = '0px';
          } else {
            el.style.left = e.target.offsetLeft - 120 + 'px';
          }
        });
      }
    },

    insertEmoji(emoji) {
      this.showEmojiPicker = false;

      if (this.selection) {
        let tmpMessage = this.$refs.messageEditor.$refs.el.innerText;
        let before = tmpMessage.substring(
          0,
          this.selection.start <= this.selection.end ? this.selection.start : this.selection.end
        );
        let after = tmpMessage.substring(
          this.selection.start > this.selection.end ? this.selection.start : this.selection.end
        );

        this.$refs.messageEditor.$refs.el.innerText = before + emoji.emoji + after;
        this.message = this.$refs.messageEditor.$refs.el.innerHTML;
      } else {
        // no selection, append emoji
        this.message = this.message + '' + emoji.emoji;
      }
      this.selection = null;

      this.onMessageUpdate();
      this.focusOnEditor();
    },

    sendGif(e, gif) {
      this.showGifPicker = false;
      let gifPath = gif.images.fixed_height.url;

      if (!gifPath) {
        return;
      }

      // send message
      axios
        .post('/api/v2/tickets/' + this.ticket.id + '/messages', {
          message: '',
          body_type: 'GIF',
          gif: gifPath,
        })
        .then((res) => {
          this.onMessageSent(res.data.message, false);
        })
        .catch((e) => {
          this.onMessageUpdate();
          // throw at sentry
          throw e;
        });

      this.focusOnEditor();

      // complete onboarding step
      eventBus.$emit('ticket-gif-sent');
    },

    addAttachment(data) {
      if (!this.isEmail() && !this.isCustom() && this.attachments.length > 0) {
        return;
      }

      var formData = new FormData();

      if (data.quick_reply_attachment_id) {
        formData.append('quick_reply_attachment_id', data.quick_reply_attachment_id);
      } else {
        formData.append('file', data.obj);
      }

      data.uploading = true;
      data.client_name = data.name;
      data.id = null;
      this.attachments.push(data);
      this.pendingUploads++;

      axios
        .post('/api/v2/ticket_draft_attachments?channel_id=' + this.channel.id + '&key=' + this.draftKey, formData)
        .then((res) => {
          data.client_name = res.data.client_name;
          data.uploading = false;
          data.full_url = res.data.full_url;
          data.id = res.data.id;
          this.pendingUploads--;
        })
        .catch((res) => {
          this.attachments.splice(this.attachments.indexOf(data), 1);
          data.uploading = false;
          this.pendingUploads--;
        });
    },

    removeAttachment(attachment) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);

      axios
        .delete(
          '/api/v2/ticket_draft_attachments/' +
            attachment.id +
            '?channel_id=' +
            this.channel.id +
            '&key=' +
            this.draftKey
        )
        .then(() => {});
    },

    loadQuickReplies() {
      let withoutAttachments = !!this.publicProvider;
      ticketRepository.loadQuickReplies(this.channel.type, this.channel.id, withoutAttachments).then((res) => {
        this.quickReplies = res;
      });
    },

    initAttachmentDrop() {
      var v = this;
      var droppable = $(this.$el),
        lastenter;

      droppable.on('dragenter', (event) => {
        if (isDragSourceExternalFile(event.originalEvent.dataTransfer) === false || v.allowMedia == false) {
          return false;
        }
        this.dragenter = true;
        lastenter = event.target;
      });

      droppable.on('dragleave', (event) => {
        if (lastenter === event.target) {
          this.dragenter = false;
        }
      });

      droppable.on('dragover', function (e) {
        e.preventDefault();
      });

      droppable.on('drop', (e) => {
        this.onImageDrop(e);
      });
    },

    onImageDrop(e) {
      if (isDragSourceExternalFile(e.originalEvent.dataTransfer) === false) {
        return false;
      }

      let imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg'];

      e.preventDefault();
      e.stopPropagation();
      var files = e.originalEvent.dataTransfer.files;
      this.dragenter = false;
      for (let i = 0; i < files.length; i++) {
        // if (imageTypes.includes(files[i].type)) {
        //
        // }
        // else {
        this.addAttachment({
          name: files[i]['name'],
          obj: files[i],
          extension: getExtFromFileName(files[i]['name']),
          size: formatBytes(files[i]['size']),
        });
        // }
      }
    },

    resetSendingState() {
      this.sending = false;
      this.ticketResultId = -1;
      this.isResultModalOpen = false;
      this.shouldSendLater = false;
      this.shouldCloseAfterSend = false;
    },

    onResultSelect(resultId: number) {
      this.isResultModalOpen = false;
      this.ticketResultId = resultId;
      this.processSend(true, false);
    },

    send(send_and_close = false, later = false) {
      this.validateTemplateVariables();
      const emptyTemplateVariable = this.hasEmptyTemplateVariable;

      if (this.sending || !this.isValid() || emptyTemplateVariable || this.hasNotSelectedHeaderImage) {
        return;
      }

      if (
        this.isEmail() &&
        (this.subject == '' || this.subject == null) &&
        !confirm(this.$t('tickets.composer_warning_no_subject'))
      ) {
        return;
      }

      if (this.channel.type == CHANNEL_TYPE.FACEBOOK && this.message.length > 2000) {
        $.growl.error({
          size: 'large',
          duration: 6000,
          namespace: 'growl',
          title: '',
          location: 'tc',
          message: 'The length of this message exceeds the Facebook limit of 2000 characters.',
        });
        return;
      }

      // New messaging flow
      if (this.isConsolidatedMessageEndpointEnabled) {
        /**
         * The new flow requires that a result_id be present *before* the message gets sent, whereas
         * the old flow would first send the message, then let you choose the result, and only once
         * a result is chosen does it actually close the ticket.
         *
         * Because of the new flow, we have to keep track of the state of the request, for example
         * whether the user hit the 'send later' button or not. I don't think it's actually possible
         * to 'Send Later & Close', but in any case it simplifies the flow of logic here if we can
         * use this piece of state to determine the `send_later` param inside of the request body.
         */
        this.shouldSendLater = later;
        this.shouldCloseAfterSend = send_and_close;

        if (send_and_close && this?.$root?.ticketResults?.length > 0) {
          this.isResultModalOpen = true;
          return;
        }
      }

      this.$nextTick(() => {
        this.processSend(send_and_close, later);
      });
    },

    async processSendViaConsolidatedEndpoint() {
      try {
        const ticketId = this.newTicket ? null : this.ticket.id;

        let payload: ConsolidatedComposerSendPayload;

        const basePayload: BasePayload = {
          channel_id: this.channel.id,
          ticket_id: ticketId,
          subject: this.subject,
        };

        if (this.isComposingWhatsappTemplateMessage) {
          const whatsappTemplate = await this.prepareHsmPayload(ticketId);

          const recipient = this.contact[0]?.id ? [this.contact[0]] : [];

          payload = {
            ...basePayload,
            type: 'whatsapp_template',
            whatsapp_template: { ...whatsappTemplate, recipient: this.buildRecipientList(recipient)[0] },
          };
        } else {
          payload = {
            ...basePayload,
            type: 'generic_message',
            messaging: {
              recipients: this.buildRecipientList(this.contact),
              cc: this.buildRecipientList(this.cc),
              bcc: this.buildRecipientList(this.bcc),
              message: this.isEmail() ? this.message : window.stripHtml(convertHTMLToText(this.message)),
              is_note: false,
              send_later: this.shouldSendLater,
              close_after_send: this.shouldCloseAfterSend,
              delivery_method: this.shouldDeliverViaEmailInChat ? 'email' : null,
              ticket_result_id: this.ticketResultId > -1 ? this.ticketResultId : null,
              forwarded_message_id: this?.forwardMessageId || null,
              forwarding_ticket_id: this?.forwardTicketId || null,
              // We don't have a type for ticket attachments, so for now we have to ignore its type here
              // @ts-ignore
              attachment_ids: this?.attachments?.map((attachment) => attachment.id) ?? [],
              // Erik is still working out the kinks with this particular functionality; For now it'll remain false until that's solved
              mark_as_read: false || (this.newTicket && this.channel.type !== 'AUDIT' && this.channel.type !== 'NOTE'),
            },
          };
        }

        await sendConsolidatedMessage(payload)
          .then((response) => {
            this.handleSentMessage(response.data.message, true);

            if (this.shouldCloseAfterSend) {
              eventBus.$emit('ticket.current.close', ticketId);
            }
          })
          .finally(() => {
            this.resetSendingState();
          });
      } catch (err) {
        // TODO: Proper handling; What are the possible states here that aren't handled by axios?
        console.error(err);
        this.resetSendingState();
      }
    },

    buildRecipientList(recipients: { identifier: string; full_name?: string; [key: string]: unknown }[]): Recipient[] {
      // @ts-ignore
      return recipients.map((recipient) => {
        return {
          identifier: recipient.identifier,
          name: recipient?.full_name || recipient.identifier.substring(0, recipient.identifier.indexOf('@')),
        };
      });
    },

    async processSend(send_and_close: boolean, later: boolean) {
      try {
        this.sending = true;

        if (this.isConsolidatedMessageEndpointEnabled) {
          this.processSendViaConsolidatedEndpoint();
        } else {
          const contact_id = await this.processContact();

          const ticket_id = await this.processTicket(contact_id);

          const processMessage = await this.processMessage(ticket_id, later);

          this.onMessageSent(processMessage.data.message, true, send_and_close);

          if (send_and_close) {
            waitForEvent(
              'ticket-loaded',
              parseInt(this.$route.params.ticketId) === ticket_id,
              (t) => parseInt(t.id) === ticket_id,
              10000
            ).then((ticket) => {
              eventBus.$emit('ticket.current.close', ticket_id);
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.sending = false;
      }
    },

    processContact() {
      return new Promise((resolve, reject) => {
        if (this.contact[0]?.id && this.contact[0].is_private === this.channel.is_private) {
          resolve(this.contact[0].id);
          return;
        }

        retryRequestIfResponseMatchesStatusCode(
          {
            method: 'POST',
            url: `${ENDPOINT.CHANNELS}/${this.channel.id}/contacts`,
            data: {
              identifier: this.contact[0].identifier,
            },
          },
          {
            statusCodes: [STATUS_CODE.NOT_FOUND],
          }
        )
          .then((res) => {
            resolve(res.data.id);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    updateTicketContact(ticket_id, contact_id) {
      return retryRequestIfResponseMatchesStatusCode(
        {
          url: `${ENDPOINT.TICKETS}/${ticket_id}`,
          method: 'PUT',
          data: { contact_id },
        },
        { statusCodes: [STATUS_CODE.NOT_FOUND] }
      );
    },

    processTicket(contact_id) {
      return new Promise((resolve, reject) => {
        if (!this.newTicket) {
          if (this.isEmail()) {
            // Handle situation where the contact is updated
            if (!this.contact[0].id || this.contact[0].id !== this.ticket.contact_id) {
              if (!this.contact[0].id) {
                this.processContact().then((contact_id) => {
                  this.updateTicketContact(this.ticket.id, contact_id)
                    .then(() => {
                      resolve(this.ticket.id);
                    })
                    .catch(() => {
                      reject();
                    });
                });
              } else {
                this.updateTicketContact(this.ticket.id, contact_id)
                  .then(() => {
                    resolve(this.ticket.id);
                  })
                  .catch(() => {
                    reject();
                  });
              }
            } else {
              resolve(this.ticket.id);
            }
          } else {
            resolve(this.ticket.id);
          }
          return;
        }

        retryRequestIfResponseMatchesStatusCode(
          {
            method: 'POST',
            url: ENDPOINT.TICKETS,
            data: {
              channel_id: this.channel.id,
              contact_id,
              subject: this.subject,
            },
          },
          { statusCodes: [STATUS_CODE.NOT_FOUND] }
        )
          .then((res) => {
            resolve(res.data.id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    async prepareHsmPayload(ticket_id) {
      const hasSelectedTemplateHeaderImage =
        this.isTemplateWithHeaderImage && this.selectedWaTemplateHeaderImage.length;
      const transformedHeaderImageParam = [];

      if (hasSelectedTemplateHeaderImage) {
        await this.uploadWaTemplateHeaderImage();
      }

      const transformedBodyParams = this.waTemplateBodyParams?.map((item) => {
        return {
          key: item.key,
          value: item.value,
          type: TEMPLATE_PARAM_TYPE.BODY,
        };
      });
      if (this.isTemplateWithHeaderImage) {
        transformedHeaderImageParam.push({
          key: TEMPLATE_HEADER_TYPE.IMAGE,
          value: this.waTemplateHeaderParam.value,
          type: TEMPLATE_PARAM_TYPE.HEADER,
        });
      }

      const params = [...transformedBodyParams, ...transformedHeaderImageParam];

      let payload = {
        ticket_id,
        hsm_id: this.wa_template.id,
        params,
        source: 'trengo-app',
      };

      return payload;
    },

    async sendHsm(ticket_id: number) {
      try {
        const payload = await this.prepareHsmPayload(ticket_id);
        if (this.isTemplateWithHeaderImage && this.waTemplateHeaderParam.value) {
          return sendWaTemplateMessage(payload);
        } else if (!this.isTemplateWithHeaderImage) {
          return sendWaTemplateMessage(payload);
        }
      } catch (error) {
        console.error(error);
        this.sending = false;
      }
    },

    processMessage(ticket_id, later) {
      // Make request to HSM endpoint and abort
      if (this.isComposingWhatsappTemplateMessage) {
        return this.sendHsm(ticket_id);
      }

      let formData = new FormData();

      if (this.message) {
        let msg = this.message;

        if (this.channel.type !== CHANNEL_TYPE.EMAIL) {
          msg = window.stripHtml(convertHTMLToText(this.message));
        }

        formData.append('message', msg);
      }

      if (this.subject) {
        formData.append('subject', this.subject);
      }

      if (this.bcc) {
        formData.append('bcc', JSON.stringify(this.bcc));
      }

      if (this.cc) {
        formData.append('cc', JSON.stringify(this.cc));
      }

      if (this.forwarding) {
        if (this.forwardMessageId) {
          formData.append('forwarding_message_id', this.forwardMessageId);
        }
        formData.append('forwarding_ticket_id', this.forwardTicketId);
        formData.append('forwarding', 1);
      }

      if (later) {
        formData.append('send_later', 1);
      }

      formData.append('delivery_method', this.chatMailMode === true ? 'email' : null);

      if (this.attachments.length > 0) {
        for (let i = 0, len = this.attachments.length; i < len; i++) {
          formData.append('attachment_ids[]', this.attachments[i]['id']);
        }
      }

      let originalMessage = this.message;

      if (!this.isEmail()) {
        this.message = '';
        this.onMessageUpdate();
        this.$refs.messageEditor.$el.focus();
      }

      if (this.channel.type === CHANNEL_TYPE.CHAT) {
        this.endTypingIndicator();
      }

      if (this.replyTo !== null && this.replyTo.message_id) {
        formData.append('message_id', this.replyTo.message_id);
      }

      // Process multi-contact email
      formData.append('contacts', JSON.stringify(this.contact));

      return new Promise((resolve) => {
        retryRequestIfResponseMatchesStatusCode(
          {
            method: 'POST',
            url: `${ENDPOINT.TICKETS}/${ticket_id}/messages`,
            data: formData,
          },
          { statusCodes: [STATUS_CODE.NOT_FOUND] }
        )
          .then((res) => {
            resolve(res);
            eventBus.$emit('user-reply-conversation');
          })
          .catch((e) => {
            this.message = originalMessage;
            this.sending = false;
            this.onMessageUpdate();
          });
      });
    },

    /**
     * Decided to copy this method rather than refactor the old one to make it easier to remove the
     * old codepath in a future PR. Since the `shouldCloseAfterSend` bit now lives in the Composer
     * itself, we no longer need to pass it as an argument, plus augmenting the type of the message
     * param proved to introduce some annoying TS issues which I'd rather not deal with in this huge
     * ticket.
     */
    handleSentMessage(message: ConsolidatedMessageReturnPayload['message'], shouldDeleteDraft = true) {
      this.$emit('sent', message.ticket_id, this.shouldCloseAfterSend);

      if (!this.newTicket) {
        this.attachments = [];
      }

      if (
        !this.newTicket &&
        this.publicProvider !== CHANNEL_TYPE.FACEBOOK &&
        this.publicProvider !== CHANNEL_TYPE.INSTAGRAM
      ) {
        message.is_latest = true;

        if (message?.ticket_id === this?.ticket?.id) {
          this.$emit('appendMessage', message);
        }
      }

      if (shouldDeleteDraft) {
        this.deleteDraft(false, this.isEmail());
      }

      eventBus.$emit('ticket-message-sent');
      if (this.channel.type === CHANNEL_TYPE.EMAIL) {
        eventBus.$emit('ticket-email-sent');
      }
    },

    onMessageSent(message, deleteDraft = true, send_and_close = false) {
      this.$emit('sent', message.ticket_id, send_and_close);

      this.sending = false;

      if (!this.newTicket) {
        this.attachments = [];
      }

      if (
        !this.newTicket &&
        this.publicProvider !== CHANNEL_TYPE.FACEBOOK &&
        this.publicProvider !== CHANNEL_TYPE.INSTAGRAM
      ) {
        message.is_latest = true;

        if (parseInt(message.ticket_id) === parseInt(this.ticket.id)) {
          this.$emit('appendMessage', message);
        }
      }

      if (deleteDraft) {
        this.deleteDraft(false, this.isEmail());
      }

      eventBus.$emit('ticket-message-sent');

      if (this.channel.type === CHANNEL_TYPE.EMAIL) {
        eventBus.$emit('ticket-email-sent');
      }
    },

    onFilePaste(file) {
      this.addAttachment({
        name: file['name'],
        obj: file,
        extension: getExtFromFileName(file['name']),
        size: formatBytes(file['size']),
      });
    },

    isValid() {
      if (this.pendingUploads > 0) {
        return;
      }

      if (this.isEmail() || this.newTicket) {
        if (!this.contact[0] || (!this.contact[0].id && !this.contact[0].identifier)) {
          return false;
        }
      }

      if (this.isComposingWhatsappTemplateMessage) {
        return this.wa_template != null; // todo
      }

      if (this.shouldShowEmptyFallbackMsg) {
        return;
      }

      return this.message != '' || this.attachments.length > 0;
    },

    hideEmojipicker() {
      this.showEmojiPicker = false;
    },

    hideGifpicker() {
      this.showGifPicker = false;
    },

    canSendLater() {
      return (
        !this.forwarding &&
        this.newTicket &&
        this.channel != null &&
        this.channel.type !== CHANNEL_TYPE.VOIP &&
        !this.isComposingWhatsappTemplateMessage
      );
    },

    onMessageUpdate() {
      if (this.channel.type === CHANNEL_TYPE.EMAIL || !this.$refs.messageEditor) {
        return;
      }

      this.$refs.messageEditor.$el.innerHTML = this.message || '';
    },

    async updateMessage(message?: string) {
      this.suggestedReplyStore.isLoading = true;
      await animateMessageUpdate(this, message);
      this.suggestedReplyStore.isLoading = false;
      if (!this.$refs.messageEditor) return;
      this.$refs.messageEditor.$el.innerHTML = this.message || '';
    },

    openInExternalWindow() {
      this.$emit('popout');
      let url = this.newTicket
        ? 'https://' + window.location.hostname + '/composer'
        : 'https://' + window.location.hostname + '/composer/' + this.ticket.id;

      if (this.forwarding) {
        url += '?forward=true&ticket_id=' + this.forwardTicketId;
        if (this.forwardMessageId) {
          url += '&message_id=' + this.forwardMessageId;
        }
      }

      this.externalWindowInstance = window.PopupCenter(url, 'Composer', 800, 600, false);
      this.externalWindowInstance.isComposerWindow = true;

      this.externalWindowInstance.onbeforeunload = () => {
        this.externalWindowInstance = null;
        setTimeout(() => {
          this.restoreDraft();
        }, 200);
        return null;
      };
    },

    updateTicketChannelInComposer(channel) {
      this.channel = channel;
      this.processDraft();
    },

    appendToComposer(data) {
      if (this.message.indexOf(data.message) < 0) {
        this.message += data.message + ' ';
        this.onMessageUpdate();
        this.focusOnEditor();
      }
    },

    contentEditableContentChanged() {
      this.contentEditableReplaceQRShortcuts();
    },

    onContentEditableBlur(value) {
      this.message = value;
      this.contentEditableSelRange = this.$refs.messageEditor?.saveSelection();
    },

    contentEditableReplaceQRShortcuts() {
      const matches = Array.from(this.$refs?.messageEditor?.$el?.innerHTML?.trim()?.matchAll(this.getQrRegex()) || []);
      if (matches?.length) {
        this.$refs.messageEditor.insertTextAtCursor('<span class="caret-position"></span>', 'span');
        this.openQuickReplyDropdown(this.$refs.dropdown_quick_replies, document.querySelector('.caret-position'));
        this.message = this.$refs.messageEditor.$el.innerHTML;
      }
    },

    insertContentEditableStringAtCaret(string) {
      this.$refs.messageEditor.insertTextAtCursor(string, 'span');
      this.message = this.$refs.messageEditor.$el.innerHTML;
    },

    froalaContentChanged() {
      setTimeout(() => {
        this.triggerDropdownWithShortcode();
      }, 10);
    },

    onFroalaBlur() {
      // fix for inserting quick reply with no focus at start of the editors content instead of end
      setTimeout(() => {
        if (!this.quickReplyOpenDropdown) {
          this.shouldInsertQuickReplyAtStart = true;
        }
      }, 200);
    },

    onFroalaFocus() {
      this.shouldInsertQuickReplyAtStart = false;
    },

    triggerDropdownWithShortcode() {
      const matches = Array.from(
        this.$refs?.emailEditor?.instance?.html?.get()?.trim()?.matchAll(this.getQrRegex()) || []
      );
      if (matches?.length) {
        this.$refs.emailEditor.instance.html.insert('<span class="caret-position"></span>');
        this.openQuickReplyDropdown(this.$refs.dropdown_quick_replies, document.querySelector('.caret-position'));
      }
    },

    onQuickRepliesOpen() {
      if (this.quickReplies.length === 0) {
        this.loadQuickReplies(this.channel.id);
      }
    },

    onQuickRepliesClosed() {
      this.quickReplyOpenDropdown = false;
      setTimeout(() => {
        if (this.isEmail()) {
          if (this.$refs.emailEditor.instance.html.get()) {
            this.replaceShortcodes();
          } else {
            // fix for removing caret-position element in empty editor
            this.$refs.emailEditor.instance.html.set('');
          }
        } else if (this.$refs.messageEditor.$el.innerHTML.includes('<span class="caret-position"></span>')) {
          this.replaceShortcodes();
          this.onMessageUpdate();
          window.placeCaretAtEnd(this.$refs.messageEditor.$el);
        }
      }, 10);
    },

    replaceShortcodes(string = '') {
      if (this.isEmail()) {
        // remove open & closing <p> tag from reply message with only 1 surrounding <p> tag (to prevent caret jumping to end when setting editors html)
        if ((string.match(/<p>/g) || []).length === 1) {
          string = this.removeFroalaOpeningTag(string);
        }

        // remove /qr and reset caret position
        if (this.$refs.emailEditor.instance.html.get().includes('<span class="caret-position"></span>')) {
          this.replaceFroalaShortcodes(string);
          // fix for if editor is empty
        } else if (string) {
          this.$refs.emailEditor.instance.html.set(
            string + this.createSelectionMarkerElement() + this.$refs.emailEditor.instance.html.get()
          );
        }
        return;
      }

      // replace and update messaging editor
      if (this.$refs.messageEditor.$el.innerHTML.includes('<span class="caret-position"></span>')) {
        this.replaceContentEditableShortcodes(string);
      }
    },

    replaceFroalaShortcodes(string = '') {
      this.message = this.$refs.emailEditor.instance.html
        .get()
        .replaceAll(this.getQrRegex(), (match, group1) => group1)
        .replace('<span class="caret-position"></span>', string + this.createSelectionMarkerElement());
      setTimeout(() => {
        this.$refs.emailEditor.instance.html.set(this.removeFroalaOpeningTag(this.message));
      });
    },

    replaceContentEditableShortcodes(string) {
      this.message = this.$refs.messageEditor.$el.innerHTML
        .replace('<span class="caret-position"></span>', string)
        .replaceAll('<span></span>', string)
        .replaceAll(this.getQrRegex(), (match, group1, group2) => group1);
      this.onMessageUpdate();
      window.placeCaretAtEnd(this.$refs.messageEditor.$el);
    },

    replaceQuickReplyTags(reply) {
      return reply.message
        .replaceAll(/(\[ticket_id])/, this.ticket?.id || '')
        .replaceAll(/\[name]/, this.ticket?.contact?.full_name || this.contact[0]?.full_name || '')
        .replaceAll(/\[contact_name]/, this.ticket?.contact?.full_name || this.contact[0]?.full_name || '')
        .replaceAll(/\[agent_name]/, this.$root.user?.full_name || '')
        .replaceAll(/\[profile_name]/, this.ticket?.contact?.profile[0]?.name || '');
    },

    insertQuickReply(reply) {
      this.processQuickReplyAttachments(reply);
      let replyMessage = this.replaceQuickReplyTags(reply);

      if (this.isEmail()) {
        this.replaceShortcodes(replyMessage);
      } else {
        replyMessage = this.stripText(replyMessage);
        if (!this.$refs.messageEditor.$el.innerHTML.includes('<span class="caret-position"></span>')) {
          this.$refs.messageEditor.restoreSelection(this.contentEditableSelRange);
          // if messaging editor has focus
          if (document?.activeElement.classList.contains('message-editor')) {
            this.insertContentEditableStringAtCaret(replyMessage);
            // if messaging editor has no focus
          } else {
            this.message = this.$refs.messageEditor.$el.innerHTML = replyMessage + this.message;
          }
        } else {
          this.replaceShortcodes(replyMessage);
        }
        this.$refs.messageEditor.onChange();
      }
    },

    insertCaretPositionElement() {
      if (this.isEmail()) {
        if (!this.$refs.emailEditor.instance.html.get().includes('<span class="caret-position"></span>')) {
          if (this.shouldInsertQuickReplyAtStart) {
            this.$refs.emailEditor.instance.html.set(
              '<span class="caret-position"></span>' + this.$refs.emailEditor.instance.html.get()
            );
          } else if (
            /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
            (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !window.is_mobile_device())
          ) {
            this.$refs.emailEditor.instance.html.set(
              '<span class="caret-position"></span>' + this.$refs.emailEditor.instance.html.get()
            );
          } else {
            this.$refs.emailEditor.instance.html.insert('<span class="caret-position"></span>');
          }
        }
        this.message = this.$refs.emailEditor.instance.html.get();
      }
    },

    triggerDropdown() {
      this.insertCaretPositionElement();
      this.openQuickReplyDropdown(this.$refs.quick_replies_icon);
    },

    openQuickReplyDropdown(ref, position = null) {
      if (ref) {
        if (this.isEmail()) {
          this.$refs.emailEditor.instance.el.blur();
        }
        this.quickReplyOpenDropdown = true;
        eventBus.$emit('OPEN_DROPDOWN', ref, position);
      }
    },

    processQuickReplyAttachments(reply) {
      if (reply.attachments.length) {
        if (!this.isEmail()) {
          reply.attachments = reply.attachments.splice(0, 1);
        }

        $.each(reply.attachments, (i, attachment) => {
          this.addAttachment({
            quick_reply_attachment_id: attachment.id,
            extension: attachment.client_name.substr(attachment.client_name.lastIndexOf('.') + 1),
            size: formatBytes(attachment.file_size),
            name: attachment.client_name,
          });
        });
      }
      eventBus.$emit('ticket-quick-reply-inserted');
    },

    getQrRegex() {
      // https://regex101.com/r/JTSrTS/1
      return /(^|[\s>;])(\/qr)/g;
    },

    stripText(string) {
      string = stripHtml(string);
      string = string.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return string;
    },

    removeFroalaOpeningTag(string) {
      // remove open & closing <p> tag from string (to prevent caret jumping to end when setting editors html)
      if (
        string &&
        string.startsWith('<p>') &&
        string.endsWith('</p>') &&
        !string.startsWith('<p><br>') &&
        !string.endsWith('<br></p>')
      ) {
        string = string.slice(0, -4).substring(3);
      }
      return string;
    },

    // Restore froala caret at certain position, can be used in combination with this.emailEditor.html.set()
    createSelectionMarkerElement() {
      return '<span class="fr-marker" data-id="0" data-type="false" style="display: none; line-height: 0;">&ZeroWidthSpace;</span><span class="fr-marker" data-id="0" data-type="true" style="display: none; line-height: 0;">&ZeroWidthSpace;</span>';
    },
    validateTemplateVariables() {
      if (this.hasEmptyTemplateVariable) {
        this.isValidTemplateVariable = false;
      }
      if (this.hasNotSelectedHeaderImage) {
        this.shouldValidateWaTemplateHeaderImageFile = true;
      }
    },

    isEmptyTemplateVariable(val) {
      return !this.isValidTemplateVariable && !val.trim();
    },
    updateWaTemplateBodyParams(val, idx) {
      this.waTemplateBodyParams[idx].value = val;
    },
    async getAcceptedWaTemplates() {
      const res = await fetchWaTemplatesByChannel('ACCEPTED', this.channel.id);

      this.wa_template_messages = res.data.data;
      this.wa_template = this.wa_template_messages[0];
    },
    redirectToCreateTemplates() {
      this.$router.push('/admin/wa_templates/create');
      if (this.newTicket) {
        this.$emit('popout');
      }
    },
    redirectToWaChannelSetting() {
      let { id } = this.channelInfo;
      this.$router.push(`/admin/channels2/wa_business/${id}`);
    },
    handleFileChange(value) {
      this.selectedWaTemplateHeaderImage = value || [];
    },
    async uploadWaTemplateHeaderImage() {
      try {
        const formData = new FormData();
        formData.append('file', this.selectedWaTemplateHeaderImage[0]);
        const response = await saveFile(formData);
        this.waTemplateHeaderParam.value = response?.id;
      } catch (error) {
        console.error(error);
      }
    },
    async handleDeleteSelectedWaTemplateImage() {
      try {
        // add back the selected image
        // This enables the TFileInput component to still have the selected file
        this.selectedWaTemplateHeaderImage = [...this.selectedWaTemplateHeaderImage];

        const shouldDeleteImage = await this.$tConfirm(
          this.$t('whatsapp.are_you_sure_you_want_to_remove_the_attached_file'),
          {
            title: this.$t('whatsapp.remove_attached_file'),
            confirmText: this.$t('whatsapp.remove_file'),
          }
        );

        if (!shouldDeleteImage) {
          return;
        }

        // remove the selected image
        this.selectedWaTemplateHeaderImage = [];
      } catch (error) {
        console.error('error while deleting selected template', error);
      }
    },
    stripHtml(str) {
      if (str == null) {
        return;
      }
      return str.replace(/<\/?[^>]+(>|$)/g, '');
    },
  },
};
</script>
