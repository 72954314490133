<template>
  <div class="row-body">
    <admin-section-title :title="getName(channel)"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div v-if="showChannelList" id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <setting-sidebar :channels="channels" :channel-name="getName(channel)" @create="createNewChannel(channel)" />
        </div>

        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id" :channels="channels" @reloadChannelIndexList="loadList" />
        </div>
      </div>
    </div>
    <create-email-modal-new-view v-if="isNewEmailChannelView" v-model="modalOpen" />
    <create-email-modal v-else @update-loaded="handleLoadedUpdate"></create-email-modal>
    <create-voip-modal></create-voip-modal>
    <create-sms-modal></create-sms-modal>
    <create-wa-bsp-modal v-model="showWaBspModal"></create-wa-bsp-modal>
  </div>
</template>

<script type="text/babel">
import { CHANNEL_TYPE, FEATURE_FLAG_CHANNELS, FEATURE_FLAG_VOLUME } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import checkFeatureFlag from '@/middleware/checkFeatureFlag';

import SettingSidebar from './components/SettingSidebar';
import CreateWaBspModal from './CreateChannelModals/WhatsApp/CreateWaBspModal';
import createEmailModal from './CreateEmailModal.vue';
import createSmsModal from './CreateSmsModal.vue';
import createVoipModal from './CreateVoipModal.vue';
import CreateEmailModalNewView from '@/components/Channels/CreateEmailModalNewView.vue';
import { useFeatureFlagStore } from '@/store/pinia';

const channelNames = {
  sms: 'SMS',
  chat: 'Live chat',
  voip: 'Voice',
  telegram: 'Telegram',
  instagram: 'Instagram',
  email: 'Email',
  facebook: 'Facebook',
  zipwhip: 'Zipwhip',
  wa_business: 'WA Business',
  custom: 'Custom',
  gbm: 'Gbm',
};

export default {
  name: 'Channels',
  async beforeRouteEnter(to, from, next) {
    const channelParam = to.params.channel;
    const isGbmChannel = channelParam ? channelParam.toLowerCase() === CHANNEL_TYPE.GBM.toLowerCase() : false;

    if (isGbmChannel) {
      const isHideGbmEnabled = await checkFeatureFlag(FEATURE_FLAG_CHANNELS.TC_HIDE_GBM);

      if (isHideGbmEnabled) {
        next('/tickets');
        return;
      }
    }

    next();
  },
  data() {
    return {
      loaded: false,
      channels: [],
      channel: this.$route.params.channel,
      showWaBspModal: false,
      modalOpen: false,
    };
  },

  components: {
    CreateEmailModalNewView,
    CreateWaBspModal,
    SettingSidebar,
    createEmailModal,
    createVoipModal,
    createSmsModal,
  },
  computed: {
    showChannelList() {
      // hide channel list on create or edit
      return (
        (!['/create', '/create360dialog'].some((url) => this.$route.path.endsWith(url)) && !this.$route.params.id) ||
        this.isDesktop
      );
    },
    isDesktop() {
      return window.matchMedia('(min-width:1900px)').matches;
    },
    isNewEmailChannelView() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_VOLUME.TVE_NYLAS_DEPRECATE_CHANNEL_PROVIDERS);
    },
  },
  watch: {
    '$route.params'() {
      this.channel = this.$route.params.channel;
      this.loadList(false);
    },
  },
  mounted() {
    this.checkAuthErrorMessage();
    this.loadList(false);

    if (this.$route.query.create) {
      this.createNewChannel('email');
    }

    eventBus.$on('channels.reload', () => this.loadList(true));

    eventBus.$on('showWaBspModal', () => {
      this.showWaBusinessModal();
    });
  },

  unmounted() {
    eventBus.$off('channels.reload');
    eventBus.$off('showWaBspModal');
  },

  methods: {
    loadList(reload_root = true) {
      axios.get('/api/v2/channels', { params: { type: this.channel } }).then((result) => {
        this.channels = result.data.data;
        this.loaded = true;
        if (reload_root) {
          this.$root.loadAuthorizedChannels();
        }
      });
    },
    getName(type) {
      if (channelNames[type]) return channelNames[type];

      return '';
    },
    showEmailModal() {
      this.modalOpen = this.isNewEmailChannelView;
      if (!this.isNewEmailChannelView) {
        $('#CreateEmailModal').modal('show');
      }
    },
    showVoipModal() {
      $('#CreateVoipModal').modal();
    },
    showWaBusinessModal() {
      this.showWaBspModal = true;
    },
    showSmsModal() {
      $('#CreateSmsModal').modal();
    },
    createNewChannel(channel) {
      if (channel && channel === 'email') {
        this.showEmailModal();
      } else if (channel && channel === 'voip') {
        this.showVoipModal();
      } else if (channel && channel === 'wa_business') {
        this.showWaBusinessModal();
      } else if (channel && channel === 'sms') {
        this.showSmsModal();
      } else {
        this.$router.push('/admin/channels2/' + channel + '/create');
      }
    },
    checkAuthErrorMessage() {
      if (this.$route.query.error === 'access_denied') {
        this.flashError('You need to authorize Trengo to access your account');
      }
    },
    handleLoadedUpdate(val) {
      this.loaded = val;
    },
  },
};
</script>
