<template>
  <div>
    <div v-if="action === 'create' && voipChannelProvider === 'TWILIO'" class="box">
      <div class="box-header">
        <h2>{{ $t('voice.twilio_credentials') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <auth-credentials
          v-model="voipChannelAuthCredentialsId"
          help="Select or add your Twilio account"
          provider="TWILIO"
        ></auth-credentials>

        <div v-if="twilio_numbers_loaded" class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.phone_number') }}</label>
          <div v-if="twilio_phone_numbers.length > 0" class="col-sm-10">
            <select v-model="voipChannelMetaTwilioPhoneSid" class="form-control">
              <option v-for="record in twilio_phone_numbers" :value="record.id">{{ record.text }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.select_your_twilio_phone_number_from_the_list') }}
              <i class="fa fa-refresh ml-2 text-xs" @click="loadNumbers"></i>
            </span>
          </div>
          <div v-else class="col-sm-10">
            <div class="alert alert-grey">
              <span v-html="$t('voice.twilio_account_doesnt_have_suitable_numbers')" />
              <button type="button" class="btn btn-default btn-xs ml-4" @click="loadNumbers()">
                {{ $t('voice.try_again') }}
              </button>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-10 offset-md-2">
            <p v-html="markupSanitizer($t('voice.must_have_agent_conference_for_twilio_processing'))" />

            <label>
              <input v-model="voipChannelTerms" type="checkbox" />
              <span v-html="markupSanitizer($t('voice.yes_i_have_activated_agent_conference'))" />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="voipChannelProvider === 'VOYS'" class="box">
      <div class="box-header">
        <h2><img width="80" :src="`${$root.assetsURL}img/voys.svg`" /></h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.phone_number') }}</label>
          <div class="col-sm-10">
            <input
              v-model="record.username"
              type="text"
              :placeholder="$t('voice.for_example_3185123456789')"
              class="form-control"
            />
            <span class="form-text">{{ $t('voice.your_voys_phone_number_in_international_format') }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.sip_devices') }}</label>
          <div class="col-sm-10">
            <table class="mt-2 table bg-grey-200">
              <thead>
                <tr>
                  <th width="33%">{{ $t('voice.device_extension') }}</th>
                  <th width="33%">{{ $t('voice.user') }}</th>
                  <!--                                    <th width="50%">Click2Call</th>-->
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="device in record.voipChannel?.sip_devices">
                  <td>
                    <input
                      v-model="device.extension"
                      type="number"
                      class="form-control"
                      :placeholder="$t('voice.for_example_201')"
                    />
                  </td>
                  <td>
                    <select v-model="device.user_id" required class="form-control">
                      <option :value="null"></option>
                      <option v-for="user in $root.users" :value="user.id">{{ user.name }}</option>
                    </select>
                  </td>
                  <td align="right">
                    <div class="dropdown">
                      <a
                        id="dropdownMenuLink"
                        class=""
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="material-icons text-muted mt-2">more_horiz</i>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a
                          class="dropdown-item"
                          @click="
                            record.voipChannel?.sip_devices.splice(record.voipChannel?.sip_devices.indexOf(device), 1)
                          "
                        >
                          {{ $t('voice.delete') }}
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colspan="3"
                    class="text-info cursor-pointer"
                    @click="record.voipChannel?.sip_devices.push({ meta: {} })"
                  >
                    {{ $t('voice.add_device') }}
                  </td>
                </tr>
              </tfoot>
            </table>

            <span class="form-text" v-html="markupSanitizer($t('voice.use_this_list_to_link_users_from_voys'))" />
          </div>
        </div>
        <div v-if="action === 'edit'" class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.webhook_configuration') }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              :value="'https://app.trengo.com/voip-api/voys/callback?token=' + record.password"
              readonly="readonly"
            />
            <span class="form-text">
              {{ $t('voice.use_this_url_to_setup_your_notifications_from_voys_when_a_new_call_comes_in') }}
              <a href="https://help.trengo.com/en/articles/63912-phone-calls-via-sip-devices" target="_blank">
                {{ $t('voice.learn_how') }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="voipChannelProvider === 'CUSTOM'" class="box">
      <div class="box-header">
        <h2>
          <i class="fa-fw md-18 far fa-phone"></i>
          {{ $t('voice.custom_voip') }}
        </h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.phone_number') }}</label>
          <div class="col-sm-10">
            <input
              v-model="record.username"
              type="text"
              :placeholder="$t('voice.for_example_3185123456789')"
              class="form-control"
            />
            <span class="form-text">{{ $t('voice.your_phone_number_in_international_format') }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.sip_devices') }}</label>
          <div class="col-sm-10">
            <table class="mt-2 table bg-grey-200">
              <thead>
                <tr>
                  <th width="33%">{{ $t('voice.device_extension') }}</th>
                  <th width="33%">{{ $t('voice.user') }}</th>
                  <!--                                    <th width="50%">Click2Call</th>-->
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="device in record.voipChannel?.sip_devices">
                  <td>
                    <input
                      v-model="device.extension"
                      type="number"
                      class="form-control"
                      :placeholder="$t('voice.for_example_201')"
                    />
                  </td>
                  <td>
                    <select v-model="device.user_id" required class="form-control">
                      <option :value="null"></option>
                      <option v-for="user in $root.users" :value="user.id">{{ user.name }}</option>
                    </select>
                  </td>
                  <td align="right">
                    <div class="dropdown">
                      <a
                        id="dropdownMenuLink"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="material-icons text-muted mt-2">more_horiz</i>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a
                          class="dropdown-item"
                          @click="
                            record.voipChannel?.sip_devices.splice(record.voipChannel?.sip_devices.indexOf(device), 1)
                          "
                        >
                          {{ $t('voice.delete') }}
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colspan="3"
                    class="text-info cursor-pointer"
                    @click="record.voipChannel?.sip_devices.push({ meta: {} })"
                  >
                    {{ $t('voice.add_device') }}
                  </td>
                </tr>
              </tfoot>
            </table>

            <span
              class="form-text"
              v-html="markupSanitizer($t('voice.use_this_list_to_link_users_to_api_reference_documentation'))"
            />
          </div>
        </div>
        <div v-if="action === 'edit'" class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.webhook_configuration') }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              :value="'https://app.trengo.com/api/v2/voip/sip/callback?channel_token=' + record.password"
              readonly="readonly"
            />
            <span class="form-text">
              {{ $t('voice.use_this_url_to_setup_your_notifications_for_sip_devices_when_a_new_call_comes_in') }}
              <a href="https://help.trengo.com/en/articles/136318-bring-your-own-sip-carrier" target="_blank">
                {{ $t('voice.learn_how') }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'edit' && hasVoipSettings" class="box">
      <div class="box-header">
        <h2>{{ $t('voice.voip_settings') }}</h2>
      </div>
      <div class="box-divider m-a-0"></div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.record_calls') }}</label>
          <div class="col-sm-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="voipChannelRecord" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('voice.please_check_your_local_regulations_before_enabling_this_feature') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.allow_calls_outside_business_hours') }}</label>
          <div class="col-sm-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="voipChannelAllowCallsOutsideBusinessHours" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('voice.when_switched_off_incoming_calls_will_be_redirected_to_voicemail') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.queue_audio') }}</label>
          <div class="col-sm-10">
            <input type="file" @change="selectAudioFile($event)" />
            <span class="form-text">
              {{ $t('voice.this_audio_is_played_in_a_loop') }}
            </span>
            <br />
            <audio ref="queueAudio" controls>
              <source :src="record.voipChannel?.hold_music_path" type="audio/mpeg" />
              {{ $t('voice.your_browser_does_not_support_the_audio_element') }}
            </audio>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.queue_time_limit') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelQueueTimeLimit" class="form-control">
              <option v-for="(key, value) in queue_time_limit_options" :value="value">{{ key }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.callers_are_forwarded_to_voicemail_when_they_exceed_the_above_limit') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.max_queue_size_callers') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelQueueSizeLimit" class="form-control">
              <option v-for="(key, value) in queue_size_limit" :value="value">{{ key }}</option>
            </select>
            <span class="form-text">{{ $t('voice.callers_are_forwarded_to_voicemail_if_the_queue_is_full') }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.welcome_greeting') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelWelcomeGreetingId" class="form-control">
              <option :value="null">{{ $t('voice.default') }}</option>
              <option v-for="greeting in greetings" :value="greeting.id">{{ greeting.title }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.click') }}
              <a class="btn btn-xs btn-default" @click="openGreetingsModal">{{ $t('voice.here') }}</a>
              {{ $t('voice.to_create_custom_greetings') }}
            </span>
          </div>
        </div>
        <div class="form-group row" :class="{ 'disabled-form-setting': overflowPhoneNumber }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.no_available_agents_greeting') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelNoAnswerGreetingId" class="form-control">
              <option :value="null">{{ $t('voice.default') }}</option>
              <option v-for="greeting in greetings" :value="greeting.id">{{ greeting.title }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.the_greeting_for_when_no_users_are_available_click') }}
              <a class="btn btn-xs btn-default" @click="openGreetingsModal">{{ $t('voice.here') }}</a>
              {{ $t('voice.to_create_custom_greetings') }}
            </span>
          </div>
        </div>

        <div class="form-group row" :class="{ 'disabled-form-setting': outsideBusinessHoursPhoneNumber }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.outside_business_hours_greeting') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelVoicemailGreetingId" class="form-control">
              <option :value="null">{{ $t('voice.default') }}</option>
              <option v-for="greeting in greetings" :value="greeting.id">{{ greeting.title }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.click') }}
              <a class="btn btn-xs btn-default" @click="openGreetingsModal">{{ $t('voice.here') }}</a>
              {{ $t('voice.to_create_custom_greetings') }}
            </span>
          </div>
        </div>

        <div v-if="voipChannelProvider === 'TWILIO_TRENGO'" class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.outbound_phone_number') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelOutgoingCallerId" class="form-control">
              <option :value="null">{{ $t('voice.default') }}</option>
              <option v-for="record in verified_outbound_numbers" :value="record.id">{{ record.phone }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.click') }}
              <a class="btn btn-xs btn-default" @click="openOutboundPhoneModal">{{ $t('voice.here') }}</a>
              {{ $t('voice.to_add_custom_outbound_phone_numbers') }}
            </span>
          </div>
        </div>

        <div v-if="voipChannelProvider === 'TWILIO'" class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.outgoing_caller_id') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChanelMetaOutgoingCallerId" class="form-control">
              <option :value="record.phone">{{ $t('voice.default') }} ({{ record.phone }})</option>
              <option v-for="record in twilioCallerIds" :value="record.id">{{ record.text }}</option>
            </select>
            <span class="form-text">
              <span v-html="markupSanitizer($t('voice.use_different_caller_id_instead_of_your_own_number'))" />
              <i class="fa fa-refresh ml-2 cursor-pointer text-xs hover:text-black" @click="loadTwilioCallerIds"></i>
            </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.ivr_menu') }}</label>
          <div class="col-sm-10">
            <select v-model="voipChannelVoipIvrMenuId" class="form-control">
              <option :value="null">{{ $t('voice.none') }}</option>
              <option v-for="record in ivrMenus" :value="record.id">{{ record.name }}</option>
            </select>
            <span class="form-text">
              {{ $t('voice.the_ivr_menu_that_each_call_will_be_routed_to_click') }}
              <a class="btn btn-xs btn-default" @click="openIVRManager">{{ $t('voice.here') }}</a>
              {{ $t('voice.to_manage_ivr_menus') }}
            </span>
          </div>
        </div>

        <div v-if="voipChannelProvider === 'TWILIO_SANDBOX'" class="form-group row">
          <label class="col-sm-2 form-control-label"></label>
          <div class="col-sm-10">
            {{ $t('voice.note_ivr_menu_and_call_overflow') }}
          </div>
        </div>

        <div
          class="form-group row"
          :class="{ 'disabled-form-setting disabled': voipChannelProvider === 'TWILIO_SANDBOX' }"
        >
          <label class="col-sm-2 form-control-label">{{ $t('voice.call_overflow') }}</label>
          <div class="col-sm-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="overflow" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('voice.forward_calls_to_an_alternative_phone_number_instead_of_voicemail') }}
            </span>
          </div>
        </div>

        <div class="form-group row" :class="{ 'disabled-form-setting': !overflow }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.outside_business_hours') }}</label>
          <div class="col-sm-10">
            <tel-input v-model="outsideBusinessHoursPhoneNumber" />
            <span v-if="$authorization().hasPermission(PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE)" class="form-text">
              {{ $t('voice.the_phone_number_that_will_be_called_when_outside') }}
              <router-link :to="SETTINGS_URL.ADMIN__BUSINESS_HOURS" class="btn btn-xs btn-default">
                {{ $t('voice.here') }}
              </router-link>
              {{ $t('voice.to_manage_business_hours') }}
            </span>
          </div>
        </div>

        <div class="form-group row" :class="{ 'disabled-form-setting': !overflow }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.full_queue') }}</label>
          <div class="col-sm-10">
            <tel-input v-model="fullQueuePhoneNumber" />
            <span class="form-text">{{ $t('voice.the_phone_number_that_will_be_called_when_the_queue_is_full') }}</span>
          </div>
        </div>

        <div class="form-group row" :class="{ 'disabled-form-setting': !overflow }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.no_agents_available') }}</label>
          <div class="col-sm-10">
            <tel-input v-model="overflowPhoneNumber" />
            <span class="form-text">
              {{ $t('voice.the_phone_number_that_will_be_called_when_no_agents_are_available') }}
            </span>
          </div>
        </div>

        <div class="form-group row" :class="{ 'disabled-form-setting': overflow }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.enable_voicemail') }}</label>
          <div class="col-sm-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="voipChannelEnableVoicemail" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">
              {{ $t('voice.forward_callers_to_voicemail_when_an_inbound_call_can_not_be_answered') }}
            </span>
          </div>
        </div>
        <div class="form-group row" :class="{ 'disabled-form-setting': overflow }">
          <label class="col-sm-2 form-control-label">{{ $t('voice.log_missed_calls') }}</label>
          <div class="col-sm-10">
            <label class="ui-switch success m-t-xs m-r">
              <input v-model="voipChannelStoreMissedCalls" type="checkbox" true-value="1" />
              <i></i>
            </label>
            <span class="form-text">{{ $t('voice.create_a_ticket_when_an_inbound_call_is_missed') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="voipChannelProvider === 'TWILIO_SANDBOX' && action !== 'info'" class="box">
      <div class="box-header">
        <h2>{{ $t('voice.sandbox_settings') }}</h2>
      </div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.your_demo_number') }}</label>
          <div class="col-sm-10">
            <p class="form-control-static font-weight-bold">
              <a style="text-decoration: underline" :href="'tel:' + twilioNumber" target="_blank" rel="noopener">
                {{ formattedTwilioNumber }}
              </a>
            </p>
            <span class="text-muted">
              {{ $t('voice.use_this_number_to_test_the_voip_twilio_integration') }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.phone_numbers') }}</label>
          <div class="col-sm-10">
            <div class="flex">
              <tel-input
                v-model="sandbox_phone"
                type="text"
                style="width: 300px"
                :submit-on-enter="false"
                :placeholder="$t('voice.for_example_31612345678')"
                @blur="addNumberToSandbox"
                @enter="addNumberToSandbox"
              />
              <button
                type="button"
                class="bnt success rounded"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0; margin-left: -2px; width: 50px"
                @click="addNumberToSandbox"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <span class="form-text">
              {{ $t('voice.add_numbers_for_testing_purposes_to_receive_calls_from_twilio') }}
            </span>
            <ul v-if="record.voipSandboxNumbers?.length" class="list-group mt-2">
              <li v-for="item in record.voipSandboxNumbers" class="list-group-item mr-2" style="display: inline-block">
                {{ item.phone }}
                <i class="text-muted fa fa-remove" @click="deleteFromSandbox(item)"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action === 'edit' && record.voipChannel?.meta === null && hasVoipSettings" class="box">
      <div class="box-header b-b">
        <h2>{{ $t('voice.phone_number') }}</h2>
      </div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.phone_number') }}</label>
          <p class="form-control-static">
            {{ record.phone }}
            <a class="btn btn-xs btn-default ml-2" @click="showCallLogModal()">{{ $t('voice.view_call_log') }}</a>
          </p>
        </div>
      </div>
    </div>

    <div v-if="action === 'edit' && record.voipChannel?.meta && voipChannelProvider === 'TWILIO'" class="box">
      <div class="box-header b-b">
        <h2>{{ $t('voice.connected_twilio_account') }}</h2>
      </div>
      <div class="box-body">
        <div class="form-group row">
          <label class="col-sm-2 form-control-label">{{ $t('voice.phone_number') }}</label>
          <p class="form-control-static">
            {{ record.phone }}
            <a
              class="btn btn-xs btn-default ml-2"
              :href="'https://www.twilio.com/console/phone-numbers/' + record.voipChannel?.meta?.twilio_phone_sid"
              target="_blank"
            >
              {{ $t('voice.view_in_twilio') }}
            </a>
          </p>
        </div>
      </div>
    </div>

    <greetings-modal
      ref="greetingsManager"
      :record="record"
      :default-greetings="default_greetings"
      :greetings="greetings"
      :description-types="description_types"
      @changed="getGreetings"
    ></greetings-modal>
    <outbound-phone-numbers-modal
      ref="callerIdsManager"
      :voip-channel-meta="record.voipChannel?.meta"
      :outbound-numbers="outbound_numbers"
      @changed="getOutboundNumbers"
    ></outbound-phone-numbers-modal>
    <call-log-modal ref="callLogModal"></call-log-modal>
  </div>
</template>

<script type="text/babel">
import _ from 'lodash';

import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import markupSanitizer from '@/util/markupSanitizer';

import AuthCredentials from './AuthCredentials';
import CallLogModal from './Voip/CallLogModal';
import GreetingsModal from './Voip/Greetings';
import OutboundPhoneNumbersModal from './Voip/OutboundPhoneNumbers';

export default {
  name: 'VoipChannel',
  emits: [
    'update-outside-business-hours-phone-number',
    'update-overflow-phone-number',
    'update-full-queue-phone-number',
    'update-ready',
    'call-init-warning-method',
    'update-loading',
  ],
  components: {
    GreetingsModal,
    OutboundPhoneNumbersModal,
    CallLogModal,
    AuthCredentials,
  },

  props: {
    action: {
      type: String,
      default: 'create',
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    overflowPhoneNumberProp: {
      type: String,
      default: '',
    },
    outsideBusinessHoursPhoneNumberProp: {
      type: String,
      default: '',
    },
    fullQueuePhoneNumberProp: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      PERMISSION,
      SETTINGS_URL,
      twilioNumber: window.MIX_VOIP_SANDBOX_PHONE || '31858088215',
      queue_time_limit_options: {},
      queue_size_limit: {},
      greetings: {},
      default_greetings: {},
      phone_number: {
        country: 'NL',
        prefix: '',
        provider: 'twilio',
      },
      phone_numbers: [],
      searching: false,
      queue_audio_file: null,
      outbound_numbers: [],
      verified_outbound_numbers: [],
      ivrMenus: [],
      overflow: false,
      twilio_phone_numbers: [],
      twilio_numbers_loaded: false,
      loading_twilio_numbers: false,
      twilioCallerIds: [],
      twilioAgentConferencingEnabled: false,
      sandbox_phone: '',
      overflowPhoneNumber: this.overflowPhoneNumberProp,
      outsideBusinessHoursPhoneNumber: this.outsideBusinessHoursPhoneNumberProp,
      fullQueuePhoneNumber: this.fullQueuePhoneNumberProp,
      description_types: {},
    };
  },

  watch: {
    'phone_number.country'(v) {
      this.phone_number.prefix = '';
      this.record.voipChannel.country = v;
    },
    overflow(v) {
      if (!v) {
        this.outsideBusinessHoursPhoneNumber = '';
        this.overflowPhoneNumber = '';
        this.fullQueuePhoneNumber = '';
      }
    },
    voipChannelAuthCredentialsId(v) {
      this.twilio_numbers_loaded = false;
      this.voipChannelMetaTwilioPhoneSid = null;
      this.twilio_phone_numbers = [];
      this.$emit('update-ready', false);
      if (v !== null) {
        this.loadNumbers();
      }
    },
    '$route.query.provider'() {
      window.location.reload();
    },
    outsideBusinessHoursPhoneNumber(newValue) {
      this.$emit('update-outside-business-hours-phone-number', newValue);
    },
    overflowPhoneNumber(newValue) {
      this.$emit('update-overflow-phone-number', newValue);
    },
    fullQueuePhoneNumber(newValue) {
      this.$emit('update-full-queue-phone-number', newValue);
    },
  },

  computed: {
    formattedTwilioNumber() {
      // https://stackoverflow.com/questions/53427046/how-to-add-space-between-every-4-characters-in-javascript/53427113
      return ('+' + this.twilioNumber).match(/.{1,3}/g).join(' ');
    },
    hasVoipSettings() {
      return this.voipChannelProvider !== 'VOYS' && this.voipChannelProvider !== 'CUSTOM';
    },
    record: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    voipChannelRecord: {
      get() {
        return this.record.voipChannel?.record || false;
      },
      set(value) {
        this.record.voipChannel.record = value;
      },
    },
    voipChannelProvider: {
      get() {
        return this.record.voipChannel?.provider ?? '';
      },
      set(value) {
        this.record.voipChannel.provider = value;
      },
    },
    voipChannelAuthCredentialsId: {
      get() {
        return this.record.voipChannel?.auth_credentials_id;
      },
      set(value) {
        this.record.voipChannel.auth_credentials_id = value;
      },
    },
    voipChannelMetaTwilioPhoneSid: {
      get() {
        return this.record.voipChannel?.meta?.twilio_phone_sid;
      },
      set(value) {
        this.record.voipChannel.meta.twilio_phone_sid = value;
      },
    },
    voipChannelTerms: {
      get() {
        return this.record.voipChannel?.terms;
      },
      set(value) {
        this.record.voipChannel.terms = value;
      },
    },
    voipChannelAllowCallsOutsideBusinessHours: {
      get() {
        return this.record.voipChannel?.allow_calls_outside_business_hours;
      },
      set(value) {
        this.record.voipChannel.allow_calls_outside_business_hours = value;
      },
    },
    voipChannelQueueTimeLimit: {
      get() {
        return this.record.voipChannel?.queue_time_limit;
      },
      set(value) {
        this.record.voipChannel.queue_time_limit = value;
      },
    },
    voipChannelQueueSizeLimit: {
      get() {
        return this.record.voipChannel?.queue_size_limit;
      },
      set(value) {
        this.record.voipChannel.queue_size_limit = value;
      },
    },
    voipChannelWelcomeGreetingId: {
      get() {
        return this.record.voipChannel?.welcome_greeting_id;
      },
      set(value) {
        this.record.voipChannel.welcome_greeting_id = value;
      },
    },
    voipChannelNoAnswerGreetingId: {
      get() {
        return this.record.voipChannel?.no_answer_greeting_id;
      },
      set(value) {
        this.record.voipChannel.no_answer_greeting_id = value;
      },
    },
    voipChannelVoicemailGreetingId: {
      get() {
        return this.record.voipChannel?.voicemail_greeting_id;
      },
      set(value) {
        this.record.voipChannel.voicemail_greeting_id = value;
      },
    },
    voipChannelOutgoingCallerId: {
      get() {
        return this.record.voipChannel?.outgoing_caller_id;
      },
      set(value) {
        this.record.voipChannel.outgoing_caller_id = value;
      },
    },
    voipChanelMetaOutgoingCallerId: {
      get() {
        return this.record.voipChannel?.meta?.outgoing_caller_id;
      },
      set(value) {
        this.record.voipChannel.meta.outgoing_caller_id = value;
      },
    },
    voipChannelVoipIvrMenuId: {
      get() {
        return this.record.voipChannel?.voip_ivr_menu_id;
      },
      set(value) {
        this.record.voipChannel.voip_ivr_menu_id = value;
      },
    },
    voipChannelEnableVoicemail: {
      get() {
        return this.record.voipChannel?.enable_voicemail;
      },
      set(value) {
        this.record.voipChannel.enable_voicemail = value;
      },
    },
    voipChannelStoreMissedCalls: {
      get() {
        return this.record.voipChannel?.store_missed_calls;
      },
      set(value) {
        this.record.voipChannel.store_missed_calls = value;
      },
    },
  },
  mounted() {
    this.findPhoneNumbers(true);
    this.loadOptions();
    this.getGreetings();
    this.fetchIvrMenus();

    if (this.action === 'create') {
      this.record.voipChannel.provider = this.$route.query.provider;

      if (
        this.voipChannelProvider !== 'TWILIO' &&
        this.voipChannelProvider !== 'VOYS' &&
        this.voipChannelProvider !== 'CUSTOM'
      ) {
        router.push('/admin/channels2/voip');
      }

      if (this.voipChannelProvider === 'TWILIO') {
        this.$emit('update-ready', false);
      }
    }

    this.overflow =
      this.outsideBusinessHoursPhoneNumber != null ||
      this.overflowPhoneNumber != null ||
      this.fullQueuePhoneNumber != null;

    if (this.action === 'edit') {
      this.$emit('call-init-warning-method');
      if (this.voipChannelProvider === 'TWILIO') {
        this.loadTwilioCallerIds();
      } else {
        this.getOutboundNumbers();
      }
    }
  },

  methods: {
    markupSanitizer: markupSanitizer,

    fetchIvrMenus() {
      axios.get('/api/v2/ivr_menus').then((result) => {
        this.ivrMenus = result.data.data;
      });
    },

    showCallLogModal() {
      $(this.$refs.callLogModal.$refs.modal).modal('show');
    },

    loadOptions() {
      axios.get('/api/v2/channels/voip/options').then((result) => {
        this.queue_time_limit_options = result.data.queue_time_limit_options;
        this.queue_size_limit = result.data.queue_size_limit;
        this.description_types = result.data.description_types;
        this.greeting_types = result.data.greeting_types;

        this.voipChannelProvider === 'TWILIO_SANDBOX'
          ? delete result.data.default_greetings.WELCOME
          : delete result.data.default_greetings.WELCOME_SANDBOX;
        this.default_greetings = result.data.default_greetings;
      });
    },

    getGreetings() {
      axios.get('/api/v2/voip/greetings').then((result) => {
        this.greetings = result.data.data;
      });
    },

    getOutboundNumbers() {
      axios.get('/api/v2/voip/caller_ids').then((result) => {
        this.verified_outbound_numbers = result.data.data.filter((c) => c.status === 'VERIFIED');
        this.outbound_numbers = result.data.data;
      });
    },

    openGreetingsModal() {
      $(this.$refs.greetingsManager.$refs.modal).modal('show');

      $(this.$refs.greetingsManager.$refs.newModal).on('hidden.bs.modal', () => {
        $(this.$refs.greetingsManager.$refs.modal).modal('show');
      });
    },

    openOutboundPhoneModal() {
      $(this.$refs.callerIdsManager.$refs.modal).modal('show');

      $(this.$refs.callerIdsManager.$refs.newModal).on('hidden.bs.modal', () => {
        $(this.$refs.callerIdsManager.$refs.modal).modal('show');
      });
    },

    loadTwilioCallerIds() {
      axios.post('/api/v2/twilio/outgoing_caller_ids?channel_id=' + this.record.id).then((res) => {
        this.twilioCallerIds = res.data;
      });
    },

    selectAudioFile(event) {
      this.$emit('update-loading', true);
      let data = new FormData();
      data.append('file', event.target.files[0]);

      axios
        .post('/api/v2/voip/queue_audio', data)
        .then((res) => {
          this.$emit('update-loading', false);
          this.record.voipChannel.queue_audio_file_url = res.data.path;
          this.$refs.queueAudio.src = res.data.path;
        })
        .catch(() => {
          this.$emit('update-loading', false);
        });
    },

    findPhoneNumbers: _.debounce(function (init = false) {
      this.searching = true;
      if (this.action === 'create' && this.voipChannelProvider === 'TWILIO_TRENGO') {
        this.record.voipChannel.phone_number = null;
      }
      this.phone_numbers = [];
      axios
        .get('/api/v2/available_phone_numbers', { params: this.phone_number })
        .then((result) => {
          if (result.data.type !== 'error') {
            this.phone_numbers = result.data;
            this.record.voipChannel.phone_number = result.data[0];
          }
          this.searching = false;
        })
        .catch(() => {})
        .finally(() => {
          this.searching = false;
          if (init) {
            this.$emit('call-init-warning-method');
          }
        });
    }, 200),

    openIVRManager() {
      router.push('/admin/ivr');
    },

    loadNumbers() {
      this.twilio_numbers_loaded = false;
      this.voipChannelMetaTwilioPhoneSid = null;
      this.twilio_phone_numbers = [];
      this.$emit('update-ready', false);

      this.loading_twilio_numbers = true;

      axios
        .post('/api/v2/twilio/incoming_phone_numbers', {
          auth_credentials_id: this.voipChannelAuthCredentialsId,
        })
        .then((res) => {
          if (!this.voipChannelProvider) {
            return;
          }

          this.twilio_phone_numbers = res.data;
          this.twilio_numbers_loaded = true;
          this.loading_twilio_numbers = false;

          if (res.data.length > 0) {
            this.voipChannelMetaTwilioPhoneSid = res.data[0].id;
            this.$emit('update-ready', true);
          }
        })
        .catch((res) => {
          this.loading_twilio_numbers = false;
        });
    },

    addNumberToSandbox(e) {
      if (this.sandbox_phone.length < 10) {
        return;
      }

      this.record.voipSandboxNumbers.push({
        phone: this.sandbox_phone,
      });

      this.sandbox_phone = '';
    },
    deleteFromSandbox(item) {
      this.record.voipSandboxNumbers.splice(this.record.voipSandboxNumbers.indexOf(item), 1);
    },
  },
};
</script>
