<template>
  <div>
    <collapse
      :collapse-key="'chat-thread-list-' + type"
      class="collapse-element"
      :default-open="true"
      @collapse="collapse"
    >
      <template #collapse-title>
        <h6 class="pointer relative mb-1 flex select-none" style="font-weight: 700; height: 19px">
          <span v-if="type === 'groups'" class="text-ellipsis" style="max-width: 93%">
            {{ $t('internal_chat.group_threads_title') }}
          </span>
          <span v-else-if="type === 'users'" class="text-ellipsis" style="max-width: 93%">
            {{ $t('internal_chat.user_threads_title') }}
          </span>
          <unread-indicator
            v-if="(type === 'groups' && !showPanelGroups) || (type === 'users' && !showPanelUsers)"
            class="label-big ml-2"
            style="padding: 0.25em 0.5em"
            :count="totalUnreadMessageCount(type)"
            :mentions-count="totalUnreadMentionsCount(type)"
          />
          <button
            v-if="type === 'groups'"
            class="add-group container-icon md-20 selector-team-chat-create-group"
            @click.prevent.stop="addGroup()"
          >
            <i
              class="text-md material-icons absolute -mr-1 ml-2 rounded text-grey-500 hover:text-black"
              style="top: 0; right: 0"
            >
              add
            </i>
          </button>
        </h6>
      </template>
      <template #collapse-content>
        <ul class="list-unstyled -mt-2 mb-0 select-none p-0 pt-1">
          <template v-if="threads.length">
            <div
              v-for="(thread, key) in filteredThreads"
              style="padding: 6px 20px"
              class="selector-team-chat-thread flex cursor-pointer items-center rounded text-grey-700"
              role="button"
              :class="{
                'thread-selected bg-grey-200 text-grey-900':
                  (activeThread && activeThread === thread.identifier) ||
                  (!activeThread && currentThread && currentThread.identifier === thread.identifier),
                muted: thread.muted,
                'thread--unread':
                  (!threadIsCurrentThread(thread) && !thread.muted && thread.getUnreadMessageCount() > 0) ||
                  thread.getMarkUnread(),
              }"
              @click="goToThread(thread, key)"
            >
              <i
                v-if="thread.isGroup()"
                :class="{ 'thread-active': thread.getUnreadMessageCount() && !threadIsCurrentThread(thread) }"
                class="far fa-hashtag thread-icon-group flex flex-shrink-0 items-center justify-center"
              ></i>
              <div v-else-if="thread.isUser()" class="relative">
                <div class="flex-shrink-0 overflow-hidden rounded-lg" style="z-index: 9999; width: 25px; height: 25px">
                  <avatar
                    :color="thread.getUser().color"
                    font-size="12px"
                    :abbr="thread.getUser().abbr"
                    :image="thread.getUser().profileImage"
                    width="25"
                    class="avatar-left white"
                    style="top: 0; left: 0"
                  ></avatar>
                </div>
                <online-status
                  :status="chatStatus(thread.getUser())"
                  :avatar-as-parent="true"
                  style="width: 10px; height: 10px; bottom: -1px; right: -1px"
                ></online-status>
              </div>
              <span class="thread-title ml-2 w-auto text-ellipsis">{{ thread.name }}</span>
              <span v-if="thread.isUser() && thread.getUser().id === currentUser.id" class="ml-1 text-sm text-grey-500">
                ({{ $t('internal_chat.you_indicator') }})
              </span>
              <unread-indicator
                v-if="showUnreadBadge(thread)"
                :thread-is-user="thread.isUser()"
                :count="thread.getUnreadMessageCount()"
                :mentions-count="thread.isUser() ? thread.getUnreadMessageCount() : thread.getUnreadMentionsCount()"
              />
              <i
                v-if="thread.muted"
                class="fa ml-4 lg:ml-auto"
                :aria-label="'Muted ' + thread.name"
                @click="thread.toggleMute()"
              ></i>
              <!--                        <i v-if="type === 'users'" class="material-icons text-grey-400 md-18 ml-1">phone</i>-->
            </div>
          </template>
          <li v-else-if="!threads.length && !queryValue" class="select-none text-grey-500" style="margin-left: 20px">
            <button v-if="type === 'groups'" @click.prevent.stop="addGroup">
              {{ $t('internal_chat.group_create_group') }}
            </button>
          </li>
        </ul>
      </template>
    </collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import eventBus from '@/eventBus';

import OnlineStatus from './OnlineStatus';
import UnreadIndicator from './UnreadIndicator';
import Avatar from '../../Avatar';
import Collapse from '../../Collapse';

export default {
  name: 'ThreadList',
  props: {
    type: {
      type: String,
      required: true,
      default: '',
    },
    queryValue: {
      type: String,
      default: '',
    },
  },
  components: { UnreadIndicator, Avatar, OnlineStatus, Collapse },
  data() {
    return {
      showPanelGroups: null,
      showPanelUsers: null,
      activeThread: null,
    };
  },
  async created() {
    this.showPanelGroups = await this.$tStorage.getItem('chat-thread-list-groups');
    this.showPanelUsers = await this.$tStorage.getItem('chat-thread-list-users');
  },
  mounted() {
    if (this.showPanelGroups === null) this.showPanelGroups = true;
    if (this.showPanelUsers === null) this.showPanelUsers = true;

    eventBus.$on('chat@AFTER_THREAD_SET', (threadIdentifier) => {
      this.activeThread = threadIdentifier;
    });
  },
  beforeUnmount() {
    // todo specify callback
    eventBus.$off('chat@AFTER_THREAD_SET');
  },
  computed: {
    threads() {
      if (this.type === 'users') {
        return this.userThreads.filter((t) => {
          return (
            this.removeAccent(t.getName().toLowerCase()).includes(this.removeAccent(this.queryValue)) ||
            t.getIdentifier().includes(this.removeAccent(this.queryValue))
          );
        });
      } else if (this.type === 'groups') {
        return this.groupThreads.filter((t) => {
          return (
            this.removeAccent(t.subject.toLowerCase()).includes(this.removeAccent(this.queryValue)) ||
            t.getIdentifier().includes(this.removeAccent(this.queryValue))
          );
        });
      }
    },
    filteredThreads() {
      return this.threads.filter(
        (thread) => (thread.isUser() && thread.getUser().exists) || thread.isGroup() || this.queryValue
      );
    },
    ...mapGetters({
      userThreads: 'chat/userThreads',
      groupThreads: 'chat/groupThreads',
      currentThread: 'chat/currentThread',
      chatStatus: 'chat/chatStatus',
      totalUnreadMessageCount: 'chat/totalUnreadMessageCount',
      totalUnreadMentionsCount: 'chat/totalUnreadMentionsCount',
      currentUser: 'usersInternalChat/currentUser',
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        let hash = this.$route.hash.split('/');
        let newIdentifier = hash[0] || this.$route.params.identifier || null;
        this.messageId = parseInt(hash[1]) || parseInt(this.$route.params.messageId) || null;

        this.activeThread = newIdentifier;
      },
    },
  },
  methods: {
    removeAccent(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    showUnreadBadge(thread) {
      // show badge when not in the same thread or mark as unread is called
      return (
        ((thread.isUser() || thread.getUnreadMentionsCount()) && !this.threadIsCurrentThread(thread)) ||
        ((thread.isUser() || thread.getUnreadMentionsCount()) && thread.getMarkUnread())
      );
    },
    addGroup() {
      eventBus.$emit('chat@SHOW_MANAGE_GROUP_MODAL', null);
    },
    collapse() {
      if (this.type === 'users') {
        this.showPanelUsers = !this.showPanelUsers;
      } else if (this.type === 'groups') {
        this.showPanelGroups = !this.showPanelGroups;
      }
    },
    goToThread(thread) {
      if (this.currentThread?.identifier !== thread?.identifier || (!this.currentThread && thread?.identifier)) {
        eventBus.$emit('showThreadOverlay');
        // activate overlay before loading thread (emit delay)
        setTimeout(() => {
          this.$router.push('/chat/' + thread.identifier);
        }, 1);
      } else if (this.currentThread?.identifier === thread?.identifier && window.innerWidth <= 991) {
        eventBus.$emit('hideNav');
      }
    },
    threadIsCurrentThread(thread) {
      return thread?.identifier === this.currentThread?.identifier;
    },
  },
};
</script>

<style>
.online-status-border {
  font-size: 14px;
  margin-left: 1px;
}
</style>

<style lang="scss" scoped>
.muted:not(.is-current) {
  opacity: 0.55;
}
.thread-title {
  max-width: 200px;
}
.label {
  padding: 2px 5px;
  font-size: 10px;
}
.label-big {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.tt-search-box {
  margin-top: 1rem;
  height: 46px;
  input::placeholder {
    color: theme('colors.grey-600');
    opacity: 0.8;
  }
}

@media (max-width: 991px) {
  .thread-title {
    max-width: 100%;
  }
}

.thread-icon-group {
  color: theme('colors.grey-400');
  width: 12px;
}

.thread-selected,
.thread-active {
  .thread-icon-group {
    color: theme('colors.grey-900');
  }
}
.thread-active {
  color: theme('colors.grey-900');
}

.thread--unread {
  font-weight: 700;
  color: theme('colors.grey-900');
}
</style>
