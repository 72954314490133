<template>
  <div>
    <settings-landing
      :title="$t('whatsapp.whatsapp_business_api')"
      :icon="`${$root.assetsURL}img/settings-landing/whatsapp.svg`"
      :badge="badge"
      :list="listItems"
    >
      <template v-if="!isEntitledToWaBusiness" #entitlement-badge>
        <t-badge :text="badgeText" variant="upgrade" class="w-fit cursor-default" data-test="entitlement-badge">
          <template #icon><arrow-top-circle-linear size="1rem" /></template>
        </t-badge>
      </template>
      <t-button
        v-if="isConnectButtonVisible"
        size="lg"
        btn-style="primary"
        role="button"
        :aria-label="$t('whatsapp.connect_your_number')"
        type="submit"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="openWaBusinessModal"
      >
        {{ $t('whatsapp.connect_your_number') }}
      </t-button>
      <t-button
        v-if="isUpgradeButtonVisible"
        size="lg"
        btn-style="primary"
        role="button"
        :aria-label="upgradeToPlanText"
        type="submit"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)"
      >
        <div class="flex items-center justify-center">
          <arrow-top-circle-linear class="mr-1" size="1.25rem" />
          {{ upgradeToPlanText }}
        </div>
      </t-button>
      <t-button
        size="lg"
        btn-style="secondary"
        role="button"
        :aria-label="$t('whatsapp.test_whatsapp_business')"
        type="submit"
        label="Button"
        @click="openSandboxModal"
      >
        {{ $t('whatsapp.test_whatsapp_business') }}
      </t-button>
      <template v-if="isHintVisible" #hint>
        <p class="mt-2.5 text-sm">
          {{ $t('entitlement.admin_unlock_this_feature') }}
        </p>
      </template>
    </settings-landing>

    <whatsapp-sandbox-modal
      v-model="computedValue"
      @open="openSandboxModal"
      @close="showSandboxModal = false"
    ></whatsapp-sandbox-modal>
  </div>
</template>

<script>
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';

import SettingsLanding from '@/components/common/SettingsLanding';
import { ENTITLEMENT_STATUS, FEATURE, PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import eventBus from '@/eventBus';
import { useEntitlementsStore } from '@/store/pinia';

import WhatsappSandboxModal from './WhatsappSandboxModal';

export default {
  name: 'WhatsappSandbox',
  emits: ['open', 'nextPage'],
  props: {
    isOnboarding: {
      type: Boolean,
      default: false,
    },
  },
  components: { WhatsappSandboxModal, SettingsLanding, ArrowTopCircleLinear },
  data() {
    return {
      showSandboxModal: false,
      listItems: [
        this.$t('whatsapp.meet_your_customers_where_they_already_are'),
        this.$t('whatsapp.manage_a_single_number_with_multiple_team_members'),
        this.$t('whatsapp.use_broadcasting_to_proactively_engage_with_customers'),
      ],
      ENTITLEMENT_STATUS,
      FEATURE,
      SETTINGS_URL,
      PERMISSION,
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    hasPermissionToUpgrade() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
    entitlementStatus() {
      return this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__WHATSAPP);
    },
    isEntitledToWaBusiness() {
      return this.entitlementStatus === ENTITLEMENT_STATUS.ENTITLED;
    },
    isConnectButtonVisible() {
      return this.isEntitledToWaBusiness;
    },
    isUpgradeButtonVisible() {
      return this.hasPermissionToUpgrade && !this.isEntitledToWaBusiness;
    },
    isHintVisible() {
      return !this.isEntitledToWaBusiness && !this.hasPermissionToUpgrade;
    },
    upgradeToPlanText() {
      return this.$t('entitlement.upgrade_subscription');
    },
    badgeText() {
      return this.$t('entitlement.upgrade_plan_to_get_access');
    },
    badge() {
      return this.isEntitledToWaBusiness ? this.$t('whatsapp.you_can_be_live_in_less_than_2_minutes') : '';
    },
    computedValue: {
      get() {
        return this.showSandboxModal;
      },
      set(value) {
        this.showSandboxModal = value;
      },
    },
  },
  watch: {
    phone() {
      this.error = false;
    },
  },
  mounted() {
    this.openModalBasedOnQueryParameters(this.$route.query.open);
  },
  methods: {
    openModalBasedOnQueryParameters(query) {
      if (!query) {
        return false;
      }
      if (query === 'whatsapp_sandbox') {
        this.openSandboxModal();
        return false;
      }
      this.openWaBusinessModal();
    },
    openWaBusinessModal() {
      if (
        this.$store.state.onboardingSteps?.enabled &&
        this.$root.onboarding?.type === 'whatsapp' &&
        this.isOnboarding
      ) {
        this.$emit('nextPage');
        return;
      }

      eventBus.$emit('showWaBspModal');
    },
    openSandboxModal() {
      this.computedValue = true;
      if (
        this.$store.state.onboardingSteps?.enabled &&
        this.$root.onboarding?.type === 'whatsapp' &&
        this.isOnboarding
      ) {
        this.$emit('open');
        return;
      }
    },
  },
};
</script>
