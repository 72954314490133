<script>
import eventBus from '@/eventBus';

import Automation from './Automation';
import GreetingAction from './Components/Actions/Greeting';
import OpenHelpCenter from './Components/Actions/OpenHelpCenter';
import SetFlowbot from './Components/Actions/SetFlowbot';
import Condition from './Components/Condition';
import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
export default {
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      record: {},
      allWidgets: [],
      audienceConditionTypes: {},
      globalConditionTypes: {},
      globalConditionEnablers: {},
      actionTypes: {},
      sectionsDone: new Array(5),
      sectionsTouched: new Array(5),
      isClone: false,
      currentAction: this.$route.params.template || null,
    };
  },
  computed: {
    recordConditions() {
      return this.record.payload.conditions.filter((condition) => condition.type in this.audienceConditionTypes);
    },
  },
  components: {
    SetFlowbot,
    OpenHelpCenter,
    Condition,
    GreetingAction,
  },

  mixins: [RouterWarningOnEdit],

  created() {
    this.record = Automation.DefaultAutomation(this.$route.params.template || null);
    this.audienceConditionTypes = { ...Automation.audienceConditionTypes() };
    this.globalConditionTypes = { ...Automation.globalConditionTypes() };
    this.globalConditionEnablers = { ...Automation.globalConditionEnablers() };
    this.actionTypes = { ...Automation.actionTypes };
  },

  mounted() {
    this.loadInitialData();

    $('.collapse').on('hide.bs.collapse', (e) => {
      this.sectionsTouched.splice(parseInt(e.currentTarget.getAttribute('data-section')), 1, true);
      this.checkSectionsDone();
    });
  },

  methods: {
    changeAction(actionType) {
      this.record.payload = Object.assign({}, this.record.payload, { actions: [] });
      this.addAction(this.record, this.actionTypes[actionType]);

      this.$nextTick(() => {
        setTimeout(() => {
          const section = $('#section' + actionType);
          section.collapse({ toggle: true, parent: '#widget-automation' });
          section.on('hide.bs.collapse', (e) => {
            this.sectionsTouched.splice(parseInt(e.currentTarget.getAttribute('data-section')), 1, true);
            this.checkSectionsDone();
          });
          $('#sectionOne').collapse('hide');
          setTimeout(() => {
            $('#section' + actionType).collapse('show');
          }, 300);
        }, 300);
      });
    },
    saveAutomation() {
      this.removeUnusedConditions();

      if (this.record.id) {
        // update
        axios
          .put('/api/v2/widget_automation/' + this.record.id, this.record)
          .then((r) => {
            this.flashSuccess(this.$t('widget_automation.flash_message_saved'));

            this.loadInitialData();
          })
          .catch((r) => {
            this.loadInitialData();
          });
      } else {
        // create
        axios
          .post('/api/v2/widget_automation/', this.record)
          .then((r) => {
            this.flashSuccess(this.$t('widget_automation.flash_message_saved'));
            this.initWarning(false);

            this.$router.push('/admin/widget_automation/' + r.data.id);
            this.loadInitialData(); // this will be called in case of router error
          })
          .catch((r) => {
            this.loadInitialData();
          });
      }
    },

    /**
     * This is why globalConditions should be of a unique type, only occurring once.
     */
    firstConditionByType(conditionTypeString) {
      return this.record.payload.conditions.find((c) => c.type === conditionTypeString);
    },

    loadInitialData() {
      // have some initial values to suppress console errors
      this.initGlobalConditions();

      // edit
      if (this.$route.params.id) {
        axios
          .get('/api/v2/widget_automation/' + this.$route.params.id)
          .then((r) => {
            this.record = r.data;
            setTimeout(() => {
              this.initWarning();
            }, 250);
            this.recordLoaded();
          })
          .catch((r) => {
            this.recordLoaded();
          });
      } else {
        // create
        setTimeout(() => {
          this.initWarning();
        }, 250);
        this.recordLoaded();
      }

      // load available widgets
      axios.get('/api/v2/web_widgets/').then((r) => {
        this.allWidgets = r.data.data.map((w) => {
          return { id: w.id, text: w.title };
        });
      });
    },

    recordLoaded() {
      this.initGlobalConditions();

      // assume all sections touched when editing existing automation
      if (this.record.id) {
        this.sectionsTouched.fill(true);
        this.checkSectionsDone();
      }

      // quick-fix
      setTimeout(() => eventBus.$emit('automations.loaded'), 250);
    },

    goBack() {
      this.$router.push('/admin/widget_automation');
    },

    cloneAutomation() {
      this.removeUnusedConditions();

      let clone = { ...this.record };
      clone.title = clone.title + ' copy';
      clone.id = null;
      clone.created_at = null;
      clone.updated_at = null;
      clone.metrics = null;

      this.initWarning(false);

      this.$router.push({ path: '/admin/widget_automation/clone/' + encodeURIComponent(JSON.stringify(clone)) });
    },

    async deleteAutomation() {
      const shouldDeleteAutomation = await this.$tConfirm(this.$t('widget_automation.delete_confirmation'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteAutomation) {
        if (this.record.id) {
          axios.delete('/api/v2/widget_automation/' + this.record.id).then(() => {
            this.initWarning(false);
            this.$router.push('/admin/widget_automation');
          });
        } else {
          this.initWarning(false);
          this.$router.push('/admin/widget_automation');
        }
      }
    },

    /**
     * Should only be removed before save. Record should have every globalCondition until save.
     */
    removeUnusedConditions() {
      const filteredConditions = this.record.payload.conditions.filter((condition, conditionI) => {
        let enablerKey = Object.keys(this.globalConditionEnablers).find((enablerKey) => {
          let enabler = this.globalConditionEnablers[enablerKey];
          if (enabler.conditionType === 'audience_conditions') {
            return condition.type in this.audienceConditionTypes;
          } else {
            return enabler.conditionType === condition.type;
          }
        });
        let enabler = this.globalConditionEnablers[enablerKey];

        return enabler.value !== null;
      });
      this.record.payload = Object.assign({}, this.record.payload, { conditions: filteredConditions });
    },

    checkSectionsDone() {
      // validate form-input
      //document.querySelectorAll('.edit-automation .form-control').forEach(el => console.log(el, el.checkValidity()));

      // actions
      /*this.$set(this.sectionsDone, 1, this.sectionsTouched[1] && (
                    this.record.payload.actions.length
                ));*/

      // greeting
      const greeting = this.firstActionByType(this.record, this.actionTypes.greeting);
      if (greeting) {
        this.sectionsDone.splice(
          0,
          1,
          this.sectionsTouched[0] &&
            !!greeting.payload.message.length &&
            greeting.payload.message.every((v) => !!v.message) &&
            !!greeting.payload.from.name
        );
      }

      // set flowbot
      const flow = this.firstActionByType(this.record, this.actionTypes.setFlowbot);
      if (flow) {
        this.sectionsDone.splice(1, 1, this.sectionsTouched[1] && !!flow.payload && !!flow.payload.flowbotId);
      }

      // set openHelpCenter
      const helpCenter = this.firstActionByType(this.record, this.actionTypes.openHelpCenter);
      if (helpCenter) {
        this.sectionsDone.splice(
          5,
          1,
          this.sectionsTouched[5] &&
            !!helpCenter.payload &&
            !!this.record.web_widget_id &&
            !!helpCenter.payload.articleId
        );
      }

      this.sectionsDone.splice(
        2,
        1,
        this.sectionsTouched[2] &&
          (this.getAudienceConditions(this.record).every(
            (condition) => condition.value !== undefined && condition.value !== null && condition.value !== ''
          ) || // every audience condition has a value
            !this.globalConditionEnablers.audienceConditions.value) // or 'everyone' selected
      );

      this.sectionsDone.splice(
        3,
        1,
        this.sectionsTouched[3] &&
          (!!this.firstConditionByType(this.globalConditionEnablers.pageSelection.conditionType).subconditions.every(
            (condition) => condition.value !== undefined && condition.value !== null && condition.value !== ''
          ) || // every subcondition has a value (can be False)
            !this.globalConditionEnablers.pageSelection.value) // or 'all pages' selected
      );

      this.sectionsDone.splice(
        4,
        1,
        this.sectionsTouched[4] &&
          this.record.active &&
          !!this.record.web_widget_id &&
          (!this.globalConditionEnablers.startDate.value || // 'now' selected
            !!this.firstConditionByType(this.globalConditionEnablers.startDate.conditionType).value) && // or has a value for its start_date condition
          (!this.globalConditionEnablers.endDate.value || // 'never' selected
            !!this.firstConditionByType(this.globalConditionEnablers.endDate.conditionType).value) // or has a value for its end_date condition
      );
    },

    getActionNames(automation) {
      return automation.payload.actions.map((a) => a.type);
    },

    initGlobalConditions() {
      for (let enablerKey in this.globalConditionEnablers) {
        let enabler = this.globalConditionEnablers[enablerKey];

        let inBlacklist =
          enabler.templateBlacklist &&
          enabler.templateBlacklist.filter((b) => this.getActionNames(this.record).includes(b)).length > 0;
        // blacklist
        if (inBlacklist) {
          continue;
        }

        // audienceConditions does not have one condition, only enabler. All other, non-global conditions are audienceConditions.
        if (enablerKey === 'audienceConditions') {
          enabler.value = this.getAudienceConditions(this.record).length ? enabler.conditionType : null;
        } else {
          // find if this globalcondition exists in record, otherwise create it
          let condition = this.firstConditionByType(enabler.conditionType);
          if (!condition) {
            condition = Automation.Condition(
              Automation.globalConditionTypes()[enabler.conditionType],
              this.action === 'create' && !this.isClone
            );
            this.record.payload.conditions.push(condition);
          }
          enabler.value =
            condition.value || (condition.subconditions && condition.subconditions.length) ? condition.type : null;
        }
      }
    },

    addAction(automation, action) {
      automation.payload.actions.push(Automation.Action(action));
    },

    removeAction(automation, action) {
      let index = automation.payload.actions.findIndex((item) => item === action);
      if (index > -1) {
        automation.payload.actions.splice(index, 1);
      }
    },

    firstActionByType(automation, type) {
      return automation.payload.actions.find((item) => item.type === type.type);
    },

    addAudienceCondition(automation) {
      automation.payload.conditions.push(Automation.Condition(Automation.audienceConditionTypes().last_seen)); // default type last seen
    },

    removeAudienceCondition(automation, condI) {
      automation.payload.conditions.splice(condI, 1);
    },

    addSubcondition(subconditions, type) {
      subconditions.push(Automation.Condition(type));
    },

    getAudienceConditions(automation) {
      return automation.payload.conditions.filter((item) => {
        return item.type in Automation.audienceConditionTypes();
      });
    },
  },

  watch: {
    '$route.params.clone'() {
      // clone
      if (this.action === 'create' && this.$route.params.clone) {
        this.isClone = true;
        this.record = JSON.parse(decodeURIComponent(this.$route.params.clone));
        if (!this.record) {
          this.flashError(this.$t('widget_automation.flash_message_something_went_wrong'));
          this.initWarning(false);
          this.$router.go(-1);
        } else {
          this.recordLoaded();
          this.initWarning();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 10px;
}

.form-control:invalid {
  border-color: red;
}

.card-automation {
  border-radius: 15px;
  //overflow: hidden;

  .card-header {
    cursor: pointer;
    transition: background-color 300ms ease, color 300ms ease, border-radius 0ms linear 0ms;
    background-color: #14b29f;
    color: #fff;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    .step {
      vertical-align: top;
      background-color: #fff;
      color: #14b29f;
      border-radius: 500px;
      padding: 0;
      line-height: 22px;
      width: 22px;
      height: 22px;
      text-align: center;
      margin: 0 0.5rem 0 0;
      font-size: 0.75rem;
      display: inline-block;
    }
    .material-icons.rotate-arrow {
      transition: transform 300ms ease;
      transform-origin: 50% 50%;
      transform: rotateZ(-90deg);
    }
    &.collapsed {
      transition: background-color 300ms ease, color 300ms ease, border-radius 150ms linear 150ms;
      border-radius: 10px;
      background-color: #fff;
      color: #808080;
      &.done {
        color: #000;
      }

      .step {
        background-color: #cacaca;
        color: #fff;

        &.done {
          background-color: #14b29f;
        }

        &.warning {
          background-color: #f6993f;
        }
      }

      .material-icons.rotate-arrow {
        transform: rotateZ(0);
      }
    }
  }

  .panel-body {
    border: 1px solid #14b29f;
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

.custom-border-radius {
  border-radius: 10px;
}

.invisible-input {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
}
</style>

<template>
  <div class="row-col">
    <div class="white bg b-b">
      <div class="navbar no-radius box-shadow-z1">
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <span class="navbar-item text-md">Widget automation</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="flex w-full">
            <div
              class="edit-automation w-full"
              style="
                display: flex;
                justify-content: center;
                align-items: flex-start;
                max-width: 100%;
                min-height: 100%;
                overflow-x: auto;
              "
            >
              <div class="w-100 mx-32 mt-4">
                <div class="flex flex-col-reverse md:flex-row">
                  <!--<label for="automationTitle" class="mx-1 my-0" style="align-self: center; color: #bbb;"><i class="material-icons" style="vertical-align: middle;">edit</i></label>-->
                  <input id="automationTitle" v-model="record.title" type="text" class="invisible-input h3 flex-grow" />
                  <div class="ml-auto">
                    <button class="btn btn-outline-secondary b-black text-muted mb-4 ml-2 ml-2" @click="goBack()">
                      <i class="material-icons md-18 mr-2" style="vertical-align: -3px">arrow_back</i>
                      {{ $t('widget_automation.action_button_back') }}
                    </button>
                    <button class="btn btn-outline-secondary b-black text-muted mb-4 ml-2" @click="cloneAutomation()">
                      <i class="material-icons md-18 mr-2" style="vertical-align: -3px">add_circle</i>
                      {{ $t('widget_automation.action_button_copy') }}
                    </button>
                    <button
                      class="btn btn-outline-secondary b-black text-muted mb-4 ml-2"
                      @click="deleteAutomation(record)"
                    >
                      <i class="material-icons md-18 mr-2" style="vertical-align: -3px">delete</i>
                      {{ $t('widget_automation.action_button_delete') }}
                    </button>
                    <button class="btn success mb-4 ml-2" @click="saveAutomation()">
                      <i class="material-icons md-18 mr-2" style="vertical-align: -3px">save</i>
                      {{ $t('widget_automation.action_button_save') }}
                    </button>
                  </div>
                </div>

                <transition-group id="widget-automation" name="slide-fade" tag="div" class="mt-3 md:-mt-0">
                  <!-- greeting -->
                  <div
                    v-show="firstActionByType(record, actionTypes.greeting)"
                    key="0"
                    class="panel card card-automation custom-border-radius border-0"
                  >
                    <div
                      id="headinggreeting"
                      class="card-header flex"
                      role="tab"
                      data-parent="#widget-automation"
                      aria-expanded="true"
                      aria-controls="sectiongreeting"
                      data-toggle="collapse"
                      data-target="#sectiongreeting"
                    >
                      <h5 class="mb-0">
                        <span v-if="sectionsDone[0] && sectionsTouched[0]" class="step done">
                          <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
                        </span>
                        <span v-else-if="!sectionsDone[0] && sectionsTouched[0]" class="step warning">
                          <i class="material-icons md-12" style="padding-top: 0.3rem">comment</i>
                        </span>
                        <span v-else class="step">
                          <i class="material-icons md-12" style="padding-top: 0.3rem">comment</i>
                        </span>
                        {{ $t('widget_automation.page_greeting_greeting') }}
                      </h5>
                      <span class="ml-auto">
                        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
                      </span>
                    </div>

                    <div
                      id="sectiongreeting"
                      role="tabpanel"
                      aria-labelledby="headingAction"
                      class="in collapse"
                      data-section="0"
                    >
                      <div class="panel-body">
                        <greeting-action
                          v-if="firstActionByType(record, actionTypes.greeting)"
                          :key="action.type"
                          style="margin: 0; border-bottom: 1px solid #dee0e2"
                          :action="firstActionByType(record, actionTypes.greeting)"
                        ></greeting-action>

                        <div class="row">
                          <div class="col-xs-12 mx-4 mt-4 p-4">
                            <button
                              type="button"
                              class="btn btn-outline btn-outline-success"
                              data-parent="#widget-automation"
                              aria-expanded="false"
                              aria-controls="sectionTwo"
                              data-toggle="collapse"
                              data-target="#sectionTwo"
                              @click.prevent="checkSectionsDone()"
                            >
                              {{ $t('widget_automation.page_greeting_ready_choose_audience') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- set flowbot -->
                  <div
                    v-show="firstActionByType(record, actionTypes.setFlowbot)"
                    key="1"
                    class="panel card card-automation custom-border-radius border-0"
                  >
                    <div
                      id="headingsetFlowbot"
                      class="card-header flex"
                      role="tab"
                      data-parent="#widget-automation"
                      aria-expanded="true"
                      aria-controls="sectionsetFlowbot"
                      data-toggle="collapse"
                      data-target="#sectionsetFlowbot"
                    >
                      <h5 class="mb-0">
                        <span v-if="sectionsDone[1] && sectionsTouched[1]" class="step done">
                          <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
                        </span>
                        <span v-else-if="!sectionsDone[1] && sectionsTouched[1]" class="step warning">
                          <i class="far fa-fw fa-sitemap md-18" style="padding-top: 0.15rem"></i>
                        </span>
                        <span v-else class="step">
                          <i class="far fa-fw fa-sitemap md-18" style="padding-top: 0.15rem"></i>
                        </span>
                        Flowbot
                      </h5>
                      <span class="ml-auto">
                        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
                      </span>
                    </div>

                    <div
                      id="sectionsetFlowbot"
                      role="tabpanel"
                      aria-labelledby="headingFlowbot"
                      class="in collapse"
                      data-section="1"
                    >
                      <div class="panel-body p-4">
                        <set-flowbot
                          v-if="firstActionByType(record, actionTypes.setFlowbot)"
                          :key="action.type"
                          style="border-bottom: 1px solid #dee0e2"
                          :action="firstActionByType(record, actionTypes.setFlowbot)"
                          :action-type="actionTypes.setFlowbot"
                        ></set-flowbot>

                        <div class="row">
                          <div class="col-xs-12 mx-4 mt-4 p-4">
                            <button
                              type="button"
                              class="btn btn-outline btn-outline-success"
                              data-parent="#widget-automation"
                              aria-expanded="false"
                              aria-controls="sectionTwo"
                              data-toggle="collapse"
                              data-target="#sectionTwo"
                              @click.prevent="checkSectionsDone()"
                            >
                              {{ $t('widget_automation.page_flowbot_ready_choose_audience') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- openHelpCenter -->
                  <div
                    v-show="firstActionByType(record, actionTypes.openHelpCenter)"
                    key="5"
                    class="panel card card-automation custom-border-radius border-0"
                  >
                    <div
                      id="headingOpenHelpCenter"
                      class="card-header flex"
                      role="tab"
                      data-parent="#widget-automation"
                      aria-expanded="true"
                      aria-controls="sectionsOpenHelpCenter"
                      data-toggle="collapse"
                      data-target="#sectionsOpenHelpCenter"
                    >
                      <h5 class="mb-0">
                        <span v-if="sectionsDone[5] && sectionsTouched[5]" class="step done">
                          <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
                        </span>
                        <span v-else-if="!sectionsDone[5] && sectionsTouched[5]" class="step warning">
                          <i class="far fa-fw fa-question md-18" style="padding-top: 0.15rem"></i>
                        </span>
                        <span v-else class="step">
                          <i class="far fa-fw fa-question md-18" style="padding-top: 0.15rem"></i>
                        </span>
                        {{ $t('widget_automation.page_helpcenter_help_center') }}
                      </h5>
                      <span class="ml-auto">
                        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
                      </span>
                    </div>

                    <div
                      id="sectionsOpenHelpCenter"
                      role="tabpanel"
                      aria-labelledby="headingFlowbot"
                      class="in collapse"
                      data-section="5"
                    >
                      <div class="panel-body p-4">
                        <open-help-center
                          v-if="firstActionByType(record, actionTypes.openHelpCenter)"
                          :key="action.type"
                          :record="record"
                          style="border-bottom: 1px solid #dee0e2"
                          :action="firstActionByType(record, actionTypes.openHelpCenter)"
                          :action-type="actionTypes.openHelpCenter"
                        ></open-help-center>

                        <div class="row">
                          <div class="col-xs-12 mx-4 mt-4 p-4">
                            <button
                              type="button"
                              class="btn btn-outline btn-outline-success"
                              data-parent="#widget-automation"
                              aria-expanded="false"
                              aria-controls="sectionTwo"
                              data-toggle="collapse"
                              data-target="#sectionTwo"
                              @click.prevent="checkSectionsDone()"
                            >
                              {{ $t('widget_automation.page_helpcenter_ready_choose_audience') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- audience conditions -->
                  <div key="2" class="panel card card-automation custom-border-radius border-0">
                    <div
                      id="headingTwo"
                      class="card-header collapsed flex"
                      role="tab"
                      data-parent="#widget-automation"
                      aria-expanded="false"
                      aria-controls="sectionTwo"
                      data-toggle="collapse"
                      data-target="#sectionTwo"
                    >
                      <h5 class="mb-0">
                        <span v-if="sectionsDone[2] && sectionsTouched[2]" class="step done">
                          <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
                        </span>
                        <span v-else-if="!sectionsDone[2] && sectionsTouched[2]" class="step warning">2</span>
                        <span v-else class="step">2</span>
                        {{ $t('widget_automation.page_greeting_choose_audience') }}
                      </h5>
                      <span class="ml-auto">
                        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
                      </span>
                    </div>

                    <div id="sectionTwo" role="tabpanel" aria-labelledby="headingTwo" class="collapse" data-section="2">
                      <div class="panel-body p-4">
                        <div>
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">perm_identity</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ globalConditionEnablers.audienceConditions.niceName }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 md:mb-0">
                              <select v-model="globalConditionEnablers.audienceConditions.value" class="form-control">
                                <option
                                  v-for="option in globalConditionEnablers.audienceConditions.options"
                                  :key="option.text"
                                  :value="option.value"
                                >
                                  {{ option.text }}
                                </option>
                              </select>
                            </div>
                            <div
                              v-if="globalConditionEnablers.audienceConditions.value !== null"
                              class="col-md-2 mb-1 md:mb-0"
                            >
                              <button
                                class="btn btn-outline btn-outline-success"
                                @click.prevent="addAudienceCondition(record)"
                              >
                                {{ $t('widget_automation.page_button_add_condition') }}
                              </button>
                            </div>
                          </div>

                          <div v-if="globalConditionEnablers.audienceConditions.value !== null">
                            <condition
                              v-for="(condition, condI) in recordConditions"
                              :key="condI"
                              class="mb-3"
                              :has-buttons="true"
                              :condition="condition"
                              :allowed-types="audienceConditionTypes"
                              icon="perm_identity"
                              @add="addAudienceCondition(record)"
                              @remove="removeAudienceCondition(record, condI)"
                            ></condition>
                          </div>
                          <hr />
                        </div>

                        <button
                          type="button"
                          class="btn btn-outline btn-outline-success"
                          data-parent="#widget-automation"
                          aria-expanded="false"
                          aria-controls="sectionThree"
                          data-toggle="collapse"
                          data-target="#sectionThree"
                          @click.prevent="checkSectionsDone()"
                        >
                          {{ $t('widget_automation.page_greeting_ready_set_page_targeting') }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- page condiitons (global conditions) -->
                  <div key="3" class="panel card card-automation custom-border-radius border-0">
                    <div
                      id="headingThree"
                      class="card-header collapsed flex"
                      role="tab"
                      data-parent="#widget-automation"
                      aria-expanded="false"
                      aria-controls="sectionThree"
                      data-toggle="collapse"
                      data-target="#sectionThree"
                    >
                      <h5 class="mb-0">
                        <span v-if="sectionsDone[3] && sectionsTouched[3]" class="step done">
                          <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
                        </span>
                        <span v-else-if="!sectionsDone[3] && sectionsTouched[3]" class="step warning">3</span>
                        <span v-else class="step">3</span>
                        {{ $t('widget_automation.page_greeting_set_page_targeting') }}
                      </h5>
                      <span class="ml-auto">
                        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
                      </span>
                    </div>

                    <div
                      id="sectionThree"
                      role="tabpanel"
                      aria-labelledby="headingThree"
                      class="collapse"
                      data-section="3"
                    >
                      <div class="panel-body p-4">
                        <!--delivery moment-->
                        <div v-if="firstConditionByType(globalConditionEnablers.deliveryMoment.conditionType)">
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">access_time</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ globalConditionEnablers.deliveryMoment.niceName }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 md:mb-0">
                              <select v-model="globalConditionEnablers.deliveryMoment.value" class="form-control">
                                <option
                                  v-for="option in globalConditionEnablers.deliveryMoment.options"
                                  :key="option.text"
                                  :value="option.value"
                                >
                                  {{ option.text }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <condition
                            v-if="globalConditionEnablers.deliveryMoment.value !== null"
                            :has-buttons="false"
                            :condition="firstConditionByType(globalConditionEnablers.deliveryMoment.conditionType)"
                            :allowed-types="{ seconds_on_page: globalConditionTypes.seconds_on_page }"
                            icon="access_time"
                          ></condition>
                          <hr />
                        </div>

                        <!--page selection-->
                        <div>
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">desktop_windows</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ globalConditionEnablers.pageSelection.niceName }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 md:mb-0">
                              <select v-model="globalConditionEnablers.pageSelection.value" class="form-control">
                                <option
                                  v-for="option in globalConditionEnablers.pageSelection.options"
                                  :key="option.text"
                                  :value="option.value"
                                >
                                  {{ option.text }}
                                </option>
                              </select>
                            </div>
                            <div
                              v-if="globalConditionEnablers.pageSelection.value !== null"
                              class="col-md-2 mb-1 md:mb-0"
                            >
                              <button
                                class="btn btn-outline btn-outline-success"
                                @click.prevent="
                                  addSubcondition(
                                    firstConditionByType(globalConditionEnablers.pageSelection.conditionType)
                                      .subconditions,
                                    globalConditionTypes.page_url
                                  )
                                "
                              >
                                {{ $t('widget_automation.page_button_add_page') }}
                              </button>
                            </div>
                          </div>

                          <div
                            v-if="
                              globalConditionEnablers.pageSelection.value !== null &&
                              firstConditionByType(globalConditionEnablers.pageSelection.conditionType)
                            "
                          >
                            <condition
                              v-for="(subcondition, subcondI) in firstConditionByType(
                                globalConditionEnablers.pageSelection.conditionType
                              ).subconditions"
                              :key="subcondI"
                              class="mb-3"
                              :has-buttons="true"
                              :condition="subcondition"
                              :allowed-types="{ page_url: globalConditionTypes.page_url }"
                              icon="desktop_windows"
                              @add="
                                addSubcondition(
                                  firstConditionByType(globalConditionEnablers.pageSelection.conditionType)
                                    .subconditions,
                                  globalConditionTypes.page_url
                                )
                              "
                              @remove="
                                firstConditionByType(
                                  globalConditionEnablers.pageSelection.conditionType
                                ).subconditions.splice(subcondI, 1)
                              "
                            ></condition>
                          </div>
                          <hr />
                        </div>

                        <button
                          type="button"
                          class="btn btn-outline btn-outline-success"
                          data-parent="#widget-automation"
                          aria-expanded="false"
                          aria-controls="sectionFour"
                          data-toggle="collapse"
                          data-target="#sectionFour"
                          @click.prevent="checkSectionsDone()"
                        >
                          {{ $t('widget_automation.page_greeting_ready_go_to_next_step') }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- date global conditions, publish and save -->
                  <div key="4" class="panel card card-automation custom-border-radius border-0">
                    <div
                      id="headingFour"
                      class="card-header collapsed flex"
                      role="tab"
                      data-parent="#widget-automation"
                      aria-expanded="false"
                      aria-controls="sectionFour"
                      data-toggle="collapse"
                      data-target="#sectionFour"
                    >
                      <h5 class="mb-0">
                        <span v-if="sectionsDone[4] && sectionsTouched[4]" class="step done">
                          <i class="material-icons md-18" style="padding-top: 0.15rem">check</i>
                        </span>
                        <span v-else-if="!sectionsDone[4] && sectionsTouched[4]" class="step warning">4</span>
                        <span v-else class="step">4</span>
                        {{ $t('widget_automation.page_greeting_save_publish_or_schedule') }}
                      </h5>
                      <span class="ml-auto">
                        <i class="material-icons rotate-arrow md-18">keyboard_arrow_left</i>
                      </span>
                    </div>

                    <div
                      id="sectionFour"
                      role="tabpanel"
                      aria-labelledby="headingFour"
                      class="collapse"
                      data-section="4"
                    >
                      <div class="panel-body p-4">
                        <!--start date-->
                        <div>
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">today</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ globalConditionEnablers.startDate.niceName }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 md:mb-0">
                              <select v-model="globalConditionEnablers.startDate.value" class="form-control">
                                <option
                                  v-for="option in globalConditionEnablers.startDate.options"
                                  :key="option.text"
                                  :value="option.value"
                                >
                                  {{ option.text }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <condition
                            v-if="
                              globalConditionEnablers.startDate.value !== null &&
                              firstConditionByType(globalConditionEnablers.startDate.conditionType)
                            "
                            :has-buttons="false"
                            :condition="firstConditionByType(globalConditionEnablers.startDate.conditionType)"
                            :allowed-types="{ start_date: globalConditionTypes.start_date }"
                            icon="today"
                          ></condition>
                          <hr />
                        </div>

                        <!--end date-->
                        <div>
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">event</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ globalConditionEnablers.endDate.niceName }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 md:mb-0">
                              <select v-model="globalConditionEnablers.endDate.value" class="form-control">
                                <option
                                  v-for="option in globalConditionEnablers.endDate.options"
                                  :key="option.text"
                                  :value="option.value"
                                >
                                  {{ option.text }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <condition
                            v-if="
                              globalConditionEnablers.endDate.value !== null &&
                              firstConditionByType(globalConditionEnablers.endDate.conditionType)
                            "
                            :has-buttons="false"
                            :condition="firstConditionByType(globalConditionEnablers.endDate.conditionType)"
                            :allowed-types="{ end_date: globalConditionTypes.end_date }"
                            icon="event"
                          ></condition>
                          <hr />
                        </div>

                        <!-- widget -->
                        <div v-if="!firstActionByType(record, actionTypes.openHelpCenter)">
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">code</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ $t('widget_automation.page_greeting_select_widget') }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 md:mb-0">
                              <select v-model="record.web_widget_id" class="form-control">
                                <option v-for="widget in allWidgets" :key="widget.id" :value="widget.id">
                                  {{ widget.text }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <hr />
                        </div>

                        <!--active-->
                        <div>
                          <div class="row mb-3">
                            <div class="col-md-3 mb-1 md:mb-0">
                              <div class="row">
                                <div class="col-xs-2 pt-2 text-center" style="padding-right: 0">
                                  <i class="material-icons">remove_red_eye</i>
                                </div>

                                <div class="col-xs-10 pt-2">
                                  <span>{{ $t('widget_automation.page_greeting_active') }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2 mb-1 pt-1 md:mb-0">
                              <label class="ui-switch success m-t-xs m-r">
                                <input id="archiveCheck" v-model="record.active" type="checkbox" true-value="1" />
                                <i></i>
                              </label>
                            </div>
                          </div>
                          <hr />
                        </div>

                        <button class="btn success" type="submit" @click="saveAutomation">
                          <i class="material-icons md-18 pr-2">save</i>
                          {{ $t('widget_automation.page_greeting_save') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
