<template #toggle>
  <div v-if="!contact.email || record.update" class="x-editable-bs">
    <a class="email-field">
      <div v-if="!contact.email || !record.update">
        <i class="material-icons material-icon-mail"></i>
        <i class="material-icons material-icon-add"></i>
      </div>
    </a>
  </div>
</template>

<style lang="scss">
.x-editable-bs {
  .material-icons {
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .material-icon-mail {
    color: #919191;

    &:before {
      @extend .material-icons;
      content: 'email';
    }
  }

  .material-icon-add {
    color: #919191;
    font-size: 10px !important;
    background: #fff;
    border-radius: 50%;
    margin-left: -12px;

    &:before {
      @extend .material-icons;
      content: 'add';
    }
  }

  .editable-container {
    top: 100px !important;
    left: 30px !important;
    -webkit-box-shadow: 10px 10px 25px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 25px -18px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 25px -18px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    border: 0;
    width: 293px;
    z-index: 100000;
  }

  .editableform .form-group > div {
    display: flex;
    flex-direction: column;
  }

  .popover-title {
    text-align: center;
    background: #fff;
    border-bottom: none;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
    font-weight: bold;
  }

  input {
    border: solid #f1f1f1 3px;
    border-radius: 10px;
    padding-right: 0;
    width: 100% !important;
  }

  .editable-buttons {
    margin-top: 13px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    margin-left: 0;

    button {
      border: 0;
    }

    i {
      display: none;
    }

    .editable-submit {
      order: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      border-radius: 20px;

      &:before {
        font-family: 'Material Icons';
        content: '\e163';
        font-size: 20px;
        margin-right: 10px;
      }

      &:after {
        content: 'Save';
      }
    }

    .editable-cancel {
      order: 1;
      border-radius: 20px;
      width: 52px;
      margin-left: 0;

      &:before {
        font-family: 'Material Icons';
        content: '\e872';
        font-size: 20px;
        color: #919191;
      }
    }
  }

  .editable-clear-x {
    display: none !important;
  }

  .editable-cancel {
    background: #f1f1f1;
  }

  .form-group {
    width: 100%;
  }
}

.material-icon-mail {
  font-size: 20px !important;
  margin-left: -2px;
}

@media (max-width: 991px) {
  .contact-block-name .popover {
    right: 10px;
    width: auto;
  }

  .contact-block-name .editable-submit {
    width: auto !important;
    padding: 0 20px;
  }
}
</style>

<script type="text/babel">
import eventBus from '@/eventBus';

export default {
  emits: ['delete', 'add'],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      record: {
        email: this.contact.email,
        update: false,
      },
    };
  },

  mounted() {
    eventBus.$off('contact.update');
    eventBus.$on('contact.update', (update) => {
      this.record.update = update;
      this.updateMail();
    });
    this.editable();
  },

  methods: {
    updateMail() {
      this.$nextTick(() => {
        let editable = this.editable()
          .on('hidden', (event, reason) => {
            if (reason === 'cancel') {
              if (this.record.email === null) {
                return;
              }

              this.$emit('delete');

              this.record.email = null;
              this.contact.email = null;

              axios.put('/api/v2/contacts/' + this.contact.id, { email: null }).then((res) => {
                this.contact.email = null;

                eventBus.$emit('ticket.current.reload', {
                  focus: true,
                });
              });
            }
          })
          .on('shown', (e, editable) => {
            editable.input.$input.val(this.contact.email);
          });
        editable.trigger('click');
      });
    },

    editable() {
      return $('.email-field')
        .editable({
          type: 'email',
          title: this.$t('general.email'),
          value: this.contact.email,
          defaultValue: this.contact.email,
          display: false,
          highlight: false,
        })
        .on('save', (a, b) => {
          if (this.record.email === b.newValue) {
            return;
          }
          this.record.email = b.newValue;
          axios.put('/api/v2/contacts/' + this.contact.id, this.record).then((res) => {
            if (b.newValue !== '') {
              this.contact.email = b.newValue;
              this.$emit('add');
            } else if (b.newValue === '') {
              this.contact.email = null;
              this.$emit('delete');
            }
            eventBus.$emit('ticket.current.reload', {
              focus: true,
            });
          });
        });
    },
  },
};
</script>
