<template>
  <div ref="backdrop" class="trengo-modal-chat flex items-center justify-center md:p-8" @click.self="$emit('close')">
    <div
      class="box custom-shadow-outline relative flex h-full w-full select-none flex-col overflow-hidden rounded-lg"
      style="margin-bottom: 0"
      tabindex="0"
      @keydown.esc="$emit('close')"
    >
      <div class="modal-header">
        <h3 class="mb-0 text-ellipsis px-8 text-center" style="font-size: 18px; line-height: 1.5">
          {{ $t('internal_chat.search_participant_modal_title', { name: thread.name }) }}
        </h3>
        <button type="button" class="modal-close-btn leading-none" aria-label="Close">
          <i class="material-icons text-grey-500" style="margin-top: -3px" @click="$emit('close')">close</i>
        </button>
      </div>

      <div class="mx-4 flex lg:mx-8">
        <i
          class="material-icons icon-search b-2x flex items-center pl-3 text-grey-600"
          style="border-width: 2px"
          @click="$refs.inputSearch.focus()"
        >
          search
        </i>
        <input
          ref="inputSearch"
          type="text"
          :value="queryValue"
          class="form-control input-search b-2x sm:mt-0"
          :placeholder="$t('internal_chat.search_participant_modal_placeholder')"
          @input="(e) => (queryValue = e.target.value)"
        />
      </div>

      <div class="mx-4 lg:mx-8">
        <div class="w-100 mt-3 inline-flex rounded-lg bg-grey-200 font-medium text-grey-600" style="height: 40px">
          <span
            v-for="(filter, key) in filteredCategory"
            :key="key"
            class="pointer b-2x pointer flex flex-1 items-center justify-center rounded-lg"
            style="border-color: #ebedf5"
            :class="{ 'bg-white': key === activeItem }"
            @click="selectItem(key)"
          >
            {{ filter.title }}
            <span class="font-normal text-grey-500">&nbsp;({{ filter.amount }})</span>
          </span>
        </div>
      </div>

      <div v-if="filteredItems && filteredItems.length > 0" class="scroll-on-hover h-full" style="overflow-x: hidden">
        <div class="pl-4 lg:pl-8 lg:pr-4">
          <template v-for="participant in filteredItems">
            <router-link
              v-if="onlineStatus(participant.id)"
              :key="participant.id"
              :to="'/chat/' + getUserById(participant.id).identifier"
              class="dropdown-item modal-item relative"
              @click="$emit('goToParticipant')"
            >
              <div class="flex items-center">
                <div class="relative">
                  <div class="avatar-container overflow-hidden rounded-lg">
                    <avatar
                      :color="getUserById(participant.id).color"
                      font-size="14px"
                      :abbr="getUserById(participant.id).abbr"
                      :image="getUserById(participant.id).profileImage"
                      width="44"
                      class="avatar-left white"
                      style="top: 0; left: 0"
                    ></avatar>
                  </div>
                  <online-status
                    :status="chatStatus(getUserById(participant.id))"
                    :avatar-as-parent="true"
                    class="online-status absolute mr-1"
                  ></online-status>
                </div>
                <div class="colleague-description pl-4 leading-none">
                  <div class="text-ellipsis font-bold" style="padding-bottom: 1px">
                    {{ getUserById(participant.id).getDisplayName() }}
                    <span v-if="currentUser.id === participant.id" class="text-grey-500">
                      ({{ $t('internal_chat.you_indicator') }})
                    </span>
                  </div>
                  <div
                    v-if="currentUser.id !== participant.id"
                    class="modal-item-description mt-2 text-ellipsis text-grey-600"
                    :style="{
                      'padding-bottom':
                        identicalGroups[participant.id] && identicalGroups[participant.id].length > 0 ? '0' : '1px',
                    }"
                  >
                    <template v-if="identicalGroups[participant.id] && identicalGroups[participant.id].length > 0">
                      {{
                        $t('internal_chat.search_participant_modal_similar_groups', {
                          name: getUserById(participant.id).firstName,
                        })
                      }}
                      <span
                        v-if="identicalGroups[participant.id]"
                        v-tooltip="{
                          content: identicalGroups[participant.id].join(', '),
                          loadingContent: 'Please wait...',
                          loadingClass: 'content-is-loading',
                          delay: 100,
                          popperClass: ['tooltip-colleague'],
                        }"
                        class="b-b-2x inline-block border-grey-400"
                        style="padding-bottom: 1px"
                      >
                        {{ identicalGroups[participant.id].length }}
                      </span>
                      <span v-if="identicalGroups[participant.id] && identicalGroups[participant.id].length > 1">
                        {{ $t('internal_chat.search_participant_modal_similar_groups_plural') }}
                      </span>
                      <span v-else-if="identicalGroups[participant.id] && identicalGroups[participant.id].length === 1">
                        {{ $t('internal_chat.search_participant_modal_similar_groups_singular') }}
                      </span>
                      <span
                        class="modal-item-hover absolute mr-4 font-normal"
                        style="font-size: 12px; padding-bottom: 21px"
                      >
                        {{ $t('internal_chat.search_participant_modal_hover_participant') }}
                      </span>
                    </template>
                    <template
                      v-else-if="identicalGroups[participant.id] && identicalGroups[participant.id].length === 0"
                    >
                      <span style="padding-bottom: 1px">
                        {{
                          $t('internal_chat.search_participant_modal_similar_groups_none', {
                            name: getUserById(participant.id).firstName,
                          })
                        }}
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </div>
      </div>
      <div
        v-else-if="filteredItems.length === 0"
        class="no-results fw-medium mt-8 p-2 text-center"
        style="font-size: 20px"
      >
        {{ $t('internal_chat.no_results_found') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Avatar from '../../../Avatar';
import OnlineStatus from '../OnlineStatus';

export default {
  name: 'SearchColleagueModal',
  emits: ['close', 'goToParticipant'],
  components: { Avatar, OnlineStatus },
  props: {
    thread: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      queryValue: '',
      colleague: null,
      filters: [],
      activeItem: 0,
      status: 'All',
      identicalGroups: {},
    };
  },
  mounted() {
    if (!is_mobile_device()) {
      this.$refs.inputSearch.focus();
    }

    if (this.queryValue) {
      this.searchClicked();
    }

    this.getIdenticalGroups();
  },
  methods: {
    selectItem(key) {
      this.status = this.filters[key].title;
      this.activeItem = key;
      if (!is_mobile_device()) {
        this.$refs.inputSearch.focus();
      }
    },
    getIdenticalGroups() {
      this.thread.participants.forEach((participant) => {
        if (this.identicalGroups[participant.id]) {
          return;
        }

        Object.assign(this.identicalGroups, {
          [participant.id]: this.groupThreads
            .filter((t) => t.participants.find((p) => p.id === participant.id) && t.id !== this.currentThread.id)
            .map((g) => g.identifier),
        });
      });
    },
    onlineStatus(id) {
      return this.status === 'All' ? this.getUserById(id) : this.getUserById(id).isOnline;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'usersInternalChat/currentUser',
      getUserById: 'usersInternalChat/userById',
      chatStatus: 'chat/chatStatus',
      groupThreads: 'chat/groupThreads',
      currentThread: 'chat/currentThread',
      threadByUserId: 'chat/threadByUserId',
    }),
    filteredCategory() {
      return (this.filters = [
        {
          title: this.$t('internal_chat.search_participant_modal_filter_all'),
          amount: this.thread.participants.length,
        },
        {
          title: this.$t('internal_chat.search_participant_modal_filter_online'),
          amount: this.thread.participants.filter((p) => this.chatStatus(this.getUserById(p.id)) !== 'offline').length,
        },
      ]);
    },
    filteredItems() {
      return this.thread.participants.filter((t) => {
        let user = this.getUserById(t.id);
        return user.getDisplayName().includes(this.queryValue) || user.identifier.includes(this.queryValue);
      });
    },
  },
};
</script>

<style>
.tooltip-colleague {
  z-index: 999999999999999;
}
</style>

<style scoped lang="scss">
body {
  overflow-y: auto;
}

.message-body {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.dropdown-item {
  padding: 1rem 0 !important;
  border-bottom: 0;
}

.trengo-modal-chat {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  overflow: hidden;

  .modal-header {
    border-bottom: 0 !important;
  }

  .avatar-container {
    z-index: 9999;
    width: 44px;
    height: 44px;
  }

  .online-status {
    bottom: -2px;
    right: -6px;
    opacity: 1;
  }

  .colleague-description {
    width: calc(100% - 44px);
    padding-top: 1px;
  }

  .dropdown-item {
    margin-right: calc(2rem - 16px);
    &:hover .modal-item-hover {
      display: block;
    }
  }

  .modal-item-hover {
    display: none;
    right: 0;
    bottom: 0;
  }

  .modal-item-description {
    width: 75%;
  }

  .box {
    box-shadow: rgba(0, 0, 0, 0.11) 0 0 50px 0;
    width: 700px;
    max-width: 100%;
    border-radius: 10px;
    border: none;
    max-height: 687px;

    h2 {
      font-size: 300%;
      font-weight: 600;
    }

    h3 {
      font-size: 200%;
      font-weight: 600;
    }
  }

  .modal-item:not(:first-child) {
    border-top: solid theme('colors.grey-200') 2px;
  }

  .icon-search {
    font-size: 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: text;
    border-color: theme('colors.grey-200');
  }

  .input-search {
    border-left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: theme('colors.grey-200');
  }

  .dropdown-item:hover {
    background-color: transparent !important;
  }

  .form-control::placeholder {
    color: theme('colors.grey-600') !important;
    opacity: 0.5 !important;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(0, 0, 0, 0.11) 0 0 9px 0;
}

@media (max-width: 991px) {
  .trengo-modal-chat {
    .avatar-container {
      width: 40px;
      height: 40px;
    }
    .avatar-left {
      width: 40px !important;
      height: 40px !important;
      line-height: 40px !important;
    }
    .colleague-description {
      width: calc(100% - 40px);
    }
  }
}

@media (max-width: 800px) {
  .trengo-modal-chat {
    .scroll-on-hover {
      overflow-y: overlay !important;
      padding-right: 16px;
      &:hover {
        padding-right: 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  .trengo-modal-chat {
    .box {
      width: 100%;
      border-radius: 0;
    }
    .modal-item-description {
      width: 100%;
    }
    .dropdown-item {
      &:hover .modal-item-hover {
        display: none;
      }
    }
  }
}
</style>
