<template>
  <span ref="v" :class="{ 'flex w-full select-none flex-row flex-wrap': renderToggleElement && !toggleElementInline }">
    <span
      v-if="!remote && renderToggleElement"
      class="w-100 flex min-w-0"
      style="pointer-events: all"
      :class="{ 'rounded-lg border': beautify }"
      :data-test="dataTestDropdownToggle"
      @click="open(null, $event)"
    >
      <slot name="toggle" :label="selectedLabel" :selected="current">
        <div
          v-if="current"
          class="
            flex
            min-w-0
            select-none
            flex-nowrap
            items-center
            text-ellipsis
            rounded-lg
            bg-grey-200
            leading-none
            text-grey-600
            hover:text-grey-800
          "
          style="display: inline-flex; vertical-align: middle; padding: 5px 10px"
        >
          <span>{{ selectedLabel }}</span>
          <i v-if="caret" class="material-icons text-md pl-1" style="line-height: 0">expand_more</i>
        </div>
      </slot>
      <i v-if="beautify" class="material-icons text-md pl-1" style="line-height: 0">expand_more</i>
    </span>
    <div
      v-for="(current, i) in currentsArray"
      :key="i"
      v-click-away="deselectValue"
      class="mr-1 cursor-pointer rounded-lg leading-none"
      style="margin: 5px; padding: 5px 10px"
      :class="{
        'bg-grey-600 text-white': current === highlightedValue,
        'bg-grey-200 text-grey-600': current !== highlightedValue,
      }"
      @click="highlightValue(current, $event)"
    >
      <span class="select-text selection:bg-none" data-hj-suppress>{{ current[selectedLabelField] }}</span>
      <!--<span class="pl-1">×</span>-->
    </div>
    <input
      v-if="remote"
      ref="search"
      v-model="term"
      data-hj-suppress
      class="p-0"
      style="padding: 0; padding-left: 5px; width: calc(100% - 5px) !important"
      type="text"
      :data-test="dataTestRemoteSearchInput"
      @focus="onSearchFocus()"
      @keydown="onRemoteSearch"
      @blur="onRemoteQueryBlur"
    />

    <portal to="body-portal">
      <div v-if="isOpen" class="dropdown-overlay nav-bg" @click="close"></div>
      <div
        v-if="isOpen"
        :id="id"
        ref="dropdown"
        class="animated slideInUp dropdown-container align-items min-w-0 select-none flex-col"
        :class="[{ 'dropdown-container-mobile-fullscreen': !remote, 'dropdown-auto-top': autoHeight }, dropdownClass]"
        style="pointer-events: all"
        :style="{ width: width, maxWidth: maxWidth, minWidth: minWidth }"
      >
        <div v-show="!remote" v-if="renderCloseButton" class="close-dropdown" @click="close">
          <i class="material-icons text-grey-500">close</i>
        </div>
        <slot name="heading"></slot>
        <div
          v-if="!remote && search"
          class="search-bar m-4 flex rounded-lg border-2 border-grey-200 bg-white p-2 leading-none"
        >
          <img
            v-if="showSearchIcon"
            alt="icon-search"
            style="width: 16px"
            :src="`${$root.assetsURL}img/icon-search.svg`"
            class="mr-2"
          />
          <input
            ref="search"
            v-model="term"
            type="text"
            :placeholder="$t('general.search')"
            :data-test="dataTestSearchDropdownInput"
          />
        </div>

        <div
          v-if="static"
          class="dropdown-options flex flex-col"
          :style="autoHeight ? '' : { maxHeight: maxHeight }"
          :class="{ 'scroll-on-hover': scrollOnHover }"
          @click="close"
        >
          <slot name="body"></slot>
        </div>
        <ul
          v-if="filteredResults(options).length"
          class="dropdown-options m-0 flex-1 select-none p-0"
          :style="{ maxHeight: maxHeight, overflowY: overflowY }"
          :data-test="dataTestDropdownList"
        >
          <slot name="options">
            <li
              v-for="option in filteredResults(options)"
              :key="option.id"
              class="align-items b-b border-grey m-0 flex p-3 px-3"
              :class="[option.itemClass, { active: option.id === current.id }]"
              :data-test="dataTestDropdownListItem"
              @click="click(option, $event)"
              @mouseover="selected = option"
            >
              <slot name="option" :option="option">
                <span>
                  <i v-if="useMaterialIcons && option.icon" class="material-icons option-icon" style="margin-top: -3px">
                    {{ option.icon }}
                  </i>
                  <i v-else-if="option.isMaterial" class="material-icons option-icon" style="margin-top: -3px">
                    {{ option.icon }}
                  </i>
                  <i v-else-if="option.icon" class="fa-fw mr-1" :class="option.icon"></i>
                  {{ option[label] }}
                </span>
              </slot>
            </li>
            <template v-if="!!$slots.afterList">
              <li class="align-items b-b border-grey m-0 flex p-3 px-3 text-black">
                <slot name="afterList"></slot>
              </li>
            </template>
          </slot>
        </ul>
        <slot v-if="!remote && term !== '' && !filteredResults(options).length" name="no-results">
          <div class="border-t-2 border-grey-200 p-4 text-grey-600">No results found for "{{ term }}"</div>
        </slot>
      </div>
    </portal>
  </span>
</template>

<script type="text/babel">
import { mixin as VueClickAway } from 'vue3-click-away';

import eventBus from '@/eventBus';
import { randomString } from '@/util/stringHelpers';

export default {
  name: 'Dropdown',
  emits: ['update:modelValue', 'selected', 'open', 'close', 'search', 'count', 'inputEnter'],
  props: {
    searchAction: {
      type: Function,
      default: null,
    },
    dynamicSearch: {
      type: Boolean,
      default: false,
    },
    renderToggleElement: {
      type: Boolean,
      default: true,
    },
    toggleElementInline: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    maxHeight: {
      type: String,
      default: '231px',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    caret: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'text',
    },
    overflowY: {
      type: String,
      default: 'scroll',
    },
    selectedLabelField: {
      type: String,
      default: 'text',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    'active-state': {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '300px',
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
    visibleItems: {
      type: Number,
      default: 4,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emailFormat: {
      type: Boolean,
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 20,
    },
    static: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: true,
    },
    beautify: {
      type: Boolean,
      default: false,
    },
    scrollOnHover: {
      type: Boolean,
      default: true,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    createOnNoResults: {
      type: Boolean,
      default: false,
    },
    renderCloseButton: {
      type: Boolean,
      default: true,
    },
    useMaterialIcons: {
      type: Boolean,
      default: true,
    },
    dropdownClass: {
      type: String,
      default: '',
    },
    showSearchIcon: {
      type: Boolean,
      default: false,
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    },
    dataTestSearchDropdownInput: {
      type: String,
      default: 'dropdown-search-input',
    },
    dataTestDropdownList: {
      type: String,
      default: 'dropdown-list',
    },
    dataTestDropdownListItem: {
      type: String,
      default: 'dropdown-list-item',
    },
    dataTestDropdownToggle: {
      type: String,
      default: 'dropdown-toggle',
    },
    dataTestRemoteSearchInput: {
      type: String,
      default: 'remote-search-input',
    },
  },

  mixins: [VueClickAway],

  data() {
    return {
      isOpen: false,
      term: '',
      current: this.modelValue,
      resultSelected: false,
      selected: null,
      inited: false,
      currents: [],
      highlightedValue: null,
      id: '',
      dropdown: null,
    };
  },

  computed: {
    currentsArray() {
      return this.remote ? this.currents : [];
    },
    selectedLabel() {
      if (this.current[this.selectedLabelField]) {
        return this.current[this.selectedLabelField];
      }
      return ' ';
    },
  },

  watch: {
    highlightedValue() {
      if (this.highlightedValue) {
        this.mountKeyboard();
      } else {
        this.unmountKeyboard();
      }
    },

    modelValue() {
      this.current = this.modelValue;
      this.currents = this.modelValue;
    },

    term() {
      this.doSearch();
    },

    options() {
      if (this.remote) {
        this.inited = true;
      }
      this.$nextTick(() => {
        if (this.remote) {
          if (this.dropdown && this.dropdown.querySelector('ul')) {
            this.dropdown.querySelector('ul').scrollTop = 0;
          }
          this.selected = this.options[0];
        }
      });

      if (this.options.length > 0) {
        this.mountKeyboard();
      } else {
        this.unmountKeyboard();
      }
    },
  },

  mounted() {
    this.id = randomString(20);
    this.selected = this.options[0];

    eventBus.$on('OPEN_DROPDOWN', this.onTriggerOpen);
    eventBus.$on('CLOSE_DROPDOWN', this.onTriggerClose);
  },

  beforeUnmount() {
    eventBus.$off('OPEN_DROPDOWN', this.onTriggerOpen);
    eventBus.$off('CLOSE_DROPDOWN', this.onTriggerClose);

    this.unmountKeyboard();
  },

  methods: {
    onTriggerOpen(ref, position = null) {
      if (ref.$el === this.$refs.v) {
        this.open(position);
      }
    },

    onTriggerClose(ref) {
      if (!ref?.$el || ref.$el === this.$refs.v) {
        this.close();
      }
    },

    doSearch() {
      this.$emit('search', this.term);

      if (this.term !== '' && !this.remote) {
        this.$emit('count', this.filteredResults().length);
      }

      if (this.term === '' && this.remote) {
        this.close();
        return;
      }

      if (this.remote && !this.isOpen) {
        this.open();
      }

      if (this.dynamicSearch && this.searchAction) {
        this.searchAction(this.term);
      }

      this.$nextTick(() => {
        if (!this.remote) {
          // this.dropdown.querySelector('ul').scrollTop = 0;
          this.selected = this.filteredResults()[0];
        }
      });
    },

    onSearchFocus() {
      if (this.term === '') {
        return;
      }
      this.doSearch();
    },

    mountKeyboard() {
      this.unmountKeyboard();
      document.addEventListener('keydown', this.handleKeyboardEvent);
    },

    unmountKeyboard() {
      document.removeEventListener('keydown', this.handleKeyboardEvent);
    },

    handleKeyboardEvent(e) {
      if (!this.dropdown) {
        this.dropdown = document.getElementById(this.id);
      }

      if (
        e.charCode === 8 ||
        e.keyCode === 8 ||
        e.key === 'Backspace' ||
        e.key === 'Delete' ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 65 && e.keyCode <= 90)
      ) {
        if (e.metaKey || e.ctrlKey || (e.shiftKey && e.keyCode !== 8)) {
          return;
        }

        if (this.highlightedValue != null) {
          if (this.currents.length > 1) {
            this.currents.splice(this.currents.indexOf(this.highlightedValue), 1);
          } else {
            this.currents = [];
          }
          this.highlightedValue = null;
          this.$refs.search.focus();
          this.$emit('update:modelValue', this.currents);
        }
      }

      if (e.key === 'ArrowDown') {
        if (this.options.length > 0 && this.selected != null) {
          let current = this.options.indexOf(this.selected);
          if (this.options[current + 1]) {
            this.selected = this.options[current + 1];
            let pointerPosition = this.options.indexOf(this.selected) * this.itemHeight();

            if (
              this.dropdown &&
              this.dropdown.querySelector('ul').scrollTop <=
                pointerPosition - (this.visibleItems - 1) * this.itemHeight()
            ) {
              this.dropdown.querySelector('ul').scrollTop =
                pointerPosition - (this.visibleItems - 1) * this.itemHeight();
            }
          }
        }
      }

      if (e.key === 'ArrowUp') {
        if (this.options.length > 0 && this.selected != null) {
          let current = this.options.indexOf(this.selected);
          if (this.options[current - 1]) {
            this.selected = this.options[current - 1];
            let pointerPosition = this.options.indexOf(this.selected) * this.itemHeight();
            if (this.dropdown && this.dropdown.querySelector('ul').scrollTop >= pointerPosition) {
              this.dropdown.querySelector('ul').scrollTop = pointerPosition;
            }
          }
        }
      }

      if (e.key === 'Enter') {
        if (this.isOpen) {
          if (this.selected != null) {
            this.click(this.selected);
          } else if (!this.selected && this.term !== '' && !this.remote && this.createOnNoResults) {
            this.$emit('inputEnter', this.term);
            this.term = '';
          }
        }
      }

      if (e.key === 'Escape') {
        if (this.isOpen) {
          this.close();
        }
      }
    },

    highlightValue(item, e) {
      this.selectedNode = e.target;

      this.$nextTick(() => {
        this.highlightedValue = item;
        window.getSelection().selectAllChildren(e.target);
      });
    },

    deselectValue(e) {
      // clickaway doesn't trigger correctly because vue-portal is used.
      // See: https://github.com/simplesmiler/vue-clickaway/issues/9
      if (e.target === this.selectedNode) {
        return;
      }
      this.highlightedValue = null;
      this.selectedNode = null;
    },

    onRemoteSearch(e) {
      if (e.key === ',' || e.key === ' ' || e.key === ';' || e.key === 'Enter') {
        if (this.term !== '' && this.multiple && this.validateEmail(this.term)) {
          e.preventDefault();
          this.click({
            text: this.term,
            identifier: this.term,
            //id: this.term
          });
        }
      }

      if (e.key === 'Backspace') {
        if (this.remote && this.term === '') {
          this.highlightedValue = this.currents[this.currents.length - 1];
          //this.$refs.search.blur();
          return;
        }
      }
    },

    onRemoteQueryBlur() {
      if (this.multiple && this.options.length === 0) {
        if (this.term !== '' && this.validateEmail(this.term)) {
          this.click({
            text: this.term,
            identifier: this.term,
          });
          this.close();
        }
        return;
      }

      if (this.term !== '' && this.options.length === 0) {
        this.click({
          text: this.term,
          identifier: this.term,
          //id: this.term
        });
      }

      if (this.term !== '' && !this.validateEmail(this.term)) {
        setTimeout(this.close, 200); // Quick fix for closing when using tab while still make the item's clickable
      }
    },

    itemHeight() {
      if (!this.dropdown || !this.dropdown.querySelector('li')) {
        return;
      }
      return this.dropdown.querySelector('li').offsetHeight;
    },

    open(position = null, e = null) {
      this.$emit('open');

      if (!this.remote) {
        this.$emit('count', this.filteredResults().length);
      }

      if (!this.isOpen) {
        setTimeout(() => {
          this.inited = true;
        }, 300); // delay 300ms for animation to finish
      }

      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        // Duplicate nextTick because Vue Portal
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.dropdown = document.getElementById(this.id);
            this.dropdown.style.display = 'flex';

            let width = this.width;

            if (width === 'auto') {
              width = this.dropdown.clientWidth;
            } else {
              width = this.width.replace('px', '') - 10;
            }

            //let pos = cumulativeElOffset(e.target); // Creates variable position depending where you click..
            // Solution below, might require further testing
            let v;
            if (!position) {
              v = this.$refs.v;
            } else {
              v = position;
            }
            let rect = v.getBoundingClientRect();
            let pos = {
              top: rect.top + document.body.scrollTop + v.clientHeight - 10,
              left: rect.left + document.body.scrollLeft,
            };

            let offsetRight = document.body.clientWidth - pos.left - width;
            let offsetBottom = document.body.clientHeight - pos.top - (this.maxHeight.replace('px', '') - 10);

            if (offsetRight < 0) {
              this.dropdown.style.right = '10px';
              this.dropdown.style.left = 'auto';
            } else {
              this.dropdown.style.left = pos.left + 'px';
            }

            if (!this.remote && this.search) {
              this.$nextTick(() => {
                this.$refs.search.focus();
              });
            }

            if (offsetBottom > 0) {
              this.dropdown.style.top = pos.top + 'px';
            } else {
              let height = this.maxHeight.replace('px', '');
              if (parseInt(height) > pos.top && this.dropdown.querySelector('ul')) {
                this.dropdown.style.top = 0;
                this.dropdown.querySelector('ul').style.maxHeight = pos.top - 30 + 'px';
              }
              this.dropdown.style.bottom =
                window.innerHeight - (this.renderToggleElement ? pos.top : rect.bottom) + 30 + 'px';
            }

            if (this.placement === 'right') {
              this.dropdown.style.left = pos.left + v.offsetWidth + 'px';
              this.dropdown.style.bottom = window.innerHeight - (pos.top + 10 - v.offsetHeight / 2) + 'px';
            } else if (this.placement === 'left') {
              this.dropdown.style.right = '40px';
            }
          });
        });
        if (e && this.stopPropagation) {
          e.stopPropagation();
        }
      } else {
        this.close();
      }
    },

    close(e = null) {
      if (this.inited) {
        this.$emit('close');
        this.isOpen = false;
        this.inited = false;
        this.term = '';
      }
    },

    filteredResults() {
      if (this.remote) {
        return this.options;
      }
      return this.options.filter((record) => {
        var found = false;
        for (var key in record) {
          if (typeof record[key] == 'string' && record[key].toLowerCase().indexOf(this.term.toLowerCase()) != -1) {
            found = true;
          }
        }
        return found;
      });
    },

    click(item, e) {
      if (e && e.target.tagName !== 'LI' && e.target.tagName !== 'SPAN') {
        // return;
      }

      this.highlightedValue = null;

      if (this.remote) {
        if (!this.multiple) {
          this.currents = [];
        }
        this.term = '';

        if (this.currents.length >= this.maxItems) {
          return;
        }

        this.currents.push(item);
        this.$emit('update:modelValue', this.currents);

        if (this.multiple) {
          this.$refs.search.focus();
        }

        return;
      } else {
        if (!this.dynamicSearch) {
          this.term = '';
        }
        if (this.activateState) {
          this.current = item;
        }
        if (item) {
          if (this.closeOnSelect) {
            this.close();
          }
          this.$emit('update:modelValue', item);
          this.$emit('selected', item);
        }
      }
    },

    validateEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  width: 300px;
  position: fixed;
  z-index: 9999999;
  background: white;
  border-radius: 10px;
  box-shadow: 0 1px 9px 0 rgb(220, 221, 224);
  font-size: 14px;
  margin-top: 20px;
  overflow: hidden;
}

.dropdown-overlay {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: 99999;
}

.close-dropdown {
  display: none;
}

@media only screen and (max-width: 991px) {
  .close-dropdown {
    display: block;
    position: absolute;
    right: 12px;
    top: 13px;
    color: #9b9b9b;
    z-index: 999999;
  }
  .dropdown-container {
    width: auto !important;
    left: 15px !important;
    right: 15px !important;
    max-width: none !important;
    min-width: 0 !important;
  }

  .dropdown-container-mobile-fullscreen {
    width: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    max-width: none !important;
    min-width: 0 !important;
    top: 150px !important;
    margin-top: 0;
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: none;
    animation-duration: 0.3s;
  }

  .dropdown-container-mobile-fullscreen.dropdown-auto-top {
    top: auto !important;
  }

  .dropdown-container-mobile-fullscreen .dropdown-options {
    max-height: none !important;
    height: 100%;
  }
}

.input-search {
  position: relative;
  height: 33px;
  margin: 12px 12px 12px;
  border-radius: 6px;
  display: flex;
}

.input-search .fa {
  padding-top: 10px;
  padding-left: 9px;
}

input {
  flex: 1;
  background: none;
  border: none;
  padding-left: 8px;
  font-weight: normal;
}

ul {
  overflow: hidden;
}

li {
  cursor: pointer;
  border-bottom: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: #303133;
}

li.selected {
  background: theme('colors.grey-200');
}

li.active {
  font-weight: bold;
}

.highlight-value {
  font-weight: 600;
  color: rgb(77, 79, 82);
  white-space: nowrap;
  background: #cfe4fa;
  /* color: white; */
  border-radius: 4px;
  pointer-events: none;
}

.option-icon {
  width: 1.2rem;
  margin-right: 0.2rem !important;
  text-align: center;
  opacity: 0.7;
  margin-left: 0;
  font-size: 18px;
  vertical-align: middle !important;
}

.selection\:bg-none::selection {
  background-color: transparent;
}

.sms-composer-qr .search-bar {
  background: var(--color-grey-200);
  border-radius: 12px;
  border: none;
  font-family: 'Inter', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-grey-600);

  ::placeholder {
    color: var(--color-grey-600);
    opacity: 1;
    /* Firefox */
  }
}

.sms-composer-tag {
  li {
    margin: 4px 12px;
    border-radius: 12px;
  }

  li.selected {
    background: var(--color-grey-300);
  }
}
</style>
