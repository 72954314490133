<template>
  <div class="py-4">
    <div class="flex items-center gap-3">
      <slot name="icon" />
      <div class="flex grow flex-col gap-2">
        <div class="flex justify-between">
          <span class="t-text-md-emphasize text-grey-800">
            <slot name="title" />
          </span>

          <div v-if="isUpcoming || isVoid" class="flex items-center gap-1">
            <span class="t-text-sm text-grey-700">
              {{ $t('usage_overview.overview_section_in_progress', { inProgressAmount }) }}
            </span>
          </div>
          <div v-else>
            <span class="t-text-sm text-grey-700">{{ formatAmount(format, spentAmount) }}</span>
            <span class="t-text-sm text-grey-600">
              / {{ isUnlimited ? $t('usage_overview.unlimited') : formatAmount(format, quota) }}
            </span>
          </div>
        </div>
        <div>
          <div class="flex gap-1">
            <div
              v-for="(bar, i) in bars"
              :key="i"
              class="!p-0"
              :class="[{ 'border-l-1 border-grey-300 !pl-1': i === 1 }, `col-md-${bar.barLength}`]"
            >
              <t-progress-bar
                :markings-amount="isYearly ? 12 : 10"
                :finished="bar.spentAmount"
                :in-progress="bar.inProgressAmount"
                :total="bar.quota"
                :in-progress-color="barColors.IN_PROGRESS"
                :finished-color="barColors.FINISHED"
                :in-progress-tooltip="
                  $t(barTooltips(i > 0).IN_PROGRESS, { amount: formatAmount(format, bar.inProgressAmount) })
                "
                :finished-tooltip="$t(barTooltips(i > 0).FINISHED, { amount: formatAmount(format, bar.spentAmount) })"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="usageThreshold && !isTrial" class="flex justify-between pt-4">
      <span class="t-text-sm flex items-center text-grey-600">
        {{ $t('usage_overview.overview_section_threshold', { usageThreshold }) }}
        <slot v-if="showButton && percentageFinished >= 90" name="info" />
      </span>
      <slot v-if="showButton && percentageFinished >= 90" name="button" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';

import TProgressBar from '@/components/common/TProgressBar/TProgressBar.vue';
import { PERIOD_TYPE } from '@/Configs/Constants';

import { PROGRESS_CARD_COLORS, FULL_BAR_LENGTH, PROGRESS_CARD_TOOLTIPS } from '../constants';
import { formatAmount, calculateDividedBarValues } from '../utils';

import type { BarValues } from '../types';
import type { PeriodType } from '@/types';
import type { PropType } from 'vue';

const props = defineProps({
  type: {
    type: String as PropType<'intelligent_conversations' | 'standard_conversations' | 'balance'>,
    required: true,
  },
  periodType: {
    type: String as PropType<PeriodType>,
    default: PERIOD_TYPE.YEAR,
    required: true,
  },
  spentAmount: {
    type: Number,
    required: true,
  },
  inProgressAmount: {
    type: Number,
    default: 0,
  },
  quota: {
    type: Number,
    required: true,
  },
  format: {
    type: String as PropType<'number' | 'currency'>,
    required: false,
    default: 'number',
  },
  showButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  isUnlimited: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const bars = ref<BarValues[]>([]);

const isUpcoming = computed(() => props.periodType === PERIOD_TYPE.UPCOMING);
const isVoid = computed(() => props.periodType === PERIOD_TYPE.VOID);
const isTrial = computed(() => props.periodType === PERIOD_TYPE.TRIAL);
const isYearly = computed(() => props.periodType === PERIOD_TYPE.YEAR);

const barColors = computed(() => {
  switch (true) {
    case props.type === 'balance' || isTrial.value || props.isUnlimited:
      return PROGRESS_CARD_COLORS.INITIAL_COLORS;
    case usageThreshold.value === 90:
      return PROGRESS_CARD_COLORS.OVER_90_TRESHOLD;
    case usageThreshold.value === 100:
      return PROGRESS_CARD_COLORS.OVER_USAGE;
    default:
      return PROGRESS_CARD_COLORS.INITIAL_COLORS;
  }
});

const barTooltips = computed(() => (isOverUsage: boolean) => {
  switch (true) {
    case props.type === 'balance':
      return PROGRESS_CARD_TOOLTIPS.BALANCE;
    case isOverUsage:
      return PROGRESS_CARD_TOOLTIPS.OVER_USAGE;
    default:
      return PROGRESS_CARD_TOOLTIPS.DEFAULT;
  }
});

const usageThreshold = computed(() => {
  switch (true) {
    case percentageFinished.value >= 100:
      return 100;
    case percentageFinished.value > 90:
      return 90;
    case percentageFinished.value > 75:
      return 75;
    case percentageFinished.value > 50:
      return 50;
    default:
      return null;
  }
});

const percentageFinished = computed(() => {
  return Math.min(1, props.spentAmount / props.quota) * 100;
});

const isOverUsed = computed(() => {
  if (props.type === 'balance' || isTrial.value || props.isUnlimited) {
    return false;
  }

  return props.spentAmount + props.inProgressAmount > props.quota;
});

const loadBars = () => {
  const amounts = {
    spentAmount: props.spentAmount,
    inProgressAmount: props.inProgressAmount,
    quota: props.quota,
  };

  if (!isOverUsed.value) {
    bars.value = [
      {
        ...amounts,
        barLength: FULL_BAR_LENGTH,
      },
    ];
    return;
  }

  bars.value = calculateDividedBarValues({ ...amounts });
};
onMounted(() => {
  loadBars();
});

watch(
  () => props.spentAmount,
  () => {
    loadBars();
  }
);
</script>
