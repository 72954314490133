<template>
  <div ref="main_wrapper" data-test="edit-team-page" class="relative block h-screen overflow-y-auto pb-30">
    <template v-if="is_loaded">
      <div class="nav-settings light left b-primary">
        <div class="text-md white header px-6 py-3 sm:p-6">
          <span class="sm:t-text-desktop-h3 t-text-mobile-h3 text-ellipsis text-grey-800" v-text="record.name"></span>
        </div>
      </div>
      <div class="px-3 py-8 pb-6 sm:mb-2 sm:px-8 sm:pb-8">
        <div class="flex flex-col justify-between sm:flex-row sm:items-end">
          <div class="mb-2 w-full sm:mb-0 sm:mr-14 sm:w-1/3">
            <div class="t-text-desktop-label-md mb-2 text-grey-900">{{ $t('team.team_name_title') }}</div>
            <atomic-input
              v-model="record.name"
              :error="!nameIsValid"
              :max="40"
              data-test="team_name"
              :placeholder="$t('team.team_name_placeholder')"
              @blur="validateName"
            />
            <t-error-item
              v-if="!nameIsValid"
              danger-icon
              data-test="error-name"
              :text="$t('team.enter_team_name_error')"
            />
          </div>
          <workload-balancing-toggle
            v-if="isEntitled"
            :is-enabled="workLoadIsEnabled"
            @click="handleToggleWorkLoadBalancing()"
          />
          <upgrade-plan v-if="showUpgradeWorkLoadBalancingForThisUser" @cancel="handleCancelShowWorkLoadBalancing" />
        </div>
      </div>
      <div class="px-3 sm:px-8">
        <team-members
          :has-limit="workLoadIsEnabled"
          :team-name="record.name"
          :team-id="record.id"
          :users="computedUsers"
          @change-users="handleUsersChanges"
          @input-value-changed="handleChangeInputValue"
          @remove-user="handleRemoveUsers"
        />
        <team-channels
          :has-workload="workLoadIsEnabled"
          :team-name="record.name"
          :team-id="record.id"
          :channels="immediate_record.channels"
          @change="handleChannelsChanges"
        />
      </div>
      <div v-show="isConsiderToggleAvailable" data-test="user-availability" class="mt-6 px-3 sm:mt-10 sm:px-8">
        <div class="t-text-desktop-label-sm mb-1 text-grey-800">
          {{ $t('team.workload_balancing_availability_title') }}
        </div>
        <div class="sm:t-text-desktop-paragraph-sm t-text-desktop-paragraph-xs mb-2 text-grey-600">
          {{ $t('team.workload_balancing_detail') }}
        </div>
        <div class="flex flex-row">
          <t-switch v-model="immediate_record.user_availability" data-test="user-availability-toggle" />
          <div class="t-text-desktop-paragraph-md ml-2 text-grey-900">
            {{ $t('team.consider_team_member_availability_title') }}
          </div>
        </div>
      </div>
      <div data-test="ticket-notification" class="mt-6 px-3 sm:mt-10 sm:px-8">
        <div class="t-text-desktop-label-sm mb-1 text-grey-800">{{ $t('team.ticket_notification_title') }}</div>
        <div class="sm:t-text-desktop-paragraph-sm t-text-desktop-paragraph-xs mb-2 text-grey-600">
          {{ $t('team.ticket_notification_detail') }}
        </div>
        <div class="w-full sm:w-1/3">
          <atomic-input
            v-model="record.notification_setting.value"
            :error="!emailIsValid"
            data-test="ticket-notification-input"
            :placeholder="$t('team.ticket_notification_example_email')"
            @blur="validateEmail"
          />
          <t-error-item
            v-if="!emailIsValid"
            danger-icon
            data-test="error-email"
            :text="$t('team.enter_valid_email_address_label_error')"
          />
        </div>
      </div>
      <div class="mt-6 px-3 sm:mt-10 sm:px-8">
        <div class="t-text-desktop-label-sm mb-1 text-grey-800">{{ $t('team.delete_team_title') }}</div>
        <div class="sm:t-text-desktop-paragraph-sm t-text-desktop-paragraph-xs mb-3 text-grey-600 sm:mb-3">
          {{ $t('team.delete_team_does_not_impact_assigned_conversation_hint') }}
        </div>
        <t-button data-test="delete-team" btn-style="danger-alt" @click="deleteTeam">
          {{ $t('team.delete_team_button') }}
        </t-button>
      </div>
      <div v-if="recordIsDirty" data-test="action-pop-up" class="fixed bottom-0" :style="actionPopUpWidth">
        <t-action-popup
          :saving="saving"
          :text="$t('general.action_popup_you_have_unsaved_changes')"
          :discard-text="$t('general.action_popup_discard')"
          :save-text="$t('general.action_popup_save_changes')"
          @save="submitChanges"
          @reset="resetStates"
        />
      </div>
    </template>
    <template v-else>
      <span class="grid h-screen place-items-center">
        <t-spinner />
      </span>
    </template>
    <enable-work-load-modal
      v-if="openEnableWorkloadModal"
      :users="computedUsers"
      :team="record.name"
      :channels="immediate_record.channels"
      :is-open="openEnableWorkloadModal"
      @submit="addAndEnableWorkLoadBalancing"
      @remove-channel="handleRemoveChannel"
      @cancel="handleCloseWorkloadBalancing"
    />
  </div>
</template>

<script lang="ts">
import { sanitize } from 'dompurify';
import { isEmpty, map } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import {
  deleteTeamChannelRequest,
  deleteTeamRequest,
  getTeamRequest,
  getUserConfigUpgradeTipWLB,
  setAllConversationsEnable,
  setUserConfigUpgradeTipWLB,
  updateTeamRequestNewAPI,
} from '@/components/Teams/api';
import { FEATURE } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import RouterWarningOnEdit from '@/mixins/RouterWarningOnEdit';
import { useEntitlementsStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';
import { validateEmail } from '@/util/helpers';

import { TeamChannels, TeamMembers } from '../components';
import EnableWorkLoadModal from '../components/EnableWorkLoadModal';
import UpgradePlan from '../components/UpgradePlan';
import WorkloadBalancingToggle from '../components/WorkloadBalancingToggle';
export default defineComponent({
  name: 'EditTeamPage',
  emits: ['call-loadlist-method'],
  data() {
    return {
      user_configs_wlb: [],
      show_upgrade_for_user: false,
      showWorkLoadBalancing: false,
      openEnableWorkloadModal: false,
      validates: {
        name: true,
        notification_setting: true,
      },
      breakpoints,
      immediate_record: {
        users: [],
        channels: [],
        user_availability: false,
        load_balancing: false,
        add_all_conversations: false,
      },
      record: {
        id: 0,
        name: '',
        notification_setting: { value: '' },
      },
      saving: false,
      is_loaded: false,
      timeout: null,
      channels: [],
    };
  },
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },
  mixins: [RouterWarningOnEdit],
  components: {
    UpgradePlan,
    WorkloadBalancingToggle,
    EnableWorkLoadModal,
    TeamMembers,
    TeamChannels,
  },
  watch: {
    'immediate_record.user_availability'(val) {
      if (val === undefined || !this.is_loaded) {
        return false;
      }
      this.debounce(() => this.updateUserAvailability(val), 1000);
    },
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitled() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__AUTOMATION__WORKLOAD_BALANCING);
    },
    isConsiderToggleAvailable() {
      return this.workLoadIsEnabled;
    },
    showUpgradeWorkLoadBalancingForThisUser() {
      return this.show_upgrade_for_user && !this.isEntitled;
    },
    computedUsers: {
      get() {
        return this.immediate_record.users;
      },
      set(val) {
        this.immediate_record.users = val;
      },
    },
    workLoadIsEnabled() {
      return !!this.immediate_record.load_balancing;
    },
    nameIsValid() {
      return !!this.validates.name;
    },
    emailIsValid() {
      return !!this.validates.notification_setting;
    },
    isMobile() {
      return !this.breakpoints.comparisons.gt_md;
    },
    actionPopUpWidth() {
      const mainWrapper = this.$refs.main_wrapper;
      const mainWrapperWidth = mainWrapper?.offsetWidth;
      return { width: this.isMobile ? '100%' : `${mainWrapperWidth}px` };
    },
    channelsList() {
      return this.$root.channels.filter((c) => !c.is_private);
    },
    ready() {
      if (this.record.name === '') {
        return;
      }
      return true;
    },
  },
  mounted() {
    this.fetchTeam();
  },
  methods: {
    fetchTeam() {
      getTeamRequest(this.$route.params.id)
        .then((result) => {
          this.setRecords(result.data);
          this.setImmediateRecords(result.data);
          this.initialShowWorkLoadBalancing();
          this.initWarning();
          this.$nextTick(() => {
            getUserConfigUpgradeTipWLB().then((res) => {
              const { data } = res;
              this.user_configs_wlb = data;
              const userConfigExistsForThisUser = data?.filter((config) => config.team_id === this.record.id);
              this.show_upgrade_for_user =
                userConfigExistsForThisUser?.state === 1 || !userConfigExistsForThisUser.length;
            });
            this.is_loaded = true;
          });
        })
        .catch((err) => {
          console.error(err);
          this.$router.push('/admin/teams');
        });
    },
    handleRemoveUsers(users) {
      this.computedUsers = users;
    },
    handleCancelShowWorkLoadBalancing() {
      const updateUserConfig = [...this.user_configs_wlb, { state: 0, team_id: this.record.id }];
      setUserConfigUpgradeTipWLB({ value: updateUserConfig }).then(() => {
        this.show_upgrade_for_user = false;
      });
    },
    initialShowWorkLoadBalancing() {
      this.showWorkLoadBalancing = this.isEntitled;
      if (!this.showWorkLoadBalancing) {
        this.immediate_record.load_balancing = false;
      }
    },
    updateUserAvailability(value) {
      const data = {
        consider_user_availability: value,
      };
      updateTeamRequestNewAPI(this.record.id, data).then(() => {
        this.flashSuccess(this.$t('team.team_updated_successfully'));
      });
    },
    debounce(func, timeout = 500) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        func();
      }, timeout);
    },
    handleChangeInputValue(value) {
      this.debounce(() => this.updateUserByTicketLimit(value));
    },
    updateUserByTicketLimit(user) {
      const data = {
        users: {},
        load_balancing_enabled: true,
      };
      data['users'][user.id] = user.load_balancing_ticket_limit;
      updateTeamRequestNewAPI(this.record.id, data).then(() => {
        this.flashSuccess(this.$t('team.team_updated_successfully'));
      });
    },
    addAndEnableWorkLoadBalancing(dataObject) {
      const { users, add_all_conversations } = dataObject;
      this.computedUsers = users;
      this.immediate_record.add_all_conversations = add_all_conversations;
      this.immediate_record.load_balancing = true;
      const data = {
        users: {},
        load_balancing_enabled: true,
      };
      this.computedUsers.map(function (user) {
        data['users'][user.id] = user.load_balancing_ticket_limit;
      });
      if (add_all_conversations) {
        setAllConversationsEnable().then(() => {
          this.callUpdateTeamAPI(data);
        });
        return false;
      }
      this.callUpdateTeamAPI(data);
    },
    callUpdateTeamAPI(data) {
      updateTeamRequestNewAPI(this.record.id, data).then(() => {
        this.flashSuccess(this.$t('team.team_updated_successfully'));
        this.$store.dispatch('teams/updateTeams');
        this.loadParentList();
      });
    },
    loadParentList() {
      eventBus.$emit('update-team');
      this.fetchTeam();
      this.$root.loadAuthorizedChannels();
      this.$root.loadAllChannels();
    },
    handleToggleWorkLoadBalancing() {
      if (!this.immediate_record.load_balancing) {
        this.openEnableWorkloadModal = true;
        return true;
      }
      this.requestDisableWorkLoadBalancing();
    },
    handleCloseWorkloadBalancing() {
      this.openEnableWorkloadModal = false;
    },
    async requestDisableWorkLoadBalancing() {
      const data = {
        load_balancing_enabled: false,
      };
      const shouldDeactivateWorkLoadBalancing = await this.$tConfirm(
        `Are you sure you want to deactivate workload balancing for <b>"${this.record.name}"</b>? Conversations will no longer be distributed automatically.`,
        {
          type: 'danger',
          confirmText: this.$t('team.deactivate_workload_balancing_button'),
          title: this.$t('team.deactivate_workload_balancing_title'),
        }
      );
      if (!shouldDeactivateWorkLoadBalancing) {
        return false;
      }
      this.immediate_record.load_balancing = !this.immediate_record.load_balancing;
      updateTeamRequestNewAPI(this.record.id, data).then(() => {
        this.flashSuccess(this.$t('team.team_updated_successfully'));
        this.$store.dispatch('teams/updateTeams');
        this.loadParentList();
      });
    },
    resetStates() {
      this.revertDirtyChanges();
      this.validateEmail();
      this.validateName();
    },
    validateEmail() {
      this.validates.notification_setting =
        isEmpty(this.record.notification_setting?.value) || validateEmail(this.record.notification_setting?.value);
    },
    validateName() {
      this.validates.name = !isEmpty(this.record.name);
    },
    handleChannelsChanges(channels) {
      this.immediate_record.channels = channels;
    },
    handleRemoveChannel(channel) {
      deleteTeamChannelRequest(channel.id, this.record.id).then(() => {
        this.immediate_record.channels = this.immediate_record.channels.filter((u) => u.id !== channel.id);
      });
    },
    handleUsersChanges(users) {
      this.computedUsers = users;
    },
    setRecords(data) {
      this.record = {
        id: data.id,
        name: data.name,
        notification_setting: data.notification_setting ? data.notification_setting : this.record.notification_setting,
      };
    },
    setImmediateRecords(data) {
      this.immediate_record = {
        users: data.users,
        channels: data.channels,
        user_availability: data.consider_user_availability,
        load_balancing: data.load_balancing_enabled,
      };
    },
    submitChanges() {
      if (this.saving) {
        return false;
      }
      if (!this.nameIsValid || !this.emailIsValid) {
        return false;
      }
      this.saving = true;
      this.updateTeam();
    },
    updateTeam() {
      const data = {
        name: this.record.name,
        email_notification_setting: this.record.notification_setting?.value,
      };
      updateTeamRequestNewAPI(this.record.id, data)
        .then(() => {
          this.saving = false;
          this.recordIsDirty = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('team.team_updated_successfully'));
          this.initWarning();
        })
        .catch(() => {
          this.saving = false;
        });
    },
    async deleteTeam() {
      const shouldDeleteTeam = await this.$tConfirm(
        this.$t('team.are_you_sure_to_delete_team', { teamName: sanitize(this.record.name) }),
        {
          title: this.$t('general.are_you_sure'),
          type: 'danger',
          confirmText: this.$t('general.delete'),
        }
      );
      if (shouldDeleteTeam) {
        this.initWarning();
        deleteTeamRequest(this.$route.params.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push('/admin/teams');
          this.flashSuccess(this.$t('team.team_deleted_successfully_message'));
        });
      }
    },
    mapChannelIds() {
      return map(this.immediate_record.channels, 'id');
    },
    mapUserIds() {
      return map(this.computedUsers, 'id');
    },
  },
});
</script>

<style src="./Edit.scss" scoped lang="scss" />
