/**
 * https://developers.apideck.com/apis/crm/coverage
 * APIDeck doesn't support filtering uniformly for every integration, and there's also no easy way
 * of knowing which types are possible programmatically. So, we have to hardcode which integrations
 * support filtering by various means, for example if we can filter by Company ID, or even if we can
 * see if we can access opportunities in the first place.
 */

const OPPORTUNITIES_UNSUPPORTED = ['crm+attio', 'crm+flexmail', 'crm+google-contacts'];

/**
 * Determines whether or not APIDeck supports calling the `/opportunties` endpoint for a given integration
 */
export function areOpportunitiesSupported(serviceId: string): boolean {
  return !OPPORTUNITIES_UNSUPPORTED.includes(serviceId);
}

const COMPANY_FILTERING_UNSUPPORTED = ['crm+close', 'crm+pipedrive', 'crm+zoho-crm'];

/**
 * Determines whether or not APIDeck supports filtering by company_id for a given integration
 */
export function isCompanyFilteringSupported(serviceId: string): boolean {
  return !COMPANY_FILTERING_UNSUPPORTED.includes(serviceId);
}
