<template>
  <div class="forwarding-email-edit-dns">
    <div class="mb-3">
      <div class="text-lg font-semibold">
        <span class="leading-5">{{ $t('email_settings.add_email') }}</span>
      </div>
      <div class="text-base font-normal">
        <span class="leading-6">{{ $t('email_settings.select_an_existing_email_or_enter_a_new_one') }}</span>
      </div>
    </div>
    <search-dropdown
      v-model="computedSenderDomain"
      :options="computedVerifiedSenderDomainsItems"
      :placeholder="placeholder"
      :disabled="!isEntitleToUpdateSenderDomain"
      @update:modelValue="(value) => handleFilterDomains(value)"
    >
      <template #item="slotProps">
        <dns-item :item="slotProps.item" @delete="handleDeleteItem" @click="handleItemClick" />
      </template>
      <template v-if="showAddDomain" #end>
        <div>
          <div
            v-if="computedVerifiedSenderDomainsItems.length > 0"
            class="border-bottom mx-4 border-1 border-grey-300"
          />
          <div
            class="m-3 mb-4 cursor-pointer items-center justify-center pb-1"
            :class="enableAddDomain ? 'text-grey-900' : 'text-grey-500'"
            @click="handleAddDomain"
          >
            <span class="ml-4 mr-0">
              <i v-show="loading" class="fal fa-spinner animate-spin" />
              <span v-show="!loading" class="text-2xl font-extralight">+</span>
            </span>
            <span class="text-base font-semibold">{{ $t('email_settings.add_input_as_a_new_email') }}</span>
          </div>
        </div>
      </template>
    </search-dropdown>
    <div v-if="!isEntitleToUpdateSenderDomain" class="mt-4 flex">
      <p
        class="t-text-sm mr-2 text-grey-600"
        v-html="$t('entitlement.upgrade_sending_email', { plan: requiredPlanEmailWhiteLabel })"
      />
      <t-tooltip v-if="badgeTooltipText.length > 0" position="top" :text="badgeTooltipText">
        <t-badge id="badge" variant="upgrade" :text="requiredPlanEmailWhiteLabel">
          <template #icon><arrow-top-circle-linear size="1rem" /></template>
        </t-badge>
      </t-tooltip>
      <t-badge v-else id="badge" variant="upgrade" :text="requiredPlanEmailWhiteLabel">
        <template #icon><arrow-top-circle-linear size="1rem" /></template>
      </t-badge>
    </div>
  </div>
</template>

<script>
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';

import { removeSenderDomain } from '@/components/Channels/EmailSettings/Pages/ChannelSettings/api';
import { PERMISSION, FEATURE, ENTITLEMENT_STATUS } from '@/Configs/Constants';
import { useEntitlementsStore } from '@/store/pinia';
import { validateEmail } from '@/util/helpers';

import DnsItem from '../DnsItem';
import { SearchDropdown } from '../SearchDropdown';

export default {
  name: 'ForwardingEmailEditDns',
  emits: ['update:modelValue', 'add-dns', 'refresh', 'click'],
  components: { DnsItem, SearchDropdown, ArrowTopCircleLinear },
  props: {
    modelValue: {
      type: [String, Object],
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    domains: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
  },
  watch: {
    domains(v) {
      this.allDomains = v;
    },
    loading(v) {
      this.enableAddDomain = v;
    },
  },
  data() {
    return {
      senderDomain: '',
      allDomains: [], //show all domains
      showAddDomain: false,
      enableAddDomain: false,
      FEATURE,
      ENTITLEMENT_STATUS,
      PERMISSION,
    };
  },
  mounted() {
    this.allDomains = this.domains;
  },
  methods: {
    handleDeleteItem(id) {
      this.$tConfirm(this.$t('email_settings.this_domain_will_be_permanently_deleted_this_cannot_be_undone'), {
        title: this.$t('email_settings.delete_this_domain'),
        confirmText: this.$t('email_settings.delete'),
        cancelText: this.$t('email_settings.cancel'),
        type: 'danger',
        deleteIcon: true,
      }).then((res) => {
        if (res) {
          removeSenderDomain(id).then(() => {
            this.computedVerifiedSenderDomainsItems = this.allDomains.filter((item) => item.id !== id);
            this.$emit('refresh');
          });
        }
      });
    },
    handleFilterDomains(value) {
      this.computedSenderDomain = value;
      const filterDomainsExists = value
        ? this.domains
            .filter((domain) => domain.domain.toLowerCase().includes(value.toLowerCase()))
            .map((domain) => {
              return {
                ...domain,
                domain: domain.domain.replace(value, `<strong>${value}</strong>`),
              };
            })
        : this.domains;

      this.showAddDomain = value && filterDomainsExists;
      const isEmail = validateEmail(value);
      this.enableAddDomain =
        isEmail &&
        !(value && filterDomainsExists.filter((domain) => domain.domain.toLowerCase() === value.toLowerCase()).length);
      this.computedVerifiedSenderDomainsItems = filterDomainsExists;
    },
    handleItemClick(v) {
      const { value } = v;
      this.$emit('click', value);
      this.computedValue = value;
      this.computedSenderDomain = value;
    },
    handleAddDomain() {
      if (this.enableAddDomain) {
        this.$emit('add-dns', this.senderDomain);
      }
    },
  },
  computed: {
    ...mapStores(useEntitlementsStore),
    isEntitleToUpdateSenderDomain() {
      return (
        this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__EMAIL__WHITELABEL) ===
        ENTITLEMENT_STATUS.ENTITLED
      );
    },
    requiredPlanEmailWhiteLabel() {
      return this.entitlementsStore?.getMinimumRequiredPlanFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__EMAIL__WHITELABEL);
    },
    hasPermissionToUpgrade() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
    badgeTooltipText() {
      return this.hasPermissionToUpgrade ? '' : this.$t('entitlement.admin_upgrade_subscription');
    },
    computedSenderDomain: {
      get() {
        const value = this.modelValue?.domain;
        return value && Object.keys(value).length > 0 ? value : '';
      },
      set(value) {
        this.senderDomain = value;
      },
    },
    computedVerifiedSenderDomainsItems: {
      get() {
        return this.allDomains;
      },
      set(value) {
        this.allDomains = value;
      },
    },
    verifiedSenderDomains: {
      get() {
        return this.senderDomains.filter((n) => n.status === 'VERIFIED');
      },
      set(value) {
        this.senderDomains = value;
      },
    },
    computedValue: {
      get() {
        return this.modelValue.domain;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
