import { attachFile } from '@/api/modules/file';

// These filetypes are explicitly allowed from the backend, check config/mimes.php for reference
const ALLOWED_IMAGE_EXTENSIONS = [
  'bmp',
  'gif',
  'ico',
  'jpe',
  'jpeg',
  'jpg',
  'png',
  'tif',
  'tiff',
] as ReadonlyArray<string>;
const ALLOWED_VIDEO_EXTENSIONS = [
  'asf',
  'asx',
  'avi',
  'divx',
  'flv',
  'm4v',
  'mkv',
  'mov',
  'mp4',
  'mpe',
  'mpeg',
  'mpg',
  'ogv',
  'qt',
  'webm',
  'wm',
  'wmv',
  'wmx',
] as ReadonlyArray<string>;
const ALLOWED_TEXT_EXTENSIONS = [
  'asc',
  'c',
  'cc',
  'css',
  'csv',
  'h',
  'htm',
  'html',
  'ics',
  'rtx',
  'tsv',
  'txt',
  'xml',
] as ReadonlyArray<string>;
const ALLOWED_AUDIO_EXTENSIONS = [
  'm4a',
  'm4b',
  'mid',
  'midi',
  'mka',
  'mp3',
  'oga',
  'ogg',
  'ra',
  'ram',
  'wav',
  'wax',
  'wma',
] as ReadonlyArray<string>;
const ALLOWED_DOCUMENT_EXTENSIONS = [
  '7z',
  'doc',
  'docm',
  'docx',
  'dotm',
  'dotx',
  'key',
  'mdb',
  'mpp',
  'numbers',
  'odb',
  'odc',
  'odf',
  'odg',
  'odp',
  'ods',
  'odt',
  'onepkg',
  'onetmp',
  'onetoc',
  'onetoc2',
  'pages',
  'pdf',
  'pot',
  'pot',
  'potm',
  'potx',
  'ppam',
  'pps',
  'ppsm',
  'ppsx',
  'ppt',
  'pptx',
  'rar',
  'sldm',
  'sldx',
  'tar',
  'wp',
  'wpd',
  'wri',
  'xla',
  'xlam',
  'xls',
  'xlsb',
  'xlsm',
  'xlsm',
  'xlsx',
  'xlt',
  'xltm',
  'xltx',
  'xlw',
  'zip',
] as ReadonlyArray<string>;

// All allowed file extensions
export const ALLOWED_FILE_EXTENSIONS = ALLOWED_IMAGE_EXTENSIONS.concat(
  ALLOWED_VIDEO_EXTENSIONS,
  ALLOWED_TEXT_EXTENSIONS,
  ALLOWED_AUDIO_EXTENSIONS,
  ALLOWED_DOCUMENT_EXTENSIONS
) as ReadonlyArray<string>;

// 20,000kb in bytes (the limit imposed by the backend)
export const MAX_FILE_SIZE = 20000000;

export default function getFileType(extension: string): 'image' | 'video' | 'text' | 'document' | 'audio' | 'invalid' {
  if (!extension) {
    throw new TypeError('Argument `extension` cannot be blank');
  }
  if (extension.includes('.')) {
    throw new TypeError('Argument `extension` cannot contain period characters');
  }
  if (extension.includes(' ')) {
    throw new TypeError('Argument `extension` cannot contain whitespace characters');
  }

  const lowercased = extension.toLowerCase();

  if (ALLOWED_IMAGE_EXTENSIONS.includes(lowercased)) {
    return 'image';
  }

  if (ALLOWED_VIDEO_EXTENSIONS.includes(lowercased)) {
    return 'video';
  }

  if (ALLOWED_TEXT_EXTENSIONS.includes(lowercased)) {
    return 'text';
  }

  if (ALLOWED_DOCUMENT_EXTENSIONS.includes(lowercased)) {
    return 'document';
  }

  if (ALLOWED_AUDIO_EXTENSIONS.includes(lowercased)) {
    return 'audio';
  }

  return 'invalid';
}

export function getFileExtension(fileName: string): string {
  if (!fileName) {
    throw new TypeError('Argument `fileName` cannot be blank');
  }
  if (!fileName.includes('.')) {
    throw new TypeError('Argument `fileName` must contain a period character');
  }

  return String(fileName.split('.').pop()).toLowerCase();
}

export function getByteFormat(bytes: number): string {
  let result = '';
  const byteThreshold = {
    GB: 1073741824,
    MB: 1048576,
    KB: 1024,
  };

  if (bytes >= byteThreshold['GB']) {
    result = `${(bytes / byteThreshold['GB']).toFixed(2)} GB`;
  } else if (bytes >= byteThreshold['MB']) {
    result = `${(bytes / byteThreshold['MB']).toFixed(2)} MB`;
  } else if (bytes >= byteThreshold['KB']) {
    result = `${(bytes / byteThreshold['KB']).toFixed(2)} kB`;
  } else if (bytes < byteThreshold['KB'] && bytes > 0) {
    result = bytes === 1 ? `${bytes} byte` : `${bytes} bytes`;
  } else {
    result = `0 bytes`;
  }

  return result;
}

export const getFileSizeInBytes = (size: number): number => {
  return size / 1000000;
};

/**
 * Reusable global file uploader
 * result.data contains Array<{file_url, ?attachment_id, ...}>, depending on options
 *
 * @param files         Array<File>     the files to upload
 * @param type          String          morphType when storing in database, saves the files in file_upload table and returns the id's
 * @returns {Promise<Object>}
 */
export const uploadFiles = (files: File[], type = null, id = null): Promise<object> => {
  const formData = new FormData();
  Object.entries(files).forEach(([key, f]) => {
    formData.append('file[]', f, f.name);
  });

  const options = [];
  if (type) {
    options.push('type=' + type);
  }
  if (id) {
    options.push('attach_id=' + id); //todo backend attach
  }

  return attachFile(options.join('&'), formData);
};

export const recordToFormData = (record: Record<string, unknown>) =>
  Object.entries(record).reduce((formData, [key, value]) => {
    formData.append(key, JSON.stringify(value));
    return formData;
  }, new FormData());
