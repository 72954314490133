type NotificationType = 'error' | 'warning' | 'notice';

export const showGrowlNotification = (
  type: NotificationType,
  size: number,
  duration: string,
  title: string,
  message: string,
  location: string
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $.growl[type]({
    size: size,
    duration: duration,
    namespace: 'growl',
    title: title,
    location: location,
    message: message,
  });
};
