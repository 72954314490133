<script>
import dompurify from 'dompurify';

import avatar from '../Avatar';
import ContentEditable from '../ContentEditable';
import timeLeftAgo from '../Elements/TimeLeftAgo';

export default {
  name: 'Notes',
  emits: ['input', 'focus'],
  props: {
    initialNotes: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      notes: this.initialNotes,
      newNote: '',
    };
  },

  components: {
    ContentEditable,
    avatar,
    timeLeftAgo,
  },

  watch: {
    initialNotes(notes) {
      this.notes = notes.map((note) => {
        return { ...note, ...{ body: dompurify.sanitize(note.body, { ALLOWED_TAGS: ['br'], ALLOWED_ATTR: [] }) } };
      });
    },
  },

  mounted() {
    this.textareaResize();
  },

  methods: {
    textareaResize() {
      if (this.$refs.noteTextArea?.$el) {
        this.$refs.noteTextArea.$el.style.minHeight = this.$refs.noteTextArea.$el.scrollHeight + 52 + 'px'; // border-bottom = 60px
      }
    },
    onContentEditableSubmit() {
      this.$emit('input', this.newNote);
    },
    onContentEditableFocus() {
      this.$emit('focus');
    },
    onContentEditableBlur(value) {
      this.newNote = value;
    },
  },
};
</script>

<template>
  <div>
    <div v-for="note in notes" class="m-y-1">
      <div class="sidebar_text-muted flex text-sm" style="flex-wrap: nowrap">
        <div class="hidden-md-down p-b-xs mt-auto" style="width: 38px"></div>
        <div style="margin-left: 3px; flex: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          {{ note.user ? note.user.full_name : $t('general.deleted_user') }}
        </div>
        <div style="margin-right: 3px" class="ml-auto">
          <time-left-ago :date="note.created_at" short-text="true"></time-left-ago>
        </div>
      </div>
      <div class="flex">
        <div class="hidden-md-down p-b-xs pr-2" style="margin-top: 7px">
          <avatar
            v-if="note.user === null"
            class="avatar-left"
            :color="'#5bb130'"
            width="30"
            font-size="1em"
            :abbr="'T'"
          />
          <avatar
            v-else
            :color="note.user.color"
            :abbr="note.user.abbr"
            width="30"
            font-size="1em"
            :image="note.user.profile_image"
          />
        </div>
        <div class="b-a-lightblue my-1 overflow-hidden rounded-lg">
          <div
            style="text-overflow: ellipsis"
            class="noteBalloon select-text overflow-hidden rounded-lg bg-white p-2"
            v-html="note.body"
          ></div>
        </div>
      </div>
    </div>
    <div class="m-y-1 flex">
      <div class="hidden-md-down pr-2" style="margin-top: 7px">
        <avatar
          :color="$root.user.color"
          :abbr="$root.user.abbr"
          width="30"
          font-size="1em"
          :image="$root.user.profile_image"
        ></avatar>
      </div>
      <div class="flex-1">
        <div class="relative inline-block w-full">
          <div class="b-a-lightblue rounded-lg">
            <content-editable
              ref="noteTextArea"
              v-model="newNote"
              class="noteTextArea block w-full rounded-lg bg-white p-2"
              :placeholder="placeholder"
              @focus="onContentEditableFocus"
              @enter="onContentEditableSubmit"
              @input="textareaResize"
              @blur="onContentEditableBlur"
            ></content-editable>
          </div>
          <button
            style="bottom: 10px; right: 10px"
            class="success h-40 inline-block-40px absolute rounded-full align-middle font-bold text-white"
            @click="onContentEditableSubmit"
          >
            <i class="material-icons text-center align-middle">arrow_upward</i>
            <span class="sr-only">Send</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.success:hover {
  background-color: #32a665 !important;
}
.noteBalloon {
  padding: 0.5rem 1em;
}
.noteTextArea {
  padding: 0.5rem 1em;
  border-bottom: 52px solid var(--color-white);
  resize: none;
}
</style>
