<script type="text/babel">
import eventBus from '@/eventBus';

import Timezones from './Timezones';
export default {
  name: 'UpdateProfile',
  data() {
    return {
      first_name: this.$root.user.first_name,
      last_name: this.$root.user.last_name,
      profile_image_url: this.$root.user.profile_image,
      profile_image: null,
      locale_code: this.$root.user.locale_code,
      phone: this.$root.user.phone,
      saving: false,
      timezone: this.$root.user.timezone,
      timezones: Timezones,
      user: {},
      custom_fields_mapped: [],
    };
  },

  async mounted() {
    let result = await axios.get('/api/v2/users/' + this.$root.user.id);
    this.user = result.data;

    this.custom_fields_mapped = this.mapCustomFields();
  },

  methods: {
    storeProfile() {
      if (this.saving == true) {
        return;
      }
      this.saving = true;
      let data = new FormData();
      data.append('first_name', this.first_name);
      data.append('last_name', this.last_name);
      data.append('timezone', this.timezone);
      data.append('locale_code', this.locale_code);
      data.append('file', this.profile_image);
      data.append('custom_fields_mapped', JSON.stringify(this.custom_fields_mapped));

      if (this.phone) {
        data.append('phone', this.phone);
      }

      axios
        .post('/client-api/me/profile', data)
        .then((res) => {
          // optimize: send in 1 request
          if (res.data.profile_image) {
            eventBus.$emit('user-profile-image-set');
          }
          if (res.data.phone) {
            eventBus.$emit('user-phone-number-set');
          }
          if (this.$root.user.timezone !== res.data.timezone || this.$root.user.locale_code !== res.data.locale_code) {
            // Refresh required when timezone or language changes
            window.location.reload();
          }
          this.$root.user.first_name = res.data.first_name;
          this.$root.user.last_name = res.data.last_name;
          this.$root.user.full_name = res.data.first_name + ' ' + res.data.last_name;
          this.$root.user.phone = res.data.phone;
          this.$root.user.abbr = res.data.abbr;
          this.$root.user.profile_image = res.data.profile_image;
          this.profile_image_url = res.data.profile_image;
          this.locale_code = res.data.locale_code;
          this.profile_image = null;
          this.saving = false;
          this.flashSuccess(this.$t('user_profile.overview_profile_saved_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },
    onFileChange(event) {
      this.profile_image = event.target.files[0];
    },
    async deleteImage() {
      const shouldDeleteImage = await this.$tConfirm(this.$t('user_profile.delete_profile_image_description'), {
        delete: true,
        title: this.$t('user_profile.overview_are_you_sure'),
      });
      if (shouldDeleteImage) {
        axios.delete('/client-api/me/avatar');
        this.$root.user.profile_image = null;
        this.profile_image_url = null;
        this.flashSuccess(this.$t('user_profile.image_deleted_successfully'));
      }
    },

    /*
            @return [
                {title: <field title>, id: <custom field id>, value: <custom user field value, if not set: ''>}
            ]
            */
    mapCustomFields() {
      return (this.$root.customUserFields || []).map((field) => {
        let userField = this.user.custom_fields.find((userField) => {
          return userField.id === field.id;
        });

        let value = '';

        if (userField) {
          value = userField.pivot.value;
        }

        return { title: field.title, id: field.id, value: value };
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header b-b">
          <h2>{{ $t('user_profile.general_overview') }}</h2>
        </div>
        <div class="box-body">
          <form @submit.prevent="storeProfile">
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.overview_first_name') }}</label>
              <div class="col-md-9">
                <input
                  v-model="first_name"
                  class="form-control"
                  :placeholder="$t('user_profile.overview_first_name')"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.overview_last_name') }}</label>
              <div class="col-md-9">
                <input
                  v-model="last_name"
                  class="form-control"
                  :placeholder="$t('user_profile.overview_last_name')"
                  type="text"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.overview_profile_image') }}</label>
              <div class="col-md-9">
                <input type="file" class="form-control" accept="image/*" @change="onFileChange" />
                <div v-show="profile_image_url != null">
                  <img :src="profile_image_url" width="75" class="img-circle" style="margin-top: 10px" />
                  <a style="display: block; margin-top: 10px" class="text-danger" @click="deleteImage">Delete image</a>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.overview_language') }}</label>
              <div class="col-md-9">
                <select v-model="locale_code" class="form-control">
                  <option v-for="(name, code) in $root.localeCodes" :value="code">{{ name }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.overview_phone_number') }}</label>
              <div class="col-md-9">
                <tel-input v-model="phone"></tel-input>
                <span class="form-text">{{ $t('user_profile.hint_numeric_international_format') }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 form-control-label">{{ $t('user_profile.overview_timezone') }}</label>
              <div class="col-md-9">
                <select v-model="timezone" class="form-control">
                  <option v-for="timezone in timezones" :value="timezone">{{ timezone }}</option>
                </select>
              </div>
            </div>
            <div v-for="field in custom_fields_mapped">
              <div class="form-group row">
                <label class="col-md-3 form-control-label">{{ field.title }}</label>
                <div class="col-md-9">
                  <input
                    v-model="field.value"
                    class="form-control"
                    :placeholder="field.title"
                    :data-id="field.id"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3 offset-md-3">
                <button type="submit" class="btn success" :disabled="saving">
                  <span v-if="!saving">{{ $t('user_profile.overview_save_profile') }}</span>
                  <span v-if="saving"><i class="fa fa-spin fa-spinner"></i></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
