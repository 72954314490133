<template>
  <sidebar-dropdown
    :title="$tc('ticket_sidebar.conversation')"
    collapse-key="conversationDropdown"
    data-test="conversation-dropdown-wrapper"
  >
    <watcher-display v-if="hasWatchers || hasAgent" :ticket="ticket" />

    <h2 class="t-text-sm t-text-grey-800 mt-3 first:mt-0" data-test="conversation-dropdown-channel-title">
      {{ $tc('ticket_sidebar.channel') }}
    </h2>
    <div class="flex flex-row items-center">
      <channel-icon
        class="mr-2"
        :channel="ticket.channel.type"
        :style="{ color: ticket.channel.color }"
        data-test="conversation-channel-icon"
      />

      <router-link
        v-if="hasChannelsManagePermission"
        class="m-0 text-ellipsis p-0 capitalize text-grey-800 underline"
        :to="`/admin/channels2/${ticket.channel.type.toLowerCase()}/${ticket.channel.id}`"
        data-test="channel-link"
      >
        {{ ticket.channel.text }}
      </router-link>

      <p v-else class="m-0 text-ellipsis p-0 capitalize text-grey-800" data-test="channel-title">
        {{ ticket.channel.text }}
      </p>
    </div>

    <h2 class="t-text-sm t-text-grey-800 mt-3" data-test="ticket-status-text">
      {{ $tc('tickets.status') }}:
      <span class="font-bold">{{ $tc(`general.status_${ticket.status.toLowerCase()}`) }}</span>
    </h2>

    <h2 v-if="doTicketResultsExist" class="t-text-sm t-text-grey-800 mt-3" data-test="ticket-results">
      {{ $tc('tickets.ticket_result') }}:
      <span v-if="ticket.results.length > 0" class="font-bold">{{ ticket.results[0].name }}</span>
      <span v-else>
        <em>{{ $tc('general.no_results') }}</em>
      </span>
    </h2>
  </sidebar-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ChannelIcon from '@/components/ChannelIcon.vue';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import WatcherDisplay from '@/components/TicketSidebar/WatcherDisplay.vue';
import { PERMISSION } from '@/Configs/Constants';

import type Tickets from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ConversationDropdown',

  components: {
    ChannelIcon,
    WatcherDisplay,
    SidebarDropdown,
  },

  props: {
    ticket: {
      type: Object as PropType<Tickets>,
      default: () => ({}),
    },
  },

  computed: {
    hasWatchers() {
      return this.ticket.watchers && this.ticket.watchers.length !== 0;
    },

    hasAgent() {
      return this.ticket.agent && this.ticket.agent.id !== this.$root?.user?.id;
    },

    hasChannelsManagePermission() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE);
    },

    doTicketResultsExist() {
      return this.$root?.ticketResults?.length > 0;
    },
  },
});
</script>
