import { debounce } from 'lodash';

import { vm } from '@/ApplicationHandler';
import { PRICING_MODEL } from '@/Configs/Constants';

const checkPricingModelForQuarterly = async () => {
  await new Promise(ensureCompanyProfileIsFetched);

  return vm && vm.$root ? vm.$root['companyProfile']?.pricing_model : PRICING_MODEL.USAGE_BASED;
};

const ensureCompanyProfileIsFetched = async (resolve: (value?: unknown) => void) => {
  if (vm?.$root && vm.$root['companyProfile']?.agency) {
    resolve();
    return;
  }

  const debounced = debounce(function () {
    ensureCompanyProfileIsFetched(resolve);
  }, 30);

  debounced();
};

export default checkPricingModelForQuarterly;
