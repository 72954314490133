<template>
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-stroke">
      <circle
        cx="12"
        cy="12"
        r="10.25"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
      <path
        d="M10.5 10.5L15.5 8.5L13.5 13.5L8.5 15.5L10.5 10.5Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="svg-stroke"
      />
    </svg>

    <span class="icon-active">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_9260_95551" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM16.1964 8.77854C16.3078 8.49998 16.2425 8.18182 16.0304 7.96967C15.8182 7.75752 15.5 7.69222 15.2215 7.80364L10.2215 9.80364C10.0309 9.87987 9.8799 10.0309 9.80366 10.2215L7.80366 15.2215C7.69224 15.5 7.75754 15.8182 7.96969 16.0303C8.18184 16.2425 8.5 16.3078 8.77856 16.1964L13.7786 14.1964C13.9691 14.1201 14.1201 13.9691 14.1964 13.7785L16.1964 8.77854Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM16.1964 8.77854C16.3078 8.49998 16.2425 8.18182 16.0304 7.96967C15.8182 7.75752 15.5 7.69222 15.2215 7.80364L10.2215 9.80364C10.0309 9.87987 9.8799 10.0309 9.80366 10.2215L7.80366 15.2215C7.69224 15.5 7.75754 15.8182 7.96969 16.0303C8.18184 16.2425 8.5 16.3078 8.77856 16.1964L13.7786 14.1964C13.9691 14.1201 14.1201 13.9691 14.1964 13.7785L16.1964 8.77854Z"
          fill="white"
        />
        <path
          d="M16.1964 8.77854L17.5891 9.33563L17.5891 9.33563L16.1964 8.77854ZM15.2215 7.80364L14.6644 6.41093L15.2215 7.80364ZM9.80366 10.2215L8.41095 9.66437L9.80366 10.2215ZM7.80366 15.2215L6.41095 14.6644L7.80366 15.2215ZM8.77856 16.1964L9.33565 17.5891L9.33565 17.5891L8.77856 16.1964ZM13.7786 14.1964L13.2215 12.8036L13.7786 14.1964ZM14.1964 13.7785L12.8037 13.2215L14.1964 13.7785ZM21.5 12C21.5 17.2467 17.2467 21.5 12 21.5V24.5C18.9036 24.5 24.5 18.9036 24.5 12H21.5ZM12 2.5C17.2467 2.5 21.5 6.75329 21.5 12H24.5C24.5 5.09644 18.9036 -0.5 12 -0.5V2.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5V-0.5C5.09644 -0.5 -0.5 5.09644 -0.5 12H2.5ZM12 21.5C6.75329 21.5 2.5 17.2467 2.5 12H-0.5C-0.5 18.9036 5.09644 24.5 12 24.5V21.5ZM14.9697 9.03033C14.7575 8.81818 14.6922 8.50002 14.8037 8.22146L17.5891 9.33563C17.9234 8.49994 17.7275 7.54545 17.091 6.90901L14.9697 9.03033ZM15.7786 9.19636C15.5 9.30778 15.1818 9.24248 14.9697 9.03033L17.091 6.90901C16.4546 6.27257 15.5001 6.07665 14.6644 6.41093L15.7786 9.19636ZM10.7786 11.1964L15.7786 9.19636L14.6644 6.41093L9.66439 8.41093L10.7786 11.1964ZM11.1964 10.7785C11.1201 10.9691 10.9691 11.1201 10.7786 11.1964L9.66439 8.41093C9.09266 8.63962 8.63964 9.09264 8.41095 9.66437L11.1964 10.7785ZM9.19638 15.7785L11.1964 10.7785L8.41095 9.66437L6.41095 14.6644L9.19638 15.7785ZM9.03035 14.9697C9.2425 15.1818 9.30781 15.5 9.19638 15.7785L6.41095 14.6644C6.07667 15.5001 6.27259 16.4545 6.90903 17.091L9.03035 14.9697ZM8.22148 14.8036C8.50004 14.6922 8.8182 14.7575 9.03035 14.9697L6.90903 17.091C7.54547 17.7274 8.49996 17.9233 9.33565 17.5891L8.22148 14.8036ZM13.2215 12.8036L8.22148 14.8036L9.33565 17.5891L14.3357 15.5891L13.2215 12.8036ZM12.8037 13.2215C12.8799 13.0309 13.0309 12.8799 13.2215 12.8036L14.3357 15.5891C14.9074 15.3604 15.3604 14.9074 15.5891 14.3356L12.8037 13.2215ZM14.8037 8.22146L12.8037 13.2215L15.5891 14.3356L17.5891 9.33563L14.8037 8.22146Z"
          fill="white"
          mask="url(#path-1-inside-1_9260_95551)"
        />
      </svg>
    </span>
  </span>
</template>
