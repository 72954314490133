<template>
  <div class="row-col">
    <div class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.name"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('labels.label') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('labels.name') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.name" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('labels.color') }}</label>
                      <div class="col-sm-10">
                        <compact-picker
                          v-model="record.color"
                          :palette="colors"
                          @update:model-value="updateColorValue"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('labels.channels') }}</label>
                      <div class="col-sm-10">
                        <multiselect
                          v-model="record.channels"
                          label="text"
                          track-by="id"
                          :allow-empty="true"
                          :options="$root.channels"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          :multiple="true"
                          :placeholder="$t('labels.select_one_or_more_channels')"
                        ></multiselect>
                        <span class="form-text">
                          {{ $t('labels.the_channels_for_this_label_leave_empty_to_use_on_all_channels') }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('labels.sort_order') }}</label>
                      <div class="col-sm-10">
                        <input v-model="record.sort_order" type="number" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label" for="archiveCheck">
                        {{ $t('labels.archive_this_label') }}
                      </label>
                      <div class="col-sm-10">
                        <label classsandboxd8b28811a36846bd86f86a29047c4250.mailgun.org="ui-switch success m-t-xs m-r">
                          <input id="archiveCheck" v-model="record.archived" type="checkbox" :true-value="1" />
                          <i></i>
                        </label>
                        <span class="form-text">
                          {{ $t('labels.archived_labels_dont_show_up_when_assigning_a_label_to_a_ticket') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready_to_save"
                      v-text="saving ? this.$t('labels.saving') : this.$t('labels.save_changes')"
                    ></button>
                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteLabel()"
                    >
                      {{ $t('labels.delete') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { Compact } from '@ckpack/vue-color';
import { map } from 'lodash';
import Multiselect from 'vue-multiselect';

import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';

export default {
  name: 'EditLabel',
  emits: ['call-loadlist-method'],
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  components: {
    'compact-picker': Compact,
    Multiselect,
  },

  computed: {
    ready_to_save() {
      if (this.record.name == '' || this.record.color == '') {
        return;
      }

      return true;
    },
  },

  data() {
    return {
      record: {
        name: '',
        color: '',
      },
      saving: false,
      colors: [
        '#5D9CEC',
        '#4FC1E9',
        '#48CFAD',
        '#A0D468',
        '#FFCE54',
        '#FC6E51',
        '#ED5565',
        '#AC92EC',
        '#EC87C0',
        '#E6E9ED',
        '#AAB2BD',
        '#434A54',
      ],
      channels: [],
    };
  },

  mounted() {
    if (this.action == 'edit') {
      axios.get('/api/v2/labels/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        this.initWarning();
      });
    } else {
      this.record.color = this.getRandomColor();
      this.initWarning();
    }
  },

  methods: {
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.action == 'create') {
        return this.storeLabel();
      } else {
        this.updateLabel();
      }
    },

    storeLabel() {
      axios
        .post('/api/v2/labels', { ...{ channel_ids: this.mapChannelIds() }, ...this.record })
        .then((res) => {
          this.saving = false;
          this.initWarning();
          this.$emit('call-loadlist-method');
          this.$nextTick(() => router.push('/admin/labels/' + res.data.id));
          this.flashSuccess(this.$t('labels.the_label_is_created'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateLabel() {
      axios
        .put('/api/v2/labels/' + this.record.id, { ...{ channel_ids: this.mapChannelIds() }, ...this.record })
        .then((res) => {
          this.saving = false;
          this.$emit('call-loadlist-method');
          this.flashSuccess(this.$t('labels.the_label_is_updated'));
          this.initWarning();
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteLabel() {
      const shouldDeleteLabel = await this.$tConfirm(this.$t('labels.are_you_sure_you_want_to_delete_this_label'), {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteLabel) {
        this.initWarning(false);

        axios.delete('/api/v2/labels/' + this.record.id).then(() => {
          this.$emit('call-loadlist-method');
          router.push('/admin/labels/create');
          this.flashSuccess(this.$t('labels.the_label_is_deleted'));
        });
      }
    },

    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },

    updateColorValue(color) {
      this.record.color = color.hex;
    },

    mapChannelIds() {
      return map(this.record.channels, 'id');
    },
  },
};
</script>
