<template>
  <dropdown :search="false" :options="options" width="auto" max-width="300px" min-width="250px" @input="onInput">
    <template #heading>
      <div class="flex items-center p-2">
        <div class="pr-2">
          <span @click="openContact(contact.id)">
            <avatar
              :color="contact.color"
              :abbr="contact.abbr"
              width="30"
              font-size="12px"
              :image="contact.profile_image"
            ></avatar>
          </span>
        </div>
        <div>
          <strong @click="openContact(contact.id)">{{ contact.full_name }}</strong>
          <div class="text-xs">{{ contact.formatted_phone }}</div>
        </div>
      </div>
    </template>
    <template #toggle>
      <span class="font-weight-normal flex-1 cursor-pointer text-ellipsis hover:text-grey-800">
        <i class="fa fa-phone pr-1"></i>
        {{ contact.formatted_phone }}
      </span>
    </template>
  </dropdown>
</template>

<script type="text/babel">
import eventBus from '@/eventBus';

import Avatar from './Avatar';
import Dropdown from './ReplyForm/Dropdown';

export default {
  name: 'PhoneDropdown',
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    voip: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Dropdown,
    Avatar,
  },

  computed: {
    options() {
      let list = [];
      if (this.voip) {
        list.push({
          icon: 'call',
          id: 'call',
          text: this.$t('general.call_contact'),
        });
      }

      list.push({
        icon: 'person',
        id: 'open_contact',
        text: this.$t('general.show_contact'),
      });
      list.push({
        icon: 'search',
        id: 'search',
        text: this.$t('general.search') + " '" + this.contact.formatted_phone + "'",
      });

      list.push({
        icon: 'filter_none',
        id: 'copy',
        text: this.$t('general.copy') + " '" + this.contact.formatted_phone + "'",
      });

      return list;
    },
  },

  methods: {
    onInput(input) {
      let phone = this.contact.formatted_phone.replace(/ /g, '');
      switch (input.id) {
        case 'open_contact':
          router.push('/contacts/' + this.contact.id);
          break;

        case 'call':
          eventBus.$emit('voip.call', {
            phone: '+' + this.contact.phone,
          });
          break;

        case 'search':
          eventBus.$emit('tickets.search', phone);
          break;

        case 'copy':
          window.copyToClipboard(phone);
          break;
      }
    },
  },
};
</script>
