import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-50b2631e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full overflow-y-scroll" }
const _hoisted_2 = {
  key: 0,
  class: "hidden-md-down"
}
const _hoisted_3 = {
  key: 0,
  class: "flex h-full flex-col items-start pl-20 pt-20"
}
const _hoisted_4 = { class: "text-4xl font-bold" }
const _hoisted_5 = { class: "mt-3 w-max rounded-3xl bg-grey-400 px-4 py-2" }
const _hoisted_6 = { class: "m-0 text-sm font-semibold" }
const _hoisted_7 = { class: "mt-6 flex items-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "ml-3 font-medium" }
const _hoisted_10 = { class: "mt-6 flex items-center" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "ml-3 font-medium" }
const _hoisted_13 = { class: "mt-6 flex items-center" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "ml-3 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_preview_card = _resolveComponent("mobile-preview-card")!
  const _component_t_feature_icon = _resolveComponent("t-feature-icon")!
  const _component_atomic_button = _resolveComponent("atomic-button")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_custom_fields_list = _resolveComponent("custom-fields-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mobile_preview_card, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('general.feature_only_available_on_desktop')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    (!_ctx.customFields || !_ctx.customFields.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.customFieldsLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_t_feature_icon, {
                  icon: _ctx.FEATURE_ICON.CUSTOM_FIELDS,
                  class: "mb-8"
                }, null, 8 /* PROPS */, ["icon"]),
                _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('custom_fields.custom_fields')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('custom_fields.takes_less_than_1_min')), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: `${_ctx.$root.assetsURL}img/check-dark.svg`,
                    class: "custom-field-list-icon"
                  }, null, 8 /* PROPS */, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('custom_fields.collect_customer_info')), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: `${_ctx.$root.assetsURL}img/check-dark.svg`,
                    class: "custom-field-list-icon"
                  }, null, 8 /* PROPS */, _hoisted_11),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('custom_fields.add_more_data')), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("img", {
                    src: `${_ctx.$root.assetsURL}img/check-dark.svg`,
                    class: "custom-field-list-icon"
                  }, null, 8 /* PROPS */, _hoisted_14),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('custom_fields.organize_and_prioritize_conversations')), 1 /* TEXT */)
                ]),
                _createVNode(_component_atomic_button, {
                  size: "md",
                  class: "mt-10 block",
                  onClick: _ctx.goToCustomField
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('custom_fields.add_custom_field')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : (Object.keys(_ctx.$route.params).length > 0)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        : (_openBlock(), _createBlock(_component_custom_fields_list, {
            key: 2,
            "custom-contact-fields": _ctx.customContactFields,
            "custom-profile-fields": _ctx.customProfileFields,
            "custom-ticket-fields": _ctx.customTicketFields,
            "custom-user-fields": _ctx.customUserFields,
            class: "hidden-md-down"
          }, {
            default: _withCtx(() => [
              (_ctx.customFieldsLoaded)
                ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["custom-contact-fields", "custom-profile-fields", "custom-ticket-fields", "custom-user-fields"]))
  ]))
}