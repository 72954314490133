<template>
  <div v-if="loaded" class="row-col">
    <div v-if="!isFeatureFlagEnabled" class="nav-settings light left b-primary">
      <ul>
        <li class="text-md white header">
          <span class="text-ellipsis" v-text="record.title"></span>
        </li>
      </ul>
    </div>
    <div class="row-row">
      <div class="row-body scrollable">
        <div class="row-inner">
          <back-header
            v-if="isFeatureFlagEnabled"
            class="mb-4 mt-6 px-6 xs:pl-6 xs:pr-0"
            :title="record.title"
            @click="handleBackButtonClick"
          />
          <div class="p-a p-b-0 container">
            <div class="p-a">
              <form @submit.prevent="save">
                <div class="box">
                  <div class="box-header">
                    <h2>{{ $t('auto_replies.auto_reply') }}</h2>
                  </div>
                  <div class="box-divider m-a-0"></div>
                  <div class="box-body">
                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">
                        {{ $t('auto_replies.title') }}
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input v-model="record.title" type="text" autocomplete="off" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-xs-12 form-control-label">{{ $t('auto_replies.channels') }}</label>
                      <div class="col-xs-10 col-sm-10">
                        <multiselect
                          v-model="record.channels"
                          :options="channels"
                          :placeholder="$t('auto_replies.select_channels_for_auto_reply_placeholder')"
                          :multiple="true"
                          label="title"
                          track-by="id"
                        >
                          <!--<template slot="option" slot-scope="props">-->
                          <!--<i class="fa fa-fw"-->
                          <!--:class="getChannelIcon(props.option.type)"-->
                          <!--:style="getChannelIconColor(props.option.type)"></i>-->
                          <!--<span>{{props.option.title}}</span>-->
                          <!--</template>-->
                        </multiselect>
                        <br />
                        <div v-for="(group, i) in daysDataArray" :key="i">
                          <strong class="text-green">
                            {{ $t('auto_replies.business_hours_group_name', { group_name: group.name }) }}
                          </strong>
                          <span
                            v-for="(channel, j) in filterSelectedChannels(group.channels).slice(0, 3)"
                            :key="j"
                            class="ml-1"
                          >
                            {{ channel.text }}
                          </span>
                          <label
                            v-show="filterSelectedChannels(group.channels).length > 3"
                            v-tooltip="getTooltip(group)"
                            class="label label-default ml-1"
                          >
                            +{{ filterSelectedChannels(group.channels).length - 3 }}
                          </label>

                          <div class="mb-4 mt-2 rounded-lg border bg-grey-200 p-3">
                            <div v-for="(day, j) in group.days" :key="j" class="flex pb-1">
                              <div class="" style="width: 75px">
                                <strong>{{ daysOfWeek[day.day_of_week] }}</strong>
                              </div>
                              <div class="col">
                                <template v-if="day.is_open">
                                  <span v-for="(slot, k) in day.slots" :key="k">
                                    <span v-if="k > 0">&</span>
                                    {{ slot.start.substr(0, 5) }} - {{ slot.end.substr(0, 5) }}
                                  </span>
                                </template>
                                <template v-else>
                                  <span v-for="(slot, k) in day.slots" :key="k">
                                    <em class="text-muted">closed</em>
                                  </span>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--<div class="col-xs-2">-->
                      <!--<label for="allChannels" class="all btn btn-sm btn-default btn-block text-ellipsis">-->
                      <!--<label class="md-check sm-check">-->
                      <!--<input type="checkbox" id="allChannels"-->
                      <!--:disabled="(!record.channels || !record.channels.length) && !previousChannels.length"-->
                      <!--@change="allChannelsChanged"-->
                      <!--:checked="!record.channels || !record.channels.length">-->
                      <!--<i class="success"></i>-->
                      <!--All channels-->
                      <!--</label>-->
                      <!--</label>-->
                      <!--</div>-->
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('auto_replies.opening_message') }}</label>
                      <div class="col-sm-10">
                        <textarea
                          v-model="record.open_message"
                          rows="4"
                          autocomplete="off"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('auto_replies.closing_message') }}</label>
                      <div class="col-sm-10">
                        <textarea
                          v-model="record.closed_message"
                          rows="4"
                          autocomplete="off"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label">{{ $t('auto_replies.customize_days') }}</label>
                      <div class="col-sm-10 mt-2">
                        <label class="ui-switch success m-r">
                          <input v-model="customDays" type="checkbox" />
                          <i></i>
                        </label>
                        <span class="form-text">
                          {{
                            $t(
                              'auto_replies.select_this_option_if_you_want_to_specify_days_on_which_this_auto_reply_is_active'
                            )
                          }}
                        </span>
                      </div>
                    </div>

                    <div v-show="customDays" class="form-group row" style="margin-top: -10px">
                      <label class="col-sm-2 form-control-label"></label>
                      <div class="col-sm-10">
                        <label
                          v-for="(day, index) in daysOfWeek"
                          :key="index"
                          :for="day"
                          class="day btn btn-sm btn-default"
                        >
                          <label class="md-check sm-check">
                            <input :id="day" v-model="record.days[index]" type="checkbox" />
                            <i class="success"></i>
                            {{ day }}
                          </label>
                        </label>
                        <span class="form-text">
                          {{ $t('auto_replies.specify_the_days_you_want_to_use_this_auto_reply') }}
                        </span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2 form-control-label" for="archiveCheck">
                        {{ $t('auto_replies.archive_this_auto_reply') }}
                      </label>
                      <div class="col-sm-10">
                        <label class="ui-switch success m-t-xs m-r">
                          <input id="archiveCheck" v-model="record.archived" type="checkbox" :true-value="1" />
                          <i></i>
                        </label>
                        <span class="form-text">{{ $t('auto_replies.archived_auto_replies_will_not_be_used') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-body flow-root">
                    <button
                      type="submit"
                      :class="{ loader: saving }"
                      class="btn success text-white"
                      :disabled="saving || !ready"
                      v-text="saving ? this.$t('auto_replies.saving') : this.$t('auto_replies.save_changes')"
                    ></button>

                    <button
                      v-if="action == 'edit'"
                      type="button"
                      class="btn btn-link text-muted pull-right"
                      @click="deleteAutoReply()"
                    >
                      {{ $t('auto_replies.delete_auto_reply') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.day,
.all {
  background-color: rgba(158, 158, 158, 0.2);
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 5px;

  input {
    margin: 0 0 2px 0;
    vertical-align: middle;
  }
}

.all {
  margin: 0;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
}
</style>

<script type="text/babel">
import { join, includes, map, toArray } from 'lodash';
import Multiselect from 'vue-multiselect';

import { FEATURE_FLAG_SETTINGS } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';

import RouterWarningOnEdit from '../../mixins/RouterWarningOnEdit';
import ChannelTypes from '../ChannelTypes';
import BackHeader from '../common/PageHeaders/BackHeader';

export default {
  name: 'AutoReplyEdit',
  props: {
    action: {
      type: String,
      default: 'create',
    },
  },

  mixins: [RouterWarningOnEdit],

  components: {
    Multiselect,
    BackHeader,
  },

  computed: {
    isFeatureFlagEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_SETTINGS.TA_SETTINGS_AUTOREPLY);
    },
    daysDataArray() {
      return !this.record.archived ? this.daysData : [];
    },
    ready() {
      if (this.record.title == '' || this.record.message == '') {
        return;
      }

      return true;
    },
  },

  watch: {
    'record.channels'() {
      if (!this.loaded) return;
      let ids = this.record.channels.filter((c) => {
        return c.business_hour_id !== null;
      });

      if (ids.length === 0) {
        ids = this.channels.filter((c) => {
          return c.business_hour_id !== null;
        });
      }

      axios.get('/api/v2/business_hours?ids=' + map(ids, 'business_hour_id')).then((res) => {
        this.daysData = res.data.data;
      });
    },

    customDays(v) {
      if (!v) {
        this.record.days = Array(7).fill(true);
      }
    },
  },

  data() {
    return {
      daysOfWeek: moment.weekdaysShort(),
      previousChannels: [],
      loaded: false,
      channels: [],
      record: {
        title: this.$t('auto_replies.auto_reply'),
        channels: [],
        open_message: '',
        closed_message: '',
        days: Array(7).fill(true),
        archived: 0,
      },
      saving: false,
      customDays: false,
      daysData: [],
    };
  },

  mounted() {
    this.loadAsyncData();

    if (this.action === 'edit') {
      axios.get('/api/v2/auto_reply_sets/' + this.$route.params.id).then((result) => {
        this.record = result.data;
        if (!this.record.days || !this.record.days.length) {
          this.record.days = Array(7).fill(true);
        }
        this.afterRecordLoaded();
      });
    } else {
      this.afterRecordLoaded();
    }
  },

  methods: {
    handleBackButtonClick() {
      this.$router.push('/admin/auto_replies/');
      eventBus.$emit('reload-auto-replies');
    },
    afterRecordLoaded() {
      this.customDays = !isEqualObject(this.record.days, Array(7).fill(true));
      this.$nextTick(() => {
        this.initWarning();
      });
    },

    allChannelsChanged(e) {
      if (e.target.checked) {
        this.previousChannels = this.record.channels;
        this.record.channels = [];
      } else {
        this.record.channels = this.previousChannels;
      }
    },
    arrayToQueryString(inputArray, paramName) {
      return inputArray.map((item) => paramName + '[]=' + encodeURIComponent(item)).join('&');
    },

    getChannelIcon(channelType) {
      const item = ChannelTypes[channelType];
      return item.icon || ChannelTypes.defaultIcon;
    },
    getChannelIconColor(channelType) {
      const item = ChannelTypes[channelType];
      return { color: item.iconColor || ChannelTypes.defaultColor };
    },

    loadAsyncData() {
      this.channels = this.$root.channels.filter((item) => {
        if (item.smsChannel && item.smsChannel.type === 'SEND_ONLY') {
          return false;
        }
        if (item.type === 'EMAIL' || item.type === 'VOIP' || item.type === 'INSTAGRAM') {
          return false;
        }
        return true;
      });
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      this.record.channel_ids = toArray(map(this.record.channels, 'id'));

      if (this.action === 'create') {
        return this.storeAutoReply();
      } else {
        this.updateAutoReply();
      }
    },

    storeAutoReply() {
      axios
        .post('/api/v2/auto_reply_sets', this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning(false);
          if (!this.isFeatureFlagEnabled) {
            eventBus.$emit('reload-auto-replies');
          }
          this.$router.push('/admin/auto_replies/' + res.data.id);
          this.flashSuccess(this.$t('auto_replies.the_message_has_been_created_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    updateAutoReply() {
      axios
        .put('/api/v2/auto_reply_sets/' + this.record.id, this.record)
        .then((res) => {
          this.saving = false;
          this.initWarning();
          if (!this.isFeatureFlagEnabled) {
            eventBus.$emit('reload-auto-replies');
          }
          this.flashSuccess(this.$t('auto_replies.the_message_has_been_updated_successfully'));
        })
        .catch(() => {
          this.saving = false;
        });
    },

    async deleteAutoReply() {
      const shouldDeleteAutoreply = await this.$tConfirm(
        this.$t('auto_replies.are_you_sure_you_want_to_delete_this_auto_reply'),
        {
          delete: true,
          title: this.$t('general.are_you_sure'),
        }
      );
      if (shouldDeleteAutoreply) {
        axios.delete('/api/v2/auto_reply_sets/' + this.record.id).then(() => {
          this.initWarning(false);
          if (!this.isFeatureFlagEnabled) {
            eventBus.$emit('reload-auto-replies');
          } else {
            eventBus.$emit('reload-auto-replies');
          }
          this.$router.push('/admin/auto_replies');
          this.flashSuccess(this.$t('auto_replies.the_message_has_been_deleted_successfully'));
        });
      }
    },

    getTooltip(day) {
      return {
        content: join(map(this.filterSelectedChannels(day.channels), 'text'), ', '),
        placement: 'bottom',
        distance: 5,
        delay: { show: 0 },
        popperClass: 'tooltip-medium',
      };
    },

    filterSelectedChannels(list) {
      if (this.record.channels.length === 0) {
        return list;
      }

      return list.filter((c) => {
        return includes(map(this.record.channels, 'id'), c.id);
      });
    },
  },
};
</script>
