import _ from 'lodash';
import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { generateDangerZoneToken } from '@/api';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { LOCAL_STORAGE } from '@/Configs/Constants/localStorage';
import { AUTH_URL } from '@/Configs/Constants/navigation/authUrl';
import { checkAuthentication } from '@/middleware/auth';
import { checkEntitlements } from '@/middleware/entitlements';
import { useRoutesStore } from '@/store/pinia/routes';
import { trackPageWithSegmentAnalytics } from '@/util/analytics/segmentAnalytics';
import { flashError } from '@/util/errorHelper';

import admin from './admin/admin';
import dashboard from './dashboard';
import store from '../store/index';

const EmptyComponent = { render: () => h('div') };

let routes = [
  {
    path: AUTH_URL.LOGIN,
    name: 'login',
    props: true,
    component: () => import(/* webpackChunkName: "Login" */ '../components/User/Login'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/reset-password/request-email',
    name: 'request-email',
    props: true,
    component: require('../components/User/RequestEmail').default,
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/reset-password/reset-password/:token',
    name: 'reset-password',
    component: require('../components/User/ResetPassword').default,
    meta: { allowUnauthenticated: true },
  },
  {
    path: AUTH_URL.PASSWORD,
    name: 'auth-password',
    component: require('../components/User/SetupPassword').default,
  },
  {
    path: AUTH_URL.SETUP_2FA,
    name: 'setup-2fa',
    component: require('../components/User/SetupTwoFactorAuth').default,
  },
  {
    path: '/composer/:ticketId?',
    component: require('../components/ComposerPopout').default,
  },
  {
    path: '/',
    component: require('../components/Dashboard').default,
    children: [...dashboard, ...admin],
  },
  {
    path: '/verification',
    beforeEnter: async (to, _from, next) => {
      if (!to.query.secret) {
        console.error('No secret provided for verification');
        next('/');
        return;
      }
      try {
        const {
          data: { token },
        } = await generateDangerZoneToken(to.query.secret);
        localStorage.setItem(LOCAL_STORAGE.DANGER_ZONE_TOKEN, token);
      } catch (error) {
        console.error(error);
        flashError(error?.response?.data?.message || error?.message || 'An error occurred');
      } finally {
        next('/');
      }
    },
    component: EmptyComponent, // necessary for beforeEnter to work
  },
];

let router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach(async (to, from, next) => {
  await checkAuthentication(to, from, next);
  await checkEntitlements(to, from, next);
  const user = store.getters['initialData/user'];
  const shouldRedirectToMFASetup = store.getters['initialData/shouldRedirectToMFASetup'];
  if (user?.needs_new_pw && to.path !== AUTH_URL.PASSWORD) {
    next(AUTH_URL.PASSWORD);
    return;
  }
  if (shouldRedirectToMFASetup && to.path !== AUTH_URL.SETUP_2FA) {
    next(AUTH_URL.SETUP_2FA);
    return;
  }

  const deprecatedChannels = [CHANNEL_TYPE.TWITTER, CHANNEL_TYPE.WECHAT];
  const isChannelDeprecated = deprecatedChannels.some(
    (channel) => channel.toLowerCase() === to.params.channel?.toLowerCase()
  );

  if (isChannelDeprecated) {
    next('/tickets');
    return;
  }

  next();
});

router.afterEach((to, from) => {
  const user = store.getters['initialData/user'];
  const fullPath = to.fullPath;
  const routesStore = useRoutesStore();

  ga('set', 'page', to.path);
  ga('send', 'pageview');

  if (typeof Appcues !== 'undefined') {
    setTimeout(() => Appcues.page(), 1000);
  }

  trackPageWithSegmentAnalytics({ user, route: fullPath });

  routesStore.setLastRoutePath(from.path);
  routesStore.setCurrentRoutePath(to.path);
  // Remove modal backdrop
  $('.modal-backdrop').remove();
});

export default router;
