<template>
  <div>
    <div class="flex flex-col">
      <t-badge
        :text="$t('entitlement.upgrade_to_plan_long', { plan: plan })"
        variant="upgrade"
        class="mb-3 flex w-fit flex-row items-center"
      >
        <template #icon>
          <arrow-top-circle-linear size="1.1rem" />
        </template>
      </t-badge>

      <p class="t-text-md m-0 mb-8 p-0 text-grey-700">
        {{ $tc('admin_inboxes.automate_certain_parts_of_your_workflow') }}
      </p>

      <div class="flex flex-row justify-between">
        <t-button
          v-if="$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
          class="flex flex-row items-center"
          @click.prevent="upgrade"
        >
          <arrow-top-circle-linear class="mr-1" size="1.25rem" />

          {{ $t('entitlement.upgrade_to_plan', { plan: plan }) }}
        </t-button>

        <t-button
          class="flex flex-row items-center"
          btn-style="secondary"
          href="https://help.trengo.com/en/articles/63757-the-basic-configuration-of-rules"
          target="_blank"
          rel="noreferrer"
        >
          {{ $tc('general.learn_more') }}

          <external-link-linear class="ml-1" size="1.25rem" />
        </t-button>
      </div>

      <p
        v-if="!$authorization().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
        class="t-text-sm m-0 mt-2 p-0 text-grey-700"
      >
        {{ $tc('billing.contact_your_administrator_to_upgrade') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LightningLinear, ArrowTopCircleLinear, ExternalLinkLinear } from '@trengo/trengo-icons';

import { vm } from '@/ApplicationHandler';
import { PERMISSION } from '@/Configs/Constants';

defineProps<{ plan: string }>();

const upgrade = () => {
  vm.$router.push('/admin/subscription_settings');
};
</script>
