import { debounce } from 'lodash';

import { vm } from '@/ApplicationHandler';

async function hasPermission(to, from, next) {
  await new Promise(ensureUserPermissionsAreFetched);

  const permission = to.meta['permissionRequired'];

  if (vm && !vm.$authorization().hasPermission(permission)) {
    next('/unauthorized');

    return;
  }

  next();
}

function ensureUserPermissionsAreFetched(resolve, reject) {
  if (vm && vm.$store.getters['initialData/user'].permissions) {
    resolve();
  }

  const debounced = debounce(function () {
    ensureUserPermissionsAreFetched(resolve, reject);
  }, 30);

  debounced();
}

export default hasPermission;
