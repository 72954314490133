<template>
  <div>
    <div class="mb-5 max-w-full rounded-xl border-1 border-grey-300 bg-white p-6">
      <div class="flex flex-col flex-wrap">
        <div class="mb-2 flex items-center justify-between">
          <div class="flex w-full items-center gap-2">
            <div>
              <div class="mb-1 flex gap-4">
                <span class="t-text-h5 m-0 text-grey-800">
                  {{ $t('usage_overview.overview_section_heading') }}
                </span>
                <t-badge
                  v-if="isUpcomingCycle"
                  class="w-fit"
                  variant="default"
                  :text="$t(`usage_overview.overview_section_badge_${periodBreakdown.periodType}`)"
                />
              </div>
              <span class="t-text-sm text-grey-600">
                {{ $t('usage_overview.overview_section_subheading') }}
              </span>
            </div>
            <div class="ml-auto">
              <t-button
                v-if="useFeatureFlagStore().isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_ADD_CONVERSATION_BLOCKS_CURRENT)"
                size="sm"
                data-test="addConversationBlocksCTA"
                :disabled="!isCurrentCycle || isQuotaUpdatePending"
                btn-style="primary"
                @click="openConversationBlocksModal"
              >
                {{ $t('usage_overview.overview_section_conversations_cta') }}
              </t-button>
            </div>
          </div>
        </div>

        <div v-if="(isCurrentCycle && isQuotaUpdatePending) || isUpcomingCycle" class="mt-6">
          <t-inline-banner v-if="isCurrentCycle && isQuotaUpdatePending" class="mb-2">
            <template #icon>
              <info-linear />
            </template>
            {{ $t('usage_overview.overview_section_quota_update_pending') }}
          </t-inline-banner>
          <t-inline-banner v-if="isUpcomingCycle" class="mb-2">
            <template #icon>
              <info-linear />
            </template>
            <div class="t-text-sm-emphasize mb-1">
              {{ $t('usage_overview.overview_section_upcoming_banner_title') }}
            </div>
            <div class="t-text-sm">
              {{ $t('usage_overview.overview_section_upcoming_banner_description') }}
            </div>
          </t-inline-banner>
        </div>

        <div class="flex flex-col">
          <progress-overview :usage-overview="periodBreakdown" />
          <div class="pt-2">
            <div v-for="(info, i) in OVERVIEW_INFORMATIONS" :key="i" class="float-left mb-2 mt-4 w-1/2 pl-1 pr-2">
              <div class="t-text-sm-emphasize mb-3 flex items-center gap-2">
                <component :is="info.icon" size="20px"></component>
                <span>{{ $t(info.title) }}</span>
              </div>
              <div class="t-text-sm text-grey-600">
                {{ $t(info.description) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InfoLinear } from '@trengo/trengo-icons';
import { computed, type PropType } from 'vue';

import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { PERIOD_TYPE, FEATURE_FLAG_SUBSCRIPTION } from '@/Configs/Constants';
import { useBillingStore, useFeatureFlagStore } from '@/store/pinia';

import ProgressOverview from './ProgressOverview.vue';
import { OVERVIEW_INFORMATIONS } from '../constants';

import type { PeriodBreakdown } from '../types';

const props = defineProps({
  periodBreakdown: {
    type: Object as PropType<PeriodBreakdown>,
    required: true,
  },
  isCurrentCycle: {
    type: Boolean,
    default: false,
  },
});

const billingStore = useBillingStore();
const isQuotaUpdatePending = computed(() => billingStore.invoices.length > 0);
const { handleOpen: openConversationBlocksModal } = useQueryParameterOpenState('addConversationBlocksModal');


const isUpcomingCycle = computed(() => props.periodBreakdown.periodType === PERIOD_TYPE.UPCOMING);
</script>
