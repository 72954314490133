<template>
  <div class="mx-2 my-16 flex flex-col items-center text-center">
    <t-thumbnail mx-auto class="mb-5 bg-white" size="large">
      <integration-linear size="1.5rem" />
    </t-thumbnail>

    <h1 class="t-text-md-emphasize m-0 mb-2 p-0 text-grey-800">
      {{ $tc('integration_hub.no_integrations_available') }}
    </h1>

    <h2 class="t-text-sm m-0 mb-1 p-0 text-grey-700">
      {{ $tc('integration_hub.integrations_need_to_be_installed') }}
    </h2>

    <h2 class="t-text-sm m-0 mb-5 p-0 text-grey-700">
      {{ $tc('integration_hub.check_if_integrations_are_active') }}
    </h2>

    <t-button
      v-if="hasIntegrationHubPermissions"
      class="flex flex-row items-center"
      btn-style="secondary"
      @click.prevent="navigateToIntegrations"
    >
      {{ $tc('integration_hub.go_to_integration_hub') }}

      <arrow-right-linear class="ml-2" size="1.4rem" />
    </t-button>
  </div>
</template>

<script lang="ts">
import { IntegrationLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { FEATURE_FLAG_INBOX, PERMISSION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default defineComponent({
  name: 'NoIntegrationsPlaceholder',

  components: {
    IntegrationLinear,
    ArrowRightLinear,
  },

  computed: {
    hasIntegrationHubPermissions() {
      return this.$authorization().hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE);
    },
  },

  methods: {
    navigateToIntegrations() {
      if (useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTEGRATION_HUB)) {
        this.$router.push('/admin/integrations/all');
      } else {
        this.$router.push('/admin/developers/plugins');
      }
    },
  },
});
</script>
