import Tribute from 'tributejs';

import { escapeHtml } from '@/util/stringHelpers';

import emojiList from '../../../util/emoji';

export default new Tribute({
  trigger: ':',
  noMatchTemplate: '',
  menuShowMinLength: '2',
  replaceTextSuffix: '',
  selectTemplate: (item) => {
    if (!item) {
      return;
    }
    return item.original.char;
  },
  menuItemTemplate: (item) => {
    return (
      '<span class="flex w-full items-center"><span class="user-image items-center" style="display:flex;line-height:1;min-width:21px;width:21px;">' +
      escapeHtml(item.original.char) +
      '</span><span class="text-ellipsis" style="font-weight:normal;line-height:1;padding:0 7px 3px 0;">' +
      escapeHtml(item.original.name.replace(/_/g, ' ')) +
      '</span></span>'
    );
  },
  lookup: (item) => item.name,
  values: (_text, cb) => {
    let emojis = [];

    Object.values(emojiList).forEach((group) => {
      Object.entries(group).forEach(([name, char]) => {
        emojis.push({ name: name, char: char });
      });
    });

    cb(emojis);
  },
});
