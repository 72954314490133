<script>
export default {
  props: {
    showDay: {
      type: Boolean,
      default: false,
    },
    dayText: {
      type: String,
      default: '',
    },
    showUnread: {
      type: Boolean,
      default: false,
    },
    unreadText: {
      type: String,
      default: '',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div
    class="relative ml-4 mt-6 lg:mr-2"
    :class="[showDay ? 'mb-12' : 'mb-6']"
    style="height: 1px"
    :style="{ backgroundColor: showUnread ? 'var(--color-error-500)' : 'var(--color-grey-300)' }"
  >
    <span
      v-if="showDay"
      class="
        _500
        absolute
        relative
        inline-block
        min-w-0
        max-w-full
        select-none
        break-words
        rounded-full
        bg-white
        px-2
        text-sm text-grey-800
      "
      style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
    >
      <span class="unread-indicator-label rounded-full border border-grey-200 px-4 py-2">{{ dayText }}</span>
    </span>
    <span
      v-if="showUnread"
      class="pull-right bg-white p-2 text-sm"
      style="transform: translate(0%, -50%); color: var(--color-error-500)"
    >
      <span>{{ isMobile ? $t('tickets.unread_message') : unreadText }}</span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 543px) {
  .unread-indicator-label {
    border-width: 0;
  }
}
</style>
