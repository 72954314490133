export default {
  methods: {
    formatCalendar(dayString, format = null) {
      return window.moment(dayString, format).calendar(null, {
        sameDay: '[' + this.$t('general.today') + ']',
        nextDay: '[' + this.$t('general.tomorrow') + ']',
        nextWeek: 'dddd',
        lastDay: '[' + this.$t('general.yesterday') + ']',
        lastWeek: '[' + this.$t('general.last_day_prefix') + '] dddd',
        sameElse: 'L',
      });
    },
    formatSeconds(seconds) {
      const hours = Math.floor(seconds / 60 / 60 || 0);
      const minutes = Math.floor((seconds / 60) % 60 || 0);

      if (hours > 0) {
        return this.$t('general.format_hours_minutes', { h: hours, m: minutes });
      } else {
        return this.$t('general.format_minutes', { m: minutes });
      }
    },
    formatTime(seconds) {
      return window.moment(seconds * 1000).format('LT');
    },
    formatDate(date) {
      return window.moment(date).format('L');
    },
    moment(...params) {
      return window.moment(params);
    },
    setGlobalLocale(locale) {
      window.moment.locale(locale);
    },
    setGlobalTimeZone(timezone) {
      window.moment.tz.setDefault(timezone);
    },
  },
};
