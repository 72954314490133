<script type="text/babel">
import TimeFormatting from '../../mixins/TimeFormatting';
import channelIcon from '../ChannelIcon.vue';
import collapse from '../Collapse';
import timeLeftAgo from '../Elements/TimeLeftAgo';

export default {
  name: 'Moments',
  emits: ['no-moments'],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
  },

  data: function () {
    return {
      searchTerm: '',
      isSearching: false,
      originalTickets: {
        data: [],
        links: {},
        meta: {},
      },
      tickets: {
        data: [],
        links: {},
        meta: {},
      },
    };
  },

  mixins: [TimeFormatting],

  components: {
    collapse,
    channelIcon,
    timeLeftAgo,
  },

  mounted() {
    this.search(false, true);
  },

  watch: {
    ticket() {
      this.search(false, true);
    },
  },

  methods: {
    statusColor: function (t) {
      switch (t.status) {
        case 'OPEN':
          return '#f3c111';
        case 'ASSIGNED':
          return t.user_id === this.$root.user.id ? '#4bc0c0' : '#14B29F';
        case 'CLOSED':
        case 'SPAM':
        default:
          return '#adadad';
      }
    },
    async search(next = false, init = false) {
      let page = 1;
      if (next) {
        page = this.tickets.meta.current_page + 1;
      }

      if (this.searchTerm.length || init || next) {
        this.isSearching = !init;

        let searchParams = {
          page: page,
          sort: '-date', // todo: search sort
          term: this.searchTerm,
        };
        if (this.profile) {
          searchParams.profile_id = this.profile.id;
        } else {
          searchParams.contact_id = this.ticket.contact_id;
        }

        let tickets = (await axios.get('/api/v2/tickets', { params: searchParams })).data;
        if (next) {
          this.tickets = Object.assign({}, this.tickets, {
            data: [...this.tickets.data, ...tickets.data],
            meta: tickets.meta,
            links: tickets.links,
          });
        } else {
          this.tickets = tickets;
        }

        if (!this.originalTickets.data.length || init) {
          this.originalTickets = tickets; // notice: by ref
        }

        if (init && !tickets.data.length) {
          this.$emit('no-moments');
        }
      } else {
        this.resetSearch();
      }
    },
    resetSearch() {
      this.isSearching = false;
      this.searchTerm = '';
      this.tickets = this.originalTickets;

      if (!this.tickets.data.length) {
        this.search(false, true);
      }
    },

    getChannelNamePresenter(type) {
      switch (type) {
        case 'EMAIL':
          return 'Email';
        case 'CHAT':
          return 'Chat';
        case 'VOIP':
          return 'Phone call';
        case 'WHATSAPP':
        case 'WA_BUSINESS':
          return 'WhatsApp';
        case 'FACEBOOK':
          return 'Facebook';
        case 'SMS':
          return 'SMS';
        default:
          return type.toLocaleString();
      }
    },
    stripHtml(str) {
      if (str == null) {
        return;
      }
      return str.replace(/<\/?[^>]+(>|$)/g, '');
    },
    openTicket(ticket) {
      this.$router.push('/tickets/' + ticket.id);
    },
  },
};
</script>

<template>
  <div>
    <div class="input-group tt-search-box my-3">
      <div v-show="isSearching" class="input-group-addon remove-addon" @click="resetSearch()">
        <i class="fa fa-times text-grey-600"></i>
      </div>
      <div v-show="!isSearching" class="input-group-addon remove-addon" @click="search()">
        <i class="fa fa-search text-grey-600"></i>
      </div>
      <input
        v-model="searchTerm"
        type="text"
        :placeholder="$t('general.searching')"
        @keyup.enter="search()"
        @keyup.esc="resetSearch()"
      />
    </div>

    <button
      v-for="t in tickets.data"
      class="contact_moment w-full py-3"
      :class="{ 'current-conv': t.id === ticket.id }"
      @click="openTicket(t)"
    >
      <div class="text-left">
        <div class="flex items-center">
          <div v-if="profile" class="contact_moment_title flex-1 text-ellipsis text-left">
            <span class="">{{ t.contact.name }}</span>
          </div>
          <div v-else class="contact_moment_title text-ellipsis text-left">
            <span class="">{{ getChannelNamePresenter(t.channel.type) }}</span>
          </div>
          <div class="mx-2">
            <i
              v-if="t.latest_message && t.latest_message.attachments && t.latest_message.attachments.length > 0"
              class="material-icons text-md mb-0 mr-2 pb-0 text-grey-500"
            >
              attachment
            </i>
          </div>
          <div class="ml-auto flex-1 flex-shrink flex-nowrap text-right">
            <datetime
              v-if="t.latest_message"
              :time="t.latest_message.created_at"
              class="contact_moment_date text-xs"
              :pretty="$root.prettyDates"
            ></datetime>
            <datetime
              v-else
              :time="t.created_at"
              class="contact_moment_date text-xs"
              :pretty="$root.prettyDates"
            ></datetime>
          </div>
        </div>

        <div class="ml-auto">
          <div class="sidebar_text-muted text-left text-sm"></div>
        </div>
      </div>

      <div class="contact_moment_text truncate text-left">
        <channel-icon class="pr-1" :channel="t.channel.type" :style="{ color: statusColor(t) }"></channel-icon>

        <span v-if="t.channel.type === 'EMAIL'" :class="{ _500: t.id === ticket.id }" class="latest_moment_text">
          {{ stripHtml(t.subject) }}
        </span>
        <span
          v-else-if="t.latest_message && t.latest_message.message"
          :class="{ _500: t.id === ticket.id }"
          class="latest_moment_text"
        >
          {{ stripHtml(t.latest_message.message) }}
        </span>
      </div>
    </button>

    <div v-if="isSearching && tickets.data.length === 0" class="font-weight-bold mt-4">
      {{ $t('tickets.no_tickets_found') }}
    </div>

    <div
      v-if="tickets.links && tickets.links.next != null"
      v-tooltip.top="$t('tickets.load_more')"
      style="margin-top: -5px; cursor: pointer"
      class="text-muted pt-4 text-center"
      data-toggle="tooltip"
      @click="search(true)"
    >
      <i class="material-icons">expand_more</i>
    </div>
  </div>
</template>
<style lang="scss" scoped>
// Trengo Theme Variables
@import '../../assets/sass/variables';

.form-control {
  border: $t-border;
  border-radius: $t-border-radius;
}

.contact_moment {
  border-bottom: $t-border;

  .contact_moment_title {
    font-weight: 600;
    color: black;
  }

  .contact_moment_text {
    color: $t-gray-dark;
  }

  .contact_moment_date {
    color: $t-gray-dark;
  }
}

.custom-shadow-outline {
  box-shadow: rgba(74, 144, 226, 0.05) 0px 0px 4px 0px, rgba(0, 0, 0, 0.11) 0px 0px 9px 0px;
}

.custom-shadow-outline:hover {
  background-color: #f1f1f1;
}

.rounded-xl {
  border-radius: 15px;
}

.latest_moment_text {
  overflow: hidden;
  max-height: 40px;
}

.current-conv {
  .latest_moment_text {
    color: $t-black;
    /*font-weight: 500;*/
  }

  .contact_moment_date {
    color: $t-black;
    /*font-weight: 500;*/
  }
}

.tt-search-box {
  background: #f1f1f1;
  border-radius: $t-border-radius;

  input {
    width: 100%;
    background-color: #f1f1f1;
    padding: 0.5rem 0.75rem 0.5rem 0;
    border-bottom-right-radius: $t-border-radius;
    border-top-right-radius: $t-border-radius;
    opacity: 1;
    color: $t-gray-dark;
    border: none;

    &::-ms-clear {
      display: none;
    }
  }

  .input-group-addon {
    border: none !important;
    border-bottom-left-radius: $t-border-radius;
    border-top-left-radius: $t-border-radius;
    color: $t-gray-dark;
    padding-right: 0.75rem;
    background-color: #f1f1f1;
  }

  .remove-addon {
    border: none !important;
  }
}
</style>
